import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import UploadPhotosDialog from 'components/Dialog/UploadPhotosDialog/UploadPhotosDialog';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import InspectionPlatformService from 'services/InspectionPlatformService/inspectionPlatformService';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';

interface IUploadPhotosMenuItemProps {
  inspection: IInspection;
}

const UploadPhotosMenuItem = ({ inspection }: IUploadPhotosMenuItemProps) => {
  const [isUpdatePhotosDialogOpen, setIsUpdatePhotosDialogOpen] = useState(false);
  const { isRegulator, isAdmin } = useAuthDispatch();

  const hasPermition = isRegulator() || isAdmin();

  const isTransmition = InspectionPlatformService.isSORTInspection(inspection.inspectionPlatform?.code);

  const showIfIsDigital = isTransmition
    ? inspection.currentStatus?.type === StatusEnum.PHOTO_RECEIVED
    : inspection.currentStatus?.type === StatusEnum.PHOTO_RECEIVED ||
      inspection.currentStatus?.type === StatusEnum.INSPECTION_STARTED ||
      inspection.currentStatus?.type === StatusEnum.LINK_SENT;

  const showIfIsLocally =
    InspectionService.isInspectionCategoryLocally(inspection?.inspectionCategory) &&
    inspection.currentStatus?.type === StatusEnum.DEFAULT;

  const showByPermissionAndCurrentStatus = hasPermition && (showIfIsDigital || showIfIsLocally);

  return (
    <>
      <MenuItem
        data-testid="upload-photos"
        onClick={() => setIsUpdatePhotosDialogOpen(true)}
        disabled={!showByPermissionAndCurrentStatus}
      >
        Enviar Fotos
      </MenuItem>

      <UploadPhotosDialog
        dialogVisibility={isUpdatePhotosDialogOpen}
        inspectionId={inspection.id}
        currentStatus={inspection.currentStatus}
        inspectionPhotos={inspection.photos}
        inspectionCategory={inspection.inspectionCategory}
        handleClose={() => setIsUpdatePhotosDialogOpen(false)}
      />
    </>
  );
};

export default UploadPhotosMenuItem;
