import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { IInspection } from 'pages/SchedulerList/components/Inspection';
import { toast } from 'react-toastify';
import { NotFound } from 'pages/NotFound';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { ReportContainer } from './Report.styles';
import { Details } from './components/Details/Details';

export const Report: React.FC = () => {
  const { pathname } = useLocation();
  const [selectedInspection, setSelectedInspection] = useState<IInspection>();
  const [showPageNotFound, setShowPageNotFound] = useState<boolean>(false);

  const getInspectionTokenFromPathname = (path: string) => {
    const token = path.split('/vistoria/');
    return token ? token[1] : '';
  };

  const getInspectionTokenFromPath = useCallback(async () => {
    const inspectionTokenPath = getInspectionTokenFromPathname(pathname);
    try {
      const { inspection } = await InspectionService.getInspectionByToken(inspectionTokenPath);

      if (!selectedInspection) {
        inspectionTokenPath && setSelectedInspection(inspection);
      }
    } catch (error) {
      toast.error('Erro ao validar o token. Por favor, recarregue a página.');
      setShowPageNotFound(true);
    }
  }, [selectedInspection, pathname, setSelectedInspection]);

  useEffect(() => {
    getInspectionTokenFromPath();
  }, [getInspectionTokenFromPath]);

  return (
    <>
      {!showPageNotFound ? (
        <ReportContainer>
          <Details inspectionData={selectedInspection} />
        </ReportContainer>
      ) : (
        <NotFound />
      )}
    </>
  );
};
