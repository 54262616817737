import styled from 'styled-components';

export const ListItem = styled.li`
  min-height: 69px;
  padding: 5px 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
`;

export const ItemSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 35%;

  @media (max-width: 1715px) {
    width: 35%;
  }

  @media (max-width: 1310px) {
    width: 25%;
  }
`;

export const ItemLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #000000;
  }

  span {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;
    color: #00000080;
  }

  @media (max-width: 1440px) {
    strong {
      font-size: 12px;
    }
  }
`;

export const InformationServiceContainer = styled.div`
  display: flex;
  gap: 30px;
  width: 65%;
  justify-content: end;
  align-items: end;

  @media (max-width: 1715px) {
    gap: 30px;
    width: 65%;
  }

  @media (max-width: 1310px) {
    gap: 10px;
    width: 70%;
  }
`;

export const InformationServiceItem = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  min-width: 80px;
  max-width: 80px;

  @media (min-width: 1920px) {
    min-width: 80px;
    max-width: 90px;
  }

  @media (min-width: 1850px) {
    min-width: 80px;
    max-width: 100px;
  }
`;

export const InformationTitle = styled.span`
  color: #6a758b;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

export const InformationSubtitle = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: #000;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;

  @media (max-width: 1366px) {
    font-size: 11px;
  }
`;

export const ItemActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;
