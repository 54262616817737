import React from 'react';
import { useAppSelector } from 'hooks/hooks';

import { selectDeductible, selectSummaryCost } from 'stores/presentBudget/presentBudget.selector';
import { selectFipValue } from 'stores/presentInspection/presentInspection.selector';
import { priceFormatter, addPercentFormatterNumber } from 'utils/formatPrice';
import * as S from './FooterOverview.styles';

export default function FooterOverview() {
  const summaryCost = useAppSelector(selectSummaryCost);
  const deductible = useAppSelector(selectDeductible);
  const fipValue = useAppSelector(selectFipValue);

  return (
    <S.Container>
      <S.OverviewContainer>
        <S.Title>RESUMO</S.Title>
        <S.OverviewContent>
          <S.OverviewItem>
            <S.Subtitle>Peças</S.Subtitle>
            <S.Price>{priceFormatter.format(summaryCost.parts.net)}</S.Price>
          </S.OverviewItem>

          <S.Operand>+</S.Operand>

          <S.OverviewItem>
            <S.Subtitle>Serviços</S.Subtitle>
            <S.Price>{priceFormatter.format(summaryCost.services.total)}</S.Price>
          </S.OverviewItem>

          <S.Operand>=</S.Operand>

          <S.OverviewItem>
            <S.Subtitle>Total geral</S.Subtitle>
            <S.Price>{priceFormatter.format(summaryCost.gross)}</S.Price>
          </S.OverviewItem>

          <S.Operand>-</S.Operand>

          <S.OverviewItem>
            <S.Subtitle>Franquia</S.Subtitle>
            <S.PriceHighlighted>{priceFormatter.format(deductible)}</S.PriceHighlighted>
          </S.OverviewItem>

          <S.Operand>=</S.Operand>
        </S.OverviewContent>
      </S.OverviewContainer>

      <S.HighlightedContainer gray net>
        <S.OverviewItem>
          <S.Subtitle>Líquido geral</S.Subtitle>
          <S.Price>{priceFormatter.format(summaryCost.net)}</S.Price>
        </S.OverviewItem>
      </S.HighlightedContainer>

      <S.ActionsContainer>
        <S.HighlightedContainer>
          <S.Title>VALOR FIPE • {priceFormatter.format(fipValue ?? 0)}</S.Title>
          <S.OverviewContent>
            <S.OverviewItem>
              <S.Subtitle>Porcentagem de danos</S.Subtitle>
              <S.Price>{addPercentFormatterNumber(summaryCost.percentages.damageRate)}</S.Price>
            </S.OverviewItem>
          </S.OverviewContent>
        </S.HighlightedContainer>
      </S.ActionsContainer>
    </S.Container>
  );
}
