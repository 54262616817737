import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBudget } from 'commom/types/Budget.types';
import { GeneralEvaluationEnum, IReportDamageParts, IReportRescuedParts } from 'commom/types/BudgetReport.types';
import {
  BudgetsSteps,
  IInitBudget,
  IPresentBudget,
  ISetDamageReportAllAnswer,
  ISetRescuedReportAllAnswer,
  ISetRescuedReportAnswerId,
  ISetRescuedReportAnswerText,
} from './presentBudget.types';

const initialState: IPresentBudget = {
  currentStep: 'INITIAL_DATA',
  budget: {} as IBudget,
  rescuedReport: null,
  damageReport: null,
  rescuedQuestionIdsUnanswered: null,
  damageQuestionsIdsUnanswered: null,
};

export const presentBudgetSlice = createSlice({
  name: 'presentBudget',
  initialState,
  reducers: {
    resetPresentBudgetState: () => initialState,
    initBudget: (state, action: PayloadAction<IInitBudget>) => {
      const { payload } = action;

      state.currentStep = payload.currentStep || 'INITIAL_DATA';
      state.budget = payload.budget;
    },
    setCurrentStep: (state, action: PayloadAction<BudgetsSteps>) => {
      const { payload: currentStep } = action;
      state.currentStep = currentStep;
    },
    setBudget: (state, action: PayloadAction<IBudget>) => {
      const { payload } = action;

      state.budget = payload;
    },
    setRescuedReport: (state, action: PayloadAction<IReportRescuedParts>) => {
      const { payload } = action;

      state.rescuedReport = payload;
    },
    setDamageReport: (state, action: PayloadAction<IReportDamageParts>) => {
      const { payload } = action;

      state.damageReport = payload;
    },
    setRescuedQuestionIdsUnanswered: (state, action: PayloadAction<string[] | null>) => {
      state.rescuedQuestionIdsUnanswered = action.payload;
    },
    setDamageQuestionIdsUnanswered: (state, action: PayloadAction<string[] | null>) => {
      state.damageQuestionsIdsUnanswered = action.payload;
    },
    setVehicleEvaluationWithoutEngineAccess: (state, action: PayloadAction<boolean>) => {
      if (state.rescuedReport) {
        state.rescuedReport.vehicleEvaluation.withoutEngineAccess = action.payload;
      }
    },
    setVehicleEvaluationGeneralEvaluation: (state, action: PayloadAction<GeneralEvaluationEnum | null>) => {
      if (state.rescuedReport) {
        state.rescuedReport.vehicleEvaluation.generalEvaluation = action.payload;
      }
    },
    setVehicleEvaluationEngineNumber: (state, action: PayloadAction<string | null>) => {
      if (state.rescuedReport) {
        state.rescuedReport.vehicleEvaluation.engineNumber = action.payload;
      }
    },
    setVehicleEvaluationJustifications: (state, action: PayloadAction<string | null>) => {
      if (state.rescuedReport) {
        state.rescuedReport.vehicleEvaluation.justifications = action.payload;
      }
    },
    setRescuedReportAnswerId: (state, action: PayloadAction<ISetRescuedReportAnswerId>) => {
      if (!state.rescuedReport) {
        return;
      }

      const { questionGroupId, questionId, answerId } = action.payload;

      const questionGroup = state.rescuedReport.questionsGroups.find((group) => group._id === questionGroupId);

      if (!questionGroup) {
        return;
      }

      const question = questionGroup.questions.find((q) => q._id === questionId);

      if (!question) {
        return;
      }

      question.answerId = answerId;
    },
    setRescuedReportAnswerText: (state, action: PayloadAction<ISetRescuedReportAnswerText>) => {
      if (!state.rescuedReport) {
        return;
      }

      const { questionGroupId, questionId, answerText } = action.payload;

      const questionGroup = state.rescuedReport.questionsGroups.find((group) => group._id === questionGroupId);

      if (!questionGroup) {
        return;
      }

      const question = questionGroup.questions.find((q) => q._id === questionId);

      if (!question) {
        return;
      }

      question.textAnswer = answerText;
    },
    setRescuedReportAllAnswers: (state, action: PayloadAction<ISetRescuedReportAllAnswer>) => {
      if (!state.rescuedReport) return;

      const { questionGroupId, slug } = action.payload;

      const questionGroup = state.rescuedReport.questionsGroups.find((group) => group._id === questionGroupId);

      if (!questionGroup) return;

      questionGroup.questions.forEach((question) => {
        const answer = question.options.find((option) => option.slug === slug);

        if (answer) question.answerId = answer._id;
      });
    },

    setDamageReportAnswerId: (state, action: PayloadAction<ISetRescuedReportAnswerId>) => {
      if (!state.damageReport) return;

      const { questionGroupId, questionId, answerId } = action.payload;

      const questionGroup = state.damageReport.questionsGroups.find((group) => group._id === questionGroupId);

      if (!questionGroup) return;

      const question = questionGroup.questions.find((question) => question._id === questionId);

      if (!question) return;

      question.answerId = answerId;
    },

    setDamageReportAllAnswers: (state, action: PayloadAction<ISetDamageReportAllAnswer>) => {
      if (!state.damageReport) return;

      const { questionGroupId, groupIdentifier, slug } = action.payload;

      const questionGroup = state.damageReport.questionsGroups.find((group) => group._id === questionGroupId);

      if (!questionGroup) return;

      const targetQuestions =
        groupIdentifier === 'firstHalf'
          ? questionGroup.questions.slice(0, Math.ceil(questionGroup.questions.length / 2))
          : questionGroup.questions.slice(Math.ceil(questionGroup.questions.length / 2));

      targetQuestions.forEach((question) => {
        const answer = question.options.find((option) => option.slug === slug);

        if (answer) question.answerId = answer._id;
      });
    },
  },
});

export const {
  resetPresentBudgetState,
  initBudget,
  setCurrentStep,
  setBudget,
  setRescuedReport,
  setDamageReport,
  setRescuedQuestionIdsUnanswered,
  setDamageQuestionIdsUnanswered,
  setVehicleEvaluationEngineNumber,
  setVehicleEvaluationGeneralEvaluation,
  setVehicleEvaluationJustifications,
  setVehicleEvaluationWithoutEngineAccess,
  setRescuedReportAnswerId,
  setRescuedReportAnswerText,
  setRescuedReportAllAnswers,
  setDamageReportAnswerId,
  setDamageReportAllAnswers,
} = presentBudgetSlice.actions;
export default presentBudgetSlice.reducer;
