import React from 'react';
import { priceFormatter } from 'utils/formatPrice';
import { IBudgetLabor } from 'commom/types/Budget.types';
import { Card, CardBody, CardHeader, CardTitle, DiscountCard, LaborContainer } from './LaborValues.styles';
import { DataBox } from 'components/DataBox';

interface ILaborValuesProps {
  labor: IBudgetLabor;
}

const LaborValues = ({ labor }: ILaborValuesProps) => {
  return (
    <LaborContainer>
      <Card>
        <CardHeader>
          <CardTitle>VALORES DE MÃO DE OBRA</CardTitle>
        </CardHeader>

        <CardBody>
          <DataBox.Root>
            <DataBox.Content>
              {labor?.cost?.map((cost) => (
                <DataBox.Item key={cost?.type}>
                  <DataBox.Label>{cost.description}</DataBox.Label>
                  <DataBox.Value>{cost.price ? priceFormatter.format(cost.price) : '-'}</DataBox.Value>
                </DataBox.Item>
              ))}
            </DataBox.Content>
          </DataBox.Root>
        </CardBody>
      </Card>

      <DiscountCard>
        <CardHeader>
          <CardTitle>DESCONTO E MAJORAÇÂO DE PEÇAS</CardTitle>
        </CardHeader>

        <CardBody>
          <DataBox.Root>
            <DataBox.Content>
              <DataBox.Item>
                <DataBox.Label>Desconto de peça</DataBox.Label>
                <DataBox.Value>{labor?.discount}%</DataBox.Value>
              </DataBox.Item>
              <DataBox.Item>
                <DataBox.Label>Majoração</DataBox.Label>
                <DataBox.Value>{labor?.increase}%</DataBox.Value>
              </DataBox.Item>
            </DataBox.Content>
          </DataBox.Root>
        </CardBody>
      </DiscountCard>
    </LaborContainer>
  );
};

export default LaborValues;
