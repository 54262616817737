import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React from 'react';
import { ToggleButtonContainer } from './QuestionGroupInput.styles';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { setRescuedReportAllAnswers } from 'stores/presentBudget/presentBudget.store';
import { IToggleButtonOption } from 'components/ToggleButton';
import { IQuestionGroupInputProps } from './QuestionGroupInput.types';
import { selectRescuedReportIsDraft } from 'stores/presentBudget/presentBudget.selector';

const QuestionGroupInput = ({ questionGroupId, questions }: IQuestionGroupInputProps) => {
  const [SelectedOptionValue, setSelectedOptionValue] = React.useState<string | null>(null);
  const isDraftRescuedReport = useAppSelector(selectRescuedReportIsDraft);
  const dispatch = useAppDispatch();

  const toggleButtonOptions: IToggleButtonOption[] = questions[0].options.map((option) => {
    return {
      code: option.slug,
      title: option.slug,
    };
  });

  const handleToggleButtonOnChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
    setSelectedOptionValue(value);
    dispatch(setRescuedReportAllAnswers({ questionGroupId, slug: value }));
  };

  return (
    <ToggleButtonContainer>
      <ToggleButton
        size="small"
        options={toggleButtonOptions}
        value={SelectedOptionValue}
        onChange={handleToggleButtonOnChange}
        disabled={!isDraftRescuedReport}
      />
    </ToggleButtonContainer>
  );
};

export default QuestionGroupInput;
