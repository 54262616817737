import { IBudgetPart } from 'commom/types/Budget.types';
import React from 'react';
import { formatDecimalValue } from 'utils/formatDecimalValue';
import { capitalizeFirstLetter } from 'utils/formatString';
import arrowDownIcon from 'assets/svg/arrowDown.svg';
import arrowUpIcon from 'assets/svg/arrowUp.svg';
import clockIcon from 'assets/svg/clock.svg';
import {
  PartsTableRow,
  PartTableName,
  PartDetailsContainer,
  LabelContainer,
  ItemTitle,
  ItemSubtitle,
  ServiceIcon,
  ServiceBadge,
  ServiceInfo,
  ItemTypeSubtitle,
  PartContainer,
  ItemSubtitlePriceModified,
  ItemSubtitleNetPrice,
  PartsSection,
} from './BudgetPart.styles';
import { priceFormatter } from 'utils/formatPrice';

interface IBudgetPartProps {
  part: IBudgetPart;
}

const BudgetPart = ({ part }: IBudgetPartProps) => {
  const renderPartTempServiceTypeIcon = (serviceType: string) => {
    const partTempServiceTypes: { [key: string]: React.JSX.Element } = {
      repair: <ServiceBadge className="bg-red">R</ServiceBadge>,
      painting: <ServiceBadge className="bg-purple">P</ServiceBadge>,
      removeAndInstall: <ServiceBadge className="bg-orange">R&I</ServiceBadge>,
    };

    return serviceType ? partTempServiceTypes[serviceType] : null;
  };

  const showHourService = (hourService: number) => {
    return hourService > 0 ? formatDecimalValue(hourService) : '-';
  };

  const renderPartTempService = (part: IBudgetPart) => {
    return Object.entries(part.serviceHours).map(([key, value]) => {
      return value !== 0 ? (
        <ServiceIcon key={key}>
          {renderPartTempServiceTypeIcon(key)}
          <ServiceInfo>
            <img src={clockIcon} alt="clock" width="10" />
            <span>{showHourService(value)}</span>
          </ServiceInfo>
        </ServiceIcon>
      ) : null;
    });
  };

  const showArrowIndicator = (original: number, current: number) => {
    const icon = current > original ? arrowUpIcon : arrowDownIcon;
    return current === 0 || current === original ? null : <img src={icon} alt="arrow" width="10" />;
  };

  return (
    <PartsSection>
      <PartContainer>
        <PartsTableRow>
          <PartDetailsContainer>{renderPartTempService(part)}</PartDetailsContainer>
          <PartTableName>
            <LabelContainer>
              <strong>{capitalizeFirstLetter(part.name)}</strong>
              <span>{part.sku}</span>
            </LabelContainer>
          </PartTableName>

          <PartTableName>
            <ItemTitle>Tipo</ItemTitle>
            <ItemTypeSubtitle>{part.sourceType.description}</ItemTypeSubtitle>
          </PartTableName>
          <PartTableName>
            <ItemTitle>Fornecedor</ItemTitle>
            <ItemSubtitle>{part.partSupplier.description}</ItemSubtitle>
          </PartTableName>
          <PartTableName>
            <ItemTitle>Peça</ItemTitle>
            <ItemSubtitle isPriceModifed={part.isNetPriceModified}>
              {priceFormatter.format(part.originalPrice)}
            </ItemSubtitle>
          </PartTableName>
          <PartTableName>
            <ItemTitle>Com desconto e majoração</ItemTitle>
            <ItemSubtitle isPriceModifed={part.isNetPriceModified}>
              {priceFormatter.format(part.individualNetPrice)}
            </ItemSubtitle>
            {part.isNetPriceModified && (
              <ItemSubtitlePriceModified>{priceFormatter.format(part.individualNetPrice)}</ItemSubtitlePriceModified>
            )}
          </PartTableName>
          <PartTableName>
            <ItemTitle>Qtd.</ItemTitle>
            <ItemSubtitle>{part.quantity}</ItemSubtitle>
          </PartTableName>
          <PartTableName>
            <ItemTitle>Valor Líquido</ItemTitle>
            <ItemSubtitleNetPrice>
              {priceFormatter.format(
                part.quantity * (part.isNetPriceModified ? part.inputNetPrice : part.individualNetPrice)
              )}
              {showArrowIndicator(
                part.originalPrice,
                part.isNetPriceModified ? part.inputNetPrice : part.individualNetPrice
              )}
            </ItemSubtitleNetPrice>
          </PartTableName>
        </PartsTableRow>
      </PartContainer>
    </PartsSection>
  );
};

export default BudgetPart;
