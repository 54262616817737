import React, { useState } from 'react';
import ResponsibleDialog from 'components/Dialog/ResponsibleDialog/ResponsibleDialog';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { AuthorityEnum } from 'utils/AuthorityEnum';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { useAppSelector } from 'hooks/hooks';
import { MenuItem, SendAnalyst, BackRegulator } from '../../Header.styles';

interface IResponsibleActionsProps {
  inspection: IInspection;
  closeMenu: () => void;
}

const ResponsibleActions = ({ inspection, closeMenu }: IResponsibleActionsProps) => {
  const [showResponsibleDialog, setShowResponsibleDialog] = useState<boolean>(false);
  const [responsibleIsRegulator, setResponsibleIsRegulator] = useState<boolean>(true);
  const budgetConclusions = useAppSelector((state) => state.presentBudget.budget.conclusions);
  const { isAdmin, isRegulator, isAnalyst } = useAuthDispatch();
  const { account } = useAuthState();
  const { isConsumerActive } = useConsumer();
  const { id: inspectionId, companyId, currentStatus, currentResponsible } = inspection;

  const isAdminOrRegulator = isAdmin() || isRegulator();
  const isAdminOrAnalyst = isAdmin() || isAnalyst();

  const inspectionStartedOrPhotosReceived =
    InspectionStatusService.isInspectionStarted(currentStatus) ||
    InspectionStatusService.isPhotosReceived(currentStatus);

  const isResponsibleAdminOrRegulator =
    currentResponsible?.authority === AuthorityEnum.ADMIN || currentResponsible?.authority === AuthorityEnum.REGULATOR;

  const isResponsibleAdminOrAnalyst =
    currentResponsible?.authority === AuthorityEnum.ADMIN || currentResponsible?.authority === AuthorityEnum.ANALYST;

  const showSendToAnalyst = isAdminOrRegulator && isResponsibleAdminOrRegulator && inspectionStartedOrPhotosReceived;

  const showReturnToRegulator = isAdminOrAnalyst && isResponsibleAdminOrAnalyst && inspectionStartedOrPhotosReceived;

  const hasUserConclusion = budgetConclusions?.some((conclusion) => conclusion.user.id === account.id);

  const disableActions = !hasUserConclusion && !isAdmin();

  const openResponsibleDialog = (isRegulatorAction: boolean) => {
    setShowResponsibleDialog(true);
    setResponsibleIsRegulator(isRegulatorAction);
  };

  const closeResponsibleDialog = () => {
    closeMenu();
    setShowResponsibleDialog(false);
  };

  return (
    <>
      {isConsumerActive(ConsumersEnum.INSPECTION_ANALYST, companyId || '') && (
        <>
          {showSendToAnalyst && (
            <MenuItem
              data-testid="send-analysis-button"
              onClick={() => openResponsibleDialog(true)}
              disabled={disableActions}
            >
              <SendAnalyst />
              <span>Transmitir para analista</span>
            </MenuItem>
          )}

          {showReturnToRegulator && (
            <MenuItem
              data-testid="back-regulator-button"
              onClick={() => openResponsibleDialog(false)}
              disabled={disableActions}
            >
              <BackRegulator />
              <span>Transmitir para perito</span>
            </MenuItem>
          )}

          <ResponsibleDialog
            inspectionId={inspectionId}
            companyId={companyId}
            isRegulator={responsibleIsRegulator}
            dialogVisibility={showResponsibleDialog}
            handleClose={closeResponsibleDialog}
          />
        </>
      )}
    </>
  );
};

export default ResponsibleActions;
