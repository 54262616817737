import { IBudgetLaborCost } from 'commom/types/Budget.types';
import { IBudgetLaborCostsServiceTypes, ICreateDealState } from './BudgetLaborCostsService.types';

export const BudgetLaborCostsService: IBudgetLaborCostsServiceTypes = {
  getCreateDealFormSateByCosts: (laborCosts: IBudgetLaborCost[]): ICreateDealState => {
    return laborCosts.reduce((acc, laborCost) => {
      acc[laborCost.type] = laborCost;
      return acc;
    }, {} as ICreateDealState);
  },
  getLaborCostsFromDealState: (dealState: ICreateDealState): IBudgetLaborCost[] => {
    return Object.values(dealState);
  },
};
