import React from 'react';
import vehicleIcon from 'assets/svg/budget-template/icon-vehicle.svg';
import { IProduct } from 'pages/SchedulerList/components/Inspection';
import { getVehicleKilometers } from 'utils/formatDistance';
import {
  HeaderSection,
  VehicleSectionContainer,
  BodySection,
  InformationContainer,
  Information,
  Label,
  Value,
  VersionValue,
} from './VehicleSection.styles';

interface IVehicleSectionProps {
  product: IProduct | undefined;
}

const VehicleSection = ({ product }: IVehicleSectionProps) => {
  return (
    <VehicleSectionContainer>
      <HeaderSection>
        <img src={vehicleIcon} alt="clock" width="18" />
        <h1>Dados do veículo</h1>
      </HeaderSection>

      <BodySection>
        <InformationContainer>
          <Information>
            <Label>Marca</Label>
            <Value>{product?.vehicle?.brand || '-'}</Value>
          </Information>

          <Information>
            <Label>Placa</Label>
            <Value>{product?.plate || '-'}</Value>
          </Information>

          <Information>
            <Label>Modelo</Label>
            <Value>{product?.vehicle?.model || '-'}</Value>
          </Information>
        </InformationContainer>

        <InformationContainer>
          <Information>
            <Label>Versão</Label>
            <VersionValue>{product?.vehicle?.version || '-'}</VersionValue>
          </Information>

          <Information>
            <Label>Ano</Label>
            <Value>{product?.vehicle?.modelYear || '-'}</Value>
          </Information>

          <Information>
            <Label>Odômetro</Label>
            <Value>{getVehicleKilometers(product?.vehicle?.kilometers)}</Value>
          </Information>

          <Information>
            <Label>Chassi</Label>
            <Value>{product?.vehicle?.chassis || '-'}</Value>
          </Information>
        </InformationContainer>
      </BodySection>
    </VehicleSectionContainer>
  );
};

export default VehicleSection;
