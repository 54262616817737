import React from 'react';
import { InvoicesProvider } from 'contexts/invoices/InvoicesProvider';
import InvoicesRouter from './Invoices.router';

export const Invoices = () => {
  return (
    <InvoicesProvider>
      <InvoicesRouter />
    </InvoicesProvider>
  );
};
