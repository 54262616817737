import { IBudgetReportTypeEnum } from './BudgetReport.types';

export enum IBudgetPartServiceTypeEnum {
  REPAIR = 'REPAIR',
  REPLACEMENT = 'REPLACEMENT',
}

export enum BudgetStatusCodeEnum {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export enum IBudgetLaborCostTypeEnum {
  PAINTING = 'PAINTING',
  TRICOAT_PAINTING = 'TRICOAT_PAINTING',
  LABOR = 'LABOR',
  METALLIC_PAINTING = 'METALLIC_PAINTING',
  PEARLIZED_PAINTING = 'PEARLIZED_PAINTING',
  REPAIR = 'REPAIR',
}

export enum IBudgetPartServiceHoursEnum {
  REMOVE_AND_INSTALL = 'REMOVE_AND_INSTALL',
  REPAIR = 'REPAIR',
  PAINTING = 'PAINTING',
}

export interface IBudgetStatus {
  _id: string;
  createdAt: string;
  updatedAt: string;
  code: BudgetStatusCodeEnum;
  description: string;
}

export interface IBudgetStatusItem {
  createdAt: string;
  status: IBudgetStatus;
}

export interface IBudgetConclusion {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  reason: string;
  comment: string;
  isArchived: boolean;
  user: {
    id: string;
    name: string;
  };
}

export interface IBudgetWorkshopAddress {
  fullAddress?: string;
  city?: string;
  uf?: string;
  cep?: string;
  neighborhood?: string;
}

export interface IBudgetWorkshop {
  name: string;
  cnpj: string;
  phone?: string;
  email?: string;
  address?: IBudgetWorkshopAddress;
}

export interface IBudgetClientInfo {
  name: string;
  phone: string;
}

export interface IBudgetPartServiceType {
  code: IBudgetPartServiceTypeEnum;
  description: 'Troca' | 'Reparo';
}

export interface IBudgetPartServiceHours {
  removeAndInstall: number;
  repair: number;
  painting: number;
}

export type BudgetPartServiceHoursKeys = keyof IBudgetPartServiceHours;

export interface IBudgetPart {
  _id: string;
  createdAt: string;
  updatedAt: string;
  sku: string;
  name: string;
  user: string;
  partSupplier: {
    code: string;
    description: string;
  };
  sourceType: {
    code: string;
    description: string;
  };
  quantity: number;
  originalPrice: number;
  totalGrossPrice: number;
  discount: number;
  totalNetPrice: number;
  individualNetPrice: number;
  totalDiscount: number;
  servicePrice: number;
  inputNetPrice: number;
  individualServicePrice: number;
  finalDiscount: number;
  finalIncrease: number;
  totalFinalAmount: number;
  isManualEntry: boolean;
  isNetPriceModified: boolean;
  serviceType: IBudgetPartServiceType;
  originalServiceHours: IBudgetPartServiceHours;
  serviceHours: IBudgetPartServiceHours;
}

export interface IBudgetItemOutsourcedService {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  price: number;
}

export interface IBudgetItems {
  parts: IBudgetPart[];
  outsourcedServices: IBudgetItemOutsourcedService[];
}

export interface IBudgetLaborCost {
  price: number;
  type: IBudgetLaborCostTypeEnum;
  description: string;
}

export interface IBudgetLabor {
  cost: IBudgetLaborCost[];
  createdAt: string;
  discount: number;
  increase: number;
  updatedAt: string;
  user?: {
    authority: string;
    avatar: {
      color: string;
    };
    id: string;
    name: string;
    username: string;
  };
}

export interface IBudgetSummaryHours {
  painting: number;
  repair: number;
  replacement: number;
  total: number;
}

export interface IBudgetSummaryParts {
  discount: number;
  gross: number;
  net: number;
  increase: number;
}

export interface IBudgetSummaryPercentages {
  outsourcedServices: number;
  painting: number;
  repair: number;
  replacement: number;
  damageRate: number;
}

export interface IBudgetSummaryServices {
  outsourcedServices: number;
  painting: number;
  repair: number;
  replacement: number;
  total: number;
}

export interface IBudgetSummaryCost {
  createdAt: string;
  updatedAt: string;
  gross: number;
  hours: IBudgetSummaryHours;
  net: number;
  parts: IBudgetSummaryParts;
  percentages: IBudgetSummaryPercentages;
  services: IBudgetSummaryServices;
}

export interface IReportItem {
  reportId: string;
  reportType: IBudgetReportTypeEnum;
}

export interface IBudget {
  createdAt: string;
  updatedAt: string;
  _id: string;
  isPrivate: boolean;
  version: number;
  isCurrentVersion: boolean;
  deductible: number;
  isTotalLoss: boolean;
  currentStatus: IBudgetStatusItem;
  conclusions: IBudgetConclusion[];
  workshop: IBudgetWorkshop;
  clientInfo: IBudgetClientInfo;
  items: IBudgetItems;
  labor: IBudgetLabor;
  laborNegotiationHistory: IBudgetLabor[];
  summaryCost: IBudgetSummaryCost;
  reports: IReportItem[];
}
