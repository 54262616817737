import { IReportDamageParts, IReportRescuedParts } from 'commom/types/BudgetReport.types';
import ApiService from 'services/ApiService';
import { IBudgetReportService } from './BudgetReportService.types';

const BudgetReportService: IBudgetReportService = {
  getBudgetReportRescuedParts: async (reportId: string) => {
    const { data } = await ApiService.get<{ report: IReportRescuedParts }>(`/budgets/report/${reportId}`);

    return data.report;
  },

  getBudgetReportDamageParts: async (reportId: string) => {
    const { data } = await ApiService.get<{ report: IReportDamageParts }>(`/budgets/report/${reportId}`);

    return data.report;
  },

  updateBudgetReportRescuedParts: async (report) => {
    await ApiService.put(`/budgets/report/${report._id}`, { ...report });
  },

  updateBudgetReportDamageParts: async (report) => {
    await ApiService.put(`/budgets/report/${report._id}`, { ...report });
  },

  toggleDraftState: async (reportId) => {
    const { data } = await ApiService.put(`/budgets/report/${reportId}/toggle-draft`);
    return data.report;
  },
};

export default BudgetReportService;
