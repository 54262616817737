import React from 'react';
import { Switch } from 'react-router-dom';
import Route from 'routes/CustomRoute';
import { History } from '../History';
import OldTransmission from '../Transmission/OldTransmission';
import Budgets from '../Budgets/Budgets';
import { IInspection } from '../Inspection/Inspection.types';

interface IDetailsRouterProps {
  inspection: IInspection;
}

function DetailsRouter({ inspection }: IDetailsRouterProps) {
  return (
    <Switch>
      <Route exact isPrivate path="/agendamentos/detalhes/:id" component={() => <History inspection={inspection} />} />
      <Route isPrivate path="/agendamentos/detalhes/:id/transmissao" component={() => <OldTransmission />} />
      <Route
        isPrivate
        path="/agendamentos/detalhes/:id/orcamentos"
        component={() => <Budgets inspection={inspection} />}
      />
    </Switch>
  );
}

export default React.memo(DetailsRouter, (prevProps, nextProps) => prevProps.inspection === nextProps.inspection);
