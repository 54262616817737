import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';

export const LoadingInputContainer = styled.div`
  .MuiCircularProgress-root {
    color: #000;
    width: 20px !important;
    height: 20px !important;
  }
`;

export const ContainerIllustration = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const Illustration = styled.img`
  height: 160px;
`;

export const TitleIllustration = styled.span`
  color: #6a758b;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
`;

export const SubtitleIllustration = styled.div`
  color: #6a758b;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  width: 260px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
  margin-top: 10px;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px 1px;
  overflow-y: auto;
`;

interface IWorkshopCardProps {
  isSelected: boolean;
}

export const WorkshopCard = styled.div<IWorkshopCardProps>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 20px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelected }) => (isSelected ? '#000' : '#ced6e5')};

  &:hover {
    border-color: #000;
    cursor: pointer;
  }
`;

export const WorkshopCardName = styled.span`
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
`;

export const WorkshopCardCNPJ = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
`;

export const Separator = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;
`;

export const FormContent = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 1px;
  overflow-y: auto;

  .MuiTextField-root {
    width: 100%;
  }
`;
