import React from 'react';
import clockIcon from 'assets/svg/clock.svg';
import { IBudgetLaborCost, IBudgetLaborCostTypeEnum } from 'commom/types/Budget.types';
import {
  BudgetContainer,
  ServicePricesHeader,
  Label,
  ServicePricesBody,
  TotalPrices,
  FlexColumn,
  ValueBlue,
  Flex,
  OrangeDot,
  RedDot,
  PercentageText,
  SemiBold,
  AlignCenter,
  BreakSpaces,
  RowServiceHeader,
  ValueServiceHeader,
  RowServiceBody,
  ValueServiceBody,
} from './BudgetServicesPrice.styles';
import { selectInspection } from 'stores/presentInspection/presentInspection.selector';
import { selectBudget } from 'stores/presentBudget/presentBudget.selector';
import { useAppSelector } from 'hooks/hooks';
import { priceFormatter, roundedPercentageFormat } from 'utils/formatPrice';

const BudgetServicesPrice = () => {
  const inspection = useAppSelector(selectInspection);
  const budget = useAppSelector(selectBudget);
  const paintingType = inspection.product?.vehicle?.paintingType?.toString() || IBudgetLaborCostTypeEnum.PAINTING;
  const discountCost = budget.labor.discount;
  const increaseCost = budget.labor.increase;

  const getCostPriceByType = (type: IBudgetLaborCostTypeEnum | string, budgetCosts: IBudgetLaborCost[]) => {
    const filteredCost = budgetCosts.find((cost) => cost.type === type);
    return filteredCost?.price || undefined;
  };

  const laborCost = getCostPriceByType(IBudgetLaborCostTypeEnum.LABOR, budget.labor.cost);
  const paintingCost = getCostPriceByType(paintingType, budget.labor.cost);
  const repairCost = getCostPriceByType(IBudgetLaborCostTypeEnum.REPAIR, budget.labor.cost);

  const formatDecimal = (price: number | undefined) => {
    return price ? price.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-';
  };

  return (
    <BudgetContainer>
      <ServicePricesHeader>
        <RowServiceHeader>
          <Label>Reparação</Label>
          <ValueServiceHeader>{repairCost ? `R$ ${repairCost}/h` : '-'}</ValueServiceHeader>
        </RowServiceHeader>

        <RowServiceHeader>
          <Label>Remoção & instalação</Label>
          <ValueServiceHeader>{laborCost ? `R$ ${laborCost}/h` : '-'}</ValueServiceHeader>
        </RowServiceHeader>

        <RowServiceHeader>
          <Label>Pintura {inspection.product?.vehicle?.paintingDescription}</Label>
          <ValueServiceHeader>{paintingCost ? `R$ ${paintingCost}/h` : '-'}</ValueServiceHeader>
        </RowServiceHeader>

        <RowServiceHeader>
          <BreakSpaces>Desconto</BreakSpaces>
          <ValueServiceHeader>{roundedPercentageFormat(discountCost)}</ValueServiceHeader>
        </RowServiceHeader>

        <RowServiceHeader>
          <BreakSpaces>Majoração</BreakSpaces>
          <ValueServiceHeader>{roundedPercentageFormat(increaseCost)}</ValueServiceHeader>
        </RowServiceHeader>
      </ServicePricesHeader>

      <ServicePricesBody>
        <TotalPrices>
          <FlexColumn>
            <Label>Total Mão de obra</Label>
            <ValueBlue>{priceFormatter.format(budget.summaryCost.services.total)}</ValueBlue>
          </FlexColumn>

          <FlexColumn>
            <Label>Horas Totais</Label>
            <Flex gap="3px">
              <img src={clockIcon} alt="clock" width="10" />
              <ValueServiceBody>{formatDecimal(budget.summaryCost.hours.total)}</ValueServiceBody>
            </Flex>
          </FlexColumn>
        </TotalPrices>

        <FlexColumn>
          <RowServiceBody>
            <AlignCenter>
              <OrangeDot />
              <PercentageText>
                Remoção & instalação ({roundedPercentageFormat(budget.summaryCost.percentages.replacement)})
              </PercentageText>
            </AlignCenter>
            <AlignCenter>
              <SemiBold>{priceFormatter.format(budget.summaryCost.services.replacement)}</SemiBold>
              <Flex gap="3px">
                <img src={clockIcon} alt="clock" width="8" />
                {formatDecimal(budget.summaryCost.hours.replacement)}
              </Flex>
            </AlignCenter>
          </RowServiceBody>

          <RowServiceBody>
            <AlignCenter>
              <RedDot />
              <PercentageText>
                Reparação ({roundedPercentageFormat(budget.summaryCost.percentages.repair)})
              </PercentageText>
            </AlignCenter>
            <AlignCenter>
              <SemiBold>{priceFormatter.format(budget.summaryCost.services.repair)}</SemiBold>
              <Flex gap="3px">
                <img src={clockIcon} alt="clock" width="8" />
                {formatDecimal(budget.summaryCost.hours.repair)}
              </Flex>
            </AlignCenter>
          </RowServiceBody>

          <RowServiceBody>
            <AlignCenter>
              <RedDot />
              <PercentageText>
                Pintura ({roundedPercentageFormat(budget.summaryCost.percentages.painting)})
              </PercentageText>
            </AlignCenter>
            <AlignCenter>
              <SemiBold>{priceFormatter.format(budget.summaryCost.services.painting)}</SemiBold>
              <Flex gap="3px">
                <img src={clockIcon} alt="clock" width="8" />
                {formatDecimal(budget.summaryCost.hours.painting)}
              </Flex>
            </AlignCenter>
          </RowServiceBody>

          <RowServiceBody>
            <AlignCenter>
              <RedDot />
              <PercentageText>
                Outros serviços ({roundedPercentageFormat(budget.summaryCost.percentages.outsourcedServices)})
              </PercentageText>
            </AlignCenter>
            <AlignCenter>
              <SemiBold>{priceFormatter.format(budget.summaryCost.services.outsourcedServices)}</SemiBold>
            </AlignCenter>
          </RowServiceBody>
        </FlexColumn>
      </ServicePricesBody>
    </BudgetContainer>
  );
};

export default BudgetServicesPrice;
