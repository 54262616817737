import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  Container,
  Header,
  RecoverableVehicleContent,
  InformationContent,
  Subtitle,
  CheckBoxContainer,
  Description,
} from './GeneralEvaluationSkeleton.styles';

const GeneralEvaluationSkeleton = () => {
  return (
    <Container>
      <Header>
        <Skeleton variant="text" width={150} height={30} />
        <RecoverableVehicleContent>
          <Skeleton variant="rectangular" width="100%" height={40} />
        </RecoverableVehicleContent>
      </Header>

      <InformationContent>
        <Skeleton variant="text" width={100} height={30} />
        <Subtitle>
          <Skeleton variant="text" width={100} height={20} />
        </Subtitle>
        <Skeleton variant="rectangular" width="100%" height={40} />

        <CheckBoxContainer>
          <Skeleton variant="circular" width={24} height={24} />
          <Description>
            <Skeleton variant="text" width={150} height={20} />
          </Description>
        </CheckBoxContainer>
      </InformationContent>

      <InformationContent>
        <Skeleton variant="text" width={200} height={30} />
        <Subtitle>
          <Skeleton variant="text" width={100} height={20} />
        </Subtitle>
        <Skeleton variant="rectangular" width="100%" height={100} />
      </InformationContent>
    </Container>
  );
};

export default GeneralEvaluationSkeleton;
