import ApiService from 'services/ApiService';
import {
  IGraphicData,
  IMetricsIsOutOfLimitsResponse,
  IMetricsEfficiency,
  IMetricsService,
  IMetricsStatus,
  IMetricsStatusFilterResponse,
  IMetricsTimeFilterResponse,
  ICanceledInspectionChartsResponse,
  IFiltersRequest,
  IFinishedInspectionChartsResponse,
  ITopTenRegulatorsMetricsResponse,
  IGeneralMetricsDetailsRequest,
  IGeneralMetricsDetailsFilteredInspection,
  IGeneralMetricsResponse,
  IChartsDetailsCancelledRequest,
  IChartsDetailsFinishedRequest,
  IChartsDetailsResponse,
  IChartsDetailsExpiredRequest,
  ITopTenRegulatorsMetricsParams,
  IRegulatorsGeneralDetailsMetricsParams,
  IRegulatorsGeneralDetails,
  IRegulatorsGeneralDetailsMetricsCSVParams,
  IRegulatorsGeneralDetailsCSVResponse,
} from './metricsService.types';

const MetricsService: IMetricsService = {
  searchMetricsStatus: async ({ companiesId, createdAtGreaterThan, createdAtLessThan }) => {
    const { data } = await ApiService.get<IMetricsStatusFilterResponse>('/metrics/count-by-status', {
      params: {
        companiesId: companiesId.join(','),
        createdAtGreaterThan,
        createdAtLessThan,
      },
    });

    return data;
  },

  searchMetricsTime: async ({ companiesId, createdAtGreaterThan, createdAtLessThan }) => {
    const { data } = await ApiService.get<IMetricsTimeFilterResponse>('/metrics/cicletime', {
      params: {
        companiesId: companiesId.join(','),
        createdAtGreaterThan,
        createdAtLessThan,
      },
    });

    return data;
  },

  searchMetricsEfficiency: async ({ companiesId, createdAtGreaterThan, createdAtLessThan, endProccessStatus }) => {
    const { data } = await ApiService.get<IMetricsEfficiency>('/metrics/efficiency', {
      params: {
        companiesId: companiesId.join(','),
        createdAtGreaterThan,
        createdAtLessThan,
        efficiencyStatus: endProccessStatus,
      },
    });

    return data;
  },

  getGraphicSubtitleElement: (name: string, graphicSubtitles: IMetricsStatus[]) => {
    const subtitle = graphicSubtitles.find((graphicSubtitle) => graphicSubtitle.name === name);

    return subtitle || { name: 'Não encontrado', value: 0 };
  },

  generateGraphicData: (graphicSubtitles: IMetricsStatus[] | undefined) => {
    if (graphicSubtitles) {
      const linkSent = MetricsService.getGraphicSubtitleElement('Link enviado', graphicSubtitles);
      const photosReceived = MetricsService.getGraphicSubtitleElement('Fotos recebidas', graphicSubtitles);
      const inspectionProgress = MetricsService.getGraphicSubtitleElement('Realizando atendimento', graphicSubtitles);
      const inspectionFinish = MetricsService.getGraphicSubtitleElement('Atendimento finalizado', graphicSubtitles);
      const inspectionCanceled = MetricsService.getGraphicSubtitleElement('Atendimento cancelado', graphicSubtitles);
      const inspectionExpired = MetricsService.getGraphicSubtitleElement('Atendimento expirado', graphicSubtitles);

      return [
        {
          id: linkSent.name,
          label: linkSent.name,
          value: linkSent.value,
          color: '#0E7BC4',
        },
        {
          id: photosReceived.name,
          label: photosReceived.name,
          value: photosReceived.value,
          color: '#61C3FA',
        },
        {
          id: inspectionProgress.name,
          label: inspectionProgress.name,
          value: inspectionProgress.value,
          color: '#EDB72B',
        },
        {
          id: inspectionFinish.name,
          label: inspectionFinish.name,
          value: inspectionFinish.value,
          color: '#099857',
        },
        {
          id: inspectionCanceled.name,
          label: inspectionCanceled.name,
          value: inspectionCanceled.value,
          color: '#E71C40',
        },
        {
          id: inspectionExpired.name,
          label: inspectionExpired.name,
          value: inspectionExpired.value,
          color: '#F77228',
        },
      ];
    }

    return [];
  },

  getCanceledInspectionsChart: async (request: IFiltersRequest) => {
    const { data } = await ApiService.get<ICanceledInspectionChartsResponse>(
      'v2/metrics/cancelled-inspections/metric-summary',
      {
        params: {
          companyIds: request.companyIds.join(','),
          createdAtGreaterThan: request.createdAtGreaterThan,
          createdAtLessThan: request.createdAtLessThan,
          inspectionCategoryIds: request.inspectionCategoryIds.join(','),
          inspectionProductTypeIds: request.inspectionProductTypeIds.join(','),
          regulatorIds: request.regulatorIds.join(','),
        },
      }
    );

    return data.metricSummaries;
  },

  getFinishedInspectionsChart: async (request: IFiltersRequest) => {
    const { data } = await ApiService.get<IFinishedInspectionChartsResponse>(
      'v2/metrics/finished-inspections/metric-summary',
      {
        params: {
          companyIds: request.companyIds.join(','),
          createdAtGreaterThan: request.createdAtGreaterThan,
          createdAtLessThan: request.createdAtLessThan,
          inspectionCategoryIds: request.inspectionCategoryIds?.join(','),
          inspectionProductTypeIds: request.inspectionProductTypeIds?.join(','),
          regulatorIds: request.regulatorIds.join(','),
        },
      }
    );
    return data.metricSummaries;
  },
  getCanceledInspectionsChartDetails: async (request: IChartsDetailsCancelledRequest) => {
    const { data, headers } = await ApiService.get<IChartsDetailsResponse>('v2/metrics/cancelled-inspections', {
      params: {
        companyIds: request.companyIds.join(','),
        createdAtGreaterThan: request.createdAtGreaterThan,
        createdAtLessThan: request.createdAtLessThan,
        inspectionCategoryIds: request.inspectionCategoryIds.join(','),
        inspectionProductTypeIds: request.inspectionProductTypeIds.join(','),
        regulatorIds: request.regulatorIds.join(','),
        page: request.page,
        size: request.size,
        cancellationMotiveIds: request.cancellationMotiveIds.join(','),
      },
    });

    data.totalCount = Number(headers['x-total-count']);
    data.totalPages = Number(headers['x-total-pages']);
    return data;
  },
  getFinishedInspectionsChartDetails: async (request: IChartsDetailsFinishedRequest) => {
    const { data, headers } = await ApiService.get<IChartsDetailsResponse>('v2/metrics/finished-inspections', {
      params: {
        companyIds: request.companyIds.join(','),
        createdAtGreaterThan: request.createdAtGreaterThan,
        createdAtLessThan: request.createdAtLessThan,
        inspectionCategoryIds: request.inspectionCategoryIds.join(','),
        inspectionProductTypeIds: request.inspectionProductTypeIds.join(','),
        regulatorIds: request.regulatorIds.join(','),
        page: request.page,
        size: request.size,
        inspectionFinishedTimes: request.inspectionFinishedTimes,
      },
    });

    data.totalCount = Number(headers['x-total-count']);
    data.totalPages = Number(headers['x-total-pages']);

    return data;
  },
  getExpiredInspectionsChartDetails: async (request: IChartsDetailsExpiredRequest) => {
    const { data, headers } = await ApiService.get<IChartsDetailsResponse>('v2/metrics/expired-inspections', {
      params: {
        companyIds: request.companyIds.join(','),
        createdAtGreaterThan: request.createdAtGreaterThan,
        createdAtLessThan: request.createdAtLessThan,
        inspectionCategoryIds: request.inspectionCategoryIds.join(','),
        inspectionProductTypeIds: request.inspectionProductTypeIds.join(','),
        regulatorIds: request.regulatorIds.join(','),
        page: request.page,
        size: request.size,
      },
    });

    data.totalCount = Number(headers['x-total-count']);
    data.totalPages = Number(headers['x-total-pages']);

    return data;
  },

  removeZeroFromGraphicData: (graphicData: IGraphicData[]) => {
    return graphicData.filter((data) => data.value > 0);
  },

  saveUserCycleTimeLimit: async (cycleTime) => {
    await ApiService.put<void>('/metrics/cycle-time-limit/user', cycleTime);
  },

  saveRegulationCycleTimeLimit: async (cycleTime) => {
    await ApiService.put<void>('/metrics/cycle-time-limit/regulation', cycleTime);
  },

  saveProcessCycleTimeLimit: async (cycleTime) => {
    await ApiService.put<void>('/metrics/cycle-time-limit/process', cycleTime);
  },

  isCycleTimeOutOfLimit: async (cycleTimeLimit) => {
    const { data } = await ApiService.get<IMetricsIsOutOfLimitsResponse>('/metrics/is-out-of-limit', {
      params: cycleTimeLimit,
    });

    return data;
  },

  getGeneralMetrics: async (request: IFiltersRequest) => {
    const { data } = await ApiService.get<IGeneralMetricsResponse>('v2/metrics/inspections/group-by-status', {
      params: {
        companyIds: request.companyIds.join(','),
        createdAtGreaterThan: request.createdAtGreaterThan,
        createdAtLessThan: request.createdAtLessThan,
        inspectionCategoryIds: request.inspectionCategoryIds.join(','),
        inspectionProductTypeIds: request.inspectionProductTypeIds.join(','),
        regulatorIds: request.regulatorIds.join(','),
      },
    });
    const totalCount = data.inProgressCount + data.cancelledCount + data.notStartedCount + data.finishedCount;
    return { ...data, totalCount };
  },

  getGeneralMetricsDetails: async (request: IGeneralMetricsDetailsRequest) => {
    const { data, headers } = await ApiService.get('v2/metrics/inspections', {
      params: {
        companyIds: request.companyIds.join(','),
        createdAtGreaterThan: request.createdAtGreaterThan,
        createdAtLessThan: request.createdAtLessThan,
        inspectionCategoryIds: request.inspectionCategoryIds.join(','),
        inspectionProductTypeIds: request.inspectionProductTypeIds.join(','),
        regulatorIds: request.regulatorIds.join(','),
        page: request.page,
        size: request.size,
        filter: request.filter,
      },
    });
    return {
      inspections: data.inspections as IGeneralMetricsDetailsFilteredInspection[],
      totalCount: Number(headers['x-total-count']),
      totalPages: Number(headers['x-total-pages']),
    };
  },

  getTopTenRegulatorsMetrics: async (request) => {
    const params: ITopTenRegulatorsMetricsParams = {
      clientId: request.clientId,
      sortParam: request.sortParam,
      isAsc: request.isAsc,
      createdAtGreaterThan: request.startDateSelected,
      createdAtLessThan: request.endDateSelected,
      ...(request.categoriesSelected.length > 0 && { inspectionCategoryIds: request.categoriesSelected.join(',') }),
      ...(request.companiesSelected.length > 0 && { companyIds: request.companiesSelected.join(',') }),
      ...(request.productTypesSelected.length > 0 && { productTypeIds: request.productTypesSelected.join(',') }),
      ...(request.regulatorSelected && { accountId: request.regulatorSelected.value }),
    };

    const { data } = await ApiService.get<ITopTenRegulatorsMetricsResponse>('/v2/metrics/regulators/sorted', {
      params,
    });

    return data;
  },

  getRegulatorsGeneralDetailsMetrics: async (filter) => {
    const params: IRegulatorsGeneralDetailsMetricsParams = {
      clientId: filter.clientId,
      createdAtGreaterThan: filter.startDateSelected,
      createdAtLessThan: filter.endDateSelected,
      page: filter.page,
      size: 7,
      ...(filter.categoriesSelected.length > 0 && { inspectionCategoryIds: filter.categoriesSelected.join(',') }),
      ...(filter.companiesSelected.length > 0 && { companyIds: filter.companiesSelected.join(',') }),
      ...(filter.productTypesSelected.length > 0 && { productTypeIds: filter.productTypesSelected.join(',') }),
      ...(filter.regulatorSelected && { accountIds: filter.regulatorSelected.value }),
    };

    const { data, headers } = await ApiService.get('/v2/metrics/regulators', {
      params,
    });

    return {
      regulatorsMetrics: data.regulatorsMetrics as IRegulatorsGeneralDetails[],
      totalCount: Number(headers['x-total-count']),
      totalPages: Number(headers['x-total-pages']),
    };
  },

  getRegulatorsGeneralDetailsMetricsCSV: async (filter) => {
    const params: IRegulatorsGeneralDetailsMetricsCSVParams = {
      clientId: filter.clientId,
      createdAtGreaterThan: filter.startDateSelected,
      createdAtLessThan: filter.endDateSelected,
      ...(filter.categoriesSelected.length > 0 && { inspectionCategoryIds: filter.categoriesSelected.join(',') }),
      ...(filter.companiesSelected.length > 0 && { companyIds: filter.companiesSelected.join(',') }),
      ...(filter.productTypesSelected.length > 0 && { productTypeIds: filter.productTypesSelected.join(',') }),
      ...(filter.regulatorSelected && { accountIds: filter.regulatorSelected.value }),
    };

    const { data } = await ApiService.get<IRegulatorsGeneralDetailsCSVResponse>('/v2/metrics/regulators', {
      params,
    });

    return data;
  },
};

export default MetricsService;
