import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'components';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';

interface IFinishInspectionActionProps {
  inspection: IInspection;
}

const FinishInspectionAction = ({ inspection }: IFinishInspectionActionProps) => {
  const { id: inspectionId, currentStatus, conclusion, companyId } = inspection;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isConsumerActive } = useConsumer();
  const { isAdmin, isRegulator, isAnalyst } = useAuthDispatch();
  const { invalidateInspectionParallel } = useMutateInspection();

  const isAdminOrAnalyst = isAdmin() || isAnalyst();
  const isValidUser = isRegulator() || isAnalyst() || isAdmin();
  const hasAnalystConsumer = isConsumerActive(ConsumersEnum.INSPECTION_ANALYST, companyId || '');
  const hasBudgetAccess = isConsumerActive(ConsumersEnum.BUDGETS_ACCESS, companyId || '');

  const isInspectionStarted = InspectionStatusService.isInspectionStarted(currentStatus);

  const hasConclusionConsumer = isConsumerActive(ConsumersEnum.INSPECTION_CONCLUSION, companyId || '');

  const inspectionHasNotConclusion = hasConclusionConsumer && !conclusion;

  const showFinishInspection = (hasAnalystConsumer ? isAdminOrAnalyst : isValidUser) && isInspectionStarted;

  const renderDialogText = (): string =>
    hasBudgetAccess
      ? 'Ao confirmar, você não poderá mais alterar orçamento.'
      : 'Ao confirmar, você não poderá mais alterar o atendimento.';

  const finishInspection = async (): Promise<void> => {
    try {
      if (!inspectionId) return;
      await InspectionStatusService.finished(inspectionId);
    } catch (error) {
      toast.error('Ocorreu um erro ao finalizar o atendimento, tente novamente.');
    }
  };

  const handleFinish = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await finishInspection();
      await invalidateInspectionParallel(inspectionId);
    } catch (error) {
      toast.error('Ocorreu um erro inesperado, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setShowConfirmDialog(true);
  };

  return showFinishInspection ? (
    <>
      <Button
        type="button"
        testID="finish-admin-button"
        size="small"
        text="FINALIZAR ATENDIMENTO"
        onClick={handleSubmit}
        loading={isLoading}
        disabled={isLoading || inspectionHasNotConclusion}
      />

      {showConfirmDialog && (
        <ConfirmDialog
          dialogVisibility={showConfirmDialog}
          handleClose={() => setShowConfirmDialog(false)}
          testID="confirm-dialog"
          onSubmit={handleFinish}
          title="Deseja realmente finalizar o atendimento?"
          subtitle={renderDialogText()}
        />
      )}
    </>
  ) : null;
};

export default FinishInspectionAction;
