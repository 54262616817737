import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid #00000017;
  justify-content: space-between;
  page-break-inside: avoid;

  &.empty {
    padding-bottom: 0;
  }
`;

export const IconTitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #282828;
  margin: 0;
`;

export const QuantityContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const NormalText = styled.h1`
  font-size: 20px;
  font-weight: 400;
  color: #282828;
  margin: 0;
`;

export const SemiBoldText = styled.div`
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`;

export const PartsTable = styled.div`
  padding: 10px 15px;
`;

export const EmptyPartsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  padding: 10px;
  color: grey;
`;
