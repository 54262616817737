import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Container, Header, QuestionList } from './QuestionGroupSkeleton.styles';

const QuestionGroupSkeleton = () => {
  return (
    <Container>
      <Header>
        <Skeleton variant="text" width={200} height={30} />
        <Skeleton variant="rectangular" width={100} height={30} />
      </Header>

      <QuestionList>
        {Array.from({ length: 15 }).map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height={40} />
        ))}
      </QuestionList>
    </Container>
  );
};

export default QuestionGroupSkeleton;
