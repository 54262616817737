import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/CloseRounded';
import DrawerRoot from '../DrawerRoot';
import { Divider as DividerMui } from '@mui/material';
import DrawerContent from '../DrawerContent';

export const Container = styled(DrawerRoot)``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1e293b;
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  color: #64748b;
`;

export const Content = styled(DrawerContent)`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
`;

export const Separator = styled(DividerMui)`
  border-color: #ced6e5 !important;
`;

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 5px;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const MessageError = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-top: 10px;
`;

export const Footer = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
