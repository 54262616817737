import { IClient } from 'services/ApiService/AccountService/types';

export interface IInvoice {
  id: string;
  client: IClient;
  clientId: string;
  price: number;
  expensesCount: number;
  createdAt: string;
  startDate: string;
  endDate: string;
  status: IInvoiceStatus[];
}

export interface IInvoiceStatus {
  id: string;
  status: InvoiceStatusEnum;
  description: string;
}

export enum InvoiceStatusEnum {
  NOT_PAID = 'NOT_PAID',
  CREATED = 'CREATED',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID',
}

export enum InvoiceExpenseTypesEnum {
  PHOTOS_RECEIVED = 'PHOTOS_RECEIVED',
  BUDGET = 'BUDGET',
}

export interface IInvoiceExpense {
  companyName: string;
  type: InvoiceExpenseTypesEnum;
  price: number;
  inspectionCode: string;
  responsibleName: string;
  startDate: string;
}

export interface IInvoicePaid {
  paymentDate: string;
  receipt: IReceiptInvoice;
}

export interface IReceiptInvoice {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  paymentDate: string;
  path: string;
  name: string;
  invoiceId: string;
  url?: string;
}

export interface IInvoiceWithExpenses extends Omit<IInvoice, 'clientId' | 'createdAt'> {
  invoiceExpenses: IInvoiceExpense[];
  receipt?: IReceiptInvoice;
}
