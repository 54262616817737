import { ReactFragment } from 'react';

export interface IConfirmDialogProps {
  handleClose: () => void;
  dialogVisibility: boolean;
  testID?: string;
  onSubmit: () => void;
  title: string | ReactFragment;
  subtitle: string | ReactFragment;
  subtitleColor?: string;
  confirmText?: string;
  cancelText?: string;
}

export enum SubtitleColor {
  DARK_RED = '#D32F2F',
  BLACK = '#000000',
}
