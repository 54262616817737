import { ICompanySelectOptions, IOptions } from 'components/Select/Select.types';
import { ICategoryNameEnum, IInspectionCategoryService } from './inspectionCategoryService.types';

const InspectionCategoryService: IInspectionCategoryService = {
  inspectionCategoryIsLocally: (categoriesList: IOptions[], categorySelected: string) => {
    const inspectionCategory = categoriesList.find((item) => item.value === categorySelected);
    return !!(inspectionCategory !== undefined && inspectionCategory.name === ICategoryNameEnum.LOCALLY);
  },

  companyInspectionCategoryIsLocally: (companySelected: ICompanySelectOptions) => {
    return (
      companySelected.inspectionCategory !== undefined &&
      companySelected.inspectionCategory.length === 1 &&
      companySelected.inspectionCategory[0].name === ICategoryNameEnum.LOCALLY
    );
  },
};

export default InspectionCategoryService;
