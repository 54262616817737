/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';

export const useTranslate = () => {
  const { t } = useTranslation();

  const translate = (value: string, options?: any) => {
    return t(value, options) as string;
  };

  return {
    translate,
  };
};
