import { DonutChart } from 'components';
import React, { useEffect, useState } from 'react';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import {
  ICanceledInspectionCharts,
  IFinishedInspectionCharts,
  IFiltersRequest,
} from 'services/ApiService/MetricsService/metricsService.types';
import { toast } from 'react-toastify';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import { useHistory } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Container,
  ContainerCard,
  ContainerChart,
  ContainerPercentages,
  ContainerTitle,
  ShowDetails,
  Title,
} from './Charts.styles';
import { inspectionStatusCanceled, inspectionStatusFinished } from './Charts.types';
import { ChartsSkeletonLoading } from './ChartsSkeletonLoading';

export function Charts() {
  const {
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    startDateSelected,
    productTypesSelected,
    regulatorSelected,
  } = useMetricsFilter();

  const [cancelledInspectionsData, setCancelledInspectionsData] = useState<ICanceledInspectionCharts[]>([]);
  const [finishedInspectionsData, setFinishedInspectionsData] = useState<IFinishedInspectionCharts[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const ShowDetailsCanceledInspections = cancelledInspectionsData?.length > 0;
  const ShowDetailsFinishedInspections = finishedInspectionsData?.length > 0;
  const formatStartDateSelected = startDateSelected || '';
  const formatEndDateSelected = endDateSelected || '';

  const history = useHistory();

  const getInspections = async (filters: IFiltersRequest) => {
    setLoading(true);
    try {
      const requestCanceledInspections = MetricsService.getCanceledInspectionsChart(filters);
      const requestFinishedInspections = MetricsService.getFinishedInspectionsChart(filters);

      const [responseCanceledInspections, responseFinishedInspections] = await Promise.all([
        requestCanceledInspections,
        requestFinishedInspections,
      ]);

      setCancelledInspectionsData(responseCanceledInspections);
      setFinishedInspectionsData(responseFinishedInspections);
    } catch (error) {
      setCancelledInspectionsData([]);
      setFinishedInspectionsData([]);
      toast.error('Falha ao carregar as informações para os gráficos de atendimentos');
    }
    setLoading(false);
  };

  const formatPercentage = (value: string) => {
    const percentageFormatted = value.replace('.', ',');
    return `(${percentageFormatted}%)`;
  };

  const getColorCanceledInspection = () => {
    return cancelledInspectionsData?.map(
      (element: ICanceledInspectionCharts) => inspectionStatusCanceled[element.code]?.color
    );
  };

  const getColorFinishedInspection = () => {
    return finishedInspectionsData?.map(
      (element: IFinishedInspectionCharts) => inspectionStatusFinished[element.code]?.color
    );
  };

  const getLabelsCancelled = () => {
    return cancelledInspectionsData?.map(
      (element: ICanceledInspectionCharts) => inspectionStatusCanceled[element.code]?.label
    );
  };

  const getLabelsFinished = () => {
    return finishedInspectionsData?.map(
      (element: IFinishedInspectionCharts) => inspectionStatusFinished[element.code]?.label
    );
  };

  const getSeriesChart = (chartData: ICanceledInspectionCharts[] | IFinishedInspectionCharts[]) => {
    return chartData?.map((item) => item.count);
  };

  const renderChartPercentages = (chartData: ICanceledInspectionCharts[] | IFinishedInspectionCharts[]) => {
    return (
      <ContainerPercentages>
        {chartData?.map((item) => (
          <p key={item.name}>{`${item.count} ${formatPercentage(String(item.percentage))}`}</p>
        ))}
      </ContainerPercentages>
    );
  };

  const goToDetails = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    getInspections({
      companyIds: companiesSelected || [],
      createdAtGreaterThan: formatStartDateSelected,
      createdAtLessThan: formatEndDateSelected,
      inspectionCategoryIds: categoriesSelected || [],
      inspectionProductTypeIds: productTypesSelected || [],
      regulatorIds: [regulatorSelected?.value || ''],
    });
  }, [
    companiesSelected,
    formatStartDateSelected,
    formatEndDateSelected,
    categoriesSelected,
    productTypesSelected,
    regulatorSelected,
  ]);

  return (
    <>
      {!loading ? (
        <Container>
          <ContainerCard>
            <ContainerTitle>
              <Title>Atendimentos cancelados</Title>
              {ShowDetailsCanceledInspections && (
                <ShowDetails
                  data-testid="show-details-cancelled-inspections"
                  onClick={() => goToDetails('/metrics-chart-cancelled-details')}
                >
                  <RemoveRedEyeIcon sx={{ width: '14px' }} />
                  Ver detalhes
                </ShowDetails>
              )}
            </ContainerTitle>
            <ContainerChart>
              <DonutChart
                series={getSeriesChart(cancelledInspectionsData)}
                labels={getLabelsCancelled()}
                colors={getColorCanceledInspection()}
                width={440}
                height={170}
                offsetXPie={-29}
                offsetXLegend={-5}
                offsetYLegend={-25}
                testId="donut-chart-1"
              />

              <ContainerPercentages className="cancelled">
                {renderChartPercentages(cancelledInspectionsData)}
              </ContainerPercentages>
            </ContainerChart>
          </ContainerCard>

          <ContainerCard>
            <ContainerTitle>
              <Title>Atendimentos finalizados</Title>

              {ShowDetailsFinishedInspections && (
                <ShowDetails
                  data-testid="show-details-finish-inspections"
                  onClick={() => goToDetails('/metrics-chart-finished-details')}
                >
                  <RemoveRedEyeIcon sx={{ width: '14px' }} />
                  Ver detalhes
                </ShowDetails>
              )}
            </ContainerTitle>
            <ContainerChart>
              <DonutChart
                series={getSeriesChart(finishedInspectionsData)}
                labels={getLabelsFinished()}
                colors={getColorFinishedInspection()}
                width={440}
                height={170}
                offsetXPie={-73}
                offsetXLegend={30}
                offsetYLegend={25}
                testId="donut-chart-2"
              />
              <ContainerPercentages className="finished">
                {renderChartPercentages(finishedInspectionsData)}
              </ContainerPercentages>
            </ContainerChart>
          </ContainerCard>
        </Container>
      ) : (
        <Container>
          <ContainerCard>
            <ChartsSkeletonLoading />
          </ContainerCard>

          <ContainerCard>
            <ChartsSkeletonLoading />
          </ContainerCard>
        </Container>
      )}
    </>
  );
}
