import ErrorNotFound from 'errors/ErrorNotFound';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AccountService from 'services/ApiService/AccountService';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { isAxiosError } from 'utils/isAxiosError';

const ITEMS_PER_PAGE = 10;

export const useFilterInspections = (
  query: string,
  companiesIdSelected: string[] = [],
  statusIds: string[] = [],
  startDateSchedule: string,
  finishDateSchedule: string
) => {
  const { data, error, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
    ['get-inspections', { query, companiesIdSelected, statusIds, startDateSchedule, finishDateSchedule }],
    ({ pageParam = 0 }) =>
      InspectionService.getInspectionsByFilter(
        getPaginationURL(pageParam, query, companiesIdSelected, statusIds, startDateSchedule, finishDateSchedule)
      ),
    {
      getNextPageParam: (lastPage) => {
        const currentPage = parseInt(lastPage.headers['x-current-page'], 10);
        const nextPage = parseInt(lastPage.headers['x-has-next-page'], 10);

        return nextPage ? currentPage + 1 : undefined;
      },
      onError: (err) => {
        if (!(err instanceof ErrorNotFound)) {
          toast.error('Aconteceu algo ao pesquisar, tente novamente.');
        }
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: !!companiesIdSelected.length && !!statusIds.length,
    }
  );

  return {
    inspectionsData: data,
    inspectionsNotFound: error && error instanceof ErrorNotFound,
    inspectionsError: error,
    isLoading,
    hasNextPage: hasNextPage || false,
    isFetchingNextPage,
    fetchNextPage,
  };
};

type PageName = 'DETAILS' | 'TRANSMISSION' | 'BUDGET_STEP_REPORT';

export const useFilterInspectionById = (id: string, pageName: PageName) => {
  const { data, error, isLoading } = useQuery(
    ['get-inspection-by-id', { id, pageName }],
    () => InspectionService.getInspectionsById(id),
    {
      onError: (err) => {
        showToastError(err);
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    inspectionData: data?.inspection,
    isError: error,
    isLoadingInspection: isLoading,
  };
};

export const useMutateInspection = () => {
  const queryClient = useQueryClient();

  const invalidateInspectionSequential = async (inspectionId: string) => {
    await queryClient.invalidateQueries({ queryKey: ['get-inspections'] });
    await queryClient.invalidateQueries({ queryKey: ['get-inspection-by-id', { id: inspectionId }] });
  };

  const invalidateInspectionParallel = async (inspectionId: string) => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: ['get-inspection-by-id', { id: inspectionId }],
      }),

      queryClient.invalidateQueries({ queryKey: ['get-inspections'] }),
    ]);
  };

  return {
    invalidateInspectionParallel,
    invalidateInspectionSequential,
  };
};

const getPaginationURL = (
  pageIndex: number,
  query: string,
  companiesIdSelected: string[],
  statusIds: string[],
  startDateSchedule?: string,
  finishDateSchedule?: string
): string => {
  return `/inspections/filter?query=${encodeURI(
    query
  )}&page=${pageIndex}&size=${ITEMS_PER_PAGE}&companiesId=${companiesIdSelected.join(',')}&statusIds=${statusIds.join(
    ','
  )}&startDateSchedule=${startDateSchedule}&finishDateSchedule=${finishDateSchedule}`;
};

const showToastError = (error: unknown) => {
  const { clientId } = AccountService.getActiveProfileInLocalStorage();
  const accountGroup = AccountService.getClientInAccountGroupInLocalStorageByClientId(clientId);

  const haveClientMessage = accountGroup ? `ou esta associada a ${accountGroup.client.name}` : '';

  if (isAxiosError(error)) {
    if (error.response?.status === 404) {
      toast.warning(`Verifique se o ID da vistoria está correto ${haveClientMessage} e tente novamente.`);
    }
  } else {
    toast.error('Ocorreu um erro interno.');
  }
};
