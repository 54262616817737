/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AccountService from 'services/ApiService/AccountService';

interface IActiveProfile {
  clientId: string;
}

const ACTIVE_PROFILE = '@ACTIVE_PROFILE';
const initialState: IActiveProfile = AccountService.getActiveProfileInLocalStorage();

export const activeProfileSlice = createSlice({
  name: 'activeProfile',
  initialState,
  reducers: {
    updateActiveProfile(state, action: PayloadAction<string>) {
      const { payload } = action;

      state.clientId = payload;

      AccountService.updateLocalStorage(ACTIVE_PROFILE, { clientId: payload });
    },
  },
});

export const { updateActiveProfile } = activeProfileSlice.actions;
export default activeProfileSlice.reducer;
