import { Button as ButtonComponent } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

interface IButtonTextProps {
  onlyIcon: boolean;
  startIcon: boolean;
  endIcon: boolean;
}

export const ButtonText = styled.span<IButtonTextProps>`
  display: ${(props): string => (props.onlyIcon ? 'none' : 'flex')};
  margin-left: ${(props): string => (props.startIcon ? '8px' : '0')};
  margin-right: ${(props): string => (props.endIcon ? '8px' : '0')};
  font-weight: 700;
`;
export const Button = withStyles({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  outlined: {
    height: 39,
    color: '#000',
    borderWidth: '2px',
    borderColor: '#000000',
    '&:hover': {
      borderWidth: '2px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderColor: '#000',
    },
    textTransform: (props) =>
      props['button-shape'] === 'true' ? 'capitalize' : props.capitalize === 'true' ? 'capitalize' : 'uppercase',
    '&:hover span': {
      display: 'flex !important',
    },
  },
  contained: {
    height: 39,
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#000',
      cursor: (props) => (props['button-shape'] === 'true' ? 'default' : 'pointer'),
    },
    textTransform: (props) =>
      props['button-shape'] === 'true' ? 'capitalize' : props.capitalize === 'true' ? 'capitalize' : 'uppercase',
  },
})(ButtonComponent);
