import React from 'react';
import { Header } from './Drawer.styles';
import { IDrawerBaseProps } from './DrawerProps.types';

export default function DrawerHeader({ children, testId = 'drawer-header', className }: IDrawerBaseProps) {
  return (
    <Header data-testid={testId} className={className}>
      {children}
    </Header>
  );
}
