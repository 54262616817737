import { MenuItem } from '@mui/material';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import UploadDocumentsDialog from 'pages/SchedulerList/components/UploadDocumentsDialog/UploadDocumentsDialog';
import React, { useState } from 'react';

interface IUploadDocumentsMenuItemProps {
  inspection: IInspection;
}

const UploadDocumentsMenuItem = ({ inspection }: IUploadDocumentsMenuItemProps) => {
  const [isUpdateDocumentsDialogOpen, setIsUpdateDocumentsDialogOpen] = useState(false);
  const { isRegulator, isAdmin } = useAuthDispatch();

  const hasPermition = isRegulator() || isAdmin();

  const showByPermissionAndCurrentStatus =
    hasPermition &&
    (inspection.currentStatus?.type === StatusEnum.PHOTO_RECEIVED ||
      inspection.currentStatus?.type === StatusEnum.INSPECTION_STARTED);

  return (
    <>
      {showByPermissionAndCurrentStatus && (
        <MenuItem data-testid="upload-documents" onClick={() => setIsUpdateDocumentsDialogOpen(true)}>
          Enviar Documentos
        </MenuItem>
      )}

      <UploadDocumentsDialog
        dialogVisibility={isUpdateDocumentsDialogOpen}
        inspection={inspection}
        inspectionDocuments={inspection.files}
        handleClose={() => setIsUpdateDocumentsDialogOpen(false)}
      />
    </>
  );
};

export default UploadDocumentsMenuItem;
