import React from 'react';
import { Content } from './Drawer.styles';
import { IDrawerBaseProps } from './DrawerProps.types';

export default function DrawerContent({ children, testId = 'drawer-content', className }: IDrawerBaseProps) {
  return (
    <Content data-testid={testId} className={className}>
      {children}
    </Content>
  );
}
