import { useContext } from 'react';

import { IAuthDispatchContextData, IAuthStateContextData } from './types';

import { AuthDispatchContext, AuthStateContext } from './AuthProvider';

export const useAuthDispatch = (): IAuthDispatchContextData => {
  const context = useContext(AuthDispatchContext);

  if (!context) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
};

export const useAuthState = (): IAuthStateContextData => {
  const context = useContext(AuthStateContext);

  if (!context) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};
