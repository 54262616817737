import { Button } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  color: #fff;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const Message = styled.span`
  font-size: 14px;
`;

export const LinkButton = styled(Button)`
  color: #fff !important;
  background-color: var(--toastify-color-info) !important;
  width: 80px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
`;
