import React, { useCallback, useMemo } from 'react';
import { IBudgetReportQuestionTypeEnum } from 'commom/types/BudgetReport.types';
import { Label, TextField } from 'components';
import { IToggleButtonOption } from 'components/ToggleButton';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { debounce } from 'lodash';
import { setRescuedReportAnswerId, setRescuedReportAnswerText } from 'stores/presentBudget/presentBudget.store';
import { Container, Title, ToggleButtonContainer } from './QuestionInput.styles';
import { selectRescuedQuestionIdsUnanswered } from 'stores/presentBudget/presentBudget.selector';
import { answerStatus, IQuestionInputProps } from './QuestionInput.types';
import { selectRescuedReportIsDraft } from 'stores/presentBudget/presentBudget.selector';

const debouncedDispatch = debounce((dispatch, action) => {
  dispatch(action);
}, 500);

const QuestionInput = ({ questionGroupId, question }: IQuestionInputProps) => {
  const questionsWithoutAnswerId = useAppSelector(selectRescuedQuestionIdsUnanswered);
  const isDraftRescuedReport = useAppSelector(selectRescuedReportIsDraft);
  const dispatch = useAppDispatch();

  const isQuestionSelectAndInput = question.type === IBudgetReportQuestionTypeEnum.SELECT_AND_INPUT;

  const isNotAnswered = !!questionsWithoutAnswerId && !!questionsWithoutAnswerId.includes(question._id);

  const isAnswered = useMemo(() => {
    if (isQuestionSelectAndInput) {
      return !!question.textAnswer && !!question.answerId;
    }
    return !!question.answerId;
  }, [isQuestionSelectAndInput, question]);

  const getAnswerStatus = () => {
    if (isNotAnswered && !isAnswered) {
      return answerStatus.NOT_ANSWERED;
    }

    if (isAnswered) {
      return answerStatus.ANSWERED;
    }

    return answerStatus.INITIAL_STATE;
  };

  const toggleButtonOptions: IToggleButtonOption[] = useMemo(
    () =>
      question.options.map((option) => ({
        code: option._id,
        title: option.slug,
      })),
    [question.options]
  );

  const handleToggleButtonOnChange = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
      dispatch(setRescuedReportAnswerId({ questionGroupId, questionId: question._id, answerId: value }));
    },
    [dispatch, questionGroupId, question._id]
  );

  const handleQuestionInputOnChange = useCallback(
    (value: string) => {
      debouncedDispatch(
        dispatch,
        setRescuedReportAnswerText({ questionGroupId, questionId: question._id, answerText: value })
      );
    },
    [dispatch, questionGroupId, question._id]
  );

  const renderInput = () => (
    <div>
      <Label htmlFor={question.text}>{question.text}</Label>
      <TextField
        type="text"
        variant="outlined"
        placeholder="Insira a marca"
        id={question.text}
        value={question.textAnswer || ''}
        inputProps={{ 'data-testid': 'input-text' }}
        onChange={({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>): void =>
          handleQuestionInputOnChange(value)
        }
        disabled={!isDraftRescuedReport}
      />
    </div>
  );

  return (
    <Container isQuestionSelectAndInput={isQuestionSelectAndInput} answerStatus={getAnswerStatus()}>
      {isQuestionSelectAndInput ? renderInput() : <Title>{question.text}</Title>}

      <ToggleButtonContainer>
        <ToggleButton
          size="small"
          options={toggleButtonOptions}
          value={question.answerId}
          onChange={handleToggleButtonOnChange}
          disabled={!isDraftRescuedReport}
        />
      </ToggleButtonContainer>
    </Container>
  );
};

export default QuestionInput;
