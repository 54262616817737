import React from 'react';
import { Switch } from 'react-router-dom';
import Route from 'routes/CustomRoute';
import { Details } from './components/Details';
import { SchedulerRouterProps } from './SchedulerList.types';
import InspectionDetails from './components/InspectionDetails/InspectionDetails';

const SchedulerListRouter: React.FC<SchedulerRouterProps> = ({ inspectionId }) => (
  <Switch>
    <Route
      path="/agendamentos/detalhes/:id"
      component={(): JSX.Element => <Details inspectionId={inspectionId} />}
      isPrivate
    />
    <Route
      path="/agendamentos/v2/detalhes/:id"
      component={(): JSX.Element => <InspectionDetails inspectionId={inspectionId} />}
      isPrivate
    />
  </Switch>
);

export default React.memo(
  SchedulerListRouter,
  (prevProps: Readonly<SchedulerRouterProps>, nextProps: Readonly<SchedulerRouterProps>) =>
    prevProps.inspectionId === nextProps.inspectionId
);
