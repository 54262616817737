import React, { createContext, useMemo, useState } from 'react';
import { IInvoiceFilter } from 'services/ApiService/InvoicesService/invoicesService.types';
import { IInvoicesContext } from './Invoices.types';
import { InvoiceStatusEnum, IInvoice } from 'commom/types/Invoices.types';

export const InvoicesContext = createContext<IInvoicesContext>({} as IInvoicesContext);

const initialInvoice = {
  id: '',
  createdAt: '',
  startDate: '',
  endDate: '',
  clientId: '',
  client: {
    name: '',
    id: '',
    logoUrl: '',
  },
  price: 0,
  expensesCount: 0,
  status: [
    {
      id: '',
      status: InvoiceStatusEnum.CREATED,
      description: '',
    },
  ],
};

const initialFilter = {
  clientIds: undefined,
  finalRangeCreatedAt: undefined,
  initialRangeCreatedAt: undefined,
  statusIds: undefined,
};

export const InvoicesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<IInvoice>(initialInvoice);
  const [filter, setFilter] = useState<IInvoiceFilter>(initialFilter);
  const [companiesIds, setCompaniesIds] = useState<string[]>();

  const InvoicesState = useMemo(
    () => ({
      loading,
      filter,
      invoice,
      companiesIds,
    }),
    [filter, loading, invoice, companiesIds]
  );

  return (
    <InvoicesContext.Provider value={{ ...InvoicesState, setLoading, setFilter, setInvoice, setCompaniesIds }}>
      {children}
    </InvoicesContext.Provider>
  );
};
