import React from 'react';
import NumberFormat from 'react-number-format';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IIncrementDecrementButton } from './IncrementDecrementButton.types';
import { Button, TextField, ButtonGroup } from './IncrementDecrementButton.styles';

export default function IncrementDecrementButton({
  counter,
  handleIncrement,
  handleDecrement,
  handleInput,
  loading = false,
  disabledInput = false,
}: IIncrementDecrementButton) {
  const displayCounter = counter <= 1 || loading;

  return (
    <ButtonGroup aria-label="Button group" data-testid="increment-decrement-button-group">
      <Button
        onClick={handleDecrement}
        disabled={displayCounter}
        text={<RemoveIcon />}
        variant="outlined"
        className="btn-left"
        testID="decrement-button"
      />
      <NumberFormat
        disabled={disabledInput}
        customInput={TextField}
        value={counter}
        allowNegative={false}
        allowLeadingZeros={false}
        isNumericString
        onChange={handleInput}
        inputProps={{ 'data-testid': 'counter-input' }}
        {...{ variant: 'outlined' }}
      />
      <Button
        onClick={handleIncrement}
        disabled={loading}
        text={<AddIcon />}
        variant="outlined"
        className="btn-right"
        testID="increment-button"
      />
    </ButtonGroup>
  );
}
