import { IBudgetPart, IBudgetPartServiceTypeEnum } from 'commom/types/Budget.types';
import React from 'react';
import { formatDecimalValue } from 'utils/formatDecimalValue';
import { priceFormatter } from 'utils/formatPrice';
import EditPartAction from './EditPartAction/EditPartAction';
import {
  ArrowDown,
  ArrowUp,
  ChangeInfoWrapper,
  InformationContainer,
  InformationItem,
  InformationSubtitle,
  InformationTitle,
  ItemActionsContainer,
  ItemLabelContainer,
  ItemSelectContainer,
  ListItem,
  NewInformation,
  OldInformation,
} from './PartItem.styles';
import RemovePartAction from './RemovePartAction/RemovePartAction';

interface IPartItemProps {
  part: IBudgetPart;
}

const PartItem = ({ part }: IPartItemProps) => {
  const showArrowIndicator = (original: number, current: number) => {
    return current > original ? <ArrowUp /> : <ArrowDown />;
  };

  const showLaborChange = (original: number, current: number) => {
    if (original !== 0 && current !== 0 && current !== original) {
      return (
        <>
          {showArrowIndicator(original, current)}
          <ChangeInfoWrapper>
            <OldInformation>{formatDecimalValue(original)}</OldInformation>
            <NewInformation>{formatDecimalValue(current)}</NewInformation>
          </ChangeInfoWrapper>
        </>
      );
    }

    return formatDecimalValue(current);
  };

  const showPriceChange = (original: number, current: number) => {
    if (original !== 0 && current !== 0 && current !== original) {
      return (
        <>
          {showArrowIndicator(original, current)}
          <ChangeInfoWrapper className="column">
            <InformationSubtitle>{priceFormatter.format(current)}</InformationSubtitle>
          </ChangeInfoWrapper>
        </>
      );
    }
    return priceFormatter.format(original);
  };

  const handlePriceChange = (partInput: IBudgetPart) => {
    if (partInput.serviceType.code === IBudgetPartServiceTypeEnum.REPLACEMENT) {
      const current = partInput.isNetPriceModified ? partInput.inputNetPrice : partInput.individualNetPrice;
      return showPriceChange(partInput.originalPrice, current);
    }
    return '-';
  };

  return (
    <ListItem>
      <ItemSelectContainer>
        <ItemLabelContainer>
          <strong>{part.name}</strong>
          <span>{part.sku}</span>
        </ItemLabelContainer>
      </ItemSelectContainer>
      <InformationContainer>
        <InformationItem className="widthService">
          <InformationTitle>Serviço</InformationTitle>
          <InformationSubtitle>{part.serviceType.description}</InformationSubtitle>
        </InformationItem>
        <InformationItem>
          <InformationTitle>Remoção & Instalação</InformationTitle>
          <InformationSubtitle>
            {showLaborChange(part.originalServiceHours.removeAndInstall, part.serviceHours.removeAndInstall)}
          </InformationSubtitle>
        </InformationItem>
        <InformationItem className="widthSmaller">
          <InformationTitle>Pintura</InformationTitle>
          <InformationSubtitle>
            {showLaborChange(part.originalServiceHours.painting, part.serviceHours.painting)}
          </InformationSubtitle>
        </InformationItem>
        <InformationItem className="widthSmaller">
          <InformationTitle>Reparo</InformationTitle>
          <InformationSubtitle>
            {showLaborChange(part.originalServiceHours.repair, part.serviceHours.repair)}
          </InformationSubtitle>
        </InformationItem>
        <InformationItem>
          <InformationTitle>Líquido da Peça</InformationTitle>
          <InformationSubtitle>{handlePriceChange(part)}</InformationSubtitle>
        </InformationItem>
        <InformationItem>
          <InformationTitle>Mão de Obra</InformationTitle>
          <InformationSubtitle>{priceFormatter.format(part.individualServicePrice)}</InformationSubtitle>
        </InformationItem>
        <InformationItem className="widthQuantity">
          <InformationTitle>Qtd.</InformationTitle>
          <InformationSubtitle>{part.quantity}</InformationSubtitle>
        </InformationItem>
        <InformationItem>
          <InformationTitle>Valor Total</InformationTitle>
          <InformationSubtitle>{priceFormatter.format(part.totalFinalAmount)}</InformationSubtitle>
        </InformationItem>
      </InformationContainer>
      <ItemActionsContainer>
        <RemovePartAction sku={part.sku} />
        <EditPartAction part={part} />
      </ItemActionsContainer>
    </ListItem>
  );
};

export default PartItem;
