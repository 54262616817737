import { useContext } from 'react';

import { IActiveProfileContext } from './types';

import { ActiveProfileContext } from './ActiveProfileProvider';

export const useActiveProfile = (): IActiveProfileContext => {
  const context = useContext(ActiveProfileContext);

  if (!context) {
    throw new Error('useActiveProfileDispatch must be used within a AuthProvider');
  }

  return context;
};
