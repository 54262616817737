import { Button } from 'components';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import React, { useState } from 'react';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { isAxiosError } from 'utils/isAxiosError';

interface IStartInpectionActionProps {
  inspection: IInspection;
}

const StartInpectionAction = ({ inspection }: IStartInpectionActionProps) => {
  const { id: inspectionId, productType, companyId, currentStatus, product } = inspection;

  const [loading, setLoading] = useState(false);
  const { isAdmin, isRegulator } = useAuthDispatch();
  const { invalidateInspectionParallel } = useMutateInspection();
  const { isConsumerActive } = useConsumer();
  const { isFeatureToggleActive } = useFeatureToggles();
  const history = useHistory();

  const isAdminOrRegulator = isAdmin() || isRegulator();

  const isProductTypeAuto = ProductTypeService.isAuto(productType?.code);

  const isPhotoReceived = InspectionStatusService.isCurrentStatus(currentStatus, StatusEnum.PHOTO_RECEIVED);

  const hasBudgetAccess = isProductTypeAuto && isConsumerActive(ConsumersEnum.BUDGETS_ACCESS, companyId || '');

  const isDisabledStartBudget = !product || !product.vehicle;

  const startInspection = async (): Promise<void> => {
    try {
      if (!inspectionId) return;

      await InspectionStatusService.started(inspectionId);
    } catch {
      throw new Error('Aconteceu algo inesperado ao iniciar o atendimento, tente novamente.');
    }
  };

  const handleStartInspection = async (): Promise<void> => {
    try {
      setLoading(true);
      await startInspection();
      await invalidateInspectionParallel(inspectionId);
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      } else {
        toast.error('Aconteceu algo inesperado ao iniciar o atendimento');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleStartBudget = async (): Promise<void> => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}/criar-orcamento${window.location.search}`);
  };

  return (
    <>
      {isAdminOrRegulator && isPhotoReceived && (
        <>
          {hasBudgetAccess && isFeatureToggleActive('BUDGET_PROCESS') ? (
            <Button
              type="button"
              testID="bugets-init-button"
              text="INICIAR ORÇAMENTO"
              size="small"
              onClick={handleStartBudget}
              variant="contained"
              loading={loading}
              disabled={isDisabledStartBudget}
            />
          ) : (
            <Button
              type="button"
              testID="button-start-inspection"
              text="INICIAR ATENDIMENTO"
              size="small"
              onClick={handleStartInspection}
              variant="contained"
              loading={loading}
            />
          )}
        </>
      )}
    </>
  );
};

export default StartInpectionAction;
