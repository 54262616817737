import React from 'react';
import { Gallery } from 'components/Gallery/Gallery';
import NoTransmissionPhoto from 'assets/svg/no-transmission-photo.svg';
import { IPhoto } from '../../../Inspection';
import * as S from './Photos.styles';

interface ITransmissionPhotosProps {
  photos: IPhoto[];
}

export const TransmissionPhotos = ({ photos }: ITransmissionPhotosProps) => {
  const photosUrls = photos.map((photo) => photo.url);

  return (
    <S.PhotosCard>
      <S.Title>Fotos Capturadas ({photos.length || 0})</S.Title>
      {photos.length > 0 ? (
        <S.PhotosContainer>
          <Gallery urls={photosUrls} orientation="horizontal" thumbnailHeigth="120" thumbnailWidth="160" />
        </S.PhotosContainer>
      ) : (
        <S.NoPhoto>
          <S.Illustration src={NoTransmissionPhoto} />
          <S.Description>
            As fotos capturadas <b>durante a transmissão</b> serão exibidas aqui
          </S.Description>
        </S.NoPhoto>
      )}
    </S.PhotosCard>
  );
};
