import ManualPartBudgetIcon from 'assets/svg/manual-parts-budget.svg';
import PartBudgetIcon from 'assets/svg/parts-budget.svg';
import ServiceBudgetIcon from 'assets/svg/service-budget.svg';
import { IBudgetPart } from 'commom/types/Budget.types';
import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import {
  Header,
  HeaderTitle,
  HeaderTitleContainer,
  ListContainer,
  SubHeader,
  SubHeaderTitle,
  TableContainer,
} from './ListPartsAndOutsourcedServices.styles';
import OutsourcedServiceItem from './OutsourcedServiceItem/OutsourcedServiceItem';
import PartItem from './PartItem/PartItem';

const ListPartsAndOutsourcedServices: React.FC = () => {
  const { parts, outsourcedServices } = useAppSelector((state) => {
    return {
      parts: state.presentBudget.budget.items.parts,
      outsourcedServices: state.presentBudget.budget.items.outsourcedServices,
    };
  });

  const manualParts = parts.filter((part) => part.isManualEntry);
  const nonManualParts = parts.filter((part) => !part.isManualEntry);

  const renderPartsList = (partsList: IBudgetPart[], title: string, icon: string): JSX.Element => (
    <>
      <SubHeader>
        <HeaderTitleContainer>
          <SubHeaderTitle>
            <img src={icon} alt="" /> {title} ({partsList.length})
          </SubHeaderTitle>
        </HeaderTitleContainer>
      </SubHeader>
      <ListContainer>
        {partsList.map((part: IBudgetPart) => (
          <PartItem key={part._id} part={part} />
        ))}
      </ListContainer>
    </>
  );

  return (
    <TableContainer>
      <Header>
        <HeaderTitleContainer>
          <HeaderTitle>Lista de Peças e Serviços</HeaderTitle>
        </HeaderTitleContainer>
      </Header>
      {renderPartsList(nonManualParts, 'peças', PartBudgetIcon)}
      {renderPartsList(manualParts, 'peças incluídas manualmente', ManualPartBudgetIcon)}
      <SubHeader>
        <HeaderTitleContainer>
          <SubHeaderTitle>
            <img src={ServiceBudgetIcon} alt="" /> Serviços ({outsourcedServices.length})
          </SubHeaderTitle>
        </HeaderTitleContainer>
      </SubHeader>
      <ListContainer>
        {outsourcedServices.map((service) => (
          <OutsourcedServiceItem key={service.id} service={service} />
        ))}
      </ListContainer>
    </TableContainer>
  );
};

export default ListPartsAndOutsourcedServices;
