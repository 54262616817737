import React, { useEffect } from 'react';

import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { InputAdornment } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { theme } from 'themes/default';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useConsumer } from 'contexts/consumers/useConsumer';

import AuthService from 'services/ApiService/AuthService/authService';
import { IAccountGroups } from 'services/ApiService/AccountService/types';
import { Label } from 'components';
import { useAppDispatch } from 'hooks/hooks';
import { formValidations } from 'utils/form/validations';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { updateAccountGroups } from 'stores/accoutGroup.store';
import { updateActiveProfile } from 'stores/activeProfile.store';
import { ILoginForm } from './Login.types';
import { Container, Illustration, Form, Title, Description, TextField, Button, ForgotPassword } from './Login.styles';

export const Login = () => {
  const { register, errors, handleSubmit } = useForm({ mode: 'onChange' });
  const { signIn, signOut, isEmailConfirmed } = useAuthDispatch();
  const { loading } = useAuthState();
  const { initiateAccountGroups, setActiveProfile, getFCMTokenForAccountActive } = useActiveProfile();
  const { initiateCompanyConsumers } = useConsumer();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getFeatureToggles } = useFeatureToggles();

  useEffect(() => {
    const sigInByURL = async (): Promise<void> => {
      try {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        if (token) {
          const accountByToken = AuthService.getAccountByBase64(token);

          await signIn(accountByToken);

          const accountGroups = await initiateAccountGroups();

          await initiateCompanyConsumers();
          await getFCMTokenForAccountActive();

          await setActiveProfile(accountGroups.groups[0].client.id);

          dispatch(updateAccountGroups(accountGroups.groups));
          dispatch(updateActiveProfile(accountGroups.groups[0].client.id));
          history.push('/');
        }
      } catch (error) {
        toast.error('Sua URL é inválida.');
      }
    };

    sigInByURL();
  }, [
    history,
    signIn,
    initiateAccountGroups,
    initiateCompanyConsumers,
    setActiveProfile,
    dispatch,
    getFCMTokenForAccountActive,
  ]);

  const onSubmit = async (loginForm: ILoginForm): Promise<void> => {
    try {
      await signIn(loginForm);

      const accountGroups = await initiateAccountGroups();
      await initiateCompanyConsumers();
      dispatch(updateAccountGroups(accountGroups.groups));

      await getFeatureToggles();

      if (!isEmailConfirmed()) {
        history.push('/email-confirmation');
      } else {
        if (hasOnlyOneClient(accountGroups)) {
          await getFCMTokenForAccountActive();

          const clientId = accountGroups.groups[0].client.id;
          await setActiveProfile(clientId);
          dispatch(updateActiveProfile(clientId));

          history.push('/');
        }

        if (hasTwoOrMoreClient(accountGroups)) {
          await getFCMTokenForAccountActive();

          history.push('/select-profile');
        }

        if (hasNoClient(accountGroups)) {
          await signOut();

          toast.error('Conta não está associado a uma seguradora.');
        }
      }
    } catch (error) {
      toast.error('Campo usuário ou senha: Inválido.');
    }
  };

  const hasOnlyOneClient = (accountGroups: IAccountGroups): boolean => {
    return accountGroups.groups?.length === 1;
  };

  const hasTwoOrMoreClient = (accountGroups: IAccountGroups): boolean => {
    return accountGroups.groups?.length > 1;
  };

  const hasNoClient = (accountGroups: IAccountGroups): boolean => {
    return accountGroups.groups?.length === 0 || !accountGroups;
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const InputProps = {
    username: {
      startAdornment: (
        <InputAdornment position="start">
          <PersonIcon />
        </InputAdornment>
      ),
    },
    password: {
      startAdornment: (
        <InputAdornment position="start">
          <VpnKeyIcon />
        </InputAdornment>
      ),
    },
  };

  const inputProps = {
    username: {
      'data-testid': 'input-username',
    },
    password: {
      'data-testid': 'input-password',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Container data-testid="login">
        <Illustration />

        <Form role="form" onSubmit={handleSubmit(onSubmit)}>
          <Title>Seja bem-vindo!</Title>

          <Description>Entre na sua conta e comece a utilizar o portal de atendimentos digitais</Description>

          <Label htmlFor="username" testID="label-username">
            Usuário
          </Label>
          <TextField
            ariaLabelledby="username"
            error={hasError('username')}
            id="username"
            InputProps={InputProps.username}
            inputProps={inputProps.username}
            inputRef={register(formValidations.default)}
            helperText={errors?.username?.message}
            name="username"
            placeholder="Insira o seu usuário"
            state={handleInputState('username')}
            type="text"
            variant="outlined"
          />

          <Label htmlFor="password" testID="label-password">
            Senha
          </Label>
          <TextField
            error={hasError('password')}
            id="password"
            InputProps={InputProps.password}
            inputProps={inputProps.password}
            inputRef={register(formValidations.password)}
            helperText={errors.password?.message}
            name="password"
            placeholder="Insira a sua senha"
            role="password"
            state={handleInputState('password')}
            type="password"
            variant="outlined"
          />

          <Button
            fullWidth={true}
            loading={loading}
            loadingColor="#ffffff"
            loadingSize={26}
            size="large"
            text="ENTRAR"
            type="submit"
          />

          <ForgotPassword data-testid="forgot-password" to="/password-recovery?passwordRecoveryApplication=KITE">
            Esqueceu a senha?
          </ForgotPassword>
        </Form>
      </Container>
    </ThemeProvider>
  );
};
