import React from 'react';
import { Checkbox } from '@material-ui/core';
import { TableCellWrapper, TableRowWrapper, TableCellWrapperSticky } from './InspectionTableRow.styles';
import { IInspectionTableRowProps } from './InspectionTableRow.types';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';

export const InspectionTableRow: React.FC<IInspectionTableRowProps> = ({
  inspectionRow,
  isCancelable,
  isAuto = true,
  hasManyOperations,
  isRowSelected,
  selectRow,
}) => {
  return (
    <TableRowWrapper
      data-testid={inspectionRow?.testID}
      key={inspectionRow.id}
      selected={isRowSelected(inspectionRow.id)}
    >
      <TableCellWrapperSticky align="center">
        <ActionsMenu
          dataTestId="actions-cell"
          inspectionId={inspectionRow.id}
          companyId={inspectionRow.companyId}
          isCancelable={isCancelable}
          hasManyOperations={hasManyOperations}
        />
      </TableCellWrapperSticky>
      <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
        <Checkbox
          data-testid="checkbox-table"
          color="primary"
          checked={isRowSelected(inspectionRow.id)}
          inputProps={{ 'aria-label': 'select all desserts' }}
        />
      </TableCellWrapper>
      {'dateLinkSent' in inspectionRow ? (
        <>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.companyName}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.code}</TableCellWrapper>
          {isAuto && (
            <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.plate}</TableCellWrapper>
          )}
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.regulator}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.dateLinkSent}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.hourLinkSent}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.datePhotoReceived}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.hourPhotoReceived}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.dateFinished}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.hourFinished}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.conclusionType}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.conclusionReason}
          </TableCellWrapper>
          <TableCellWrapper />
        </>
      ) : (
        <>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.companyName}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.regulator}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.plate}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.vehicle}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.model}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.km}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.register}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.requested}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.accomplished}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.damageExpertAmountGross}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.damageExpertAmountNet}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.address}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.city}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.neighborhood}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.cep}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.agreement}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.contract}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.status}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.renter}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.inspectionType}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.user}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.email}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>{inspectionRow.phone}</TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            <a href={inspectionRow.shortTokenUrl} target="_blank" rel="noopener noreferrer">
              Link de redirecionamento
            </a>
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.conclusionType}
          </TableCellWrapper>
          <TableCellWrapper onClick={(): void => selectRow(inspectionRow)}>
            {inspectionRow.conclusionReason}
          </TableCellWrapper>
        </>
      )}
    </TableRowWrapper>
  );
};
