import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { toggleSchedulerListMenu } from 'stores/schedulerListMenu.store';
import * as S from './MenuButton.styles';

export function MenuButton() {
  const isMenuOpen = useAppSelector((state) => state.schedulerListMenu.isOpen);
  const dispatch = useAppDispatch();

  const icon = isMenuOpen ? <S.ArrowBack /> : <S.ArrowForward />;

  const text = isMenuOpen && <S.Text>Ocultar</S.Text>;

  return (
    <S.Button data-testid="button" onClick={() => dispatch(toggleSchedulerListMenu())}>
      <S.Icon data-isopen={+isMenuOpen}>{icon}</S.Icon>
      {text}
    </S.Button>
  );
}
