import { ITableInvoiceHeaderCells } from '../../InvoiceTable/InvoicesTable.types';

export enum InvoiceExpensesSorting {
  COMPANY_NAME = 'COMPANY_NAME',
  INSPECTION_CODE = 'INSPECTION_CODE',
  TYPE = 'TYPE',
  RESPONSIBLE_NAME = 'RESPONSIBLE_NAME',
  START_DATE = 'START_DATE',
  PRICE = 'PRICE',
}

export const tableInvoicesExpensesHeader: ITableInvoiceExpensesHeaderCells[] = [
  {
    name: 'Operação',
    sortParam: InvoiceExpensesSorting.COMPANY_NAME,
    isActive: false,
    isAsc: false,
    align: 'left',
  },
  {
    name: 'Atendimento',
    sortParam: InvoiceExpensesSorting.INSPECTION_CODE,
    isActive: false,
    isAsc: false,
    align: 'left',
  },
  {
    name: 'Produto',
    sortParam: InvoiceExpensesSorting.TYPE,
    isActive: false,
    isAsc: false,
    align: 'left',
  },
  {
    name: 'Operador',
    sortParam: InvoiceExpensesSorting.RESPONSIBLE_NAME,
    isActive: false,
    isAsc: false,
    align: 'left',
  },
  {
    name: 'Data da operação',
    sortParam: InvoiceExpensesSorting.START_DATE,
    isActive: false,
    isAsc: false,
    align: 'left',
  },
  {
    name: 'Preço',
    sortParam: InvoiceExpensesSorting.PRICE,
    isActive: false,
    isAsc: false,
    align: 'center',
  },
];

export interface ITableInvoiceExpensesHeaderCells extends ITableInvoiceHeaderCells {
  name: string;
  sortParam: InvoiceExpensesSorting;
  isAsc: boolean;
  isActive: boolean;
}
