import { Slide, List as ListComponent, ListItemText as ListItemTextComponent } from '@material-ui/core';
import { ContainerImage, ProfileImage, ShadowImage } from 'pages/SelectProfile/SelectProfile.styles';
import styled from 'styled-components';

import { ISlideMenuWrapper, IList } from './SlideMenu.types';

export const SlideMenuWrapper = styled(Slide)<ISlideMenuWrapper>`
  background-color: #ffffff;
  border-bottom: ${(props): string => (props.position === 'bottom' ? 'none' : 'solid 1px #e8e8e8')};
  border-left: none;
  border-right: solid 1px #e8e8e8;
  border-top: ${(props): string => (props.position === 'top' ? 'none' : 'solid 1px #e8e8e8')};
  bottom: ${(props): string => (props.position === 'bottom' ? '0' : 'auto')};
  left: ${(props): string => props.left};
  position: fixed;
  top: ${(props): string => (props.position === 'top' ? '0' : 'auto')};
  width: 250px;
  z-index: 20;
`;

export const Head = styled.div`
  border-bottom: solid 1px #e8e8e8;
  padding: 16px;
`;

export const Content = styled.div``;

export const List = styled(ListComponent)<IList>`
  padding: 0 !important;
`;

export const SelectProfile = styled.div`
  max-height: 180px;
  width: 100%;
  display: grid;
  grid-template-rows: 50px 1fr;
  border-bottom: solid 1px #e8e8e8;
`;

export const ListClients = styled.div`
  grid-row: 2/3;
  overflow-y: auto;
  padding: 5px 0 5px 0;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #cecece;
  }
  &::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: #ececec;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  div:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

export const CurrentProfile = styled.div`
  grid-row: 1/2;
  background-color: #ececec;
  display: flex;
  align-items: center;
  span {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
  }
  h6 {
    font-weight: 600;
    color: #000;
  }
`;

export const Profile = styled.div`
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  transition: all 0.3s ease-out;
`;

export const ProfileIcon = styled(ContainerImage)`
  width: 30px;
  height: 30px;
  margin-left: 20px;
  grid-column: 1/2;
`;

export const ShadowIcon = styled(ShadowImage)`
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0);
  z-index: 99;
`;

export const Icon = styled(ProfileImage)`
  width: 30px;
  height: 30px;
`;

export const ClientName = styled.h6`
  margin: 0;
  grid-column: 2/3;
  width: 100%;
  letter-spacing: 0.3px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  color: #505050;
`;

export const ListItemText = styled(ListItemTextComponent)`
  span {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const Title = styled.h3`
  color: #788194;
  font-size: 14px;
  margin: 0;
`;

export const Subtitle = styled.h4`
  color: #788194;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-top: 6px;
`;
