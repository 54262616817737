import styled from 'styled-components';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AvatarIcon from 'components/Avatar/Avatar';

interface ICardStyleProps {
  isUnread: boolean;
  haveLabel?: boolean;
}

export const Container = styled.div<ICardStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  width: 100%;
  max-height: ${({ haveLabel }): string => (haveLabel ? '150px' : '120px')};
  padding: 20px;
  border-bottom: 1px solid #f2f4f7;
  background-color: ${({ isUnread }): string => (isUnread ? '#F8FFFF' : '#FFFFFF')};
`;

export const InfoContentTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 50px;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
  margin-right: 10px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  height: 100%;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-weight: 600;
  color: #334155;
  font-family: Montserrat;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
`;

export const Message = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #334155;
  font-family: Montserrat;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 270px;
`;

export const UnreadDot = styled(CircleIcon)`
  font-size: 15px !important;
  color: #1ca6af !important;
  cursor: pointer;
`;

export const ReadDot = styled(CircleOutlinedIcon)`
  font-size: 15px !important;
  color: #dae0eb !important;
`;

export const Client = styled(AvatarIcon)`
  width: 30px !important;
  height: 30px !important;
  margin-top: -15px !important;
`;

export const Date = styled.div`
  font-size: 12px;
  color: #475569;
  font-weight: 400;
  font-family: Montserrat;
`;

export const ActionLink = styled.a`
  font-size: 13px;
  font-weight: 500;
  color: rgba(28, 166, 175, 1);
  text-decoration: none;
  cursor: pointer;
`;
