import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 5px;
  background-color: #fff;
  gap: 10px;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: Space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 24px 10px 24px;
`;

export const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const SummaryScore = styled.div`
  display: flex;
  background-color: #f1f1f1;
  height: 67px;
  padding: 7px 14px 7px 14px;
  gap: 12px;
  border-radius: 6px;
`;

export const SummaryClassification = styled.span`
  display: flex;
  background-color: #d8d8d8;
  height: 67px;
  padding: 8.5px 14px 8.5px 14px;
  gap: 8px;
  border-radius: 6px;
`;

export const SummaryInfo = styled.span`
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
`;

export const TitleInfo = styled(SummaryInfo)`
  color: #00000099;
`;

export const TitleBoldInfo = styled(SummaryInfo)`
  font-weight: 700;
  color: #00000099;
`;

export const ItalicInfo = styled(SummaryInfo)`
  font-style: italic;
`;

export const BoldItalicInfo = styled(SummaryInfo)`
  font-style: italic;
  font-weight: 700;
`;

export const StrongInfo = styled(SummaryInfo)`
  font-weight: 700;
`;

export const Separator = styled.div`
  border: 1px solid #0000001a;
`;
