import React from 'react';
import { IRegulatorsGeneralDetails } from 'services/ApiService/MetricsService/metricsService.types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as Styled from './RegulatorsGeneralDetails.styles';

interface IRegulatorsGeneralDetailsTableRowProps {
  row: IRegulatorsGeneralDetails;
}

export function RegulatorsGeneralDetailsTableRow({ row }: IRegulatorsGeneralDetailsTableRowProps) {
  return (
    <Styled.TableRow>
      <Styled.TableCellSticky align="left">
        <Styled.ActionLink to={`/metrics-details?name=${row.regulatorName}&regulatorId=${row.regulatorId}`}>
          <VisibilityIcon />
          Ver mais
        </Styled.ActionLink>
      </Styled.TableCellSticky>

      <Styled.TableCell align="left">{row.regulatorName}</Styled.TableCell>
      <Styled.TableCell align="left">{row.inProgressInspectionsCount}</Styled.TableCell>
      <Styled.TableCell align="left">{row.atRiskOfDelay}</Styled.TableCell>
      <Styled.TableCell align="left">{row.notStartedInspectionsCount}</Styled.TableCell>
      <Styled.TableCell align="left">{row.cancelledInspectionsCount}</Styled.TableCell>
      <Styled.TableCell align="left">{row.finishedInspectionsCount}</Styled.TableCell>
      <Styled.TableCell align="left">{row.finishedInspectionPercentage.toFixed(2)}%</Styled.TableCell>
    </Styled.TableRow>
  );
}
