import React from 'react';
import NumericFormat from 'react-number-format';
import { Label } from 'components';
import { TextField } from 'components/TextField/TextField';
import { Card, CardBody, CardHeader, CardTitle } from '../../CreateBudget.styles';

interface IInsuranceValuesProps {
  deductiblePrice: number | null;
  setDeductiblePRice: React.Dispatch<React.SetStateAction<number | null>>;
}

const InsuranceValues = ({ deductiblePrice, setDeductiblePRice }: IInsuranceValuesProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>VALORES DO SEGURO</CardTitle>
      </CardHeader>

      <CardBody>
        <div>
          <Label>Franquia</Label>
          <NumericFormat
            placeholder="Insira o valor da franquia"
            value={deductiblePrice}
            onValueChange={({ floatValue }) => setDeductiblePRice(floatValue || 0)}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="R$ "
            allowNegative={false}
            customInput={TextField}
            {...{ variant: 'outlined' }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default InsuranceValues;
