import styled from 'styled-components';
import { IconButton as ButtonIcon } from '@mui/material';

export const IconButton = styled(ButtonIcon)`
  margin: 0 20px 0 auto !important;

  .MuiIconButton-root {
    &:hover {
      background-color: transparent !important;
    }
  }

  .MuiSvgIcon-root {
    color: #000;
  }
`;
