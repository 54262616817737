import styled from 'styled-components';
import { ILoadingDotsPros } from './types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Illustration = styled.img<ILoadingDotsPros>`
  width: ${(props): string => props.width};
`;
