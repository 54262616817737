import React from 'react';
import Loading from 'components/Loading';
import { ListWrapper, ListBottomWrapper } from './List.styles';
import { IListProps } from './List.types';
import { Button } from '../../../../components';

export const List = ({
  testID = 'attends-list',
  children,
  hasMore,
  isLoading,
  loadMore,
  hasManyCompanies,
  isTransmissionInspection,
}: IListProps) => {
  return (
    <ListWrapper
      data-testid={testID}
      hasManyCompanies={hasManyCompanies}
      isTransmissionInspection={isTransmissionInspection}
    >
      {children}
      <ListBottomWrapper>
        {isLoading ? (
          <Loading width="40%" />
        ) : (
          hasMore && (
            <Button
              variant="outlined"
              type="button"
              fullWidth={true}
              size="large"
              testID="load-more-button"
              text="Carregar mais"
              onClick={loadMore}
            />
          )
        )}
      </ListBottomWrapper>
    </ListWrapper>
  );
};
