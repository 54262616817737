import React, { useRef, useState } from 'react';
import { Image } from 'components/Image/Image';
import Lightbox from 'react-image-lightbox';
import { Tooltip } from '@mui/material';
import { ITabPhotosProps } from './TabPhotos.types';
import { TabPhotosContainer, TouchableImage, ListItem } from './TabPhotos.styles';
import TabEmpty from '../TabEmpty/TabEmpty';

const TabPhotos = ({ inspection }: ITabPhotosProps) => {
  const [isGalleryExpanded, setIsGalleryExpanded] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const listRef = useRef<HTMLUListElement>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLUListElement>) => {
    if (!listRef.current) return;
    setIsMouseDown(true);
    setStartX(event.clientX - listRef.current?.offsetLeft);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLUListElement>) => {
    if (!isMouseDown) return;
    if (!listRef.current) return;
    const x = event.clientX - listRef.current?.offsetLeft;
    const scrollLeft = listRef.current?.scrollLeft;

    listRef.current.scrollLeft = scrollLeft - (x - startX);
    setStartX(x);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const photosLength = inspection.photos.length;
  const getNextImageIndex = (): number => (photoIndex + 1) % photosLength;
  const getPreviousImageIndex = (): number => (photoIndex + photosLength - 1) % photosLength;

  function handleOpenGallery(index: number) {
    setPhotoIndex(index);
    setIsGalleryExpanded(true);
  }

  if (photosLength === 0) {
    return <TabEmpty title="Não há fotos capturadas" />;
  }

  return (
    <>
      <TabPhotosContainer
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={listRef}
      >
        {inspection.photos.map((photo, index) => (
          <ListItem key={photo.id}>
            <Tooltip title={photo.description} placement="top">
              <TouchableImage type="button" onClick={() => handleOpenGallery(index)}>
                <Image width={130} height={130} alt={photo.description} src={photo.url} />
              </TouchableImage>
            </Tooltip>
          </ListItem>
        ))}
      </TabPhotosContainer>

      {isGalleryExpanded && (
        <Lightbox
          imageTitle={inspection.photos[photoIndex].description}
          mainSrc={inspection.photos[photoIndex].url}
          nextSrc={inspection.photos[getNextImageIndex()].url}
          prevSrc={inspection.photos[getPreviousImageIndex()].url}
          onMovePrevRequest={() => setPhotoIndex(getPreviousImageIndex())}
          onMoveNextRequest={() => setPhotoIndex(getNextImageIndex())}
          onCloseRequest={() => setIsGalleryExpanded(false)}
        />
      )}
    </>
  );
};

export default TabPhotos;
