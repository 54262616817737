import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Style from './AutocompleteField.styles';
import { IAutocompleteField, IOptionSorted } from './AutocompleteField.types';

const AutocompleteField = ({
  options,
  selected,
  id,
  testID = 'autocomplete-field',
  placeholder,
  required = true,
  disabled,
  onChange,
}: IAutocompleteField) => {
  const formatSelected = (): IOptionSorted | null => {
    if (!selected) {
      return null;
    }

    const firstLetter = selected[0].toUpperCase();

    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      name: selected,
    };
  };

  const formatOptionsWithFirstLetter = (): IOptionSorted[] => {
    return options?.map((option) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
  };

  const getSortedOptions = (): IOptionSorted[] => {
    return (
      formatOptionsWithFirstLetter()?.sort((input, search) => -search.firstLetter.localeCompare(input.firstLetter)) ||
      []
    );
  };

  return (
    <Autocomplete
      id={id}
      data-testid={testID}
      options={getSortedOptions()}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name}
      value={formatSelected()}
      onChange={onChange}
      disabled={disabled}
      renderInput={(params) => (
        <Style.TextField {...params} placeholder={placeholder} required={required} variant="outlined" />
      )}
    />
  );
};

export default AutocompleteField;
