import { ICompanySelectOptions, IOptions } from 'components/Select/Select.types';
import { IInspectionPlatformService, InspectionPlatformEnum } from './inspectionPlatformService.types';

const InspectionPlatformService: IInspectionPlatformService = {
  isCAPInspection: (inspectionPlatformCode) => {
    return inspectionPlatformCode === InspectionPlatformEnum.CAP;
  },

  isSORTInspection: (inspectionPlatformCode) => {
    return inspectionPlatformCode === InspectionPlatformEnum.SORT;
  },

  hasSORTInspection: (inspectionPlatforms) => {
    return inspectionPlatforms.some((platform) => platform.code === InspectionPlatformEnum.SORT);
  },

  hasPlatformCAP: (inspectionsPlatformsList: IOptions[], inspectionPlatformSelected: string) => {
    const inspectionPlatform = inspectionsPlatformsList.find((item) => item.value === inspectionPlatformSelected);
    return !!(inspectionPlatform !== undefined && inspectionPlatform.name === InspectionPlatformEnum.CAP);
  },

  companyInspectionPlatformIsCap: (companySelected: ICompanySelectOptions) => {
    return (
      companySelected.inspectionPlatforms !== undefined &&
      companySelected.inspectionPlatforms.length === 1 &&
      companySelected.inspectionPlatforms[0].code === InspectionPlatformEnum.CAP
    );
  },
};

export default InspectionPlatformService;
