import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslate } from 'hooks/Translate/TranslateHook';
import Tooltip from '@mui/material/Tooltip';
import environment from 'environments/environment';
import { INotification, NotificationStatusEnum } from 'commom/types/Notification.types';
import NotificationService from 'services/ApiService/NotificationService/notificationService';
import DateService from 'services/DateService/dateService';
import { useMutateNotifications } from 'hooks/Notifications/NotificationsHooks';
import * as S from './NotificationCard.styles';

interface INotificationCardProps {
  notification: INotification;
}

export const NotificationCard = ({
  notification: { id, title, message, createdAt, status, metaData, action },
}: INotificationCardProps) => {
  const [isUnread, setIsUnread] = useState<boolean>(status === NotificationStatusEnum.UNREAD);
  const { invalidateNotifications } = useMutateNotifications();
  const { translate } = useTranslate();
  const haveLabel = !!action?.label;

  const handleDateAndHour = (date: string) => {
    const day = DateService.getDay(date);
    const month = DateService.getMonthName(date);
    const year = DateService.getYear(date);
    const hour = DateService.getHours(date);
    const dateFormatted = `${day} de ${month}, ${year} | ${hour}h`;
    return dateFormatted;
  };

  const getTranslatedMessage = (value: string) => {
    return `NOTIFICATIONS.TYPE.${value}`;
  };

  const markAsRead = async () => {
    try {
      setIsUnread(false);
      await NotificationService.markNotificationAsRead(id);
      await invalidateNotifications();
    } catch (error) {
      toast.error('Erro ao marcar notificação como lida');
      await invalidateNotifications();
      setIsUnread(true);
    }
  };

  const handleLink = () => {
    return `${environment.APP_URL}/agendamentos/v2/detalhes/${metaData?.inspection.inspectionId}`;
  };

  return (
    <S.Container data-testid="card-notification" isUnread={isUnread} haveLabel={haveLabel}>
      <S.InfoContentTop>
        <S.LeftContent>
          {isUnread ? (
            <Tooltip title="Marcar como lida" placement="top">
              <S.UnreadDot data-testid="icon-unread" onClick={markAsRead} />
            </Tooltip>
          ) : (
            <S.ReadDot data-testid="icon-read" />
          )}
        </S.LeftContent>
        <S.MainContent>
          {title && <S.Title>{translate(getTranslatedMessage(title))}</S.Title>}
          <S.Message>
            {translate(getTranslatedMessage(message), {
              inspectionCode: metaData?.inspection.code || '(Não foi possível identificar o atendimento)',
            })}
          </S.Message>
          <S.Date>{handleDateAndHour(createdAt)}</S.Date>
        </S.MainContent>
      </S.InfoContentTop>
      <S.InfoContentBottom>
        {action?.label && (
          <S.ActionLink data-testid="link-action-notification" href={handleLink()}>
            {translate(getTranslatedMessage(action.label))}
          </S.ActionLink>
        )}
      </S.InfoContentBottom>
    </S.Container>
  );
};
