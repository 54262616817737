import React, { useState } from 'react';
import { Button } from 'components/Button';
import { Select } from 'components/Select/Select';
import { IOptions } from 'components/Select/Select.types';
import RegulatorService from 'services/ApiService/RegulatorService/regulatorService';
import { IInspectionsSelected } from 'pages/ManagementDashboard/components/InspectionsTable/InspectionsTable.types';
import { toast } from 'react-toastify';
import { mutateMany } from 'hooks/SWRUtils/SWRUtils';
import { IRegulator } from 'pages/SchedulerList/components/Inspection';
import { IAssociateRegulatorDialogProps } from './AssociateRegulatorDialog.types';
import { DialogBase, Title, Description, ContainerButton, MessageError } from './AssociateRegulatorDialog.styles';

const AssociateRegulatorDialog: React.FC<IAssociateRegulatorDialogProps> = ({
  inspectionsSelected,
  dialogVisibility,
  handleClose,
  setInspectionsSelected,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [regulatorsList, setRegulatorsList] = useState<IOptions[]>([]);
  const [regulatorSelected, setRegulatorSelected] = useState<string>('');

  const resetSelect = (): void => {
    setRegulatorsList([]);
    setRegulatorSelected('');
  };

  const closeDialog = (): void => {
    resetSelect();
    handleClose();
  };

  const totalInspections = (): string => {
    return inspectionsSelected.length > 1 ? `${inspectionsSelected.length} atendimentos` : '1 atendimento';
  };

  const isRegulatorNotSelected = regulatorSelected === '';
  const isDisabled = isRegulatorNotSelected || loading;

  const getComapaniesIdsOfInspectionsSelected = (): string[] => {
    const companiesId = inspectionsSelected.map((inspection) => inspection.companyId);
    return companiesId.filter((company, index) => companiesId.indexOf(company) === index);
  };
  inspectionsSelected.map((inspection) => inspection.companyId);

  const isRegulatorsAvailable = (): boolean => regulatorsList.length > 0;

  const regulatorsToOptions = (regulators: IRegulator[]): IOptions[] => {
    return regulators.map(({ id: value, username: name }) => ({ value, name }));
  };

  const getRegulators = async (companiesIds: string[]): Promise<IOptions[]> => {
    try {
      const regulators = await RegulatorService.getRegulators(companiesIds);

      return regulatorsToOptions(regulators);
    } catch (error) {
      toast.error('Problema ao carregar os peritos, tente novamente.');

      return [];
    }
  };

  const instantiateRegulatorsInModal = async (): Promise<void> => {
    const regulators = await getRegulators(getComapaniesIdsOfInspectionsSelected());
    setRegulatorsList(regulators);
    setRegulatorSelected(regulators[0]?.value);
  };

  const getInspectionsIds = (inspections: IInspectionsSelected[]): string[] => {
    return inspections.map((inspection) => inspection.id);
  };

  const setRegulatorToInspection = async (): Promise<void> => {
    try {
      setLoading(true);
      await RegulatorService.putRegulatorInInspections(regulatorSelected, getInspectionsIds(inspectionsSelected));

      mutateMany('^inspections\\/admin\\?companiesId=.*');
      toast.success('Atendimentos associados com sucesso.');
      setInspectionsSelected([]);
      setLoading(false);
      closeDialog();
    } catch (error) {
      toast.error('Atendimentos não associados, tente novamente.');
      setInspectionsSelected([]);
      setLoading(false);
    }
  };

  return (
    <DialogBase
      data-testid="associate-regulator-dialog"
      open={dialogVisibility}
      onClose={closeDialog}
      TransitionProps={{ onEnter: instantiateRegulatorsInModal }}
    >
      <Title>Associar ao perito</Title>
      <Description>Você está associando {totalInspections()}</Description>

      {isRegulatorsAvailable() ? (
        <Select
          testID="regulator-select"
          options={regulatorsList}
          selectedOptions={regulatorSelected}
          label="Perito"
          labelId="regulator"
          onChange={({ target: { value } }): void => setRegulatorSelected(value as string)}
        />
      ) : (
        <MessageError>Nenhum perito encontrado</MessageError>
      )}

      <ContainerButton>
        <Button
          testID="associate-regulator-cancel"
          type="button"
          variant="outlined"
          onClick={closeDialog}
          text="CANCELAR"
        />
        <Button
          testID="associate-regulator-submit"
          type="button"
          text="ASSOCIAR"
          loading={loading}
          loadingSize={25}
          loadingColor="#FFF"
          disabled={isDisabled}
          onClick={setRegulatorToInspection}
        />
      </ContainerButton>
    </DialogBase>
  );
};

export default AssociateRegulatorDialog;
