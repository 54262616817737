import React, { useState } from 'react';
import {
  useUserManagementDashboardDispatch,
  useUserManagementDashboardState,
} from 'contexts/usersManagementDashboard/UserManagementDashboard';
import metricsResultNotFound from 'assets/svg/metrics-result-not-found.svg';
import Loading from 'components/Loading';
import Stack from '@mui/material/Stack';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import {
  PaginationController,
  PaginationDetails,
  PaginationWrapper,
  UsersTableContainer,
  UserTableContent,
  Bold,
  TableLoadingWrapper,
  NotResultTitle,
  NotResultText,
  NoResultFoundWrapper,
  PaginationUserTable,
} from './UsersTable.styles';
import { CardUserComponent } from '../CardUser/CardUser';

export const UsersTable: React.FC = () => {
  const { pagination, users, isLoading } = useUserManagementDashboardState();
  const { setPagination } = useUserManagementDashboardDispatch();
  const [pageTest, setPageTest] = useState<number>(1);
  useHideZendesk();
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageTest(value);
    setPageButton(value - 1);
  };

  const setPageButton = (page: number): void => {
    setPagination({ ...pagination, currentPage: page });
  };

  const getInitialPageItem = (): number => {
    if (pagination.items === 0) {
      return 0;
    }
    return pagination.itemsPerPage * pagination.currentPage + 1;
  };

  const getFinalPageItem = (): number => {
    if (pagination.currentPage + 1 === pagination.pages) {
      return pagination.items;
    }
    return pagination.itemsPerPage + pagination.currentPage * pagination.itemsPerPage;
  };

  const NoResultFound = (): JSX.Element => {
    return (
      <NoResultFoundWrapper>
        <img src={metricsResultNotFound} alt="metrics-not-result" />

        <NotResultTitle>Nenhum usuário encontrado</NotResultTitle>
        <NotResultText>
          Sua pesquisa não teve nenhum resultado,
          <br />
          mude os filtros e tente novamente.
        </NotResultText>
      </NoResultFoundWrapper>
    );
  };

  const isTableEmpty = (): boolean => !users.length;

  return (
    <UsersTableContainer data-testid="users-table">
      {isLoading ? (
        <TableLoadingWrapper>
          <Loading width="15%" />
        </TableLoadingWrapper>
      ) : (
        <>
          {isTableEmpty() ? (
            NoResultFound()
          ) : (
            <>
              <UserTableContent>
                {users.map((user, index) => (
                  <CardUserComponent key={index} {...user} />
                ))}
              </UserTableContent>
              <PaginationWrapper>
                <PaginationDetails data-testid="pagination-details">
                  Mostrando{' '}
                  <Bold>
                    {getInitialPageItem()} a {getFinalPageItem()}
                  </Bold>{' '}
                  de <Bold>{pagination.items} resultados</Bold>
                </PaginationDetails>
                <PaginationController>
                  <Stack spacing={2}>
                    <PaginationUserTable
                      page={pageTest}
                      onChange={handleChange}
                      count={pagination.pages}
                      variant="outlined"
                      shape="rounded"
                    />
                  </Stack>
                </PaginationController>
              </PaginationWrapper>
            </>
          )}
        </>
      )}
    </UsersTableContainer>
  );
};
