import { IEditProfileForm } from 'components/Dialog/EditProfileDialog/EditProfileDialog.types';
import { IUsers } from 'contexts/usersManagementDashboard/types';
import ApiService from 'services/ApiService';
import { AuthorityEnum, AuthorityNameEnum } from 'utils/AuthorityEnum';
import { IAccount } from 'contexts/auth/types';
import {
  IAccountService,
  IChangePassword,
  IAccountGroups,
  IActiveProfile,
  IAccountGroup,
  IAssociateUserOperations,
  ICreateAccountMultipleCompany,
  IEditOperations,
  IDisassociateOperations,
} from './types';

const ACCOUNT_GROUPS = '@ACCOUNT_GROUPS';
const ACTIVE_PROFILE = '@ACTIVE_PROFILE';

const AccountService: IAccountService = {
  changePassword: async (newPassword: string) => {
    await ApiService.post('/accounts/password/reset', {
      newPassword,
    });
  },

  confirmPassword: async (password: string): Promise<IChangePassword> => {
    return ApiService.post('/accounts/password/confirm', {
      password,
    });
  },

  postAccount: async (account: ICreateAccountMultipleCompany) => {
    return ApiService.post('/accounts', {
      ...account,
    });
  },

  updateAccount: async (form: IEditProfileForm) => {
    return ApiService.put('/accounts/update', {
      ...form,
    });
  },

  getAccountGroupsByFilters: async (
    id: string,
    page: number,
    size: number,
    companiesId: string[],
    authoritiesValue: string[],
    query: string
  ) => {
    const { data } = await ApiService.get<IAccountGroup[]>(
      `/account-groups/filter/join-account?clientId=${id}&query=${query}&companiesId=${companiesId.join(
        ','
      )}&authorities=${authoritiesValue.join(',')}`
    );

    return data;
  },

  getUsersFromAccountGroups: (accountGroup: IAccountGroup[]): IUsers[] => {
    return accountGroup.map((group) => ({
      accountId: group.account.id,
      username: group.account.username,
      authority: group.account.authority,
      operations: group.companies,
    }));
  },

  associateUserOperations: async (data: IAssociateUserOperations): Promise<void> => {
    await ApiService.post('/account-groups/associate/user', {
      ...data,
    });
  },

  getAccountGroups: async () => {
    const { data } = await ApiService.get<IAccountGroups>('/account-groups');

    return data;
  },

  getAccountGroupsByClientId: async (url: string) => {
    const response = await ApiService.get<IAccountGroup[]>(url);

    return response;
  },

  getAccountsByAuthority: async (authority: AuthorityEnum, companiesIds: string[]) => {
    const { data } = await ApiService.get<IAccount[]>(
      `/accounts/authority?authority=${authority}&companiesIds=${companiesIds.join(',')}`
    );

    return data;
  },

  editOperationsAccountGroups: async (data: IEditOperations): Promise<void> => {
    return ApiService.put('/account-groups/', { ...data });
  },

  disassociateUserFromAccountGroups: async (data: IDisassociateOperations): Promise<void> => {
    return ApiService.delete('/account-groups/', { data });
  },

  setAccountGroupsInLocalStorage: (accountGroups) => {
    localStorage.setItem(ACCOUNT_GROUPS, JSON.stringify(accountGroups));
  },

  getAccountGroupsInLocalStorage: (): IAccountGroups => {
    const accountGroups = localStorage.getItem(ACCOUNT_GROUPS);
    if (!accountGroups) return {} as IAccountGroups;

    return JSON.parse(accountGroups);
  },

  deleteAccountGroupsFromLocalStorage: () => {
    localStorage.removeItem(ACCOUNT_GROUPS);
  },

  setActiveProfile: async (clientId) => {
    await ApiService.put('/accounts/active-profile', { clientId });
  },

  setActiveProfileInLocalStorage: (clientId) => {
    localStorage.setItem(ACTIVE_PROFILE, JSON.stringify({ clientId }));
  },

  getActiveProfileInLocalStorage: (): IActiveProfile => {
    const activeProfile = localStorage.getItem(ACTIVE_PROFILE);

    if (!activeProfile) return {} as IActiveProfile;

    return JSON.parse(activeProfile);
  },

  deleteActiveProfileFromLocalStorage: () => {
    localStorage.removeItem(ACTIVE_PROFILE);
  },

  updateLocalStorage: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  getPermissionsOptions: () => {
    return [
      {
        name: AuthorityNameEnum.ADMIN,
        value: AuthorityEnum.ADMIN,
      },
      {
        name: AuthorityNameEnum.ANALYST,
        value: AuthorityEnum.ANALYST,
      },
      {
        name: AuthorityNameEnum.REGULATOR,
        value: AuthorityEnum.REGULATOR,
      },
      {
        name: AuthorityNameEnum.USER,
        value: AuthorityEnum.USER,
      },
      {
        name: AuthorityNameEnum.INSURANCE_COMPANY,
        value: AuthorityEnum.INSURANCE_COMPANY,
      },
    ];
  },

  getPermissionsValues: () => {
    const permissions = AccountService.getPermissionsOptions();
    return permissions.map((permission) => permission.value);
  },

  sendNotificationToken: async (token: string) => {
    await ApiService.put(`/accounts/notification-token`, { token });
  },

  getClientInAccountGroupInLocalStorageByClientId: (clientId: string) => {
    const accountGroups = AccountService.getAccountGroupsInLocalStorage();

    return accountGroups.groups.find((group) => group.client.id === clientId);
  },
};

export default AccountService;
