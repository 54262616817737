import { Button } from 'components';
import styled from 'styled-components';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Link } from 'react-router-dom';

interface IFilterButton {
  background?: string;
  textColor?: string;
  isSelected?: boolean;
}

export const Container = styled.div`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 24px 32px;
  margin-left: 60px;
  justify-content: start;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 5px;
  width: 100%;
  max-width: 1156px;
`;

export const RegulatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const RegulatorTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const RegulatorName = styled.span`
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #6a758b;
`;

export const RegulatorTimeValue = styled.span`
  color: #3ba8a8;

  font-family: 'Montserrat';
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const RegulatorTimeLabel = styled.span`
  width: 46px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
  color: #6a758b;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000000;
`;

export const TitleMetrics = styled.div`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000000;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ArrowForwardIcon = styled(ArrowForwardIosRoundedIcon)`
  font-size: 18px !important;
`;

export const ArrowBackIcon = styled(ArrowBackIosRoundedIcon)`
  font-size: 16px !important;

  :hover {
    cursor: pointer;
  }
`;

export const BackButton = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: #000;

  :hover {
    cursor: pointer;
    color: #737791;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-width: 940px;
  max-width: 1156px;
  height: 90%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 15px;
  border: 1px solid #f8f9fa;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
`;

export const FilterButton = styled(Button)<IFilterButton>`
  border-radius: 6px !important;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600 !important;
  text-align: center;
  text-transform: none !important;
  background: ${({ background, isSelected }) => isSelected && background} !important;
  color: ${({ textColor, isSelected }) => isSelected && textColor} !important;
  border-color: ${({ textColor, isSelected }) => isSelected && textColor} !important;
`;

export const FilterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 0 20px 10px 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
`;
