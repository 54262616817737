import JsZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import OthersDocumentsIconSvg from 'assets/svg/icon-others-documents.svg';
import { DocumentTypeEnum, IDocument } from 'pages/SchedulerList/components/Inspection';
import DateService from 'services/DateService/dateService';
import ApiService from 'services/ApiService';
import CubeService from 'services/CubeService/cubeService';
import { IDocumentService, IFetchDocument, IconType } from './documentService.types';

const DocumentService: IDocumentService = {
  fetchDocuments: async (document) => {
    const { url, name, path } = document;
    const response = await axios.get(`${url}?x-request=xhr`, {
      responseType: 'blob',
    });
    return {
      blob: response.data,
      fileName: name,
      fileType: path.split('.')[1],
    };
  },

  downloadDocuments: (documents) => {
    return Promise.all(
      documents.map((document) => {
        const { url, name, path } = document;
        return DocumentService.fetchDocuments({ url, name, path });
      })
    );
  },

  exportFileZip: (fetchDocuments: IFetchDocument[], fileZipName: string) => {
    const zip = JsZip();
    const nameCountMap: { [key: string]: number } = {};

    fetchDocuments.forEach((fetchDocument) => {
      let { fileName } = fetchDocument;
      const { fileType } = fetchDocument;
      const { blob } = fetchDocument;

      if (nameCountMap[fileName]) {
        nameCountMap[fileName]++;
        fileName = `${fileName}-${nameCountMap[fileName]}`;
      } else {
        nameCountMap[fileName] = 1;
      }

      zip.file(`${fileName}.${fileType}`, blob);
    });

    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      const fileName = `${fileZipName}.zip`;
      return saveAs(zipFile, fileName);
    });
  },

  downloadFileZip: async (documents: IDocument[], fileZipName: string) => {
    const responseFetchDocuments = await DocumentService.downloadDocuments(documents);
    return DocumentService.exportFileZip(responseFetchDocuments, `sinistro-docs-${fileZipName}`);
  },

  openPdfinNewTab: async (documentData: IDocument) => {
    const { url, name, path } = documentData;
    const response = await DocumentService.fetchDocuments({ url, name, path });
    const blob = await response.blob;
    const data = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(blob);
    });

    const pdfUrl = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));

    if (documentData) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`<iframe src=${pdfUrl} width="100%" height="100%" title="Document" />`);
      }
    }
  },

  downloadDocument: (doc: IDocument) => {
    const link = document.createElement('a');
    link.href = doc.url;
    link.download = doc.path;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  downloadXmlDocument: (url, filename) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erro ao baixar o arquivo: ${response.status} ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.download = `download-${filename}.xml`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Erro:', error);
      });
  },

  transformImageListTypeInInspectionDocument: (documents, totalDocumentsWithOriginUploadKite, documentCategory) => {
    const inspectionDocuments = documents.map((document, index) => {
      const actualDocumentIndex = index + 1;
      const uploadIndex = actualDocumentIndex + totalDocumentsWithOriginUploadKite;
      const filename = document.file?.name.split('.')[0] || `upload document ${uploadIndex}`;
      return {
        name: filename,
        description: filename,
        step: actualDocumentIndex,
        origin: 'UPLOAD_KITE',
        base64: document.dataURL,
        file: document.file,
        type: DocumentTypeEnum.FILE,
        category: documentCategory,
      };
    });

    return inspectionDocuments;
  },

  transformDocuments: (documents) => {
    return documents.map((document, index) => {
      const actualDocumentIndex = index + 1;
      return {
        name: `arval-return-vehicle ${actualDocumentIndex}`,
        description: `upload contract ${actualDocumentIndex}`,
        step: actualDocumentIndex,
        origin: 'UPLOAD_KITE',
        base64: document.dataURL,
        file: document.file,
        type: DocumentTypeEnum.FILE,
      };
    });
  },

  handleBase64: (document) => {
    const array = document.split(',');

    if (array.length > 1) {
      return document;
    }

    return `data:application/xlsx;base64,${array[0]}`;
  },

  convertToBlob: async (documentUri: string | undefined) => {
    if (!documentUri) {
      throw new Error('Document undefined');
    }

    const base64 = DocumentService.handleBase64(documentUri);

    const response = await fetch(base64);

    return response.blob();
  },

  uploadDocumentsToAwsS3: async (id, documents) => {
    const uploadPromises = documents.map(async (document) => {
      try {
        if (document.base64 && document.file) {
          const documentBlob = await DocumentService.convertToBlob(document.base64);
          const documentToUpload = {
            id,
            name: document.name,
            description: document.description,
            document: documentBlob,
            documentType: document.file.type,
            extension: `${document.file.name.split('.')[1]}`,
          };

          const { path } = await CubeService.uploadDocumentToS3(documentToUpload);
          return { ...document, path };
        }

        return { ...document };
      } catch {
        return { ...document };
      }
    });

    return Promise.all(uploadPromises);
  },

  filterAllDocumentsUploadedToAwsS3: (documents) => {
    return documents.filter((document) => document.path);
  },

  saveDocuments: async (inspectionId, inspectionDocuments) => {
    const { data } = await ApiService.post('/files', {
      files: inspectionDocuments,
      inspectionId,
    });

    return data.files;
  },

  uploadDocuments: async (inspectionId, document, totalDocumentsWithOriginUploadKite, documentCategory) => {
    const inspectionDocuments = DocumentService.transformImageListTypeInInspectionDocument(
      document,
      totalDocumentsWithOriginUploadKite,
      documentCategory
    );
    const documentsResolved = await DocumentService.uploadDocumentsToAwsS3(inspectionId, inspectionDocuments);
    const documentsUploaded = DocumentService.filterAllDocumentsUploadedToAwsS3(documentsResolved);
    return DocumentService.saveDocuments(inspectionId, documentsUploaded);
  },

  sendDocuments: async (id, documents) => {
    const transformedDocuments = DocumentService.transformDocuments(documents);
    const documentsResolved = await DocumentService.uploadDocumentsToAwsS3(id, transformedDocuments);
    return DocumentService.filterAllDocumentsUploadedToAwsS3(documentsResolved);
  },

  sendResaleDocument: async (inspectionId, baseXmlPath) => {
    const { data } = await ApiService.post(`/budget/process-xml`, {
      inspectionId,
      baseXmlPath,
    });

    return data.url;
  },

  convertDocumentToCSV: async (id: string | undefined) => {
    await ApiService.get(`/budget/xlsx/${id}`);
  },

  generateReportCSV: async ({ ids, companyId }) => {
    const { data } = await ApiService.post('/budget/csv', {
      ids,
      companyId,
    });

    return data;
  },

  downloadReportCsvZip: async (reportCsv) => {
    const { path } = await DocumentService.generateReportCSV(reportCsv);
    const report = DocumentService.getReportObjectFromUrl(path);
    const responseFetchDocuments = await DocumentService.downloadDocuments(report);
    return DocumentService.exportFileZip(
      responseFetchDocuments,
      `relatorio_atendimentos_${DateService.formatDate(new Date().toISOString())}`
    );
  },

  getFilenameFromUrl: (url: string): string => {
    const segments = url.split('/');
    return segments.pop() || '';
  },

  removeFileExtension: (filename: string): string => {
    const nameParts = filename.split('.');
    nameParts.pop();
    return nameParts.join('.');
  },

  getReportObjectFromUrl: (urls: string[]) => {
    return urls.map((url: string) => {
      const fileName = DocumentService.getFilenameFromUrl(url);
      const fileNameWithoutExtension = DocumentService.removeFileExtension(fileName);

      return {
        url,
        name: `csv_daytona_${fileNameWithoutExtension}`,
        path: fileName,
      };
    });
  },

  documentIcon: (typeDocument: string) => {
    let iconSrc = OthersDocumentsIconSvg;

    IconType.forEach((icon) => {
      if (icon.type === typeDocument) {
        iconSrc = icon.icon;
      }
    });

    return iconSrc;
  },

  deleteDocuments: async (filesIds: string[], inspectionId: string) => {
    await ApiService.delete('/files', {
      data: {
        filesIds,
        inspectionId,
      },
    });
  },
};

export default DocumentService;
