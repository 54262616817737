import DrawerContent from './DrawerContent';
import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';
import DrawerRoot from './DrawerRoot';
import DrawerSubtitle from './DrawerSubtitle';
import DrawerTitle from './DrawerTitle';

export const Drawer = {
  Root: DrawerRoot,
  Title: DrawerTitle,
  Header: DrawerHeader,
  Subtitle: DrawerSubtitle,
  Content: DrawerContent,
  Footer: DrawerFooter,
};

export * from './DrawerProps.types';
