import { ICompanySelectOptions, IOptions } from 'components/Select/Select.types';
import { IInspectionPlatform } from 'pages/SchedulerList/components/Inspection';

export interface IInspectionPlatformService {
  isCAPInspection(IInspectionPlatform: string | undefined): boolean;
  isSORTInspection(IInspectionPlatform: string | undefined): boolean;
  hasSORTInspection(inspectionPlatforms: IInspectionPlatform[]): boolean;
  hasPlatformCAP(inspectionsPlatformsList: IOptions[], inspectionPlatformSelected: string): boolean;
  companyInspectionPlatformIsCap: (companySelected: ICompanySelectOptions) => boolean;
}

export enum InspectionPlatformEnum {
  SORT = 'SORT',
  WEBAPP = 'WEBAPP',
  CAP = 'CAP',
}
