import styled from 'styled-components';
import noMapIlustration from '../../../../../../assets/svg/no-map.png';

export const GeolocationCardWrapper = styled.div`
  width: 100%;
  height: 480px;
  border-radius: 5px;
  overflow: hidden;
`;

export const GeolocationNotFound = styled.div`
  background-image: url(${noMapIlustration});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 480px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;
