import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import searchNotFoundIllustrator from 'assets/svg/search-with-no-result.svg';
import detailsEmptyAnimation from 'assets/svg/attend-details-default.svg';
import { Label } from 'components';
import { InputAdornment } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Search } from '@material-ui/icons';
import { getInspectionIdFromPathname, getInspectionIdFromOldPathname } from 'utils/inspectionHandler';
import { useFilterInspections } from 'hooks/Inspections/InspectionsHooks';
import { useDebounce } from 'hooks/Debounce/DebounceHook';
import Loading from 'components/Loading';
import DateService from 'services/DateService/dateService';
import { IOptions, ICompanySelectOptions } from 'components/Select/Select.types';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import moment from 'moment';
import { TextField } from 'components/TextField/TextField';
import { formValidations } from 'utils/form/validations';
import CompanySelectService from 'services/CompanySelectService/CompanySelectService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import InspectionPlatformService from 'services/InspectionPlatformService/inspectionPlatformService';
import { useAppSelector } from 'hooks/hooks';
import { List, NoContentList } from './components';
import { GetInspectionCardState, GetSearchFromQueryParams } from './SchedulerList.types';
import SchedulerListRouter from './SchedulerList.router';
import { FormWrapper, ListWrapper } from './components/List/List.styles';
import { ICompany, IInspection, IStatus, InspectionLink } from './components/Inspection';
import Inspection from './components/Inspection/Inspection';
import { MenuButton } from './components/MenuButton/MenuButton';
import {
  DetailsEmptyContainer,
  DetailsEmptyIllustration,
  DetailsEmptyAnimationSubTitle,
  DetailsEmptyAnimationTitle,
} from './components/Details/Details.styles';
import {
  SchedulerListWrapper,
  SchedulerListLeft,
  SchedulerListRight,
  SchedulerListTitle,
  SelectContainer,
  SelectWrapper,
  SelectDate,
  SchedulerListTitleContainer,
  SchedulerListLeftContent,
} from './SchedulerList.styles';

const inputProps = {
  search: {
    'data-testid': 'scheduler-search',
  },
  date: {
    'data-testid': 'input-date',
  },
};

const InputProps = {
  search: {
    startAdornment: (
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    ),
  },
};

export const SchedulerList = () => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const { getAllCompaniesForActiveProfile, getAllCompaniesIdsForActiveProfile } = useActiveProfile();
  const { isFeatureToggleActive } = useFeatureToggles();
  const [startDateSchedule, setStartDateSchedule] = useState<string>('');

  const [finishDateSchedule, setFinishDateSchedule] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedInspectionId, setSelectedInspectionId] = useState<string>('');
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
  const [companiesList, setCompaniesList] = useState<IOptions[]>([]);
  const [statusSelected, setStatusSelected] = useState<string[]>([]);
  const [statusList, setStatusList] = useState<IOptions[]>([]);
  const schedulerListMenuIsOpen = useAppSelector((state) => state.schedulerListMenu.isOpen);
  const history = useHistory();

  const [companySelected, setCompanySelected] = useState<ICompanySelectOptions>({
    name: '',
    value: '',
    inspectionPlatforms: [],
  });

  const { register, errors } = useForm({
    mode: 'onChange',
  });

  const debounceSearch = useDebounce<string>(searchKeyword);
  const debounceCompanies = useDebounce<string[]>(companiesSelected);
  const debounceStatus = useDebounce<string[]>(statusSelected);

  const { inspectionsData, isLoading, inspectionsNotFound, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useFilterInspections(debounceSearch, debounceCompanies, debounceStatus, startDateSchedule, finishDateSchedule);

  const getCompaniesIdForQueryParam = useCallback(
    (companiesId: string[] | undefined): string => {
      return companiesId ? companiesId.join(',') : companiesSelected.join(',');
    },
    [companiesSelected]
  );

  const getStatusIdForQueryParam = useCallback(
    (statusIds: string[] | undefined): string => {
      return statusIds ? statusIds.join(',') : statusSelected.join(',');
    },
    [statusSelected]
  );

  const addSearchToQueryParams = useCallback(
    (companiesId?: string[], statusIds?: string[]): void => {
      const searchParam = `?q=${searchKeyword}&companiesId=${getCompaniesIdForQueryParam(
        companiesId
      )}&statusIds=${getStatusIdForQueryParam(
        statusIds
      )}&startDateSchedule=${startDateSchedule}&finishDateSchedule=${finishDateSchedule}`;
      history.push({ search: searchParam });
    },
    [
      history,
      searchKeyword,
      getCompaniesIdForQueryParam,
      getStatusIdForQueryParam,
      finishDateSchedule,
      startDateSchedule,
    ]
  );

  const getStatus = useCallback(async (): Promise<IOptions[]> => {
    try {
      const status = await InspectionStatusService.getStatus();
      return statusToOptions(status);
    } catch (error) {
      toast.error('Problema ao carregar os status, tente novamente.');
      return [];
    }
  }, []);

  const statusToOptions = (status: IStatus[]): IOptions[] => {
    return status.map(({ id, description: name }) => ({
      value: id,
      name,
    }));
  };

  const isCurrentInspectionSelected = useCallback(
    (inspection: IInspection): boolean => inspection.id === selectedInspectionId,
    [selectedInspectionId]
  );

  const getInspectionCardState = (inspection: IInspection): GetInspectionCardState =>
    isCurrentInspectionSelected(inspection) ? 'open' : 'closed';

  const getSearchFromQueryParams = useCallback((): GetSearchFromQueryParams => {
    const { search } = window.location;
    const query = search.match(/q=([^&]*)/);
    const companiesId = search.match(/companiesId=([^&]*)/);
    const statusIds = search.match(/statusIds=([^&]*)/);

    return {
      searchString: query ? decodeURI(query[1]) : query,
      searchCompaniesId: companiesId ? decodeURI(companiesId[1]) : companiesId,
      searchStatusIds: statusIds ? decodeURI(statusIds[1]) : statusIds,
    };
  }, []);

  const removeNonAlphanumericChars = (input: string): string => {
    return input.replace(/[^A-Za-z0-9]/g, '');
  };

  const getCompaniesIdFromQueryParamIfDifferentEmpty = useCallback(
    (searchCompaniesId: string): string[] => {
      return searchCompaniesId !== '' ? searchCompaniesId.split(',') : getAllCompaniesIdsForActiveProfile();
    },
    [getAllCompaniesIdsForActiveProfile]
  );

  const getStatusIdsFromQueryParamIfDifferentEmpty = useCallback(
    (searchStatusIds: string): string[] => {
      return searchStatusIds !== '' ? searchStatusIds.split(',') : statusList.map((s) => s.value);
    },
    [statusList]
  );

  const getInspectionsFromQueryParam = useCallback(async (): Promise<void> => {
    const { searchString, searchCompaniesId, searchStatusIds } = getSearchFromQueryParams();
    if (searchString !== null) {
      setSearchKeyword(removeNonAlphanumericChars(searchString));
    }

    if (searchCompaniesId !== null) {
      setCompaniesSelected(getCompaniesIdFromQueryParamIfDifferentEmpty(searchCompaniesId));
    }

    if (searchStatusIds !== null) {
      setStatusSelected(getStatusIdsFromQueryParamIfDifferentEmpty(searchStatusIds));
    }
  }, [
    getSearchFromQueryParams,
    getCompaniesIdFromQueryParamIfDifferentEmpty,
    getStatusIdsFromQueryParamIfDifferentEmpty,
  ]);

  const getInspectionIdFromPath = useCallback(() => {
    const pathInspectionId = isFeatureToggleActive('KITE_DETAILS_V2')
      ? getInspectionIdFromPathname(pathname)
      : getInspectionIdFromOldPathname(pathname);

    if (!selectedInspectionId) {
      pathInspectionId && setSelectedInspectionId(pathInspectionId);
    }
  }, [isFeatureToggleActive, pathname, selectedInspectionId]);

  const onClickInspection = (id: string): void => {
    setSelectedInspectionId(id);

    if (isFeatureToggleActive('KITE_DETAILS_V2')) {
      history.push(`${path}/v2/detalhes/${id}${window.location.search}`);
    } else {
      history.push(`${path}/detalhes/${id}${window.location.search}`);
    }
  };

  const onSearchKeyword = (value: string): void => {
    setSearchKeyword(removeNonAlphanumericChars(value));
  };

  const companyToOptions = (companies: ICompany[]): ICompanySelectOptions[] => {
    return companies.map(({ id: value, name, inspectionPlatforms }) => ({
      value,
      name,
      inspectionPlatforms,
    }));
  };

  const getCompanies = useCallback(async (): Promise<IOptions[]> => {
    try {
      const companies = getAllCompaniesForActiveProfile();

      return companyToOptions(companies);
    } catch (error) {
      toast.error('Problema ao carregar as operações, tente novamente.');

      return [];
    }
  }, [getAllCompaniesForActiveProfile]);

  const findCompanyByValue = (value: string, companies: ICompanySelectOptions[]): ICompanySelectOptions | undefined => {
    return companies.find((company) => company.value === value);
  };

  const companiesSetUp = useCallback(async (): Promise<void> => {
    const companyToSet =
      companiesSelected.length === 1 ? findCompanyByValue(companiesSelected[0], companiesList) : undefined;

    setCompanySelected(companyToSet || CompanySelectService.getDefaultCompany());
  }, [companiesSelected, companiesList]);

  const hasOnlySORTInspection = (): boolean => {
    const platforms = companySelected.inspectionPlatforms;
    if (platforms && platforms.length === 1) {
      return InspectionPlatformService.hasSORTInspection(platforms);
    }
    return false;
  };

  const onChangeCompaniesSelect = (companiesId: string[]) => {
    setCompaniesSelected(companiesId);
    addSearchToQueryParams(companiesId, statusSelected);
    setStartDateSchedule('');
    setFinishDateSchedule('');
  };

  const onChangeStatusSelect = (status: string[]) => {
    setStatusSelected(status);
    addSearchToQueryParams(companiesSelected, status);
    setStartDateSchedule('');
    setFinishDateSchedule('');
  };

  const onChangeDates = (date: string) => {
    setStartDateSchedule(DateService.getDateFromToday());
    setFinishDateSchedule(formatDateFinalTime(date));
  };

  const formatDateFinalTime = (date: string): string => {
    return date ? DateService.getFinalDateFromToday(date) : '';
  };

  const hasManyCompanies = (): boolean => {
    return companiesList.length > 1;
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const getTodayDate = () => {
    const todayDate = new Date();
    return moment(todayDate).format('YYYY-MM-DD');
  };

  const getNextMonthDate = () => {
    const todayDate = new Date();
    return moment(todayDate).add(30, 'days').format('YYYY-MM-DD');
  };

  const limitDateRange = () => {
    const dateInput = document.getElementById('date');
    dateInput?.setAttribute('min', getTodayDate());
    dateInput?.setAttribute('max', getNextMonthDate());
  };

  useEffect(() => {
    (async (): Promise<void> => setCompaniesList(await getCompanies()))();
  }, [getCompanies]);

  useEffect(() => {
    (async (): Promise<void> => {
      const status = await getStatus();
      setStatusList(status);
      setStatusSelected(status.map((stat) => stat.value));
    })();
  }, [getStatus]);

  useEffect(() => {
    setCompaniesSelected(getAllCompaniesIdsForActiveProfile());
  }, [getAllCompaniesIdsForActiveProfile]);

  useEffect(() => {
    getInspectionsFromQueryParam();
  }, [getInspectionsFromQueryParam, getAllCompaniesIdsForActiveProfile]);

  useEffect(() => {
    getInspectionIdFromPath();
  }, [getInspectionIdFromPath]);

  useEffect(() => {
    (async (): Promise<void> => {
      await companiesSetUp();
    })();
  }, [companiesSetUp]);

  return (
    <SchedulerListWrapper data-testid="scheduler-list" data-isopen={schedulerListMenuIsOpen}>
      <SchedulerListLeft>
        <SchedulerListTitleContainer>
          <SchedulerListTitle>Atendimentos</SchedulerListTitle>
          <MenuButton />
        </SchedulerListTitleContainer>
        <FormWrapper>
          <TextField
            ariaLabelledby="search"
            InputProps={InputProps.search}
            inputProps={inputProps.search}
            onKeyUp={({ target }: React.ChangeEvent<HTMLInputElement>): void => onSearchKeyword(target.value)}
            placeholder="Buscar por identificador"
            type="text"
            value={searchKeyword}
            variant="outlined"
          />

          {hasManyCompanies() && (
            <SelectContainer>
              <SelectWrapper
                testID="select-companies"
                labelId="companies"
                label="Operação"
                placeholder="Selecione uma operação"
                className="animate fadeIn"
                options={companiesList}
                selectedOptions={companiesSelected}
                onChange={({ target: { value } }): void => onChangeCompaniesSelect(value as string[])}
                multiple
              />
            </SelectContainer>
          )}

          <SelectContainer>
            <SelectWrapper
              testID="select-status"
              labelId="status"
              label="Status"
              placeholder="Selecione um status"
              className="animate fadeIn"
              options={statusList}
              selectedOptions={statusSelected}
              onChange={({ target: { value } }): void => onChangeStatusSelect(value as string[])}
              multiple
            />
          </SelectContainer>

          {hasOnlySORTInspection() && (
            <SelectDate>
              <Label htmlFor="date" testID="label-date">
                Filtrar até a data agendada:
              </Label>
              <TextField
                ariaLabelledby="date"
                id="date"
                error={hasError('date')}
                inputProps={inputProps.date}
                inputRef={register(formValidations.default)}
                helperText={errors?.date?.message}
                name="date"
                placeholder="Data"
                role="date"
                type="date"
                state={handleInputState('date')}
                variant="outlined"
                onClick={limitDateRange}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDates(event?.target?.value)}
                onKeyDown={(event: KeyboardEvent) => event.preventDefault()}
              />
            </SelectDate>
          )}
        </FormWrapper>
        <SchedulerListLeftContent>
          {((): JSX.Element => {
            if (isLoading) {
              return (
                <ListWrapper>
                  <Loading width="40%" />
                </ListWrapper>
              );
            }

            if (inspectionsNotFound) {
              return (
                <ListWrapper data-testid="scheduler-not-found">
                  <NoContentList
                    illustration={searchNotFoundIllustrator}
                    title="Nenhum atendimento encontrado"
                    subTitle="Verifique os filtros de pesquisa!"
                  />
                </ListWrapper>
              );
            }

            return (
              <List
                hasMore={hasNextPage}
                isLoading={isFetchingNextPage}
                loadMore={fetchNextPage}
                hasManyCompanies={hasManyCompanies()}
                isTransmissionInspection={hasOnlySORTInspection()}
              >
                {inspectionsData &&
                  inspectionsData.pages.map(({ data: { inspections } }) => {
                    return inspections.map((inspection) => {
                      return (
                        <InspectionLink key={inspection.id} onClick={(): void => onClickInspection(inspection.id)}>
                          <Inspection inspection={inspection} type={getInspectionCardState(inspection)} />
                        </InspectionLink>
                      );
                    });
                  })}
              </List>
            );
          })()}
        </SchedulerListLeftContent>
      </SchedulerListLeft>
      <SchedulerListRight>
        {selectedInspectionId ? (
          <SchedulerListRouter inspectionId={selectedInspectionId} />
        ) : (
          <DetailsEmptyContainer data-testid="details-empty-animation">
            <DetailsEmptyIllustration src={detailsEmptyAnimation} />

            <div>
              <DetailsEmptyAnimationTitle>Detalhes do Atendimento</DetailsEmptyAnimationTitle>
              <DetailsEmptyAnimationSubTitle>Selecione um atendimento ao lado</DetailsEmptyAnimationSubTitle>
            </div>
          </DetailsEmptyContainer>
        )}
      </SchedulerListRight>
    </SchedulerListWrapper>
  );
};
