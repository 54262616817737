import styled from 'styled-components';
import { Button, TextField as TextFieldComponent } from 'components';
import MenuComponent from '@mui/material/Menu';
import MenuItemComponent from '@mui/material/MenuItem';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TextField = styled(TextFieldComponent)`
  flex: 1;
`;

export const SelectionParts = styled.div`
  border-radius: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const SelectionPartsHeader = styled.div`
  background: #fff;
  height: 60px;
  padding: 0 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ced6e5;
`;

export const Title = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  color: #000;
`;

export const SelectionPartsBody = styled.div`
  background: #f9f9f9;
  flex: 1;
  overflow-y: auto;
`;

export const ContainerIllustration = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const Illustration = styled.img`
  height: 160px;
`;

export const TitleIllustration = styled.span`
  color: #6a758b;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
`;

export const SubtitleIllustration = styled.div`
  color: #6a758b;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  width: 260px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerParts = styled.div`
  display: flex;
  padding: 20px;
  overflow-y: auto;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
`;

export const PartCard = styled.div`
  padding: 12px;
  width: 300px;
  height: 97px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border: 1px solid #dbd9d9;
  }

  -webkit-box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
`;

export const GroupPartTitle = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 10px;
  color: #000000;
`;

export const PartTitle = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const MenuButton = styled(Button)`
  width: auto;
  border-radius: 6px !important;
  padding: 5px 0px 5px 15px !important;

  .MuiButton-endIcon {
    padding: 9px 8px !important;
    border-left: 2px solid !important;
    border-right: 2px !important ;
    margin: 0px !important;
  }
`;

export const Menu = styled(MenuComponent)`
  .MuiMenu-paper {
    width: 190px;
    display: flex !important;
    flex-direction: column !important;
    gap: 5px !important;
  }
`;

export const MenuItem = styled(MenuItemComponent)`
  display: flex !important;
  justify-content: left !important;
  gap: 6px !important;
  color: #6a758b !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
`;
