import React from 'react';
import { IPartGroup, IVehicleArea, IVehicleType } from 'services/ApiService/PartService/partsService.types';
import { BackButton } from 'components';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import {
  Area,
  CarContainer,
  Container,
  EmptyComtainer,
  PartGroupItem,
  PartGroupList,
  PartGroupListContainer,
  PartGroupTitle,
  VehicleAreaItem,
  VehicleAreaList,
  VehicleAreaListContainer,
  VehicleAreaTitle,
} from './SelectionCarAreaAndPartGroup.styles';

interface ISelectionCarAreaAndPartGroupProps {
  vehicleType: IVehicleType | undefined;
  vehicleArea: IVehicleArea | undefined;
  hasVehicleAreaSelected: boolean;
  isVehicleTypeLoading: boolean;
  isVehicleAreaLoading: boolean;
  handleChangeVehicleArea: (vehicleAreaId: IVehicleArea | null) => void;
  handleChangePartGroup: (partGroup: IPartGroup | null) => void;
}

const SelectionCarAreaAndPartGroup = ({
  vehicleType,
  vehicleArea,
  hasVehicleAreaSelected,
  isVehicleTypeLoading,
  isVehicleAreaLoading,
  handleChangeVehicleArea,
  handleChangePartGroup,
}: ISelectionCarAreaAndPartGroupProps) => {
  const isEmptyVehicleAreas = vehicleType?.vehicleAreas.length === 0 || vehicleType?.vehicleAreas === undefined;
  const isEmptyPartGroups = vehicleArea?.partGroups.length === 0 || vehicleArea?.partGroups === undefined;

  const handleEmptyMessage = (message: string) => {
    return (
      <EmptyComtainer>
        <span>{message}</span>
      </EmptyComtainer>
    );
  };

  const handleLoading = () => {
    return Array.from({ length: 4 }).map((_, index) => (
      <Skeleton key={index} variant="rounded" width={235} height={46} />
    ));
  };

  const handleVehicleAreaList = () => {
    return (
      <VehicleAreaListContainer>
        <div>
          <VehicleAreaTitle>Região do veículo</VehicleAreaTitle>
        </div>
        {(() => {
          if (isVehicleTypeLoading) {
            return <VehicleAreaList>{handleLoading()}</VehicleAreaList>;
          }

          if (isEmptyVehicleAreas) {
            return handleEmptyMessage('Nenhuma região encontrada');
          }

          return (
            <VehicleAreaList>
              {vehicleType?.vehicleAreas.map((area) => (
                <VehicleAreaItem key={area.id} onClick={() => handleChangeVehicleArea(area)}>
                  <img src={area.imagePath} alt="" />
                  <span>{area.description}</span>
                </VehicleAreaItem>
              ))}
            </VehicleAreaList>
          );
        })()}
      </VehicleAreaListContainer>
    );
  };

  const goBackToVehicleAreaList = () => {
    handleChangeVehicleArea(null);
    handleChangePartGroup(null);
  };

  const handlePartGroupList = () => {
    return (
      <PartGroupListContainer>
        <div>
          <BackButton onClick={() => goBackToVehicleAreaList()} />
          <PartGroupTitle>{vehicleArea?.description}</PartGroupTitle>
        </div>
        {(() => {
          if (isVehicleAreaLoading) {
            return handleLoading();
          }

          if (isEmptyPartGroups) {
            return handleEmptyMessage('Nenhum grupo de peças encontrado');
          }

          return (
            <PartGroupList>
              {vehicleArea?.partGroups.map((partGroup) => (
                <PartGroupItem key={partGroup.id} onClick={() => handleChangePartGroup(partGroup)}>
                  <span>{partGroup.description}</span>
                </PartGroupItem>
              ))}
            </PartGroupList>
          );
        })()}
      </PartGroupListContainer>
    );
  };

  return (
    <Container>
      <CarContainer>
        <svg width="452" height="282" viewBox="0 0 452 282" fill="none">
          <rect width="452" height="282" fill="url(#pattern0)" />

          {vehicleType?.vehicleAreas.map((area) => {
            return (
              <Tooltip key={area.id} title={area.description} placement="top">
                <Area
                  d={area.coordinates}
                  fill="#0E86CA"
                  stroke="#0E86CA"
                  fillOpacity="0"
                  strokeOpacity="0"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                  onClick={() => handleChangeVehicleArea(area)}
                />
              </Tooltip>
            );
          })}

          <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image0_12897_14505" transform="matrix(0.000577679 0 0 0.000925926 -0.0545723 0)" />
            </pattern>
            <image id="image0_12897_14505" width="1920" height="1080" xlinkHref={vehicleType?.imagePath} />
          </defs>
        </svg>
      </CarContainer>

      {hasVehicleAreaSelected ? handlePartGroupList() : handleVehicleAreaList()}
    </Container>
  );
};

export default SelectionCarAreaAndPartGroup;
