import { TableCell as MuiTableCell, TableRow as MuiTableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const TableCellSticky = withStyles({
  root: {
    borderWidth: 1,
    borderColor: 'rgba(237, 237, 237, .9)',
    borderStyle: 'solid',
    position: 'sticky',
    background: 'white',
    '@media (min-width:800px)': { left: '88%' },
    '@media (min-width:900px)': { left: '89%' },
    '@media (min-width:1000px)': { left: '90%' },
    '@media (min-width:1100px)': { left: '91.5%' },
    '@media (min-width:1200px)': { left: '92%' },
    '@media (min-width:1300px)': { left: '92.5%' },
    '@media (min-width:1400px)': { left: '93%' },
    '@media (min-width:1500px)': { left: '94%' },
    '@media (min-width:1600px)': { left: '94.5%' },
    '@media (min-width:1700px)': { left: '95%' },
  },
  body: {
    color: '#000000',
    fontFamily: 'Montserrat, sans-serif',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const TableCell = withStyles({
  root: {
    borderWidth: 1,
    borderColor: 'rgba(237, 237, 237, .9)',
    borderStyle: 'solid',
  },
  body: {
    color: '#000000',
    fontFamily: 'Montserrat, sans-serif',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const TableRow = withStyles({
  selected: {
    backgroundColor: '#ced6e5 !important',
    textShadow: '1px 0px 0px #000000',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f0f3fa !important',
    },
  },
})(MuiTableRow);
