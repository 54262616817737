/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import DescriptionIcon from '@mui/icons-material/Description';

interface IDocumentProps {
  orientation: string;
  positionLoading?: boolean;
}

interface ILoadingProps {
  width: string;
  height: string;
  orientation: string;
}

export const DocumentWrapper = styled.div<IDocumentProps>`
  display: ${({ orientation }): string => (orientation === 'horizontal' ? 'contents' : 'flex')};
  flex-direction: ${({ orientation }): string => (orientation === 'horizontal' ? 'row' : 'column')};
  max-height: 60vh;
  overflow-x: auto;
  padding: 0 5px;
  width: ${({ orientation }): string => (orientation === 'horizontal' ? 'auto' : '40%')};
  ${({ orientation }): string => (orientation === 'vertical' ? 'row-gap: 10px' : '')};

  @media (max-width: 1366px) {
    width: 30%;
  }
`;

export const Thumbnail = styled.img<IDocumentProps>`
  ${({ positionLoading }): string => (positionLoading ? 'position: absolute;' : '')}
  cursor: pointer;
  border-radius: 4px;
`;

export const Loading = styled(Skeleton)<ILoadingProps>`
  width: ${({ width }): string => width}px !important;
  height: ${({ orientation, height }): string => (orientation === 'horizontal' ? `${height}px` : '400px')} !important;
  margin: 0 20px 0 0;
  border-radius: 4px;
  padding: ${({ orientation }): string => (orientation === 'horizontal' ? '0 5px' : '5px 0')};
`;

export const LightboxWrapper = styled.div``;

export const DocumentCardWrapper = styled.div`
  height: fit-content;
  background: #ffffff;
  border: 1px solid #dbd9d9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0px 15px 10px 15px;
`;

export const DocumentCard = styled.div`
  display: flex;
  width: 160px;
  height: 144px;
  justify-content: center;
  align-items: center;
`;

export const DocumentIcon = styled(DescriptionIcon)`
  width: 5rem !important;
  height: 5rem !important;
`;

export const DocumentIconStyle = styled.img`
  width: 5rem !important;
  height: 5rem !important;
  cursor: pointer;
`;

export const DocumentCardTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
  height: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  margin-top: -15px;
`;

export const DocumentIndexBadge = styled.div`
  background: #ffffff;
  border: 1px solid #dbd9d9;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -13px;
  left: -28px;
`;
