import React, { useCallback, useEffect, useState } from 'react';
import { Drawer } from 'components/Drawer';
import { AnchorTypesEnum } from 'components/Drawer/DrawerProps.types';
import { Button, Label, TextField } from 'components';
import { InputAdornment } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import { Search } from '@material-ui/icons';
import { useDebounce } from 'hooks/Debounce/DebounceHook';
import WorkshopService from 'services/ApiService/WorkshopService/WorkshopService';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import NoPartsSVG from 'assets/svg/metrics-result-not-found.svg';
import { IWorkshop } from 'services/ApiService/WorkshopService/WorkshopService.types';
import {
  ActionsContainer,
  ContainerIllustration,
  FilterContainer,
  Header,
  Illustration,
  Content,
  LoadingInputContainer,
  Separator,
  SubtitleIllustration,
  TitleIllustration,
  WorkshopCard,
  WorkshopCardCNPJ,
  WorkshopCardName,
} from './WorkshopDrawer.styles';
import CreateWorkshopDrawer from './CreateWorkshopDrawer';

const IllustrationComponent = () => {
  return (
    <ContainerIllustration>
      <Illustration src={NoPartsSVG} alt="" />
      <TitleIllustration>Oficinas</TitleIllustration>
      <SubtitleIllustration>Pesquise uma oficina por CNPJ ou nome e associe ao orçamento.</SubtitleIllustration>
    </ContainerIllustration>
  );
};

const InputProps = (loading: boolean) => {
  return {
    endAdornment: (
      <InputAdornment position="end">
        {loading ? (
          <LoadingInputContainer>
            <CircularProgress />
          </LoadingInputContainer>
        ) : (
          <Search />
        )}
      </InputAdornment>
    ),
  };
};

const inputProps = {
  search: {
    'data-testid': 'workshop-search',
  },
};

interface ISearchWorkshopDrawerProps {
  openDrawer: boolean;
  onCloseDrawer: () => void;
  setSelectedWorkshop: React.Dispatch<React.SetStateAction<IWorkshop | null>>;
}

const SearchWorkshopDrawer = ({ openDrawer, onCloseDrawer, setSelectedWorkshop }: ISearchWorkshopDrawerProps) => {
  const [openCreateWorkshopStep, setOpenCreateWorkshopStep] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const debounceSearch = useDebounce(inputSearch);
  const [searchLoading, setSearchLoading] = useState(false);
  const [workshopList, setWorkshopList] = useState<IWorkshop[]>([]);
  const [workshopSelected, setWorkshopSelected] = useState<IWorkshop | null>(null);

  const isWorkshopsEmpty = workshopList.length === 0;

  const isDisabledSelectWorkshop = workshopSelected === null;

  const handleCloseCreateWorkshopStep = () => {
    setOpenCreateWorkshopStep(false);
  };

  const isSelectedWorkshop = (workshop: IWorkshop) => {
    return !!workshopSelected && workshopSelected.cnpj === workshop.cnpj;
  };

  const handleAddWorkshopInBudget = async () => {
    setSelectedWorkshop(workshopSelected);
    onCloseDrawer();
  };

  const getWorkshops = useCallback(async (query: string) => {
    try {
      setSearchLoading(true);
      const response = await WorkshopService.getByQuery(query);

      setWorkshopList(response);
    } catch (error) {
      toast.error('Erro ao buscar oficinas');
    } finally {
      setSearchLoading(false);
    }
  }, []);

  useEffect(() => {
    if (debounceSearch) {
      getWorkshops(debounceSearch);
    } else {
      setWorkshopList([]);
      setWorkshopSelected(null);
    }
  }, [debounceSearch, getWorkshops]);

  return (
    <Drawer.Root anchor={AnchorTypesEnum.RIGHT} openDrawer={openDrawer} onClose={onCloseDrawer}>
      {openCreateWorkshopStep ? (
        <CreateWorkshopDrawer
          backToSearch={handleCloseCreateWorkshopStep}
          onCloseDrawer={onCloseDrawer}
          setSelectedWorkshop={setSelectedWorkshop}
        />
      ) : (
        <>
          <Header>
            <Drawer.Title>Pesquisar oficina</Drawer.Title>
            <Drawer.Subtitle>
              Cadastre uma oficina ou pesquise por uma já cadastrada para adicionar ao orçamento.
            </Drawer.Subtitle>
            <FilterContainer>
              <Button
                testID="create-workshop"
                text="cadastrar oficina"
                variant="contained"
                onClick={() => setOpenCreateWorkshopStep(true)}
              />
              <Divider>ou</Divider>
              <div>
                <Label>Pesquisar</Label>
                <TextField
                  type="text"
                  ariaLabelledby="search"
                  variant="outlined"
                  placeholder="Buscar oficina por nome ou CNPJ"
                  InputProps={InputProps(searchLoading)}
                  inputProps={inputProps.search}
                  value={inputSearch}
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => setInputSearch(target.value)}
                />
              </div>
            </FilterContainer>
            <Separator />
          </Header>

          <Content>
            {isWorkshopsEmpty ? (
              <IllustrationComponent />
            ) : (
              <>
                {workshopList.map((workshop, index) => (
                  <WorkshopCard
                    key={`${workshop.cnpj}-${index}`}
                    onClick={() => setWorkshopSelected(workshop)}
                    isSelected={isSelectedWorkshop(workshop)}
                  >
                    <WorkshopCardName>{workshop.name}</WorkshopCardName>
                    <WorkshopCardCNPJ>
                      <strong>CNPJ: </strong>
                      {workshop.cnpj}
                    </WorkshopCardCNPJ>
                  </WorkshopCard>
                ))}
              </>
            )}
          </Content>

          <Separator />
          <ActionsContainer>
            <Button variant="outlined" text="cancelar" onClick={() => onCloseDrawer()} />
            <Button
              variant="contained"
              text="confirmar"
              disabled={isDisabledSelectWorkshop}
              onClick={() => handleAddWorkshopInBudget()}
            />
          </ActionsContainer>
        </>
      )}
    </Drawer.Root>
  );
};

export default SearchWorkshopDrawer;
