/* eslint-disable @typescript-eslint/no-explicit-any */
import { cache, mutate } from 'swr';

export const mutateMany = (select: string): Promise<any[]> => {
  const keys = cache.keys();
  const mutations = [];
  const regexp = new RegExp(`(${select})`);
  for (const key of keys) {
    if (regexp.test(key)) {
      mutations.push(mutate(key, null, true));
    }
  }
  return Promise.all(mutations);
};
