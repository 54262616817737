import React, { useEffect, useState } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Geolocation from '../../../../services/GeolocationService/geolocationService';

import { Container } from './Maps.styles';
import { IMaps } from './Maps.types';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const Maps: React.FC<IMaps> = ({ geolocationPhotos, height, width }) => {
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });

  useEffect(() => {
    const { latitude, longitude } = Geolocation.getInitialCoordinatesOfGeolocationPhotos(geolocationPhotos);

    setViewport((value) => ({ ...value, latitude, longitude }));
  }, [geolocationPhotos]);

  return (
    <Container data-testid="map-container" height={height} width={width}>
      <MapGL
        mapboxApiAccessToken={MAPBOX_TOKEN}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
      >
        {geolocationPhotos.map((geolocationPhoto, index) => (
          <Marker key={index} latitude={geolocationPhoto.latitude ?? 0} longitude={geolocationPhoto.longitude ?? 0}>
            <LocationOnIcon color="primary" fontSize="large" />
          </Marker>
        ))}
      </MapGL>
    </Container>
  );
};

export default Maps;
