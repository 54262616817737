import styled from 'styled-components';

export const ContainerLinkNotSent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 230px 0 230px;
`;

export const TitleLinkNotSent = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: #6a758b;
`;

export const DescriptionLinkNotSent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #6a758b;
  text-align: center;

  span {
    font-weight: bold;
  }
`;

export const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DetailsLoadingWrapper = styled.div`
  padding: 40px 30px;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TransmissionContainer = styled.div`
  padding: 30px 40px;
`;
