import React, { useState } from 'react';
import { Drawer } from 'components/Drawer';
import { AnchorTypesEnum } from 'components/Drawer/DrawerProps.types';
import { IVehiclePartPrice, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import PartsList from './DrawersContent/PartsList/PartsList';
import PartLaborCost from './DrawersContent/PartLaborCost/PartLaborCost';

export interface IAddPartsDrawerProps {
  isOpenAddPartsDrawer: boolean;
  partTypeSelected: IVehiclePartType;
  handleChangeIsOpenAddPartsDrawer: (isOpenDrawer: boolean) => void;
  handleChangePartType: (partType: IVehiclePartType | null) => void;
}

const AddPartsDrawer = ({
  isOpenAddPartsDrawer,
  partTypeSelected,
  handleChangeIsOpenAddPartsDrawer,
  handleChangePartType,
}: IAddPartsDrawerProps) => {
  const [partPriceSelected, setPartPriceSelected] = useState<IVehiclePartPrice | null>(null);
  const [isOpenPartLaborCostDrawer, setIsOpenPartLaborCostDrawer] = useState(false);

  const handleChangePartPriceAndIsOpenPartLaborCostDrawer = (
    partPrice: IVehiclePartPrice | null,
    isShowLaborCostDrawer: boolean
  ) => {
    setPartPriceSelected(partPrice);
    setIsOpenPartLaborCostDrawer(isShowLaborCostDrawer);
  };

  const handleCloseDrawer = () => {
    handleChangePartType(null);
    handleChangeIsOpenAddPartsDrawer(false);
  };

  return (
    <Drawer.Root anchor={AnchorTypesEnum.RIGHT} openDrawer={isOpenAddPartsDrawer} onClose={handleCloseDrawer}>
      {isOpenPartLaborCostDrawer ? (
        <PartLaborCost
          partTypeSelected={partTypeSelected}
          partPriceSelected={partPriceSelected}
          handleChangePartPriceAndIsOpenPartLaborCostDrawer={handleChangePartPriceAndIsOpenPartLaborCostDrawer}
          handleCloseAddPartsDrawer={handleCloseDrawer}
        />
      ) : (
        <PartsList
          partTypeSelected={partTypeSelected}
          handleChangePartPriceAndIsOpenPartLaborCostDrawer={handleChangePartPriceAndIsOpenPartLaborCostDrawer}
        />
      )}
    </Drawer.Root>
  );
};

export default AddPartsDrawer;
