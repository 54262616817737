export interface IConsumerService {
  getCompanyConsumersByAccount: () => Promise<ICompanyConsumers>;
  setCompanyConsumersInLocalStorage: (companyConsumers: ICompanyConsumers) => void;
  getCompanyConsumersInLocalStorage: () => ICompanyConsumers;
  deleteCompanyConsumersInLocalStorage: () => void;
}

export interface ICompanyConsumer {
  id: string;
  companyId: string;
  consumer: IConsumer;
}

export interface ICompanyConsumers {
  companyConsumers: ICompanyConsumer[];
}

export interface IConsumer {
  id: string;
  value: string;
  isActive: boolean;
}

export enum ConsumersEnum {
  DAMAGES_ACCESS = 'DAMAGES_ACCESS',
  BUDGETS_ACCESS = 'BUDGETS_ACCESS',
  EDIT_BUDGET_SERVICE_COSTS = 'EDIT_BUDGET_SERVICE_COSTS',
  INSPECTION_CONCLUSION = 'INSPECTION_CONCLUSION',
  SYNCH_PLATFORM = 'SYNCH_PLATFORM',
  ASSOCIATE_REGULATOR = 'ASSOCIATE_REGULATOR',
  GENERATE_CSV = 'GENERATE_CSV',
  ARVAL_BULK_UPDATE = 'ARVAL_BULK_UPDATE',
  INSPECTION_REPORT_MANAGEMENT = 'INSPECTION_REPORT_MANAGEMENT',
  RESALE_ARVAL = 'RESALE_ARVAL',
  INSPECTION_ANALYST = 'INSPECTION_ANALYST',
}
