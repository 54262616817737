import { IAvatar } from 'components/Avatar';
import { IInspectionType } from 'services/InspectionTypeService/inspectionTypeService.types';
import { IProductType } from 'services/ProductTypeService/productTypeService.types';
import { IVehicle } from 'services/ApiService/VehicleService/vehicleService.types';
import { ITransmission } from 'commom/types/Transmission.types';

export interface IInspectionProps {
  inspection: IInspection;
  type?: 'open' | 'closed';
}

export interface IInspection {
  testID?: string;
  id: string;
  code: string;
  customer: ICustomer;
  status: IStatus[];
  currentStatus: IStatus;
  photos: IPhoto[];
  createdAt: string;
  updatedAt: string;
  accountId?: string;
  clientId?: string;
  companyId?: string;
  company?: ICompany;
  files?: IDocument[];
  product?: IProduct;
  productType?: IProductType;
  schedule?: string;
  informations?: IInformation[];
  inspectionType?: IInspectionType;
  inspectionPlatform?: IInspectionPlatform;
  inspectionDetails?: IInspectionDetails;
  inspectionCategoryId?: string;
  inspectionCategory?: IInspectionCategory;
  transmissionSessionId?: string;
  transmissionSession?: ITransmission;
  inspectionNotificationSender?: IInspectionNotificationSender;
  regulator?: IRegulator;
  regulatorId?: string;
  currentResponsible?: IResponsible;
  deductible?: number;
  conclusion?: IConclusion;
  cancellation?: ICancellation;
  shortTokenUrl?: string;
}

export interface IInspectionDetails {
  id: string;
  contract: string;
  agreement: string;
  damageExpertAmountGross: string;
  damageExpertAmountNet: string;
}

export interface ICancellation {
  cancellationMotive: ICancellationMotive;
  description?: string | null;
}

export interface ICancellationMotive {
  motive: string;
}

export interface IInspectionNotificationSender {
  id: string;
  sentMessagesHistory: ISentMessage[];
}

export interface ISentMessage {
  statusId: string;
  notificationSenderId: string;
  notificationSender: INotificationSender;
  createdAt: string;
  updatedAt: string;
}

export interface INotificationSender {
  id: string;
  type: NotificationSenderEnum;
  description: string;
}

export enum NotificationSenderEnum {
  SMS_DEV = 0,
  SMS_TWILIO = 1,
  WHATSAPP = 2,
  EMAIL_TWILIO = 3,
  ZENVIA_WHATSAPP = 4,
}

export interface ICustomer {
  name: string;
  contactName: string;
  phone: string;
}

export interface IProduct {
  plate: string;
  vehicle?: IVehicle;
  canEditVehicle?: boolean;
}

export interface ICompany {
  id: string;
  name: string;
  code: string;
  accounts: string[];
  createdAt: string;
  updatedAt: string;
  photoConsumers?: IPhotoConsumer[];
  productTypes: IProductType[];
  inspectionPlatforms: IInspectionPlatform[];
  inspectionTypes: IInspectionType[];
  inspectionCategories?: IInpectionCategory[];
}

export interface IInspectionPlatform {
  id: string;
  createdAt: string;
  updatedAt: string;
  code: string;
  description: string;
}

export interface IInpectionCategory {
  createdAt: string;
  updatedAt: string;
  id: string;
  code: string;
  name: string;
  statusIds: string[];
  status?: IStatus[];
}

export interface IPhotoConsumer {
  id: string;
  value: string;
}

export interface IPhoto {
  id?: string;
  description: string;
  url: string;
  path: string;
  name: string;
  latitude: number | null;
  longitude: number | null;
  inspectionId?: string;
  comment?: string;
  step?: number;
  origin?: string;
  createdAt?: string;
  updatedAt?: string;
  damageUrl?: string;
  damagePath?: string;
  damages?: IDamage[];
}

export interface IDocument {
  id?: string;
  description: string;
  url: string;
  path: string;
  name: string;
  latitude: number | null;
  longitude: number | null;
  inspectionId?: string;
  origin?: string;
  step?: number;
  createdAt?: string;
  updatedAt?: string;
  isDeleted: boolean;
  type: DocumentTypeEnum;
  category?: DocumentCategoryEnum;
}

export enum DocumentCategoryEnum {
  BUDGET_GROSS = 'BUDGET_GROSS',
  BUDGET_NET = 'BUDGET_NET',
  REPORT = 'REPORT',
}

interface IDamage {
  name: string;
  box: number[];
  score: number;
  area: number;
  parts: {
    name: string;
    box: number[];
    intersectionArea: number;
  };
}

export interface IImageWithSkeleton {
  photo: IPhoto;
}

export interface IInspectionRow {
  label: string;
  value: string;
}

export interface ISubHeader {
  alert: boolean;
}

export interface IStatus {
  description: string;
  date: string;
  type: StatusEnum;
  id: string;
}

export enum PhotoStatesEnum {
  CREATED = 'CREATED',
  SENT = 'SENT',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export enum StatusEnum {
  DEFAULT = 0,
  LINK_SENT = 1,
  PHOTO_RECEIVED = 2,
  INSPECTION_STARTED = 3,
  INSPECTION_FINISHED = 4,
  INSPECTION_CANCELED = 5,
  INSPECTION_EXPIRED = 6,
}

export enum DocumentTypeEnum {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export interface IInformation {
  key: string;
  value: string;
}

export interface IRegulator {
  authority: string;
  id: string;
  role: string;
  username: string;
  avatar?: IAvatar;
}

export interface IGeolocation {
  latitude: number | null;
  longitude: number | null;
}

export interface IInspectionCategory {
  createdAt: string;
  updatedAt: string;
  id: string;
  code: string;
  name: string;
  statusIds: string[];
  status?: IStatus[];
}

export interface IConclusionType {
  id: string;
  description: string;
  code: string;
}

export interface IConclusionReason {
  id: string;
  description: string;
  code: string;
}

export interface IConclusion {
  conclusionType: IConclusionType;
  conclusionReason: IConclusionReason;
  comment: string;
  createdBy?: string;
  createdById?: string;
  updatedAt?: string;
  createdAt?: string;
  isPrivate?: boolean;
}

export interface IResponsible {
  id: string;
  username: string;
  authority: string;
  avatar?: IAvatar;
}
