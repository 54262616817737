import styled from 'styled-components';
import Edit from 'assets/svg/edit-icon.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;

export const EditIcon = styled.div`
  width: 14px;
  height: 15px;
  background-image: url(${Edit});
`;
