import ApiService from 'services/ApiService';
import {
  IPasswordRecoveryService,
  IResponseExpiredToken,
  IResponseNewPassword,
  PasswordRecoveryApplicationEnum,
} from './types';

const PasswordRecoveryService: IPasswordRecoveryService = {
  passwordRecovery: async (username: string, application: PasswordRecoveryApplicationEnum) => {
    await ApiService.post(`/accounts/password-recovery/create-token`, { username, application });
  },

  newPassword: async (password: string, token: string) => {
    const { data } = await ApiService.post<IResponseNewPassword>(`/accounts/password-recovery`, { password, token });

    return data;
  },

  expiredToken: async (token: string) => {
    const {
      data: { expired },
    } = await ApiService.post<IResponseExpiredToken>(`/accounts/password-recovery/expired`, { token });

    return expired;
  },
};

export default PasswordRecoveryService;
