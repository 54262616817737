import ApiService from '..';
import { IWorkshop, IWorkshopForm, IWorkshopService } from './WorkshopService.types';

const WorkshopService: IWorkshopService = {
  create: async (workshopForm: IWorkshopForm) => {
    const { data } = await ApiService.post<{ workshop: IWorkshop }>('/workshops', workshopForm);

    return data.workshop;
  },

  getByQuery: async (query: string) => {
    const { data } = await ApiService.get<{ workshops: IWorkshop[] }>(`/workshops`, {
      params: {
        query,
      },
    });
    return data.workshops;
  },
};

export default WorkshopService;
