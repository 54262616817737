import React, { ReactNode } from 'react';
import Collapse from '@material-ui/core/Collapse';

import { CardBase, CardHeaderBase, CardBody, CardActionsBase, CardHeaderContainer } from './Card.styles';
import { ICardProps } from './Card.types';

export const CardComponent = ({
  testID,
  title,
  subheader,
  actions,
  body,
  type = 'closed',
  onClick,
  headerAction,
  ref,
  alert = false,
}: ICardProps) => (
  <CardBase data-testid={testID} type={type} click={onClick}>
    <CardHeaderContainer>
      <CardHeaderBase
        data-testid="card-header"
        action={headerAction}
        alert={alert.toString()}
        title={title}
        subheader={subheader}
        disableTypography
      />
    </CardHeaderContainer>
    <CardBody>{body}</CardBody>
    {actions && getCollapse(actions, type, ref)}
  </CardBase>
);

// eslint-disable-next-line
const getCollapse = (actions: ReactNode, type: 'open' | 'closed', ref: any) => {
  const checkCollapse = (): boolean => type === 'open';

  return (
    <span data-testid="collapse">
      <Collapse ref={ref} in={checkCollapse()} timeout="auto" unmountOnExit>
        <CardActionsBase>{actions}</CardActionsBase>
      </Collapse>
    </span>
  );
};
