/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Search } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ITableHeader } from 'components/Table/Table.types';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { Button, Table } from 'components';
import { theme } from 'themes/default';
import InspectionTableService from 'services/InspectionTableService/InspectionTableService';
import AssociateRegulatorDialog from 'components/Dialog/AssociateRegulatorDialog/AssociateRegulatorDialog';
import { useManagementDashboardState } from 'contexts/managementDashboard/useManagementDashboard';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection';
import DocumentService from 'services/DocumentService/documentService';
import CompanyService from 'services/ApiService/CompanyService/companyService';
import { toast } from 'react-toastify';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import DateService from 'services/DateService/dateService';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { ButtonWrapper, FormWrapper, TableActionsWrapper, ButtonCSVLink, TextField } from './InspectionsTable.styles';

import { IInspectionsSelected, IInspectionTableRow, IReportInspectionTableRow } from './InspectionsTable.types';
import { InspectionTableRow } from './components/InspectionTableRow/InspectionTableRow';
import { ConfirmDialogCSV } from './components/ConfirmDialogCSV/ConfirmDialogCSV';
import BulkUpdateDialog from './components/BulkUpdateDialog/BulkUpdateDialog';

export const InspectionsTable = () => {
  const { getAllCompaniesForActiveProfile } = useActiveProfile();
  const { inspections, selectedCompanyId, isLoading } = useManagementDashboardState();
  const { isInsuranceCompany } = useAuthDispatch();
  const { isConsumerActive } = useConsumer();
  const isInspectionReport = isConsumerActive(ConsumersEnum.INSPECTION_REPORT_MANAGEMENT, selectedCompanyId);
  const [page, setPage] = useState<number>(0);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [confirmDialogOpen, setConfirmDownloadCSVDialogOpen] = useState<boolean>(false);
  const [inspectionsSelected, setInspectionsSelected] = useState<IInspectionsSelected[]>([]);
  const [downloadActionDisabled, setDownloadActionDisabled] = useState<boolean>(true);
  const [associateRegulatorDialogIsOpen, setAssociateRegulatorDialogVisibility] = useState(false);
  const [bulkUpdateDialogIsOpen, setBulkUpdateDialogIsOpen] = useState(false);
  const [inspectionsTableState, setInspectionsTableState] = useState(
    InspectionTableService.getFromInspection(inspections, isInspectionReport)
  );
  const [disableCSVDaytona, setDisableCSVDaytona] = useState<boolean>(true);

  const rowsPerPage = inspectionsTableState.length === 0 ? 0 : 7;
  const inspectionsByPage = inspectionsTableState.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const companySelectedIsAuto = () => {
    const companySeleted = getAllCompaniesForActiveProfile().find((company) => company.id === selectedCompanyId);
    const productType = companySeleted?.productTypes.find((product) => ProductTypeService.isAuto(product.code));

    return !!productType;
  };

  const InputProps = {
    search: {
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    },
  };

  const inputProps = {
    search: {
      'data-testid': 'table-search',
    },
  };

  const getCompanyByConsumer = (featureName: string) => {
    return {
      inspections,
      inspectionsSelected,
      featureName,
      isConsumerActive,
      companies: getAllCompaniesForActiveProfile(),
    };
  };

  const getInspectionsBySelected = useCallback((): IInspection[] => {
    return inspections.filter((inspection) => {
      return inspectionsSelected.some((selected) => {
        return selected.id === inspection.id;
      });
    });
  }, [inspections, inspectionsSelected]);

  const getInspectionBySelected = useCallback(
    (inspectionSelectedId: string): IInspection | undefined => {
      return inspections.find((inspection) => {
        return inspectionSelectedId === inspection.id;
      });
    },
    [inspections]
  );

  const isInspectionsStatusPhotosReceived = useCallback((selectedInspections: IInspection[]): boolean => {
    return selectedInspections.some((inspection) => inspection.currentStatus.type >= StatusEnum.PHOTO_RECEIVED);
  }, []);

  const isInspectionStatusPhotosReceived = useCallback((selectedInspection: IInspection): boolean => {
    return selectedInspection.currentStatus.type < StatusEnum.PHOTO_RECEIVED;
  }, []);

  const isSelectedInspectionsNotCancelable = useCallback((): boolean => {
    return isInspectionsStatusPhotosReceived(getInspectionsBySelected());
  }, [getInspectionsBySelected, isInspectionsStatusPhotosReceived]);

  const isInspectionCancelable = useCallback(
    (inspectionSelectedId: string): boolean => {
      const inspectionSelected = getInspectionBySelected(inspectionSelectedId);

      if (inspectionSelected !== undefined) {
        return isInspectionStatusPhotosReceived(inspectionSelected);
      }

      return false;
    },
    [getInspectionBySelected, isInspectionStatusPhotosReceived]
  );

  const isCancelActionsDisabled = useCallback((): boolean => {
    return inspectionsSelected.length !== 1 || isSelectedInspectionsNotCancelable();
  }, [inspectionsSelected.length, isSelectedInspectionsNotCancelable]);

  const isInspectionLoaded = useCallback((): boolean => {
    return inspections.length > 0;
  }, [inspections]);

  const selectRow = ({ id, companyName, companyId }: IInspectionTableRow | IReportInspectionTableRow): void => {
    const selectedIndex = inspectionsSelected.find((inspection) => inspection.id === id);

    if (selectedIndex) {
      setInspectionsSelected(removeInspectionSelected(id));
    } else {
      setInspectionsSelected([...inspectionsSelected, { id, companyName, companyId }]);
    }
  };

  const removeInspectionSelected = (inspectionId: string): IInspectionsSelected[] => {
    return inspectionsSelected.filter((inspection) => inspection.id !== inspectionId);
  };

  const filterTableInspections = (
    tableInspections: (IInspectionTableRow | IReportInspectionTableRow)[],
    search: string
  ): (IInspectionTableRow | IReportInspectionTableRow)[] => {
    return tableInspections.filter((tableInspection) => {
      const tableInspectionValues = Object.values(tableInspection).filter((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase())
      );

      return tableInspectionValues.length > 0;
    });
  };

  const resetTableState = (): void => {
    setInputSearch('');
    setInspectionsSelected([]);
  };

  const isRowSelected = (id: string): boolean => !!inspectionsSelected.find((inspection) => inspection.id === id);

  const getInspectionTableHeaderActionsTable = (): ITableHeader[] => {
    if (showCheckboxHeaderOnlyIfInspectionFiltered()) {
      return InspectionTableService.getHeaderActionsTable(companySelectedIsAuto());
    }

    return InspectionTableService.getHeaderEmptyActionsTable(companySelectedIsAuto());
  };

  const getInspectionReportTableHeaderActions = (): ITableHeader[] => {
    if (showCheckboxHeaderOnlyIfInspectionFiltered()) {
      return InspectionTableService.getReportHeaderActionsTable();
    }

    return InspectionTableService.getReportHeaderEmptyActionsTable();
  };

  const handleAssociateRegulatorDialogOpen = (): void => {
    setAssociateRegulatorDialogVisibility(true);
  };

  const handleAssociateRegulatorDialogClose = (): void => {
    setAssociateRegulatorDialogVisibility(false);
  };

  const dialogBulkInspectionsOpen = (): void => {
    setBulkUpdateDialogIsOpen(true);
  };

  const buttonIsDisabledWhenNoSelection = useCallback((): boolean => {
    return !(inspectionsSelected.length >= 1);
  }, [inspectionsSelected]);

  const hasManyOperations = useCallback((): boolean => {
    return getAllCompaniesForActiveProfile().length > 1;
  }, [getAllCompaniesForActiveProfile]);

  const showCheckboxHeaderOnlyIfInspectionFiltered = (): boolean => {
    return inspections.length > 0;
  };

  const handleDisableButtonCSVDaytona = useCallback(() => {
    const inspectionsFinished = inspections.filter((inspection) => {
      return inspection.currentStatus.type === StatusEnum.INSPECTION_FINISHED;
    });

    return (
      isConsumerActive(ConsumersEnum.GENERATE_CSV, selectedCompanyId) && inspectionsFinished.length > 0 && !isLoading
    );
  }, [inspections, isConsumerActive, selectedCompanyId, isLoading]);

  useEffect(() => {
    resetTableState();
    setInspectionsTableState(InspectionTableService.getFromInspection(inspections, isInspectionReport));
    setPage(0);
  }, [inspections, isInspectionReport]);

  useEffect(() => {
    setInspectionsTableState(
      filterTableInspections(InspectionTableService.getFromInspection(inspections, isInspectionReport), inputSearch)
    );
    setPage(0);
  }, [inputSearch, inspections, isInspectionReport]);

  useEffect(() => {
    setDownloadActionDisabled(!isInspectionLoaded());
    setDisableCSVDaytona(!handleDisableButtonCSVDaytona());
  }, [
    inspectionsSelected,
    setInspectionsSelected,
    isCancelActionsDisabled,
    isInspectionLoaded,
    handleDisableButtonCSVDaytona,
  ]);

  const getInspectionsIdByConsumerGenerateCSV = () => {
    return CompanyService.filterInspectionsByActiveConsumerCompany(getCompanyByConsumer(ConsumersEnum.GENERATE_CSV));
  };

  const featureByCompanyId = (featureName: string) => {
    return CompanyService.isFeatureVisibleByActiveConsumerCompany(getCompanyByConsumer(featureName));
  };

  const showSendAgreements = featureByCompanyId(ConsumersEnum.ARVAL_BULK_UPDATE) && !isInsuranceCompany();

  const hasInspectionsSelectedByConsumerActive = () => {
    return getInspectionsIdByConsumerGenerateCSV().ids.length > 0;
  };

  const onSubmitDownloadCSVDialog = async () => {
    try {
      if (hasInspectionsSelectedByConsumerActive()) {
        await DocumentService.downloadReportCsvZip(getInspectionsIdByConsumerGenerateCSV());
        toast.success('Arquivo CSV gerado com sucesso!');
      } else {
        toast.warning(
          'Não há atendimentos selecionados da(s) operação(ões) ativa(s). Para gerar o CSV, verifique sua seleção.'
        );
      }
    } catch (error) {
      toast.error('Houve um erro ao gerar o CSV.');
    }
  };

  const formatCSVFileName = () => {
    const date = DateService.formatDate(new Date().toISOString());
    return `tabela_atendimentos_${date}`;
  };

  const openCSVDialog = (): void => {
    setConfirmDownloadCSVDialogOpen(true);
  };

  const closeCSVDialog = (): void => {
    setConfirmDownloadCSVDialogOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableActionsWrapper data-testid="inspections-table">
        <FormWrapper>
          <TextField
            ariaLabelledby="search"
            InputProps={InputProps.search}
            inputProps={inputProps.search}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => setInputSearch(target.value)}
            placeholder="Pesquisar"
            type="text"
            value={inputSearch}
            variant="outlined"
          />
        </FormWrapper>

        <ButtonWrapper>
          {showSendAgreements && (
            <>
              <Button
                variant="outlined"
                type="button"
                text="ENVIAR ACORDOS"
                testID="send-update-file"
                onClick={dialogBulkInspectionsOpen}
                disabled={false}
              />
              <BulkUpdateDialog
                bulkUpdateDialogIsOpen={bulkUpdateDialogIsOpen}
                setBulkUpdateDialogIsOpen={setBulkUpdateDialogIsOpen}
              />
            </>
          )}
          {!isInsuranceCompany() && (
            <>
              <Button
                variant="outlined"
                type="button"
                text="ASSOCIAR AO PERITO"
                testID="button-associate-regulator"
                onClick={handleAssociateRegulatorDialogOpen}
                disabled={buttonIsDisabledWhenNoSelection()}
              />
              <AssociateRegulatorDialog
                setInspectionsSelected={setInspectionsSelected}
                inspectionsSelected={inspectionsSelected}
                dialogVisibility={associateRegulatorDialogIsOpen}
                handleClose={handleAssociateRegulatorDialogClose}
              />
            </>
          )}
          {downloadActionDisabled ? (
            <Button
              variant="contained"
              type="button"
              text="BAIXAR CSV"
              disabled={downloadActionDisabled}
              startIcon={<GetAppIcon />}
            />
          ) : (
            <ButtonCSVLink
              data={inspectionsTableState}
              filename={formatCSVFileName()}
              headers={InspectionTableService.getCSVHeader(isInspectionReport, companySelectedIsAuto())}
              target="_blank"
              enclosingCharacter=""
            >
              <Button
                variant="contained"
                type="button"
                text="BAIXAR CSV"
                disabled={downloadActionDisabled}
                startIcon={<GetAppIcon />}
              />
            </ButtonCSVLink>
          )}

          {featureByCompanyId(ConsumersEnum.GENERATE_CSV) && (
            <Button
              variant="contained"
              type="button"
              text="BAIXAR CSV DAYTONA"
              disabled={disableCSVDaytona}
              startIcon={<GetAppIcon />}
              onClick={openCSVDialog}
              testID="button-generate-csv-daytona"
            />
          )}
        </ButtonWrapper>
      </TableActionsWrapper>

      {isInspectionReport ? (
        <Table
          headers={getInspectionReportTableHeaderActions()}
          page={page}
          count={inspectionsTableState.length}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          emptyMessage="Nenhum atendimento encontrado"
          totalPages={Math.ceil(inspectionsTableState.length / rowsPerPage)}
          marginRight="-92px"
          isLoading={isLoading}
        >
          {inspectionsByPage.map((inspectionRow) => (
            <InspectionTableRow
              key={inspectionRow.id}
              isCancelable={isInspectionCancelable(inspectionRow.id)}
              hasManyOperations={hasManyOperations()}
              inspectionRow={inspectionRow}
              isRowSelected={isRowSelected}
              selectRow={selectRow}
            />
          ))}
        </Table>
      ) : (
        <Table
          headers={getInspectionTableHeaderActionsTable()}
          page={page}
          count={inspectionsTableState.length}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          emptyMessage="Nenhum atendimento encontrado"
          totalPages={Math.ceil(inspectionsTableState.length / rowsPerPage)}
          marginRight="-92px"
          isLoading={isLoading}
        >
          {inspectionsByPage.map((inspectionRow) => (
            <InspectionTableRow
              key={inspectionRow.id}
              isCancelable={isInspectionCancelable(inspectionRow.id)}
              isAuto={companySelectedIsAuto()}
              hasManyOperations={hasManyOperations()}
              inspectionRow={inspectionRow}
              isRowSelected={isRowSelected}
              selectRow={selectRow}
            />
          ))}
        </Table>
      )}
      <ConfirmDialogCSV
        companiesByActiveConsumer={getCompanyByConsumer(ConsumersEnum.GENERATE_CSV)}
        closeCSVDialog={closeCSVDialog}
        confirmDialogOpen={confirmDialogOpen}
        onSubmitDownloadCSVDialog={onSubmitDownloadCSVDialog}
        testId="confirm-dialog-csv"
      />
    </ThemeProvider>
  );
};
