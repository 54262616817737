import React, { useState } from 'react';
import SendIcon from '@material-ui/icons/SendRounded';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TransmissionService from 'services/ApiService/TransmissionService/transmissionService';
import * as S from './Comments.styles';
import { formValidations } from 'utils/form/validations';

interface ITransmissionCommentsProps {
  transmissionComments: string[];
  transmissionSessionId: string;
  setTransmissionComments: (comments: string[]) => void;
  isTransmissionOn: boolean;
}

interface ITransmissionCommentForm {
  comment: string;
}

export const TransmissionComments = ({
  isTransmissionOn,
  transmissionSessionId,
  transmissionComments,
  setTransmissionComments,
}: ITransmissionCommentsProps) => {
  const [uploadingComment, setUploadingComment] = useState(false);
  const { register, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const onCommentSubmit = async ({ comment }: ITransmissionCommentForm): Promise<void> => {
    try {
      setUploadingComment(true);
      const { transmissionSession } = await TransmissionService.saveComment(comment, transmissionSessionId);
      setTransmissionComments(transmissionSession.comments || ['']);
      resetInputs();
    } catch (err) {
      console.log(err);
      toast.error('Occorreu um problema ao salvar o comentário.');
    } finally {
      setUploadingComment(false);
    }
  };

  const resetInputs = (): void => {
    setValue('comment', '');
  };

  return (
    <S.CommentsCard>
      <S.Title>Comentários</S.Title>
      <S.Form testID="comment-form" role="form" onSubmit={handleSubmit(onCommentSubmit)}>
        <S.ChatArea data-testid="chat-area">
          {transmissionComments.length > 0 ? (
            transmissionComments.map((comment, index) => <S.Comment key={index}>{comment}</S.Comment>)
          ) : (
            <S.Body>
              <S.Description>
                <b>Sem comentários</b>
              </S.Description>
            </S.Body>
          )}
        </S.ChatArea>
        {isTransmissionOn && (
          <S.Actions>
            <S.TextField
              type="text"
              id="comment"
              name="comment"
              required
              inputRef={register(formValidations.default)}
              disabled={!isTransmissionOn}
              placeholder="Escreva um comentário..."
              variant="outlined"
              inputProps={{ 'data-testid': 'input-comment' }}
            />
            <S.IconButton
              type="submit"
              testID="comment-button"
              variant="text"
              text={<SendIcon className="send-icon" />}
              loading={uploadingComment}
              loadingColor="#000"
              disabled={uploadingComment || !isTransmissionOn}
            />
          </S.Actions>
        )}
      </S.Form>
    </S.CommentsCard>
  );
};
