import { useContext } from 'react';

import { IUsersManagementDashboardDispatchContextData, IUsersManagementDashboardStateContextData } from './types';

import {
  UsersManagementDashboardDispatchContext,
  UsersManagementDashboardStateContext,
} from './UserManagementDashboardProvider';

export const useUserManagementDashboardDispatch = (): IUsersManagementDashboardDispatchContextData => {
  const context = useContext(UsersManagementDashboardDispatchContext);

  if (!context) {
    throw new Error('useUserManagementDashboardDispatch must be used within a UserManagementDashboardProvider');
  }

  return context;
};

export const useUserManagementDashboardState = (): IUsersManagementDashboardStateContextData => {
  const context = useContext(UsersManagementDashboardStateContext);

  if (!context) {
    throw new Error('useUserManagementDashboardState must be used within a UserManagementDashboardProvider');
  }

  return context;
};
