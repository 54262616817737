import { useContext } from 'react';

import { IFeatureTogglesDispatchContextData } from './types';

import { FeatureTogglesDispatchContext } from './FeatureTogglesProvider';

export const useFeatureToggles = (): IFeatureTogglesDispatchContextData => {
  const context = useContext(FeatureTogglesDispatchContext);

  if (!context) {
    throw new Error('useFeatureToggles must be used within a FeatureTogglesProvider');
  }

  return context;
};
