import React from 'react';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import Header from './components/Header/Header';
import InspectionFiles from '../InspectionFiles/InspectionFiles';
import InspectionSteps from './components/InspectionSteps/InspectionSteps';
import { IInspection } from '../Inspection/Inspection.types';
import GeolocationCard from './components/GeolocationCard/GeolocationCard';
import InformationCard from './components/InformationCard/InformationCard';
import InspectionConclusion from './components/InspectionConclusion/InspectionConclusion';
import * as S from './InspectionHistory.styles';

interface IInspectionHistoryProps {
  inspection: IInspection;
}

const InspectionHistory = ({ inspection }: IInspectionHistoryProps) => {
  useHideZendesk();

  return (
    <>
      <Header inspection={inspection} />
      <S.DetailsBody>
        <InspectionFiles inspection={inspection} />

        <S.DetailsCards>
          <S.InformationContent>
            <InspectionConclusion inspection={inspection} />
            <InformationCard inspection={inspection} />
            <GeolocationCard inspection={inspection} />
          </S.InformationContent>

          <InspectionSteps inspection={inspection} />
        </S.DetailsCards>
      </S.DetailsBody>
    </>
  );
};

export default InspectionHistory;
