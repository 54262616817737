import React from 'react';
import { BackButton } from 'components/BackButton';
import { Drawer } from 'components/Drawer';
import EmptyDealSvg from 'assets/svg/deal-not-found.svg';
import {
  BackButtonContent,
  ContainerEmptyDeal,
  CreateDealButton,
  DealsContainer,
  DescriptionEmptyDeal,
  Separator,
  TitleEmptyDeal,
} from './ListDeal.styles';
import { IListDealProps } from './ListDeal.types';
import { useAppSelector } from 'hooks/hooks';
import DealCard from './DealCard/DealCard';
import { selectNegotiationHistory } from 'stores/presentBudget/presentBudget.selector';

const ListDeal = ({ setCreateDeal, onCloseDrawer }: IListDealProps) => {
  const negotiationHistory = useAppSelector(selectNegotiationHistory);
  const createdNegotiations = negotiationHistory.length > 1 ? negotiationHistory.slice(0, -1) : [];
  const negotiationsIsEmpty = createdNegotiations.length === 0;

  return (
    <Drawer.Content>
      <Drawer.Header>
        <BackButtonContent>
          <BackButton onClick={onCloseDrawer} />
        </BackButtonContent>
        <Drawer.Title>Negociação</Drawer.Title>
        <Drawer.Subtitle>Negocie os valores de mão de obra e desconto e altere no orçamento.</Drawer.Subtitle>
        <CreateDealButton
          testID="create-deal-button"
          size="large"
          text="Nova Negociação"
          onClick={() => setCreateDeal(true)}
        />
        <Separator />
      </Drawer.Header>
      {negotiationsIsEmpty ? (
        <ContainerEmptyDeal>
          <img src={EmptyDealSvg} alt="Não há negociação" data-testid="deal-not-found" />
          <TitleEmptyDeal>Nenhuma negociação</TitleEmptyDeal>
          <DescriptionEmptyDeal>Você ainda não realizou nenhuma negociação.</DescriptionEmptyDeal>
        </ContainerEmptyDeal>
      ) : (
        <DealsContainer>
          {createdNegotiations.map((deal, index) => (
            <DealCard key={index} deal={deal} />
          ))}
        </DealsContainer>
      )}
    </Drawer.Content>
  );
};

export default ListDeal;
