import React, { useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import SendDocumentDialog from 'components/Dialog/SendDocumentDialog/SendDocumentDialog';
import { DocumentCategoryEnum, IDocument, IInspectionDetails } from 'pages/SchedulerList/components/Inspection';
import DocumentService from 'services/DocumentService/documentService';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { IUploadDocumentsDialogProps } from './UploadDocumentsDialog.types';

const UploadDocumentsDialog = ({
  dialogVisibility,
  inspection,
  inspectionDocuments,
  handleClose,
}: IUploadDocumentsDialogProps) => {
  const grossBudgetType = {
    name: 'Orçamento Bruto',
    value: 'Orçamento Bruto',
  };
  const netBudgetType = {
    name: 'Orçamento Líquido',
    value: 'Orçamento Líquido',
  };
  const documentsTypesList = [grossBudgetType, netBudgetType];
  const [documentCategory, setDocumentCategory] = useState<DocumentCategoryEnum>(DocumentCategoryEnum.BUDGET_GROSS);
  const [loading, setLoading] = useState(false);
  const { invalidateInspectionParallel } = useMutateInspection();

  const setDocumentCategoryByDocumentType = (documentType: string) => {
    switch (documentType) {
      case grossBudgetType.name:
        setDocumentCategory(DocumentCategoryEnum.BUDGET_GROSS);
        break;
      case netBudgetType.name:
        setDocumentCategory(DocumentCategoryEnum.BUDGET_NET);
        break;
      default:
        setDocumentCategory(DocumentCategoryEnum.REPORT);
    }
  };

  const getTotalDocumentsWithOriginUploadKite = (): number | undefined => {
    return inspectionDocuments?.filter((document) => document.origin === 'UPLOAD_KITE').length;
  };

  const handleSubmit = async (onImageRemoveAll: () => void, documents: ImageListType) => {
    try {
      const totalDocuments = getTotalDocumentsWithOriginUploadKite() || 0;
      setLoading(true);
      const s3Documents = await DocumentService.uploadDocuments(
        inspection.id,
        documents,
        totalDocuments,
        documentCategory
      );
      convertDocumentToCSV(s3Documents);
      toast.success('Documento enviado com sucesso, em breve, você poderá visualizar-lo.');
      onImageRemoveAll();
      setLoading(false);
      invalidateInspectionParallel(inspection.id);
      handleClose();
    } catch (error) {
      setLoading(false);
      toast.error('Falha ao enviar documento.');
    }
  };

  const isDocumentCategoryBudget = () => {
    return (
      documentCategory === DocumentCategoryEnum.BUDGET_GROSS || documentCategory === DocumentCategoryEnum.BUDGET_NET
    );
  };

  const hasNoContractOrAgreement = (inspectionDetails: IInspectionDetails | undefined) => {
    return !(inspectionDetails?.contract || inspectionDetails?.agreement);
  };

  const isBudgetDocumentWithoutContractOrAgreement = () => {
    return isDocumentCategoryBudget() && hasNoContractOrAgreement(inspection.inspectionDetails);
  };

  const convertDocumentToCSV = async (documentsNotConverted: IDocument[]) => {
    try {
      await DocumentService.convertDocumentToCSV(documentsNotConverted[0].id);
    } catch (error) {
      setLoading(false);
      toast.error('Falha na conversão do documento.');
    }
  };

  return (
    <SendDocumentDialog
      title="Enviar documento"
      subtitle="Para realizar o atendimento, envie os documentos clicando abaixo"
      textDragAndDrop="Clique ou arraste o arquivo de planilha aqui"
      errorMessage="* Tipo de arquivo não suportado, use apenas XLSX"
      btnSubmitText="ENVIAR"
      dialogVisibility={dialogVisibility}
      extensions={['xlsx']}
      replaceTooltipIcon="Substituir documento"
      removeTooltipIcon="Remover documento"
      loadingButton={loading}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      hasSelect={true}
      documentsTypesList={documentsTypesList}
      setDocumentCategoryByDocumentType={setDocumentCategoryByDocumentType}
      testId="send-document-dialog"
      isBudgetDocumentWithoutContractOrAgreement={isBudgetDocumentWithoutContractOrAgreement}
    />
  );
};

export default UploadDocumentsDialog;
