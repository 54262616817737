import styled from 'styled-components';
import { Select, MenuItem, FormControl } from '@material-ui/core';

export const FormControlWrapper = styled(FormControl)`
  width: 100%;
`;

export const SelectWrapper = styled(Select)`
  width: 100%;
  height: 40px;

  div {
    padding: 10.5px 14px;
    border-radius: 4px;

    :focus {
      background: none;
    }
  }
`;

export const MenuItemWrapper = styled(MenuItem)``;

export const PlaceholderContainer = styled.span`
  color: #9d9d9dde;
`;
