import React, { useState } from 'react';
import { MenuItem } from '../../Header.styles';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import DealDrawer from 'components/Drawer/DealDrawer/DealDrawer';

const DealAction = () => {
  const [showNegotiationDrawer, setShowNegotiationDrawer] = useState(false);

  return (
    <>
      <MenuItem onClick={() => setShowNegotiationDrawer(true)}>
        <PriceChangeOutlinedIcon />
        <span>Negociação</span>
      </MenuItem>
      <DealDrawer onClose={() => setShowNegotiationDrawer(false)} open={showNegotiationDrawer} />
    </>
  );
};

export default DealAction;
