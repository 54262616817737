import React, { useState, useCallback, useEffect, ChangeEvent, KeyboardEvent } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { Form, Label } from 'components';
import { theme } from 'themes/default';
import { IOptions, ICompanySelectOptions } from 'components/Select/Select.types';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import AuthService from 'services/ApiService/AuthService/authService';
import { IAccount } from 'contexts/auth/types';
import { ICompany, IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import CompanySelectService from 'services/CompanySelectService/CompanySelectService';
import { CompanyLabel } from 'components/CompanyLabel/CompanyLabel';
import { formValidations } from 'utils/form/validations';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import * as Style from './PreviousInspection.styles';
import image from '../../assets/svg/form-image.svg';
import { IPreviousInspectionForm } from './PreviousInspection.types';

import { normalizePhone } from '../../utils/formatPhone';

export const PreviousInspection: React.FC = () => {
  const [companySelected, setCompanySelected] = useState<ICompanySelectOptions>(
    CompanySelectService.getDefaultCompany()
  );
  const [companiesList, setCompaniesList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { getCompaniesWithoutCAPForActiveProfile } = useActiveProfile();
  const [clientCodeSelected, setClientCodeSelected] = useState<string>('');
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });

  const resetInputs = (): void => {
    setValue('proposalNumber', '');
    setValue('plate', '');
    setValue('contactName', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('clientCode', '');
    setValue('brokerName', '');
    setValue('brokerCode', '');
    setValue('brokerCodeRegistered', '');
    setValue('subsidiaryName', '');
    setValue('subsidiaryCode', '');
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const onSubmit = async (prevInspectionForm: IPreviousInspectionForm): Promise<void> => {
    try {
      setLoadingSubmit(true);

      const form = formatPreviousInspectionForm(prevInspectionForm);

      const inspection = await createPreviousInspection(form);

      if (inspection) {
        resetInputs();
        await sendLink(inspection.id);
      }
    } catch (error) {
      toast.error('Alguma coisa deu errado, tente novamente.');
    }
    setLoadingSubmit(false);
  };

  const formatPreviousInspectionForm = ({
    proposalNumber,
    plate,
    contactName,
    email,
    phone,
    clientCode,
    brokerName,
    brokerCode,
    brokerCodeRegistered,
    subsidiaryName,
    subsidiaryCode,
  }: IPreviousInspectionForm): IPreviousInspectionForm => {
    return {
      companyId: companySelected.value,
      proposalNumber,
      plate,
      contactName,
      clientCode,
      email,
      phone: normalizePhone(phone),
      brokerName,
      brokerCode,
      brokerCodeRegistered,
      subsidiaryName,
      subsidiaryCode,
      productTypeId: companySelected.productTypeId,
      createStrategy: 'WEB_APP',
    };
  };

  const createPreviousInspection = async (formRequest: IPreviousInspectionForm): Promise<IInspection | undefined> => {
    try {
      const { inspection } = await InspectionService.postPreviousInspection(formRequest);

      toast.success('Vistoria prévia criada com sucesso!');

      return inspection;
    } catch (error) {
      toast.error('Ocorreu um erro ao criar a vistoria prévia.');

      return undefined;
    }
  };

  const sendLink = async (inspectionId: string): Promise<void> => {
    try {
      await InspectionStatusService.linkSent(inspectionId);

      toast.success('Link enviado!');
    } catch (error) {
      toast.error('O link não foi enviado, tente novamente na tela de Lista de Atendimentos.');
    }
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const companiesToOption = useCallback((companies: ICompany[]): ICompanySelectOptions[] => {
    return companies
      .map(CompanySelectService.transformCompanyToOption)
      .filter(CompanySelectService.isOptionPreviousInspection);
  }, []);

  const getCompanies = useCallback(async (): Promise<IOptions[]> => {
    try {
      const companies = getCompaniesWithoutCAPForActiveProfile();
      return companiesToOption(companies);
    } catch (error) {
      toast.error('Problema ao carregar as empresas, tente novamente.');

      return [];
    }
  }, [getCompaniesWithoutCAPForActiveProfile, companiesToOption]);

  const disableSubmitButton = (): boolean => {
    return companySelected.value === '' || companySelected === null || companySelected === undefined;
  };

  const companiesSetUp = useCallback(async (): Promise<void> => {
    const companies = await getCompanies();
    const defaultCompany = CompanySelectService.getDefaultCompany();
    const firstCompany = CompanySelectService.getFirstCompany(companies);
    setCompaniesList(companies.length > 0 ? companies : []);
    setCompanySelected(companies.length > 0 ? firstCompany : defaultCompany);
  }, [getCompanies]);

  const getAccount = (): IAccount => {
    return AuthService.getAccountInLocalStorage();
  };

  const getAccountByUsername = (username: string): Promise<IAccount> => {
    return AuthService.getAccountByUsername(username);
  };

  const inputProps = {
    proposalNumber: {
      'data-testid': 'input-proposal-number',
    },
    plate: {
      'data-testid': 'input-plate',
    },
    contactName: {
      'data-testid': 'input-contact-name',
    },
    phone: {
      'data-testid': 'input-phone',
    },
    clientCode: {
      'data-testid': 'input-client-code',
    },
    email: {
      'data-testid': 'input-email',
      form: {
        autocomplete: 'off',
      },
    },
    brokerName: {
      'data-testid': 'input-broker-name',
    },
    brokerCode: {
      'data-testid': 'input-broker-code',
    },
    brokerCodeRegistered: {
      'data-testid': 'input-broker-code-registered',
    },
    subsidiaryName: {
      'data-testid': 'input-subsidiary-name',
    },
    subsidiaryCode: {
      'data-testid': 'input-subsidiary-code',
    },
  };

  const hasOneCompany = (): boolean => {
    return companiesList.length === 1;
  };

  const getCompanyName = (): string => {
    return companiesList[0]?.name;
  };

  const handleSelectCompany = (value: string) => {
    const company = companiesList.find((item) => item.value === value);

    if (company) {
      setCompanySelected(company);
    }
  };

  const onChangeClientCode = (clientCodeValue: string) => {
    setClientCodeSelected(clientCodeValue);
  };

  const verifyCpfCnpj = (event: KeyboardEvent) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      setClientCodeSelected(clientCodeSelected?.slice(0, -1));
    } else if (clientCodeSelected?.length === 18) event.preventDefault();
  };

  useEffect(() => {
    const { username } = getAccount();
    getAccountByUsername(username);
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      await companiesSetUp();
    })();
  }, [companiesSetUp]);

  return (
    <ThemeProvider theme={theme}>
      <Style.PreviousInspectionForm data-testid="prev-inspection-page">
        <Style.LeftContent>
          <Form testID="previous-inspection-form" onSubmit={handleSubmit(onSubmit)}>
            <Style.Title>Envio de link | Vistoria Prévia</Style.Title>

            <Label htmlFor="companies" testID="label-companies">
              Operação
            </Label>
            {hasOneCompany() ? (
              <CompanyLabel testID="company-selected">{getCompanyName()}</CompanyLabel>
            ) : (
              <Style.SelectContainer>
                <Style.SelectWrapper
                  testID="companies-select"
                  options={companiesList}
                  selectedOptions={companySelected.value}
                  labelId="companies"
                  onChange={({ target: { value } }): void => handleSelectCompany(value as string)}
                />
              </Style.SelectContainer>
            )}

            <Label htmlFor="proposalNumber" testID="label-proposal-number">
              Número da proposta
            </Label>
            <Style.TextField
              ariaLabelledby="proposalNumber"
              error={hasError('proposalNumber')}
              id="proposalNumber"
              inputProps={inputProps.proposalNumber}
              inputRef={register(formValidations.default)}
              helperText={errors?.proposalNumber?.message}
              name="proposalNumber"
              placeholder="Insira o número da proposta"
              state={handleInputState('proposalNumber')}
              type="number"
              variant="outlined"
            />

            <Label htmlFor="plate" testID="label-plate">
              Placa
            </Label>
            <Style.TextField
              ariaLabelledby="Placa"
              error={hasError('plate')}
              id="plate"
              inputProps={inputProps.plate}
              inputRef={register(formValidations.plate)}
              helperText={errors?.plate?.message}
              mask="aaa0*00"
              maxLength="7"
              minLength="0"
              name="plate"
              placeholder="Insira a placa do veículo"
              prepare={(value: string): string => value.toUpperCase()}
              role="plate"
              state={handleInputState('plate')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="contactName" testID="label-contact-name">
              Nome do cliente
            </Label>
            <Style.TextField
              ariaLabelledby="Nome do cliente"
              error={hasError('contactName')}
              id="contactName"
              InputLabelProps={{ shrink: true }}
              inputProps={inputProps.contactName}
              inputRef={register(formValidations.default)}
              helperText={errors?.contactName?.message}
              name="contactName"
              placeholder="Nome de quem receberá o link"
              role="contactName"
              state={handleInputState('contactName')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="phone" testID="label-phone">
              Telefone
            </Label>
            <Style.TextField
              ariaLabelledby="Telefone"
              error={hasError('phone')}
              id="phone"
              inputProps={inputProps.phone}
              inputRef={register(formValidations.phone)}
              helperText={errors?.phone?.message}
              mask="(00) 0 0000-0000"
              name="phone"
              placeholder="Ex. (99) 9 9999-9999"
              state={handleInputState('phone')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="clientCode" testID="label-client-code">
              CPF/CNPJ
            </Label>
            <Style.TextField
              ariaLabelledby="CPF/CNPJ"
              error={hasError('clientCode')}
              id="clientCode"
              inputProps={inputProps.clientCode}
              inputRef={register(formValidations.cpfOrCnpj)}
              helperText={errors?.clientCode?.message}
              mask={clientCodeSelected.length < 15 ? '000.000.000-000' : '00.000.000/0000-00'}
              name="clientCode"
              state={handleInputState('clientCode')}
              type="text"
              placeholder="Insira o cpf ou cnpj"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeClientCode(event?.target?.value)}
              onKeyDown={(event: KeyboardEvent) => verifyCpfCnpj(event)}
            />

            <Label htmlFor="email" testID="label-email">
              E-mail
            </Label>
            <Style.TextField
              ariaLabelledby="email"
              error={hasError('email')}
              id="email"
              inputProps={inputProps.email}
              inputRef={register(formValidations.default)}
              helperText={errors?.email?.message}
              name="email"
              state={handleInputState('email')}
              type="email"
              placeholder="Insira o e-mail"
              variant="outlined"
            />

            <Label htmlFor="brokerName" testID="label-broker-name">
              Nome da corretora de seguros
            </Label>
            <Style.TextField
              ariaLabelledby="Nome da corretora de seguros"
              error={hasError('brokerName')}
              id="brokerName"
              inputProps={inputProps.brokerName}
              inputRef={register(formValidations.default)}
              helperText={errors?.brokerName?.message}
              name="brokerName"
              placeholder="Insira o nome da corretora de seguros"
              role="brokerName"
              state={handleInputState('brokerName')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="brokerCode" testID="label-broker-code">
              Código do corretor (Oficial Susep)
            </Label>
            <Style.TextField
              ariaLabelledby="Código do corretor (Oficial Susep)"
              error={hasError('brokerCode')}
              id="brokerCode"
              inputProps={inputProps.brokerCode}
              inputRef={register(formValidations.default)}
              helperText={errors?.brokerCode?.message}
              name="brokerCode"
              placeholder="Código do corretor (Oficial Susep)"
              state={handleInputState('brokerCode')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="brokerCodeRegistered" testID="label-broker-code-registered">
              Código do corretor cadastrado na seguradora
            </Label>
            <Style.TextField
              ariaLabelledby="Código do corretor cadastrado na seguradora"
              error={hasError('brokerCodeRegistered')}
              id="brokerCodeRegistered"
              inputProps={inputProps.brokerCodeRegistered}
              inputRef={register(formValidations.default)}
              helperText={errors?.brokerCodeRegistered?.message}
              name="brokerCodeRegistered"
              placeholder="Código do corretor na seguradora"
              state={handleInputState('brokerCodeRegistered')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="subsidiaryName" testID="label-insurer-name">
              Nome da seguradora
            </Label>
            <Style.TextField
              ariaLabelledby="Nome da seguradora"
              error={hasError('subsidiaryName')}
              id="subsidiaryName"
              inputProps={inputProps.subsidiaryName}
              inputRef={register(formValidations.default)}
              helperText={errors?.subsidiaryName?.message}
              name="subsidiaryName"
              placeholder="Insira o nome da seguradora"
              role="companyType"
              state={handleInputState('subsidiaryName')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="subsidiaryCode" testID="label-branch-code">
              Código da filial
            </Label>
            <Style.TextField
              ariaLabelledby="Código da filial"
              error={hasError('subsidiaryCode')}
              id="subsidiaryCode"
              inputProps={inputProps.subsidiaryCode}
              inputRef={register(formValidations.default)}
              helperText={errors?.subsidiaryCode?.message}
              name="subsidiaryCode"
              placeholder="Insira o código da filial"
              role="subsidiaryCode"
              state={handleInputState('subsidiaryCode')}
              type="text"
              variant="outlined"
            />

            <Style.Button
              testID="button-form"
              type="submit"
              text="ENVIAR LINK"
              loading={loadingSubmit}
              fullWidth={true}
              size="large"
              loadingSize={30}
              loadingColor="#FFF"
              disabled={disableSubmitButton()}
            />
          </Form>
        </Style.LeftContent>

        <Style.ImageWrapper image={image} />
      </Style.PreviousInspectionForm>
    </ThemeProvider>
  );
};
