import React, { Ref, useEffect, useState } from 'react';
import { createSession, OTSession, OTSubscriberRef, preloadScript, SessionHelper } from 'opentok-react';
import { Error, Signal } from 'opentok-react/types/opentok';
import CameraIcon from '@material-ui/icons/CameraAlt';
import SendIcon from '@material-ui/icons/SendRounded';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { useFilterInspectionById } from 'hooks/Inspections/InspectionsHooks';
import TransmissionService from 'services/ApiService/TransmissionService/transmissionService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { Gallery } from 'components/Gallery/Gallery';
import NoTransmissionPhoto from 'assets/svg/no-transmission-photo.svg';
import WaitTransmission from 'assets/svg/wait-transmission.svg';
import NoTransmission from 'assets/svg/no-transmission.svg';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { useAuthState } from 'contexts/auth/useAuth';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';
import { useQueryClient } from 'react-query';
import { getInspectionIdFromPathname, getInspectionIdFromOldPathname } from 'utils/inspectionHandler';
import { useHistory, useLocation } from 'react-router-dom';
import { BackButton } from 'components/BackButton';
import DateService from 'services/DateService/dateService';
import { Publisher, Subscriber } from './components';
import { IPhoto } from '../Inspection';
import * as S from './OldTransmission.styles';

const OPENTOK_API_KEY = process.env.REACT_APP_OPENTOK_API_KEY;

interface ITransmissionCommentForm {
  comment: string;
}

const OldTransmission = () => {
  const [isTransmissionOn, setIsTransmissionOn] = useState(false);
  const [error, setError] = useState(false);
  const [transmissionExpired, setTransmissionExpired] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [loadingUploadPhoto, setLoadingUploadPhoto] = useState(false);
  const [sessionHelper, setSessionHelper] = useState<SessionHelper>();
  const [loadingFinishTransmission, setLoadingFinishTransmission] = useState(false);
  const [loadingUploadComment, setLoadingUploadComment] = useState(false);
  const [transmissionComments, setTransmissionComments] = useState<string[]>([]);
  const [capturedPhotos, setCapturedPhotos] = useState<IPhoto[]>([]);
  const [FinishiTransmissionDialogIsOpen, setFinishiTransmissionDialogInOpen] = useState(false);
  const otSubscriber: Ref<OTSubscriberRef> | undefined = React.createRef();
  const { register, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const { isFeatureToggleActive } = useFeatureToggles();
  const { pathname } = useLocation();
  const { account } = useAuthState();
  const queryClient = useQueryClient();
  const history = useHistory();
  const inspectionId = isFeatureToggleActive('KITE_DETAILS_V2')
    ? getInspectionIdFromPathname(pathname)
    : getInspectionIdFromOldPathname(pathname);
  const { inspectionData } = useFilterInspectionById(inspectionId, 'TRANSMISSION');

  const handleFinishiTransmissionOpen = (): void => {
    setFinishiTransmissionDialogInOpen(true);
  };

  const handleFinishiTransmissionClose = (): void => {
    setFinishiTransmissionDialogInOpen(false);
  };

  useEffect(() => {
    setCapturedPhotos(inspectionData?.photos || []);
    setTransmissionComments(inspectionData?.transmissionSession?.comments || []);
    if (OPENTOK_API_KEY && inspectionData?.transmissionSession) {
      const transmissionSessionHelper = createSession({
        apiKey: OPENTOK_API_KEY,
        sessionId: inspectionData?.transmissionSession.sessionId,
        token: inspectionData?.transmissionSession.token,
        onStreamsUpdated: (streams) => streams,
      });
      setSessionHelper(transmissionSessionHelper);
    }
  }, [inspectionData]);

  const sessionEventHandlers = {
    streamCreated: () => {
      setIsTransmissionOn(true);
      setError(false);
    },
    streamDestroyed: async () => {
      setIsTransmissionOn(false);
      setError(false);
      await mutateInspection();
    },
    signal: async () => {
      if (OPENTOK_API_KEY && inspectionData?.transmissionSession) {
        const { session } = createSession({
          apiKey: OPENTOK_API_KEY,
          sessionId: inspectionData?.transmissionSession.sessionId,
          token: inspectionData?.transmissionSession.token,
          onStreamsUpdated: (streams) => streams,
        });

        session.once('signal', async (event: Signal) => {
          if (event.type === 'signal:takePictureFinished') {
            const photo = await JSON.parse(event.data || '');
            setCapturedPhotos((oldCapturedPhotos) => {
              return updateNewPhoto(oldCapturedPhotos, photo);
            });
            setLoadingUploadPhoto(false);
          }

          if (event.type === 'signal:archiveError') {
            toast.error(
              'Não foi possível salvar a gravação. Continue a transmissão e tire o máximo de fotos possível.',
              {
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
              }
            );
          }

          if (event.data === 'Error: takePicture') {
            toast.error('Ocorreu um erro ao tirar a foto!');
            setLoadingUploadPhoto(false);
          }

          if (event.data === 'Error: archiveError') {
            toast.error('Ocorreu um erro ao informar problema no arquivamento!');
          }
        });
      }
    },
  };

  const updateNewPhoto = (oldCapturedPhotos: IPhoto[], photo: IPhoto) => {
    const index = oldCapturedPhotos.findIndex((element) => {
      return element.path === 'string';
    });
    oldCapturedPhotos[index] = photo;
    return [...oldCapturedPhotos];
  };

  const onSessionError = ({ code }: Error) => {
    if (code === 1004) {
      setTransmissionExpired(true);
      setError(false);
    } else if (code === 1500) {
      setPermissionDenied(true);
      setError(false);
    } else {
      setError(true);
      setIsTransmissionOn(false);
    }
  };

  const handleStopTransmission = async () => {
    try {
      setLoadingFinishTransmission(true);

      stopArchivingTransmission();

      if (inspectionData) {
        await InspectionStatusService.photosReceived(inspectionData?.id);
      }

      sessionHelper?.disconnect();

      setIsTransmissionOn(false);

      await mutateInspection();
    } catch (err) {
      toast.error('Erro ao encerrar transmissão.');
    } finally {
      setLoadingFinishTransmission(false);
    }
  };

  const mutateInspection = async (): Promise<void> => {
    if (inspectionData?.id) {
      setTimeout(async () => {
        queryClient.invalidateQueries({
          queryKey: ['get-inspection-by-id', { id: inspectionData.id }],
        });
        queryClient.invalidateQueries({ queryKey: ['get-inspections'] });
      }, 5000);
    }
  };

  const stopArchivingTransmission = async () => {
    try {
      if (inspectionData?.transmissionSession) {
        const { id } = inspectionData?.transmissionSession;
        await TransmissionService.stopArchiving(id);
      }
    } catch (err) {
      toast.error('Erro ao parar gravação.');
    }
  };

  const handleTakePhoto = async () => {
    setCapturedPhotos((oldCapturedPhotos) => [
      ...oldCapturedPhotos,
      {
        description: 'string',
        url: 'string',
        path: 'string',
        name: 'string',
        latitude: null,
        longitude: null,
      },
    ]);
    sessionHelper?.session.signal(
      {
        type: 'takePicture',
        data: account.username,
      },
      (signalError) => {
        if (signalError) {
          toast.error('Ocorreu um erro ao tirar a foto!');
        }
      }
    );
  };

  const capturedPhotosUrls = () => capturedPhotos.map((photo) => photo.url);

  const onCommentSubmit = async ({ comment }: ITransmissionCommentForm): Promise<void> => {
    try {
      setLoadingUploadComment(true);
      const { transmissionSession } = await TransmissionService.saveComment(
        comment,
        inspectionData?.transmissionSessionId
      );
      setTransmissionComments(transmissionSession.comments || ['']);
      resetInputs();
    } catch (err) {
      toast.error('Occorreu um problema ao salvar o comentário.');
    } finally {
      setLoadingUploadComment(false);
    }
  };

  const resetInputs = (): void => {
    setValue('comment', '');
  };

  const hasPhotosReceived = () => {
    return inspectionData?.currentStatus && InspectionStatusService.hasPhotosReceived(inspectionData.currentStatus);
  };

  const canBeStartedNow = (): boolean => {
    if (inspectionData?.schedule) {
      const scheduleDate = new Date(inspectionData.schedule);
      const today = new Date();
      return today >= scheduleDate;
    }
    return true;
  };

  const InputValidation = {
    comment: {},
  };

  const goBack = () => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}`);
  };

  return (
    <S.Container permissionDenied={permissionDenied}>
      <S.TransmissionCard>
        <S.Header>
          <S.TitleCard>
            {isFeatureToggleActive('KITE_DETAILS_V2') && <BackButton onClick={goBack} />}
            Transmissão ao vivo
          </S.TitleCard>
          {!error && canBeStartedNow() && !hasPhotosReceived() && (
            <S.Actions>
              <S.Button
                type="button"
                variant="contained"
                text="ENCERRAR"
                onClick={() => handleFinishiTransmissionOpen()}
                loading={loadingFinishTransmission}
                loadingColor="#000"
                disabled={loadingFinishTransmission}
                startIcon={<CallEndIcon />}
              />
              <ConfirmDialog
                dialogVisibility={FinishiTransmissionDialogIsOpen}
                handleClose={handleFinishiTransmissionClose}
                onSubmit={handleStopTransmission}
                title="Deseja encerrar transmissão?"
                subtitle='Atenção! Verifique se ainda há fotos carregando em "Fotos Capturadas". Encerrar a transmissão antes do
                  carregamento completo pode ocasionar a perda delas. Deseja encerrar mesmo assim?'
              />
              {isTransmissionOn && (
                <S.Button
                  type="button"
                  testID="take-photo-button"
                  variant="outlined"
                  text="TIRAR FOTO"
                  onClick={() => handleTakePhoto()}
                  loading={loadingUploadPhoto}
                  loadingColor="#000"
                  disabled={loadingUploadPhoto}
                  startIcon={<CameraIcon />}
                />
              )}
            </S.Actions>
          )}
        </S.Header>

        {inspectionData?.transmissionSession && OPENTOK_API_KEY && (
          <>
            {error && !transmissionExpired && !hasPhotosReceived() && (
              <S.Body>
                <S.Ilustration src={NoTransmission} />
                <S.Subtitle>
                  Ocorreu algum <b>erro</b> na transmissão
                </S.Subtitle>
              </S.Body>
            )}

            {permissionDenied && !hasPhotosReceived() && (
              <S.Body>
                <S.VideoSubtitle>
                  Permissão de áudio <b>negada</b>! Por favor, verifique as configurações de permissão do navegador.
                </S.VideoSubtitle>
              </S.Body>
            )}

            {transmissionExpired && !hasPhotosReceived() && (
              <S.Body>
                <S.Ilustration src={NoTransmission} />
                <S.Subtitle>
                  A transmissão <b>expirou</b>
                </S.Subtitle>
              </S.Body>
            )}

            {!isTransmissionOn &&
              !error &&
              !transmissionExpired &&
              !hasPhotosReceived() &&
              !inspectionData?.transmissionSession?.urlTransmission &&
              canBeStartedNow() && (
                <S.Body>
                  <S.Ilustration src={WaitTransmission} />
                  <S.Subtitle>
                    Aguarde o cliente se conectar ou <b>encerre</b> a transmissão
                  </S.Subtitle>
                </S.Body>
              )}

            {!isTransmissionOn && !error && !transmissionExpired && !hasPhotosReceived() && !canBeStartedNow() && (
              <S.Body>
                <S.Ilustration src={WaitTransmission} />
                {inspectionData.schedule && (
                  <S.Subtitle>
                    A <b>transmissão</b> está agendada para:
                    <b> {DateService.formatDateAndTime(inspectionData.schedule)}</b>
                  </S.Subtitle>
                )}
              </S.Body>
            )}

            {!isTransmissionOn && hasPhotosReceived() && inspectionData?.transmissionSession?.urlTransmission && (
              <S.Body>
                <S.VideoSubtitle>
                  A transmissão já foi <b>finalizada</b>. Verifique as fotos, comentários e gravação.
                </S.VideoSubtitle>
                {inspectionData?.transmissionSession?.urlTransmission && (
                  <S.Video controls>
                    <source src={inspectionData.transmissionSession.urlTransmission} type="video/mp4" />
                    <track kind="captions" />
                  </S.Video>
                )}
              </S.Body>
            )}

            {!isTransmissionOn && hasPhotosReceived() && !inspectionData?.transmissionSession?.urlTransmission && (
              <S.Body>
                <S.Ilustration src={NoTransmission} />
                <S.Subtitle>
                  A transmissão já foi <b>finalizada</b>. Porém ocorreu um <b>erro na gravação</b>.
                </S.Subtitle>
              </S.Body>
            )}

            <OTSession
              apiKey={OPENTOK_API_KEY}
              sessionId={inspectionData.transmissionSession.sessionId}
              token={inspectionData.transmissionSession.token}
              onError={onSessionError}
              eventHandlers={sessionEventHandlers}
            >
              {isTransmissionOn && !error && !hasPhotosReceived() && (
                <>
                  <Publisher onSessionError={onSessionError} />
                  <Subscriber onSessionError={onSessionError} subscriberRef={otSubscriber} />
                </>
              )}
            </OTSession>
          </>
        )}
      </S.TransmissionCard>
      {!error && (
        <>
          <S.PhotosCard>
            <S.TitleCard>Fotos Capturadas</S.TitleCard>
            {capturedPhotos.length > 0 ? (
              <S.PhotosContainer>
                <Gallery
                  urls={capturedPhotosUrls()}
                  orientation="horizontal"
                  thumbnailHeigth="120"
                  thumbnailWidth="160"
                />
              </S.PhotosContainer>
            ) : (
              <S.NoPhoto>
                <S.Ilustration src={NoTransmissionPhoto} />
                <S.Subtitle>
                  As fotos capturadas <b>durante a transmissão</b> serão exibidas aqui
                </S.Subtitle>
              </S.NoPhoto>
            )}
          </S.PhotosCard>
          <S.CommentsCard>
            <S.TitleCard>Comentários</S.TitleCard>
            <S.Form role="form" onSubmit={handleSubmit(onCommentSubmit)}>
              <S.TextArea>
                {transmissionComments.length > 0 ? (
                  transmissionComments.map((comment, index) => <S.Comment key={index}>{comment}</S.Comment>)
                ) : (
                  <S.Body>
                    <S.Subtitle>
                      <b>Sem comentários</b>
                    </S.Subtitle>
                  </S.Body>
                )}
              </S.TextArea>
              {isTransmissionOn && (
                <S.ActionsWrapper>
                  <S.TextField
                    testID="input-comment"
                    type="text"
                    id="comment"
                    name="comment"
                    required
                    inputRef={register(InputValidation.comment)}
                    disabled={!isTransmissionOn}
                    placeholder="Escreva um comentário..."
                    variant="outlined"
                  />
                  <S.IconButton
                    type="submit"
                    testID="comment-button"
                    variant="text"
                    text=""
                    loading={loadingUploadComment}
                    loadingColor="#000"
                    disabled={loadingUploadComment || !isTransmissionOn}
                    startIcon={<SendIcon />}
                  />
                </S.ActionsWrapper>
              )}
            </S.Form>
          </S.CommentsCard>
        </>
      )}
    </S.Container>
  );
};

export default preloadScript(OldTransmission);
