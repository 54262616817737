import TextFieldComponent from '@material-ui/core/TextField';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const TextField = withTheme(styled(TextFieldComponent)`
  [class*='MuiFormHelperText-root'] {
    position: absolute;
    bottom: ${(props): number => -props.theme.spacing(2.5)}px;
  }

  .Mui-disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`);
