import React from 'react';
import { IVehiclePartPrice, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import { PartLaborCostComponent } from './PartLaborCostComponents';
import { PartLaborWrapper } from './PartLaborCost.styles';
import { PartLaborCostContextProvider } from './PartLaborCostContext';

interface IPartLaborCostProps {
  partTypeSelected: IVehiclePartType;
  partPriceSelected: IVehiclePartPrice | null;
  handleChangePartPriceAndIsOpenPartLaborCostDrawer: (
    partPrice: IVehiclePartPrice | null,
    isShowLaborCostDrawer: boolean
  ) => void;
  handleCloseAddPartsDrawer: () => void;
}

const PartLaborCost = ({
  partTypeSelected,
  partPriceSelected,
  handleChangePartPriceAndIsOpenPartLaborCostDrawer,
  handleCloseAddPartsDrawer,
}: IPartLaborCostProps) => {
  return (
    <PartLaborCostContextProvider partTypeSelected={partTypeSelected} partPriceSelected={partPriceSelected}>
      <PartLaborWrapper>
        <PartLaborCostComponent.Header
          partPriceSelected={partPriceSelected}
          handleChangePartPriceAndIsOpenPartLaborCostDrawer={handleChangePartPriceAndIsOpenPartLaborCostDrawer}
        />

        <PartLaborCostComponent.Content
          partPriceSelected={partPriceSelected}
          partOriginalServiceHours={partTypeSelected.partTemp.partTempOriginal}
        />

        <PartLaborCostComponent.Footer
          partTypeSelected={partTypeSelected}
          partPriceSelected={partPriceSelected}
          handleCloseAddPartsDrawer={handleCloseAddPartsDrawer}
        />
      </PartLaborWrapper>
    </PartLaborCostContextProvider>
  );
};

export default PartLaborCost;
