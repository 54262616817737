import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
  background-color: #fff;
  padding: 30px 50px 30px 50px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-right: auto;
  align-items: start;
  justify-content: end;
  gap: 10px;
`;
