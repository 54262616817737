import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'components';
import { toast } from 'react-toastify';
import { IDisassociateOperations } from 'services/ApiService/AccountService/types';
import AccountService from 'services/ApiService/AccountService';
import { mutateMany } from 'hooks/SWRUtils/SWRUtils';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { useHistory } from 'react-router-dom';
import { IDeleteAssociatedUserProps } from './DisassociateUserDialog.types';
import {
  DialogBase,
  DialogTitle,
  FormWrapper,
  DialogActions,
  ButtonWrapper,
  ConfirmActionWrapper,
  ConfirmActionText,
} from './DisassociateUserDialog.styles';
import { isAxiosError } from 'utils/isAxiosError';

export const DisassociateUserDialog: React.FC<IDeleteAssociatedUserProps> = ({
  dialogVisibility,
  accountId,
  username,
  handleClose,
}) => {
  const [confirmOperation, setConfirmOperation] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { clientId } = AccountService.getActiveProfileInLocalStorage();
  const { handleSubmit } = useForm({ mode: 'onBlur' });
  const { account } = useAuthState();

  const history = useHistory();
  const { signOut } = useAuthDispatch();

  const onSubmit = async (): Promise<void> => {
    const formData: IDisassociateOperations = {
      clientId,
      accountId,
    };

    try {
      setLoadingSubmit(true);
      await disassociateUser(formData);
      setLoadingSubmit(false);
    } catch (error) {
      setLoadingSubmit(false);
      toast.error('Por favor, selecione pelo menos uma operação.');
    }
  };

  const showToastError = (status: number | undefined): void => {
    if (status === 404) {
      toast.error('Usuário não associado');
    } else {
      toast.error('Erro ao desassociar usuário.');
    }
  };

  const disassociateUser = async (formData: IDisassociateOperations): Promise<void> => {
    try {
      await AccountService.disassociateUserFromAccountGroups(formData);
      toast.success('Usuário desassociado com sucesso!');
      if (hasUsernameEqualToTheLoggedInUser()) {
        LogOutUser();
      }
      updateSWRAccountGroups();
    } catch (error) {
      if (isAxiosError(error)) {
        showToastError(error.response?.status);
      } else {
        toast.error('Erro interno da aplicação');
      }
    }
  };
  const hasUsernameEqualToTheLoggedInUser = (): boolean => username === account.username;

  const LogOutUser = async (): Promise<void> => {
    try {
      await signOut();
      history.push('/login');
    } catch (error) {
      toast.error('Error ao tentar sair, tente novamente.');
    }
  };

  const closeDialog = (): void => {
    setConfirmOperation(false);
    handleClose();
  };

  const updateSWRAccountGroups = async (): Promise<void> => {
    await mutateMany(`^/account-groups/${clientId}(.*)`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmOperation(event.target.checked);
  };

  const disableSubmitButton = (): boolean => !confirmOperation;

  const ChangeConfirmOperation = (): void => setConfirmOperation(!confirmOperation);

  return (
    <DialogBase open={dialogVisibility} onClose={closeDialog}>
      <DialogTitle>Desassociar Usuário</DialogTitle>
      <FormWrapper>
        <Form testID="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <ConfirmActionWrapper>
            <Checkbox testId="disassociate-checkbox" checked={confirmOperation} onChange={handleChange} />
            <ConfirmActionText onClick={ChangeConfirmOperation}>
              Tenho ciência que, ao desassociar o usuário, retiro todas as operações do mesmo.
            </ConfirmActionText>
          </ConfirmActionWrapper>

          <DialogActions>
            <ButtonWrapper>
              <Button
                testID="dialog-button-cancel"
                type="button"
                variant="outlined"
                onClick={closeDialog}
                text="CANCELAR"
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                testID="dialog-button-submit"
                type="submit"
                text="FINALIZAR"
                disabled={disableSubmitButton()}
                loading={loadingSubmit}
                loadingSize={25}
                loadingColor="#FFF"
              />
            </ButtonWrapper>
          </DialogActions>
        </Form>
      </FormWrapper>
    </DialogBase>
  );
};
