export interface IInspectionTypeService {
  isPreviousInspection(inpectionTypeCode: string | undefined): boolean;
}

export interface IInspectionType {
  id: string;
  createdAt: string;
  updatedAt: string;
  code: string;
  description: string;
}

export enum InspectionTypeCodeEnum {
  PREVIOUS = 'PREVIOUS',
  INITIAL = 'INITIAL',
  COMPLEMENTARY = 'COMPLEMENTARY',
}
