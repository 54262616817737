import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';

export const ConclusionListContainer = styled.div`
  height: 675px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 15px;
  flex: 1;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const TabsBase = styled(Tabs)`
  .MuiTab-root {
    height: 50px;
    font-family: 'Montserrat' !important;
    max-width: 100% !important;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #ced6e5 !important;
  }

  .MuiButtonBase-root {
    flex: 1;
  }

  .MuiTab-textColorPrimary {
    color: #bcc4d2;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #000;
    font-weight: 700;
  }

  .MuiTabs-indicator {
    background-color: #000;
  }

  .MuiTab-wrapper {
    text-transform: capitalize;
  }
`;

export const CardBody = styled.div`
  height: 625px;
  padding: 24px 35px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;
