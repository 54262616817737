import ArrowDownwardRounded from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRounded from '@mui/icons-material/ArrowUpwardRounded';
import styled from 'styled-components';

export const ListItem = styled.li`
  min-height: 69px;
  padding: 5px 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
`;

export const ItemSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 35%;

  @media (max-width: 1715px) {
    width: 35%;
  }

  @media (max-width: 1310px) {
    width: 25%;
  }
`;

export const ItemLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #000000;
  }

  span {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    color: #00000080;
  }

  @media (max-width: 1440px) {
    strong {
      font-size: 12px;
    }
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 65%;
  justify-content: end;

  @media (max-width: 1715px) {
    gap: 10px;
    width: 65%;
  }

  @media (max-width: 1310px) {
    gap: 10px;
    width: 75%;
  }
`;

export const InformationItem = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 9px;
  max-width: 120px;

  &.widthSmaller {
    min-width: 35px;
    max-width: 70px;
  }

  &.widthQuantity {
    min-width: 25px;
    max-width: 45px;
  }

  &.widthService {
    min-width: 35px;
    max-width: 80px;
  }

  @media (max-width: 1366px) {
    min-width: 70px;

    &.widthSmaller {
      min-width: 40px;
    }

    &.widthService {
      min-width: 50px;
    }
  }
`;

export const InformationTitle = styled.span`
  color: #6a758b;
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 600;

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

export const InformationSubtitle = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: #000;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;

  @media (max-width: 1366px) {
    font-size: 11px;
  }
`;

export const ArrowUp = styled(ArrowUpwardRounded)`
  font-size: 16px !important;
  margin-left: -16px;
  color: #b60000;
`;

export const ArrowDown = styled(ArrowDownwardRounded)`
  font-size: 16px !important;
  margin-left: -16px;
  color: #099857;
`;

export const ChangeInfoWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

export const OldInformation = styled.span`
  color: #000;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: line-through;

  @media (max-width: 1366px) {
    font-size: 8px;
  }
`;

export const NewInformation = styled.span`
  color: #0e86ca;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

export const ItemActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;
