import CubeService from 'services/CubeService/cubeService';
import PhotoService from 'services/PhotoService/photoService';
import ApiService from '..';
import { IInspectionPhotoService } from './InspectionPhotoService.types';

const InspectionPhotoService: IInspectionPhotoService = {
  transformImageListTypeInInspectioPhoto: (images, maxStepFromPhotos, totalPhotosWithOriginUploadKite) => {
    const inspectionPhotos = images.map((image, index) => {
      const actualPhotoIndex = index + 1;
      const uploadIndex = actualPhotoIndex + totalPhotosWithOriginUploadKite;

      return {
        name: `upload ${uploadIndex}`,
        description: `upload ${uploadIndex}`,
        step: maxStepFromPhotos + actualPhotoIndex,
        origin: 'UPLOAD_KITE',
        base64: image.dataURL,
        file: image.file,
      };
    });

    return inspectionPhotos;
  },

  uploadAllPhotosToAwsS3: async (inspectionId, inspectionPhotos) => {
    const uploadPromises = inspectionPhotos.map(async (inspectionPhoto) => {
      try {
        if (inspectionPhoto.base64 && inspectionPhoto.file) {
          const photoBlob = await PhotoService.convertToBlob(inspectionPhoto.base64);

          const photoToUpload = {
            inspectionId,
            name: inspectionPhoto.name,
            description: inspectionPhoto.description,
            image: photoBlob,
            imageType: inspectionPhoto.file.type,
          };

          const { path } = await CubeService.uploadPhotoToS3(photoToUpload);

          return { ...inspectionPhoto, path };
        }

        return { ...inspectionPhoto };
      } catch {
        return { ...inspectionPhoto };
      }
    });

    return Promise.all(uploadPromises);
  },

  filterAllPhotosUploadedToAwsS3: (inspectionPhotos) => {
    return inspectionPhotos.filter((inspectionPhoto) => inspectionPhoto.path);
  },

  savePhotos: async (inspectionId, inspectionPhotos) => {
    await ApiService.post(`/photos/${inspectionId}`, {
      photos: inspectionPhotos,
    });
  },

  uploadPhotos: async (inspectionId, images, maxStepFromPhotos, totalPhotosWithOriginUploadKite) => {
    const inspectionPhotos = InspectionPhotoService.transformImageListTypeInInspectioPhoto(
      images,
      maxStepFromPhotos,
      totalPhotosWithOriginUploadKite
    );
    const photosResolved = await InspectionPhotoService.uploadAllPhotosToAwsS3(inspectionId, inspectionPhotos);
    const photosUploaded = InspectionPhotoService.filterAllPhotosUploadedToAwsS3(photosResolved);
    await InspectionPhotoService.savePhotos(inspectionId, photosUploaded);
  },
};

export default InspectionPhotoService;
