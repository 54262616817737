import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Grid } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'themes/default';
import { Label } from 'components/Label';
import CircularProgress from '@mui/material/CircularProgress';
import * as Style from './AsyncAutocomplete.styles';
import { IAutocompleteFieldProps } from './AsyncAutocomplete.types';

const AsyncAutocomplete = (props: IAutocompleteFieldProps) => {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ minWidth: '220px' }}>
        {props.label && <Label>{props.label}</Label>}
        <Autocomplete
          {...props}
          noOptionsText={props.noOptionsText || 'Sem resultado'}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          getOptionSelected={(option, value) => option.name === value.name}
          data-testid="autocomplete-field"
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          filterSelectedOptions
          renderInput={(params) => (
            <Style.TextField
              {...params}
              placeholder={props.placeholder}
              required={true}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(OptionsProps, option) => {
            return (
              <div {...OptionsProps}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box component="span" sx={{ fontWeight: option.selected ? 'bold' : 'regular' }}>
                      {OptionsProps.name}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            );
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default AsyncAutocomplete;
