import React from 'react';
import arrowDownIcon from 'assets/svg/arrowDown.svg';
import arrowUpIcon from 'assets/svg/arrowUp.svg';
import {
  PageBreakInside,
  Subtitle,
  FlexContainer,
  ServiceBadge,
  Description,
  ValueBadge,
  PartType,
  LabelContainer,
  LabelText,
  LabelDescription,
  IconImage,
  ServiceContainer,
} from './LabelsGuide.styles';

const LabelsGuide = () => {
  return (
    <PageBreakInside>
      <Subtitle>
        <FlexContainer>
          <ServiceContainer>
            <ServiceBadge className="bg-orange">R&I</ServiceBadge>
            <Description>Remoção & Instalação</Description>
          </ServiceContainer>

          <ServiceContainer>
            <ServiceBadge className="bg-red">R</ServiceBadge>
            <Description>Reparação</Description>
          </ServiceContainer>

          <ServiceContainer>
            <ServiceBadge className="bg-purple">P</ServiceBadge>
            <Description>Pintura</Description>
          </ServiceContainer>

          <ServiceContainer>
            <ValueBadge>
              <IconImage src={arrowUpIcon} alt="arrow up" />
            </ValueBadge>
            <Description>Acima do padrão</Description>
          </ServiceContainer>

          <ServiceContainer>
            <ValueBadge>
              <IconImage src={arrowDownIcon} alt="arrow down" />
            </ValueBadge>
            <Description>Abaixo do padrão</Description>
          </ServiceContainer>
        </FlexContainer>

        <PartType>
          <LabelContainer>
            <LabelText className="label-origin">Original</LabelText>
            <LabelDescription>
              Peça de reposição de mesma origem do fabricante, usada para reparo ou manutenção, mas não na linha de
              montagem.
            </LabelDescription>
          </LabelContainer>

          <LabelContainer>
            <LabelText className="label-genuine">Genuína</LabelText>
            <LabelDescription>Peça original: idêntica à usada na linha de montagem de veículos.</LabelDescription>
          </LabelContainer>

          <LabelContainer>
            <LabelText className="label-green">Verde</LabelText>
            <LabelDescription>Peça reciclada ou reaproveitada de componentes automotivos.</LabelDescription>
          </LabelContainer>

          <LabelContainer>
            <LabelText className="label-other-sources">Outras Fontes</LabelText>
            <LabelDescription>Peça de fabricante independente da montadora do veículo.</LabelDescription>
          </LabelContainer>
        </PartType>
      </Subtitle>
    </PageBreakInside>
  );
};

export default LabelsGuide;
