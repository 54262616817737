import { IOptions } from 'components/Select/Select.types';
import { TopTenRegulatorsSorting } from 'pages/Metrics/components/Regulators/TopTenRegulators.types';

export interface IMetricsService {
  searchMetricsStatus: (metricsFilter: IMetricsFilterRequest) => Promise<IMetricsStatusFilterResponse>;
  searchMetricsTime: (metricsFilter: IMetricsFilterRequest) => Promise<IMetricsTimeFilterResponse>;
  searchMetricsEfficiency: (metricsFilter: IMetricsFilterRequest) => Promise<IMetricsEfficiency>;
  getGraphicSubtitleElement: (name: string, graphicSubtitles: IMetricsStatus[]) => IMetricsStatus;
  generateGraphicData: (graphicSubtitles: IMetricsStatus[] | undefined) => IGraphicData[];
  removeZeroFromGraphicData: (graphicData: IGraphicData[]) => IGraphicData[];
  saveUserCycleTimeLimit: (cycleTime: ICycleTimeLimit) => Promise<void>;
  saveRegulationCycleTimeLimit: (cycleTime: ICycleTimeLimit) => Promise<void>;
  saveProcessCycleTimeLimit: (cycleTime: ICycleTimeLimit) => Promise<void>;
  isCycleTimeOutOfLimit: (cycletimeLimit: IMetricsCycleTimeLimitRequest) => Promise<IMetricsIsOutOfLimitsResponse>;
  getGeneralMetrics: (request: IFiltersRequest) => Promise<IGeneralMetricsResponse>;
  getCanceledInspectionsChart: (request: IFiltersRequest) => Promise<ICanceledInspectionCharts[]>;
  getFinishedInspectionsChart: (request: IFiltersRequest) => Promise<IFinishedInspectionCharts[]>;
  getTopTenRegulatorsMetrics: (filter: ITopTenRegulatorsMetricsFilter) => Promise<ITopTenRegulatorsMetricsResponse>;
  getCanceledInspectionsChartDetails: (request: IChartsDetailsCancelledRequest) => Promise<IChartsDetailsResponse>;
  getExpiredInspectionsChartDetails: (request: IChartsDetailsExpiredRequest) => Promise<IChartsDetailsResponse>;
  getFinishedInspectionsChartDetails: (request: IChartsDetailsFinishedRequest) => Promise<IChartsDetailsResponse>;
  getGeneralMetricsDetails: (request: IGeneralMetricsDetailsRequest) => Promise<IGeneralMetricsDetailsResponse>;
  getRegulatorsGeneralDetailsMetrics: (
    filter: IRegulatorsGeneralDetailsMetricsFilter
  ) => Promise<IRegulatorsGeneralDetailsResponse>;
  getRegulatorsGeneralDetailsMetricsCSV: (
    filter: IRegulatorsGeneralDetailsMetricsCSVFilter
  ) => Promise<IRegulatorsGeneralDetailsCSVResponse>;
}

export interface IGeneralMetricsResponse {
  riskOfDelayCount: number;
  inProgressCount: number;
  cancelledCount: number;
  notStartedCount: number;
  finishedCount: number;
  averageTime: string;
  totalCount: number;
}

export interface IChartsDetails {
  id: string;
  createdAt: string;
  code: string;
  schedule?: string;
  conclusionTime?: string;
  regulator: {
    username: string;
  };
  inspectionType: {
    description: string;
  };
  company: {
    name: string;
  };
  productType: {
    code: string;
    description: string;
  };
  currentStatus: {
    id: string;
    date: string;
    description: string;
  };
}
export interface IChartsDetailsResponse {
  inspections: IChartsDetails[];
  totalCount: number;
  totalPages: number;
}

export interface IMetricsFilterRequest {
  companiesId: string[];
  createdAtGreaterThan: string;
  createdAtLessThan: string;
  endProccessStatus?: string;
}

export interface IMetricsStatusFilterResponse {
  metrics: IMetricsStatus[];
}

export interface IMetricsTimeFilterResponse {
  metrics: IMetricsTime[];
}

export interface IMetricsStatus {
  name: string;
  value: number;
  outOfLimit?: boolean;
  limit?: string;
}

export interface IMetricsTime {
  name: string;
  value: string;
  limit: string;
  outOfLimit: boolean;
}

export interface IMetricsEfficiency {
  efficiency: number;
}

export interface IGraphicData {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface IMetricsCycleTimeLimitRequest {
  cycletime: string;
  limit: string;
}

export interface IMetricsIsOutOfLimitsResponse {
  isOutOfLimit: boolean;
}

export interface ICycleTimeLimit {
  cycleTimeLimit: string;
}

export interface ICanceledInspectionCharts {
  name: string;
  count: number;
  percentage: number;
  code: CanceledInspectionEnum;
}

export interface IFinishedInspectionCharts {
  name: string;
  count: number;
  percentage: number;
  code: FinishedInspectionEnum;
}

export interface ICanceledInspectionChartsResponse {
  metricSummaries: ICanceledInspectionCharts[];
}

export interface IFinishedInspectionChartsResponse {
  metricSummaries: IFinishedInspectionCharts[];
}
export interface IFiltersRequest {
  companyIds: string[];
  createdAtGreaterThan: string;
  createdAtLessThan: string;
  inspectionCategoryIds: string[];
  inspectionProductTypeIds: string[];
  regulatorIds: string[];
}

export interface IChartsDetailsCancelledRequest extends IFiltersRequest {
  page?: string;
  size?: string;
  cancellationMotiveIds: string[];
}

export interface IChartsDetailsExpiredRequest extends IFiltersRequest {
  page?: string;
  size?: string;
}

export interface IChartsDetailsFinishedRequest extends IFiltersRequest {
  page?: string;
  size?: string;
  inspectionFinishedTimes: FinishedInspectionEnum;
}

export enum FinishedInspectionEnum {
  WITHIN_24_HOURS = 'WITHIN_24_HOURS',
  WITHIN_48_HOURS = 'WITHIN_48_HOURS',
  MORE_THAN_48_HOURS = 'MORE_THAN_48_HOURS',
}

export enum CanceledInspectionEnum {
  ALREADY_FINISHED = 'ALREADY_FINISHED',
  CANCELED_BY_INSURANCE = 'CANCELED_BY_INSURANCE',
  FAILED_TO_SEND_IMAGES = 'FAILED_TO_SEND_IMAGES',
  ABSENT_VEHICLE = 'ABSENT_VEHICLE',
  OTHERS = 'OTHERS',
  INSPECTION_EXPIRED = 'INSPECTION_EXPIRED',
}

export interface ITopTenRegulatorsMetrics {
  regulatorName: string;
  inProgressInspectionsCount: number;
  atRiskOfDelay: number;
  notStartedInspectionsCount: number;
  cancelledInspectionsCount: number;
  finishedInspectionsCount: number;
  finishedInspectionPercentage: number;
}

export interface ITopTenRegulatorsMetricsParams {
  accountId?: string;
  createdAtGreaterThan?: string;
  createdAtLessThan?: string;
  companyIds?: string;
  inspectionCategoryIds?: string;
  productTypeIds?: string;
  clientId: string;
  sortParam: TopTenRegulatorsSorting;
  isAsc: boolean;
}

export interface ITopTenRegulatorsMetricsFilter {
  regulatorSelected: IOptions | null;
  startDateSelected: string;
  endDateSelected: string;
  companiesSelected: string[];
  categoriesSelected: string[];
  productTypesSelected: string[];
  clientId: string;
  sortParam: TopTenRegulatorsSorting;
  isAsc: boolean;
}

export interface ITopTenRegulatorsMetricsResponse {
  sortedRegulatorsMetricsOutput: ITopTenRegulatorsMetrics[];
}

export interface IGeneralMetricsDetailsRequest {
  companyIds: string[];
  createdAtGreaterThan: string;
  createdAtLessThan: string;
  inspectionCategoryIds: string[];
  inspectionProductTypeIds: string[];
  regulatorIds: string[];
  page: number;
  size: number;
  filter: GeneralMetricsDetailsFilterEnum;
}

export enum GeneralMetricsDetailsFilterEnum {
  RISK_OF_DELAY = 'RISK_OF_DELAY',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  NOT_STARTED = 'NOT_STARTED',
  FINISHED = 'FINISHED',
}

export interface IGeneralMetricsDetailsResponse {
  inspections: IGeneralMetricsDetailsFilteredInspection[];
  totalCount: number;
  totalPages: number;
}

export interface IGeneralMetricsDetailsFilteredInspection {
  code?: string;
  regulator?: string;
  inspectionTypeName?: string;
  company?: string;
  productTypeName?: string;
  createdDate?: string;
  startedDate?: string;
  finishedDate?: string;
  averageTime?: string;
  averageTimeLong?: string;
  cancelledReason?: string;
}

export interface IRegulatorsGeneralDetailsMetricsFilter {
  regulatorSelected: IOptions | null;
  startDateSelected: string;
  endDateSelected: string;
  companiesSelected: string[];
  categoriesSelected: string[];
  productTypesSelected: string[];
  clientId: string;
  page: number;
}

export interface IRegulatorsGeneralDetailsMetricsParams {
  accountIds?: string;
  createdAtGreaterThan?: string;
  createdAtLessThan?: string;
  companyIds?: string;
  inspectionCategoriesId?: string;
  productTypesId?: string;
  clientId: string;
  page: number;
  size: number;
}

export interface IRegulatorsGeneralDetails {
  regulatorName: string;
  regulatorId: string;
  inProgressInspectionsCount: number;
  atRiskOfDelay: number;
  notStartedInspectionsCount: number;
  cancelledInspectionsCount: number;
  finishedInspectionsCount: number;
  finishedInspectionPercentage: number;
}

export interface IRegulatorsGeneralDetailsResponse {
  regulatorsMetrics: IRegulatorsGeneralDetails[];
  totalCount: number;
  totalPages: number;
}

export type IRegulatorsGeneralDetailsMetricsCSVFilter = Omit<IRegulatorsGeneralDetailsMetricsFilter, 'page'>;

export type IRegulatorsGeneralDetailsMetricsCSVParams = Omit<IRegulatorsGeneralDetailsMetricsParams, 'page' | 'size'>;

export type IRegulatorsGeneralDetailsCSV = Omit<IRegulatorsGeneralDetails, 'regulatorId'>;

export interface IRegulatorsGeneralDetailsCSVResponse {
  regulatorsMetrics: IRegulatorsGeneralDetailsCSV[];
}
