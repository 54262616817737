import React from 'react';
import { IBudgetItemOutsourcedService } from 'commom/types/Budget.types';
import { priceFormatter } from 'utils/formatPrice';
import {
  InformationServiceContainer,
  InformationServiceItem,
  InformationSubtitle,
  InformationTitle,
  ItemActionsContainer,
  ItemLabelContainer,
  ItemSelectContainer,
  ListItem,
} from './OutsourcedServiceItem.style';
import RemoveOutsourcedServiceAction from './RemoveOutsourcedServiceAction/RemoveOutsourcedServiceAction';
import EditOutsourcedServiceAction from './EditOutsourcedServiceAction/EditOutsourcedServiceAction';

interface IOutsourcedServiceItemProps {
  service: IBudgetItemOutsourcedService;
}

const OutsourcedServiceItem = ({ service }: IOutsourcedServiceItemProps) => {
  return (
    <ListItem>
      <ItemSelectContainer>
        <ItemLabelContainer>
          <strong>{service.name}</strong>
        </ItemLabelContainer>
      </ItemSelectContainer>
      <InformationServiceContainer>
        <InformationServiceItem>
          <InformationTitle>Valor Total</InformationTitle>
          <InformationSubtitle>{priceFormatter.format(service.price)}</InformationSubtitle>
        </InformationServiceItem>
      </InformationServiceContainer>
      <ItemActionsContainer>
        <RemoveOutsourcedServiceAction serviceId={service.id} />
        <EditOutsourcedServiceAction outsourcedService={service} />
      </ItemActionsContainer>
    </ListItem>
  );
};

export default OutsourcedServiceItem;
