import styled from 'styled-components';
import { Label as LabelComponent } from 'components';
import { Select as SelectComponent } from 'components/Select/Select';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const CardContainer = styled.div`
  height: 675px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 15px;
  flex: 1;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const CardBody = styled.div`
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled.h2`
  height: 50px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  border-bottom: 1px solid #ced6e5;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 33px;
`;

export const Label = styled(LabelComponent)`
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
`;

export const Select = styled(SelectComponent)`
  border: 0.3px solid #ced6e5;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 15px;
  justify-content: space-between;
`;

export const SelectItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 100%;
`;

export const TextField = styled(TextFieldComponent)`
  width: 100%;
  border: 1px solid #ced6e5;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;
