import React from 'react';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { Subtitle, Title, TitleCanceled, TitleWrapper } from './HeaderTitle.styles';

interface IHeaderTitleProps {
  inspection: IInspection;
}

const HeaderTitle = ({ inspection }: IHeaderTitleProps) => {
  const { status, company, inspectionCategory, code, currentResponsible } = inspection;
  const isInspectionCanceled = InspectionStatusService.isCanceled(status);
  const isInspectionExpired = InspectionStatusService.isExpired(status);
  const titleCanledOrExpiredComplement = isInspectionCanceled ? 'Cancelado' : 'Expirado';

  const getCategoryName = () => {
    return inspectionCategory ? inspectionCategory.name : '';
  };

  return (
    <TitleWrapper>
      {!!company && (
        <Subtitle>
          Operação <b>{company?.name}</b>
        </Subtitle>
      )}

      {isInspectionCanceled || isInspectionExpired ? (
        <TitleCanceled>
          Atendimento {getCategoryName()} #{code} - {titleCanledOrExpiredComplement}
        </TitleCanceled>
      ) : (
        <>
          <Title>
            Atendimento {getCategoryName()} #{code}
          </Title>
        </>
      )}

      {!!currentResponsible && (
        <Subtitle>
          Responsável <b>{currentResponsible.username}</b>
        </Subtitle>
      )}
    </TitleWrapper>
  );
};

export default HeaderTitle;
