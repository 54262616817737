import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailConfirmationService from 'services/ApiService/EmailConfirmationService/emailConfirmationService';

import {
  Container,
  Illustration,
  Information,
  Title,
  Description,
  Button,
} from '../../../PasswordRecovery/PasswordRecovery.styles';

export const EmailConfirmed: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation().search;

  const getTokenFromUrl = (): string | null => {
    const query = new URLSearchParams(location);
    return query.get('token');
  };

  const redirectWhenTokenExpired = (): void => {
    toast.error('Link expirado, tente reenviar o email.');
    history.push('/email-confirmation');
  };

  const tokenValidated = (): void => {
    toast.success('Email confirmado com sucesso.');
    history.push('/login');
  };

  const checkValidityToken = (valid: boolean): void => {
    valid ? tokenValidated() : redirectWhenTokenExpired();
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const token = getTokenFromUrl();

      if (token) {
        setLoading(true);
        const { valid } = await EmailConfirmationService.newEmail(token);
        setLoading(false);

        checkValidityToken(valid);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao confirmar email.');
    }
  };

  return (
    <Container>
      <Illustration />
      <Information>
        <Title>Falta pouco!</Title>
        <Description>
          Clique no botão abaixo para confirmar o seu email e ser redirecionado para a tela de login.
        </Description>

        <Button
          fullWidth={true}
          loading={loading}
          loadingColor="#ffffff"
          loadingSize={26}
          size="large"
          text="CONFIRMAR EMAIL"
          type="button"
          onClick={(): Promise<void> => handleSubmit()}
        />
      </Information>
    </Container>
  );
};
