import { ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';

const sizeContent = {
  small: '40px',
  medium: '50px',
  large: '60px',
};

const fontSizeContent = {
  small: '8px',
  medium: '10px',
  large: '13px',
};

interface IToggleButtonGroup {
  fontSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

export const ToggleButtonGroupBase = styled(ToggleButtonGroup)<IToggleButtonGroup>`
  display: flex;
  width: 100%;
  height: ${({ size }) => sizeContent[size || 'medium']};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  #option1 {
    border-radius: 7px 0 0 7px;
    &:active {
      border: 1px solid #fcfcfc;
    }
  }

  #option2 {
    border-radius: 0 7px 7px 0;
    &:active {
      border: 1px solid #fcfcfc;
    }
  }

  .MuiToggleButton-root {
    font-size: ${({ fontSize }) => fontSizeContent[fontSize || 'large']} !important;
    padding: 10px 0px;
    color: black !important;
    background-color: #fcfcfc !important;
    width: 50%;
  }
  .Mui-selected {
    background-color: #000000 !important;
    color: white !important;
  }
`;

export default ToggleButtonGroupBase;
