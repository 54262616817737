import styled from 'styled-components';
import { Stepper, StepLabel, StepIconProps } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const StepConnectorWrapper = withStyles({
  alternativeLabel: {
    top: 22,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
  },
  active: {
    '& $line': {
      backgroundColor: '#95a1b6',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#008080',
    },
  },
  disabled: {
    '& $line': {
      backgroundColor: '#95a1b6',
    },
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: '#95a1b6',
    borderRadius: 1,
  },
})(StepConnector);

export const StepErrorConnectorWrapper = withStyles({
  alternativeLabel: {
    top: 22,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: '#E71C40',
    borderRadius: 1,
  },
})(StepConnector);

export const StepperLabelWraper = withStyles({
  label: {
    color: '#6A758B !important',
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Montserrat,sans-serif',
  },
})(StepLabel);

export const StepperContainer = styled(Stepper)`
  background-color: #fff;
  width: 100%;
`;

export const StepperIconWraper = makeStyles({
  root: {
    backgroundColor: '#95a1b6',
    padding: 10,
    borderRadius: '100%',
    height: 50,
    width: 50,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#95a1b6',
  },
  completed: {
    backgroundColor: '#008080',
  },
  error: {
    backgroundColor: '#E71C40',
  },
});

export const getIconClass = (props: StepIconProps): string => {
  const { active, completed, error } = props;
  const classes = StepperIconWraper();

  return clsx(classes.root, {
    [classes.active]: active,
    [classes.completed]: completed,
    [classes.error]: error,
  });
};

export const StatusEmpty = styled.div`
  justify-content: center;
  display: flex;
  padding-bottom: 30px;
  font-weight: 500;
  color: #6a758b;
  font-size: 22px;
`;
