import styled from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkToOpenCap = styled.a`
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding: 8px 22px;
  background-color: #000;
  border-radius: 4px;
`;
