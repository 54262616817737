export enum IBudgetReportTypeEnum {
  RESCUED = 'RESCUED',
  DAMAGE = 'DAMAGE',
}

export enum IBudgetReportQuestionTypeEnum {
  SELECT = 'SELECT',
  INPUT = 'INPUT',
  SELECT_AND_INPUT = 'SELECT_AND_INPUT',
}

export enum GeneralEvaluationEnum {
  RECOVERABLE = 'RECOVERABLE',
  NON_RECOVERABLE = 'NON_RECOVERABLE',
}

export enum IReportDamagePartsSeverityEnum {
  LIGHT_DAMAGE = 'LIGHT_DAMAGE',
  MEDIUM_DAMAGE = 'MEDIUM_DAMAGE',
  HEAVY_DAMAGE = 'HEAVY_DAMAGE',
}

export interface IBudgetReportQuestionOption {
  createdAt: string;
  updatedAt: string;
  _id: string;
  label: string;
  slug: string;
}

export interface IBudgetReportQuestion {
  createdAt: string;
  updatedAt: string;
  _id: string;
  text: string;
  weight: number;
  type: IBudgetReportQuestionTypeEnum;
  textAnswer: string | null;
  answerId: string | null;
  options: IBudgetReportQuestionOption[];
}

export interface IBudgetReportQuestionGroup {
  createdAt: string;
  updatedAt: string;
  _id: string;
  text: string;
  questions: IBudgetReportQuestion[];
}

export interface IBudgetReport {
  createdAt: string;
  updatedAt: string;
  _id: string;
  form: string;
  type: IBudgetReportTypeEnum;
  isDraft: boolean;
  questionsGroups: IBudgetReportQuestionGroup[];
}

export interface IReportRescuedPartsVehicleEvaluation {
  withoutEngineAccess: boolean;
  generalEvaluation: GeneralEvaluationEnum | null;
  engineNumber: string | null;
  justifications: string | null;
}

export interface IReportRescuedParts extends IBudgetReport {
  vehicleEvaluation: IReportRescuedPartsVehicleEvaluation;
}

export interface IReportDamagePartsSeverity {
  yes: number;
  notEvaluated: number;
  severityPoints: number;
  severity: IReportDamagePartsSeverityEnum | null;
}

export interface IReportDamageParts extends IBudgetReport {
  damagePartSeverity: IReportDamagePartsSeverity;
}
