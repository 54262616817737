import React, { ReactNode } from 'react';
import * as Styled from './styles';

interface IGeneralMetricsCardRoot {
  children: ReactNode;
  backgroundColor: string;
  borderColor: string;
}

export const GeneralMetricsCardRoot = ({ children, backgroundColor, borderColor }: IGeneralMetricsCardRoot) => {
  return (
    <Styled.Container data-testid="root-card-component" background={backgroundColor} borderColor={borderColor}>
      {children}
    </Styled.Container>
  );
};
