import { IBudgetReportTypeEnum } from 'commom/types/BudgetReport.types';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import BudgetReportService from 'services/ApiService/BudgetReportService/BudgetReportService';
import { selectReports, selectRescuedReport } from 'stores/presentBudget/presentBudget.selector';
import { setRescuedReport } from 'stores/presentBudget/presentBudget.store';
import RescuedReportActions from '../../RescuedDamageReportActions/RescuedDamageReportActions';
import RescuedCaption from './RescueCaption/RescueCaption';
import { Container, Header, Title } from './RescuedReport.styles';
import RescuedReportForm from './RescuedReportForm/RescuedReportForm';
import RescuedReportFormSkeleton from './RescueReportSkeleton/RescuedReportFormSkeleton/RescuedReportFormSkeleton';

const RescuedReport = () => {
  const budgetReports = useAppSelector(selectReports);
  const report = useAppSelector(selectRescuedReport);

  const dispatch = useAppDispatch();

  const reportId = budgetReports?.find(({ reportType }) => reportType === IBudgetReportTypeEnum.RESCUED)?.reportId;

  const { isLoading } = useQuery(
    ['get-rescued-report-parts', { reportId }],
    () => BudgetReportService.getBudgetReportRescuedParts(reportId as string),
    {
      onSuccess: (data) => {
        dispatch(setRescuedReport(data));
      },
      onError: () => {
        toast.error('Erro ao buscar dados do laudo de salvados');
      },
      enabled: !!reportId,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || !report) {
    return (
      <Container>
        <Header>
          <Title>Laudo de salvados</Title>
        </Header>
        <RescuedCaption />
        <RescuedReportFormSkeleton />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Laudo de salvados</Title>
        <RescuedReportActions report={report} />
      </Header>
      <RescuedCaption />
      <RescuedReportForm />
    </Container>
  );
};

export default RescuedReport;
