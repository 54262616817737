import React from 'react';
import Route from 'routes/CustomRoute';
import { Switch } from 'react-router-dom';
import { InvoiceDetails } from './components/Details';
import { InvoicesDashboard } from './components/Dashboard';

function InvoicesRouter() {
  return (
    <Switch>
      <Route exact path="/invoices" component={() => <InvoicesDashboard />} isPrivate />
      <Route path="/invoices/:id" component={() => <InvoiceDetails />} isPrivate />
    </Switch>
  );
}

export default React.memo(InvoicesRouter, (prevProps, nextProps) => prevProps === nextProps);
