import React from 'react';
import detailsEmptyAnimation from 'assets/svg/attend-details-default.svg';
import * as S from './EmptyInspection.styles';
import AccountService from 'services/ApiService/AccountService';

const EmptyInspection = () => {
  const { clientId } = AccountService.getActiveProfileInLocalStorage();
  const accountGroup = AccountService.getClientInAccountGroupInLocalStorageByClientId(clientId);

  const haveClientMessage = accountGroup ? `ou esta associada a ${accountGroup.client.name}` : '';
  return (
    <S.Container data-testid="empty-inspection">
      <img src={detailsEmptyAnimation} alt="Não há vistoria" data-testid="empty-inspection-svg" />
      <S.Description>Vistoria não encontrada</S.Description>
      <S.SubDescription>
        Verifique se o ID da vistoria está correto {haveClientMessage} e tente novamente.
      </S.SubDescription>
    </S.Container>
  );
};

export default EmptyInspection;
