import { Button, Label } from 'components';
import { Drawer } from 'components/Drawer';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useState } from 'react';
import NumericFormat from 'react-number-format';
import { toast } from 'react-toastify';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { BudgetLaborCostsService } from 'services/BudgetLaborCostsService/BudgetLaborCostsService';
import { ICreateDealState } from 'services/BudgetLaborCostsService/BudgetLaborCostsService.types';
import {
  selectBudgetId,
  selectCost,
  selectLaborDiscount,
  selectLaborIncrease,
} from 'stores/presentBudget/presentBudget.selector';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import {
  ActionsContainer,
  Description,
  DiscountAndIncreaseContent,
  DrawerContent,
  Separator,
  TextField,
  ToastContent,
  ToastSubtitle,
} from './CreateDeal.style';
import { ICreateDealProps } from './CreateDeal.types';

const CreateDeal = ({ setCreateDeal, onCloseDrawer }: ICreateDealProps) => {
  const costStore = useAppSelector(selectCost);
  const discountStore = useAppSelector(selectLaborDiscount);
  const increaseStore = useAppSelector(selectLaborIncrease);
  const budgetId = useAppSelector(selectBudgetId);
  const [costsFormState, setCostsFormState] = useState<ICreateDealState>(
    BudgetLaborCostsService.getCreateDealFormSateByCosts(costStore)
  );
  const [discount, setDiscount] = useState(discountStore >= 0 ? discountStore : 0);
  const [increase, setIncrease] = useState(increaseStore >= 0 ? increaseStore : 0);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleValueChange = (field: keyof ICreateDealState, price: number | undefined) => {
    setCostsFormState((prev) => ({
      ...prev,
      [field]: { ...prev[field], price: price || 0 },
    }));
  };

  const handleDiscountChange = (floatValue: number | undefined) => {
    setDiscount(floatValue !== undefined && floatValue >= 0 ? floatValue : 0);
  };

  const handleIncreaseChange = (floatValue: number | undefined) => {
    setIncrease(floatValue !== undefined && floatValue >= 0 ? floatValue : 0);
  };

  const createDeal = async () => {
    setLoading(true);
    try {
      const cost = BudgetLaborCostsService.getLaborCostsFromDealState(costsFormState);
      const budgetResponse = await BudgetService.createBudgetDeal(budgetId, cost, discount, increase);
      dispatch(setBudget(budgetResponse));
      setLoading(false);
      setCreateDeal(false);

      toast.success(
        <ToastContent>
          <div>Negociação criada!</div>
          <ToastSubtitle>A negociação foi criada com sucesso.</ToastSubtitle>
        </ToastContent>,
        {
          icon: false,
        }
      );
      onCloseDrawer();
    } catch {
      toast.error('Erro ao criar negociação');
      setLoading(false);
    }
  };

  const validateInputPriceValue = (): boolean =>
    [
      costsFormState.REPAIR.price,
      costsFormState.PAINTING.price,
      costsFormState.TRICOAT_PAINTING.price,
      costsFormState.METALLIC_PAINTING.price,
      costsFormState.PEARLIZED_PAINTING.price,
      costsFormState.LABOR.price,
      increase,
      discount,
    ].some((value) => typeof value !== 'number' || value < 0);

  const renderNumericInput = (label: string, field: keyof ICreateDealState, testId: string) => (
    <div>
      <Label htmlFor={field} testID={`label-${testId}`}>
        {label}
      </Label>
      <NumericFormat
        customInput={TextField}
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={2}
        fixedDecimalScale={true}
        value={costsFormState[field].price}
        disabled={loading}
        prefix="R$ "
        allowNegative={false}
        allowLeadingZeros={false}
        isNumericString
        placeholder={`Insira o valor de ${label.toLowerCase()}`}
        onValueChange={({ floatValue }) => handleValueChange(field, floatValue)}
        inputProps={{ 'data-testid': `${testId}-input` }}
        {...{ variant: 'outlined', role: field, name: field, id: field }}
      />
    </div>
  );

  return (
    <>
      <Drawer.Header>
        <Drawer.Title>Negociação</Drawer.Title>
        <Drawer.Subtitle>Negocie os valores de mão de obra e desconto e altere no orçamento.</Drawer.Subtitle>
      </Drawer.Header>

      <DrawerContent>
        <Description>Valores de mão de obra</Description>
        {renderNumericInput('Pintura', 'PAINTING', 'painting')}
        {renderNumericInput('Reparo', 'REPAIR', 'repair')}
        {renderNumericInput('Pintura tricoat', 'TRICOAT_PAINTING', 'tricoat')}
        {renderNumericInput('Pintura perolizada', 'PEARLIZED_PAINTING', 'pearlized')}
        {renderNumericInput('Pintura metalizada', 'METALLIC_PAINTING', 'metallic')}
        {renderNumericInput('Remoção e instalação', 'LABOR', 'labor')}

        <Description>Desconto e majoração de peças</Description>

        <DiscountAndIncreaseContent>
          <div>
            <Label htmlFor="discount" testID="label-discount">
              Desconto
            </Label>
            <NumericFormat
              value={discount}
              placeholder="Insira o valor do desconto"
              prefix="% "
              defaultValue={0}
              min={0}
              disabled={loading}
              customInput={TextField}
              allowNegative={false}
              inputProps={{ 'data-testid': 'input-discount' }}
              onValueChange={({ floatValue }) => handleDiscountChange(floatValue)}
              {...{ variant: 'outlined' }}
            />
          </div>

          <div>
            <Label htmlFor="increase" testID="label-increase">
              Majoração
            </Label>
            <NumericFormat
              value={increase}
              placeholder="Insira o valor da majoração"
              disabled={loading}
              prefix="% "
              defaultValue={0}
              min={0}
              allowEmptyFormatting={false}
              customInput={TextField}
              allowNegative={false}
              inputProps={{ 'data-testid': 'input-increase' }}
              onValueChange={({ floatValue }) => handleIncreaseChange(floatValue)}
              {...{ variant: 'outlined' }}
            />
          </div>
        </DiscountAndIncreaseContent>
      </DrawerContent>

      <Separator />

      <ActionsContainer>
        <Button
          testID="cancel-create-deal-button"
          onClick={() => setCreateDeal(false)}
          variant="outlined"
          text="Cancelar"
        />
        <Button
          testID="save-create-deal-button"
          onClick={createDeal}
          disabled={validateInputPriceValue()}
          loading={loading}
          variant="contained"
          text="Salvar"
        />
      </ActionsContainer>
    </>
  );
};

export default CreateDeal;
