import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const DetailsLoadingWrapper = styled.div`
  padding: 40px 30px;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: #6a758b;
  margin: 0;
  margin-bottom: 30px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 600;
`;

export const TitleCanceled = styled(Title)`
  color: #c73c3c;
`;

export const DetailsEmptyContainer = styled.div`
  width: 100%;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DetailsEmptyIllustration = styled.img`
  width: 70%;
  height: 70%;
`;

export const DetailsEmptyAnimationTitle = styled.h1`
  font-weight: 600;
  font-size: 22px;
  color: #6a758b;
  text-align: center;
  margin: 0 0 10px 0;
`;

export const DetailsEmptyAnimationSubTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #6a758b;
  text-align: center;
  margin: auto;
  max-width: 280px;
`;

export const DetailsContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const DetailsHeader = styled.div`
  position: relative;
  background-color: #fff;
  padding: 30px 50px 0 50px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
`;

export const DetailsBody = styled.div`
  padding: 40px 30px;
  flex: 1;
  margin-bottom: 95px;
`;

export const TabsBase = styled(Tabs)`
  margin-top: 10px;

  .MuiTabs-indicator {
    background-color: #000;
  }

  .MuiTabs-centered {
    justify-content: space-evenly;
  }
`;

export const ContainerLinkNotSent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 230px 0 230px;
`;

export const TitleLinkNotSent = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: #6a758b;
`;

export const DescriptionLinkNotSent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #6a758b;
  text-align: center;

  span {
    font-weight: bold;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 23px;
  top: 20px;
`;
