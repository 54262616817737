import styled from 'styled-components';
import { Accordion as AccordionComponent, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';
import { IconButton as ButtonIcon } from '@mui/material';

export const Accordion = styled(AccordionComponent)`
  width: 100%;
  box-shadow: none !important;
  border-radius: 0px !important;
  margin: 0px !important;

  .MuiButtonBase-root {
    flex-direction: row-reverse;
    justify-content: start;

    .MuiAccordionSummary-content {
      width: 100%;
      align-items: center;
      flex-grow: unset;
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
    height: 50px;
    padding: 10px 0px;
  }

  .MuiAccordionSummary-root {
    min-height: 0;
    height: 50px;
    padding: 10px 0;
    cursor: default !important;
  }
`;

export const Summary = styled(AccordionSummary)`
  .MuiTouchRipple-root {
    display: none;
  }
`;

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #000000;
`;

export const Details = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 16px !important;
`;

export const TabsBase = styled(Tabs)`
  .MuiTab-root {
    min-width: 0;
    padding: 6px;
    text-transform: none;
    font-family: 'Montserrat' !important;
  }

  .MuiTab-textColorPrimary {
    color: #bcc4d2;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #000;
    font-weight: 600;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-centered {
    justify-content: space-evenly;
  }

  .MuiTab-wrapper {
    font-size: 16px;
  }
`;

export const IconButton = styled(ButtonIcon)`
  margin-left: auto !important;

  .MuiSvgIcon-root {
    color: #000;
  }
`;

export const ExpandIcon = styled(ExpandMore)`
  color: #000;
  cursor: pointer;
`;
