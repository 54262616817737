import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px;
    border-radius: 4px;
    max-width: 380px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  height: 24px;
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 16px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 330px;
  margin-bottom: 25px;
`;

export const MessageError = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
`;

export const ContainerButton = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 25px;
`;
