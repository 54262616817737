export type DetailsProps = {
  inspectionId: string;
};

export type ActiveTab = 'tab-inspection' | 'tab-transmission' | 'tab-budgets';

export enum ACTIVE_TAB_ENUM {
  TAB_INSPECTION = 'tab-inspection',
  TAB_TRANSMISSION = 'tab-transmission',
  TAB_BUDGETS = 'tab-budgets',
}
