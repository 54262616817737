import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthState } from 'contexts/auth/useAuth';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { ISidenavProps, Sidenav } from './components/Sidenav';
import { SlideMenu } from './components/SlideMenu';

import { Avatar, Badge, SidebarWrapper, ClientAvatar } from './Sidebar.styles';

const allowedUrls = ['/agendamentos', '/management-dashboard', '/users-dashboard', '/metrics', '/invoices', '/cap'];

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { account } = useAuthState();
  const { activeProfile, accountGroups } = useActiveProfile();

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  const isAllowedUrl = (): boolean | RegExpMatchArray => {
    return pathname.match(new RegExp(allowedUrls.join('|'))) || pathname === '/';
  };

  const getActiveProfileLogo = (): string | undefined => {
    return accountGroups?.groups?.find((group) => group.client.id === activeProfile?.clientId)?.client.logoUrl;
  };

  if (!isAllowedUrl()) return null;

  const sidenavProps: ISidenavProps = {
    toggleButton: (
      <Badge
        data-prevent-close="true"
        onClick={toggle}
        data-testid="badge-icon"
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar testId="avatar-icon" color={account.avatar?.color}>
          {account?.username}
        </Avatar>
        <ClientAvatar src={getActiveProfileLogo()} />
      </Badge>
    ),
  };

  return (
    <SidebarWrapper data-testid="sidebar">
      <Sidenav {...sidenavProps} />
      <SlideMenu onChange={setIsOpen} open={isOpen} position="bottom" left="60px" />
    </SidebarWrapper>
  );
};
