import ApiService from '..';
import { ISavePhotoResponse, ITransmissionResponse, ITransmissionService } from './transmissionService.types';

const TransmissionService: ITransmissionService = {
  savePhoto: async (photo, inspectionId) => {
    const { data } = await ApiService.post<ISavePhotoResponse>(`/photos/transmission/${inspectionId}`, {
      photo,
    });

    return data;
  },

  saveComment: async (comment, transmissionSessionId) => {
    const { data } = await ApiService.post<ITransmissionResponse>(
      `/transmission-session/comments/${transmissionSessionId}`,
      {
        comment,
      }
    );

    return data;
  },

  stopArchiving: async (transmissionSessionId) => {
    const { data } = await ApiService.post<ITransmissionResponse>('/transmission-session/stop-archiving', {
      transmissionSessionId,
    });

    return data;
  },
};

export default TransmissionService;
