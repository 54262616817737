import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ClickAwayListener, ListItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { EditProfileDialog } from 'components/Dialog/EditProfileDialog';
import { ChangePasswordDialog } from 'components/Dialog/ChangePasswordDialog';
import { IAccountGroups, IClient } from 'services/ApiService/AccountService/types';
import { useAppDispatch } from 'hooks/hooks';

import { updateActiveProfile } from 'stores/activeProfile.store';
import { BudgetSettingsDialog } from 'components/Dialog/BudgetSettingsDialog';
import { useModules } from 'contexts/modules/useModules';
import {
  SlideMenuWrapper,
  Head,
  Content,
  Title,
  List,
  ListItemText,
  Icon,
  Profile,
  SelectProfile,
  Subtitle,
  ProfileIcon,
  ShadowIcon,
  ClientName,
  ListClients,
  CurrentProfile,
} from './SlideMenu.styles';
import { ISlideMenuProps } from './SlideMenu.types';

export const SlideMenu: React.FC<ISlideMenuProps> = ({
  testID = 'sidemenu',
  position,
  left = '0px',
  open = false,
  onChange,
}) => {
  const history = useHistory();
  const { signOut, isAdmin } = useAuthDispatch();
  const { account } = useAuthState();
  const { accountGroups, setActiveProfile, activeProfile } = useActiveProfile();
  const { setSelectedClientModules } = useModules();
  const [editProfileDialogIsOpen, setEditProfileDialogVisibility] = useState(false);
  const [changePasswordDialogIsOpen, setChangePasswordDialogVisibility] = useState(false);
  const [budgetSettingsDialogIsOpen, setBudgetSettingsDialogVisibility] = useState(false);
  const dispatch = useAppDispatch();

  const close = (): void => {
    onChange && onChange(false);
  };

  const fromAccountGroupsToClient = (accGroups: IAccountGroups): IClient[] => {
    return accGroups?.groups
      ?.filter((group) => group.client.id !== activeProfile?.clientId)
      .map((group) => {
        return {
          id: group.client.id,
          name: group.client.name,
          logoUrl: group.client.logoUrl,
        };
      });
  };

  const getActiveProfileName = (): string | undefined => {
    return accountGroups?.groups?.find((group) => group.client.id === activeProfile?.clientId)?.client.name;
  };

  const getActiveProfileLogo = (): string | undefined => {
    return accountGroups?.groups?.find((group) => group.client.id === activeProfile?.clientId)?.client.logoUrl;
  };

  const resetApplication = (): void => {
    const { pathname } = window.location;

    if (pathname !== '/') {
      history.push('/');
    }
  };

  const selectProfile = async (clientId: string): Promise<void> => {
    await setActiveProfile(clientId);
    await setSelectedClientModules(clientId);

    dispatch(updateActiveProfile(clientId));
    resetApplication();
  };

  const hasOnlyOneClient = (): boolean => {
    return accountGroups?.groups?.length === 1;
  };

  const handleClickAway = (e: React.MouseEvent<Document, MouseEvent>): void => {
    const { preventClose } = (e.target as HTMLElement).dataset;

    if (open && !preventClose && !budgetSettingsDialogIsOpen && !editProfileDialogIsOpen) {
      close();
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await signOut();

      history.push('/login');
    } catch (error) {
      toast.error('Error ao tentar sair, tente novamente.');
    }
    close();
  };

  const handleEditProfileOpen = (): void => {
    setEditProfileDialogVisibility(true);
  };

  const handleEditProfileClose = (): void => {
    setEditProfileDialogVisibility(false);
  };

  const handleChangePasswordOpen = (): void => {
    setChangePasswordDialogVisibility(true);
  };

  const handleChangePasswordClose = (): void => {
    setChangePasswordDialogVisibility(false);
  };

  const handleBudgetSettingsOpen = (): void => {
    setBudgetSettingsDialogVisibility(true);
  };

  const handleBudgetSettingsClose = (): void => {
    setBudgetSettingsDialogVisibility(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <SlideMenuWrapper
        data-testid={testID}
        position={position}
        left={left}
        direction="right"
        in={open}
        date-testid={testID}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Head data-testid="head">
            <Title>Bem-Vindo</Title>
            <Subtitle>{account.username}</Subtitle>
          </Head>
          <Content>
            <List component="nav">
              <SelectProfile data-testid="select-profile">
                <CurrentProfile data-testid="select-profile-item">
                  <Profile>
                    <ProfileIcon>
                      <ShadowIcon />
                      <Icon src={getActiveProfileLogo()} alt={getActiveProfileName()} />
                    </ProfileIcon>
                    <ClientName>{getActiveProfileName()}</ClientName>
                  </Profile>
                </CurrentProfile>
                {!hasOnlyOneClient() && (
                  <ListClients data-testid="client-list">
                    {fromAccountGroupsToClient(accountGroups)?.map((client) => (
                      <Profile
                        key={client.id}
                        onClick={async (): Promise<void> => {
                          await selectProfile(client.id);
                        }}
                        data-testid="select-profile-item"
                      >
                        <ProfileIcon>
                          <ShadowIcon />
                          <Icon src={client.logoUrl} alt={client.name} />
                        </ProfileIcon>
                        <ClientName>{client.name}</ClientName>
                      </Profile>
                    ))}
                  </ListClients>
                )}
              </SelectProfile>
              <div>
                <ListItem data-testid="edit-profile" onClick={handleEditProfileOpen} button>
                  <ListItemText primary="Editar perfil" />
                </ListItem>
                <EditProfileDialog dialogVisibility={editProfileDialogIsOpen} handleClose={handleEditProfileClose} />

                <ListItem data-testid="changepassword" onClick={handleChangePasswordOpen} button>
                  <ListItemText primary="Alterar senha" />
                </ListItem>
                <ChangePasswordDialog
                  dialogVisibility={changePasswordDialogIsOpen}
                  handleClose={handleChangePasswordClose}
                />
                {isAdmin() && (
                  <>
                    <ListItem data-testid="budget-settings" onClick={handleBudgetSettingsOpen} button>
                      <ListItemText primary="Configurar orçamento" />
                    </ListItem>
                    <BudgetSettingsDialog
                      dialogVisibility={budgetSettingsDialogIsOpen}
                      handleClose={handleBudgetSettingsClose}
                    />
                  </>
                )}
              </div>
              <ListItem data-testid="logout" onClick={(): Promise<void> => logout()} button>
                <ListItemText primary="Sair" />
              </ListItem>
            </List>
          </Content>
        </div>
      </SlideMenuWrapper>
    </ClickAwayListener>
  );
};
