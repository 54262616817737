import React from 'react';
import Conclusion from 'pages/SchedulerList/components/Conclusion/Conclusion';
import { Button } from 'components';
import * as S from './ConclusionCard.styles';
import { IConclusionCardProps } from './ConclusionCard.types';

const ConclusionCard = ({
  conclusion,
  onClick,
  testId = 'conclusion-card-component',
  inspectionAuthenticated = false,
  inspectionStatusIsFinished = false,
}: IConclusionCardProps) => {
  const { comment, conclusionReason, conclusionType, createdBy, updatedAt } = conclusion;
  const showEditButtonIfInspectionIsNotAuthenticated = !inspectionAuthenticated && !inspectionStatusIsFinished;
  return (
    <S.Container data-testid={testId}>
      <Conclusion
        comment={comment}
        conclusionReason={conclusionReason.description}
        conclusionType={conclusionType.description}
        createdByUser={createdBy || 'Usuário não encontrado'}
        dateCreated={updatedAt || 'Data não encontrada'}
        testId="conclusion-component"
      />
      {showEditButtonIfInspectionIsNotAuthenticated && (
        <Button
          onClick={onClick}
          variant="outlined"
          text="Editar Conclusão"
          testID="button-edit-conclusion"
          startIcon={<S.EditIcon />}
        />
      )}
    </S.Container>
  );
};

export default ConclusionCard;
