import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Container,
  Illustration,
  Information,
  Title,
  Description,
  Button,
} from '../../../PasswordRecovery/PasswordRecovery.styles';

export const CheckConfirmationEmail: React.FC = () => {
  const history = useHistory();
  const location = useLocation().search;

  const getEmailFromUrl = (): string | null => {
    const query = new URLSearchParams(location);
    return query.get('email');
  };

  return (
    <Container data-testid="email-confirmation-check">
      <Illustration />
      <Information>
        <Title>Cheque seu email</Title>
        <Description>
          Caso seja válido, você receberá um email em <b>{getEmailFromUrl()}</b> com um link para efetuar a confirmação.
          O link de confirmação expirará dentro de <b>10 minutos</b>.
        </Description>
        <Description>
          Se você não encontrar o email na sua caixa de entrada, verifique a lixeira ou a pasta de spam.
        </Description>

        <Button
          fullWidth={true}
          size="large"
          text="VOLTAR AO LOGIN"
          type="button"
          onClick={(): void => history.push('/login')}
        />
      </Information>
    </Container>
  );
};
