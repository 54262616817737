import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 100px;
`;

export const Content = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;

  @media screen and (max-width: 1400px) {
    margin-bottom: 100px;
  }
`;
