import { createSlice } from '@reduxjs/toolkit';

interface ISchedulerListMenu {
  isOpen: boolean;
}

const initialState: ISchedulerListMenu = {
  isOpen: true,
};

export const schedulerListMenuSlice = createSlice({
  name: 'schedulerListMenu',
  initialState,
  reducers: {
    toggleSchedulerListMenu(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleSchedulerListMenu } = schedulerListMenuSlice.actions;
export default schedulerListMenuSlice.reducer;
