import styled from 'styled-components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export const Description = styled.span`
  color: #6a758b;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16.5px;
`;

interface IIndicatorContentProps {
  isDraft?: boolean;
}

export const IndicatorContent = styled.div<IIndicatorContentProps>`
  display: flex;
  align-items: ${({ isDraft }) => (isDraft ? 'center' : 'end')};
  gap: 2px;
`;

export const CloseIcon = styled(CloseRoundedIcon)`
  width: 20px !important;
  height: 17px !important;
  color: #f44336;
`;

export const CheckIcon = styled(CheckRoundedIcon)`
  width: 20px !important;
  height: 17px !important;
  color: #4caf50;
`;
