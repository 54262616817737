import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { TableRow as MuiTableRow, TableCell } from '@mui/material';

export const Container = styled.div`
  background: #fff;
  width: 100%;
  padding: 20px 0 0px 0px;
  border-radius: 15px;
  border: 0.744px solid #f8f9fa;
  box-shadow: 0px 2.9763px 14.88148px 0px rgba(238, 238, 238, 0.5);
`;

export const Header = styled.div`
  padding: 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
`;

export const ShowDetails = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat';
  color: #000000;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #737791;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 15px 0px;
  padding: 0px 15px;
`;

interface ITableHeaderCell {
  isIndexTop: boolean;
}

export const TableHeaderCell = MuiStyled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isIndexTop',
})<ITableHeaderCell>(({ isIndexTop }) => ({
  padding: '6px 16px',
  color: '#6a758b',
  zIndex: isIndexTop ? 1 : 0,
}));

export const SortingButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: #fff;
  font-size: 14px;
  font-style: normal;
  color: #6a758b;
  font-weight: 500;
  text-align: left;

  &[data-active='true'] {
    color: #000;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TableBodyCell = MuiStyled(TableCell)(() => ({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  padding: '14px 16px',
  border: 0,
}));

export const TableBodyRow = MuiStyled(MuiTableRow)(() => ({
  backgroundColor: '#fff',

  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FBFD',
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TableBodyMessage = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FinishedInspectionPercentageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  div {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .MuiLinearProgress-root {
    height: 6px;
    border-radius: 16px;
    background-color: #ececec;
  }

  .MuiLinearProgress-bar {
    background-color: #00c6c6;
  }
`;
