import React from 'react';
import {
  TemplateContainer,
  HeaderSection,
  RowWithLogo,
  LogoContainer,
  SectionTitle,
  SectionDate,
  SectionItem,
  SectionVersion,
} from './ReportPreview.styles';
import { IBudget } from 'commom/types/Budget.types';
import { IInspection } from 'pages/SchedulerList/components/Inspection';
import DateService from 'services/DateService/dateService';
import VehicleSection from './VehicleSection/VehicleSection';
import WorkshopSection from './WorkshopSection/WorkshopSection';
import InsuranceDetailsSection from './InsuranceDetailsSection/InsuranceDetailsSection';
import BudgetConclusionsSection from './BudgetConclusionsSection/BudgetConclusionsSection';
import PartsAndServicesSection from './PartsAndServicesSection/PartsAndServicesSection';

interface IReportPreviewProps {
  budget: IBudget;
  inspection: IInspection;
  clientLogo: string | undefined;
}

const ReportPreview = ({ budget, inspection, clientLogo }: IReportPreviewProps) => {
  const BudgetVersionAndData = () => {
    return (
      <SectionVersion>
        <SectionTitle>Orçamento {budget.version}</SectionTitle>
        <SectionDate>
          <SectionItem>Data de criação:</SectionItem>
          {DateService.formatDate(budget.createdAt)}
        </SectionDate>
      </SectionVersion>
    );
  };

  return (
    <TemplateContainer>
      <HeaderSection>
        <RowWithLogo>
          <LogoContainer>{clientLogo ? <img src={clientLogo} alt="Logo" width="110" /> : null}</LogoContainer>
          <VehicleSection product={inspection.product} />
          <InsuranceDetailsSection />
        </RowWithLogo>
      </HeaderSection>

      <BudgetVersionAndData />
      <WorkshopSection workshop={budget.workshop} />
      <PartsAndServicesSection />
      <BudgetConclusionsSection conclusions={budget.conclusions} />
    </TemplateContainer>
  );
};

export default ReportPreview;
