import ApiService from 'services/ApiService';
import {
  IGetRegulatorsByCompanyIdResponse,
  IPutRegulatorInInspectionsResponse,
  IRegulatorService,
} from './regulatorService.types';

const RegulatorService: IRegulatorService = {
  getRegulators: async (companiesIds) => {
    const {
      data: { regulators },
    } = await ApiService.get<IGetRegulatorsByCompanyIdResponse>('/accounts/regulators', {
      params: {
        companiesIds: companiesIds.join(','),
      },
    });

    return regulators;
  },

  putRegulatorInInspections: async (accountId, inspectionsIds) => {
    const {
      data: { inspections },
    } = await ApiService.put<IPutRegulatorInInspectionsResponse>(`/inspections/regulator`, {
      accountId,
      inspectionsIds,
    });

    return inspections;
  },
};

export default RegulatorService;
