import React from 'react';
import Workshop from './Workshop/Workshop';
import Vehicle from './Vehicle/Vehicle';
import { Container } from './InitialData.styles';
import InsuranceDataAndVehicle from './InsuranceDataAndVehicle/InsuranceDataAndVehicle';
import LaborValues from 'components/LaborValues/LaborValues';
import { useAppSelector } from 'hooks/hooks';

const InitialData = () => {
  const labor = useAppSelector((state) => state.presentBudget.budget.labor);
  return (
    <>
      <Container>
        <Vehicle />
        <Workshop />
        <InsuranceDataAndVehicle />
        <LaborValues labor={labor} />
      </Container>
    </>
  );
};

export default InitialData;
