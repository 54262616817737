import React, { useState } from 'react';
import { TableBody, TableRow } from '@material-ui/core';
import { Stack } from '@mui/material';
import Loading from 'components/Loading';
import { ITableProps } from './Table.types';
import * as Styles from './Table.styles';

export const Table = ({
  children,
  headers,
  emptyMessage = 'Tabela vazia',
  page,
  rowsPerPage = 7,
  count = 0,
  totalPages,
  setPage,
  removeAlingSticky = false,
  marginRight = '-90px',
  isLoading = false,
}: ITableProps) => {
  const [pageSelected, setPageSelected] = useState<number>(page + 1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageSelected(value);
    setPage(value - 1);
  };

  const isActionsHeader = (index: number): boolean => index === 0;
  const getInitialPageItem = (): number => {
    if (count === 0) {
      return 0;
    }
    return rowsPerPage * page + 1;
  };

  const getFinalPageItem = (): number => {
    if (page + 1 === totalPages) {
      return count;
    }
    return rowsPerPage + page * rowsPerPage;
  };

  const rightAlignTable = removeAlingSticky ? '0' : marginRight;

  return (
    <>
      <Styles.TableContainerActions>
        {isLoading ? (
          <Styles.TableLoadingWrapper>
            <Loading width="15%" />
          </Styles.TableLoadingWrapper>
        ) : (
          <Styles.TableActions rightaligntable={rightAlignTable}>
            <Styles.TableHead>
              <TableRow>
                {headers.map((header, index) => {
                  if (isActionsHeader(index) && !removeAlingSticky) {
                    return (
                      <Styles.TableCellSticky align={header.key === 0 ? 'center' : 'left'} key={header.key}>
                        {header.name}
                      </Styles.TableCellSticky>
                    );
                  }

                  return (
                    <Styles.TableCell align={header.key === 0 ? 'center' : 'left'} key={header.key}>
                      {header.name}
                    </Styles.TableCell>
                  );
                })}
              </TableRow>
            </Styles.TableHead>
            <TableBody>
              {count ? (
                children
              ) : (
                <TableRow>
                  <Styles.EmptyTableCell colSpan={12}>{emptyMessage}</Styles.EmptyTableCell>
                </TableRow>
              )}
            </TableBody>
          </Styles.TableActions>
        )}
      </Styles.TableContainerActions>
      <Styles.PaginationWrapper>
        {rowsPerPage > 0 && (
          <Styles.PaginationDetails data-testid="pagination-details">
            Mostrando{' '}
            <Styles.Bold>
              {getInitialPageItem()} a {getFinalPageItem()}
            </Styles.Bold>{' '}
            de <Styles.Bold>{count} resultados</Styles.Bold>
          </Styles.PaginationDetails>
        )}
        <Styles.PaginationController>
          <Stack spacing={0}>
            <Styles.PaginationTable
              page={pageSelected}
              onChange={handleChange}
              count={totalPages || 0}
              siblingCount={0}
            />
          </Stack>
        </Styles.PaginationController>
      </Styles.PaginationWrapper>
    </>
  );
};
