import { IInspectionFilterService } from './inspectionFilterService.types';

const InspectionFilterService: IInspectionFilterService = {
  buildInspectionDataFilters: () => {
    return {
      companiesId: [] as string[],
      statusIds: [] as string[],
      createdAtGreaterThan: '',
      createdAtLessThan: '',
      isInspectionReport: false,
      inspectionCategoriesId: [] as string[],
    };
  },
  buildInspectionDataFiltersOld: () => {
    return {
      companiesId: [] as string[],
      statusType: '',
      createdAtGreaterThan: '',
      createdAtLessThan: '',
      isInspectionReport: false,
      inspectionCategoriesId: [] as string[],
    };
  },
};

export default InspectionFilterService;
