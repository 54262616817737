import React from 'react';
import { Container } from './TabEmpty.styles';
import { ITabEmptyProps } from './TabEmpty.types';

const TabEmpty = ({ title }: ITabEmptyProps) => {
  return (
    <Container>
      <p>{title}</p>
    </Container>
  );
};

export default TabEmpty;
