import styled from 'styled-components';

export const PartsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px;

  &:first-child {
    background-color: #f9f9f9;
  }
`;

export const PartContainer = styled.div`
  border-radius: 4px;
  min-height: 60px;
`;

export const PartsTableRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2.2fr 0.8fr 1fr 1fr 2fr 0.5fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  padding: 6px 10px;
`;

export const PartTableName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  gap: 4px;
`;

export const PartDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-wrap: wrap;
  padding: 0 10px;
  width: 65%;
  font-family: 'Montserrat', sans-serif;

  strong {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    color: #000;
  }

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    color: #6a758b;
  }
`;

export const ItemTitle = styled.div`
  color: #6a758b;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 600;
`;

interface IItemSubtitleProps {
  isPriceModifed?: boolean;
}

export const ItemSubtitle = styled.div<IItemSubtitleProps>`
  font-size: 10px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  text-decoration: ${({ isPriceModifed }) => (isPriceModifed ? 'line-through' : 'none')};
`;

export const ItemSubtitlePriceModified = styled(ItemSubtitle)`
  color: rgb(14, 134, 202);
`;

export const ItemTypeSubtitle = styled.div`
  border: 0.6px solid #000000;
  padding: 0.5px 2px;
  border-radius: 2px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 600;
`;

export const ItemSubtitleNetPrice = styled(ItemSubtitle)`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

export const ServiceIcon = styled.div`
  display: flex;
  align-items: center;
  width: 12%;
  gap: 5px;
`;

export const ServiceBadge = styled.div`
  padding: 0px 3px;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  font-family: 'Montserrat';
  font-weight: 600;

  &.bg-orange {
    background-color: #f67228;
  }

  &.bg-purple {
    background-color: #3821ae;
  }

  &.bg-red {
    background-color: #b60000;
  }
`;

export const ServiceInfo = styled.div`
  gap: 2px;
  margin-top: 1px;

  color: #000;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
