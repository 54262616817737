import React, { createContext, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AccountService from 'services/ApiService/AccountService';
import { IAccountGroup, IAccountGroups, IActiveProfile, IClient } from 'services/ApiService/AccountService/types';
import { InspectionPlatformEnum } from 'services/InspectionPlatformService/inspectionPlatformService.types';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import { IActiveProfileContext } from './types';
import FirebaseService from 'services/FirebaseService/firebaseService';

export const ActiveProfileContext = createContext<IActiveProfileContext>({} as IActiveProfileContext);

export const ActiveProfileContainer: React.FC = ({ children }) => {
  const [activeProfile, setActiveProfileInContext] = useState<IActiveProfile>({} as IActiveProfile);
  const [accountGroups, setAccountGroups] = useState<IAccountGroups>({} as IAccountGroups);

  useEffect(() => {
    try {
      const accountGroupsLocalStorage = AccountService.getAccountGroupsInLocalStorage();
      const activeProfileLocalStorage = AccountService.getActiveProfileInLocalStorage();

      if (accountGroupsLocalStorage) setAccountGroups(accountGroupsLocalStorage);
      if (activeProfileLocalStorage) setActiveProfileInContext(activeProfileLocalStorage);
    } catch (arror) {
      toast.error('Ocorreu um erro interno ao tentar validar os dados do usuário.');

      AccountService.deleteAccountGroupsFromLocalStorage();
      AccountService.deleteActiveProfileFromLocalStorage();
    }
  }, []);

  const initiateAccountGroups = useCallback(async (): Promise<IAccountGroups> => {
    try {
      const groups = await AccountService.getAccountGroups();
      AccountService.deleteAccountGroupsFromLocalStorage();
      AccountService.setAccountGroupsInLocalStorage(groups);
      setAccountGroups(groups);
      return groups;
    } catch (err) {
      throw new Error('Could not initiate account groups');
    }
  }, []);

  const setActiveProfile = useCallback(
    async (clientId: string): Promise<void> => {
      try {
        await AccountService.setActiveProfile(clientId);

        AccountService.setActiveProfileInLocalStorage(clientId);

        setActiveProfileInContext({ clientId });
      } catch (err) {
        throw new Error('Could not set active profile.');
      }
    },
    [setActiveProfileInContext]
  );

  const hasNoActiveProfile = useCallback(() => {
    const accountGroupsLocalStorage = AccountService.getAccountGroupsInLocalStorage();
    const activeProfileLocalStorage = AccountService.getActiveProfileInLocalStorage();

    return (
      Object.keys(activeProfileLocalStorage).length === 0 && !(Object.keys(accountGroupsLocalStorage).length === 0)
    );
  }, []);

  const getGroupForActiveProfile = useCallback((): IAccountGroup | undefined => {
    return accountGroups?.groups?.find((accountGroup) => accountGroup.client.id === activeProfile.clientId);
  }, [accountGroups, activeProfile]);

  const getAllClientsFromAccountGroups = useCallback((): IClient[] => {
    return accountGroups?.groups?.map(({ client }) => client) || ([] as IClient[]);
  }, [accountGroups]);

  const getAllClientsIdsFromAccountGroups = useCallback((): string[] => {
    return accountGroups?.groups?.map(({ client }) => client?.id) || ([] as string[]);
  }, [accountGroups]);

  const getCompaniesCAPForActiveProfile = useCallback((): ICompany[] => {
    const group = getGroupForActiveProfile();

    return (
      group?.companies.filter((company) =>
        company.inspectionPlatforms.some((platform) => platform.code === InspectionPlatformEnum.CAP)
      ) || ([] as ICompany[])
    );
  }, [getGroupForActiveProfile]);

  const getCompaniesWithoutCAPForActiveProfile = useCallback((): ICompany[] => {
    const group = getGroupForActiveProfile();

    return (
      group?.companies.filter((company) =>
        company.inspectionPlatforms.some((platform) => platform.code !== InspectionPlatformEnum.CAP)
      ) || ([] as ICompany[])
    );
  }, [getGroupForActiveProfile]);

  const getCompanyByIdFromActiveProfile = useCallback(
    (companyId: string): ICompany | undefined => {
      const group = getGroupForActiveProfile();

      return group?.companies.find((company) => company.id === companyId);
    },
    [getGroupForActiveProfile]
  );

  const getAllCompaniesForActiveProfile = useCallback((): ICompany[] => {
    const group = getGroupForActiveProfile();

    return group?.companies || ([] as ICompany[]);
  }, [getGroupForActiveProfile]);

  const getAllCompaniesIdsForActiveProfile = useCallback((): string[] => {
    const group = getGroupForActiveProfile();

    return group?.companiesIds || ([] as string[]);
  }, [getGroupForActiveProfile]);

  const removeCAPCompaniesId = useCallback((companiesIds: string[], companiesCAP: ICompany[]): string[] => {
    return companiesIds.filter((id) => !companiesCAP.some((company) => id === company.id));
  }, []);

  const getCompaniesIdsWithoutCAPForActiveProfile = useCallback((): string[] => {
    const companiesIds = getAllCompaniesIdsForActiveProfile();

    const companiesCAP = getCompaniesCAPForActiveProfile();

    const companiesIdsWithoutCAP = removeCAPCompaniesId(companiesIds, companiesCAP);

    return companiesIdsWithoutCAP;
  }, [getAllCompaniesIdsForActiveProfile, getCompaniesCAPForActiveProfile, removeCAPCompaniesId]);

  const hasCAPCompany = useCallback(
    (): boolean =>
      !!getGroupForActiveProfile()?.companies.find((company) =>
        company.inspectionPlatforms.some((platform) => platform.code === InspectionPlatformEnum.CAP)
      ),
    [getGroupForActiveProfile]
  );

  const getCurrentClient = useCallback((): IClient | undefined => {
    return accountGroups.groups?.find((group) => group.client.id === activeProfile.clientId)?.client;
  }, [accountGroups.groups, activeProfile.clientId]);

  const getFCMTokenForAccountActive = useCallback(async (): Promise<void> => {
    try {
      const token = await FirebaseService.getTokenInFirebase();
      await AccountService.sendNotificationToken(token);
    } catch (error) {
      console.error('Error getting FCM token', error);
    }
  }, []);

  return (
    <ActiveProfileContext.Provider
      value={{
        accountGroups,
        activeProfile,
        initiateAccountGroups,
        setActiveProfile,
        getCompaniesWithoutCAPForActiveProfile,
        getCompaniesIdsWithoutCAPForActiveProfile,
        getAllClientsIdsFromAccountGroups,
        getAllClientsFromAccountGroups,
        hasNoActiveProfile,
        hasCAPCompany,
        getCompaniesCAPForActiveProfile,
        getAllCompaniesForActiveProfile,
        getAllCompaniesIdsForActiveProfile,
        getCompanyByIdFromActiveProfile,
        getCurrentClient,
        getFCMTokenForAccountActive,
      }}
    >
      {children}
    </ActiveProfileContext.Provider>
  );
};
