import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#6A758B',
        paddingBottom: '5px',
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
      },
    },
  },
});
