export interface IDrawerBaseProps {
  children: React.ReactNode | string;
  testId?: string;
  className?: string;
}

export interface IDrawerProps extends IDrawerBaseProps {
  openDrawer: boolean;
  anchor: AnchorTypesEnum;
  onClose(): void;
}

export enum AnchorTypesEnum {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}
