import styled from 'styled-components';
import MenuComponent from '@mui/material/Menu';
import MenuItemComponent from '@mui/material/MenuItem';
import ShareIcon from '@mui/icons-material/IosShareRounded';
import { Button } from 'components';

export const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 20px 30px;
  border-bottom: 1px solid #e8e8e8;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const BackButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;

  height: 40px;
  border-radius: 4px;
  background: white;
  border: none;
  cursor: pointer;

  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Title = styled.h1`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0;
`;

export const MenuButton = styled(Button)`
  width: auto;
  background: ${({ disabled }) => !disabled && '#1ca6af'} !important;
`;

export const Menu = styled(MenuComponent)`
  .MuiMenu-paper {
    width: 257px;
    display: flex !important;
    flex-direction: column !important;
    gap: 5px !important;
  }
`;

export const MenuItem = styled(MenuItemComponent)`
  display: flex !important;
  justify-content: left !important;
  gap: 6px !important;
  color: #6a758b !important;

  :hover {
    svg {
      color: #1ca6af !important;
    }
  }
`;

export const SendAnalyst = styled(ShareIcon)`
  transform: rotate(90deg);
`;

export const BackRegulator = styled(ShareIcon)`
  transform: rotate(-90deg);
`;
