import React from 'react';
import { Card, CaptionContainer, CaptionContent, CaptionInfo, CaptionSymbol } from './RescueCaption.styles';

const RescuedCaption = () => {
  const captions = [
    { symbol: 'B', info: 'BOM' },
    { symbol: 'R', info: 'REGULAR' },
    { symbol: 'P', info: 'PÉSSIMO' },
    { symbol: 'A', info: 'AVARIADO' },
    { symbol: 'N', info: 'NÃO HÁ' },
    { symbol: 'F', info: 'FALTANTE' },
  ];

  return (
    <Card>
      <CaptionContainer>
        {captions.map(({ symbol, info }) => (
          <CaptionContent key={symbol}>
            <CaptionSymbol>{symbol}</CaptionSymbol>
            <CaptionInfo>-</CaptionInfo>
            <CaptionInfo>{info}</CaptionInfo>
          </CaptionContent>
        ))}
      </CaptionContainer>
    </Card>
  );
};

export default RescuedCaption;
