import { IPhoto } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { IGeolocationPhoto, IGeolocationService } from './geolocation.types';

const GeolocationService: IGeolocationService = {
  getInitialCoordinatesOfGeolocationPhotos: (geolocationPhotos: IGeolocationPhoto[]) => {
    const geolocationPhotoResponse = geolocationPhotos.find(
      (geolocationPhoto) => geolocationPhoto.latitude && geolocationPhoto.longitude
    );

    return {
      latitude: geolocationPhotoResponse?.latitude ?? 0,
      longitude: geolocationPhotoResponse?.longitude ?? 0,
    };
  },

  existCoordinatesInPhotos: (photos: IPhoto[]) => {
    return photos.some((photo) => photo.latitude && photo.longitude);
  },

  getGeolocationPhotos: (photos: IPhoto[]) => {
    return photos.reduce((geolocationPhotos: IGeolocationPhoto[], photo) => {
      const geolocationPhoto = geolocationPhotos.find(
        (locationPhoto) => locationPhoto.latitude === photo.latitude && locationPhoto.longitude === photo.longitude
      );

      if (!geolocationPhoto) {
        geolocationPhotos.push({ latitude: photo.latitude, longitude: photo.longitude, photos: [photo] });
      } else {
        geolocationPhoto.photos.push(photo);
      }

      return geolocationPhotos;
    }, []);
  },
};

export default GeolocationService;
