import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { INotificationsContext } from './Notifications.types';
import { useAuthState } from 'contexts/auth/useAuth';

export const NotificationsContext = createContext<INotificationsContext>({} as INotificationsContext);

export const NotificationsProvider: React.FC = ({ children }) => {
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
  const { account } = useAuthState();
  const storageKey = useMemo(() => `@NEW_NOTIFICATIONS_${account.id}`, [account.id]);

  useEffect(() => {
    const checkNewNotifications = localStorage.getItem(storageKey) === 'true';
    setHasNewNotifications(checkNewNotifications);
  }, [storageKey]);

  const updateHasNewNotifications = useCallback(
    (value: boolean) => {
      localStorage.setItem(storageKey, `${value}`);
      setHasNewNotifications(value);
    },
    [storageKey]
  );

  const contextValue = useMemo(
    () => ({
      hasNewNotifications,
      updateHasNewNotifications,
    }),
    [hasNewNotifications, updateHasNewNotifications]
  );

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};
