import React, { useCallback, useEffect, useRef, useState } from 'react';
import InspectionTableService from 'services/InspectionTableService/InspectionTableService';
import { Button, Table } from 'components';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import { CanceledInspectionEnum } from 'services/ApiService/MetricsService/metricsService.types';
import { toast } from 'react-toastify';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import { IMotive } from 'pages/ManagementDashboard/components/CancellationMotiveDialog/CancellationMotiveDialog.types';
import CancellationMotiveService from 'services/ApiService/CancellationMotiveService/CancellationMotiveService';
import Loading from 'components/Loading';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateService from 'services/DateService/dateService';
import { CSVLink } from 'react-csv';
import * as Styled from './InspectionsCanceledDetails.styles';
import { MetricsTableRow } from '../../TableRow/TableRow';
import { MetricsFilter } from '../../MetricsFilter/MetricsFilter';
import { inspectionStatusCanceled } from '../Charts.types';
import { IMetricsTableRow } from '../../TableRow/TableRow.types';

export const InspectionsCanceledDetails = () => {
  const {
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    startDateSelected,
    productTypesSelected,
    regulatorSelected,
  } = useMetricsFilter();

  useHideZendesk();

  const [page, setPage] = useState<number>(0);
  const [tableData, setTableData] = useState<IMetricsTableRow[]>([]);
  const [dataCSV, setDataCSV] = useState<IMetricsTableRow[]>([]);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [CSVloading, setCSVLoading] = useState<boolean>(false);
  const [filterMotive, setFilterMotive] = useState<CanceledInspectionEnum>(
    CanceledInspectionEnum.CANCELED_BY_INSURANCE
  );
  const [cancellationMotives, setCancellationMotives] = useState<IMotive[]>([]);
  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const dataTableIsEmpty = tableData.length === 0;
  const rowsPerPage = 7;

  const getCanceledMotives = async () => {
    try {
      const canceledMotives = await CancellationMotiveService.getMotives();
      setCancellationMotives(canceledMotives);
    } catch (error) {
      toast.error('Falha ao carregar os motivos de cancelamento');
    }
  };

  const getCanceledMotiveIdSelected = useCallback(() => {
    return cancellationMotives.find((motive) => motive.code === filterMotive)?.id;
  }, [cancellationMotives, filterMotive]);

  const formatCSVFileName = () => {
    const date = DateService.formatDate(new Date().toISOString());
    return `tabela_atendimentos_cancelados_${date}`;
  };

  const getExpiredInspections = useCallback(
    (isDownloadCSV: boolean) => {
      return MetricsService.getExpiredInspectionsChartDetails({
        companyIds: companiesSelected || [],
        createdAtGreaterThan: startDateSelected || '',
        createdAtLessThan: endDateSelected || '',
        inspectionCategoryIds: categoriesSelected || [],
        inspectionProductTypeIds: productTypesSelected || [],
        regulatorIds: [regulatorSelected?.value || ''],
        page: isDownloadCSV ? '' : String(page),
        size: isDownloadCSV ? '' : String(rowsPerPage),
      });
    },
    [
      categoriesSelected,
      companiesSelected,
      endDateSelected,
      page,
      productTypesSelected,
      regulatorSelected,
      rowsPerPage,
      startDateSelected,
    ]
  );

  const getCanceledInspections = useCallback(
    (isDownloadCSV: boolean, canceledMotiveId: string) => {
      return MetricsService.getCanceledInspectionsChartDetails({
        companyIds: companiesSelected || [],
        createdAtGreaterThan: startDateSelected || '',
        createdAtLessThan: endDateSelected || '',
        inspectionCategoryIds: categoriesSelected || [],
        inspectionProductTypeIds: productTypesSelected || [],
        regulatorIds: [regulatorSelected?.value || ''],
        page: isDownloadCSV ? '' : String(page),
        size: isDownloadCSV ? '' : String(rowsPerPage),
        cancellationMotiveIds: [canceledMotiveId],
      });
    },
    [
      categoriesSelected,
      companiesSelected,
      endDateSelected,
      page,
      productTypesSelected,
      regulatorSelected,
      rowsPerPage,
      startDateSelected,
    ]
  );

  const handleExpiredInspections = useCallback(async () => {
    setTableLoading(true);

    try {
      const isDownloadCSV = false;
      const response = await getExpiredInspections(isDownloadCSV);
      const dataInspectionsExpiredTable = InspectionTableService.getInspectionsChartsTableRows(response.inspections);

      setTotalPages(response.totalPages);
      setTotalCount(response.totalCount);
      setTableData(dataInspectionsExpiredTable);
    } catch {
      setTableData([]);
      toast.error('Falha ao carregar detalhes dos atendimentos expirados');
    }

    setTableLoading(false);
  }, [getExpiredInspections]);

  const handleCanceledInspections = useCallback(
    async (canceledMotiveIdSelected: string) => {
      setTableLoading(true);

      try {
        const isDownloadCSV = false;
        const response = await getCanceledInspections(isDownloadCSV, canceledMotiveIdSelected);
        const dataInspectionsCancelledTable = InspectionTableService.getInspectionsChartsTableRows(
          response.inspections
        );

        setTotalPages(response.totalPages);
        setTotalCount(response.totalCount);
        setTableData(dataInspectionsCancelledTable);
      } catch {
        setTableData([]);
        toast.error('Falha ao carregar detalhes dos atendimentos');
      }

      setTableLoading(false);
    },
    [getCanceledInspections]
  );

  const getTableDataCSV = async () => {
    setCSVLoading(true);

    try {
      const downloadCSV = true;

      const canceledMotiveIdSelected = getCanceledMotiveIdSelected();
      if (canceledMotiveIdSelected) {
        const response = await getCanceledInspections(downloadCSV, canceledMotiveIdSelected);
        setDataCSV(InspectionTableService.getInspectionsChartsTableRows(response.inspections));
      } else if (CanceledInspectionEnum.INSPECTION_EXPIRED === filterMotive) {
        const response = await getExpiredInspections(downloadCSV);
        setDataCSV(InspectionTableService.getInspectionsChartsTableRows(response.inspections));
      }
    } catch {
      toast.error('Erro ao realizar o download do CSV');
    }

    setCSVLoading(false);
  };

  const setFilterData = (filterSelected: CanceledInspectionEnum) => {
    setFilterMotive(filterSelected);
    setPage(0);
  };

  useEffect(() => {
    getCanceledMotives();
  }, []);

  useEffect(() => {
    const canceledMotiveIdSelected = getCanceledMotiveIdSelected();

    if (canceledMotiveIdSelected) {
      handleCanceledInspections(canceledMotiveIdSelected);
    } else if (CanceledInspectionEnum.INSPECTION_EXPIRED === filterMotive) {
      handleExpiredInspections();
    }
  }, [getCanceledMotiveIdSelected, handleCanceledInspections, handleExpiredInspections, filterMotive]);

  useEffect(() => {
    if (dataCSV && csvInstance && csvInstance.current) {
      setTimeout(() => {
        csvInstance?.current?.link.click();

        setDataCSV([]);
      });
    }
  }, [dataCSV]);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>
          <Styled.Link>Atendimentos Cancelados</Styled.Link>
          <Styled.ArrowForwardIcon />
          Detalhamento
        </Styled.Title>
        <Styled.BackButton to="/metrics">
          <Styled.ArrowBackIcon />
          Voltar
        </Styled.BackButton>
      </Styled.Header>
      <Styled.Content>
        <MetricsFilter style={{ padding: 0 }} />

        <Styled.FilterButtonsWrapper>
          {cancellationMotives?.map((motive) => (
            <Styled.FilterButton
              key={motive.id}
              text={inspectionStatusCanceled[motive.code].label}
              variant="outlined"
              textColor={inspectionStatusCanceled[motive.code].color}
              background={inspectionStatusCanceled[motive.code].background}
              onClick={() => setFilterData(motive.code)}
              isSelected={filterMotive === motive.code}
            />
          ))}
        </Styled.FilterButtonsWrapper>
        <Styled.LowerButtons>
          {cancellationMotives?.length > 0 && (
            <Styled.FilterButton
              text={inspectionStatusCanceled.INSPECTION_EXPIRED.label}
              variant="outlined"
              textColor={inspectionStatusCanceled.INSPECTION_EXPIRED.color}
              background={inspectionStatusCanceled.INSPECTION_EXPIRED.background}
              onClick={() => setFilterData(CanceledInspectionEnum.INSPECTION_EXPIRED)}
              isSelected={filterMotive === CanceledInspectionEnum.INSPECTION_EXPIRED}
            />
          )}

          <Button
            variant="outlined"
            type="button"
            text="BAIXAR CSV"
            disabled={dataTableIsEmpty}
            startIcon={<GetAppIcon />}
            loading={CSVloading}
            loadingColor="black"
            onClick={() => getTableDataCSV()}
          />

          {dataCSV && dataCSV.length > 0 ? (
            <Styled.ButtonCSV
              data={dataCSV}
              filename={formatCSVFileName()}
              headers={InspectionTableService.getInspectionCancelledChartCSVHeaders()}
              target="_blank"
              enclosingCharacter=""
              ref={csvInstance}
            />
          ) : undefined}
        </Styled.LowerButtons>

        {tableLoading ? (
          <Styled.LoadingContainer>
            <Loading width="100%" />
          </Styled.LoadingContainer>
        ) : (
          <Table
            headers={InspectionTableService.getInspectionChartTableHeaders(false)}
            page={dataTableIsEmpty ? 0 : page}
            count={dataTableIsEmpty ? 0 : totalCount}
            setPage={setPage}
            rowsPerPage={dataTableIsEmpty ? 0 : rowsPerPage}
            emptyMessage="Nenhum resultado encontrado"
            totalPages={dataTableIsEmpty ? 0 : totalPages}
            removeAlingSticky={true}
          >
            {tableData.map((row, index) => (
              <MetricsTableRow key={index} row={row} />
            ))}
          </Table>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
