export interface IFeatureToggles {
  [key: string]: boolean;
}

export interface IFeatureTogglesService {
  getToggles: () => Promise<IFeatureToggles>;
  setFeatureTogglesInLocalStorage: (feature: IFeatureToggles) => void;
  getFeatureTogglesInLocalStorage: () => IFeatureToggles;
  deleteFeatureTogglesInLocalStorage: () => void;
}

export enum FeatureTogglesEnum {
  KITE_DETAILS_V2 = 'KITE_DETAILS_V2',
  SWAPP_PHOTO = 'SWAPP_PHOTO',
  SHOW_BUDGET_FEATURES = 'SHOW_BUDGET_FEATURES',
}
