import DataBoxRoot from './cells/Root';
import DataBoxHeader from './cells/Header';
import DataBoxTitle from './cells/Title';
import DataBoxActions from './cells/Actions';
import DataBoxContent from './cells/Content';
import DataBoxItem from './cells/Item';
import DataBoxLabel from './cells/Label';
import DataBoxValue from './cells/Value';

export const DataBox = {
  Root: DataBoxRoot,
  Header: DataBoxHeader,
  Title: DataBoxTitle,
  Actions: DataBoxActions,
  Content: DataBoxContent,
  Item: DataBoxItem,
  Label: DataBoxLabel,
  Value: DataBoxValue,
};
