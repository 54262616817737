import styled from 'styled-components';
import { Drawer as DrawerMaterial } from '@mui/material';

export const Drawer = styled(DrawerMaterial)`
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    width: 380px;
    display: flex;
    padding: 20px;
    flex: 1;
  }
  z-index: 1300 !important;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

export const HeaderSubtitle = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;
