import { EnvironmentType, ProcessEnvType } from './environment.types';

const environments = {
  local: {
    API_URL: 'http://localhost:8080',
    APP_URL: 'http://localhost:3000',
    CUBE_URL: 'https://h4ncw9jb06.execute-api.us-west-2.amazonaws.com/dev',
    WEBAPP_URL: 'http://localhost:3030/?inspectionId=',
    PROJECT_NAME: 'Painel de Controle',
    TYPE: '[LOCAL] ',
  },
  dev: {
    API_URL: 'https://square-development.api-abletech.info',
    APP_URL: 'https://kite-development.api-abletech.info',
    WEBAPP_URL: 'https://tesseract-development.api-abletech.info/?inspectionId=',
    CUBE_URL: 'https://h4ncw9jb06.execute-api.us-west-2.amazonaws.com/dev',
    PROJECT_NAME: 'Painel de Controle',
    TYPE: '[DEV] ',
  },
  hml: {
    API_URL: 'https://square-homologation.api-abletech.info',
    APP_URL: 'https://kite-homologation.api-abletech.info',
    WEBAPP_URL: 'https://tesseract-homologation.api-abletech.info/?inspectionId=',
    CUBE_URL: 'https://5pnlqn1pg1.execute-api.us-west-2.amazonaws.com/hml',
    PROJECT_NAME: 'Painel de Controle',
    TYPE: '[HML] ',
  },
  prd: {
    API_URL: 'https://api.inspections-management.com',
    APP_URL: 'https://app.inspections-management.com',
    WEBAPP_URL: 'https://webapp.inspections-management.com/?inspectionId=',
    CUBE_URL: 'https://2auox3q9f2.execute-api.us-west-2.amazonaws.com/prd',
    PROJECT_NAME: 'Painel de Controle',
    TYPE: '',
  },
};

const ENV: ProcessEnvType = process.env.BUILD_ENV ? process.env.BUILD_ENV : process.env.REACT_APP_BUILD_ENV;

const getEnvironments = (): EnvironmentType => {
  switch (ENV) {
    case 'dev':
      return 'dev';
    case 'hml':
      return 'hml';
    case 'prd':
      return 'prd';
    default:
      return 'local';
  }
};

export default environments[getEnvironments()];
