import { IBudgetItemOutsourcedService } from 'commom/types/Budget.types';
import React from 'react';
import { capitalizeFirstLetter } from 'utils/formatString';
import { Wrapper, Table, Row, ServiceName, ServiceDetails, Title, Subtitle } from './BudgetServicesSection.styles';
import { priceFormatter } from 'utils/formatPrice';

interface IBudgetOutsourcedServicesProps {
  outsourcedServices: IBudgetItemOutsourcedService[];
}

const BudgetOutsourcedServices = ({ outsourcedServices }: IBudgetOutsourcedServicesProps) => {
  if (outsourcedServices.length <= 0) {
    return <div>Sem serviços adicionados</div>;
  }

  return (
    <Wrapper>
      <Table>
        {outsourcedServices.map((service) => (
          <Row key={service.id}>
            <ServiceName>
              <strong>{capitalizeFirstLetter(service.name)}</strong>
            </ServiceName>
            <ServiceDetails>
              <Title>Valor Total</Title>
              <Subtitle>{priceFormatter.format(service.price)}</Subtitle>
            </ServiceDetails>
          </Row>
        ))}
      </Table>
    </Wrapper>
  );
};

export default BudgetOutsourcedServices;
