import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { IDonutChartProps } from './DonutChart.types';
import { TextDataEmpty } from './DonutChart.styles';

export function DonutChart({
  series,
  width,
  height,
  labels,
  colors,
  thicknessPie = '60%',
  offsetXPie = 0,
  offsetXLegend = 0,
  offsetYLegend = 0,
  testId = 'donut-chart',
  textDataEmpty = 'Não há atendimentos para exibir',
}: IDonutChartProps) {
  const options: ApexOptions = {
    chart: {
      fontFamily: 'Montserrat, sans-serif',
      animations: {
        enabled: true,
      },
    },
    colors,
    labels,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      fontSize: '13px',
      offsetX: offsetXLegend,
      offsetY: offsetYLegend,
      labels: {
        colors: '#505050',
        useSeriesColors: false,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 5,
      },
      markers: {
        offsetY: 1,
        offsetX: -10,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        offsetX: offsetXPie,
        donut: {
          size: thicknessPie,
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '24px',
              fontWeight: 600,
              color: '#505050',
              offsetY: -1,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: '16px',
              fontWeight: 600,
              color: '#505050',
            },
          },
        },
      },
    },
  };

  return (
    <>
      {series?.length > 0 ? (
        <div data-testid="react-chart">
          <ReactApexChart
            data-testid={testId}
            options={options}
            series={series}
            type="donut"
            width={width}
            height={height}
          />
        </div>
      ) : (
        <TextDataEmpty data-testid="label-empty">
          <span>{textDataEmpty}</span>
        </TextDataEmpty>
      )}
    </>
  );
}

export default DonutChart;
