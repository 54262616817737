import ApiService from 'services/ApiService';
import { ICompanyService, IGetCompany } from './companyService.types';

const CompanyService: ICompanyService = {
  getCompany: async (id: string) => {
    const {
      data: { company },
    } = await ApiService.get<IGetCompany>(`/companies/${id}`);

    return company;
  },

  isFeatureVisibleByActiveConsumerCompany: (companiesByActiveConsumer) => {
    const companyConsumer = CompanyService.getCompanyByActiveConsumer(companiesByActiveConsumer);
    return companyConsumer !== undefined && companyConsumer.length > 0;
  },

  getCompanyByActiveConsumer: (companiesByActiveConsumer) => {
    const { companies, featureName, isConsumerActive } = companiesByActiveConsumer;
    return companies.filter((company) => {
      return isConsumerActive(featureName, company.id);
    });
  },

  filterInspectionsByActiveConsumerCompany: (inspectionsFiltered) => {
    const { inspections, inspectionsSelected, companies, featureName, isConsumerActive } = inspectionsFiltered;
    const companyActiveConsumer = CompanyService.getCompanyByActiveConsumer({
      companies,
      featureName,
      isConsumerActive,
    });

    const selectFilterInspections = inspections.filter((inspection) => {
      return inspectionsSelected.some((selected) => {
        return (
          selected.id === inspection.id && companyActiveConsumer.some((company) => company.id === selected.companyId)
        );
      });
    });

    return {
      ids: selectFilterInspections.map((inspection) => inspection.id),
      companyId: companyActiveConsumer.map((company) => company.id)[0],
    };
  },
};

export default CompanyService;
