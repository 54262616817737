import React from 'react';
import { Skeleton } from '@mui/material';
import {
  Card,
  SummaryContainer,
  SummaryScore,
  SummaryContent,
  SummaryInfo,
  SummaryClassification,
} from './DamageSummarySkeleton.styles';

const DamageSummarySkeleton = () => {
  return (
    <Card data-testid="damage-summary-skeleton">
      <SummaryContainer>
        <SummaryScore>
          <SummaryContent>
            <SummaryInfo>
              <Skeleton variant="text" width={280} height={20} />
              <Skeleton variant="text" width={180} height={20} />
            </SummaryInfo>
          </SummaryContent>
          <Skeleton variant="rectangular" width="100%" height={1} />
          <SummaryContent>
            <SummaryInfo>
              <Skeleton variant="text" width={280} height={20} />
              <Skeleton variant="text" width={180} height={20} />
            </SummaryInfo>
          </SummaryContent>
        </SummaryScore>
        <SummaryClassification>
          <SummaryContent>
            <Skeleton variant="text" width={600} height={20} />
            <Skeleton variant="rectangular" width={150} height={40} />
          </SummaryContent>
        </SummaryClassification>
      </SummaryContainer>
    </Card>
  );
};

export default DamageSummarySkeleton;
