import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import 'moment/locale/pt-br';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Label } from 'components/Label';
import { Button } from 'components/Button';
import { Moment } from 'moment';
import { ISelectDateRangeProps, FocusedInputType } from './DateRange.types';
import { DateRangeWrapper, MonthNavPrev, MonthNavNext } from './DateRange.styles';

export const DateRange: React.FC<ISelectDateRangeProps> = ({
  testID,
  startDate,
  endDate,
  label,
  buttonShape,
  className,
  onDatesChange,
}) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputType>(null);

  const onFocusChange = (focused: FocusedInputType): void => {
    setFocusedInput(focused);
  };

  const getFormarttedDate = (date: Moment | null): string | undefined => {
    return date?.format('DD/MM/YYYY');
  };

  return (
    <DateRangeWrapper data-testid={testID}>
      {!buttonShape && label && <Label>{label}</Label>}
      {!buttonShape && (
        <span className={className}>
          <DateRangePicker
            startDate={startDate}
            startDateId="start-date-id"
            daySize={30}
            endDate={endDate}
            endDateId="end-date-id"
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            startDatePlaceholderText="Data inicial"
            endDatePlaceholderText="Data final"
            isOutsideRange={(): boolean => false}
            weekDayFormat="ddd"
            hideKeyboardShortcutsPanel={true}
            showClearDates={true}
            readOnly={true}
            navPrev={
              <MonthNavPrev>
                <ArrowBackIosIcon />
              </MonthNavPrev>
            }
            navNext={
              <MonthNavNext>
                <ArrowForwardIosIcon />
              </MonthNavNext>
            }
          />
        </span>
      )}
      {buttonShape && label && (
        <Button
          text={
            <>
              {label}:{' '}
              <b>
                {getFormarttedDate(startDate)} → {getFormarttedDate(endDate)}
              </b>
            </>
          }
          type="button"
          size="large"
          className="animate fadeIn"
        />
      )}
    </DateRangeWrapper>
  );
};
