import styled from 'styled-components';

export const Container = styled.div`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px;
  margin-left: 60px;

  @media only screen and (max-width: 599px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #6a758b;
  margin-bottom: 25px;
`;
