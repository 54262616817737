import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 11px;

  @media (max-width: 1270px) {
    flex-direction: column;
  }
`;

export const ContainerCard = styled.div`
  background: #fff;
  width: 100%;
  height: 260px;
  padding: 16px 0 10px 30px;
  border-radius: 15px;
  border: 0.744px solid #f8f9fa;
  box-shadow: 0px 2.9763px 14.88148px 0px rgba(238, 238, 238, 0.5);
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 28px;
  height: 60px;
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-top: 4px;
  color: #000000;
  margin: 0 0 20px 5px;
  display: block;
  width: fit-content;
`;

export const ShowDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat';
  color: #000000;
  height: 29px;

  &:hover {
    cursor: pointer;
    color: #737791;
  }
`;

export const ContainerChart = styled.div`
  display: flex;
`;

export const ContainerPercentages = styled.div`
  p {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 0;
  }

  &.finished {
    margin-top: 53px;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
`;
