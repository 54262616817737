import React from 'react';
import { Switch } from 'react-router-dom';
import { ModuleEnum } from 'utils/ModulesEnum';
import { GeneralMetricsDetails } from 'pages/Metrics/components/GeneralMetrics/Details';
import { InspectionsCanceledDetails } from 'pages/Metrics/components/Charts/InspectionsCanceledDetails';
import { RegulatorsGeneralDetails } from 'pages/Metrics/components/Regulators/RegulatorsGeneralDetails/RegulatorsGeneralDetails';
import { InspectionsFinishedDetails } from 'pages/Metrics/components/Charts/InspectionsFinishedDetails';
import {
  SchedulerForm,
  SchedulerList,
  Report,
  NotFound,
  Login,
  PreviousInspection,
  ManagementDashboard,
  SelectProfile,
  PasswordRecovery,
  CheckEmail,
  PasswordRecovered,
  NewPassword,
  EmailConfirmation,
  CheckConfirmationEmail,
  EmailConfirmed,
  UsersManagementDashboard,
  Invoices,
  Metrics,
} from '../pages';
import Route from './CustomRoute';

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={SchedulerForm} isPrivate />;
      <Route path="/select-profile" component={SelectProfile} isPrivate />
      <Route path="/agendamentos" component={SchedulerList} isPrivate />;
      <Route
        path="/management-dashboard"
        component={ManagementDashboard}
        isPrivate
        isAdminRoute
        isInsuranceCompanyRoute
      />
      <Route path="/users-dashboard" component={UsersManagementDashboard} isPrivate isAdminRoute />
      <Route path="/invoices" component={Invoices} isPrivate />
      <Route
        path="/metrics"
        component={Metrics}
        isPrivate
        isAdminRoute
        module={ModuleEnum.INSPECTION_MANAGEMENT}
        isInsuranceCompanyRoute
      />
      <Route
        path="/metrics-details"
        component={GeneralMetricsDetails}
        isPrivate
        isAdminRoute
        isInsuranceCompanyRoute
        module={ModuleEnum.INSPECTION_MANAGEMENT}
      />
      <Route
        path="/metrics-chart-cancelled-details"
        component={InspectionsCanceledDetails}
        isPrivate
        isAdminRoute
        isInsuranceCompanyRoute
        module={ModuleEnum.INSPECTION_MANAGEMENT}
      />
      <Route
        path="/metrics-chart-finished-details"
        component={InspectionsFinishedDetails}
        isPrivate
        isAdminRoute
        isInsuranceCompanyRoute
        module={ModuleEnum.INSPECTION_MANAGEMENT}
      />
      <Route
        path="/metrics-regulators-general-details"
        component={RegulatorsGeneralDetails}
        isPrivate
        isAdminRoute
        isInsuranceCompanyRoute
        module={ModuleEnum.INSPECTION_MANAGEMENT}
      />
      <Route path="/previous-inspection" component={PreviousInspection} isPrivate />
      <Route path="/login" component={Login} />
      <Route path="/password-recovery" component={PasswordRecovery} />
      <Route path="/check-email" component={CheckEmail} />
      <Route path="/new-password" component={NewPassword} isTokenValidationPagePrivate />
      <Route path="/password-recovered" component={PasswordRecovered} isTokenValidationPagePrivate />
      <Route path="/email-confirmation" component={EmailConfirmation} isPrivate />
      <Route path="/check-confirmation-email" component={CheckConfirmationEmail} />
      <Route path="/email-confirmed" component={EmailConfirmed} isTokenValidationPagePrivate />
      <Route path="/vistoria/:token" component={Report} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default AppRouter;
