import React, { Ref, useState, useEffect, useCallback } from 'react';
import { createSession, OTSession, OTSubscriberRef, preloadScript, SessionHelper } from 'opentok-react';
import { Error, SessionEventHandlers, Signal } from 'opentok-react/types/opentok';
import CameraIcon from '@material-ui/icons/CameraAlt';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { useFilterInspectionById, useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import TransmissionService from 'services/ApiService/TransmissionService/transmissionService';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { toast } from 'react-toastify';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import WaitTransmission from 'assets/svg/wait-transmission.svg';
import NoTransmission from 'assets/svg/no-transmission.svg';
import { useAuthState } from 'contexts/auth/useAuth';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';
import { getInspectionIdFromPathname } from 'utils/inspectionHandler';
import { useHistory, useLocation } from 'react-router-dom';
import { BackButton } from 'components/BackButton';
import DateService from 'services/DateService/dateService';
import { Publisher, Subscriber, TransmissionPhotos, TransmissionComments } from './components';
import { IPhoto } from '../Inspection';
import * as S from './Transmission.styles';

const OPENTOK_API_KEY = process.env.REACT_APP_OPENTOK_API_KEY;

const Transmission = () => {
  const [isTransmissionOn, setIsTransmissionOn] = useState(false);
  const [error, setError] = useState(false);
  const [transmissionExpired, setTransmissionExpired] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [sessionHelper, setSessionHelper] = useState<SessionHelper>();
  const [finishingTransmission, setFinishingTransmission] = useState(false);
  const [transmissionComments, setTransmissionComments] = useState<string[]>([]);
  const [capturedPhotos, setCapturedPhotos] = useState<IPhoto[]>([]);
  const [showFinishTransmissionDialog, setShowFinishTransmissionDialog] = useState(false);
  const [linkDownloadTransmission, setLinkDownloadTransmission] = useState('');
  const otSubscriber: Ref<OTSubscriberRef> | undefined = React.createRef();
  const { pathname } = useLocation();
  const { account } = useAuthState();
  const history = useHistory();
  const { invalidateInspectionParallel } = useMutateInspection();
  const inspectionId = getInspectionIdFromPathname(pathname);
  const { inspectionData } = useFilterInspectionById(inspectionId, 'TRANSMISSION');

  const sessionEventHandlers: SessionEventHandlers = {
    streamCreated: () => {
      setIsTransmissionOn(true);
      setError(false);
    },
    streamDestroyed: async () => {
      setIsTransmissionOn(false);
      setError(false);
      await mutateInspection();
    },
    signal: async () => {
      onSignalReceived();
    },
  };

  const createTransmissionSession = useCallback(async () => {
    if (OPENTOK_API_KEY && inspectionData?.transmissionSession) {
      const transmissionSessionHelper = createSession({
        apiKey: OPENTOK_API_KEY,
        sessionId: inspectionData?.transmissionSession.sessionId,
        token: inspectionData?.transmissionSession.token,
        onStreamsUpdated: (streams) => streams,
      });
      setSessionHelper(transmissionSessionHelper);
      return transmissionSessionHelper;
    }

    return null;
  }, [inspectionData]);

  const onSignalReceived = async () => {
    if (OPENTOK_API_KEY && inspectionData?.transmissionSession) {
      const transmissionSessionHelper = await createTransmissionSession();

      if (!transmissionSessionHelper) return;

      transmissionSessionHelper.session.once('signal', async (event: Signal) => {
        if (event.type === 'signal:takePictureFinished') {
          const photo = await JSON.parse(event.data || '');
          setCapturedPhotos((oldCapturedPhotos) => {
            return updateNewPhoto(oldCapturedPhotos, photo);
          });
          setUploadingPhoto(false);
        }

        if (event.type === 'signal:archiveError') {
          toast.error(
            'Não foi possível iniciar a gravação. Recomendamos tirar o máximo de fotos possível durante a transmissão.',
            {
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
            }
          );
        }

        if (event.data === 'Error: takePicture') {
          toast.error('Ocorreu um problema ao tirar a foto.');
          setUploadingPhoto(false);
        }

        if (event.data === 'Error: takePictureFinished') {
          toast.warning('Sua foto foi salva, porém ocorreu um problema no recebimento.');
          setUploadingPhoto(false);
        }

        if (event.data === 'Error: archiveError') {
          toast.error('Ocorreu um problema na gravação.');
        }
      });
    }
  };

  const onSessionError = ({ code }: Error) => {
    if (code === 1004) {
      setTransmissionExpired(true);
      setError(false);
    } else if (code === 1500) {
      setPermissionDenied(true);
      setError(false);
    } else {
      setError(true);
      setIsTransmissionOn(false);
    }
  };

  const updateNewPhoto = (oldCapturedPhotos: IPhoto[], photo: IPhoto) => {
    const signalId = photo.name.split(' - ')[0];
    const index = oldCapturedPhotos.findIndex((element) => {
      return element.name === `${signalId} - name`;
    });

    if (index === -1) {
      oldCapturedPhotos.push(photo);
    } else {
      oldCapturedPhotos[index] = photo;
    }

    return [...oldCapturedPhotos];
  };

  const handleStopTransmission = async () => {
    try {
      setFinishingTransmission(true);

      sessionHelper?.session.signal({
        type: 'finishTransmission',
      });

      stopArchivingTransmission();

      if (inspectionData) {
        await InspectionStatusService.photosReceived(inspectionData?.id);
      }

      sessionHelper?.disconnect();

      setIsTransmissionOn(false);

      await mutateInspection();
    } catch (err) {
      toast.error('Erro ao encerrar transmissão.');
    } finally {
      setFinishingTransmission(false);
    }
  };

  const openFinishTransmissionDialog = (): void => {
    setShowFinishTransmissionDialog(true);
  };

  const closeFinishTransmissionDialog = (): void => {
    setShowFinishTransmissionDialog(false);
  };

  const mutateInspection = async (): Promise<void> => {
    if (inspectionData?.id) {
      setTimeout(async () => {
        invalidateInspectionParallel(inspectionData.id);
      }, 5000);
    }
  };

  const stopArchivingTransmission = async () => {
    try {
      if (inspectionData?.transmissionSession) {
        const { id } = inspectionData?.transmissionSession;
        await TransmissionService.stopArchiving(id);
      }
    } catch (err) {
      toast.error('Erro ao parar gravação.');
    }
  };

  const handleTakePhoto = async () => {
    const signalId = Date.now();
    const signalData = JSON.stringify({ signalId, responsible: account.username });

    sessionHelper?.session.signal(
      {
        type: 'takePicture',
        data: signalData,
      },
      (signalError) => {
        if (signalError) {
          toast.warning('Ocorreu um problema ao tirar a foto, tente novamente.');
        }
      }
    );

    setCapturedPhotos((oldCapturedPhotos) => [
      ...oldCapturedPhotos,
      {
        description: 'string',
        url: 'string',
        path: 'string',
        name: `${signalId} - name`,
        latitude: null,
        longitude: null,
      },
    ]);
  };
  const hasPhotosReceived = () => {
    return inspectionData?.currentStatus && InspectionStatusService.hasPhotosReceived(inspectionData.currentStatus);
  };

  const canBeStartedNow = (): boolean => {
    if (inspectionData?.schedule) {
      const scheduleDate = new Date(inspectionData.schedule);
      const today = new Date();
      return today >= scheduleDate;
    }
    return true;
  };

  const goBack = () => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}`);
  };

  const handleCopyLink = async () => {
    try {
      toast.dismiss();
      if (linkDownloadTransmission) {
        await navigator.clipboard.writeText(linkDownloadTransmission);
        toast.success('O link da gravação foi copiado para a área de transferência.');
      } else {
        toast.error('Não foi possível copiar o link da gravação porque não foi possível gera-lo.');
      }
    } catch (error) {
      toast.error('Houve um problema ao gerar o link da gravação.');
    }
  };

  useEffect(() => {
    setCapturedPhotos(inspectionData?.photos || []);
    setTransmissionComments(inspectionData?.transmissionSession?.comments || []);
    createTransmissionSession();
    setLinkDownloadTransmission(inspectionData?.transmissionSession?.urlTransmission || '');
  }, [inspectionData, createTransmissionSession]);

  return (
    <S.Container permissionDenied={permissionDenied}>
      <S.VideoCard>
        <S.Header>
          <S.Title>
            <BackButton onClick={goBack} />
            Transmissão ao vivo
          </S.Title>
          {!error && inspectionData && canBeStartedNow() && !hasPhotosReceived() ? (
            <S.Actions>
              <S.Button
                type="button"
                variant="contained"
                text="ENCERRAR"
                onClick={openFinishTransmissionDialog}
                loading={finishingTransmission}
                loadingColor="#000"
                disabled={finishingTransmission}
                startIcon={<CallEndIcon />}
              />
              <ConfirmDialog
                dialogVisibility={showFinishTransmissionDialog}
                handleClose={closeFinishTransmissionDialog}
                onSubmit={handleStopTransmission}
                title="Deseja encerrar a transmissão?"
                subtitle='Atenção! Verifique se ainda há fotos carregando em "Fotos Capturadas". Encerrar a transmissão antes do
                  carregamento completo pode ocasionar a perda delas. Deseja encerrar mesmo assim?'
              />
              {isTransmissionOn && !finishingTransmission && (
                <S.Button
                  type="button"
                  testID="take-photo-button"
                  variant="outlined"
                  text="TIRAR FOTO"
                  onClick={handleTakePhoto}
                  loading={uploadingPhoto}
                  loadingColor="#000"
                  disabled={uploadingPhoto}
                  startIcon={<CameraIcon />}
                />
              )}
            </S.Actions>
          ) : (
            <S.Actions>
              {linkDownloadTransmission && (
                <S.Button
                  type="button"
                  variant="outlined"
                  text="Link da gravação"
                  testID="copy-link-button"
                  onClick={handleCopyLink}
                  startIcon={<InsertLinkIcon />}
                />
              )}
            </S.Actions>
          )}
        </S.Header>

        {inspectionData?.transmissionSession && OPENTOK_API_KEY && (
          <>
            {error && !transmissionExpired && !hasPhotosReceived() && (
              <S.Body>
                <S.Illustration src={NoTransmission} />
                <S.Description>
                  Ocorreu algum <b>erro</b> na transmissão
                </S.Description>
              </S.Body>
            )}

            {permissionDenied && !hasPhotosReceived() && (
              <S.Body>
                <S.VideoSubtitle>
                  Permissão de áudio <b>negada</b>! Por favor, verifique as configurações de permissão do navegador.
                </S.VideoSubtitle>
              </S.Body>
            )}

            {transmissionExpired && !hasPhotosReceived() && (
              <S.Body>
                <S.Illustration src={NoTransmission} />
                <S.Description>
                  A transmissão <b>expirou</b>
                </S.Description>
              </S.Body>
            )}

            {!isTransmissionOn &&
              !error &&
              !transmissionExpired &&
              !hasPhotosReceived() &&
              !inspectionData?.transmissionSession?.urlTransmission &&
              canBeStartedNow() && (
                <S.Body>
                  <S.Illustration src={WaitTransmission} />
                  <S.Description>
                    Aguarde o cliente se conectar ou <b>encerre</b> a transmissão
                  </S.Description>
                </S.Body>
              )}

            {!isTransmissionOn && !error && !transmissionExpired && !hasPhotosReceived() && !canBeStartedNow() && (
              <S.Body>
                <S.Illustration src={WaitTransmission} />
                {inspectionData.schedule && (
                  <S.Description>
                    A <b>transmissão</b> está agendada para:
                    <b> {DateService.formatDateAndTime(inspectionData.schedule)}</b>
                  </S.Description>
                )}
              </S.Body>
            )}

            {!isTransmissionOn && hasPhotosReceived() && !inspectionData?.transmissionSession?.urlTransmission && (
              <S.Body>
                <S.Illustration src={NoTransmission} />
                <S.Description>
                  A transmissão já foi <b>finalizada</b>. Porém ocorreu um <b>erro na gravação</b>.
                </S.Description>
              </S.Body>
            )}

            {!isTransmissionOn && hasPhotosReceived() && inspectionData?.transmissionSession?.urlTransmission && (
              <S.Body>
                <S.VideoSubtitle>
                  A transmissão já foi <b>finalizada</b>. Verifique as fotos, comentários e gravação.
                </S.VideoSubtitle>
                {inspectionData?.transmissionSession?.urlTransmission && (
                  <S.Video controls>
                    <source src={inspectionData.transmissionSession.urlTransmission} type="video/mp4" />
                    <track kind="captions" />
                  </S.Video>
                )}
              </S.Body>
            )}

            <OTSession
              apiKey={OPENTOK_API_KEY}
              sessionId={inspectionData.transmissionSession.sessionId}
              token={inspectionData.transmissionSession.token}
              onError={onSessionError}
              eventHandlers={sessionEventHandlers}
            >
              {isTransmissionOn && !error && !hasPhotosReceived() && (
                <>
                  <Publisher onSessionError={onSessionError} />
                  <Subscriber onSessionError={onSessionError} subscriberRef={otSubscriber} />
                </>
              )}
            </OTSession>
          </>
        )}
      </S.VideoCard>
      {!error && (
        <>
          <TransmissionPhotos photos={capturedPhotos} />

          <TransmissionComments
            isTransmissionOn={isTransmissionOn}
            transmissionComments={transmissionComments}
            setTransmissionComments={setTransmissionComments}
            transmissionSessionId={inspectionData?.transmissionSessionId || ''}
          />
        </>
      )}
    </S.Container>
  );
};

export default preloadScript(Transmission);
