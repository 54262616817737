/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import { IShowFilters } from './InspectionFilter.types';

export const InspectionsFilterWrapper = styled.div<IShowFilters>`
  display: flex;
  flex-direction: ${(props) => (props.showFilters ? 'column' : 'row')};
  align-items: ${(props) => (props.showFilters ? 'inherit' : 'center')};
  gap: ${(props) => (props.showFilters ? 0 : '20px')};
  row-gap: 20px;
  width: 100%auto;
  padding: 20px 35px;
  background-color: #fff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const InspectionsFilterTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #6a758b;
`;

export const InspectionSelectFilters = styled.div<IShowFilters>`
  display: ${(props) => (props.showFilters ? 'grid' : 'flex')};
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  z-index: 1;

  @media only screen and (max-width: 599px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 600px) and (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(6, 1fr);

  @media only screen and (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 600px) and (max-width: 1279px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
