import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { useAppSelector } from 'hooks/hooks';
import { useDispatch } from 'react-redux';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { toast } from 'react-toastify';
import { DeleteIcon, IconButtonCustom } from './RemoveOutsourcedServiceAction.styles';

interface IRemoveOutsourcedServiceActionProps {
  serviceId: string;
}

const RemoveOutsourcedServiceAction = ({ serviceId }: IRemoveOutsourcedServiceActionProps) => {
  const [loading, setLoading] = useState(false);
  const budgetId = useAppSelector((state) => state.presentBudget.budget._id);
  const dispatch = useDispatch();

  const removeOutsourcedServiceBudget = async () => {
    try {
      setLoading(true);
      const budget = await BudgetService.deleteExternalService(budgetId, serviceId);
      dispatch(setBudget(budget));
    } catch (error) {
      toast.error('Não foi possível remover o serviço');
    } finally {
      setLoading(false);
    }
  };

  return (
    <IconButtonCustom
      color="inherit"
      className="delete-icon"
      onClick={() => removeOutsourcedServiceBudget()}
      disabled={loading}
    >
      <Tooltip title="Deletar" placement="top" arrow>
        <DeleteIcon />
      </Tooltip>
    </IconButtonCustom>
  );
};

export default RemoveOutsourcedServiceAction;
