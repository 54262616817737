import React, { useState, useEffect, useCallback } from 'react';
import { Button, Label } from 'components';
import { IOptions } from 'components/Select/Select.types';
import { toast } from 'react-toastify';
import CancellationMotiveService from 'services/ApiService/CancellationMotiveService/CancellationMotiveService';
import { mutateMany } from 'hooks/SWRUtils/SWRUtils';
import { ICancellation } from 'services/ApiService/CancellationMotiveService/CancellationMotiveService.types';
import useFade from 'utils/useFadeEffect/useFade';
import { useManagementDashboardState } from 'contexts/managementDashboard/useManagementDashboard';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { ICancellationMotiveDialogProps, IMotive, MotivesEnum } from './CancellationMotiveDialog.types';
import * as Style from './CancellationMotiveDialog.styles';

export const CancellationMotiveDialog: React.FC<ICancellationMotiveDialogProps> = ({
  inspectionId,
  dialogVisibility,
  handleClose,
}) => {
  const { isConsumerActive } = useConsumer();
  const { selectedCompanyId } = useManagementDashboardState();
  const [motiveList, setMotiveList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [motiveSelected, setMotiveSelected] = useState<string>('');
  const [motiveNameSelected, setMotiveNameSelected] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dialogHeight, setDialogHeight] = useState(false);

  const { isVisible, setFadeShow, fromProps } = useFade(false);
  const descriptionProps = {
    'data-testid': 'input-area',
    maxLength: 250,
    form: {
      autocomplete: 'off',
    },
  };

  const setDefaultSelectedValue = ([motive]: IMotive[]): void => {
    setMotiveSelected(motive.id);
    setMotiveNameSelected(motive.motive);
  };

  const getMotiveList = useCallback(async (): Promise<IOptions[]> => {
    try {
      const motives = await CancellationMotiveService.getMotives();
      setDefaultSelectedValue(motives);
      return motivesToOptions(motives);
    } catch (error) {
      toast.error('Problema ao carregar os motivos de cancelamento');
      return [];
    }
  }, []);

  const motivesToOptions = (motives: IMotive[]): IOptions[] => {
    return motives.map(({ id: value, motive: name }) => ({ value, name }));
  };

  const isDescriptionVisible = useCallback((): boolean => {
    return motiveNameSelected === MotivesEnum.OTHERS;
  }, [motiveNameSelected]);

  const handleCheckbox = (): void => setCheckbox(!checkbox);

  const disableSubmitButton = (): boolean => {
    if (motiveNameSelected === MotivesEnum.OTHERS) {
      return !checkbox || description === '';
    }
    return !checkbox || motiveNameSelected === '';
  };

  const getCancellationData = (): ICancellation => {
    const motive = motiveList.find(({ name }) => name === MotivesEnum.OTHERS);
    if (description !== '' && motiveSelected === motive?.value) {
      return {
        inspectionId,
        cancellationMotiveId: motiveSelected,
        description,
      };
    }
    return {
      inspectionId,
      cancellationMotiveId: motiveSelected,
    };
  };

  const handleSubmit = async (): Promise<void> => {
    setLoadingSubmit(true);
    try {
      await CancellationMotiveService.saveCancellation(getCancellationData());
      updateSWRInspections();
      toast.success('Atendimento cancelado com sucesso');
      setLoadingSubmit(false);
      handleClose();
    } catch (error) {
      toast.error('Falha ao cancelar, tente novamente');
      setLoadingSubmit(false);
    }
  };

  const updateSWRInspections = async (): Promise<void> => {
    const inspectionEndpoint = isConsumerActive(ConsumersEnum.INSPECTION_REPORT_MANAGEMENT, selectedCompanyId)
      ? '^inspections/arval-report\\?companiesId(.*)'
      : '^inspections\\/admin\\?companiesId=.*';

    await mutateMany(inspectionEndpoint);
  };

  const handleMotiveNameSelect = useCallback(
    (value: string): void => {
      const selectedMotive = motiveList.find((motive) => motive.value === value);
      if (selectedMotive) {
        setMotiveNameSelected(selectedMotive.name);
      }
    },
    [motiveList]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      setMotiveList(await getMotiveList());
    })();
  }, [getMotiveList]);

  useEffect(() => {
    handleMotiveNameSelect(motiveSelected);
  }, [handleMotiveNameSelect, motiveSelected]);

  useEffect(() => {
    if (isDescriptionVisible()) {
      setDialogHeight(true);
      setFadeShow(true);
    } else {
      setDialogHeight(false);
      setFadeShow(false);
    }
  }, [isDescriptionVisible, setFadeShow]);

  return (
    <Style.DialogBase
      data-testid="cancel-motivation-dialog"
      aria-labelledby="form-dialog-title"
      open={dialogVisibility}
      onClose={handleClose}
    >
      <Style.FormWrapper dialogHeight={dialogHeight}>
        <Style.DialogTitle>Qual o motivo de cancelamento?</Style.DialogTitle>
        <Label htmlFor="create-account-company" testID="label-company">
          Motivo
        </Label>
        <Style.SelectContainer>
          <Style.SelectWrapper
            testID="cancellation-motive-select"
            options={motiveList}
            selectedOptions={motiveSelected}
            labelId="cancellation-motive-select"
            name="motives"
            onChange={({ target: { value } }): void => setMotiveSelected(value as string)}
          />
        </Style.SelectContainer>
        {isVisible && (
          <Style.DescriptionContainer {...fromProps}>
            <Label htmlFor="create-account-company" testID="label-company">
              Descrição
            </Label>
            <Style.TextField
              testID="cancellation-motive-description"
              ariaLabelledby="create-account-email"
              id="cancellation-motive-description"
              inputProps={descriptionProps}
              name="description"
              type="text"
              variant="outlined"
              multiline
              minRows={6}
              onChange={({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void =>
                setDescription(value as string)
              }
            />
            <Style.DescriptionCounter data-testid="description-counter">
              {description?.length} / 250
            </Style.DescriptionCounter>
          </Style.DescriptionContainer>
        )}
        <Style.CheckboxContainer onClick={(): void => handleCheckbox()}>
          <Style.CancellationCheckbox data-testid="cancellation-motive-checkbox" color="primary" checked={checkbox} />
          <Style.CheckboxText>
            Tenho ciência que após cancelar o atendimento não poderei reabrir o mesmo.
          </Style.CheckboxText>
        </Style.CheckboxContainer>
        <Style.DialogActions>
          <Style.ButtonWrapper>
            <Button
              testID="cancellation-motive-cancel"
              type="button"
              variant="outlined"
              onClick={handleClose}
              text="VOLTAR"
            />
          </Style.ButtonWrapper>
          <Style.ButtonWrapper>
            <Button
              testID="cancellation-motive-submit"
              type="submit"
              text="FINALIZAR"
              disabled={disableSubmitButton()}
              loading={loadingSubmit}
              onClick={handleSubmit}
              loadingSize={25}
              loadingColor="#FFF"
            />
          </Style.ButtonWrapper>
        </Style.DialogActions>
      </Style.FormWrapper>
    </Style.DialogBase>
  );
};
