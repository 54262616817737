import React from 'react';
import { MessagePayload } from 'firebase/messaging';
import environment from 'environments/environment';
import { useTranslate } from 'hooks/Translate/TranslateHook';
import { useNotificationsContext } from 'contexts/notifications/useNotificationsContext';
import { NotificationTypeEnum } from 'commom/types/Notification.types';
import * as S from './NotificationListener.styles';

interface INotificationListenerProps {
  payload: MessagePayload;
}

export const NotificationListener = ({ payload }: INotificationListenerProps) => {
  const { translate } = useTranslate();
  const { updateHasNewNotifications } = useNotificationsContext();

  const isAlertType = payload.data?.type === NotificationTypeEnum.ALERT;
  const isAwaitingRegulator = payload.data?.title.includes('AWAITING_REGULATOR');
  const baseInspectionLink = `${environment.APP_URL}/agendamentos/v2/detalhes/${payload.data?.inspectionId}`;

  const getTranslatedMessage = (value: string) => {
    return `NOTIFICATIONS.TYPE.${value}`;
  };

  const handleLink = () => {
    if (isAwaitingRegulator) return `${baseInspectionLink}/transmissao`;

    return baseInspectionLink;
  };

  const onLinkClick = () => {
    !isAlertType && updateHasNewNotifications(false);
  };

  return (
    <S.Container>
      {
        <>
          <S.Title>{translate(`NOTIFICATIONS.TYPE.${payload.data?.title}`)}</S.Title>
          <S.Message>
            {translate(`NOTIFICATIONS.TYPE.${payload.data?.message}`, {
              inspectionCode: payload.data?.code || '(Não foi possível identificar o atendimento)',
            })}
          </S.Message>
          {payload.data?.label && (
            <S.LinkButton data-testid="link-action-notification" href={handleLink()} onClick={onLinkClick}>
              {translate(getTranslatedMessage(payload.data?.label))}
            </S.LinkButton>
          )}
        </>
      }
    </S.Container>
  );
};
