import React from 'react';
import InspectionCreatedIcon from 'assets/svg/inspection-status/icon-inspection-created.svg';
import InspectioLinkSentIcon from 'assets/svg/inspection-status/icon-link-sent.svg';
import InspectionPhotosReceivedIcon from 'assets/svg/inspection-status/icon-photos-received.svg';
import InspectionStartedIcon from 'assets/svg/inspection-status/icon-inspection-started.svg';
import InspectionFinishedIcon from 'assets/svg/inspection-status/icon-inspection-finished.svg';
import DateService from 'services/DateService/dateService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { InspectionCategoryEnum } from 'utils/InspectionEnum';
import { formatPhone } from '../../../../../../utils/formatPhone';
import { StatusEnum } from '../../../Inspection/Inspection.types';
import { IInspectionSteps } from './InspectionSteps.types';
import * as S from './InspectionSteps.styles';

const InspectionSteps = ({
  testID = 'inspection-steps',
  inspection: { status, inspectionCategory, customer },
}: IInspectionSteps) => {
  const statusFiltered = status?.filter(({ type }) => InspectionStatusService.isNotExpiredOrCanceled(type));
  const categoryStatus = inspectionCategory?.status
    ?.filter(({ type }) => InspectionStatusService.isNotExpiredOrCanceled(type))
    .map(({ id, description, type }) => ({ id, description, type }));

  const getInspectionStatus = () => {
    if (categoryStatus && statusFiltered) {
      const merged = categoryStatus.map((item) => {
        return {
          ...item,
          date: statusFiltered.find(({ type }) => type === item.type)?.date || '',
        };
      });
      return merged;
    }
    return [];
  };

  const getLinkSentMessage = () => {
    return (
      <>
        Mensagem enviada para <S.Semibold>{customer?.contactName}</S.Semibold>, no número{' '}
        <S.Semibold>{formatPhone(customer?.phone || '')}</S.Semibold> via <S.Semibold>Whatsapp</S.Semibold>
      </>
    );
  };

  const getStatusInfo = (type: StatusEnum) => {
    const infos = {
      0: {
        icon: <img src={InspectionCreatedIcon} alt="" />,
        message: 'O atendimento foi criado',
      },
      1: {
        icon: <img src={InspectioLinkSentIcon} alt="" />,
        message: getLinkSentMessage(),
      },
      2: {
        icon: <img src={InspectionPhotosReceivedIcon} alt="" />,

        message: 'As fotos foram enviadas',
      },
      3: {
        icon: <img src={InspectionStartedIcon} alt="" />,

        message: 'O atendimento está sendo realizado',
      },
      4: {
        icon: <img src={InspectionFinishedIcon} alt="" />,

        message: 'O atendimento foi finalizado',
      },
      5: { icon: <img src="" alt="" />, message: '' },
      6: { icon: <img src="" alt="" />, message: '' },
    };

    return infos[type];
  };

  const hasError = InspectionStatusService.isCanceled(status) || InspectionStatusService.isExpired(status);
  const isLastStatus = (type: StatusEnum): boolean => type === StatusEnum.INSPECTION_FINISHED;
  const isCompleted = (type: StatusEnum): boolean => {
    const lastIndex =
      inspectionCategory?.code === InspectionCategoryEnum.LOCALLY && type !== StatusEnum.DEFAULT
        ? statusFiltered.length - 1
        : statusFiltered.length - 2;

    return type <= lastIndex;
  };

  return (
    <S.Container data-testid={testID}>
      <S.Title>Histórico de atividades</S.Title>
      {getInspectionStatus() && getInspectionStatus()?.length > 0 ? (
        <S.Stepper orientation="vertical" connector={null} activeStep={statusFiltered.length}>
          {getInspectionStatus().map(({ id, type, description, date }) => (
            <S.Step key={id} expanded>
              <S.Header
                error={hasError}
                StepIconComponent={({ error, completed }) => (
                  <S.Icon error={error} completed={completed}>
                    {getStatusInfo(type).icon}
                  </S.Icon>
                )}
              >
                {description}
              </S.Header>
              <S.Content error={+hasError} completed={+isCompleted(type)} last={+isLastStatus(type)}>
                <S.StatusMessage>{getStatusInfo(type).message}</S.StatusMessage>
                {date && <S.StatusDate>{DateService.formatDateAndTime(date)}</S.StatusDate>}
              </S.Content>
            </S.Step>
          ))}
        </S.Stepper>
      ) : (
        <S.StatusEmpty>Os status deste atendimento estão indisponíveis no momento</S.StatusEmpty>
      )}
    </S.Container>
  );
};

export default InspectionSteps;
