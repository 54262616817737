/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';

export function useHideZendesk() {
  useEffect(() => {
    if ((window as any).zE) {
      (window as any).zE('webWidget', 'hide');
    }

    return () => (window as any).zE('webWidget', 'show');
  }, []);
}
