import ApiService from '..';
import { IPartsService, IVehicleArea, IVehiclePartPrice, IVehiclePartType, IVehicleType } from './partsService.types';

export const PartsService: IPartsService = {
  getVehicleTypeById: async (vehicleTypeId) => {
    const { data } = await ApiService.get<{ vehicleType: IVehicleType }>('/vehicle/type', {
      params: {
        vehicleTypeId,
      },
    });

    return data.vehicleType;
  },

  getVehicleAreaById: async (vehicleAreaId) => {
    const { data } = await ApiService.get<{ vehicleArea: IVehicleArea }>('/vehicle/areas', {
      params: {
        vehicleAreaId,
      },
    });

    return data.vehicleArea;
  },

  getVehiclePartTypesByGroupPartIdAndInspectionId: async (groupPartId, vehicleIdentifier) => {
    const { data } = await ApiService.get<{ parts: IVehiclePartType[] }>('/vehicle/part-types', {
      params: {
        groupPartId,
        vehicleIdentifier,
      },
    });

    return data.parts;
  },

  getVehiclePartPriceByPartTypeIdAndVehicleIdentifier: async (partTypeId, vehicleIdentifier) => {
    const { data } = await ApiService.get<{ parts: IVehiclePartPrice[] }>('/vehicle/part/price', {
      params: {
        partTypeId,
        vehicleIdentifier,
      },
    });

    return data.parts;
  },
};
