import styled from 'styled-components';
import { Select as SelectComponent } from 'components/Select/Select';
import { DateRange as DateRangeComponent } from 'components/DateRange/DateRange';
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  padding: 20px 35px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 20%;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 80%;
`;

export const Select = styled(SelectComponent)`
  width: 230px;
  height: 34px !important;

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1.5px solid #ced6e5;
  }

  .MuiMenuItem-root {
    height: 40px !important;
  }
`;

export const DateRange = styled(DateRangeComponent)`
  .DateRangePicker {
    width: 300px !important;
    height: 34px !important;
  }

  .DateRangePickerInput {
    height: 34px !important;
    padding: 0 14px 0 0;
    border: 1.5px solid #ced6e5;

    .DateInput {
      height: 34px !important;
      background-color: #ffffff00;

      .DateInput_input {
        height: 34px !important;
        background-color: #ffffff00;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .DateRangePicker_picker {
      top: 42px !important;
    }

    .DateRangePickerInput_arrow_svg {
      width: 18px;
    }

    .DateRangePickerInput_clearDates {
      padding: 5px;
      margin-right: 2px;
    }
  }
`;
