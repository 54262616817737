import styled from 'styled-components';

export const StepsContainer = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;

export const StepButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 40px;
  border-radius: 4px;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isActive }) => (isActive ? '#000' : '#fff')};
  cursor: pointer;

  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  text-align: left;

  &:disabled {
    cursor: not-allowed;

    img {
      filter: opacity(0.5) grayscale(1);
    }
  }

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
`;
