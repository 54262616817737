import axios, { AxiosRequestConfig } from 'axios';
import environment from 'environments/environment';

const ApiService = axios.create({
  baseURL: `${environment.API_URL}`,
});

ApiService.defaults.withCredentials = true;

ApiService.interceptors.request.use((config: AxiosRequestConfig) => {
  const headers = { ...config.headers };

  return { ...config, headers };
});

ApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { url } = error.config;
    const allowList = ['/login', '/toggles'];
    const allowListReg = new RegExp(`(${allowList.join('|')})`, 'g');

    if (!allowListReg.test(url) && error.response) {
      const { status } = error.response;
      switch (status) {
        case 401:
          if (url !== url.includes('/vistoria')) {
            unauthorizeSession();
          }
          break;
        case 403:
          unauthorizeSession();
          break;
        default:
      }
    }
    return Promise.reject(error);
  }
);

const unauthorizeSession = (): void => {
  localStorage.clear();
  window.location.pathname = '/login';
};

export default ApiService;
