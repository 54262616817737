import React from 'react';
import { Drawer } from 'components/Drawer';
import Loading from 'components/Loading';
import { priceFormatter } from 'utils/formatPrice';
import { useAppSelector } from 'hooks/hooks';
import UnavaliableParts from 'assets/svg/unavaliable-parts.svg';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { PartsService } from 'services/ApiService/PartService/partsService';
import { IVehiclePartPrice, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import {
  ListPartsContent,
  LoadingContainer,
  PartCard,
  TitlePart,
  SubtitlePart,
  Divider,
  HeaderContainer,
  UnavaliablePartsContainer,
  UnavaliablePartsWrapper,
} from '../../AddPartsDrawer.styles';

interface IPartListProps {
  partTypeSelected: IVehiclePartType | null;
  handleChangePartPriceAndIsOpenPartLaborCostDrawer: (
    partPrice: IVehiclePartPrice | null,
    isShowLaborCostDrawer: boolean
  ) => void;
}

const PartsList = ({ partTypeSelected, handleChangePartPriceAndIsOpenPartLaborCostDrawer }: IPartListProps) => {
  const vehicleIdentifier = useAppSelector((state) => state.presentInspection.inspection.product?.vehicle?.identifier);

  const vehiclePartPrices = useQuery(
    ['get-vehicle-part-price', { partTypeId: partTypeSelected?.id, vehicleIdentifier }],
    () =>
      PartsService.getVehiclePartPriceByPartTypeIdAndVehicleIdentifier(
        partTypeSelected?.id as string,
        vehicleIdentifier as string
      ),
    {
      onError: () => {
        toast.error('Erro ao buscar os preços da peças');
      },
      enabled: partTypeSelected !== null && !!vehicleIdentifier,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <HeaderContainer>
        <Drawer.Header>
          <Drawer.Title>Adicionar Peça</Drawer.Title>
          <Drawer.Subtitle>Selecione um fabricante, se houver mais de um, e adicione a peça.</Drawer.Subtitle>
        </Drawer.Header>
        <Divider className="services-labor-cost" />
      </HeaderContainer>

      {(() => {
        if (vehiclePartPrices.isLoading) {
          return (
            <LoadingContainer data-testid="loading-container">
              <Loading width="20%" />
            </LoadingContainer>
          );
        }

        if (vehiclePartPrices.data && vehiclePartPrices.data.length === 0) {
          return (
            <Drawer.Content>
              <UnavaliablePartsContainer>
                <img
                  alt="Não há peças disponíveis"
                  src={UnavaliableParts}
                  data-testid="empty-parts"
                  width={150}
                  height={116}
                />
                <UnavaliablePartsWrapper>
                  <b>Não há peças disponíveis</b>
                </UnavaliablePartsWrapper>
              </UnavaliablePartsContainer>
            </Drawer.Content>
          );
        }

        return (
          <Drawer.Content>
            <ListPartsContent>
              {vehiclePartPrices.data?.map((vehiclePartPrice) => (
                <PartCard
                  key={vehiclePartPrice.partNumber}
                  onClick={() => handleChangePartPriceAndIsOpenPartLaborCostDrawer(vehiclePartPrice, true)}
                >
                  <TitlePart>{vehiclePartPrice.description}</TitlePart>
                  <SubtitlePart>
                    {vehiclePartPrice.partNumber} <b>• {priceFormatter.format(vehiclePartPrice.price)}</b>
                  </SubtitlePart>
                </PartCard>
              ))}
            </ListPartsContent>
          </Drawer.Content>
        );
      })()}
    </>
  );
};

export default PartsList;
