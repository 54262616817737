import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { IOptionLabelProps, IRadioButtonGroupsProps } from './RadioButtonsGroup.types';
import { LabelRadioForm, SubtitleRadioForm, TitleRadioForm, FormControl } from './RadioButtonsGroup.styles';

const OptionLabel = ({ option }: IOptionLabelProps) => {
  return (
    <LabelRadioForm>
      <TitleRadioForm>{option.title}</TitleRadioForm>
      {option.subtitle && (
        <SubtitleRadioForm>
          {option.subtitle}
          {option.secondarySubtitle && (
            <>
              {' • '}
              <b>{option.secondarySubtitle}</b>
            </>
          )}
        </SubtitleRadioForm>
      )}
    </LabelRadioForm>
  );
};

export default function RadioButtonsGroup({
  options,
  onChange,
  testId = 'radio-buttons-group',
  defaultValue,
  disabled = false,
}: IRadioButtonGroupsProps) {
  return (
    <FormControl data-testid={testId} disabled={disabled}>
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={onChange}
        defaultValue={defaultValue}
        row
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio data-testid={option.id} size="small" />}
            label={<OptionLabel option={option} />}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
