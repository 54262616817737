import { Button } from 'components';
import { DateRange } from 'components/DateRange/DateRange';
import { Select } from 'components/Select/Select';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  align-items: inherit;
  gap: 0px;
  row-gap: 20px;
  width: 100%;
  padding: 14px 20px;
  background-color: #fff;
  border-radius: 8px;
  gap: 10px;
  flex-wrap: wrap;
  z-index: 1;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #6a758b;
  margin-bottom: 25px;
  text-align: left;
`;

export const FilterButton = styled.div`
  margin: auto 0 0 auto;
`;

export const StyedButton = styled(Button)`
  height: 37px !important;
`;

export const SelectStyled = styled(Select)`
  max-width: 160px;
  height: 34px !important;

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1.5px solid #ced6e5;
  }

  .MuiMenuItem-root {
    height: 40px !important;
  }
`;

export const DateRangeStyled = styled(DateRange)`
  .DateRangePicker {
    height: 34px !important;
  }

  .DateRangePickerInput {
    height: 34px !important;
    padding: 0 14px 0 0;
    border: 1.5px solid #ced6e5;

    .DateInput {
      height: 34px !important;
      background-color: #ffffff00;

      .DateInput_input {
        height: 34px !important;
        background-color: #ffffff00;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .DateRangePicker_picker {
      top: 42px !important;
    }

    .DateRangePickerInput_arrow_svg {
      width: 18px;
    }

    .DateRangePickerInput_clearDates {
      padding: 5px;
    }
  }
`;
