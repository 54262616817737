import React from 'react';
import { IPartGroup, IVehicleArea, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import { BackButton } from 'components';
import { Skeleton } from '@mui/material';
import {
  Container,
  EmptyComtainer,
  Header,
  PartCard,
  PartList,
  PartTitle,
  Title,
} from './SelectionCarPartTypes.styles';

interface ISelectionCarPartProps {
  vehiclePartTypes: IVehiclePartType[] | undefined;
  vehicleAreaSelected: IVehicleArea;
  partGroupSelected: IPartGroup;
  isVehiclePartTypeLoading: boolean;
  handleChangePartType: (partType: IVehiclePartType | null) => void;
  handleChangePartGroup: (partGroup: IPartGroup | null) => void;
  handleChangeIsOpenAddPartsDrawer: (visible: boolean) => void;
}

const SelectionCarPartTypes = ({
  vehiclePartTypes,
  vehicleAreaSelected,
  partGroupSelected,
  isVehiclePartTypeLoading,
  handleChangePartType,
  handleChangePartGroup,
  handleChangeIsOpenAddPartsDrawer,
}: ISelectionCarPartProps) => {
  const isEmptyVehiclePartTypes = vehiclePartTypes?.length === 0 || vehiclePartTypes === undefined;

  const goBackToVehicleGroupList = () => {
    handleChangePartType(null);
    handleChangePartGroup(null);
  };

  const handleSelectPartType = (partType: IVehiclePartType) => {
    handleChangePartType(partType);
    handleChangeIsOpenAddPartsDrawer(true);
  };

  const handleEmptyMessage = () => {
    return (
      <EmptyComtainer>
        <span>Nenhuma peça encontrada</span>
      </EmptyComtainer>
    );
  };

  const handleLoading = () => {
    return Array.from({ length: 8 }).map((_, index) => (
      <Skeleton key={index} variant="rounded" width={300} height={80} />
    ));
  };

  return (
    <Container>
      <Header>
        <div>
          <BackButton onClick={goBackToVehicleGroupList} />
          <Title>
            {vehicleAreaSelected?.description} / {partGroupSelected.description}
          </Title>
        </div>
      </Header>

      {(() => {
        if (isVehiclePartTypeLoading) {
          return <PartList>{handleLoading()}</PartList>;
        }

        if (isEmptyVehiclePartTypes) {
          return handleEmptyMessage();
        }

        return (
          <PartList>
            {vehiclePartTypes?.map((part) => (
              <PartCard key={part.id} onClick={() => handleSelectPartType(part)}>
                <PartTitle>{part.description}</PartTitle>
              </PartCard>
            ))}
          </PartList>
        );
      })()}
    </Container>
  );
};

export default SelectionCarPartTypes;
