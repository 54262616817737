import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';
import { Button } from 'components';

export const BackButtonContent = styled.div`
  margin-bottom: 12px;
`;

export const CreateDealButton = styled(Button)`
  margin-top: 20px !important;
  margin-bottom: 20px !important;
`;

export const Separator = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;
`;

export const ContainerEmptyDeal = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;

  img {
    max-width: 150.96px;
    max-height: 116px;
  }
`;

export const TitleEmptyDeal = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  color: #6a758b;
  padding-top: 10px;
  width: 253px;
`;

export const DescriptionEmptyDeal = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  color: #6a758b;
  width: 204.13px;
`;

export const DealsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
