import { IBudgetPartServiceTypeEnum } from 'commom/types/Budget.types';
import { BackButton } from 'components/BackButton';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import React from 'react';
import { priceFormatter } from 'utils/formatPrice';
import ManuallyPartInformation from './ManuallyPartInformation';
import {
  HeaderPartDescriptionWrapper,
  HeaderWrapper,
  LabelService,
  Separator,
  ServiceWrapper,
  SubtitlePart,
  TitleManuallyPart,
  TitlePart,
} from './PartDrawer.styles';
import { IPartHeaderProps } from './PartDrawer.types';

export default function Header({ handleBackButton, isEditMode = false }: IPartHeaderProps) {
  const { serviceType, setServiceType, isPartyManuallyInserted, partPrice, partName, partSku } = usePartContext();

  const handleChangeToggleButton = (optionCode: IBudgetPartServiceTypeEnum) => {
    if (optionCode === IBudgetPartServiceTypeEnum.REPLACEMENT) {
      setServiceType({
        code: IBudgetPartServiceTypeEnum.REPLACEMENT,
        description: 'Troca',
      });
    } else {
      setServiceType({
        code: IBudgetPartServiceTypeEnum.REPAIR,
        description: 'Reparo',
      });
    }
  };

  const priceFormatted = priceFormatter.format(partPrice);

  return (
    <>
      <BackButton onClick={handleBackButton} />

      <HeaderWrapper>
        <HeaderPartDescriptionWrapper>
          {isPartyManuallyInserted ? (
            <TitleManuallyPart>
              {isEditMode ? 'Editar peça inserida manualmente' : 'Inclusão manual de peça'}
            </TitleManuallyPart>
          ) : (
            <>
              <TitlePart className="title-labor-cost">{partName}</TitlePart>
              <SubtitlePart>
                {partSku}
                <b> • {priceFormatted}</b>
              </SubtitlePart>
            </>
          )}
        </HeaderPartDescriptionWrapper>

        {isPartyManuallyInserted && (
          <>
            <ServiceWrapper>
              <ManuallyPartInformation isEditMode={isEditMode} />
            </ServiceWrapper>
            <Separator />
          </>
        )}

        <ServiceWrapper>
          <LabelService>Serviço</LabelService>
          <ToggleButton
            options={[
              { code: IBudgetPartServiceTypeEnum.REPLACEMENT, title: 'Troca' },
              { code: IBudgetPartServiceTypeEnum.REPAIR, title: 'Reparo' },
            ]}
            value={serviceType.code}
            onChange={(_, value: string) => handleChangeToggleButton(value as IBudgetPartServiceTypeEnum)}
          />
        </ServiceWrapper>
        <Separator />
      </HeaderWrapper>
    </>
  );
}
