import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useModules } from 'contexts/modules/useModules';
import { ICustomRouteProps } from './CustomRoute.types';

const CustomRoute: React.FC<ICustomRouteProps> = ({
  isPrivate = false,
  isAdminRoute = false,
  isInsuranceCompanyRoute = false,
  isTokenValidationPagePrivate = false,
  component: Component,
  path,
  module,
  ...rest
}) => {
  const { isAuthenticated, isAdmin, isEmailConfirmed } = useAuthDispatch();
  const { account } = useAuthState();
  const { hasNoActiveProfile } = useActiveProfile();
  const { isModuleActive } = useModules();
  const { search } = useLocation();

  const isInvalidAdminAccess = (): boolean => !isAdmin() && isAdminRoute && !isInsuranceCompanyRoute;
  const isUserNotAuthenticated = (): boolean => !isAuthenticated() && isPrivate;
  const isModuleActivated = (): boolean => (module ? !isModuleActive(module) : false);
  const isUserSelectingProfile = (): boolean => path === '/select-profile';
  const isEmailConfirmationsPage = (): boolean =>
    path === '/email-confirmation' || path === '/email-confirmation/check-email';
  const isUserAlreadyLoggedIn = (): boolean =>
    (path === '/login' || path === '/email-confirmation' || path === '/email-confirmation/check-email') &&
    isAuthenticated();
  const isUserNotConfirmedEmail = (): boolean => !isEmailConfirmed() && !account.linkAuthenticated && isPrivate;

  const isTokenValidationPageNotHasToken = (): boolean => {
    const query = new URLSearchParams(search);
    return !query.get('token') && isTokenValidationPagePrivate;
  };

  if (isUserSelectingProfile()) {
    if (hasNoActiveProfile()) {
      return <Route component={Component} {...rest} />;
    }

    if (!hasNoActiveProfile()) {
      return <Redirect to="/" />;
    }
  }

  if (isUserNotConfirmedEmail() && isEmailConfirmationsPage()) {
    return <Route component={Component} {...rest} />;
  }

  if (isUserNotAuthenticated() || isTokenValidationPageNotHasToken() || isModuleActivated()) {
    return <Redirect to="/login" />;
  }

  if (isInvalidAdminAccess() || isUserAlreadyLoggedIn()) {
    return <Redirect to="/" />;
  }

  return <Route component={Component} {...rest} />;
};

export default CustomRoute;
