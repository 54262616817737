export const inspectionStatusFinished = {
  WITHIN_24_HOURS: {
    color: '#62B87F',
    background: '#62B87F1A',
    label: 'Dentro de 24h',
  },
  WITHIN_48_HOURS: {
    color: '#F67228',
    background: '#F672281A',
    label: 'Entre 24h e 48h',
  },
  MORE_THAN_48_HOURS: {
    color: '#EE5D50',
    background: '#EE5D501A',
    label: 'Acima de 48h',
  },
};

export const inspectionStatusCanceled = {
  ALREADY_FINISHED: {
    color: '#62B87F',
    background: '#62B87F1A',
    label: 'Realizado por outro canal',
  },
  CANCELED_BY_INSURANCE: {
    color: '#EE5D50',
    background: '#EE5D501A',
    label: 'Cancelado pela seguradora',
  },
  FAILED_TO_SEND_IMAGES: {
    color: '#F67228',
    background: '#F672281A',
    label: 'Falha nas imagens',
  },
  ABSENT_VEHICLE: {
    color: '#ECB21E',
    background: '#ECB21E1A',
    label: 'Veículo Ausente',
  },
  INSPECTION_EXPIRED: {
    color: '#8F7EF9',
    background: '##8F7EF91A',
    label: 'Expiradas',
  },
  OTHERS: {
    color: '#0E86CA',
    background: '#0E86CA1A',
    label: 'Outros',
  },
};
