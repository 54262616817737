/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TabPanel as TabPanelBase } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DocumentService from 'services/DocumentService/documentService';
import { Photos } from 'pages/SchedulerList/components/Photos';
import Maps from 'pages/SchedulerList/components/Maps/Maps';
import { Documents } from 'pages/SchedulerList/components/Documents';

import DateService from 'services/DateService/dateService';
import PhotoService from 'services/PhotoService/photoService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import GeolocationService from 'services/GeolocationService/geolocationService';
import {
  IDocument,
  IPhoto,
  IStatus,
  NotificationSenderEnum,
  StatusEnum,
} from 'pages/SchedulerList/components/Inspection/Inspection.types';
import CheckIconConclusion from 'assets/svg/check-icon-conclusion.svg';
import ConclusionCard from 'pages/SchedulerList/components/History/components/ConclusionCard/ConclusionCard';
import EmptyConclusion from 'pages/SchedulerList/components/History/components/EmptyConclusion/EmptyConclusion';
import { ICompanyConclusionTypes } from 'services/ApiService/BudgetService/BudgetService.types';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import {
  HistoryWrapper,
  Title,
  TitleCard,
  CardWrapper,
  Header,
  Subtitle,
  DateCard,
  Body,
  ActionsWrapper,
  ColumnLeft,
  ColumnRight,
  InformationWrapper,
  InformationDetail,
  CardTabWrapper,
  TabsListBase,
  TabsBase,
  TabBase,
  TitleWithIcon,
} from './History.styles';
import { Button } from '../../../../components/Button';

import {
  HistoryType,
  CardType,
  InformationCardType,
  InformationDetailType,
  IHistoryCardsProps,
  IGeolocationCard,
  CardTabType,
} from './History.types';

export const History = React.memo(
  ({ inspection }: HistoryType) => {
    const { status, informations, photos, inspectionNotificationSender, conclusion } = inspection;
    const [linkSentSender, setLinkSentSender] = useState<string>('');
    const [linkSentDate, setLinkSentDate] = useState<string>('');
    const [isLinkSentSenderVisible, setIsLinkSenderVisible] = useState<boolean>(false);
    const [isConclusionCompanyLoading, setIsConclusionCompanyLoading] = useState<boolean>(false);
    const [companyConclusionTypes, setCompanyConclusionTypes] = useState<ICompanyConclusionTypes>();
    const isStatusEmpty = (): boolean => status?.length <= 0;

    const isExistInformations = (): boolean => Array.isArray(informations) && informations.length > 0;
    const haveCompanyConclusionTypes = companyConclusionTypes !== undefined;
    const checkStatusExist = (statusType: number): boolean => {
      return status.some(({ type }: any) => type === statusType);
    };

    const checkLinkSentSender = (linkSentOriginType: NotificationSenderEnum): void => {
      if (
        linkSentOriginType === NotificationSenderEnum.WHATSAPP ||
        linkSentOriginType === NotificationSenderEnum.ZENVIA_WHATSAPP
      ) {
        setLinkSentSender('WhatsApp');
      } else {
        setLinkSentSender('SMS');
      }
    };

    const putLinkSentInformation = useCallback(
      (linkSentStatus: IStatus): void => {
        const sentMessage = inspectionNotificationSender?.sentMessagesHistory.find(
          (sentMessages: any) => sentMessages.statusId === linkSentStatus.id
        );
        setIsLinkSenderVisible(!!sentMessage);
        !!sentMessage && checkLinkSentSender(sentMessage?.notificationSender.type);
        setLinkSentDate(sentMessage ? sentMessage?.updatedAt : '');
      },
      [inspectionNotificationSender]
    );

    const defaulLinkSentInformation = (): void => {
      setLinkSentSender('');
      setLinkSentDate('');
      setIsLinkSenderVisible(false);
    };

    const handleLinkSent = useCallback((): void => {
      const linkSentStatus = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.LINK_SENT);
      linkSentStatus ? putLinkSentInformation(linkSentStatus) : defaulLinkSentInformation();
    }, [inspection, putLinkSentInformation]);

    const titleWithIcon = (title: string, icon: React.ReactNode): React.ReactNode => (
      <TitleWithIcon>
        {icon} {title}
      </TitleWithIcon>
    );

    const conclusionExist = conclusion !== null && conclusion !== undefined;

    const getCompanyConclusionTypes = useCallback(async () => {
      try {
        setIsConclusionCompanyLoading(true);
        const response = await BudgetService.getCompanyConclusionTypes(inspection?.companyId || '');
        setCompanyConclusionTypes(response);
      } catch {
        setCompanyConclusionTypes(undefined);
        setIsConclusionCompanyLoading(false);
      } finally {
        setIsConclusionCompanyLoading(false);
      }
    }, [inspection]);

    useEffect(() => {
      getCompanyConclusionTypes();
    }, [getCompanyConclusionTypes]);

    useEffect(() => {
      handleLinkSent();
    }, [handleLinkSent, status, inspectionNotificationSender]);

    return (
      <HistoryWrapper data-testid="history">
        {isExistInformations() && <InformationCard title="Detalhes do sinistro" informations={informations || []} />}

        {checkStatusExist(StatusEnum.PHOTO_RECEIVED) && GeolocationService.existCoordinatesInPhotos(photos) && (
          <GeolocationCard photos={photos} />
        )}

        {checkStatusExist(StatusEnum.INSPECTION_STARTED) && (
          <Card
            title={titleWithIcon('Conclusão do atendimento', <img src={CheckIconConclusion} alt="icon-check" />)}
            testID="conclusion-card"
          >
            <>
              {conclusionExist ? (
                <ConclusionCard conclusion={conclusion} inspectionAuthenticated={true} />
              ) : (
                <EmptyConclusion
                  haveCompanyConclusionTypes={haveCompanyConclusionTypes}
                  isLoadingData={isConclusionCompanyLoading}
                  inspectionAuthenticated={true}
                />
              )}
            </>
          </Card>
        )}

        <Title>Histórico de atividades</Title>

        {isStatusEmpty() ? (
          <div data-testid="empty-message">
            <span>Nenhuma atividade foi registrada</span>
          </div>
        ) : (
          <HistoryCards
            inspection={inspection}
            checkStatusExist={checkStatusExist}
            isLinkSentSenderVisible={isLinkSentSenderVisible}
            linkSentDate={linkSentDate}
            linkSentSender={linkSentSender}
          />
        )}
      </HistoryWrapper>
    );
  },
  () => true
);

const HistoryCards = ({
  inspection,
  checkStatusExist,
  isLinkSentSenderVisible,
  linkSentDate,
  linkSentSender,
}: IHistoryCardsProps): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);
  const { customer, schedule } = inspection;
  const tabTitles = ['Fotos recebidas', 'Documentos / Orçamentos'];

  const statusDefault = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.DEFAULT);
  const statusLinkSent = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.LINK_SENT);
  const statusPhotoReceived = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.PHOTO_RECEIVED);
  const statusStarted = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_STARTED);
  const statusFinished = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_FINISHED);
  const statusCanceled = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_CANCELED);
  const statusExpired = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_EXPIRED);

  const [inspectionPhotos] = useState<IPhoto[]>(inspection.photos);
  const [inspectionDocuments] = useState<IDocument[] | undefined>(inspection?.files);

  const downloadPhotos = async (): Promise<void> => {
    try {
      if (!inspection) return;

      await PhotoService.downloadFileZip(inspectionPhotos, inspection.code);
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao baixar as fotos, tente novamente.');
    }
  };

  const downloadDocuments = async (documents: IDocument[]): Promise<void> => {
    try {
      if (!inspection) return;

      await DocumentService.downloadFileZip(documents, inspection.code);
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao baixar os documentos, tente novamente.');
    }
  };

  const isCanceledByMissingVehicle = (): boolean => {
    return (
      checkStatusExist(StatusEnum.INSPECTION_CANCELED) &&
      inspection?.cancellation?.cancellationMotive?.motive === 'Veículo Ausente'
    );
  };

  const buildCancellationStatus = () => {
    return (
      inspection?.cancellation?.description || inspection?.cancellation?.cancellationMotive?.motive || 'Não informado'
    );
  };

  return (
    <>
      {checkStatusExist(StatusEnum.INSPECTION_EXPIRED) && (
        <Card title="Atendimento expirado" status={statusExpired} testID="inspection-expired">
          <Subtitle>O atendimento foi expirado</Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.INSPECTION_CANCELED) && (
        <Card title="Atendimento cancelado" status={statusCanceled} testID="inspection-canceled">
          <Subtitle>
            O motivo do cancelamento foi <b>{buildCancellationStatus()}</b>
          </Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.INSPECTION_FINISHED) && (
        <Card title="Atendimento realizado" status={statusFinished} testID="inspection-finished">
          <Subtitle>O atendimento foi concluído.</Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.INSPECTION_STARTED) && !checkStatusExist(StatusEnum.INSPECTION_FINISHED) && (
        <Card title="Realizando atendimento" status={statusStarted} testID="inspection-progress">
          <Subtitle>O atendimento está sendo realizado...</Subtitle>
        </Card>
      )}
      {(checkStatusExist(StatusEnum.PHOTO_RECEIVED) || isCanceledByMissingVehicle()) && (
        <>
          <TabsBase selectedIndex={tabValue} onSelect={(index) => setTabValue(index)}>
            <TabsListBase>
              {tabTitles.map((title, index) => {
                return (
                  <TabBase key={index} data-testid={`tab-${index}`}>
                    {title}
                  </TabBase>
                );
              })}
            </TabsListBase>
            <CardTab status={statusPhotoReceived} testID="card-tab">
              <TabPanelBase value={tabValue} data-testid="tab-photo">
                <Photos
                  isOrganizingPhotos={false}
                  selectSwappPhoto={() => inspectionPhotos[0]}
                  canSelectSwappPhotos={() => false}
                  isPhotoSelected={() => false}
                  photos={inspectionPhotos}
                  testID="photos-id"
                  company={inspection.company}
                />
                <ActionsWrapper>
                  {!!inspectionPhotos.length && (
                    <Button
                      type="button"
                      testID="download-photos-button"
                      text="BAIXAR FOTOS"
                      variant="outlined"
                      onClick={(): Promise<void> => downloadPhotos()}
                      startIcon={<GetAppIcon />}
                    />
                  )}
                </ActionsWrapper>
              </TabPanelBase>
              <TabPanelBase value={tabValue} data-testid="tab-document">
                {inspection.files && inspectionDocuments !== undefined && (
                  <>
                    <Documents documents={inspectionDocuments} testID="documents-id" company={inspection.company} />
                    <ActionsWrapper>
                      {!!inspectionDocuments.length && (
                        <Button
                          type="button"
                          testID="download-documents-button"
                          text="BAIXAR DOCUMENTOS"
                          variant="outlined"
                          onClick={(): Promise<void> => downloadDocuments(inspectionDocuments)}
                          startIcon={<GetAppIcon />}
                        />
                      )}
                    </ActionsWrapper>
                  </>
                )}
              </TabPanelBase>
            </CardTab>
          </TabsBase>
        </>
      )}

      {schedule && (
        <Card title="Agendamento" testID="schedule-date">
          <Subtitle>
            Esse atendimento está agendado para a data <b>{DateService.formatDateAndTime(schedule)}</b>
          </Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.LINK_SENT) && customer?.phone && (
        <Card
          title="Link enviado"
          status={statusLinkSent}
          statusDate={linkSentDate}
          isStatusDateVisible={isLinkSentSenderVisible}
          testID="link-history"
        >
          {isLinkSentSenderVisible ? (
            <Subtitle>
              Mensagem enviada para o destinatário <b>{customer?.contactName}</b> no número <b>{customer?.phone}</b>
              &nbsp;via
              <b> {linkSentSender}</b>
            </Subtitle>
          ) : (
            <Subtitle>
              Mensagem enviada para o destinatário <b>{customer?.contactName}</b> no número <b>{customer?.phone}</b>
            </Subtitle>
          )}
        </Card>
      )}

      {checkStatusExist(StatusEnum.DEFAULT) && (
        <Card title="Atendimento criado" testID="service-started">
          <Subtitle>
            O atendimento <b>{inspection.code}</b> foi criado em
            <b> {DateService.formatDateAndTime(statusDefault.date)}</b>
          </Subtitle>
        </Card>
      )}
    </>
  );
};

const InformationCard = ({ title, informations }: InformationCardType): JSX.Element => {
  const half = Math.ceil(informations.length / 2);
  const firstHalfInspection = informations.slice(0, half);
  const secondHalfInspection = informations.slice(half, informations.length);

  return (
    <Card title={title} testID="inspection-informations">
      <InformationWrapper>
        <ColumnLeft>
          <InformationDetails informations={firstHalfInspection} />
        </ColumnLeft>
        <ColumnRight>
          <InformationDetails informations={secondHalfInspection} />
        </ColumnRight>
      </InformationWrapper>
    </Card>
  );
};

const InformationDetails = ({ informations }: InformationDetailType): JSX.Element => (
  <>
    {informations.map(({ key, value }) => {
      return (
        <InformationDetail key={key}>
          {key} <b>{value}</b>
        </InformationDetail>
      );
    })}
  </>
);

const GeolocationCard = ({ photos }: IGeolocationCard): JSX.Element => {
  const geolocationPhotos = GeolocationService.getGeolocationPhotos(photos);

  return (
    <Card title="Localização" testID="photos-geolocation">
      <Maps geolocationPhotos={geolocationPhotos} />
    </Card>
  );
};

const Card = ({ title, status, statusDate, isStatusDateVisible, children, testID }: CardType): JSX.Element => {
  return (
    <CardWrapper data-testid={testID}>
      <Header>
        {title ? <TitleCard role="history-card">{title}</TitleCard> : <></>}
        {isStatusDateVisible ? (
          <DateCard>{statusDate && DateService.formatDateAndTime(statusDate)}</DateCard>
        ) : (
          <DateCard>{status && DateService.formatDateAndTime(status.date)}</DateCard>
        )}
      </Header>
      <Body>{children}</Body>
    </CardWrapper>
  );
};

const CardTab = ({ status, statusDate, isStatusDateVisible, children, testID }: CardTabType): JSX.Element => {
  return (
    <CardTabWrapper data-testid={testID}>
      <Header>
        {isStatusDateVisible ? (
          <DateCard>{statusDate && DateService.formatDateAndTime(statusDate)}</DateCard>
        ) : (
          <DateCard>{status && DateService.formatDateAndTime(status.date)}</DateCard>
        )}
      </Header>
      <Body>{children}</Body>
    </CardTabWrapper>
  );
};
