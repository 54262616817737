import useSWR from 'swr';
import AccountService from 'services/ApiService/AccountService';
import { AxiosResponse } from 'axios';
import { IAccountGroup } from 'services/ApiService/AccountService/types';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAccountGroupsByClientId = (
  id: string,
  page: number,
  size: number,
  companiesId: string[],
  authoritiesValue: string[],
  query: string
) => {
  const { data, error } = useSWR(
    id ? createAccoutGroupsByClientIdURL(id, page, size, companiesId, authoritiesValue, query) : null,
    async (url) => {
      const accountGroupsResponse = await AccountService.getAccountGroupsByClientId(url);
      return accountGroupsResponse;
    },
    { revalidateOnFocus: false }
  );

  return {
    accountGroupsData: data?.data,
    isError: error,
    totalPages: getTotalPages(data),
    totalCount: getTotalCount(data),
    isLoadingAccountGroups: !data && !error,
  };
};

const createAccoutGroupsByClientIdURL = (
  id: string,
  page: number,
  size: number,
  companiesId: string[],
  authoritiesValue: string[],
  query: string
): string => {
  return `/account-groups/${id}?query=${query}&page=${page}&size=${size}&companiesId=${companiesId.join(
    ','
  )}&authorities=${authoritiesValue.join(',')}`;
};

const getTotalPages = (response: AxiosResponse<IAccountGroup[]> | undefined): number | undefined => {
  const responseData = response;
  return responseData && parseInt(responseData.headers['x-total-pages'], 10);
};

const getTotalCount = (response: AxiosResponse<IAccountGroup[]> | undefined): number | undefined => {
  const responseData = response;
  return responseData && parseInt(responseData.headers['x-total-count'], 10);
};
