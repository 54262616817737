import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { IImageProps } from './Image.types';
import { ImageContainer, ImageComponent } from './Image.styles';

const Image = React.forwardRef<HTMLImageElement, IImageProps>(({ width, height, alt, ...props }, ref) => {
  const [isLoading, setIsLoading] = useState(true);

  function handleLoad() {
    setIsLoading(false);
  }

  return (
    <ImageContainer width={width} height={height}>
      {isLoading && <Skeleton variant="rectangular" width={width} height={height} />}
      <ImageComponent
        alt={alt}
        width={width}
        height={height}
        isLoading={isLoading}
        ref={ref}
        onLoad={() => handleLoad()}
        {...props}
      />
    </ImageContainer>
  );
});
Image.displayName = 'Image';

export { Image };
