import CubeServiceAxios from './index';
import { ICubeService, IPhoto, IDocument, IInvoiceDocument, IPhotoS3 } from './cubeService.types';

const CubeService: ICubeService = {
  uploadPhotoToS3: async (photo: IPhoto) => {
    const { inspectionId, description, name, imageType } = photo;

    const { data } = await CubeServiceAxios.post<IPhotoS3>(`/upload`, photo.image, {
      params: {
        inspectionId,
        description,
        name,
      },
      headers: {
        'Content-Type': imageType,
      },
    });

    return data;
  },

  uploadDocumentToS3: async (document: IDocument) => {
    const { id, description, name, documentType, extension } = document;

    const { data } = await CubeServiceAxios.post('/upload/documents', document.document, {
      params: {
        inspectionId: id,
        description,
        name,
        extension,
      },
      headers: {
        'Content-Type': documentType,
      },
    });

    return data;
  },

  uploadXmlDocumentToS3: async ({ buffer, id, description, name }) => {
    const { data } = await CubeServiceAxios.post('/upload', buffer, {
      params: {
        inspectionId: id,
        description,
        name,
      },
      headers: {
        'Content-Type': 'application/xml',
      },
    });

    return data;
  },

  uploadInvoiceToS3: async (invoice: IInvoiceDocument) => {
    const { id, description, name, documentType, extension } = invoice;

    const { data } = await CubeServiceAxios.post('/upload/invoices', invoice.document, {
      params: {
        inspectionId: id,
        description,
        name,
        extension,
      },
      headers: {
        'Content-Type': documentType,
      },
    });

    return data;
  },
};

export default CubeService;
