import moment from 'moment-timezone';
import { IDateService } from './dateService.types';

const TIME_ZONE = 'America/Sao_Paulo';

const DateService: IDateService = {
  formatDate: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('DD/MM/YYYY');
  },

  formatTime: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('HH:mm:ss');
  },

  formatDateWithoutYear: (date: string, timeZone = TIME_ZONE) => {
    return moment.tz(date, timeZone).locale('pt-br').format('DD/MM');
  },

  formatFileNameDate: () => {
    return moment.tz(DateService.getDateFromToday(), TIME_ZONE).locale('pt-br').format('DD-MM-YYYY');
  },

  formatDateAndTime: (date: string) => {
    return `${DateService.formatDate(date)} às ${DateService.formatTime(date)}`;
  },

  getDateFromToday: () => {
    return moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
  },

  getDateFromTomorrow: () => {
    return moment().utcOffset(0).add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
  },

  getDateFromTodayMoment: () => {
    return moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  },
  getDateFromTomorrowMoment: () => {
    return moment().utcOffset(0).add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  },

  getFinalDateFromToday: (date: string) => {
    return moment(date).utcOffset(0).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString();
  },

  getSubtractDaysFromNow: (day: number) => {
    return moment()
      .subtract(day, 'days')
      .utcOffset(0)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString();
  },

  fromMomentToIsoGreaterThan: (momentGreaterThan: moment.Moment) => {
    return momentGreaterThan.utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
  },

  fromMomentToIsoLessThan: (momentLessThan: moment.Moment) => {
    return momentLessThan.utcOffset(0).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toISOString();
  },

  getSelectPeriodOptions: () => {
    return [
      {
        name: 'Hoje',
        value: DateService.getDateFromToday(),
      },
      {
        name: '24h',
        value: DateService.getSubtractDaysFromNow(1),
      },
      {
        name: '48h',
        value: DateService.getSubtractDaysFromNow(2),
      },
      {
        name: '72h',
        value: DateService.getSubtractDaysFromNow(3),
      },
    ];
  },

  formatDateToPTBR: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('DD/MM/YYYY [às] HH:mm');
  },

  getHourAndMinuteByMilliseconds: (milliseconds: string): string => {
    const millisecondsNumber = Number(milliseconds);

    const duration = moment.duration(millisecondsNumber);

    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    const minutes = String(Math.floor(duration.minutes())).padStart(2, '0');

    return `${hours}:${minutes}`;
  },

  formatDateAndHourToPTBRWithDot: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('DD/MM/YYYY - HH:mm');
  },

  formatPeriod: (startDate: string, endDate: string) => {
    return `${DateService.formatDate(startDate)} a ${DateService.formatDate(endDate)}`;
  },

  getDay: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('DD');
  },

  getMonthName: (date: string) => {
    const monthName = moment.tz(date, TIME_ZONE).locale('pt-br').format('MMMM');
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  },

  getMonthNameAcronym: (date: string) => {
    const monthName = moment.tz(date, TIME_ZONE).locale('pt-br').format('MMM');
    return monthName.toUpperCase();
  },

  getMonthAndYearAcronym: (date: string) => {
    return `${DateService.getMonthNameAcronym(date)}/${DateService.getYear(date)}`;
  },

  getYear: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('YYYY');
  },

  getHours: (date: string) => {
    return moment.tz(date, TIME_ZONE).locale('pt-br').format('HH:mm');
  },
};

export default DateService;
