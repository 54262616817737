import styled from 'styled-components';

export const PartsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px;

  h1 {
    font-size: 14px;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #00000017;
  padding: 10px 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const HeaderItem = styled.div`
  display: flex;
  gap: 10px;
`;

export const ServiceInfo = styled.div`
  display: flex;
  gap: 10px;
`;

export const ServiceQuantity = styled.div`
  font-weight: 600;
`;

export const Title = styled.h1<{ normal?: boolean }>`
  font-weight: ${({ normal }) => (normal ? 400 : 700)};
`;

export const Icon = styled.img``;
