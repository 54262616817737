import React from 'react';
import { BackButton } from 'components/BackButton';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { priceFormatter } from 'utils/formatPrice';
import { IVehiclePartPrice } from 'services/ApiService/PartService/partsService.types';
import { IBudgetPartServiceTypeEnum } from 'commom/types/Budget.types';
import {
  HeaderWrapper,
  HeaderPartDescriptionWrapper,
  ServiceWrapper,
  LabelService,
  Separetor,
  SubtitlePart,
  TitlePart,
} from '../PartLaborCost.styles';
import { usePartLaborCostContext } from '../PartLaborCostContext';

export interface IHeaderPartLaborCostProps {
  partPriceSelected: IVehiclePartPrice | null;
  handleChangePartPriceAndIsOpenPartLaborCostDrawer: (
    partPrice: IVehiclePartPrice | null,
    isShowLaborCostDrawer: boolean
  ) => void;
}

export default function Header({
  partPriceSelected,
  handleChangePartPriceAndIsOpenPartLaborCostDrawer,
}: IHeaderPartLaborCostProps) {
  const { serviceType, setServiceType } = usePartLaborCostContext();
  const handleChangeToggleButton = (optionCode: IBudgetPartServiceTypeEnum) => {
    if (optionCode === IBudgetPartServiceTypeEnum.REPLACEMENT) {
      setServiceType({
        code: IBudgetPartServiceTypeEnum.REPLACEMENT,
        description: 'Troca',
      });
    } else {
      setServiceType({
        code: IBudgetPartServiceTypeEnum.REPAIR,
        description: 'Reparo',
      });
    }
  };

  const priceFormatted = priceFormatter.format(partPriceSelected?.price || 0);

  const handleBackButton = () => {
    handleChangePartPriceAndIsOpenPartLaborCostDrawer(null, false);
  };

  return (
    <>
      <BackButton onClick={handleBackButton} />

      <HeaderWrapper>
        <HeaderPartDescriptionWrapper>
          <TitlePart className="title-labor-cost">{partPriceSelected?.description}</TitlePart>
          <SubtitlePart>
            {partPriceSelected?.partNumber}
            <b> • {priceFormatted}</b>
          </SubtitlePart>
        </HeaderPartDescriptionWrapper>

        <ServiceWrapper>
          <LabelService>Serviço</LabelService>
          <ToggleButton
            options={[
              { code: IBudgetPartServiceTypeEnum.REPLACEMENT, title: 'Troca' },
              { code: IBudgetPartServiceTypeEnum.REPAIR, title: 'Reparo' },
            ]}
            value={serviceType.code}
            onChange={(_, value: string) => handleChangeToggleButton(value as IBudgetPartServiceTypeEnum)}
          />
        </ServiceWrapper>

        <Separetor />
      </HeaderWrapper>
    </>
  );
}
