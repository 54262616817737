import { CanceledInspectionEnum } from 'services/ApiService/MetricsService/metricsService.types';

export interface ICancellationMotiveDialogProps {
  inspectionId: string;
  handleClose: () => void;
  dialogVisibility: boolean;
}

export interface IMotive {
  id: string;
  code: CanceledInspectionEnum;
  motive: string;
  createdAt: string;
  updatedAt: string;
}

export enum MotivesEnum {
  CANCELED_BY_COMPANY = 'Atendimento cancelado pela Seguradora',
  INSPECTION_FINISHED = 'Atendimento realizado por outro canal',
  IAMGES_ERROR = 'Falha nas imagens enviadas',
  OTHERS = 'Outros',
}
