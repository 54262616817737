import React from 'react';
import { Container, Header, QuestionList, Title } from './QuestionGroup.styles';
import { IBudgetReportQuestionGroup } from 'commom/types/BudgetReport.types';
import QuestionInput from './QuestionInput/QuestionInput';
import QuestionGroupInput from './QuestionGroupInput/QuestionGroupInput';

interface IQuestionGroupProps {
  questionGroup: IBudgetReportQuestionGroup;
}

const QuestionGroup = ({ questionGroup }: IQuestionGroupProps) => {
  return (
    <Container>
      <Header>
        <Title>{questionGroup.text}</Title>
        <QuestionGroupInput questionGroupId={questionGroup._id} questions={questionGroup.questions} />
      </Header>

      <QuestionList>
        {questionGroup.questions.map((question) => (
          <QuestionInput key={question._id} questionGroupId={questionGroup._id} question={question} />
        ))}
      </QuestionList>
    </Container>
  );
};

export default QuestionGroup;
