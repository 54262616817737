import React, { ReactNode } from 'react';
import * as Styled from './styles';

interface IGeneralMetricsCardIcon {
  children: ReactNode;
  tooltipText: string;
  color: string;
  placement?: placementType;
}

type placementType =
  | 'top-start'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end';

export const GeneralMetricsCardIcon = ({
  children,
  tooltipText,
  color,
  placement = 'top-start',
}: IGeneralMetricsCardIcon) => {
  return (
    <Styled.Tooltip data-testid="icon-tooltip" title={tooltipText} placement={placement}>
      <Styled.Icon data-testid="icon-card-component" iconcolor={color}>
        {children}
      </Styled.Icon>
    </Styled.Tooltip>
  );
};
