import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const Illustration = styled.img`
  width: 30%;
`;

export const Description = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: #6a758b;
  text-align: center;
  margin: 20px 0 0 0;
`;
