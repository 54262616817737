import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import Delete from 'assets/svg/delete.svg';
import DeleteColored from 'assets/svg/delete-colored.svg';
import DeleteDisabled from 'assets/svg/delete-disabled.svg';

export const IconButtonCustom = styled(IconButton)`
  padding: 0 !important;

  :hover {
    background-color: transparent !important;
  }

  &.Mui-disabled.delete-icon span div {
    background-image: url(${DeleteDisabled});
  }
`;

export const DeleteIcon = styled.div`
  width: 29px;
  height: 28px;
  transition: 0.3s;
  background-image: url(${Delete});

  &:hover {
    background-image: url(${DeleteColored});
  }
`;
