export const handleBase64 = (file: string, fileTypeHint: string) => {
  const array = file.split(',');

  if (array.length > 1) {
    return file;
  }

  let fileType = '';
  const prefix = array[0].slice(0, 4);

  switch (prefix) {
    case '/9j/':
      fileType = 'image/jpeg';
      break;
    case 'JVBE':
      fileType = 'application/pdf';
      break;
    case 'UEsD':
      if (fileTypeHint === 'docx') {
        fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else if (fileTypeHint === 'xlsx') {
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else {
        throw new Error('Unsupported file type without hint');
      }
      break;
    default:
      throw new Error('Unsupported file type');
  }

  return `data:${fileType};base64,${array[0]}`;
};

export const convertToBlob = async (documentUri: string | undefined, type: string) => {
  if (!documentUri) {
    throw new Error('Document undefined');
  }

  const base64 = handleBase64(documentUri, type);

  const response = await fetch(base64);

  return response.blob();
};
