import React, { useState } from 'react';
import { MoreVert } from '@material-ui/icons';
import { Menu } from '@mui/material';
import * as Styles from './PhotoActions.styles';
import { IPhotoActionsProps } from './PhotoActions.types';
import UploadPhotosMenuItem from './components/UploadPhotosMenuItem/UploadPhotosMenuItem';
import DownloadPhotosMenuItem from './components/DownloadPhotosMenuItem/DownloadPhotosMenuItem';

const PhotoActions = ({ inspection }: IPhotoActionsProps) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <Styles.IconButton data-testid="show-more-button" onClick={handleOpenMenu} disableRipple>
        <MoreVert />
      </Styles.IconButton>
      <Menu data-testid="menu" anchorEl={menuAnchor} open={isMenuOpen} onClose={handleCloseMenu}>
        <UploadPhotosMenuItem inspection={inspection} />
        <DownloadPhotosMenuItem inspection={inspection} onCloseMenu={handleCloseMenu} />
      </Menu>
    </>
  );
};

export default PhotoActions;
