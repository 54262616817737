import styled from 'styled-components';

export const TitleWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #6a758b;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

export const TitleCanceled = styled.h1`
  color: #c73c3c;
  margin: 0;
  width: 50%;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

export const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
`;
