import React from 'react';
import BudgetConclusionCard from './BudgetConlusionCard/BudgetConlusionCard';
import { IBudgetConclusion } from 'commom/types/Budget.types';
import conclusionWhiteIcon from 'assets/svg/budget-template/icon-conclusion-white.svg';
import { Container, ConclusionList, ConclusionSection, Header, Title } from './BudgetConclusionsSection.styles';

interface IBudgetConclusionsSectionProps {
  conclusions: IBudgetConclusion[];
}

const BudgetConclusionsSection = ({ conclusions }: IBudgetConclusionsSectionProps) => {
  return (
    <Container>
      <ConclusionSection>
        <Header>
          <img src={conclusionWhiteIcon} alt="clock" width="18" />
          <Title>Conclusão</Title>
        </Header>

        <ConclusionList>
          {conclusions
            .filter((conclusion) => !conclusion.isArchived)
            .map((conclusion) => (
              <BudgetConclusionCard key={conclusion.id} conclusion={conclusion} />
            ))}
        </ConclusionList>
      </ConclusionSection>
    </Container>
  );
};

export default BudgetConclusionsSection;
