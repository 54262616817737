export enum TopTenRegulatorsSorting {
  NAME = 'NAME',
  IN_PROGRESS = 'IN_PROGRESS',
  AT_RISK_OF_DELAY = 'AT_RISK_OF_DELAY',
  NOT_STARTED = 'NOT_STARTED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
}

export interface ITableHeaderCells {
  name: string;
  sortParam: TopTenRegulatorsSorting;
  isAsc: boolean;
  isActive: boolean;
}
