import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Label, Button } from 'components';
import { toast } from 'react-toastify';
import { IOptions } from 'components/Select/Select.types';
import { IEditOperations } from 'services/ApiService/AccountService/types';
import AccountService from 'services/ApiService/AccountService';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import { CompanyLabel } from 'components/CompanyLabel/CompanyLabel';
import { mutateMany } from 'hooks/SWRUtils/SWRUtils';
import ClientService from 'services/ApiService/ClientService';
import { IEditOperationOperationsDialogProps } from './EditOperationDialog.types';
import {
  DialogBase,
  DialogTitle,
  FormWrapper,
  SelectContainer,
  SelectWrapper,
  DialogActions,
  ButtonWrapper,
  LoadingSelectContainer,
} from './EditOperationDialog.styles';

export const EditOperationDialog: React.FC<IEditOperationOperationsDialogProps> = ({
  dialogVisibility,
  accountId,
  handleClose,
  operations,
}) => {
  const [operationsList, setOperationsList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [operationsSelected, setOperationsSelected] = useState<string[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const { clientId } = AccountService.getActiveProfileInLocalStorage();
  const { handleSubmit } = useForm({ mode: 'onBlur' });

  const getOperationId = useCallback((): string[] => {
    return operations.map((op) => op.id);
  }, [operations]);

  const getCompaniesByClientId = useCallback(async (): Promise<ICompany[]> => {
    const { companies } = await ClientService.getClientById(clientId);

    return companies;
  }, [clientId]);

  const getOperations = useCallback(async (): Promise<IOptions[]> => {
    try {
      setLoadingSelect(true);
      const operationsResponse = await getCompaniesByClientId();
      setOperationsSelected(getOperationId());
      return operationToOptions(operationsResponse);
    } catch (error) {
      toast.error('Problema ao carregar as operações, tente novamente.');
      return [];
    } finally {
      setLoadingSelect(false);
    }
  }, [getOperationId, getCompaniesByClientId]);

  const operationToOptions = (operationList: ICompany[]): IOptions[] => {
    return operationList.map(({ id: value, name }) => ({ value, name }));
  };

  const onSubmit = async (): Promise<void> => {
    const formData: IEditOperations = {
      companiesIds: operationsSelected,
      clientId,
      accountId,
    };

    try {
      if (operationsSelected.length > 0) {
        setLoadingSubmit(true);
        await editOperations(formData);
        setLoadingSubmit(false);
      } else {
        throw new Error('Error');
      }
    } catch (error) {
      setLoadingSubmit(false);
      toast.error('Por favor, selecione pelo menos uma operação.');
    }
  };

  const editOperations = async (formData: IEditOperations): Promise<void> => {
    try {
      await AccountService.editOperationsAccountGroups(formData);
      toast.success('Operações editadas com sucesso!');
      updateSWRAccountGroups();
    } catch ({ response: { status } }) {
      if (status === 404) {
        toast.error('Usuário não associado');
      } else {
        toast.error('Erro ao editar operações.');
      }
    }
  };

  const updateSWRAccountGroups = async (): Promise<void> => {
    await mutateMany(`^/account-groups/${clientId}(.*)`);
  };

  const hasOneOperation = (): boolean => {
    return operationsList.length === 1;
  };

  const getOperationName = (): string => {
    return operationsList[0]?.name;
  };

  const disableSubmitButton = (): boolean => {
    return (
      operationsSelected.length === 0 ||
      operationsSelected === null ||
      operationsSelected === undefined ||
      loadingSelect
    );
  };

  const onEnter = async (): Promise<void> => setOperationsList(await getOperations());

  return (
    <DialogBase open={dialogVisibility} onClose={handleClose} TransitionProps={{ onEnter }}>
      <DialogTitle>Editar Operação</DialogTitle>
      <FormWrapper>
        <Form testID="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="select-operations" testID="label-operation">
            Operação
          </Label>

          {((): JSX.Element => {
            if (loadingSelect) {
              return (
                <LoadingSelectContainer>
                  <CircularProgress size={25} style={{ color: '#000' }} data-testid="loading-select" />
                </LoadingSelectContainer>
              );
            }

            if (hasOneOperation()) {
              return <CompanyLabel testID="operation">{getOperationName()}</CompanyLabel>;
            }
            return (
              <SelectContainer>
                <SelectWrapper
                  testID="select-operations"
                  labelId="label-operation"
                  placeholder="Selecione uma ou mais operações"
                  options={operationsList}
                  multiple
                  selectedOptions={operationsSelected}
                  onChange={({ target: { value } }): void => setOperationsSelected(value as string[])}
                />
              </SelectContainer>
            );
          })()}

          <DialogActions>
            <ButtonWrapper>
              <Button
                testID="dialog-button-cancel"
                type="button"
                variant="outlined"
                onClick={handleClose}
                text="CANCELAR"
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                testID="dialog-button-submit"
                type="submit"
                text="FINALIZAR"
                disabled={disableSubmitButton()}
                loading={loadingSubmit}
                loadingSize={25}
                loadingColor="#FFF"
              />
            </ButtonWrapper>
          </DialogActions>
        </Form>
      </FormWrapper>
    </DialogBase>
  );
};
