import React from 'react';
import { Label } from 'components/Label';
import { TextField } from 'components/TextField';
import { Select } from 'components/Select/Select';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import { IPartTypeOption, PartType } from './PartDrawer.types';

interface IManuallyPartInformationProps {
  isEditMode: boolean;
}

const ManuallyPartInformation = ({ isEditMode }: IManuallyPartInformationProps) => {
  const { partName, setPartName, partSku, setPartSku, partType, setPartType } = usePartContext();

  const partTypeOptions: IPartTypeOption[] = [
    { value: PartType.GENUINE, name: 'Genuíno' },
    { value: PartType.ORIGINAL, name: 'Original' },
    { value: PartType.GREEN, name: 'Verde' },
    { value: PartType.OTHERS, name: 'Outros' },
  ];

  return (
    <>
      <Label htmlFor="contactName" testID="label-part-code">
        Código
      </Label>
      <TextField
        testID="code-input"
        ariaLabelledby="code"
        id="code-input"
        disabled={isEditMode === true}
        InputLabelProps={{ shrink: true }}
        value={partSku}
        onChange={({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void =>
          setPartSku(value as string)
        }
        name="code"
        placeholder="Insira o código da peça"
        role="code"
        type="text"
        variant="outlined"
      />

      <Label htmlFor="contactName" testID="label-part-name">
        Nome
      </Label>
      <TextField
        testID="name-input"
        ariaLabelledby="contato"
        id="name-input"
        InputLabelProps={{ shrink: true }}
        value={partName}
        onChange={({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void =>
          setPartName(value as string)
        }
        name="name"
        placeholder="Insira o nome da peça"
        role="name"
        type="text"
        variant="outlined"
      />

      <Label htmlFor="partType" testID="label-part-type">
        Tipo da Peça
      </Label>
      <Select
        testID="part-type"
        options={partTypeOptions}
        selectedOptions={partType}
        label=""
        labelId="regulator"
        onChange={({ target: { value } }): void => setPartType(value as PartType)}
      />
    </>
  );
};

export default ManuallyPartInformation;
