import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import { LinearProgress, TableContainer } from '@mui/material';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import { ITableHeaderCells, TopTenRegulatorsSorting } from './TopTenRegulators.types';
import {
  ITopTenRegulatorsMetrics,
  ITopTenRegulatorsMetricsFilter,
} from 'services/ApiService/MetricsService/metricsService.types';
import {
  Container,
  Content,
  Header,
  ShowDetails,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
  FinishedInspectionPercentageContainer,
  SortingButton,
  TableBodyMessage,
} from './TopTenRegulators.styles';

export function TopTenRegulators() {
  const [loading, setLoading] = useState(false);
  const [topTenRegulators, setTopTenRegulators] = useState<ITopTenRegulatorsMetrics[]>([]);
  const [tableHeaderCells, setTableHeaderCells] = useState<ITableHeaderCells[]>([
    {
      name: 'Em andamento',
      sortParam: TopTenRegulatorsSorting.IN_PROGRESS,
      isAsc: false,
      isActive: false,
    },
    {
      name: 'Risco de atraso',
      sortParam: TopTenRegulatorsSorting.AT_RISK_OF_DELAY,
      isAsc: false,
      isActive: false,
    },
    {
      name: 'Não iniciados',
      sortParam: TopTenRegulatorsSorting.NOT_STARTED,
      isAsc: false,
      isActive: false,
    },
    {
      name: 'Cancelados',
      sortParam: TopTenRegulatorsSorting.CANCELED,
      isAsc: false,
      isActive: false,
    },
    {
      name: 'Finalizados (%)',
      sortParam: TopTenRegulatorsSorting.FINISHED,
      isAsc: false,
      isActive: true,
    },
  ]);
  const {
    categoriesSelected,
    companiesSelected,
    productTypesSelected,
    regulatorSelected,
    startDateSelected,
    endDateSelected,
  } = useMetricsFilter();
  const { activeProfile } = useActiveProfile();

  const isEmptyTopTenRegulators = topTenRegulators.length === 0;
  const getTableHeaderCellActive = tableHeaderCells.find((tableHeaderCell) => tableHeaderCell.isActive);

  const handleToggleSorting = (sortParam: TopTenRegulatorsSorting) => {
    const newTableHeaderCells = tableHeaderCells.map((tableHeaderCell) => {
      if (tableHeaderCell.sortParam === sortParam) {
        return {
          ...tableHeaderCell,
          isAsc: !tableHeaderCell.isAsc,
          isActive: true,
        };
      }

      return {
        ...tableHeaderCell,
        isActive: false,
      };
    });

    setTableHeaderCells(newTableHeaderCells);
  };

  const getTopTenRegulators = async (filter: ITopTenRegulatorsMetricsFilter) => {
    try {
      setLoading(true);
      const { sortedRegulatorsMetricsOutput } = await MetricsService.getTopTenRegulatorsMetrics(filter);

      setTopTenRegulators(sortedRegulatorsMetricsOutput);
    } catch (error) {
      toast.error('Erro ao buscar os dados dos peritos');
      setTopTenRegulators([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const tableHeaderCellActive = getTableHeaderCellActive;

    if (tableHeaderCellActive && activeProfile.clientId && companiesSelected.length > 0) {
      getTopTenRegulators({
        clientId: activeProfile.clientId,
        sortParam: tableHeaderCellActive.sortParam,
        isAsc: tableHeaderCellActive.isAsc,
        categoriesSelected,
        companiesSelected,
        productTypesSelected,
        regulatorSelected,
        startDateSelected,
        endDateSelected,
      });
    }
  }, [
    activeProfile.clientId,
    getTableHeaderCellActive,
    categoriesSelected,
    companiesSelected,
    productTypesSelected,
    regulatorSelected,
    startDateSelected,
    endDateSelected,
  ]);

  return (
    <Container>
      <Header>
        <span>Peritos</span>

        <ShowDetails to="/metrics-regulators-general-details">
          <RemoveRedEyeIcon sx={{ width: '14px' }} />
          Ver detalhes
        </ShowDetails>
      </Header>

      <Content>
        <TableContainer sx={{ maxHeight: 270 }}>
          <Table stickyHeader>
            <TableHead>
              <MuiTableRow>
                <TableHeaderCell align="left" isIndexTop={false}>
                  Nome
                </TableHeaderCell>
                {tableHeaderCells.map((tableHeaderCell) => (
                  <TableHeaderCell
                    align="left"
                    key={tableHeaderCell.sortParam}
                    isIndexTop={tableHeaderCell.sortParam === TopTenRegulatorsSorting.FINISHED}
                  >
                    <SortingButton
                      data-active={tableHeaderCell.isActive}
                      disabled={isEmptyTopTenRegulators}
                      onClick={() => handleToggleSorting(tableHeaderCell.sortParam)}
                    >
                      {tableHeaderCell.name}

                      <>{tableHeaderCell.isAsc ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
                    </SortingButton>
                  </TableHeaderCell>
                ))}
              </MuiTableRow>
            </TableHead>
            {!isEmptyTopTenRegulators && !loading && (
              <TableBody>
                {topTenRegulators.map((regulator) => (
                  <TableBodyRow key={regulator.regulatorName}>
                    <TableBodyCell>{regulator.regulatorName}</TableBodyCell>
                    <TableBodyCell align="left">{regulator.inProgressInspectionsCount}</TableBodyCell>
                    <TableBodyCell align="left">{regulator.atRiskOfDelay}</TableBodyCell>
                    <TableBodyCell align="left">{regulator.notStartedInspectionsCount}</TableBodyCell>
                    <TableBodyCell align="left">{regulator.cancelledInspectionsCount}</TableBodyCell>
                    <TableBodyCell align="left">
                      <FinishedInspectionPercentageContainer>
                        <div>
                          <span>{regulator.finishedInspectionsCount}</span>
                          <span>({regulator.finishedInspectionPercentage.toFixed(2)}%)</span>
                        </div>
                        <LinearProgress variant="determinate" value={regulator.finishedInspectionPercentage} />
                      </FinishedInspectionPercentageContainer>
                    </TableBodyCell>
                  </TableBodyRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {loading && <TableBodyMessage>Carregando...</TableBodyMessage>}
        {isEmptyTopTenRegulators && !loading && <TableBodyMessage>Nenhum perito encontrado</TableBodyMessage>}
      </Content>
    </Container>
  );
}
