import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { IOptions } from 'components/Select/Select.types';
import DateService from 'services/DateService/dateService';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { IAccountGroup } from 'services/ApiService/AccountService/types';
import { IMetricsFilterContext } from './types';

export const MetricsFilterContext = createContext<IMetricsFilterContext>({} as IMetricsFilterContext);

export const MetricsFilterContainer: React.FC = ({ children }) => {
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
  const [productTypesSelected, setProductTypesSelected] = useState<string[]>([]);
  const [regulatorSelected, setRegulatorSelected] = useState<IOptions | null>(null);
  const [startDateSelected, setStartDateSelected] = useState<string>(DateService.getDateFromToday());
  const [endDateSelected, setEndDateSelected] = useState<string>(DateService.getDateFromTomorrow());

  const [companyOptions, setCompanyOptions] = useState<IOptions[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);
  const [productTypeOptions, setProductTypeOptions] = useState<IOptions[]>([]);
  const [regulatorOptions, setRegulatorOptions] = useState<IOptions[]>([]);
  const [periodOptions] = useState<IOptions[]>(DateService.getSelectPeriodOptions());

  const { getAllCompaniesForActiveProfile } = useActiveProfile();

  const findSelectedCompanies = useCallback(() => {
    const allCompaniesForActiveProfile = getAllCompaniesForActiveProfile();
    return allCompaniesForActiveProfile?.filter((company) => companiesSelected.includes(company.id));
  }, [companiesSelected, getAllCompaniesForActiveProfile]);

  const formatAccountGroupsToRegulatorOptions = useCallback((accountGroups: IAccountGroup[]) => {
    const options = accountGroups.map((accountGroup) => ({
      name: accountGroup.account.username,
      value: accountGroup.account.id,
    }));

    setRegulatorOptions(options);
  }, []);

  const updateFilters = useCallback(
    (
      companiesSelectedFilter: string[],
      categoriesSelectedFilter: string[],
      productTypesSelectedFilter: string[],
      regulatorSelectedFilter: IOptions | null,
      startDateSelectedFilter: string,
      endDateSelectedFilter: string
    ) => {
      setCompaniesSelected(companiesSelectedFilter);
      setCategoriesSelected(categoriesSelectedFilter);
      setProductTypesSelected(productTypesSelectedFilter);
      setRegulatorSelected(regulatorSelectedFilter);
      setStartDateSelected(startDateSelectedFilter);
      setEndDateSelected(endDateSelectedFilter);
    },
    []
  );

  const initalizeCompanyOptions = useCallback(() => {
    const companiesForActiveProfile = getAllCompaniesForActiveProfile();

    if (companiesForActiveProfile) {
      const options = companiesForActiveProfile.map((company) => ({
        name: company.name,
        value: company.id,
      }));

      setCompanyOptions(options);
    }
  }, [getAllCompaniesForActiveProfile]);

  useEffect(() => {
    initalizeCompanyOptions();
  }, [initalizeCompanyOptions]);

  useEffect(() => {
    if (companiesSelected.length > 0) {
      const selectedCompanies = findSelectedCompanies();

      const inspectionCategories = selectedCompanies.flatMap((company) => company.inspectionCategories ?? []);

      const filterInspectionCategories = inspectionCategories.filter(
        (inspectionCategory, index, self) =>
          index === self.findIndex((selfInspectionCategory) => selfInspectionCategory.id === inspectionCategory.id)
      );

      const options = filterInspectionCategories.map((inspectionCategory) => ({
        name: inspectionCategory.name,
        value: inspectionCategory.id,
      }));

      setCategoryOptions(options);
    }
  }, [companiesSelected, findSelectedCompanies]);

  useEffect(() => {
    if (companiesSelected.length > 0) {
      const selectedCompanies = findSelectedCompanies();

      const productTypes = selectedCompanies.flatMap((company) => company.productTypes);

      const filterProductTypes = productTypes.filter(
        (productType, index, self) =>
          index === self.findIndex((selfProductType) => selfProductType.id === productType.id)
      );

      const options = filterProductTypes.map((productType) => ({
        name: productType.description,
        value: productType.id,
      }));

      setProductTypeOptions(options);
    }
  }, [companiesSelected, findSelectedCompanies]);

  const metricsFilterState = useMemo(
    () => ({
      companiesSelected,
      categoriesSelected,
      productTypesSelected,
      regulatorSelected,
      startDateSelected,
      endDateSelected,
      companyOptions,
      categoryOptions,
      productTypeOptions,
      regulatorOptions,
      periodOptions,
      formatAccountGroupsToRegulatorOptions,
      setRegulatorSelected,
      setCompaniesSelected,
      updateFilters,
    }),
    [
      companiesSelected,
      categoriesSelected,
      productTypesSelected,
      regulatorSelected,
      startDateSelected,
      endDateSelected,
      companyOptions,
      categoryOptions,
      productTypeOptions,
      regulatorOptions,
      periodOptions,
      formatAccountGroupsToRegulatorOptions,
      setRegulatorSelected,
      setCompaniesSelected,
      updateFilters,
    ]
  );

  return <MetricsFilterContext.Provider value={metricsFilterState}>{children}</MetricsFilterContext.Provider>;
};
