import React from 'react';
import DateService from 'services/DateService/dateService';
import { IConclusionProps } from './Conclusion.types';
import * as S from './Conclusion.styles';

const Conclusion = ({
  conclusionReason,
  conclusionType,
  createdByUser,
  dateCreated,
  comment,
  testId = 'conclusion-component',
  isFocused = false,
}: IConclusionProps) => {
  const formatDate = (date: string) => {
    return DateService.formatDateToPTBR(date);
  };

  const infoText = [
    {
      title: 'Perito:',
      value: createdByUser,
      testId: 'created-by-user',
    },
    {
      title: 'Tipo de conclusão:',
      value: conclusionType,
      testId: 'conclusion-type',
    },
    {
      title: 'Motivo:',
      value: conclusionReason,
      testId: 'conclusion-reason',
    },
    {
      title: 'Data:',
      value: formatDate(dateCreated),
      testId: 'date-created',
    },
  ];

  return (
    <S.Container isFocused={isFocused} data-testid={testId}>
      <S.InfoContent>
        {infoText.map((info, index) => (
          <S.InfoTextContent key={index} data-testid="info-text-content">
            <S.InfoText isBold={true}>{info.title}</S.InfoText>
            <S.InfoText data-testid={info.testId}>{info.value}</S.InfoText>
          </S.InfoTextContent>
        ))}
      </S.InfoContent>
      <S.Divider />
      <S.CommentContent>
        <S.Comment data-testid="comment-text">{comment}</S.Comment>
      </S.CommentContent>
    </S.Container>
  );
};

export default Conclusion;
