import { GeneralMetricsCardRoot } from './Root';
import { GeneralMetricsCardTitle } from './Title';
import { GeneralMetricsCardData } from './Data';
import { GeneralMetricsCardIcon } from './Icon';

export const GeneralMetricsCard = {
  Root: GeneralMetricsCardRoot,
  Title: GeneralMetricsCardTitle,
  Data: GeneralMetricsCardData,
  Icon: GeneralMetricsCardIcon,
};
