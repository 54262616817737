import React, { useState } from 'react';
import { AnchorTypesEnum, Drawer } from 'components/Drawer';
import { Button, Label } from 'components';
import NumericFormat from 'react-number-format';
import { Separator } from 'components/Drawer/VehicleDrawer/VehicleDrawer.styles';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { IEditOutsourcedServiceDrawerProps } from './EditOutsourcedServiceDrawer.types';
import { ActionsContainer, DrawerContent, LabelNameEdit, TextField } from './EditOutsourcedServiceDrawer.styles';

const EditOutsourcedServiceDrawer = ({ open, onClose, outsourcedService }: IEditOutsourcedServiceDrawerProps) => {
  const [price, setPrice] = useState<number | undefined>(outsourcedService.price);
  const [loading, setLoading] = useState<boolean>(false);
  const budgetId = useAppSelector((state) => state.presentBudget.budget._id);
  const dispatch = useAppDispatch();

  const disableSaveButton = !price || price === outsourcedService.price;

  const handleEditOutsourcedService = async () => {
    try {
      setLoading(true);
      const budget = await BudgetService.updateExternalService({
        budgetId,
        price: price || 0,
        serviceId: outsourcedService.id,
      });
      dispatch(setBudget(budget));
      setLoading(false);
      onClose();
    } catch (error) {
      toast.error('Erro ao editar serviço');
      onClose();
    }
  };

  return (
    <>
      <Drawer.Root anchor={AnchorTypesEnum.RIGHT} openDrawer={open} onClose={onClose}>
        <Drawer.Header>
          <Drawer.Title>Editar Serviço</Drawer.Title>
        </Drawer.Header>
        <DrawerContent>
          <div>
            <Label htmlFor="name" testID="label-name">
              Nome
            </Label>
            <LabelNameEdit>{outsourcedService.name}</LabelNameEdit>
          </div>

          <div>
            <Label htmlFor="cost" testID="label-cost">
              Valor
            </Label>
            <NumericFormat
              customInput={TextField}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              value={price}
              prefix="R$ "
              allowNegative={false}
              allowLeadingZeros={false}
              isNumericString
              placeholder="Insira o valor do serviço"
              onValueChange={({ floatValue }) => setPrice(floatValue)}
              inputProps={{ 'data-testid': 'price-input' }}
              {...{ variant: 'outlined', role: 'price', name: 'price', id: 'price' }}
            />
          </div>
        </DrawerContent>
        <Separator />
        <ActionsContainer>
          <Button testID="cancel-edit-button" onClick={onClose} variant="outlined" text="Cancelar" />
          <Button
            testID="edit-button"
            size="large"
            text="Editar"
            loading={loading}
            onClick={handleEditOutsourcedService}
            disabled={disableSaveButton}
          />
        </ActionsContainer>
      </Drawer.Root>
    </>
  );
};

export default EditOutsourcedServiceDrawer;
