import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'hooks/hooks';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel/TabPanel';
import { TabsActions } from '@material-ui/core';
import * as S from './ConclusionList.styles';
import ConclusionTab from './ConclusionTab/ConclusionTab';

export enum ACTIVE_TAB_ENUM {
  TAB_CONCLUSIONS = 'tab-conclusions',
  TAB_ARCHIVED_CONCLUSIONS = 'tab-archived-conclusions',
}

const ConclusionList = () => {
  const [activeTab, setActiveTab] = useState<ACTIVE_TAB_ENUM>(ACTIVE_TAB_ENUM.TAB_CONCLUSIONS);
  const conclusionsStore = useAppSelector((state) => state.presentBudget.budget.conclusions);
  const schedulerListMenuIsOpen = useAppSelector((state) => state.schedulerListMenu.isOpen);
  const headerDetailsRef = useRef<HTMLDivElement | null>(null);
  const tabsActionsRef = useRef<TabsActions | null>(null);

  const conclusions = conclusionsStore?.filter((conclusion) => !conclusion.isArchived);

  const archivedConclusions = conclusionsStore?.filter((conclusion) => conclusion.isArchived);

  const handleTabsChange = (_: React.SyntheticEvent<Element, Event>, newValue: ACTIVE_TAB_ENUM) => {
    setActiveTab(newValue);
  };

  const updateTabIndicator = useCallback(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (tabsActionsRef.current && headerDetailsRef.current) {
        tabsActionsRef.current.updateIndicator();
      }
    });

    if (headerDetailsRef.current) {
      resizeObserver.observe(headerDetailsRef.current);
    }
  }, []);

  useEffect(() => {
    updateTabIndicator();
  }, [updateTabIndicator, schedulerListMenuIsOpen]);

  return (
    <S.ConclusionListContainer ref={headerDetailsRef}>
      <S.TabsBase value={activeTab} onChange={handleTabsChange} action={tabsActionsRef}>
        <Tab disableRipple label="Conclusões" value={ACTIVE_TAB_ENUM.TAB_CONCLUSIONS} />
        <Tab disableRipple label="Arquivadas" value={ACTIVE_TAB_ENUM.TAB_ARCHIVED_CONCLUSIONS} />
      </S.TabsBase>
      {conclusions && archivedConclusions && (
        <S.CardBody>
          <TabPanel value={activeTab} tabName={ACTIVE_TAB_ENUM.TAB_CONCLUSIONS}>
            <ConclusionTab conclusions={conclusions} />
          </TabPanel>

          <TabPanel value={activeTab} tabName={ACTIVE_TAB_ENUM.TAB_ARCHIVED_CONCLUSIONS}>
            <ConclusionTab isArchivedConclusionTab conclusions={archivedConclusions} />
          </TabPanel>
        </S.CardBody>
      )}
    </S.ConclusionListContainer>
  );
};

export default ConclusionList;
