import { getToken } from 'firebase/messaging';
import { IFirebaseService } from './firebaseService.types';
import { messaging } from 'firebaseConfig';

const FirebaseService: IFirebaseService = {
  getTokenInFirebase: async () => {
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
  },
};

export default FirebaseService;
