import { useAuthState } from 'contexts/auth/useAuth';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { IAddPartRequest } from 'services/ApiService/BudgetService/BudgetService.types';
import { selectBudgetId } from 'stores/presentBudget/presentBudget.selector';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { AnchorTypesEnum, Drawer } from '..';
import { PartDrawer } from '../PartDrawer';
import { PartTypeDescription } from '../PartDrawer/PartDrawer.types';
import { DrawerWrapper } from './AddManuallyPartDrawer.styles';
import { IAddManuallyPartDrawerProps } from './AddManuallyPartDrawer.types';

const AddManuallyPartDrawer = ({ openDrawer, onClose }: IAddManuallyPartDrawerProps) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const dispatch = useAppDispatch();
  const budgetId = useAppSelector(selectBudgetId);
  const { account } = useAuthState();
  const {
    serviceType,
    partName,
    partDiscount,
    partOriginalServiceHours,
    partServiceHours,
    partType,
    quantityParts,
    partSku,
    isServiceTypeRepair,
    partOriginalPrice,
  } = usePartContext();

  const onSubmit = async () => {
    try {
      setIsLoadingSubmit(true);
      const partToAdd: IAddPartRequest = {
        user: account.id,
        name: partName,
        sku: partSku,
        quantity: quantityParts,
        originalPrice: partOriginalPrice,
        discount: partDiscount,
        isManualEntry: true,
        serviceType,
        partSupplier: {
          code: 'OFFICE',
          description: 'Oficina',
        },
        sourceType: {
          code: partType,
          description: PartTypeDescription[partType],
        },
        originalServiceHours: partOriginalServiceHours,
        serviceHours: {
          removeAndInstall: partServiceHours.removeAndInstall,
          repair: isServiceTypeRepair ? partServiceHours.repair : 0,
          painting: partServiceHours.painting,
        },
      };
      const budget = await BudgetService.addPart(partToAdd, budgetId);

      dispatch(setBudget(budget));
      setIsLoadingSubmit(false);
      onClose();
    } catch (error) {
      toast.error('Erro ao adicionar peça');
      setIsLoadingSubmit(false);
    }
  };

  return (
    <Drawer.Root openDrawer={openDrawer} anchor={AnchorTypesEnum.RIGHT} onClose={onClose}>
      <DrawerWrapper>
        <PartDrawer.Header handleBackButton={onClose} />
        <PartDrawer.Content />
        <PartDrawer.Footer onSubmit={onSubmit} handleClose={onClose} loadingSubmit={isLoadingSubmit} />
      </DrawerWrapper>
    </Drawer.Root>
  );
};

export default AddManuallyPartDrawer;
