import React from 'react';
import { Checkbox as CheckboxMaterial } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import { ICheckboxProps } from './Checkbox.types';

export const Checkbox: React.FC<ICheckboxProps> = (
  { testId = 'checkbox', checked, onChange, checkBoxColor, disabled = false },
  props
) => {
  const DefaultCheckbox = withStyles({
    root: {
      color: checkBoxColor ? checkBoxColor : common.black,
      '&$checked': {
        color: common.black,
      },
    },
    checked: {},
  })((innerProps) => (
    <CheckboxMaterial
      data-testid={testId}
      color="default"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      {...innerProps}
    />
  ));

  return <DefaultCheckbox {...props} />;
};
