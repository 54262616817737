import React from 'react';
import { Container } from './DamageReportFormSkeleton.styles';
import DamageQuestionGroupsSkeleton from '../DamageQuestionGroupsSkeleton/DamageQuestionGroupsSkeleton';

const DamageReportFormSkeleton = () => {
  return (
    <Container data-testid="damage-report-form-skeleton">
      <DamageQuestionGroupsSkeleton />
      <DamageQuestionGroupsSkeleton />
    </Container>
  );
};

export default DamageReportFormSkeleton;
