import React from 'react';
import * as Styled from './TableRow.styles';
import { IMetricsTableRowProps } from './TableRow.types';

export const MetricsTableRow = ({
  row: {
    code,
    company,
    finishedDate,
    createdDate,
    startedDate,
    regulator,
    inspectionTypeName,
    productTypeName,
    cancelledReason,
    testID = 'metrics-table-row',
    averageTime,
  },
  showCancelledReason = false,
  showTime = false,
}: IMetricsTableRowProps) => {
  const isEmpty = (data: string | undefined) => {
    return data || '';
  };

  return (
    <Styled.TableRow data-testid={testID} key={code}>
      {showTime && <Styled.TableCellSticky align="left">{isEmpty(averageTime)}</Styled.TableCellSticky>}

      <Styled.TableCell align="left">{isEmpty(code)}</Styled.TableCell>
      <Styled.TableCell align="left">{isEmpty(regulator)}</Styled.TableCell>
      <Styled.TableCell align="left">{isEmpty(inspectionTypeName)}</Styled.TableCell>
      <Styled.TableCell align="left">{isEmpty(company)}</Styled.TableCell>
      <Styled.TableCell align="left">{isEmpty(productTypeName)}</Styled.TableCell>
      {showCancelledReason && <Styled.TableCell align="left">{isEmpty(cancelledReason)}</Styled.TableCell>}

      <Styled.TableCell align="left">{createdDate}</Styled.TableCell>
      <Styled.TableCell align="left">{startedDate}</Styled.TableCell>
      <Styled.TableCell align="left">{finishedDate}</Styled.TableCell>
    </Styled.TableRow>
  );
};
