import { IProductTypeService, ProductTypeCodeEnum } from './productTypeService.types';

const ProductTypeService: IProductTypeService = {
  isAuto: (productTypeCode) => {
    return productTypeCode === ProductTypeCodeEnum.AUTO;
  },
  isResidential: (productTypeCode) => {
    return productTypeCode === ProductTypeCodeEnum.RESIDENTIAL;
  },
  isOthers: (productTypeCode) => {
    return productTypeCode === ProductTypeCodeEnum.OTHERS;
  },
};

export default ProductTypeService;
