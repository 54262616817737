import React, { ReactNode } from 'react';
import * as Styled from './styles';

interface IGeneralMetricsCardData {
  children: ReactNode;
  color: string;
}

export const GeneralMetricsCardData = ({ children, color }: IGeneralMetricsCardData) => {
  return (
    <Styled.Data data-testid="card-data-component" color={color}>
      {children}
    </Styled.Data>
  );
};
