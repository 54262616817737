import ApiService from 'services/ApiService';

import { IInspection, IStatus, StatusEnum } from '../../../pages/SchedulerList/components/Inspection/Inspection.types';
import { IGetStatus, IInspectionStatusService } from './InspectionStatusService.types';

const InspectionStatusService: IInspectionStatusService = {
  getStatus: async (): Promise<IStatus[]> => {
    const {
      data: { status: statusResponse },
    } = await ApiService.get<IGetStatus>('/status');

    return statusResponse;
  },

  linkSent: async (inspectionId) => {
    await ApiService.put<IInspection>(`/status/link-send/${inspectionId}`);
  },

  started: async (inspectionId) => {
    await ApiService.put<IInspection>(`/status/started/${inspectionId}`);
  },

  finished: async (inspectionId) => {
    await ApiService.put<IInspection>(`/status/finished/${inspectionId}`);
  },

  cancel: async (inspectionId) => {
    await ApiService.put<IInspection>(`/status/canceled/${inspectionId}`);
  },

  photosReceived: async (inspectionId) => {
    await ApiService.put<IInspection>(`/status/photos-received/${inspectionId}`);
  },

  getCurrentStatus: (status: IStatus[]) => status[status.length - 1],

  getStatusByType: (status: IStatus[], statusType: number) => {
    return status.find((current) => current.type === statusType) || ({} as IStatus);
  },

  isCanceled: (status: IStatus[]) => {
    return status.some((current) => current.type === StatusEnum.INSPECTION_CANCELED);
  },

  isExpired: (status: IStatus[]) => {
    return status.some((current) => current.type === StatusEnum.INSPECTION_EXPIRED);
  },

  isCreatedOrSent: (currentStatus: IStatus) => {
    return currentStatus.type === StatusEnum.DEFAULT || currentStatus.type === StatusEnum.LINK_SENT;
  },

  isInspectionCreated: (currentStatus: IStatus) => {
    return currentStatus.type === StatusEnum.DEFAULT;
  },

  isPhotosReceived: (currentStatus: IStatus) => {
    return currentStatus.type === StatusEnum.PHOTO_RECEIVED;
  },

  hasPhotosReceived: (currentStatus: IStatus) => {
    return (
      currentStatus.type === StatusEnum.PHOTO_RECEIVED ||
      currentStatus.type === StatusEnum.INSPECTION_STARTED ||
      currentStatus.type === StatusEnum.INSPECTION_FINISHED
    );
  },

  isInspectionStarted: (currentStatus: IStatus) => {
    return currentStatus.type === StatusEnum.INSPECTION_STARTED;
  },

  isInspectionFinished: (currentStatus: IStatus) => {
    return currentStatus.type === StatusEnum.INSPECTION_FINISHED;
  },

  isCurrentStatus: (currentStatus: IStatus, statusType: number) => {
    return currentStatus.type === statusType;
  },

  isNotExpiredOrCanceled: (type: StatusEnum) => {
    return type !== StatusEnum.INSPECTION_EXPIRED && type !== StatusEnum.INSPECTION_CANCELED;
  },
};

export default InspectionStatusService;
