import React from 'react';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';
import CompanyService from 'services/ApiService/CompanyService/companyService';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import { IConfirmDialogCSV } from './ConfirmDialogCSV.types';
import { DialogText } from './ConfirmDialogCSV.styles';

export const ConfirmDialogCSV: React.FC<IConfirmDialogCSV> = ({
  companiesByActiveConsumer,
  onSubmitDownloadCSVDialog,
  confirmDialogOpen,
  closeCSVDialog,
  testId,
}: IConfirmDialogCSV) => {
  const getOperationText = (companyConsumer: ICompany[]) => {
    return companyConsumer.length > 1 ? 'das operações' : 'da operação';
  };

  const getCompanyNames = (companyConsumer: ICompany[]) => {
    return companyConsumer.map((company) => company.name).join(', ');
  };

  const getAdditionalText = (companyConsumer: ICompany[]) => {
    return companyConsumer.length > 1
      ? 'Se você tiver selecionado outras além dessas operações, elas não serão incluídas.'
      : 'Se você tiver selecionado outras além desta operação, elas não serão incluídas.';
  };

  const setDialogText = () => {
    const companyConsumer = CompanyService.getCompanyByActiveConsumer(companiesByActiveConsumer);
    const operationText = getOperationText(companyConsumer);
    const companyNames = getCompanyNames(companyConsumer);
    const additionalText = getAdditionalText(companyConsumer);

    return (
      <>
        "Atenção! O arquivo CSV gerado incluirá apenas os atendimentos" {operationText}
        <b> {companyNames}</b>. {additionalText}
        Você deseja continuar?
      </>
    );
  };

  return (
    <ConfirmDialog
      dialogVisibility={confirmDialogOpen}
      handleClose={closeCSVDialog}
      testID={testId}
      onSubmit={onSubmitDownloadCSVDialog}
      title="Deseja baixar o arquivo CSV?"
      subtitle={<DialogText>{setDialogText()}</DialogText>}
    />
  );
};
