export interface ITableInvoiceHeaderCells {
  name: string;
  align?: 'left' | 'right' | 'center';
}

export const tableInvoiceHeader: ITableInvoiceHeaderCells[] = [
  {
    name: 'Cliente',
    align: 'left',
  },
  {
    name: 'Criado em',
    align: 'left',
  },
  {
    name: 'Competência',
    align: 'left',
  },
  {
    name: 'Atendimentos',
    align: 'left',
  },
  {
    name: 'Preço',
    align: 'left',
  },
  {
    name: 'Status',
    align: 'left',
  },
  {
    name: 'Visualizar',
    align: 'center',
  },
];
