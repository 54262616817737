import { useContext } from 'react';

import { MetricsFilterContext } from './MetricsFilterProvider';
import { IMetricsFilterContext } from './types';

export const useMetricsFilter = (): IMetricsFilterContext => {
  const context = useContext(MetricsFilterContext);

  if (!context) {
    throw new Error('useMetricsFilter error');
  }

  return context;
};
