export function toFlooredFixed(value: number, decimal: number) {
  return (Math.floor(value * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal);
}

export function formatDecimalValue(value: number) {
  let formattedNumber = toFlooredFixed(value, 2);

  if (formattedNumber.indexOf('.') === -1) {
    formattedNumber += ',00';
  } else {
    formattedNumber = formattedNumber.replace('.', ',');
    const decimalPart = formattedNumber.split(',')[1];

    if (decimalPart.length === 1) {
      formattedNumber += '0';
    }
  }

  return formattedNumber;
}
