import { CSSProperties } from 'react';

export const Container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  marginTop: 40,
  gap: 40,

  fontFamily: 'Montserrat',
};

export const Header: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 40,
};

export const Logo: CSSProperties = {
  height: 50,
};

export const SummaryContainer: CSSProperties = {
  display: 'flex',
  gap: 6,
  justifyContent: 'space-between',
  width: '100%',
};

export const SummaryItem: CSSProperties = {
  maxWidth: 260,
  minWidth: 200,
  height: 80,
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
  padding: '25px 15px',
  backgroundColor: '#fff',
  borderRadius: 10,
  border: '1px solid #E2E2E2',
};

export const SummaryInfo: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
};

export const SummaryTitle: CSSProperties = {
  fontSize: 14,
  fontWeight: 500,
  textAlign: 'left',
  color: '#888',
};

export const SummaryValue: CSSProperties = {
  fontSize: 20,
  fontWeight: 600,
  textAlign: 'left',
  color: '#000',
};

export const SummaryIcon: CSSProperties = {
  height: 50,
  width: 50,
};

export const Section: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
};

export const SectionTitle: CSSProperties = {
  fontWeight: 600,
  fontSize: 18,
  color: '#000',
};

export const Table: CSSProperties = {
  width: '100%',
  backgroundColor: '#fff',
  overflow: 'hidden',
  borderSpacing: 0,
};

export const TableHeader: CSSProperties = {
  fontSize: 16,
  fontWeight: 500,
  textAlign: 'left',
  color: '#8F8F8F',

  padding: 10,
  backgroundColor: '#fff',
  borderBottom: '1px solid #E9EDF7',
};

export const TableRow: CSSProperties = {
  backgroundColor: '#F8F8F8',
};

export const TableCell: CSSProperties = {
  padding: 10,

  fontSize: 16,
  fontWeight: 500,
  textAlign: 'left',
  color: '#000',

  borderBottom: '1px solid #f8f8f8',
};
