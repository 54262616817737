import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { toast } from 'react-toastify';

import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/hooks';
import { DeleteIcon, IconButtonCustom } from './RemovePartAction.styles';

interface IRemovePartActionProps {
  sku: string;
}

const RemovePartAction = ({ sku }: IRemovePartActionProps) => {
  const [loading, setLoading] = useState(false);
  const budgetId = useAppSelector((state) => state.presentBudget.budget._id);
  const dispatch = useDispatch();

  const removePartBudget = async () => {
    try {
      setLoading(true);
      const budget = await BudgetService.removePartBudget(budgetId, sku);
      dispatch(setBudget(budget));
    } catch (error) {
      toast.error('Não foi possível remover a peça.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <IconButtonCustom color="inherit" className="delete-icon" onClick={() => removePartBudget()} disabled={loading}>
      <Tooltip title="Deletar" placement="top" arrow>
        <DeleteIcon />
      </Tooltip>
    </IconButtonCustom>
  );
};

export default RemovePartAction;
