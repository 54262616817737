import React from 'react';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';

interface IViewBudgetsActionProps {
  inspection: IInspection;
}

const ViewBudgetsAction = ({ inspection }: IViewBudgetsActionProps) => {
  const history = useHistory();
  const { isConsumerActive } = useConsumer();
  const { isFeatureToggleActive } = useFeatureToggles();
  const { id: inspectionId, productType, companyId, currentStatus } = inspection;

  const redirectBudgetsScreen = () => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}/orcamentos${window.location.search}`);
  };

  const hasBudgetPermission = (): boolean => {
    return (
      ProductTypeService.isAuto(productType?.code) && isConsumerActive(ConsumersEnum.BUDGETS_ACCESS, companyId || '')
    );
  };

  const inspectionStatusGreaterThanPhotosReceived = (): boolean => {
    return (
      currentStatus.type === StatusEnum.INSPECTION_STARTED ||
      currentStatus.type === StatusEnum.INSPECTION_FINISHED ||
      currentStatus.type === StatusEnum.INSPECTION_EXPIRED ||
      currentStatus.type === StatusEnum.INSPECTION_CANCELED
    );
  };

  const showButton = hasBudgetPermission() && inspectionStatusGreaterThanPhotosReceived();

  return (
    <>
      {showButton && isFeatureToggleActive('BUDGET_PROCESS') && (
        <Button
          type="button"
          testID="button-view-budgets"
          text="VER ORÇAMENTOS"
          size="small"
          onClick={redirectBudgetsScreen}
          variant="outlined"
        />
      )}
    </>
  );
};

export default ViewBudgetsAction;
