import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import NoteIcon from '@mui/icons-material/StickyNote2Outlined';
import CarIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import MapIcon from '@mui/icons-material/MapOutlined';
import FileUploadIcon from '@material-ui/icons/Publish';
import DescriptionIcon from '@mui/icons-material/Description';
import { ImageListType } from 'react-images-uploading';
import { TabPanel as TabPanelBase } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DocumentService from 'services/DocumentService/documentService';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import UploadPhotosDialog from 'components/Dialog/UploadPhotosDialog/UploadPhotosDialog';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { useConsumer } from 'contexts/consumers/useConsumer';
import SendDocumentDialog from 'components/Dialog/SendDocumentDialog/SendDocumentDialog';
import CubeService from 'services/CubeService/cubeService';
import InspectionPlatformService from 'services/InspectionPlatformService/inspectionPlatformService';
import { useQueryClient } from 'react-query';
import CheckIconConclusion from 'assets/svg/check-icon-conclusion.svg';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { ICompanyConclusionTypes } from 'services/ApiService/BudgetService/BudgetService.types';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { Photos } from '../Photos';
import { Button } from '../../../../components/Button';
import Maps from '../Maps/Maps';

import {
  HistoryWrapper,
  Title,
  TitleCard,
  CardWrapper,
  Header,
  Subtitle,
  DateCard,
  Body,
  ActionsWrapper,
  Column,
  InformationDataWrapper,
  InformationDetail,
  CardTabWrapper,
  TabsListBase,
  TabsBase,
  TabBase,
  AttendanceDataContent,
  InformartionSubtitle,
  InformartionContentWrapper,
  AutoDataWrapper,
  AutoDataContent,
  AutoDataSubtitle,
  AutoDataTitle,
  GeolocationCardWrapper,
  TitleWithIcon,
} from './History.styles';

import { useAuthDispatch } from '../../../../contexts/auth/useAuth';

import DateService from '../../../../services/DateService/dateService';
import PhotoService from '../../../../services/PhotoService/photoService';
import InspectionStatusService from '../../../../services/ApiService/InspectionStatusService/InspectionStatusService';
import GeolocationService from '../../../../services/GeolocationService/geolocationService';
import { Documents } from '../Documents';

import { IDocument, IPhoto, IStatus, NotificationSenderEnum, StatusEnum } from '../Inspection/Inspection.types';

import {
  HistoryType,
  CardType,
  InformationDetailType,
  IHistoryCardsProps,
  IGeolocationCard,
  IPhotoToSwapp,
  CardTabType,
  AttendanceDataType,
  AutoDataType,
} from './History.types';
import UploadDocumentsDialog from '../UploadDocumentsDialog/UploadDocumentsDialog';
import EmptyConclusion from './components/EmptyConclusion/EmptyConclusion';
import ConclusionCard from './components/ConclusionCard/ConclusionCard';
import ConclusionDialog from './components/ConclusionDialog/ConclusionDialog';

export const History = React.memo(
  ({ inspection }: HistoryType) => {
    const { currentStatus, status, informations, photos, inspectionNotificationSender, product, conclusion } =
      inspection;
    const [linkSentSender, setLinkSentSender] = useState<string>('');
    const [linkSentDate, setLinkSentDate] = useState<string>('');
    const [isLinkSentSenderVisible, setIsLinkSenderVisible] = useState<boolean>(false);
    const [showConclusionDialog, setShowConclusionDialog] = useState<boolean>(false);
    const [isConclusionCompanyLoading, setIsConclusionCompanyLoading] = useState<boolean>(false);
    const [companyConclusionTypes, setCompanyConclusionTypes] = useState<ICompanyConclusionTypes>();

    const isStatusEmpty = (): boolean => status?.length <= 0;
    const conclusionExist = conclusion !== null && conclusion !== undefined;
    const inspectionIsFinished = currentStatus !== undefined && currentStatus.type >= StatusEnum.INSPECTION_FINISHED;
    const haveCompanyConclusionTypes = companyConclusionTypes !== undefined;
    const isExistInformations = (): boolean => Array.isArray(informations) && informations.length > 0;

    const showAutoData = (): boolean =>
      ProductTypeService.isAuto(inspection?.productType?.code) && !!inspection?.product?.vehicle;

    const showGeolocationCard = (): boolean =>
      checkStatusExist(StatusEnum.PHOTO_RECEIVED) && GeolocationService.existCoordinatesInPhotos(photos);

    const showInformationCard = (): boolean => isExistInformations() || showAutoData() || showGeolocationCard();

    const checkStatusExist = (statusType: number): boolean => {
      return status.some(({ type }) => type === statusType);
    };

    const checkLinkSentSender = (linkSentOriginType: NotificationSenderEnum): void => {
      if (
        linkSentOriginType === NotificationSenderEnum.WHATSAPP ||
        linkSentOriginType === NotificationSenderEnum.ZENVIA_WHATSAPP
      ) {
        setLinkSentSender('WhatsApp');
      } else {
        setLinkSentSender('SMS');
      }
    };

    const putLinkSentInformation = useCallback(
      (linkSentStatus: IStatus): void => {
        const sentMessage = inspectionNotificationSender?.sentMessagesHistory.find(
          (sentMessages) => sentMessages.statusId === linkSentStatus.id
        );
        setIsLinkSenderVisible(!!sentMessage);
        !!sentMessage && checkLinkSentSender(sentMessage?.notificationSender.type);
        setLinkSentDate(sentMessage ? sentMessage?.updatedAt : '');
      },
      [inspectionNotificationSender]
    );

    const defaulLinkSentInformation = (): void => {
      setLinkSentSender('');
      setLinkSentDate('');
      setIsLinkSenderVisible(false);
    };

    const titleWithIcon = (title: string, icon: React.ReactNode): React.ReactNode => (
      <TitleWithIcon>
        {icon} {title}
      </TitleWithIcon>
    );

    const handleLinkSent = useCallback((): void => {
      const linkSentStatus = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.LINK_SENT);
      linkSentStatus ? putLinkSentInformation(linkSentStatus) : defaulLinkSentInformation();
    }, [inspection, putLinkSentInformation]);

    const handleConclusionDialogOpen = (): void => {
      setShowConclusionDialog(true);
    };

    const getCompanyConclusionTypes = useCallback(async () => {
      try {
        if (inspection?.companyId) {
          setIsConclusionCompanyLoading(true);

          const response = await BudgetService.getCompanyConclusionTypes(inspection?.companyId);
          setCompanyConclusionTypes(response);
          setIsConclusionCompanyLoading(false);
        }
      } catch {
        setIsConclusionCompanyLoading(false);
      }
    }, [inspection]);

    useEffect(() => {
      getCompanyConclusionTypes();
    }, [getCompanyConclusionTypes]);

    useEffect(() => {
      handleLinkSent();
    }, [handleLinkSent, status, inspectionNotificationSender]);

    return (
      <HistoryWrapper data-testid="history">
        {showInformationCard() && (
          <Card title="Informações" testID="inspection-informations">
            <InformartionContentWrapper>
              {isExistInformations() && <AttendanceData informations={informations || []} />}

              {showAutoData() && <AutoData autoInfo={product} />}

              {showGeolocationCard() && <GeolocationCard photos={photos} />}
            </InformartionContentWrapper>
          </Card>
        )}

        {checkStatusExist(StatusEnum.INSPECTION_STARTED) && (
          <Card
            title={titleWithIcon('Conclusão do atendimento', <img src={CheckIconConclusion} alt="icon-check" />)}
            testID="conclusion-card"
          >
            <>
              {conclusionExist ? (
                <ConclusionCard
                  conclusion={conclusion}
                  onClick={handleConclusionDialogOpen}
                  inspectionStatusIsFinished={inspectionIsFinished}
                />
              ) : (
                <EmptyConclusion
                  onClick={handleConclusionDialogOpen}
                  haveCompanyConclusionTypes={haveCompanyConclusionTypes}
                  isLoadingData={isConclusionCompanyLoading}
                />
              )}
            </>
          </Card>
        )}

        <Title>Histórico de atividades</Title>

        {isStatusEmpty() ? (
          <div data-testid="empty-message">
            <span>Nenhuma atividade foi registrada</span>
          </div>
        ) : (
          <HistoryCards
            inspection={inspection}
            checkStatusExist={checkStatusExist}
            isLinkSentSenderVisible={isLinkSentSenderVisible}
            linkSentDate={linkSentDate}
            linkSentSender={linkSentSender}
            setShowConclusionDialog={setShowConclusionDialog}
            showConclusionDialog={showConclusionDialog}
            companyConclusionTypes={companyConclusionTypes}
            isConclusionCompanyLoading={isConclusionCompanyLoading}
          />
        )}
      </HistoryWrapper>
    );
  },
  () => true
);

const HistoryCards = ({
  inspection,
  checkStatusExist,
  isLinkSentSenderVisible,
  linkSentDate,
  linkSentSender,
  setShowConclusionDialog,
  showConclusionDialog,
  companyConclusionTypes,
  isConclusionCompanyLoading,
}: IHistoryCardsProps): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);
  const { currentStatus, customer, schedule } = inspection;
  const tabTitles = ['Fotos recebidas', 'Documentos'];

  const statusDefault = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.DEFAULT);
  const statusLinkSent = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.LINK_SENT);
  const statusPhotoReceived = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.PHOTO_RECEIVED);
  const statusStarted = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_STARTED);
  const statusFinished = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_FINISHED);
  const statusCanceled = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_CANCELED);
  const statusExpired = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_EXPIRED);

  const { isRegulator, isAdmin, isAnalyst } = useAuthDispatch();
  const { isFeatureToggleActive } = useFeatureToggles();
  const { isConsumerActive } = useConsumer();
  const [isHideButton, setIsHideButton] = useState(false);
  const [inspectionPhotos] = useState<IPhoto[]>(inspection.photos);
  const [inspectionDocuments] = useState<IDocument[] | undefined>(inspection?.files);
  const [isOrganizingPhotos, setIsOrganizingPhotos] = useState(false);
  const [isDisableButtonChangeStatus, setisDisableButtonChangeStatus] = useState(false);
  const [photosToSwapp, setPhotosToSwapp] = useState<IPhotoToSwapp[]>([]);
  const [isLoadingSwappPhotos, setIsLoadingSwappPhoto] = useState<boolean>(false);
  const [isUpdatePhotosDialogOpen, setIsUpdatePhotosDialogOpen] = useState<boolean>(false);
  const [isUpdateDocumentsDialogOpen, setIsUpdateDocumentsDialogOpen] = useState<boolean>(false);
  const [isResaleDialogOpen, setIsResaleDialogOpen] = useState<boolean>(false);
  const [isResaleDialogLoading, setIsResaleDialogLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const conclusionExist = inspection?.conclusion !== null && inspection?.conclusion !== undefined;

  const handleUpdatePhotosDialogOpen = (): void => {
    setIsUpdatePhotosDialogOpen(true);
  };

  const handleUpdateDocumentsDialogOpen = (): void => {
    setIsUpdateDocumentsDialogOpen(true);
  };

  const openResaleDialog = (): void => {
    setIsResaleDialogOpen(true);
  };

  const closeResaleDialog = (): void => {
    setIsResaleDialogOpen(false);
  };

  const getFileNameWithoutExtension = (fileName: string | undefined) => {
    return fileName?.split('.')[0] || '';
  };

  const onResaleSubmit = async (onImageRemoveAll: () => void, documents: ImageListType) => {
    try {
      setIsResaleDialogLoading(true);
      if (documents[0].dataURL) {
        const base64String = documents[0].dataURL.split(';base64,')[1];
        const buffer = Buffer.from(base64String, 'base64');
        const fileName = getFileNameWithoutExtension(documents[0]?.file?.name);

        const s3Document = await CubeService.uploadXmlDocumentToS3({
          buffer,
          id: inspection.id,
          description: fileName,
          name: fileName,
        });

        if (s3Document.path) {
          const url = await DocumentService.sendResaleDocument(inspection.id, s3Document.path);

          if (url) {
            DocumentService.downloadXmlDocument(url, fileName);
          }
        }
      }
      toast.success('Arquivo baixado com sucesso!');
      onImageRemoveAll();
      setIsResaleDialogLoading(false);
      closeResaleDialog();
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar o arquivo.');
      setIsResaleDialogLoading(false);
    }
  };

  const handleUpdatePhotosDialogClose = () => {
    setIsUpdatePhotosDialogOpen(false);
  };

  const handleUpdateDocumentDialogClose = () => {
    setIsUpdateDocumentsDialogOpen(false);
  };

  const handleConclusionDialogClose = async (isSaveInspection = false): Promise<void> => {
    setShowConclusionDialog(false);

    if (isSaveInspection) {
      await mutateInspection();
    }
  };

  const startedStatus = async (): Promise<void> => {
    try {
      if (!inspection) return;

      setisDisableButtonChangeStatus(true);

      await InspectionStatusService.started(inspection.id);

      toast.success('Atendimento atualizado com sucesso!');
      setIsHideButton(true);
    } catch (error) {
      toast.error('Aconteceu algo inesperado, tente novamente.');
    } finally {
      setisDisableButtonChangeStatus(false);
    }
  };

  const finishedStatus = async (): Promise<void> => {
    try {
      if (!inspection) return;

      setisDisableButtonChangeStatus(true);

      await InspectionStatusService.finished(inspection.id);

      toast.success('Atendimento atualizado com sucesso!');
      setIsHideButton(true);
    } catch (error) {
      toast.error('Aconteceu algo inesperado, tente novamente.');
    } finally {
      setisDisableButtonChangeStatus(false);
    }
  };

  const startInspection = async (): Promise<void> => {
    try {
      await startedStatus();
      await mutateInspection();
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao tentar iniciar o atendimento, tente novamente.');
    }
  };

  const finishInspection = async (): Promise<void> => {
    try {
      await finishedStatus();
      await mutateInspection();
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao tentar finalizar o atendimento, tente novamente.');
    }
  };

  const mutateInspection = async (): Promise<void> => {
    queryClient.invalidateQueries({
      queryKey: ['get-inspection-by-id', { id: inspection.id }],
    });
    queryClient.invalidateQueries({ queryKey: ['get-inspections'] });
  };

  const showByPermissionAndCurrentStatus = (statusCard: number): boolean => {
    return hasPermition() && statusCard === currentStatus?.type;
  };

  const isNotTransmition = () => {
    return !InspectionPlatformService.isSORTInspection(inspection.inspectionPlatform?.code);
  };

  const hasPermition = (): boolean => {
    return isRegulator() || isAdmin() || isAnalyst();
  };

  const downloadPhotos = async (): Promise<void> => {
    try {
      if (!inspection) return;

      await PhotoService.downloadFileZip(inspectionPhotos, inspection.code);
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao baixar as fotos, tente novamente.');
    }
  };

  const downloadDocuments = async (documents: IDocument[]): Promise<void> => {
    try {
      if (!inspection) return;

      await DocumentService.downloadFileZip(documents, inspection.code);
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao baixar os documentos, tente novamente.');
    }
  };

  const organizePhotos = () => setIsOrganizingPhotos(true);

  const cancelSwappPhotos = () => {
    setIsOrganizingPhotos(false);
    setPhotosToSwapp([]);
  };

  const canSelectSwappPhotos = (): boolean => photosToSwapp.length < 2;

  const photoIsAlreadySelected = ({ path }: IPhoto) => photosToSwapp.some((photo) => photo.fileName === path);

  const selectSwappPhoto = (photo: IPhoto): void => {
    if (isLoadingSwappPhotos) return;

    const photos = [...photosToSwapp];
    if (canSelectSwappPhotos() && !photoIsAlreadySelected(photo)) {
      photos.push({ inspectionId: photo.inspectionId as string, fileName: photo.path });
      setPhotosToSwapp([...photos]);
    }

    const photoIndex = getPhotoIndexFromSelected(photo);

    if (photoIndex !== -1) {
      photos.splice(photoIndex, 1);
      setPhotosToSwapp([...photos]);
    }
  };

  const getPhotoIndexFromSelected = (photo: IPhoto) => photosToSwapp.findIndex((p) => p.fileName === photo.path);

  const swappInspectionPhoto = (oldPhoto: IPhotoToSwapp, newPhoto: IPhotoToSwapp) => {
    const oldIndex = inspectionPhotos.findIndex((photo) => photo.path === oldPhoto.fileName);

    const newIndex = inspectionPhotos.findIndex((photo) => photo.path === newPhoto.fileName);

    return swappUrlOfPhotos(oldIndex, newIndex);
  };

  const swappUrlOfPhotos = (oldIndex: number, newIndex: number) => {
    const tempUrl = inspectionPhotos[oldIndex].url;

    inspectionPhotos[oldIndex].url = inspectionPhotos[newIndex].url;

    inspectionPhotos[newIndex].url = tempUrl;

    return inspectionPhotos;
  };

  const finishSwappPhotos = async (): Promise<void> => {
    try {
      setIsLoadingSwappPhoto(true);
      await PhotoService.swapPhotos(photosToSwapp);
      swappInspectionPhoto(photosToSwapp[0], photosToSwapp[1]);
    } catch (error) {
      toast.error('Erro ao trocar fotos!');
    } finally {
      setPhotosToSwapp([]);
      setIsOrganizingPhotos(false);
      setIsLoadingSwappPhoto(false);
    }
  };

  const isPhotoSelected = (url: string): boolean => photosToSwapp.some((photo) => photo.fileName === url);

  const isCanceledByMissingVehicle = (): boolean => {
    return (
      checkStatusExist(StatusEnum.INSPECTION_CANCELED) &&
      inspection?.cancellation?.cancellationMotive?.motive === 'Veículo Ausente'
    );
  };

  const showButtonsUploadsStatus = (): boolean => {
    return (
      showByPermissionAndCurrentStatus(StatusEnum.PHOTO_RECEIVED) ||
      showByPermissionAndCurrentStatus(StatusEnum.INSPECTION_STARTED)
    );
  };

  const showButtonUploadDocuments = showButtonsUploadsStatus();

  const showIfIsLocally =
    InspectionService.isInspectionCategoryLocally(inspection?.inspectionCategory) &&
    showByPermissionAndCurrentStatus(StatusEnum.DEFAULT);

  const isConclusionValidForFinishButton =
    (conclusionExist && companyConclusionTypes !== undefined) || companyConclusionTypes === undefined;

  const disableFinishInspectionButton =
    isDisableButtonChangeStatus ||
    isOrganizingPhotos ||
    !isConclusionValidForFinishButton ||
    isConclusionCompanyLoading;

  const showSendPhotoCardVerification = showByPermissionAndCurrentStatus(StatusEnum.LINK_SENT) && isNotTransmition();

  const showSendPhotoCard = showSendPhotoCardVerification || showIfIsLocally;

  const buildCancellationStatus = () => {
    return (
      inspection?.cancellation?.description || inspection?.cancellation?.cancellationMotive?.motive || 'Não informado'
    );
  };

  return (
    <>
      {checkStatusExist(StatusEnum.INSPECTION_EXPIRED) && (
        <Card title="Atendimento expirado" status={statusExpired} testID="inspection-expired">
          <Subtitle>O atendimento foi expirado</Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.INSPECTION_CANCELED) && (
        <Card title="Atendimento cancelado" status={statusCanceled} testID="inspection-canceled">
          <Subtitle>
            O motivo do cancelamento foi <b>{buildCancellationStatus()}</b>
          </Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.INSPECTION_FINISHED) && (
        <Card title="Atendimento realizado" status={statusFinished} testID="inspection-finished">
          <Subtitle>O atendimento foi concluído</Subtitle>
          <ActionsWrapper>
            {isAdmin() && isConsumerActive(ConsumersEnum.RESALE_ARVAL, inspection?.companyId || '') && (
              <Button
                type="button"
                testID="resale-button"
                text="GERAR XML DE REVENDA"
                variant="outlined"
                disabled={false}
                onClick={() => openResaleDialog()}
                startIcon={<DescriptionIcon />}
              />
            )}
          </ActionsWrapper>
        </Card>
      )}
      {checkStatusExist(StatusEnum.INSPECTION_STARTED) && (
        <Card title="Realizando atendimento" status={statusStarted} testID="inspection-progress">
          <Subtitle>O atendimento está sendo realizado</Subtitle>
          <ActionsWrapper>
            {showByPermissionAndCurrentStatus(StatusEnum.INSPECTION_STARTED) && !isHideButton && (
              <Button
                type="button"
                testID="finish-admin-button"
                text="FINALIZAR ATENDIMENTO"
                onClick={(): Promise<void> => finishInspection()}
                disabled={disableFinishInspectionButton}
              />
            )}
          </ActionsWrapper>
        </Card>
      )}
      {(checkStatusExist(StatusEnum.PHOTO_RECEIVED) || isCanceledByMissingVehicle()) && (
        <TabsBase selectedIndex={tabValue} onSelect={(index) => setTabValue(index)}>
          <TabsListBase>
            {tabTitles.map((tabTitle, index) => {
              return (
                <TabBase key={index} data-testid={`tab-${index}`}>
                  {tabTitle}
                </TabBase>
              );
            })}
          </TabsListBase>
          <CardTab status={statusPhotoReceived} testID="card-tab">
            <TabPanelBase value={tabValue} data-testid="tab-photo">
              <Photos
                isOrganizingPhotos={isOrganizingPhotos}
                selectSwappPhoto={selectSwappPhoto}
                canSelectSwappPhotos={canSelectSwappPhotos}
                isPhotoSelected={isPhotoSelected}
                photos={inspectionPhotos}
                testID="photos-id"
                company={inspection.company}
              />
              <ActionsWrapper>
                {showByPermissionAndCurrentStatus(StatusEnum.PHOTO_RECEIVED) && !isHideButton && (
                  <Button
                    type="button"
                    testID="start-admin-button"
                    text="INICIAR ATENDIMENTO"
                    onClick={(): Promise<void> => startInspection()}
                    disabled={isDisableButtonChangeStatus || isOrganizingPhotos}
                  />
                )}

                {hasPermition() && !!inspectionPhotos.length && (
                  <Button
                    type="button"
                    testID="download-photos-button"
                    text="BAIXAR FOTOS"
                    variant="outlined"
                    disabled={isOrganizingPhotos}
                    onClick={(): Promise<void> => downloadPhotos()}
                    startIcon={<GetAppIcon />}
                    onlyIcon
                  />
                )}

                {showButtonsUploadsStatus() && (
                  <>
                    <Button
                      type="button"
                      testID="upload-photos-button"
                      text="ENVIAR FOTOS"
                      variant="outlined"
                      disabled={isOrganizingPhotos}
                      onClick={() => handleUpdatePhotosDialogOpen()}
                      startIcon={<FileUploadIcon />}
                      onlyIcon
                    />
                  </>
                )}

                {!!inspectionPhotos.length &&
                  hasPermition() &&
                  !isOrganizingPhotos &&
                  isFeatureToggleActive('SWAPP_PHOTO') && (
                    <Button
                      type="button"
                      testID="organize-photos-button"
                      text="ORGANIZAR FOTOS"
                      variant="outlined"
                      onClick={(): void => organizePhotos()}
                      startIcon={<EditIcon />}
                      onlyIcon
                    />
                  )}

                {hasPermition() && isOrganizingPhotos && isFeatureToggleActive('SWAPP_PHOTO') && (
                  <Button
                    type="button"
                    testID="cancel-swapp-photos-button"
                    text="CANCELAR"
                    variant="outlined"
                    onClick={(): void => cancelSwappPhotos()}
                  />
                )}

                {hasPermition() && isOrganizingPhotos && isFeatureToggleActive('SWAPP_PHOTO') && (
                  <Button
                    type="button"
                    testID="confirm-photos-button"
                    text="CONFIRMAR"
                    loading={isLoadingSwappPhotos}
                    disabled={canSelectSwappPhotos()}
                    onClick={(): Promise<void> => finishSwappPhotos()}
                  />
                )}
              </ActionsWrapper>
            </TabPanelBase>
            <TabPanelBase value={tabValue} data-testid="tab-document">
              {inspection.files && inspectionDocuments !== undefined && (
                <>
                  <Documents documents={inspectionDocuments} testID="documents-id" company={inspection.company} />
                  <ActionsWrapper>
                    {showByPermissionAndCurrentStatus(StatusEnum.PHOTO_RECEIVED) && !isHideButton && (
                      <Button
                        type="button"
                        testID="start-admin-button"
                        text="INICIAR ATENDIMENTO"
                        onClick={(): Promise<void> => startInspection()}
                        disabled={isDisableButtonChangeStatus || isOrganizingPhotos}
                      />
                    )}

                    {hasPermition() && !!inspectionDocuments.length && (
                      <Button
                        type="button"
                        testID="download-documents-button"
                        text="BAIXAR DOCUMENTOS"
                        variant="outlined"
                        disabled={isOrganizingPhotos}
                        onClick={(): Promise<void> => downloadDocuments(inspectionDocuments)}
                        startIcon={<GetAppIcon />}
                        onlyIcon
                      />
                    )}

                    {showButtonUploadDocuments && (
                      <>
                        <Button
                          type="button"
                          testID="upload-documents-button"
                          text="ENVIAR DOCUMENTOS"
                          variant="outlined"
                          disabled={false}
                          onClick={() => handleUpdateDocumentsDialogOpen()}
                          startIcon={<FileUploadIcon />}
                          onlyIcon
                        />
                      </>
                    )}
                  </ActionsWrapper>
                </>
              )}
            </TabPanelBase>
          </CardTab>
        </TabsBase>
      )}
      {showSendPhotoCard && (
        <Card title="Envie as imagens aqui." testID="change-status-photos-received">
          <Subtitle>
            Ao clicar no botão abaixo, você poderá <b>enviar as fotos</b> para a plataforma.
          </Subtitle>

          <ActionsWrapper>
            <Button
              type="button"
              testID="upload-photos-button"
              text="ENVIAR FOTOS"
              variant="outlined"
              disabled={isOrganizingPhotos}
              onClick={() => handleUpdatePhotosDialogOpen()}
              startIcon={<FileUploadIcon />}
            />
          </ActionsWrapper>
        </Card>
      )}
      {schedule && (
        <Card title="Agendamento" testID="schedule-date">
          <Subtitle>
            Esse atendimento está agendado para a data <b>{DateService.formatDateAndTime(schedule)}</b>
          </Subtitle>
        </Card>
      )}
      {checkStatusExist(StatusEnum.LINK_SENT) && customer?.phone && (
        <>
          <Card title="Link enviado" testID="link-history">
            {isLinkSentSenderVisible ? (
              <Subtitle>
                Mensagem enviada para o destinatário <b>{customer?.contactName}</b> no número <b>{customer?.phone}</b>
                &nbsp;via
                <b> {linkSentSender}</b>
                {linkSentSender && (
                  <>
                    &nbsp;em <b> {DateService.formatDateAndTime(linkSentDate)}</b>
                  </>
                )}
              </Subtitle>
            ) : (
              <Subtitle>
                Mensagem enviada para o destinatário <b>{customer?.contactName}</b> no número <b>{customer?.phone}</b>
                {statusLinkSent && (
                  <>
                    &nbsp;em <b> {DateService.formatDateAndTime(statusLinkSent.date)}</b>
                  </>
                )}
              </Subtitle>
            )}
          </Card>
        </>
      )}

      {checkStatusExist(StatusEnum.DEFAULT) && (
        <Card title="Atendimento criado" testID="service-started">
          <Subtitle>
            O atendimento <b>{inspection.code}</b> foi criado em
            <b> {DateService.formatDateAndTime(statusDefault.date)}</b>
          </Subtitle>
        </Card>
      )}

      <ConclusionDialog
        conclusion={inspection.conclusion}
        inspectionId={inspection.id}
        showConclusionDialog={showConclusionDialog}
        handleClose={handleConclusionDialogClose}
        companyConclusionTypes={companyConclusionTypes}
      />

      <UploadPhotosDialog
        dialogVisibility={isUpdatePhotosDialogOpen}
        inspectionId={inspection.id}
        currentStatus={currentStatus}
        inspectionPhotos={inspectionPhotos}
        handleClose={handleUpdatePhotosDialogClose}
        inspectionCategory={inspection.inspectionCategory}
      />
      <UploadDocumentsDialog
        dialogVisibility={isUpdateDocumentsDialogOpen}
        inspection={inspection}
        inspectionDocuments={inspectionDocuments}
        handleClose={handleUpdateDocumentDialogClose}
      />
      <SendDocumentDialog
        title="Enviar arquivo de revenda"
        subtitle="Selecione um arquivo para atualizar"
        textDragAndDrop="Clique ou arraste o arquivo aqui"
        errorMessage="* Tipo de arquivo não suportado, use apenas XML"
        btnSubmitText="ENVIAR"
        dialogVisibility={isResaleDialogOpen}
        extensions={['xml']}
        replaceTooltipIcon="Substituir arquivo"
        removeTooltipIcon="Remover arquivo"
        loadingButton={isResaleDialogLoading}
        handleSubmit={onResaleSubmit}
        handleClose={closeResaleDialog}
        hasSelect={false}
      />
    </>
  );
};

const AttendanceData = ({ informations }: AttendanceDataType): JSX.Element => {
  const half = Math.ceil(informations.length / 2);
  const firstHalfInspection = informations.slice(0, half);
  const secondHalfInspection = informations.slice(half, informations.length);

  return (
    <InformationDataWrapper>
      <InformartionSubtitle>
        <NoteIcon /> Dados do atendimento
      </InformartionSubtitle>
      <AttendanceDataContent>
        <Column>
          <InformationDetails informations={firstHalfInspection} />
        </Column>
        <Column>
          <InformationDetails informations={secondHalfInspection} />
        </Column>
      </AttendanceDataContent>
    </InformationDataWrapper>
  );
};

const InformationDetails = ({ informations }: InformationDetailType): JSX.Element => (
  <>
    {informations.map(({ key, value }) => {
      return (
        <InformationDetail key={key}>
          {key} <b>{value}</b>
        </InformationDetail>
      );
    })}
  </>
);

const AutoData = ({ autoInfo }: AutoDataType): JSX.Element => {
  const renderAutoDataContent = (title: string, value: string | undefined) => (
    <AutoDataContent>
      <AutoDataTitle>{title}</AutoDataTitle>
      <AutoDataSubtitle>{value || '-'}</AutoDataSubtitle>
    </AutoDataContent>
  );

  return (
    <InformationDataWrapper>
      <InformartionSubtitle>
        <CarIcon /> Dados do veículo
      </InformartionSubtitle>

      <AutoDataWrapper>
        {renderAutoDataContent('Placa', autoInfo?.plate)}
        {renderAutoDataContent('Marca', autoInfo?.vehicle?.brand)}
        {renderAutoDataContent('Modelo', autoInfo?.vehicle?.model)}
        {renderAutoDataContent('Ano', autoInfo?.vehicle?.modelYear)}
        {renderAutoDataContent('Versão', autoInfo?.vehicle?.version)}
        {renderAutoDataContent('Combustível', autoInfo?.vehicle?.fuel)}
        {renderAutoDataContent('Chassi', autoInfo?.vehicle?.chassis)}
        {renderAutoDataContent('Tipo', autoInfo?.vehicle?.type)}
      </AutoDataWrapper>
    </InformationDataWrapper>
  );
};

const GeolocationCard = ({ photos }: IGeolocationCard): JSX.Element => {
  const geolocationPhotos = GeolocationService.getGeolocationPhotos(photos);

  return (
    <GeolocationCardWrapper data-testid="geolocation-card">
      <InformartionSubtitle>
        <MapIcon /> Localização
      </InformartionSubtitle>
      <Body>
        <Maps geolocationPhotos={geolocationPhotos} />
      </Body>
    </GeolocationCardWrapper>
  );
};

const Card = ({ title, status, statusDate, isStatusDateVisible, children, testID }: CardType): JSX.Element => {
  return (
    <CardWrapper data-testid={testID}>
      <Header>
        {title ? <TitleCard role="history-card">{title}</TitleCard> : <></>}
        {isStatusDateVisible ? (
          <DateCard>{statusDate && DateService.formatDateAndTime(statusDate)}</DateCard>
        ) : (
          <DateCard>{status && DateService.formatDateAndTime(status.date)}</DateCard>
        )}
      </Header>
      <Body>{children}</Body>
    </CardWrapper>
  );
};

const CardTab = ({ status, statusDate, isStatusDateVisible, children, testID }: CardTabType): JSX.Element => {
  return (
    <CardTabWrapper data-testid={testID}>
      <Header>
        {isStatusDateVisible ? (
          <DateCard>{statusDate && DateService.formatDateAndTime(statusDate)}</DateCard>
        ) : (
          <DateCard>{status && DateService.formatDateAndTime(status.date)}</DateCard>
        )}
      </Header>
      <Body>{children}</Body>
    </CardTabWrapper>
  );
};
