// create Skeleton Loadin component
import React from 'react';
import { Skeleton } from '@mui/material';
import { SkeletonContainer, SkeletonRow, SkeletonSubtitle } from './ChartsSkeletonLoading.styles';

export const ChartsSkeletonLoading = () => {
  return (
    <SkeletonContainer>
      <SkeletonRow>
        <Skeleton variant="rounded" width={200} height={30} />
        <Skeleton variant="rounded" width={110} height={18} />
      </SkeletonRow>
      <SkeletonRow>
        <Skeleton variant="circular" width={160} height={160} />
        <SkeletonSubtitle>
          <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" width={250} sx={{ fontSize: '1rem' }} />
        </SkeletonSubtitle>
      </SkeletonRow>
    </SkeletonContainer>
  );
};
