import React, { useCallback, useEffect, useState } from 'react';
import { IOptions } from 'components/Select/Select.types';
import { toast } from 'react-toastify';
import { Button } from 'components';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { ICompanyConclusionTypes } from 'services/ApiService/BudgetService/BudgetService.types';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import * as S from './ConclusionDialog.styles';
import { IConclusionDialogProps } from './ConclusionDialog.types';

const commentProps = {
  'data-testid': 'input-area',
  form: {
    autocomplete: 'off',
  },
};

const ConclusionDialog = ({ testID, inspection, showConclusionDialog, handleClose }: IConclusionDialogProps) => {
  const [companyConclusionTypes, setCompanyConclusionTypes] = useState<ICompanyConclusionTypes>();
  const [reasonsOptions, setReasonsOptions] = useState<IOptions[]>([]);
  const [selectedConclusionTypeId, setSelectedConclusionTypeId] = useState(
    inspection?.conclusion?.conclusionType.id || ''
  );
  const [selectedReasonId, setSelectedReasonId] = useState(inspection?.conclusion?.conclusionReason.id || '');
  const [commentInput, setCommentInput] = useState(inspection?.conclusion?.comment || '');
  const [isConclusionCompanyLoading, setIsConclusionCompanyLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { invalidateInspectionParallel } = useMutateInspection();

  const disableSelectReason =
    reasonsOptions.length === 0 || selectedConclusionTypeId === '' || isConclusionCompanyLoading;

  const verifyInputsChange =
    inspection?.conclusion?.conclusionType?.id !== selectedConclusionTypeId ||
    inspection?.conclusion?.conclusionReason?.id !== selectedReasonId ||
    inspection?.conclusion?.comment !== commentInput;

  const disableSaveButton =
    commentInput === '' ||
    selectedConclusionTypeId === '' ||
    selectedReasonId === '' ||
    !verifyInputsChange ||
    isConclusionCompanyLoading;

  const conclusionTypesOptions: IOptions[] =
    companyConclusionTypes?.conclusionTypes.map((conclusionType) => ({
      value: conclusionType.id,
      name: conclusionType.description,
    })) || [];

  const getCompanyConclusionTypes = async () => {
    try {
      if (inspection?.companyId) {
        setIsConclusionCompanyLoading(true);

        const response = await BudgetService.getCompanyConclusionTypes(inspection?.companyId);
        setCompanyConclusionTypes(response);
        setIsConclusionCompanyLoading(false);
      }
    } catch {
      setIsConclusionCompanyLoading(false);
    }
  };

  const handleChangeSelectedConclusionType = (value: string) => {
    if (value) {
      setSelectedConclusionTypeId(value);
      setSelectedReasonId('');
    } else {
      setSelectedConclusionTypeId('');
      setSelectedReasonId('');
    }
  };

  const getConclusionTypeById = useCallback(
    (conclusionTypeId: string) => companyConclusionTypes?.conclusionTypes.find((c) => c.id === conclusionTypeId),
    [companyConclusionTypes]
  );

  const getReasonsOptionsFromCompanyConclusionType = useCallback(
    (conclusionTypeId: string): IOptions[] => {
      const conclusionTypeFiltered = getConclusionTypeById(conclusionTypeId);

      if (conclusionTypeFiltered) {
        return conclusionTypeFiltered.conclusionReasons.map((conclusionReason) => ({
          value: conclusionReason.id,
          name: conclusionReason.description,
        }));
      }

      return [];
    },
    [getConclusionTypeById]
  );

  const putConclusion = async () => {
    try {
      setLoadingButton(true);

      await InspectionService.putConclusion(inspection?.id || '', {
        conclusionTypeId: selectedConclusionTypeId,
        conclusionReasonId: selectedReasonId,
        comment: commentInput,
      });

      toast.success('Conclusão salva com sucesso');
      invalidateInspectionParallel(inspection.id);
      handleClose();
    } catch (error) {
      setLoadingButton(false);
      toast.error('Erro ao salvar conclusão');
    }
  };

  useEffect(() => {
    if (selectedConclusionTypeId) {
      setReasonsOptions(getReasonsOptionsFromCompanyConclusionType(selectedConclusionTypeId));
    }
  }, [selectedConclusionTypeId, getReasonsOptionsFromCompanyConclusionType]);

  return (
    <S.DialogBase
      data-testid={testID}
      open={showConclusionDialog}
      onClose={() => handleClose()}
      TransitionProps={{ onEnter: getCompanyConclusionTypes }}
    >
      <S.Title>Conclusão</S.Title>

      <S.SelectWrapper>
        <S.SelectItem>
          <S.Label htmlFor="type" testID="label-budget-type">
            Tipo de conclusão
          </S.Label>
          <S.Select
            testID="type-select"
            labelId="type-select"
            name="type-select"
            placeholder="Selecione o tipo de conclusão"
            disabled={isConclusionCompanyLoading}
            required={true}
            options={conclusionTypesOptions}
            selectedOptions={selectedConclusionTypeId}
            onChange={({ target: { value } }): void => handleChangeSelectedConclusionType(value as string)}
          />
        </S.SelectItem>
        <S.SelectItem>
          <S.Label htmlFor="reason" testID="label-budget-reason">
            Motivo
          </S.Label>
          <S.Select
            testID="reason-select"
            labelId="reason-select"
            name="reason-select"
            placeholder="Selecione o motivo"
            required={true}
            disabled={disableSelectReason}
            options={reasonsOptions}
            selectedOptions={selectedReasonId}
            onChange={({ target: { value } }): void => setSelectedReasonId(value as string)}
          />
        </S.SelectItem>
      </S.SelectWrapper>

      <S.CommentWrapper>
        <S.Label htmlFor="comment" testID="label-comment-type">
          Comentário
        </S.Label>
        <S.TextField
          testID="comment-input"
          ariaLabelledby="comment-input"
          id="comment-input"
          inputProps={commentProps}
          name="comment"
          type="text"
          placeholder="Descrição da conclusão..."
          variant="outlined"
          value={commentInput}
          multiline
          minRows={10}
          onChange={({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void =>
            setCommentInput(value as string)
          }
        />
      </S.CommentWrapper>
      <S.SaveButtonWrapper>
        <Button
          text="Cancelar"
          testID="cancel-conclusion-button"
          variant="outlined"
          onClick={() => handleClose()}
          disabled={loadingButton}
        />
        <Button
          text="Salvar"
          testID="salve-conclusion-button"
          variant="contained"
          loading={loadingButton}
          onClick={putConclusion}
          disabled={disableSaveButton}
          loadingSize={25}
          loadingColor="#FFF"
        />
      </S.SaveButtonWrapper>
    </S.DialogBase>
  );
};

export default ConclusionDialog;
