import { Badge as BadgeIcon, Avatar as AvatarComponent } from '@material-ui/core';
import AvatarIcon from 'components/Avatar/Avatar';

import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  position: fixed;
  z-index: 1;
`;

export const ClientAvatar = styled(AvatarComponent)`
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #000000;
  z-index: -1;
  right: 5px;
  top: 26px;
`;

export const Badge = styled(BadgeIcon)`
  position: relative;
  cursor: pointer;
`;

export const Avatar = styled(AvatarIcon)`
  position: relative;
  z-index: -1;
  right: -10px;
`;
