import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// eslint-disable-next-line
import { createBrowserHistory } from 'history';

import { QueryClientProvider } from 'react-query';

import AppRouter from 'routes';
import { Sidebar } from 'components/Sidebar';

import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import { DashboardContent, DashboardWrapper } from './Dashboard.styles';
import queryClient from 'lib/reactQuery';

const history = createBrowserHistory();

export const Dashboard: React.FC = () => {
  const { getFeatureToggles } = useFeatureToggles();
  const { initiateAccountGroups, getFCMTokenForAccountActive } = useActiveProfile();
  const { isAuthenticated } = useAuthDispatch();

  useEffect(() => {
    const loadingToggles = async (): Promise<void> => {
      try {
        await getFeatureToggles();
      } catch (error) {
        toast.error('Aconteceu algo durante o carregamento das features.');
      }
    };

    const loadingAccountGroup = async (): Promise<void> => {
      if (isAuthenticated()) {
        try {
          if (window.location.pathname !== '/login' && !window.location.pathname.includes('/vistoria')) {
            await initiateAccountGroups();
          }
        } catch (error) {
          toast.error('Aconteceu algo durante o carregamento da conta.');
        }
      }
    };

    const setupNotifications = async () => {
      if (isAuthenticated()) {
        try {
          if (window.location.pathname !== '/login' && !window.location.pathname.includes('/vistoria')) {
            await getFCMTokenForAccountActive();
          }
        } catch (error) {
          throw new Error(`Could not setup notifications, error: ${error}`);
        }
      }
    };

    setupNotifications();
    loadingToggles();
    loadingAccountGroup();
  }, [getFeatureToggles, initiateAccountGroups, isAuthenticated, getFCMTokenForAccountActive]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <DashboardWrapper data-testid="dashboard">
          <div data-testid="toast">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick={true}
              pauseOnHover={true}
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
            />
          </div>
          <Sidebar />
          <DashboardContent>
            <AppRouter />
          </DashboardContent>
        </DashboardWrapper>
      </Router>
    </QueryClientProvider>
  );
};
