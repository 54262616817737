import React from 'react';
import {
  Card,
  Separator,
  SummaryContainer,
  SummaryScore,
  SummaryContent,
  SummaryInfo,
  SummaryClassification,
  StrongInfo,
  ItalicInfo,
  TitleBoldInfo,
  BoldItalicInfo,
} from './DamageSummary.styles';
import { IReportDamagePartsSeverity } from 'commom/types/BudgetReport.types';
import DamageClassification from './DamageClassification/DamageClassification';

interface IDamageSummaryProps {
  damagePartSeverity: IReportDamagePartsSeverity;
}

const DamageSummary = ({ damagePartSeverity }: IDamageSummaryProps) => {
  return (
    <Card>
      <SummaryContainer>
        <SummaryScore>
          <SummaryContent>
            <SummaryInfo>
              <SummaryInfo>{'Total de itens marcados como '}</SummaryInfo>
              <ItalicInfo>{'"Sim" : '}</ItalicInfo>
              <StrongInfo>{damagePartSeverity.yes}</StrongInfo>
            </SummaryInfo>
            <SummaryInfo>
              <SummaryInfo>{'Total de itens marcados como '}</SummaryInfo>
              <ItalicInfo>{'"Não Avaliado" : '}</ItalicInfo>
              <StrongInfo>{damagePartSeverity.notEvaluated}</StrongInfo>
            </SummaryInfo>
          </SummaryContent>
          <Separator />
          <SummaryContent>
            <TitleBoldInfo>Total de pontos</TitleBoldInfo>
            <SummaryInfo>
              <StrongInfo>{'Somatório de itens '}</StrongInfo>
              <BoldItalicInfo>{'"Sim" + "Não Avaliado" : '}</BoldItalicInfo>
              <StrongInfo>{damagePartSeverity.severityPoints}</StrongInfo>
            </SummaryInfo>
          </SummaryContent>
        </SummaryScore>
        <SummaryClassification>
          <SummaryContent>
            <TitleBoldInfo>Classificação do dano do veículo</TitleBoldInfo>
            <DamageClassification />
          </SummaryContent>
        </SummaryClassification>
      </SummaryContainer>
    </Card>
  );
};

export default DamageSummary;
