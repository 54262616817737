import React from 'react';
import { UsersManagementDashboardContainer } from 'contexts/usersManagementDashboard/UserManagementDashboardProvider';
import { Title, Container, DashboardHeader } from './UsersManagementDashboard.styles';
import { UsersTable } from './components/UsersTable/UsersTable';
import UsersFilter from './components/UsersFilter/UsersFilter';

export const UsersManagementDashboard = () => {
  return (
    <UsersManagementDashboardContainer>
      <Container data-testid="user-management-dashboard">
        <DashboardHeader>
          <Title>Gestão de Usuários</Title>
        </DashboardHeader>
        <UsersFilter />
        <UsersTable />
      </Container>
    </UsersManagementDashboardContainer>
  );
};
