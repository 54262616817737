import styled from 'styled-components';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Tooltip, withStyles } from '@material-ui/core';

export const CardUserContainer = styled.div`
  width: 100%;
  min-width: 360px;
  padding: 18px 23px;
  background: #ffffff;
  border: 2px solid #dbd9d9;
  box-sizing: border-box;
  border-radius: 10px;
  transition: linear 0.3s;

  :hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`;

export const CardUserUsername = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  max-width: 295px;

  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CardUserButtonWrapper = styled.div`
  padding: 0 !important;
`;

export const CardUserButtonIcon = styled(MoreHorizIcon)`
  color: black;
`;

export const CardUserAuthority = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  color: #2c2c2c;
  margin-bottom: 23px;
`;

export const CardUserOperationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
`;

export const CardUserOperation = styled.div`
  min-width: 65px;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  color: #555;
  padding: 6px 20px;
  border: 1px solid #000000;
  border-radius: 100px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NavTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);
