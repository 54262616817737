import React from 'react';
import { Footer } from './Drawer.styles';
import { IDrawerBaseProps } from './DrawerProps.types';

export default function DrawerFooter({ children, testId = 'drawer-footer', className }: IDrawerBaseProps) {
  return (
    <Footer data-testid={testId} className={className}>
      {children}
    </Footer>
  );
}
