import { Checkbox } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { theme } from 'themes/default';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { ISelectProps } from './Select.types';
import { FormControlWrapper, MenuItemWrapper, PlaceholderContainer, SelectWrapper } from './Select.styles';

const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
};

export const Select: React.FC<ISelectProps> = ({
  className,
  testID,
  options,
  selectedOptions,
  onChange,
  multiple = false,
  type = 'outlined',
  label,
  labelId,
  error,
  name,
  placeholder,
  required,
  id,
  buttonShape,
  disabled = false,
}) => {
  const getSelectedOptionsName = () => {
    if (placeholder && (selectedOptions as string[]).length === 0) {
      return <PlaceholderContainer>{placeholder}</PlaceholderContainer>;
    }

    if (multiple && options.length === selectedOptions.length) {
      return 'Todos';
    }

    if (multiple) {
      return options
        .filter((currentOption) => selectedOptions?.includes(currentOption.value))
        .map((selecteds) => selecteds.name)
        .join(', ');
    }

    return options.find((option) => (selectedOptions as string) === option.value)?.name || '';
  };

  const getCheckedOptions = (value: string): boolean => {
    if (Array.isArray(selectedOptions)) {
      return selectedOptions.some((selected) => selected === value);
    }

    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <span>
        {label && !buttonShape && <Label id={labelId}>{label}</Label>}
        <FormControlWrapper variant={type}>
          {!buttonShape && (
            <SelectWrapper
              className={`${className}`}
              data-testid={testID}
              displayEmpty
              inputProps={{ 'data-testid': `${testID}-input` }}
              renderValue={() => getSelectedOptionsName()}
              labelId={labelId}
              value={selectedOptions}
              onChange={onChange}
              MenuProps={MenuProps}
              multiple={multiple}
              error={error}
              required={required}
              name={name}
              id={id}
              disabled={disabled}
            >
              {options.map((option) => (
                <MenuItemWrapper key={option.name} value={option.value}>
                  {multiple && <Checkbox color="primary" checked={getCheckedOptions(option.value)} />}
                  {option.name}
                </MenuItemWrapper>
              ))}
            </SelectWrapper>
          )}
          {buttonShape && label && (
            <Button
              text={
                <>
                  {label}: <b>{getSelectedOptionsName()}</b>
                </>
              }
              type="button"
              size="large"
              testID={`${testID}-button`}
              buttonShape={buttonShape}
              className="animate fadeIn"
            />
          )}
        </FormControlWrapper>
      </span>
    </ThemeProvider>
  );
};
