import styled from 'styled-components';

export const BudgetContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 49%;
  height: 345px;

  border: 1.5px solid #e8ebf1;
  border-radius: 4px;
`;

interface ISectionProps {
  gap?: string;
}

export const SectionTop = styled.div`
  display: flex;
  height: inherit;
  padding: 10.7px;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
  gap: 5px;
`;

export const SectionMiddle = styled.div`
  height: inherit;
  padding: 10.7px;
  align-items: center;
  border-bottom: 1.5px solid #e8ebf1;
  border-top: 1.5px solid #e8ebf1;
  justify-content: space-evenly;
  gap: 20px;
  display: flex;
`;

export const SectionFooter = styled.div`
  display: flex;
  height: inherit;
  padding: 10.7px;
  align-items: center;
  background: #e8ebf1;
  justify-content: space-evenly;
  gap: 5px;
`;

export const SectionLabel = styled.div`
  font-weight: 500;
  color: #656565;
  font-size: 12px;
  white-space: nowrap;
  font-family: 'Roboto';
`;

export const SectionValue = styled.div`
  font-weight: 600;
  color: #000;
  font-size: 14px;
  font-family: 'Roboto';
`;

export const SectionValueGreen = styled(SectionValue)`
  color: #08844b;
`;

export const SectionValueRed = styled(SectionValue)`
  color: #b60000;
`;

export const SectionValueBlue = styled(SectionValue)`
  color: #0e86ca;
`;

export const SectionValueBig = styled(SectionValue)`
  font-size: 16px;
`;

export const SectionValueBigBlue = styled(SectionValueBig)`
  color: #0e86ca;
`;

export const SectionValueBigGreen = styled(SectionValueBig)`
  color: #08844b;
`;

export const SectionDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #333;
`;
