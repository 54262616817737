import React, { useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Button } from 'components/Button';
import UpdateIcon from 'assets/svg/update-icon.svg';
import CloseIcon from 'assets/svg/close-icon.svg';
import { Tooltip } from '@material-ui/core';
import DocumentService from 'services/DocumentService/documentService';
import * as S from './SendDocumentDialog.style';
import { ISendDocumentDialogProps } from './SendDocumentDialog.types';

const SendDocumentDialog = ({
  dialogVisibility,
  handleClose,
  title,
  subtitle,
  textDragAndDrop,
  errorMessage,
  extensions,
  replaceTooltipIcon,
  removeTooltipIcon,
  handleSubmit,
  loadingButton,
  btnSubmitText = 'SALVAR',
  btnCancelText = 'CANCELAR',
  hasSelect = false,
  testId = 'send-document-dialog',
  documentsTypesList,
  setDocumentCategoryByDocumentType,
  isBudgetDocumentWithoutContractOrAgreement = () => false,
}: ISendDocumentDialogProps) => {
  const verifyDocumentList = documentsTypesList || [{ name: '', value: '' }];
  const [documents, setDocuments] = useState<ImageListType>([]);
  const [documentSelected, setDocumentSelected] = useState(verifyDocumentList[0].name);
  const isButtonSubmitDisabled = documents?.length === 0 || isBudgetDocumentWithoutContractOrAgreement();

  const onChange = (imageList: ImageListType) => {
    setDocuments(imageList);
  };

  const handleCloseModal = (onImageRemoveAll: () => void) => {
    if (!loadingButton) {
      onImageRemoveAll();
      handleClose();
    }
  };

  const handleSelect = (value: string) => {
    setDocumentSelected(value);
    setDocumentCategoryByDocumentType && setDocumentCategoryByDocumentType(value);
  };

  const setDocumentIcon = (document: string) => {
    const typeDocument = document.split('.')[1];
    const iconSrc = DocumentService.documentIcon(typeDocument);

    return <S.DocumentIconStyle data-testid="icon-doc" src={iconSrc} />;
  };

  return (
    <ImageUploading value={documents} onChange={onChange} acceptType={extensions} allowNonImageType>
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemoveAll,
        onImageRemove,
        isDragging,
        dragProps,
        errors,
      }) => (
        <>
          <S.DialogBase
            data-testid={testId}
            open={dialogVisibility}
            onClose={() => handleCloseModal(onImageRemoveAll)}
            disableAutoFocus
          >
            <S.Title>{title}</S.Title>

            <S.Description>{subtitle}</S.Description>
            {hasSelect && (
              <S.SelectWrapper>
                <S.Label htmlFor="documents" testID="label-documents-type">
                  Tipo do documento
                </S.Label>
                <S.Select
                  testID="documents-select"
                  options={verifyDocumentList}
                  selectedOptions={documentSelected}
                  error={!documentSelected}
                  required={true}
                  labelId="documents"
                  name="documents"
                  onChange={({ target: { value } }): void => handleSelect(value as string)}
                />
              </S.SelectWrapper>
            )}

            {isBudgetDocumentWithoutContractOrAgreement() && (
              <S.ErrorMessage data-testid="budget-error-msg">
                * O atendimento ainda não está associado a um contrato. Por gentileza, atualize essa informação antes de
                submeter o orçamento.
              </S.ErrorMessage>
            )}

            {imageList.length === 0 && (
              <>
                <S.DragAndDropArea
                  data-testid="drag-drop-document"
                  onClick={onImageUpload}
                  isDragging={isDragging}
                  {...dragProps}
                >
                  <p>{textDragAndDrop}</p>
                </S.DragAndDropArea>

                {errors?.acceptType && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
              </>
            )}

            <S.DocumentList data-testid="document-dialog-list">
              {imageList.map(
                (document, index) =>
                  document.dataURL && (
                    <S.DocumentItem key={`${document.dataURL},${index}`}>
                      <Tooltip title={document.file?.name || ''} placement="top">
                        <S.DocumentTitle data-testid="document-dialog-title">{document.file?.name}</S.DocumentTitle>
                      </Tooltip>
                      {setDocumentIcon(document.file?.name || '')}
                      <Tooltip title={replaceTooltipIcon} placement="top">
                        <S.UpdateButton type="button" onClick={() => onImageUpdate(index)}>
                          <img src={UpdateIcon} alt="update-icon" /> Substituir
                        </S.UpdateButton>
                      </Tooltip>
                      <Tooltip title={removeTooltipIcon} placement="top">
                        <S.CloseButton type="button" onClick={() => onImageRemove(index)}>
                          <img src={CloseIcon} alt="close-icon" />
                        </S.CloseButton>
                      </Tooltip>
                    </S.DocumentItem>
                  )
              )}
            </S.DocumentList>

            {imageList.length > 0 && <>{errors?.acceptType && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}</>}
            <S.ContainerButton>
              <Button
                testID="upload-documents-cancel"
                type="button"
                variant="outlined"
                onClick={() => handleCloseModal(onImageRemoveAll)}
                disabled={loadingButton}
                text={btnCancelText}
              />
              <Button
                testID="upload-documents-submit"
                type="button"
                text={btnSubmitText}
                onClick={() => handleSubmit(onImageRemoveAll, documents)}
                disabled={isButtonSubmitDisabled}
                loading={loadingButton}
              />
            </S.ContainerButton>
          </S.DialogBase>
        </>
      )}
    </ImageUploading>
  );
};

export default SendDocumentDialog;
