import ApiService from 'services/ApiService';
import {
  IVehicleService,
  IVehicleRequest,
  IVehicleResponse,
  IVehicleTypesResponse,
  IPaintingTypeResponse,
  IVehicleBrandsResponse,
  IVehicleModelsResponse,
  IVehicleVersionsResponse,
} from './vehicleService.types';

const VehicleService: IVehicleService = {
  getVehicleTypes: async () => {
    const { data } = await ApiService.get<IVehicleTypesResponse>(`vehicle/types`);

    return data;
  },

  getVehicleBrands: async (description: string, page = 1, size = 10) => {
    const { data } = await ApiService.get<IVehicleBrandsResponse>(
      `vehicle/brands?description=${description}&page=${page}&size=${size}`
    );

    return data;
  },

  getVehicleModels: async (description: string, brandId: string) => {
    const { data } = await ApiService.get<IVehicleModelsResponse>(
      `vehicle/models?description=${description}&brandId=${brandId}`
    );

    return data;
  },

  getVehicleVersions: async (description: string, modelId: string) => {
    const { data } = await ApiService.get<IVehicleVersionsResponse>(
      `vehicle/versions?description=${description}&modelId=${modelId}`
    );

    return data;
  },

  saveVehicle: async (request: IVehicleRequest) => {
    const { data } = await ApiService.put<IVehicleResponse>('vehicle/', request);

    return data;
  },

  getPaintingTypes: async (companyId: string) => {
    const { data } = await ApiService.get<IPaintingTypeResponse>(
      `/company-workshop-services/painting-types?companyId=${companyId}`
    );
    return data;
  },

  updateFipeValue: async (plate: string, code: string, price: number) => {
    const { data } = await ApiService.put<IVehicleResponse>(`vehicle/fipe?plate=${plate}`, {
      code,
      price,
      origin: 'USER',
    });

    return data;
  },
};

export default VehicleService;
