import React, { useState, useCallback, useEffect } from 'react';

import moment from 'moment';
import { ThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Label } from 'components';
import { theme } from 'themes/default';
import { IOptions, ICompanySelectOptions } from 'components/Select/Select.types';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import AuthService from 'services/ApiService/AuthService/authService';
import { IAccount } from 'contexts/auth/types';
import { ICompany, IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { CompanyLabel } from 'components/CompanyLabel/CompanyLabel';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import { useConsumer } from 'contexts/consumers/useConsumer';
import RegulatorService from 'services/ApiService/RegulatorService/regulatorService';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import CompanySelectService from 'services/CompanySelectService/CompanySelectService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { InspectionPlatformEnum } from 'utils/InspectionEnum';
import { formValidations } from 'utils/form/validations';
import InspectionPlatformService from 'services/InspectionPlatformService/inspectionPlatformService';
import InspectionCategoryService from 'services/InspectionCategoryService/inspectionCategoryService';
import CaptureTypeService from 'services/ApiService/CaptureTypeService/captureTypeService';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import image from '../../assets/svg/form-image.svg';
import { ISchedulerForm } from './SchedulerForm.types';
import { normalizePhone } from '../../utils/formatPhone';
import { SelectRegulator } from './components/SelectRegulator/SelectRegulator';
import * as Style from './SchedulerForm.styles';

export const SchedulerForm = () => {
  const [companySelected, setCompanySelected] = useState<ICompanySelectOptions>(
    CompanySelectService.getDefaultCompany()
  );
  const [companiesList, setCompaniesList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [inspectionTypesList, setinspectionsTypeList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [categoriesList, setCategoriesList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [inspectionsPlatformsList, setinspectionsPlatformsList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [ufSelected, setUfSelected] = useState<string>('');
  const [citySelected, setCitySelected] = useState<string>('');
  const [regulatorSelected, setRegulatorSelected] = useState<IOptions>({ name: '', value: '' });
  const [inspectionTypeSelected, setInspectionTypeSelected] = useState<string>('');
  const [categorySelected, setCategorySelected] = useState<string>('');
  const [inspectionPlatformSelected, setInspectionPlatformSelected] = useState<string>('');
  const [dateTimeSelected, setDateTimeSelected] = useState<string>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { getCompanyByIdFromActiveProfile, getAllCompaniesForActiveProfile } = useActiveProfile();
  const { isRegulator } = useAuthDispatch();
  const { isConsumerActive } = useConsumer();
  const { register, unregister, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });

  const isProductAuto = ProductTypeService.isAuto(companySelected.productTypeCode);

  const getTodayDate = () => {
    const todayDate = new Date();
    return moment(todayDate).add(1, 'hour').format('YYYY-MM-DDTHH:mm');
  };

  const dateTimeValidation = dateTimeSelected === undefined || dateTimeSelected === '';
  const hasOneHourFutureSchedule = !!(!dateTimeValidation && dateTimeSelected < getTodayDate());
  const dateTimeValidationMessage = hasOneHourFutureSchedule
    ? 'Necessário agendar com 1 hora de antecedência.'
    : undefined;

  const resetInputs = (): void => {
    setValue('name', '');
    setValue('contactName', '');
    setValue('plate', '');
    setValue('datetime', '');
    setValue('code', '');
    setValue('phone', '');
    setUfSelected('');
    setCitySelected('');
    // setCitiesList([]);
  };

  const inspectionCategoryIsLocally = InspectionCategoryService.inspectionCategoryIsLocally(
    categoriesList,
    categorySelected
  );

  const inspectionPlatformIsCap = InspectionPlatformService.hasPlatformCAP(
    inspectionsPlatformsList,
    inspectionPlatformSelected
  );

  const companyInspectionPlatformIsCap = InspectionPlatformService.companyInspectionPlatformIsCap(companySelected);

  const companyInspectionCategoryIsLocally =
    InspectionCategoryService.companyInspectionCategoryIsLocally(companySelected);

  const inspectionIsLocally = () => {
    return inspectionCategoryIsLocally || companyInspectionCategoryIsLocally;
  };

  const contactInputPlaceholder = () => {
    return inspectionPlatformIsCap || companyInspectionPlatformIsCap
      ? 'Nome do contato'
      : 'Nome de quem receberá o link';
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const associateRegulatorWithInspection = async (inspection: IInspection) => {
    try {
      await RegulatorService.putRegulatorInInspections(regulatorSelected.value, [inspection.id]);
      setRegulatorSelected({ name: '', value: '' });
    } catch (error) {
      toast.warning('Não foi possível associar o perito selecionado.');
    }
  };

  const handleRegulatorSelection = async (inspection: IInspection): Promise<void> => {
    if (regulatorSelected.name.length) {
      await associateRegulatorWithInspection(inspection);
    }
  };

  const formatDateTime = (datetime: string): string => {
    return datetime ? moment(datetime).toISOString() : '';
  };

  const formatSchedulerForm = ({ code, contactName, name, plate, datetime, phone }: ISchedulerForm): ISchedulerForm => {
    return {
      code,
      contactName,
      name,
      plate: plate || '',
      datetime: formatDateTime(datetime),
      phone: normalizePhone(phone),
      companyId: companySelected.value,
      uf: ufSelected,
      city: citySelected,
      productTypeId: companySelected.productTypeId,
      createStrategy: 'WEB_APP',
      inspectionTypeId: inspectionTypeSelected,
      inspectionPlatformId: inspectionPlatformSelected,
      inspectionCategoryId: categorySelected,
    };
  };

  const sendLink = async (inspectionId: string): Promise<void> => {
    try {
      await InspectionStatusService.linkSent(inspectionId);

      toast.success('Link enviado!');
    } catch (error) {
      toast.error('O link não foi enviado, tente novamente na tela de Lista de Atendimentos.');
    }
  };

  const verifyCaptureType = async () => {
    const response = await CaptureTypeService.getCaptureTypeByCompanyId(companySelected.value);
    return CaptureTypeService.setCaptureTypeInspectionIsLocally(response.captureTypes);
  };

  const setCaptureTypeId = async (formRequest: ISchedulerForm) => {
    if (inspectionCategoryIsLocally) {
      const captureTypeId = await verifyCaptureType();

      if (captureTypeId !== undefined) {
        formRequest.captureTypeId = captureTypeId;
      }
    }
  };

  const createInspection = async (formRequest: ISchedulerForm): Promise<IInspection | undefined> => {
    try {
      await setCaptureTypeId(formRequest);

      const { inspection } = await InspectionService.postInspection(formRequest);

      toast.success('Atendimento criado!');

      return inspection;
    } catch (error) {
      toast.error('Ocorreu um erro e o atendimento não foi criado.');

      return undefined;
    }
  };

  const isInspectionAlreadyBeenCreated = async ({
    code,
    companyId = '',
    phone,
    plate,
  }: ISchedulerForm): Promise<boolean> => {
    try {
      const inspections = await InspectionService.checkIfExistInspection(companyId, code, phone, plate);

      if (inspections && inspections.length > 0) {
        throw new Error('Inspection duplicated');
      }

      return false;
    } catch (error) {
      toast.warning('Já existe um atendimento com esses dados!');

      return true;
    }
  };

  const onSubmit = async (schedulerForm: ISchedulerForm): Promise<void> => {
    try {
      setLoadingSubmit(true);

      const form = formatSchedulerForm(schedulerForm);

      const inspectionAlreadyBeenCreated = await isInspectionAlreadyBeenCreated(form);

      if (!inspectionAlreadyBeenCreated) {
        const inspection = await createInspection(form);

        if (inspection) {
          if (inspectionIsLocally()) {
            await handleRegulatorSelection(inspection);
          } else {
            await Promise.all([handleRegulatorSelection(inspection), sendLink(inspection.id)]);
          }
          resetInputs();
        }
      }
    } catch (error) {
      toast.error('Alguma coisa deu errado, tente novamente.');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const companiesToOption = useCallback((companies: ICompany[]): ICompanySelectOptions[] => {
    return companies.map(CompanySelectService.transformCompanyToOption);
  }, []);

  const getCompanies = useCallback(async (): Promise<IOptions[]> => {
    try {
      const companies = getAllCompaniesForActiveProfile();
      return companiesToOption(companies);
    } catch (error) {
      toast.error('Problema ao carregar as empresas, tente novamente.');

      return [];
    }
  }, [getAllCompaniesForActiveProfile, companiesToOption]);

  const disableSubmitButton = (): boolean => {
    return companySelected.value === '' || companySelected === null || companySelected === undefined;
  };

  const companiesSetUp = useCallback(async (): Promise<void> => {
    const companies = await getCompanies();

    setCompaniesList(companies.length > 0 ? companies : []);
    setCompanySelected(
      companies.length > 0 ? CompanySelectService.getFirstCompany(companies) : CompanySelectService.getDefaultCompany()
    );
  }, [getCompanies]);

  const getAccount = (): IAccount => {
    return AuthService.getAccountInLocalStorage();
  };

  const getAccountByUsername = (username: string): Promise<IAccount> => {
    return AuthService.getAccountByUsername(username);
  };

  const inputProps = {
    name: {
      'data-testid': 'input-name',
    },
    contactName: {
      'data-testid': 'input-contactName',
    },
    plate: {
      'data-testid': 'input-plate',
    },
    code: {
      'data-testid': 'input-code',
    },
    phone: {
      'data-testid': 'input-phone',
    },
    datetime: {
      'data-testid': 'input-datetime',
    },
  };

  const hasOneCompany = (): boolean => {
    return companiesList.length === 1;
  };

  const getCompanyName = (): string => {
    return companiesList[0]?.name;
  };

  const getNextMonthDate = () => {
    const todayDate = new Date();
    return moment(todayDate).add(30, 'days').format('YYYY-MM-DDTHH:mm');
  };

  const limitDateRange = () => {
    const datetimeInput = document.getElementById('datetime');
    datetimeInput?.setAttribute('min', getTodayDate());
    datetimeInput?.setAttribute('max', getNextMonthDate());
  };

  const handleSelectCompany = (value: string) => {
    const company = companiesList.find((item) => item.value === value);
    if (company) {
      setCompanySelected(company);
    }
  };

  const handleSelectInspectionTypes = (value: string) => {
    const inspectionType = inspectionTypesList.find((item) => item.value === value);
    if (inspectionType) {
      setInspectionTypeSelected(inspectionType.value);
    }
  };

  const handleSelectInspectionPlatforms = (value: string) => {
    const inspectionPlatform = inspectionsPlatformsList.find((item) => item.value === value);
    if (inspectionPlatform) {
      setInspectionPlatformSelected(inspectionPlatform.value);
    }
  };

  const handleSelectInspecitonCategories = (value: string) => {
    const category = categoriesList.find((item) => item.value === value);
    if (category) {
      setCategorySelected(category.value);
    }
  };

  const isSortSelected = (): boolean => {
    const platformSelected = inspectionsPlatformsList.find((item) => item.value === inspectionPlatformSelected);
    return platformSelected?.name.toUpperCase() === InspectionPlatformEnum.SORT;
  };

  const hasManyInspectionTypes = () => {
    const company = getCompanyByIdFromActiveProfile(companySelected.value);
    if (company?.inspectionTypes) {
      return company.inspectionTypes.length > 1;
    }
    return false;
  };

  const showSelectRegulator = () => {
    if (isConsumerActive(ConsumersEnum.ASSOCIATE_REGULATOR, companySelected.value) && !isRegulator()) {
      return true;
    }

    return false;
  };

  const hasManyInspectionPlatforms = () => {
    const company = getCompanyByIdFromActiveProfile(companySelected.value);
    if (company?.inspectionPlatforms) {
      return company.inspectionPlatforms.length > 1;
    }
    return false;
  };

  const hasManyCategories = () => {
    const company = getCompanyByIdFromActiveProfile(companySelected.value);
    if (company?.inspectionCategories) {
      return company.inspectionCategories.length > 1;
    }
    return false;
  };

  useEffect(() => {
    const { username } = getAccount();
    getAccountByUsername(username);
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      await companiesSetUp();
    })();
  }, [companiesSetUp]);

  useEffect(() => {
    const company = getCompanyByIdFromActiveProfile(companySelected.value);

    if (company?.inspectionTypes) {
      setinspectionsTypeList(company.inspectionTypes.map((item) => ({ name: item.description, value: item.id })));
      setInspectionTypeSelected(company.inspectionTypes[0].id);
    }

    if (company?.inspectionPlatforms) {
      setinspectionsPlatformsList(
        company.inspectionPlatforms.map((item) => ({ name: item.description, value: item.id }))
      );
      setInspectionPlatformSelected(company.inspectionPlatforms[0].id);
    }

    if (company?.inspectionCategories) {
      setCategoriesList(company.inspectionCategories.map((item) => ({ name: item.name, value: item.id })));
      setCategorySelected(company.inspectionCategories[0].id);
    }
  }, [companySelected, getCompanyByIdFromActiveProfile]);

  return (
    <ThemeProvider theme={theme}>
      <Style.SchedulerForm data-testid="scheduler-page">
        <Style.LeftContent>
          <Style.Form testID="scheduler-form" onSubmit={handleSubmit(onSubmit)}>
            <Style.Title>Gerar Atendimento</Style.Title>

            <Label htmlFor="companies" testID="label-companies">
              Operação
            </Label>
            {hasOneCompany() ? (
              <CompanyLabel testID="company-selected">{getCompanyName()}</CompanyLabel>
            ) : (
              <Style.SelectContainer>
                <Style.SelectWrapper
                  testID="companies-select"
                  options={companiesList}
                  selectedOptions={companySelected.value}
                  id="myselectoperations"
                  labelId="companies"
                  onChange={({ target: { value } }): void => handleSelectCompany(value as string)}
                />
              </Style.SelectContainer>
            )}

            {hasManyInspectionTypes() && (
              <>
                <Label htmlFor="inspectionTypes" testID="inspection-types">
                  Tipo de vistoria
                </Label>

                <Style.SelectContainer>
                  <Style.SelectWrapper
                    testID="inspection-types-select"
                    options={inspectionTypesList}
                    selectedOptions={inspectionTypeSelected}
                    id="inspectionTypesSelect"
                    labelId="inspectionTypes"
                    onChange={({ target: { value } }): void => handleSelectInspectionTypes(value as string)}
                  />
                </Style.SelectContainer>
              </>
            )}

            {hasManyInspectionPlatforms() && (
              <>
                <Label htmlFor="inspectionPlatforms" testID="inspection-platforms">
                  Plataforma de captura
                </Label>

                <Style.SelectContainer>
                  <Style.SelectWrapper
                    testID="inspection-platform-select"
                    options={inspectionsPlatformsList}
                    selectedOptions={inspectionPlatformSelected}
                    id="inspectionPlatformsSelect"
                    labelId="inspectionPlatforms"
                    onChange={({ target: { value } }): void => handleSelectInspectionPlatforms(value as string)}
                  />
                </Style.SelectContainer>
              </>
            )}

            {hasManyCategories() && (
              <>
                <Label htmlFor="inspectionCategories" testID="inspection-categories">
                  Categoria
                </Label>

                <Style.SelectContainer>
                  <Style.SelectWrapper
                    testID="inspection-categories-select"
                    options={categoriesList}
                    selectedOptions={categorySelected}
                    id="inspectionCategoriesSelect"
                    labelId="inspectionCategories"
                    onChange={({ target: { value } }): void => handleSelectInspecitonCategories(value as string)}
                  />
                </Style.SelectContainer>
              </>
            )}

            {isProductAuto ? (
              <>
                <Label htmlFor="plate" testID="label-plate">
                  Placa
                </Label>
                <Style.TextField
                  ariaLabelledby="placa"
                  error={hasError('plate')}
                  id="plate"
                  inputProps={inputProps.plate}
                  inputRef={register(formValidations.plate)}
                  helperText={errors?.plate?.message}
                  mask="aaa0*00"
                  maxLength="7"
                  minLength="0"
                  name="plate"
                  placeholder="Placa do veículo"
                  prepare={(value: string): string => value.toUpperCase()}
                  role="plate"
                  state={handleInputState('plate')}
                  type="text"
                  variant="outlined"
                />

                <Label htmlFor="code" testID="label-sinistro">
                  Sinistro
                </Label>
                <Style.TextField
                  ariaLabelledby="sinistro"
                  error={hasError('code')}
                  id="code"
                  inputProps={inputProps.code}
                  inputRef={register(formValidations.default)}
                  helperText={errors?.code?.message}
                  name="code"
                  placeholder="Número do sinistro"
                  state={handleInputState('code')}
                  type="number"
                  variant="outlined"
                />
              </>
            ) : (
              <>
                {unregister('plate')}
                <Label htmlFor="code" testID="label-sinistro">
                  Identificador
                </Label>
                <Style.TextField
                  ariaLabelledby="sinistro"
                  error={hasError('code')}
                  id="code"
                  inputProps={inputProps.code}
                  inputRef={register(formValidations.default)}
                  helperText={errors?.code?.message}
                  name="code"
                  placeholder="Código de identificação"
                  state={handleInputState('code')}
                  type="number"
                  variant="outlined"
                />
              </>
            )}

            <Label htmlFor="name" testID="label-name">
              {isProductAuto ? 'Segurado / Terceiro' : 'Cliente'}
            </Label>
            <Style.TextField
              ariaLabelledby="name"
              error={hasError('name')}
              id="name"
              inputProps={inputProps.name}
              inputRef={register(formValidations.default)}
              helperText={errors?.name?.message}
              name="name"
              placeholder={`Nome e sobrenome do ${isProductAuto ? 'segurado / terceiro' : 'cliente'}`}
              role="name"
              state={handleInputState('name')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="contactName" testID="label-contact-name">
              Nome do contato
            </Label>
            <Style.TextField
              ariaLabelledby="contato"
              error={hasError('contactName')}
              id="contactName"
              InputLabelProps={{ shrink: true }}
              inputProps={inputProps.contactName}
              inputRef={register(formValidations.default)}
              helperText={errors?.contactName?.message}
              name="contactName"
              placeholder={contactInputPlaceholder()}
              role="contactName"
              state={handleInputState('contactName')}
              type="text"
              variant="outlined"
            />

            <Label htmlFor="phone" testID="label-phone">
              Telefone
            </Label>
            <Style.TextField
              ariaLabelledby="celular"
              error={hasError('phone')}
              id="phone"
              inputProps={inputProps.phone}
              inputRef={register(formValidations.phone)}
              helperText={errors?.phone?.message}
              mask="(00) 0 0000-0000"
              name="phone"
              placeholder="Ex. (99) 9 9999-9999"
              state={handleInputState('phone')}
              type="text"
              variant="outlined"
            />

            {isSortSelected() && (
              <>
                <Label htmlFor="datetime" testID="label-datetime">
                  Agendar - Opcional
                </Label>
                <Style.DateTimePicker
                  ariaLabelledby="datetime"
                  id="datetime"
                  error={hasError('datetime')}
                  inputProps={inputProps.datetime}
                  helperText={dateTimeValidationMessage}
                  name="datetime"
                  placeholder="Data e hora"
                  role="datetime"
                  type="datetime-local"
                  state={handleInputState('datetime')}
                  variant="outlined"
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => {
                    setDateTimeSelected(target.value);
                  }}
                  onClick={() => limitDateRange()}
                  onKeyDown={(event: KeyboardEvent) => event.preventDefault()}
                />
              </>
            )}

            {showSelectRegulator() && (
              <SelectRegulator
                companies={companySelected.value ? [companySelected.value] : []}
                regulatorSelected={regulatorSelected}
                setRegulatorSelected={setRegulatorSelected}
              />
            )}

            <Style.Button
              testID="button-form"
              type="submit"
              text="CONFIRMAR"
              loading={loadingSubmit}
              fullWidth={true}
              size="large"
              loadingSize={30}
              loadingColor="#FFF"
              disabled={disableSubmitButton()}
            />
          </Style.Form>
        </Style.LeftContent>

        <Style.ImageWrapper image={image} />
      </Style.SchedulerForm>
    </ThemeProvider>
  );
};
