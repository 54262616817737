import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { NavTooltip } from 'components/Sidebar/components/Sidenav';
import { GalleryWrapper, LightboxWrapper, Loading, Thumbnail } from './Gallery.styles';
import { IGalleryProps, IGalleryWithUrlsProps, IImageSkeleton } from './Gallery.types';

const hiddenImageStyle = {
  width: 0,
  height: 0,
};

const ImageWithSkeleton = ({
  index,
  image,
  orientation,
  thumbnailWidth,
  thumbnailHeigth,
  description,
  setIsGalleryOpen,
  setPhotoIndex,
}: IImageSkeleton): JSX.Element => {
  const [loaded, setLoaded] = useState(false);

  const OpenImageGallery = (): void => {
    setIsGalleryOpen(true);
    setPhotoIndex(index);
  };

  const RenderImage: React.FC = () => {
    const thumb = (
      <Thumbnail
        data-testid="thumbImage"
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        src={image}
        onClick={OpenImageGallery}
      />
    );
    return (
      <>
        {description && (
          <NavTooltip title={description} arrow placement="top">
            {thumb}
          </NavTooltip>
        )}

        {!description && thumb}
      </>
    );
  };

  const RenderSkeletonLoading: React.FC = () => (
    <>
      <Loading
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        variant="rect"
        animation="wave"
      />

      <Thumbnail
        data-testid="thumbImage"
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        onLoad={(): void => setLoaded(true)}
        style={hiddenImageStyle}
        positionLoading={true}
        src={image}
        onClick={OpenImageGallery}
      />
    </>
  );

  return <>{loaded ? <RenderImage /> : <RenderSkeletonLoading />}</>;
};

const GalleryWithUrls = ({
  urls,
  orientation,
  thumbnailHeigth,
  thumbnailWidth,
  photoIndex,
  setPhotoIndex,
  isGalleryOpen,
  setIsGalleryOpen,
}: IGalleryWithUrlsProps): JSX.Element => {
  const urlLength = urls ? urls.length : 0;
  const getNextImageIndex = (): number => (photoIndex + 1) % urlLength;
  const getPreviousImageIndex = (): number => (photoIndex + urlLength - 1) % urlLength;

  return (
    <>
      {urls &&
        urls.map((url, index) => (
          <ImageWithSkeleton
            key={index}
            index={index}
            image={url}
            orientation={orientation}
            thumbnailWidth={thumbnailWidth}
            thumbnailHeigth={thumbnailHeigth}
            setIsGalleryOpen={setIsGalleryOpen}
            setPhotoIndex={setPhotoIndex}
          />
        ))}

      {urls && isGalleryOpen && (
        <LightboxWrapper data-testid="gallery">
          <Lightbox
            mainSrc={urls[photoIndex]}
            nextSrc={urls[getNextImageIndex()]}
            prevSrc={urls[getPreviousImageIndex()]}
            onCloseRequest={(): void => setIsGalleryOpen(false)}
            onMovePrevRequest={(): void => setPhotoIndex(getPreviousImageIndex())}
            onMoveNextRequest={(): void => setPhotoIndex(getNextImageIndex())}
          />
        </LightboxWrapper>
      )}
    </>
  );
};

export const Gallery = ({ urls, orientation, thumbnailHeigth, thumbnailWidth, testId }: IGalleryProps): JSX.Element => {
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);

  return (
    <GalleryWrapper data-testid={testId} orientation={orientation}>
      <GalleryWithUrls
        urls={urls}
        orientation={orientation}
        thumbnailHeigth={thumbnailHeigth}
        thumbnailWidth={thumbnailWidth}
        photoIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        isGalleryOpen={isGalleryOpen}
        setIsGalleryOpen={setIsGalleryOpen}
      />
    </GalleryWrapper>
  );
};
