import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Button } from 'components';
import { Select } from 'components/Select/Select';
import { DateRange } from 'components/DateRange/DateRange';

import DateService from 'services/DateService/dateService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { IOptions } from 'components/Select/Select.types';
import { DateRangeType, DateRangeStateType } from 'components/DateRange/DateRange.types';
import { ICompany, IInpectionCategory, IStatus } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { useInspectionByAdminFilter } from 'hooks/Inspections/InspectionsManagementHook';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useManagementDashboardDispatch } from 'contexts/managementDashboard/useManagementDashboard';
import moment from 'moment';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { StatusDescriptionEnum } from 'services/ApiService/InspectionStatusService/InspectionStatusService.types';
import InspectionFilterService from 'services/InspectionFilterService/inspectionFilterService';
import {
  InspectionsFilterTitle,
  InspectionsFilterWrapper,
  InspectionSelectFilters,
  ButtonWrapper,
} from './InspectionsFilter.styles';

const InspectionsFilter = () => {
  const buildInspectionDataFilters = () => {
    return InspectionFilterService.buildInspectionDataFilters();
  };

  const { getAllCompaniesIdsForActiveProfile, getAllCompaniesForActiveProfile } = useActiveProfile();
  const [companySelected, setCompanySelected] = useState<string>('');
  const [firstCompanySelected, setFirstCompanySelected] = useState<string>('');
  const [statusSelected, setStatusSelected] = useState<string[]>([]);
  const [firtsStatusSelected, setFirtsStatusSelected] = useState<string[]>(['']);
  const [periodSelected, setPeriodSelected] = useState<string>(DateService.getSubtractDaysFromNow(3));
  const [startDateSelected, setStartDateSelected] = useState<DateRangeStateType>(null);
  const [endDateSelected, setEndDateSelected] = useState<DateRangeStateType>(null);
  const [companiesList, setCompaniesList] = useState<IOptions[]>([]);
  const [statusList, setStatusList] = useState<IOptions[]>([]);
  const [categorySelected, setCategorySelected] = useState<string>('');
  const [categoryList, setCategoryList] = useState<IOptions[]>([]);
  const [periodList, setPeriodList] = useState<IOptions[]>([
    { name: '72 horas', value: DateService.getSubtractDaysFromNow(3) },
  ]);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [inspectionDataFilters, setInspectionDataFilters] = useState(() => buildInspectionDataFilters());
  const { inspectionsData, isValidating } = useInspectionByAdminFilter(inspectionDataFilters);
  const { setInspections, setSelectedCompanyId, setIsLoading } = useManagementDashboardDispatch();
  const { isConsumerActive } = useConsumer();

  const clearOptionsSelected = (): void => {
    setCompanySelected('');
    setStatusSelected([]);
    setPeriodSelected('');
    setStartDateSelected(null);
    setEndDateSelected(null);
    setInspections([]);
    setSelectedCompanyId('');
    hasMoreThanOneCategory() && setCategorySelected('');
    setShowFilters(true);
  };

  const getInspection = (companySelectedParam?: string) => {
    try {
      const company = companySelectedParam || companySelected;
      const [createdAtGreaterThanResponse, createdAtLessThanResponse] = getCreatedAtDate();
      const isInspectionReport = isConsumerActive(ConsumersEnum.INSPECTION_REPORT_MANAGEMENT, company);
      setSelectedCompanyId(company);
      setInspectionDataFilters({
        companiesId: [company],
        statusIds: statusSelected,
        createdAtGreaterThan: createdAtGreaterThanResponse,
        createdAtLessThan: createdAtLessThanResponse,
        isInspectionReport,
        inspectionCategoriesId: [categorySelected],
      });
      setShowFilters(false);
    } catch (error) {
      toast.error('Aconteceu algo ao pesquisar, tente novamente.');
      setInspections([]);
    }
  };

  const getCreatedAtDate = (): string[] => {
    if (periodIsSelected()) return getPeriodSelectedDate();
    if (dateRangeIsSelected()) return getDateRangeSelected();
    return getCreatedAtDefault();
  };

  const getPeriodSelectedDate = (): string[] => {
    return [periodSelected, ''];
  };

  const getDateRangeSelected = (): string[] => {
    return [
      DateService.fromMomentToIsoGreaterThan(startDateSelected as moment.Moment),
      DateService.fromMomentToIsoLessThan(endDateSelected as moment.Moment),
    ];
  };

  const getCreatedAtDefault = (): string[] => {
    return [
      startDateSelected
        ? DateService.fromMomentToIsoGreaterThan(startDateSelected as moment.Moment)
        : DateService.fromMomentToIsoGreaterThan(startDateDefault() as moment.Moment),
      endDateSelected ? DateService.fromMomentToIsoLessThan(endDateDefault() as moment.Moment) : '',
    ];
  };

  const startDateDefault = (): moment.Moment => {
    const momentTime = moment();
    setStartDateSelected(momentTime);

    return momentTime;
  };

  const endDateDefault = (): DateRangeStateType => {
    if (moment().isAfter(endDateSelected)) {
      setEndDateSelected(moment());

      return moment();
    }

    return endDateSelected;
  };

  const periodIsSelected = (): boolean => {
    return periodSelected !== '';
  };

  const dateRangeIsSelected = (): boolean => {
    return startDateSelected !== null && endDateSelected !== null;
  };

  const getCompanies = useCallback(async (): Promise<IOptions[]> => {
    try {
      const companies = getAllCompaniesForActiveProfile();

      return companyToOptions(companies);
    } catch (error) {
      toast.error('Problema ao carregar as operações, tente novamente.');

      return [];
    }
  }, [getAllCompaniesForActiveProfile]);

  const photosReceivedStatus = useCallback((status: IOptions[]): string => {
    return status.find(({ name }) => name === StatusDescriptionEnum.PHOTO_RECEIVED)?.value || '';
  }, []);

  const statusToOptions = (status: IStatus[]): IOptions[] => {
    return status.map(({ id, description: name }) => ({
      value: id,
      name,
    }));
  };

  const getStatus = useCallback(async (): Promise<IOptions[]> => {
    try {
      const status = await InspectionStatusService.getStatus();
      return statusToOptions(status);
    } catch (error) {
      toast.error('Problema ao carregar o status, tente novamente.');

      return [];
    }
  }, []);

  const companyToOptions = (companies: ICompany[]): IOptions[] => {
    return companies.map(({ id: value, name }) => ({ value, name }));
  };

  const disableFilterButton = (): boolean => {
    return companySelected === '' || statusSelected.length === 0;
  };

  const onChangePeriodSelect = (value: string): void => {
    setPeriodSelected(value as string);
    setStartDateSelected(null);
    setEndDateSelected(null);
  };

  const onDatesChange = (range: { startDate: DateRangeType; endDate: DateRangeType }): void => {
    setStartDateSelected(range.startDate);
    setEndDateSelected(range.endDate);
    setPeriodSelected('');
  };

  const showDate = (): string | boolean => {
    return showFilters || (!startDateSelected && !endDateSelected && periodSelected);
  };

  const showDateRange = (): boolean | DateRangeStateType => {
    return showFilters || !periodSelected;
  };

  const editFilters = (): void => {
    setShowFilters(true);
  };

  const hasMoreThanOneCategory = (): boolean => {
    return categoryList.length > 1;
  };

  const getCategorysInspection = useCallback((): IInpectionCategory[] | undefined => {
    const companies = getAllCompaniesForActiveProfile();

    return companies.find((company) => company.id === companySelected)?.inspectionCategories;
  }, [companySelected, getAllCompaniesForActiveProfile]);

  const getCategoriesOptions = useCallback((): IOptions[] => {
    const categories = getCategorysInspection();

    setCategorySelected(categories?.[0].id || '');

    return categories?.map(({ id, name }) => ({ value: id, name })) || [];
  }, [getCategorysInspection]);

  const initializeSelectedStatusId = useCallback(async () => {
    const status = await getStatus();
    if (status.length > 0) {
      setStatusSelected([photosReceivedStatus(status)]);
      setStatusList(status);
      setFirtsStatusSelected([photosReceivedStatus(status)]);
    }
  }, [getStatus, photosReceivedStatus]);

  const initializeSelectedCompanyId = useCallback(() => {
    const companies = getAllCompaniesIdsForActiveProfile();
    if (companies.length > 0) {
      const firstCompanyId = companies[0];
      setCompanySelected(firstCompanyId);
      setFirstCompanySelected(firstCompanyId);
    }
  }, [getAllCompaniesIdsForActiveProfile]);

  const inicializeSelectedStatusAndCompany = useCallback(() => {
    if (firstCompanySelected !== '' && firtsStatusSelected.length > 0) {
      getInspection(firstCompanySelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCompanySelected, firtsStatusSelected]);

  useEffect(() => {
    initializeSelectedCompanyId();
  }, [initializeSelectedCompanyId]);

  useEffect(() => {
    inicializeSelectedStatusAndCompany();
  }, [inicializeSelectedStatusAndCompany]);

  useEffect(() => {
    initializeSelectedStatusId();
  }, [initializeSelectedStatusId]);

  useEffect(() => {
    setIsLoading(isValidating);
  }, [isValidating, setIsLoading]);

  useEffect(() => {
    if (inspectionsData) {
      setInspections(inspectionsData);
    }
  }, [inspectionsData, setInspections]);

  useEffect(() => {
    (async (): Promise<void> => setCompaniesList(await getCompanies()))();
    ((): void => setPeriodList(DateService.getSelectPeriodOptions()))();
    ((): void => setCategoryList(getCategoriesOptions()))();
  }, [getCompanies, getCategoriesOptions]);

  return (
    <InspectionsFilterWrapper showFilters={showFilters}>
      <InspectionsFilterTitle>Filtros</InspectionsFilterTitle>

      <InspectionSelectFilters showFilters={showFilters}>
        <span>
          <Select
            className="animate fadeIn"
            testID="company-select"
            options={companiesList}
            selectedOptions={companySelected || []}
            labelId="companies"
            label="Operação"
            placeholder="Selecione uma operação"
            onChange={({ target: { value } }): void => setCompanySelected(value as string)}
            buttonShape={!showFilters}
          />
        </span>
        <span>
          <Select
            className="animate fadeIn"
            testID="inspection-status-select"
            options={statusList}
            selectedOptions={statusSelected}
            labelId="status"
            label="Status"
            placeholder="Selecione um status"
            onChange={({ target: { value } }): void => setStatusSelected(value as string[])}
            buttonShape={!showFilters}
            multiple
          />
        </span>
        {showDate() && (
          <span>
            <Select
              className="animate fadeIn"
              testID="date-select"
              options={periodList}
              selectedOptions={periodSelected}
              labelId="date"
              label="Período"
              placeholder="Selecione um período"
              onChange={({ target: { value } }): void => onChangePeriodSelect(value as string)}
              buttonShape={!showFilters}
            />
          </span>
        )}
        {showDateRange() && (
          <span>
            <DateRange
              className="animate fadeIn"
              testID="date-range-select"
              startDate={startDateSelected}
              endDate={endDateSelected}
              label="Período"
              onDatesChange={onDatesChange}
              buttonShape={!showFilters}
            />
          </span>
        )}

        <span>
          <Select
            className="animate fadeIn"
            testID="category-inspection-select"
            options={categoryList}
            selectedOptions={categorySelected}
            labelId="category-inspection"
            label="Tipo de Captura"
            placeholder="Selecione um tipo de captura"
            onChange={({ target: { value } }): void => setCategorySelected(value as string)}
            buttonShape={!showFilters}
            disabled={!hasMoreThanOneCategory()}
          />
        </span>

        {!showFilters && (
          <Button
            variant="outlined"
            type="button"
            text="Adicionar Filtro"
            onClick={(): void => editFilters()}
            testID="filter-add"
            buttonShape={true}
            size="large"
          />
        )}
      </InspectionSelectFilters>
      {showFilters && (
        <ButtonWrapper>
          <Button
            text="FILTRAR"
            type="submit"
            onClick={() => getInspection()}
            disabled={disableFilterButton()}
            loading={isValidating}
            loadingColor="#fff"
            loadingSize={20}
            size="large"
          />

          <Button
            variant="outlined"
            type="button"
            text="LIMPAR"
            onClick={(): void => clearOptionsSelected()}
            size="large"
          />
        </ButtonWrapper>
      )}
    </InspectionsFilterWrapper>
  );
};

export default InspectionsFilter;
