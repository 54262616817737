import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import React from 'react';
import GeolocationService from 'services/GeolocationService/geolocationService';
import Maps from 'pages/SchedulerList/components/Maps/Maps';
import * as S from './GeolocationCard.styles';

export interface IGeolocationCard {
  inspection: IInspection;
}

const GeolocationCard = ({ inspection: { photos, status } }: IGeolocationCard): JSX.Element => {
  const geolocationPhotos = GeolocationService.getGeolocationPhotos(photos);

  const existStatusPhotosReceived = status.some(({ type }) => type === StatusEnum.PHOTO_RECEIVED);

  const showGeolocationCard = existStatusPhotosReceived && GeolocationService.existCoordinatesInPhotos(photos);

  return (
    <>
      {showGeolocationCard ? (
        <S.GeolocationCardWrapper data-testid="geolocation-card">
          <Maps geolocationPhotos={geolocationPhotos} height="100%" width="100%" />
        </S.GeolocationCardWrapper>
      ) : (
        <S.GeolocationCardWrapper data-testid="no-geolocation-card">
          <S.GeolocationNotFound>Localização Indisponível</S.GeolocationNotFound>
        </S.GeolocationCardWrapper>
      )}
    </>
  );
};

export default GeolocationCard;
