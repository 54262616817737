import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import { Form as FormComponent, Button as ButtonComponent } from 'components';
import loginIllustration from 'assets/svg/login-illustration.svg';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: white;
`;

export const Illustration = styled.div`
  background-image: url(${loginIllustration});
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  width: 60%;
  height: 100%;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const Form = styled(FormComponent)`
  display: flex;
  justify-content: center;
  padding: 30px;
  width: 40%;
  max-width: 480px;

  @media (max-width: 950px) {
    margin: auto;
    width: 100%;
  }

  button {
    margin-top: 30px;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 40%;
  max-width: 480px;

  @media (max-width: 950px) {
    margin: auto;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #323232;
  margin: 0 0 30px 0;
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: #323232;
  margin: 0 0 30px 0;
`;

export const LinkNavigation = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000;
  text-align: center;
  margin-top: 20px;
  text-decoration: none;
`;

export const TextField = withTheme(styled(TextFieldComponent)`
  && {
    margin-bottom: ${(props): number => props.theme.spacing(2.5)}px;
  }
`);

export const Button = withTheme(styled(ButtonComponent)`
  && {
    margin-top: ${(props): number => props.theme.spacing(2)}px;
  }
`);
