import styled from 'styled-components';

export const ConclusionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding-bottom: 13px;
  padding-right: 15px;
  padding-left: 15px;

  gap: 16px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    width: 100%;
  }
`;
