import ApiService from 'services/ApiService';
import { IFederalUnitResponse, IFederalUnitService } from './types';

const FederalUnitService: IFederalUnitService = {
  getAll: async (): Promise<IFederalUnitResponse> => {
    const { data } = await ApiService.get('/federal-unit');
    return data;
  },
};

export default FederalUnitService;
