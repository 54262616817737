import React, { useEffect, useState } from 'react';
import { Form } from 'components/Form';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { formValidations } from 'utils/form/validations';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { IEditInspectionDialog } from './EditInspectionDialog.types';
import { isAxiosError } from 'utils/isAxiosError';
import * as Style from './EditInspectionDialog.styles';

const inputProps = {
  code: {
    'data-testid': 'input-code',
  },
};

export const EditInspectionDialog = ({
  dialogVisibility,
  handleClose,
  inspection,
  callback,
}: IEditInspectionDialog) => {
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const isProductAuto = ProductTypeService.isAuto(inspection.productType?.code);

  const showErrorToast = (error: number | undefined) => {
    if (error === 409) {
      toast.warning('Já existe um atendimento com esses dados!');
    } else {
      toast.error('Erro ao atualizar informações!');
    }
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      await InspectionService.putCodeOrPlate(inspection.id, { code });

      toast.success('Dados atualizados com sucesso!');

      await callback();
    } catch (error) {
      if (isAxiosError(error)) {
        showErrorToast(error.response?.status);
      } else {
        toast.error('Erro ao receber informações!');
      }
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const isDisabled = () => {
    return code === inspection.code || loading;
  };

  useEffect(() => {
    setCode(inspection.code);
  }, [inspection]);

  return (
    <Style.DialogBase
      data-testid="edit-inspection-dialog"
      open={dialogVisibility}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <Style.DialogTitle>Editar Atendimento</Style.DialogTitle>
      <Style.FormWrapper>
        <Form testID="edit-inspection-form" onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="code" testID="label-code">
            {isProductAuto ? 'Sinistro' : 'Identificador'}
          </Label>
          <Style.TextField
            ariaLabelledby="code"
            error={hasError('code')}
            id="code"
            inputProps={inputProps.code}
            inputRef={register(formValidations.default)}
            helperText={errors?.code?.message}
            name="code"
            state={handleInputState('code')}
            type="text"
            variant="outlined"
            value={code}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => setCode(target.value)}
          />

          <Style.DialogActions>
            <Style.ButtonWrapper>
              <Button testID="button-cancel" type="button" variant="outlined" onClick={handleClose} text="CANCELAR" />
            </Style.ButtonWrapper>
            <Style.ButtonWrapper>
              <Button
                testID="button-submit"
                type="submit"
                disabled={isDisabled()}
                text="SALVAR"
                loading={loading}
                loadingSize={26}
              />
            </Style.ButtonWrapper>
          </Style.DialogActions>
        </Form>
      </Style.FormWrapper>
    </Style.DialogBase>
  );
};
