import { IPreviousInspectionForm, IPreviousInspectionPayload } from 'pages/PreviousInspection/PreviousInspection.types';
import { ISchedulerForm, ISchedulerPayload } from 'pages/SchedulerForm/SchedulerForm.types';

export const createInspectionPayload = (inspectionForm: ISchedulerForm): ISchedulerPayload => {
  const payload: ISchedulerPayload = {
    code: inspectionForm.code,
    createStrategy: inspectionForm.createStrategy,
    customer: {
      name: inspectionForm.name,
      contactName: inspectionForm.contactName,
      phone: inspectionForm.phone,
    },
    companyId: inspectionForm.companyId,
    productTypeId: inspectionForm.productTypeId,
    uf: inspectionForm.uf,
    city: inspectionForm.city,
    inspectionCategoryId: inspectionForm.inspectionCategoryId,
    inspectionPlatformId: inspectionForm.inspectionPlatformId,
    inspectionTypeId: inspectionForm.inspectionTypeId,
  };

  if (inspectionForm.plate) {
    payload.product = {
      plate: inspectionForm.plate,
    };
  }

  if (inspectionForm.datetime) {
    payload.schedule = inspectionForm.datetime;
  }

  if (inspectionForm.captureTypeId) {
    payload.captureTypeId = inspectionForm.captureTypeId;
  }

  return payload;
};

export const createPreviousInspectionPayload = ({
  companyId,
  proposalNumber,
  plate,
  contactName,
  clientCode,
  email,
  phone,
  brokerName,
  brokerCode,
  brokerCodeRegistered,
  subsidiaryName,
  subsidiaryCode,
  productTypeId,
  createStrategy,
}: IPreviousInspectionForm): IPreviousInspectionPayload => {
  const payload: IPreviousInspectionPayload = {
    companyId,
    createStrategy,
    proposalNumber,
    product: {
      plate,
    },
    customer: {
      contactName,
      clientCode,
      email,
      phone,
    },
    broker: {
      name: brokerName,
      code: brokerCode,
      codeRegistered: brokerCodeRegistered,
    },
    subsidiary: {
      name: subsidiaryName,
      code: subsidiaryCode,
    },
    productTypeId,
  };

  return payload;
};

export const getInspectionIdFromOldPathname = (url: string): string => {
  const id = url.match(/\w+/g);
  return id ? id[2] : '';
};

export const getInspectionIdFromPathname = (url: string): string => {
  const segments = url.split('/');
  return segments.length >= 5 ? segments[4] : '';
};
