import { IBudgetPartServiceHours, IBudgetPartServiceType, IBudgetPartServiceTypeEnum } from 'commom/types/Budget.types';
import { useAppSelector } from 'hooks/hooks';
import React, { createContext, useContext, useState } from 'react';
import { IVehiclePartPrice, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import { selectLaborDiscount, selectLaborIncrease } from 'stores/presentBudget/presentBudget.selector';
import { calculateNetPrice } from 'utils/calculateNetPrice';

type PartLaborCostContextValue = {
  isServiceTypeRepair: boolean;
  serviceType: IBudgetPartServiceType;
  partServiceHours: IBudgetPartServiceHours;
  quantityParts: number;
  partDiscount: number;
  partPrice: number;
  setServiceType: React.Dispatch<React.SetStateAction<IBudgetPartServiceType>>;
  setPartServiceHours: React.Dispatch<React.SetStateAction<IBudgetPartServiceHours>>;
  setQuantityParts: React.Dispatch<React.SetStateAction<number>>;
  setPartDiscount: React.Dispatch<React.SetStateAction<number>>;
  setPartPrice: React.Dispatch<React.SetStateAction<number>>;
};

const PartLaborCostContext = createContext({} as PartLaborCostContextValue);

export const usePartLaborCostContext = () => useContext(PartLaborCostContext);

interface IPartLaborCostContextProviderProps {
  children: React.ReactNode;
  partTypeSelected: IVehiclePartType;
  partPriceSelected: IVehiclePartPrice | null;
}

export function PartLaborCostContextProvider({
  children,
  partTypeSelected,
  partPriceSelected,
}: IPartLaborCostContextProviderProps) {
  const [serviceType, setServiceType] = useState<IBudgetPartServiceType>({
    code: IBudgetPartServiceTypeEnum.REPLACEMENT,
    description: 'Troca',
  });
  const [partServiceHours, setPartServiceHours] = useState<IBudgetPartServiceHours>(
    partTypeSelected.partTemp.partTempOriginal
  );
  const discount = useAppSelector(selectLaborDiscount);
  const increase = useAppSelector(selectLaborIncrease);
  const [quantityParts, setQuantityParts] = useState(1);
  const [partDiscount, setPartDiscount] = useState(0);
  const [partPrice, setPartPrice] = useState(calculateNetPrice(partPriceSelected?.price || 0, discount, increase));

  const isServiceTypeRepair = serviceType.code === IBudgetPartServiceTypeEnum.REPAIR;

  return (
    <PartLaborCostContext.Provider
      value={{
        isServiceTypeRepair,
        serviceType,
        partServiceHours,
        quantityParts,
        partDiscount,
        partPrice,
        setServiceType,
        setPartServiceHours,
        setQuantityParts,
        setPartDiscount,
        setPartPrice,
      }}
    >
      {children}
    </PartLaborCostContext.Provider>
  );
}
