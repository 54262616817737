/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { Tooltip } from '@mui/material';
import {
  GalleryWrapper,
  LightboxWrapper,
  Loading,
  Thumbnail,
  ImageCardWrapper,
  ImageIndexBadge,
  ImageCardTitle,
  ImageSkeletonIcon,
  SwapIcon,
  ThumbnailWrapper,
} from './InspectionGallery.style';
import { IInspectionGalleryProps, IInspectionGalleryWithImagesProps, IImageSkeleton } from './InspectionGallery.types';

const hiddenImageStyle = {
  width: 0,
  height: 0,
};

const ImageWithSkeleton = ({
  index,
  image,
  orientation,
  thumbnailWidth,
  thumbnailHeigth,
  isOrganizingPhotos,
  setIsGalleryOpen,
  setPhotoIndex,
  selectSwappPhoto,
  canSelectSwappPhotos,
  isPhotoSelected,
}: IImageSkeleton): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const { isFeatureToggleActive } = useFeatureToggles();

  const OpenImageGallery = (): void => {
    setIsGalleryOpen(true);
    setPhotoIndex(index);
  };

  const RenderImage: React.FC = () => {
    return (
      <ThumbnailWrapper
        isOrganizingPhotos={isOrganizingPhotos}
        canSelectSwappPhotos={canSelectSwappPhotos()}
        isPhotoSelected={isPhotoSelected(image.path)}
      >
        <ImageSkeletonIcon
          id="imageIcon"
          isOrganizingPhotos={isOrganizingPhotos}
          isPhotoSelected={isPhotoSelected(image.path)}
        >
          <SwapIcon />
        </ImageSkeletonIcon>
        {isOrganizingPhotos && isFeatureToggleActive('SWAPP_PHOTO') ? (
          <Thumbnail
            data-testid="thumbImage"
            isPhotoSelected={isPhotoSelected(image.path)}
            orientation={orientation}
            width={thumbnailWidth}
            height={thumbnailHeigth}
            src={image.url}
            onClick={() => selectSwappPhoto(image)}
          />
        ) : (
          <>
            <Thumbnail
              data-testid="thumbImage"
              orientation={orientation}
              width={thumbnailWidth}
              height={thumbnailHeigth}
              src={image.url}
              onClick={OpenImageGallery}
            />
          </>
        )}
      </ThumbnailWrapper>
    );
  };

  const RenderSkeletonLoading: React.FC = () => (
    <>
      <Loading
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        variant="rect"
        animation="wave"
      />

      <Thumbnail
        data-testid="thumbImage"
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        onLoad={(): void => setLoaded(true)}
        style={hiddenImageStyle}
        positionLoading={true}
        src={image.url}
        onClick={OpenImageGallery}
      />
    </>
  );

  return <>{loaded ? <RenderImage /> : <RenderSkeletonLoading />}</>;
};

const GalleryWithImages = ({
  images,
  orientation,
  thumbnailHeigth,
  thumbnailWidth,
  photoIndex,
  setPhotoIndex,
  isGalleryOpen,
  setIsGalleryOpen,
  selectSwappPhoto,
  canSelectSwappPhotos,
  isPhotoSelected,
  isOrganizingPhotos,
  companyId,
}: IInspectionGalleryWithImagesProps): JSX.Element => {
  const ImagesLength = images ? images.length : 0;
  const getNextImageIndexByImagesLength = (): number => (photoIndex + 1) % ImagesLength;
  const getPreviousImageIndexByImagesLength = (): number => (photoIndex + ImagesLength - 1) % ImagesLength;
  const renderImageIndex = (index: number) => (index < 10 ? `0${index}` : index);

  return (
    <>
      {images.map((image, index) => (
        <ImageCardWrapper key={index} role="image-card">
          <ImageIndexBadge data-testid="index-badge">{renderImageIndex(index + 1)}</ImageIndexBadge>
          <Tooltip title={image.description} placement="top">
            <ImageCardTitle data-testid="image-description">{image.description}</ImageCardTitle>
          </Tooltip>
          <ImageWithSkeleton
            key={index}
            index={index}
            image={image}
            description={image.description}
            orientation={orientation}
            thumbnailWidth={thumbnailWidth}
            thumbnailHeigth={thumbnailHeigth}
            isOrganizingPhotos={isOrganizingPhotos}
            selectSwappPhoto={selectSwappPhoto}
            canSelectSwappPhotos={canSelectSwappPhotos}
            isPhotoSelected={isPhotoSelected}
            setIsGalleryOpen={setIsGalleryOpen}
            setPhotoIndex={setPhotoIndex}
            companyId={companyId}
          />
        </ImageCardWrapper>
      ))}

      {isGalleryOpen && (
        <LightboxWrapper data-testid="gallery">
          <>
            <Lightbox
              mainSrc={images[photoIndex].url}
              nextSrc={images[getNextImageIndexByImagesLength()].url}
              prevSrc={images[getPreviousImageIndexByImagesLength()].url}
              onCloseRequest={(): void => setIsGalleryOpen(false)}
              onMovePrevRequest={(): void => setPhotoIndex(getPreviousImageIndexByImagesLength())}
              onMoveNextRequest={(): void => setPhotoIndex(getNextImageIndexByImagesLength())}
              imageCaption={images[photoIndex].comment}
              imageTitle={images[photoIndex].name}
            />
          </>
        </LightboxWrapper>
      )}
    </>
  );
};

export const InspectionGallery = ({
  images,
  orientation,
  thumbnailHeigth,
  thumbnailWidth,
  testId,
  isOrganizingPhotos,
  selectSwappPhoto,
  canSelectSwappPhotos,
  isPhotoSelected,
  companyId,
}: IInspectionGalleryProps): JSX.Element => {
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);
  return (
    <GalleryWrapper data-testid={testId} orientation={orientation}>
      <GalleryWithImages
        images={images}
        orientation={orientation}
        thumbnailHeigth={thumbnailHeigth}
        thumbnailWidth={thumbnailWidth}
        photoIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        isGalleryOpen={isGalleryOpen}
        setIsGalleryOpen={setIsGalleryOpen}
        selectSwappPhoto={selectSwappPhoto}
        canSelectSwappPhotos={canSelectSwappPhotos}
        isPhotoSelected={isPhotoSelected}
        isOrganizingPhotos={isOrganizingPhotos}
        companyId={companyId}
      />
    </GalleryWrapper>
  );
};
