import { AnchorTypesEnum, Drawer } from 'components/Drawer';
import { useAuthState } from 'contexts/auth/useAuth';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { IEditPartRequest } from 'services/ApiService/BudgetService/BudgetService.types';
import { selectBudgetId, selectLaborDiscount, selectLaborIncrease } from 'stores/presentBudget/presentBudget.selector';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { calculateNetPrice } from 'utils/calculateNetPrice';
import { PartDrawer } from '../PartDrawer';
import { EditPartWrapper } from './EditPartDrawer.styles';

interface IEditPartDrawerProps {
  openDrawer: boolean;
  onClose: () => void;
  partId: string;
}

const EditPartDrawer = ({ openDrawer, onClose, partId }: IEditPartDrawerProps) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const dispatch = useAppDispatch();
  const budgetId = useAppSelector(selectBudgetId);
  const discount = useAppSelector(selectLaborDiscount);
  const increase = useAppSelector(selectLaborIncrease);
  const { account } = useAuthState();
  const {
    serviceType,
    partName,
    partDiscount,
    partServiceHours,
    quantityParts,
    partSku,
    partPrice,
    isServiceTypeRepair,
    isPartyManuallyInserted,
    partOriginalPrice,
    partOriginalServiceHours,
    partSupplier,
    partSourceType,
  } = usePartContext();

  const isNetPriceModified = partPrice !== calculateNetPrice(partOriginalPrice, discount, increase);

  const getInputNetPrice = () => {
    return isNetPriceModified && !isPartyManuallyInserted ? { inputNetPrice: partPrice } : {};
  };

  const onSubmit = async () => {
    try {
      setIsLoadingSubmit(true);
      const partToEdit: IEditPartRequest = {
        _id: partId,
        user: account.id,
        name: partName,
        sku: partSku,
        quantity: quantityParts,
        originalPrice: partOriginalPrice,
        discount: isServiceTypeRepair ? 0 : partDiscount,
        isManualEntry: isPartyManuallyInserted,
        serviceType: serviceType,
        partSupplier: partSupplier,
        sourceType: partSourceType,
        originalServiceHours: partOriginalServiceHours,
        serviceHours: {
          removeAndInstall: partServiceHours.removeAndInstall,
          repair: isServiceTypeRepair ? partServiceHours.repair : 0,
          painting: partServiceHours.painting,
        },
        ...getInputNetPrice(),
      };

      const budget = await BudgetService.editPart(partToEdit, budgetId);

      dispatch(setBudget(budget));
      setIsLoadingSubmit(false);
      onClose();
    } catch (error) {
      toast.error('Erro ao editar peça');
      setIsLoadingSubmit(false);
    }
  };

  return (
    <Drawer.Root openDrawer={openDrawer} anchor={AnchorTypesEnum.RIGHT} onClose={onClose}>
      <EditPartWrapper>
        <PartDrawer.Header handleBackButton={onClose} isEditMode={true} />
        <PartDrawer.Content />
        <PartDrawer.Footer
          onSubmit={onSubmit}
          handleClose={onClose}
          loadingSubmit={isLoadingSubmit}
          isEditMode={true}
        />
      </EditPartWrapper>
    </Drawer.Root>
  );
};

export default EditPartDrawer;
