import styled from 'styled-components';
import { answerStatus } from './QuestionInput.types';

interface IContainerProps {
  isQuestionSelectAndInput: boolean;
  answerStatus: answerStatus;
}

enum AnsweredColor {
  NOT_ANSWERED = '#DC3D3D',
  ANSWERED = 'rgba(9, 152, 87, 1)',
  INITIAL_STATE = '#DADADA',
}

export const Container = styled.div<IContainerProps>`
  padding: 8px 12px;
  display: flex;
  align-items: ${({ isQuestionSelectAndInput }) => (isQuestionSelectAndInput ? 'flex-end' : 'center')};
  justify-content: space-between;
  gap: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ answerStatus }) => AnsweredColor[answerStatus]};
  border-radius: 5px;
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
`;

export const ToggleButtonContainer = styled.div`
  min-width: 200px;
  width: 200px;
`;
