import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Header = styled.div`
  min-height: 65px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  border-bottom: 1px solid #e8e8e8;
`;

export const Title = styled.h1`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;
`;

export const Card = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
`;

export const CardHeader = styled.div`
  background: #fff;
  height: 60px;
  padding: 0 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ced6e5;
`;

export const CardTitle = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
`;

export const CardBody = styled.div`
  padding: 30px;
`;

export const Footer = styled.div`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 8px 12px;
  border-top: 1px solid #ced6e5;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
`;
