import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: start;

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
`;

export const QuestionGroupChunkedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
