import React from 'react';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { ConclusionContent } from './Conclusion.styles';
import ConclusionForm from './ConclusionForm/ConclusionForm';
import ConclusionList from './ConclusionList/ConclusionList';
import { useAppSelector } from 'hooks/hooks';
import { selectCurrentResponsible } from 'stores/presentInspection/presentInspection.selector';

const Conclusion = () => {
  const { account } = useAuthState();
  const { isAdmin } = useAuthDispatch();
  const currentResponsible = useAppSelector(selectCurrentResponsible);

  const isResponsibleOrAdmin = currentResponsible?.id === account?.id || isAdmin();

  return (
    <>
      <ConclusionContent>
        {isResponsibleOrAdmin && <ConclusionForm />}
        <ConclusionList />
      </ConclusionContent>
    </>
  );
};

export default Conclusion;
