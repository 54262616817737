import ApiService from 'services/ApiService';
import { NotificationStatusEnum } from 'commom/types/Notification.types';
import { INotificationService, INotificationsResponse } from './notificationService.types';

const NotificationService: INotificationService = {
  sendByInspectionId: async (inspectionId: string) => {
    await ApiService.post(`/notifications?inspectionId=${inspectionId}`);
  },

  getNotifications: async (status: NotificationStatusEnum[], page: number, size: number) => {
    const response = await ApiService.get<INotificationsResponse>(
      `/notifications/filter?status=${status.join(',')}&page=${page}&size=${size}`
    );
    return response;
  },

  readAllNotifications: async () => {
    await ApiService.put(`/notifications/read-all`);
  },

  markNotificationAsRead: async (id: string) => {
    await ApiService.put(`/notifications/mark-as-read/${id}`);
  },
};

export default NotificationService;
