import ApiService from '..';
import { ICompanyConsumers, IConsumerService } from './types';

const COMPANY_CONSUMERS = '@COMPANY_CONSUMERS';

const ConsumerService: IConsumerService = {
  getCompanyConsumersByAccount: async () => {
    const { data } = await ApiService.get<ICompanyConsumers>('/company-consumer');
    return data;
  },

  setCompanyConsumersInLocalStorage: (companyConsumers: ICompanyConsumers): void => {
    localStorage.setItem(COMPANY_CONSUMERS, JSON.stringify(companyConsumers));
  },

  getCompanyConsumersInLocalStorage: (): ICompanyConsumers => {
    const companyConsumers = localStorage.getItem(COMPANY_CONSUMERS);

    if (!companyConsumers) return {} as ICompanyConsumers;

    return JSON.parse(companyConsumers);
  },

  deleteCompanyConsumersInLocalStorage: (): void => {
    localStorage.removeItem(COMPANY_CONSUMERS);
  },
};

export default ConsumerService;
