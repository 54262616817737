import styled from 'styled-components';

interface IArchivedConclusion {
  isArchived?: boolean;
}

export const CardContainer = styled.div<IArchivedConclusion>`
  display: flex;
  width: 100%;
  min-height: 191px;

  border: 1px solid #c8cdd8;
  border-radius: 8px;

  flex-direction: column;
  padding: 25px 18px;

  &:first-child {
    border: ${({ isArchived }) => (isArchived ? '1px solid #c8cdd8' : '2px solid #000000')};
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;

  border-bottom: 1px solid #eff2f7;
  padding: 0 9px 15px 9px;
`;

export const DataAndActionContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const ButtonArchive = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 35px;
  width: 35px;
  border-radius: 100%;

  background: transparent;
  color: #6a758b;

  border: none;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover:not(:disabled) {
    background-color: #f2f5fa;
  }
`;

export const DataContainer = styled.span`
  display: flex;
  height: 28px;
  width: 174px;

  align-items: center;
  justify-content: center;

  border: 1px solid #c8cdd8;
  background-color: #f2f5fa;
  color: #1c1c1c;

  border-radius: 15px;
  padding: 3px 6px;
  gap: 2px;

  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;

  svg {
    width: 18px;
    height: 12px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 9px;
`;

export const ReasonAndTypeTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #4d4d4d;
`;

export const ReasonAndTypeContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 12px;
  gap: 23px;
  justify-content: start;
  align-items: center;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
`;

export const CommentContent = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
`;
