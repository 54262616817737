import React from 'react';
import { Container } from './DamageReportForm.styles';
import DamageQuestionGroups from './DamageQuestionGroups/DamageQuestionGroups';
import { useAppSelector } from 'hooks/hooks';
import { selectDamageReportQuestionGroup } from 'stores/presentBudget/presentBudget.selector';
import { IBudgetReportQuestion } from 'commom/types/BudgetReport.types';

const DamageReportForm = () => {
  const questionsGroups = useAppSelector(selectDamageReportQuestionGroup);
  const questionGroupId = questionsGroups?.[0]?._id;

  const divideParts = (partItems: IBudgetReportQuestion[]) => {
    const half = Math.ceil(partItems.length / 2);
    return [partItems.slice(0, half), partItems.slice(half)];
  };

  const [firstHalf, secondHalf] = questionsGroups?.[0]?.questions
    ? divideParts(questionsGroups?.[0]?.questions)
    : [[], []];

  const showParts = !!firstHalf.length && !!secondHalf.length && !!questionGroupId;

  return (
    <Container>
      {showParts && (
        <>
          <DamageQuestionGroups questions={firstHalf} questionGroupId={questionGroupId} groupIdentifier="firstHalf" />
          <DamageQuestionGroups questions={secondHalf} questionGroupId={questionGroupId} groupIdentifier="secondHalf" />
        </>
      )}
    </Container>
  );
};

export default DamageReportForm;
