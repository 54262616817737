import React, { useCallback } from 'react';
import {
  Container,
  Header,
  Title,
  RecoverableVehicleContent,
  InformationContent,
  Subtitle,
  CheckBoxContainer,
  Description,
  TextInput,
  CheckBox,
} from './GeneralEvaluation.styles';
import RadioButtonsGroup from 'components/RadioButtonsGroup/RadioButtonsGroup';
import { GeneralEvaluationEnum } from 'commom/types/BudgetReport.types';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import {
  selectRescuedReportIsDraft,
  selectVehicleEvaluationEngineNumber,
  selectVehicleEvaluationGeneralEvaluation,
  selectVehicleEvaluationJustifications,
  selectVehicleEvaluationWithoutEngineAccess,
} from 'stores/presentBudget/presentBudget.selector';
import {
  setVehicleEvaluationEngineNumber,
  setVehicleEvaluationGeneralEvaluation,
  setVehicleEvaluationJustifications,
  setVehicleEvaluationWithoutEngineAccess,
} from 'stores/presentBudget/presentBudget.store';
import { debounce } from 'lodash';

const debouncedDispatch = debounce((dispatch, action) => {
  dispatch(action);
}, 500);

const GeneralEvaluation = () => {
  const dispatch = useAppDispatch();
  const generalEvaluation = useAppSelector(selectVehicleEvaluationGeneralEvaluation);
  const withoutEngineAccess = useAppSelector(selectVehicleEvaluationWithoutEngineAccess);
  const engineNumber = useAppSelector(selectVehicleEvaluationEngineNumber);
  const justifications = useAppSelector(selectVehicleEvaluationJustifications);
  const isDraftRescuedReport = useAppSelector(selectRescuedReportIsDraft);

  const recoverableVehicleOptions = [
    { id: GeneralEvaluationEnum.RECOVERABLE, title: 'Veículo recuperável' },
    { id: GeneralEvaluationEnum.NON_RECOVERABLE, title: 'Veículo não recuperável' },
  ];

  const handleChangeRecoverableVehicle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setVehicleEvaluationGeneralEvaluation(event.target.value as GeneralEvaluationEnum));
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setVehicleEvaluationWithoutEngineAccess(event.target.checked));
  };

  const handleChangeEngineNumber = useCallback(
    (value: string) => {
      debouncedDispatch(dispatch, setVehicleEvaluationEngineNumber(value));
    },
    [dispatch]
  );

  const handleChangesJustifications = useCallback(
    (value: string) => {
      debouncedDispatch(dispatch, setVehicleEvaluationJustifications(value));
    },
    [dispatch]
  );

  return (
    <Container>
      <Header>
        <Title>Avaliação Geral</Title>

        <RecoverableVehicleContent>
          <RadioButtonsGroup
            onChange={handleChangeRecoverableVehicle}
            options={recoverableVehicleOptions}
            defaultValue={generalEvaluation?.toString()}
            disabled={!isDraftRescuedReport}
          />
        </RecoverableVehicleContent>
      </Header>

      <InformationContent>
        <Title>Motor</Title>
        <Subtitle>Número do motor</Subtitle>

        <TextInput
          testID="engine-number-input"
          ariaLabelledby="engine-number-input"
          id="engine-number-input"
          InputLabelProps={{ shrink: true }}
          value={engineNumber}
          onChange={({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeEngineNumber(value)
          }
          name="number-input"
          placeholder="Insira o número do motor"
          role="engine-number-input"
          type="text"
          variant="outlined"
          disabled={!isDraftRescuedReport}
        />

        <CheckBoxContainer>
          <CheckBox
            testId="engine-access-checkbox"
            checked={withoutEngineAccess}
            onChange={handleChangeCheckbox}
            checkBoxColor="#CED6E5"
            disabled={!isDraftRescuedReport}
          />
          <Description>Sem acesso ao motor</Description>
        </CheckBoxContainer>
      </InformationContent>
      <InformationContent>
        <Title>Justificativa e observações</Title>
        <Subtitle>Justificativa</Subtitle>
        <TextInput
          testID="comment-input"
          ariaLabelledby="comment-input"
          id="comment-input"
          inputProps={{
            'data-testid': 'input-area',
          }}
          name="comment"
          type="text"
          placeholder="Insira uma justificativa e/ou observações"
          variant="outlined"
          value={justifications}
          multiline
          minRows={5}
          onChange={({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
            handleChangesJustifications(value)
          }
          disabled={!isDraftRescuedReport}
        />
      </InformationContent>
    </Container>
  );
};

export default GeneralEvaluation;
