export interface IProductTypeService {
  isAuto(productTypeCode: string | undefined): boolean;
  isResidential(productTypeCode: string | undefined): boolean;
  isOthers(productTypeCode: string | undefined): boolean;
}

export interface IProductType {
  id: string;
  description: string;
  code: ProductTypeCodeEnum;
}

export enum ProductTypeCodeEnum {
  AUTO = 'AUTO',
  OTHERS = 'OTHERS',
  RESIDENTIAL = 'RESIDENTIAL',
}
