import { IBudgetLaborCostTypeEnum } from 'commom/types/Budget.types';
import { Button } from 'components';
import { useAuthState } from 'contexts/auth/useAuth';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { IAddPartRequest } from 'services/ApiService/BudgetService/BudgetService.types';
import { IVehiclePartPrice, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { priceFormatter } from 'utils/formatPrice';
import {
  ButtonContainer,
  Divider,
  FooterWrapper,
  PriceFormated,
  TotalCostContent,
  TotalCostLabel,
  TotalCostsWrapper,
  TotalPriceFormated,
} from '../PartLaborCost.styles';
import { usePartLaborCostContext } from '../PartLaborCostContext';
import { calculateNetPrice } from 'utils/calculateNetPrice';
import {
  selectCost,
  selectLaborDiscount,
  selectLaborIncrease,
  selectBudgetId,
} from 'stores/presentBudget/presentBudget.selector';
import { selectVehiclePaintingType } from 'stores/presentInspection/presentInspection.selector';

export interface IFooterPartLaborCostProps {
  partTypeSelected: IVehiclePartType | null;
  partPriceSelected: IVehiclePartPrice | null;
  handleCloseAddPartsDrawer: () => void;
}

export default function Footer({
  partTypeSelected,
  partPriceSelected,
  handleCloseAddPartsDrawer,
}: IFooterPartLaborCostProps) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const { account } = useAuthState();
  const { isServiceTypeRepair, serviceType, partServiceHours, quantityParts, partDiscount, partPrice } =
    usePartLaborCostContext();
  const cost = useAppSelector(selectCost);
  const discount = useAppSelector(selectLaborDiscount);
  const increase = useAppSelector(selectLaborIncrease);
  const paintingType = useAppSelector(selectVehiclePaintingType);
  const budgetId = useAppSelector(selectBudgetId);
  const dispatch = useAppDispatch();

  const laborCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.LABOR)?.price || 0;
  const repairCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.REPAIR)?.price || 0;
  const paintingCost = cost.find((item) => item.type === paintingType?.toString())?.price || 0;

  const totalPartPrice = partPrice * quantityParts;
  const totalPartDiscount = (totalPartPrice * partDiscount) / 100;

  const totalLaborPrice = () => {
    const removeAndInstall = partServiceHours.removeAndInstall * laborCost;
    const repair = partServiceHours.repair * repairCost;
    const painting = partServiceHours.painting * paintingCost;

    if (isServiceTypeRepair) {
      return (removeAndInstall + repair + painting) * quantityParts;
    }

    return (removeAndInstall + painting) * quantityParts;
  };

  const totalNetPrice = () => {
    if (isServiceTypeRepair) {
      return totalLaborPrice();
    }

    return totalPartPrice - totalPartDiscount + totalLaborPrice();
  };

  const isNetPriceModified = partPrice !== calculateNetPrice(partPriceSelected?.price || 0, discount, increase);

  const getInputNetPrice = () => {
    return isNetPriceModified ? { inputNetPrice: partPrice } : {};
  };

  const inputNetPrice = getInputNetPrice();

  const onSubmit = async () => {
    try {
      if (!!partTypeSelected && !!partPriceSelected) {
        setIsLoadingSubmit(true);
        const partToAdd: IAddPartRequest = {
          user: account.id,
          name: partPriceSelected.description,
          sku: partPriceSelected.partNumber,
          quantity: quantityParts,
          originalPrice: partPriceSelected.price,
          discount: !isServiceTypeRepair ? partDiscount : 0,
          ...inputNetPrice,
          isManualEntry: false,
          serviceType,
          partSupplier: {
            code: partPriceSelected.type.code,
            description: partPriceSelected.type.description,
          },
          sourceType: {
            code: partTypeSelected.partTemp.sourceType,
            description: partTypeSelected.partTemp.sourceType,
          },
          originalServiceHours: partTypeSelected.partTemp.partTempOriginal,
          serviceHours: {
            removeAndInstall: partServiceHours.removeAndInstall,
            repair: isServiceTypeRepair ? partServiceHours.repair : 0,
            painting: partServiceHours.painting,
          },
        };
        const budget = await BudgetService.addPart(partToAdd, budgetId);

        dispatch(setBudget(budget));
        setIsLoadingSubmit(false);
        handleCloseAddPartsDrawer();
      }
    } catch (error) {
      toast.error('Erro ao adicionar peça');
      setIsLoadingSubmit(false);
    }
  };

  return (
    <>
      <Divider />
      <FooterWrapper data-testid="footer-part-labor-cost">
        <TotalCostsWrapper>
          {!isServiceTypeRepair && (
            <TotalCostContent>
              <TotalCostLabel>Valor líquido da peça</TotalCostLabel>
              <PriceFormated>{priceFormatter.format(totalPartPrice)}</PriceFormated>
            </TotalCostContent>
          )}

          {!isServiceTypeRepair && (
            <TotalCostContent>
              <TotalCostLabel>Valor dos serviços</TotalCostLabel>
              <PriceFormated>+ {priceFormatter.format(totalLaborPrice())}</PriceFormated>
            </TotalCostContent>
          )}

          <TotalCostContent>
            <TotalCostLabel>Valor líquido total</TotalCostLabel>
            <TotalPriceFormated>{priceFormatter.format(totalNetPrice())}</TotalPriceFormated>
          </TotalCostContent>
        </TotalCostsWrapper>

        <ButtonContainer>
          <Button variant="outlined" text="CANCELAR" onClick={handleCloseAddPartsDrawer} />
          <Button
            testID="add-part-button"
            variant="contained"
            text="Adicionar Peça"
            loading={isLoadingSubmit}
            onClick={onSubmit}
          />
        </ButtonContainer>
      </FooterWrapper>
    </>
  );
}
