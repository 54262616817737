import styled from 'styled-components';
import {
  Table as TableComponent,
  TableContainer as TableContainerComponent,
  TablePagination,
  TableHead as TableHeadComponent,
  TableCell as TableCellComponent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Pagination } from '@mui/material';
import { TablePaginationType } from './Table.types';

interface ITableActionsProps {
  rightaligntable: string;
}

export const TableContainerActions = styled(TableContainerComponent)`
  background: #fff;
  position: relative;

  padding: 10px 0px;
  border-left: 1px solid #dfe0eb;
  border-right: 1px solid #dfe0eb;
`;

export const TableActionsBorderCollapse = withStyles({
  root: {
    borderCollapse: 'separate',
  },
})(TableComponent);

export const TableActions = styled(TableActionsBorderCollapse)<ITableActionsProps>`
  font-family: Montserrat, sans-serif;
  position: relative;
  margin-left: ${({ rightaligntable }: ITableActionsProps) => rightaligntable};
  z-index: 0;

  .MuiTableCell-root {
    font-family: 'Montserrat', sans-serif;
  }
`;

export const TableContainer = styled(TableContainerComponent)`
  background: #fff;
`;

export const Table = styled(TableComponent)`
  .MuiTableCell-root {
    font-family: 'Montserrat', sans-serif;
  }
`;

export const TablePaginationWrapper = styled(TablePagination)<TablePaginationType>`
  background: #fff;
  overflow: hidden !important;

  .MuiTablePagination-toolbar {
    padding: 20px;
  }

  .MuiTypography-body2 {
    font-family: 'Montserrat', sans-serif;
    color: #6a758b;
    font-size: 16px;
  }
`;

export const TableHead = styled(TableHeadComponent)`
  .MuiTableCell-head {
    font-size: 16px;
    font-weight: bold;
    background-color: #f9fafb;
    color: #000;
    text-transform: none;
  }
`;

export const TableCell = withStyles({
  root: {
    borderWidth: 1,
    borderColor: '#B6B6B6',
    borderStyle: 'solid',
    whiteSpace: 'nowrap',
  },
  body: {
    color: '#6a758b',
    fontFamily: 'Montserrat, sans-serif',
  },
})(TableCellComponent);

export const TableCellSticky = withStyles({
  root: {
    borderWidth: 1,
    borderColor: '#B6B6B6',
    borderStyle: 'solid',
    whiteSpace: 'nowrap',
    background: 'white',
    position: 'sticky',
    '@media (min-width:800px)': { left: '88%' },
    '@media (min-width:900px)': { left: '89%' },
    '@media (min-width:1000px)': { left: '90%' },
    '@media (min-width:1100px)': { left: '91.5%' },
    '@media (min-width:1200px)': { left: '92%' },
    '@media (min-width:1300px)': { left: '92.5%' },
    '@media (min-width:1400px)': { left: '93%' },
    '@media (min-width:1500px)': { left: '94%' },
    '@media (min-width:1600px)': { left: '94.5%' },
    '@media (min-width:1700px)': { left: '95%' },
  },
  body: {
    color: '#6a758b',
    fontFamily: 'Montserrat, sans-serif',
  },
})(TableCellComponent);

export const EmptyTableCell = withStyles({
  body: {
    textAlign: 'center',
  },
})(TableCell);

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 50px;
  background: #fff;

  border-bottom: 1px solid #dfe0eb;
  border-left: 1px solid #dfe0eb;
  border-right: 1px solid #dfe0eb;
  border-radius: 0px 0px 8px 8px;
`;

export const PaginationDetails = styled.div``;

export const PaginationController = styled.div`
  margin-left: auto;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const PaginationTable = styled(Pagination)`
  .MuiButtonBase-root {
    color: black;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: white;
    cursor: pointer;

    &.Mui-selected {
      background: #000000 !important;
      color: white !important;
    }
  }
`;

export const TableLoadingWrapper = styled.div`
  height: 30vh;
  display: flex;
  align-items: center;
`;
