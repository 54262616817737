import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const CarContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const VehicleAreaListContainer = styled.div`
  width: 280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px 20px;
`;

export const VehicleAreaTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  color: #000;
`;

export const VehicleAreaList = styled.div`
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const VehicleAreaItem = styled.div`
  background: #f6f6f6;
  padding: 6px 10px 6px 10px;
  border-radius: 6px;
  border: 1px solid #ced6e5;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  :hover {
    background-color: #e8e8e8;
  }

  span {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    text-align: left;
  }
`;

export const PartGroupListContainer = styled.div`
  width: 280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px 20px;
`;

export const PartGroupTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  color: #000;
`;

export const PartGroupList = styled.div`
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PartGroupItem = styled.div`
  background: #f6f6f6;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ced6e5;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  :hover {
    background-color: #e8e8e8;
  }

  span {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    text-align: left;
  }
`;

export const Area = styled.path`
  :hover {
    fill-opacity: 0.5;
    stroke-opacity: 1;
    cursor: pointer;
  }
`;

export const EmptyComtainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
