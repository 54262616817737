import { IInspection } from 'pages/SchedulerList/components/Inspection';
import React, { createContext, useMemo, useState } from 'react';

import { IManagementDashboardDispatchContextData, IManagementDashboardStateContextData } from './types';

export const ManagementDashboardDispatchContext = createContext<IManagementDashboardDispatchContextData>(
  {} as IManagementDashboardDispatchContextData
);

export const ManagementDashboardStateContext = createContext<IManagementDashboardStateContextData>(
  {} as IManagementDashboardStateContextData
);

export const ManagementDashboardContainer: React.FC = ({ children }) => {
  const [inspections, setInspections] = useState<IInspection[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const managementDashboardState = useMemo(
    () => ({
      inspections,
      selectedCompanyId,
      isLoading,
    }),
    [inspections, selectedCompanyId, isLoading]
  );

  return (
    <ManagementDashboardStateContext.Provider value={managementDashboardState}>
      <ManagementDashboardDispatchContext.Provider value={{ setInspections, setSelectedCompanyId, setIsLoading }}>
        <>{children}</>
      </ManagementDashboardDispatchContext.Provider>
    </ManagementDashboardStateContext.Provider>
  );
};
