import React from 'react';
import {
  Body,
  Card,
  Description,
  Header,
  TitleContainer,
  ToggleButtonContainer,
  Value,
} from './DamageQuestionGroups.styles';
import { IBudgetReportQuestion } from 'commom/types/BudgetReport.types';
import DamageQuestionInput from './DamageQuestionInput/DamageQuestionInput';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { IToggleButtonOption } from 'components/ToggleButton';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setDamageReportAllAnswers } from 'stores/presentBudget/presentBudget.store';
import { selectDamageReportIsDraft } from 'stores/presentBudget/presentBudget.selector';

interface IDamageQuestionGroups {
  questions: IBudgetReportQuestion[];
  questionGroupId: string;
  groupIdentifier: string;
}

const DamageQuestionGroups = ({ questions, questionGroupId, groupIdentifier }: IDamageQuestionGroups) => {
  const [SelectedOptionValue, setSelectedOptionValue] = React.useState<string | null>(null);
  const isDraftRescuedReport = useAppSelector(selectDamageReportIsDraft);
  const dispatch = useAppDispatch();

  const toggleButtonOptions: IToggleButtonOption[] = questions[0].options.map((option) => {
    return {
      code: option.slug,
      title: option.slug,
    };
  });

  const handleToggleButtonOnChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
    setSelectedOptionValue(value);
    dispatch(setDamageReportAllAnswers({ questionGroupId, groupIdentifier, slug: value }));
  };

  return (
    <Card>
      <Header>
        <TitleContainer>
          <Description>Descrição do componente</Description>
          <Value>Valor</Value>
          <ToggleButtonContainer>
            <ToggleButton
              size="small"
              fontSize="medium"
              options={toggleButtonOptions}
              value={SelectedOptionValue}
              onChange={handleToggleButtonOnChange}
              disabled={!isDraftRescuedReport}
            />
          </ToggleButtonContainer>
        </TitleContainer>
      </Header>
      <Body>
        {questions.map((question) => (
          <DamageQuestionInput key={question._id} question={question} questionGroupId={questionGroupId} />
        ))}
      </Body>
    </Card>
  );
};

export default DamageQuestionGroups;
