import React from 'react';
import { Skeleton } from '@mui/material';
import {
  Card,
  Header,
  Description,
  Value,
  TitleContainer,
  ToggleButtonContainer,
  Body,
} from './DamageQuestionGroupsSkeleton.styles';

const DamageQuestionGroupsSkeleton = () => {
  return (
    <Card>
      <Header>
        <TitleContainer>
          <Description>
            <Skeleton variant="text" width={200} height={30} />
          </Description>
          <Value>
            <Skeleton variant="text" width={100} height={30} />
          </Value>
          <ToggleButtonContainer>
            <Skeleton variant="rectangular" width={260} height={40} />
          </ToggleButtonContainer>
        </TitleContainer>
      </Header>
      <Body>
        {Array.from(new Array(11)).map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height={40} />
        ))}
      </Body>
    </Card>
  );
};

export default DamageQuestionGroupsSkeleton;
