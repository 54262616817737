import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const BackButtonContent = styled.div`
  margin-bottom: 12px;
`;

export const DrawerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 20px 1px 10px 1px;
  overflow-y: auto;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const Description = styled.span`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const TextField = styled(TextFieldComponent)`
  margin-bottom: 10px !important;
`;

export const DiscountAndIncreaseContent = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Separator = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 25px;
`;

export const ToastContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ToastSubtitle = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
`;
