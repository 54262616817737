import React from 'react';
import Icon from 'assets/svg/icon-back.svg';
import * as S from './BackButton.styles';

interface IBackButtonProps {
  onClick: () => void;
}

export const BackButton = ({ onClick }: IBackButtonProps) => {
  return (
    <S.Button data-testid="back-button" type="button" onClick={onClick}>
      <img src={Icon} alt="voltar" /> voltar
    </S.Button>
  );
};
