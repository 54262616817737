import { Tooltip } from '@material-ui/core';
import { IBudgetPart } from 'commom/types/Budget.types';
import EditPartDrawer from 'components/Drawer/EditPartDrawer/EditPartDrawer';
import { PartContextProvider } from 'contexts/budgetPart/BudgetPartContext';
import React, { useState } from 'react';
import { EditIcon, IconButtonCustom } from './EditPartAction.styles';

interface IEditPartActionProps {
  part: IBudgetPart;
}

const EditPartAction = ({ part }: IEditPartActionProps) => {
  const [isOpenEditPartDrawer, setIsOpenEditPartDrawer] = useState(false);

  const handleCloseEditPartDrawer = () => {
    setIsOpenEditPartDrawer(false);
  };

  return (
    <>
      <IconButtonCustom color="inherit" className="edit-icon" onClick={() => setIsOpenEditPartDrawer(true)}>
        <Tooltip title="Editar" placement="top" arrow>
          <EditIcon />
        </Tooltip>
      </IconButtonCustom>

      {isOpenEditPartDrawer && (
        <PartContextProvider part={part}>
          <EditPartDrawer partId={part._id} openDrawer={isOpenEditPartDrawer} onClose={handleCloseEditPartDrawer} />
        </PartContextProvider>
      )}
    </>
  );
};

export default EditPartAction;
