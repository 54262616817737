import { useContext } from 'react';

import { IManagementDashboardDispatchContextData, IManagementDashboardStateContextData } from './types';

import { ManagementDashboardDispatchContext, ManagementDashboardStateContext } from './ManagementDashboardProvider';

export const useManagementDashboardDispatch = (): IManagementDashboardDispatchContextData => {
  const context = useContext(ManagementDashboardDispatchContext);

  if (!context) {
    throw new Error('useManagementDashboardDispatch must be used within a ManagementDashboardProvider');
  }

  return context;
};

export const useManagementDashboardState = (): IManagementDashboardStateContextData => {
  const context = useContext(ManagementDashboardStateContext);

  if (!context) {
    throw new Error('useManagementDashboardState must be used within a ManagementDashboardProvider');
  }

  return context;
};
