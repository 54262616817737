/* eslint-disable no-shadow */
import React, { createContext, useCallback, useMemo, useState } from 'react';

import { IRegulator } from 'commom/types/Regulator.types';
import { IRegulatorCoordinates, IRegulatorDetailsContextData } from './types';

export const RegulatorDetailsContext = createContext<IRegulatorDetailsContextData>({} as IRegulatorDetailsContextData);

export const RegulatorDetailsContainer: React.FC = ({ children }) => {
  const [regulator, setRegulator] = useState<IRegulator | null>(null);
  const [regulatorCoordinates, setRegulatorCoordinates] = useState<IRegulatorCoordinates | null>(null);

  const updateRegulator = useCallback((regulator: IRegulator) => {
    setRegulator(regulator);
  }, []);

  const updateRegulatorCoordinates = useCallback((regulatorCoordinates: IRegulatorCoordinates | null) => {
    setRegulatorCoordinates(regulatorCoordinates);
  }, []);

  const updateRegulatorAndRegulatorCoordinates = useCallback((regulator: IRegulator | null) => {
    const { geolocation } = regulator || {};
    setRegulator(regulator);

    if (geolocation) {
      const lastCoordinates = geolocation.coordinates;

      setRegulatorCoordinates({ lat: Number(lastCoordinates.latitude), lng: Number(lastCoordinates.longitude) });
    } else {
      setRegulatorCoordinates(null);
    }
  }, []);

  const RegulatorDetailsState = useMemo(
    () => ({
      regulator,
      regulatorCoordinates,
      updateRegulator,
      updateRegulatorCoordinates,
      updateRegulatorAndRegulatorCoordinates,
    }),
    [
      regulator,
      regulatorCoordinates,
      updateRegulator,
      updateRegulatorCoordinates,
      updateRegulatorAndRegulatorCoordinates,
    ]
  );

  return <RegulatorDetailsContext.Provider value={RegulatorDetailsState}>{children}</RegulatorDetailsContext.Provider>;
};
