const defaultValidations = {
  required: {
    value: true,
    message: 'Campo obrigatório',
  },
};

export const formValidations = {
  default: { ...defaultValidations },
  email: {
    ...defaultValidations,
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Email inválido',
    },
  },
  password: {
    ...defaultValidations,
    minLength: {
      value: 6,
      message: 'Deve ter no mínimo 6 caracteres',
    },
  },
  newPassword: {
    ...defaultValidations,
    minLength: {
      value: 8,
      message: 'Deve ter no mínimo 8 caracteres',
    },
    pattern: {
      value: /.*[\d].*/,
      message: 'Deve ter ao menos um número',
    },
  },
  plate: {
    ...defaultValidations,
    pattern: {
      value: /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/i,
      message: 'Placa inválida',
    },
  },
  phone: {
    ...defaultValidations,
    pattern: {
      value: /\(?\d{2}\)?\s?\d\s?\d{4}-?\d{4}/,
      message: 'Telefone inválido',
    },
  },
  cep: {
    ...defaultValidations,
    pattern: {
      value: /\d{5}-?\d{3}/,
      message: 'CEP inválido',
    },
  },
  fipeCode: {
    ...defaultValidations,
    pattern: {
      value: /\d{6}-?\d/,
      message: 'FIPE inválido',
    },
  },
  cpf: {
    ...defaultValidations,
    pattern: {
      value: /\d{3}\.?\d{3}\.?\d{3}-?\d{2}/,
      message: 'CPF inválido',
    },
  },
  cnpj: {
    ...defaultValidations,
    pattern: {
      value: /\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/,
      message: 'CNPJ inválido',
    },
  },
  cpfOrCnpj: {
    ...defaultValidations,
    pattern: {
      value: /\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/,
      message: 'CPF ou CNPJ inválido',
    },
  },
};
