import React from 'react';
import { MenuItem } from '@mui/material';
import { IDocument, IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { toast } from 'react-toastify';
import DocumentService from 'services/DocumentService/documentService';

interface IDownloadDocumentsMenuItemProps {
  inspection: IInspection;
  onCloseMenu: () => void;
}

const DownloadDocumentsMenuItem = ({ inspection, onCloseMenu }: IDownloadDocumentsMenuItemProps) => {
  const downloadDocuments = async (documents: IDocument[]): Promise<void> => {
    try {
      if (!inspection) return;

      await DocumentService.downloadFileZip(documents, inspection.code);
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao baixar os documentos, tente novamente.');
    }
  };

  const handleDownloadDocuments = async () => {
    if (!inspection.files) return;
    downloadDocuments(inspection.files);
    onCloseMenu();
  };

  const showDownloadDocumentsButton = inspection.files && inspection.files.length > 0;

  return (
    <>
      {showDownloadDocumentsButton && (
        <MenuItem data-testid="download-documents" onClick={() => handleDownloadDocuments()}>
          Baixar Documentos
        </MenuItem>
      )}
    </>
  );
};

export default DownloadDocumentsMenuItem;
