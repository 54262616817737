import React from 'react';
import { ToggleButton as ToggleButtonBase } from '@mui/material';
import * as Styled from './ToggleButton.styles';
import { IToggleButton } from './ToggleButton.types';

const ToggleButton = ({
  testId = 'toggle-button',
  options,
  value,
  size,
  onChange,
  fontSize,
  disabled = false,
}: IToggleButton) => {
  return (
    <Styled.ToggleButtonGroupBase
      data-testid={testId}
      size={size}
      value={value}
      onChange={onChange}
      exclusive
      fontSize={fontSize}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <ToggleButtonBase
          data-testid={`toggle-option-${index}`}
          key={option.code}
          value={option.code}
          id={option.code}
          disabled={value === option.code}
        >
          {option.title}
        </ToggleButtonBase>
      ))}
    </Styled.ToggleButtonGroupBase>
  );
};

export default ToggleButton;
