import React from 'react';
import {
  Card,
  CaptionInfo,
  CaptionContainer,
  FirstLineCaption,
  SecondLineCaption,
  Separator,
} from './DamageCaption.styles';

const DamageCaption = () => {
  return (
    <Card>
      <CaptionContainer>
        <FirstLineCaption>
          <CaptionInfo topCardInfo>
            Quando o componente estiver danificado, assinalar na coluna <CaptionInfo bold>"SIM"</CaptionInfo>.
          </CaptionInfo>
          <CaptionInfo topCardInfo>
            Quando o componente não estiver danificado, ou não existir originalmente, assinalar na coluna 
            <CaptionInfo bold>"NÃO"</CaptionInfo>.
          </CaptionInfo>
          <CaptionInfo topCardInfo>
            Caso não tenha sido possível avaliar se o componente foi ou não danificado no acidente, assinalar a coluna 
            <CaptionInfo bold>"Não Avaliado"</CaptionInfo>.
          </CaptionInfo>
        </FirstLineCaption>
        <Separator />
        <SecondLineCaption>
          <CaptionInfo>
            <CaptionInfo bold isTitle>
              Sim
            </CaptionInfo>
            {' = Item danificado no acidente'}
          </CaptionInfo>
          <CaptionInfo>
            <CaptionInfo bold isTitle>
              Não
            </CaptionInfo>
            {' = Item não danificado no acidente'}
          </CaptionInfo>
          <CaptionInfo>
            <CaptionInfo bold isTitle>
              Não Avaliado
            </CaptionInfo>
            {' = Não foi possivel avaliar o item'}
          </CaptionInfo>
        </SecondLineCaption>
      </CaptionContainer>
    </Card>
  );
};

export default DamageCaption;
