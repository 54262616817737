/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import LockIcon from '@material-ui/icons/Lock';
import { InputAdornment } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Label } from 'components';
import { toast } from 'react-toastify';
import { formValidations } from 'utils/form/validations';
import PasswordRecoveryService from 'services/ApiService/PasswordRecoveryService';
import { Container, Illustration, Form, Title, Description, Button, TextField } from '../../PasswordRecovery.styles';
import { INewPasswordForm } from './NewPassword.types';

const InputProps = {
  password: {
    startAdornment: (
      <InputAdornment position="start">
        <LockIcon />
      </InputAdornment>
    ),
  },
};

const inputProps = {
  password: {
    'data-testid': 'input-password',
    form: {
      autocomplete: 'off',
    },
  },
  confirmPassword: {
    'data-testid': 'input-confirm-password',
    form: {
      autocomplete: 'off',
    },
  },
};

export const NewPassword = () => {
  const history = useHistory();
  const { register, errors, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const [token] = useState(new URLSearchParams(search).get('token'));

  const getPasswordRecoveryApplication = useCallback((): string | null => {
    const query = new URLSearchParams(search);
    return query.get('passwordRecoveryApplication');
  }, [search]);

  const generateSearchParams = useCallback(
    (includeToken: boolean): string => {
      let params = `passwordRecoveryApplication=${getPasswordRecoveryApplication()}`;

      if (includeToken) {
        params = `${params}&token=${token}`;
      }

      return `?${params}`;
    },
    [getPasswordRecoveryApplication, token]
  );

  const redirectWhenTokenExpired = useCallback(() => {
    toast.error('Link expirado, tente reenviar o email.');

    history.push({ pathname: '/password-recovery', search: generateSearchParams(false) });
  }, [history, generateSearchParams]);

  const isExpiredToken = useCallback(async () => {
    if (token) {
      const expired = await PasswordRecoveryService.expiredToken(token);

      expired && redirectWhenTokenExpired();
    }
  }, [redirectWhenTokenExpired, token]);

  useEffect(() => {
    isExpiredToken();
  }, [isExpiredToken]);

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const tokenValidated = () => {
    toast.success('Senha alterada com sucesso.');
    history.push({ pathname: '/password-recovered', search: generateSearchParams(true) });
  };

  const checkValidityToken = (valid: boolean) => {
    valid ? tokenValidated() : redirectWhenTokenExpired();
  };

  const onSubmit = async (newPasswordForm: INewPasswordForm): Promise<void> => {
    try {
      const { password } = newPasswordForm;
      if (token) {
        setLoading(true);
        const { valid } = await PasswordRecoveryService.newPassword(password, token);

        setLoading(false);
        checkValidityToken(valid);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao tentar alterar senha.');
    }
  };

  const validations = {
    confirmPassword: {
      ...formValidations.default,
      validate: (value: string) => value === watch('password') || 'Deve ser igual à nova senha',
    },
  };

  return (
    <Container data-testid="password-recovery-new">
      <Illustration />
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <Title>Nova senha</Title>

        <Description>A sua nova senha deve conter pelo menos 8 caracteres com, ao menos, um número.</Description>

        <Label htmlFor="password" testID="password">
          Nova senha
        </Label>
        <TextField
          ariaLabelledby="password"
          error={hasError('password')}
          id="password"
          InputProps={InputProps.password}
          inputProps={inputProps.password}
          inputRef={register(formValidations.newPassword)}
          helperText={errors?.password?.message}
          name="password"
          placeholder="Insira a sua nova senha"
          state={handleInputState('password')}
          type="password"
          variant="outlined"
        />

        <Label htmlFor="confirmPassword" testID="confirmPassword">
          Repetir nova senha
        </Label>
        <TextField
          ariaLabelledby="confirmPassword"
          error={hasError('confirmPassword')}
          id="confirmPassword"
          InputProps={InputProps.password}
          inputProps={inputProps.confirmPassword}
          inputRef={register(validations.confirmPassword)}
          helperText={errors?.confirmPassword?.message}
          name="confirmPassword"
          placeholder="Repita sua nova senha"
          state={handleInputState('confirmPassword')}
          type="password"
          variant="outlined"
        />

        <Button
          fullWidth={true}
          loading={loading}
          loadingColor="#ffffff"
          loadingSize={26}
          size="large"
          text="RESETAR SENHA"
          type="submit"
        />
      </Form>
    </Container>
  );
};
