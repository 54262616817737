import styled from 'styled-components';
import { Form as FormComponent, TextField as TextFieldComponent, Button as ButtonComponent } from 'components';

export const CommentsCard = styled.div`
  grid-area: comments;
`;

export const Title = styled.span`
  margin-bottom: 10px;
  color: #6a758b;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Form = styled(FormComponent)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

export const ChatArea = styled.div`
  height: 162px;
  overflow: auto;
  border: 1px solid #dbd9d9;
  border-radius: 6px;
  padding: 12px 24px;
`;

export const Comment = styled.div`
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 23px;
  padding: 8px 12px;
  margin-bottom: 4px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Description = styled.span`
  color: #6a758b;
  font-size: 16px;
  font-weight: 500;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const TextField = styled(TextFieldComponent)`
  width: 100%;
`;

export const IconButton = styled(ButtonComponent)`
  width: 24px !important;
  min-width: 20px !important;
  margin-left: 5px !important;

  .send-icon {
    width: 28px !important;
  }

  .MuiCircularProgress-svg {
    width: 20px !important;
  }
`;
