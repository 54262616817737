import React from 'react';
import { priceFormatter } from 'utils/formatPrice';
import {
  BudgetContainer,
  SectionLabel,
  SectionValue,
  SectionDivider,
  SectionTop,
  SectionMiddle,
  SectionFooter,
  SectionValueGreen,
  SectionValueRed,
  SectionValueBig,
  SectionValueBigGreen,
  SectionValueBigBlue,
} from './BudgetSummaryCosts.styles';
import { useAppSelector } from 'hooks/hooks';
import { selectBudget } from 'stores/presentBudget/presentBudget.selector';

const BudgetSummaryCosts = () => {
  const budget = useAppSelector(selectBudget);

  return (
    <BudgetContainer>
      <SectionTop>
        <div>
          <SectionLabel>Total bruto peças</SectionLabel>
          <SectionValue>{priceFormatter.format(budget.summaryCost.parts.gross)}</SectionValue>
        </div>

        <SectionDivider>-</SectionDivider>

        <div>
          <SectionLabel>Desconto</SectionLabel>
          <SectionValueGreen>{priceFormatter.format(budget.summaryCost.parts.discount)}</SectionValueGreen>
        </div>

        <SectionDivider>+</SectionDivider>

        <div>
          <SectionLabel>Majoração</SectionLabel>
          <SectionValueRed>{priceFormatter.format(budget.summaryCost.parts.increase)}</SectionValueRed>
        </div>

        <SectionDivider>=</SectionDivider>

        <div>
          <SectionLabel>Líquido Peças</SectionLabel>
          <SectionValue>{priceFormatter.format(budget.summaryCost.parts.net)}</SectionValue>
        </div>
      </SectionTop>

      <SectionMiddle>
        <div>
          <SectionLabel>Líquido Peças</SectionLabel>
          <SectionValue>{priceFormatter.format(budget.summaryCost.parts.net)}</SectionValue>
        </div>

        <SectionDivider>+</SectionDivider>

        <div>
          <SectionLabel>Total mão de Obra</SectionLabel>
          <SectionValue>{priceFormatter.format(budget.summaryCost.services.total)}</SectionValue>
        </div>

        <SectionDivider>=</SectionDivider>

        <div>
          <SectionLabel>Total Geral</SectionLabel>
          <SectionValue>{priceFormatter.format(budget.summaryCost.gross)}</SectionValue>
        </div>
      </SectionMiddle>

      <SectionFooter>
        <div>
          <SectionLabel>Total Geral</SectionLabel>
          <SectionValueBig>{priceFormatter.format(budget.summaryCost.gross)}</SectionValueBig>
        </div>

        <SectionDivider>-</SectionDivider>

        <div>
          <SectionLabel>Franquia</SectionLabel>
          <SectionValueBigGreen>{priceFormatter.format(budget.deductible)}</SectionValueBigGreen>
        </div>

        <SectionDivider>=</SectionDivider>

        <div>
          <SectionLabel>Líquido Geral</SectionLabel>
          <SectionValueBigBlue>{priceFormatter.format(budget.summaryCost.net)}</SectionValueBigBlue>
        </div>
      </SectionFooter>
    </BudgetContainer>
  );
};

export default BudgetSummaryCosts;
