import styled, { css } from 'styled-components';
import { Divider as DividerMui } from '@mui/material';

export const Divider = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;

  services-labor-cost {
    margin: 0 !important;
  }
`;

export const Separator = styled(DividerMui)`
  border-color: #ced6e580 !important;
`;

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LabelService = styled.span`
  color: #6a758b;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PartLaborWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderPartDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TitleManuallyPart = styled.span`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  line-height: 19px;
`;

export const TitlePart = styled.span`
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  line-height: normal;

  title-labor-cost {
    font-size: 16px;
  }
`;

export const SubtitlePart = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  line-height: normal;
  margin-top: 2px;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const LaborServicesButtons = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 10px;
`;

export const GroupInputRow = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const InputWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const FooterWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 15px;
`;

export const TotalCostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const TotalCostContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TotalCostLabel = styled.span`
  color: #6a758b;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;

  price-formated {
    color: #26415e !important;
    font-size: 18px !important;
  }
`;

const priceBaseStyles = css`
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  text-align: left;
`;

export const PriceFormatted = styled.span`
  ${priceBaseStyles}
  font-weight: 500;
  font-size: 14px;
`;

export const TotalPriceFormatted = styled.span`
  ${priceBaseStyles}
  font-weight: 600;
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;
