import React, { useCallback, useEffect } from 'react';
import { useFilterInspectionById } from 'hooks/Inspections/InspectionsHooks';
import Loading from 'components/Loading';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { InspectionCategoryEnum } from 'utils/InspectionEnum';
import linkNotSentImage from 'assets/svg/link-not-sent.png';
import { useAppDispatch } from 'hooks/hooks';
import { setPresentInspection } from 'stores/presentInspection/presentInspection.store';
import * as S from './inspectionDetails.styles';
import { InspectionDetailsProps } from './InspectionDetails.types';
import InspectionDetailsRouter from './InspectionDetails.router';
import { IInspection, StatusEnum } from '../Inspection/Inspection.types';
import EmptyInspection from './components/EmptyInspection/EmptyInspection';

const InspectionDetails = ({ inspectionId }: InspectionDetailsProps) => {
  const { inspectionData, isLoadingInspection } = useFilterInspectionById(inspectionId, 'DETAILS');
  const dispatch = useAppDispatch();

  const currentStatusTypeIsDefault = (inspection: IInspection): boolean => {
    return InspectionStatusService.isCurrentStatus(inspection.currentStatus, StatusEnum.DEFAULT);
  };

  const isInspectionCategoryDigital = (): boolean => {
    return inspectionData?.inspectionCategory?.code === InspectionCategoryEnum.DIGITAL;
  };

  const updatePresentInspection = useCallback(() => {
    if (!inspectionData) return;
    dispatch(setPresentInspection(inspectionData));
  }, [dispatch, inspectionData]);

  useEffect(() => {
    updatePresentInspection();
  }, [updatePresentInspection]);

  if (inspectionData && currentStatusTypeIsDefault(inspectionData) && isInspectionCategoryDigital()) {
    return (
      <S.ContainerLinkNotSent data-testid="link-not-sent">
        <img src={linkNotSentImage} alt="link-not-sent" />

        <S.TitleLinkNotSent>Link não enviado</S.TitleLinkNotSent>
        <S.DescriptionLinkNotSent>
          Houve um problema com o envio do link. Tente enviar novamente clicando no botão
          <span> Reenviar Link.</span>
        </S.DescriptionLinkNotSent>
      </S.ContainerLinkNotSent>
    );
  }

  const showInspectionDetails = () => {
    if (isLoadingInspection) {
      return (
        <S.DetailsLoadingWrapper>
          <Loading width="20%" />
        </S.DetailsLoadingWrapper>
      );
    }

    return (
      <S.Container>
        {inspectionData ? <InspectionDetailsRouter inspection={inspectionData} /> : <EmptyInspection />}
      </S.Container>
    );
  };

  return <>{showInspectionDetails()}</>;
};

export default InspectionDetails;
