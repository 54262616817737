import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import FinishBudgetIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Tooltip from '@mui/material/Tooltip';
import { IInspection } from 'pages/SchedulerList/components/Inspection';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { BackButton } from 'components/BackButton';
import Steps from './components/Steps/Steps';
import ResponsibleActions from './components/ResponsibleActions/ResponsibleActions';
import * as S from './Header.styles';
import DealAction from './components/DealAction/DealAction';
import { FeatureTogglesEnum } from 'services/ApiService/FeatureToggles/types';

interface IHeaderProps {
  inspection: IInspection;
}

const Header = ({ inspection }: IHeaderProps) => {
  const { id: inspectionId, currentResponsible } = inspection;

  const [loading, setLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const { account } = useAuthState();
  const { isAdmin, isAnalyst } = useAuthDispatch();
  const isMenuOpen = Boolean(menuAnchor);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isFeatureToggleActive } = useFeatureToggles();

  const { _id: budgetId, currentStatus } = useAppSelector((state) => state.presentBudget.budget);

  const isBudgetStatusFinished = currentStatus.status.code === 'FINISHED';

  const isActionsButtonDisabled = loading || isBudgetStatusFinished;

  const isResponsibleOrAdmin = isAdmin() || currentResponsible?.id === account.id;

  const disableFinishBudget = !(isAdmin() || isAnalyst());

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const removeUrlParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('currentStep');
    url.searchParams.delete('budgetId');
    window.history.pushState({}, '', url.toString());
  };

  const handleBackButton = () => {
    removeUrlParams();
    history.push(`/agendamentos/v2/detalhes/${inspectionId}`);
  };

  const handleFinishBudget = async () => {
    try {
      setLoading(true);
      handleCloseMenu();
      const budgetFinished = await BudgetService.finishBudget(budgetId);
      dispatch(setBudget(budgetFinished));
    } catch (error) {
      toast.error('Não foi possível finalizar o orçamento. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const renderActionsButton = () => {
    return (
      <>
        <S.MenuButton
          id="actions-button"
          testID="actions-button"
          variant="contained"
          text="ações do orçamento"
          fullWidth={true}
          aria-controls={isMenuOpen ? 'actions-menu' : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleOpenMenu}
          loadingColor="#fff"
          loading={loading}
          disabled={isActionsButtonDisabled}
          endIcon={isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        />
        <S.Menu
          id="actions-menu"
          MenuListProps={{
            'aria-labelledby': 'actions-button',
          }}
          open={isMenuOpen}
          anchorEl={menuAnchor}
          onClose={handleCloseMenu}
        >
          {isFeatureToggleActive(FeatureTogglesEnum.SHOW_BUDGET_FEATURES) && (
            <ResponsibleActions inspection={inspection} closeMenu={handleCloseMenu} />
          )}
          {isFeatureToggleActive(FeatureTogglesEnum.SHOW_BUDGET_FEATURES) && (
            <S.MenuItem onClick={handleFinishBudget} disabled={disableFinishBudget}>
              <FinishBudgetIcon />
              <span>Finalizar orçamento</span>
            </S.MenuItem>
          )}
          <DealAction />
        </S.Menu>
      </>
    );
  };

  return (
    <S.Container>
      <S.TitleContainer>
        <BackButton onClick={handleBackButton} />

        <S.Title>Orçamento</S.Title>

        <Tooltip title={isBudgetStatusFinished && 'Este orçamento já foi finalizado.'} placement="bottom" arrow>
          <span>{isResponsibleOrAdmin && renderActionsButton()}</span>
        </Tooltip>
      </S.TitleContainer>

      <Steps />
    </S.Container>
  );
};

export default Header;
