import { Button } from 'components';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import DateService from 'services/DateService/dateService';
import { selectBudget } from 'stores/presentBudget/presentBudget.selector';
import { selectInspection } from 'stores/presentInspection/presentInspection.selector';
import {
  BudgetActions,
  BudgetInfo,
  Container,
  Header,
  PrintStyles,
  ReportContainer,
  SubTitle,
  Title,
} from './Report.styles';
import ReportPreview from './ReportPreview/ReportPreview';

const Report = () => {
  const { getCurrentClient } = useActiveProfile();
  const client = getCurrentClient();
  const inspection = useAppSelector(selectInspection);
  const budget = useAppSelector(selectBudget);

  const printPDF = () => {
    window.print();
  };

  return (
    <Container>
      <ReportContainer>
        <PrintStyles />
        <Header>
          <BudgetInfo>
            <Title>Orçamento</Title>
            <SubTitle>Data de criação: {DateService.formatDateAndTime(budget.createdAt)}</SubTitle>
          </BudgetInfo>

          <BudgetActions>
            <Button text="Imprimir" variant="outlined" onClick={printPDF} />
          </BudgetActions>
        </Header>
        <div id="printable">
          <ReportPreview budget={budget} inspection={inspection} clientLogo={client?.logoUrl} />
        </div>
      </ReportContainer>
    </Container>
  );
};

export default Report;
