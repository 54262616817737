import { IBudgetConclusion } from 'commom/types/Budget.types';
import React from 'react';
import DateService from 'services/DateService/dateService';
import {
  ConclusionCard,
  CardHeader,
  UserInfo,
  BoldText,
  ConclusionDetails,
  DetailLabel,
  CommentSection,
  LightText,
  ConclusionTypeContainer,
} from './BudgetConlusionCard.styles';

interface IConclusionCardProps {
  conclusion: IBudgetConclusion;
}

const BudgetConclusionCard = ({ conclusion }: IConclusionCardProps) => {
  return (
    <ConclusionCard>
      <CardHeader>
        <UserInfo>
          <BoldText>Responsável:</BoldText>
          <BoldText>{conclusion.user.name}</BoldText>
        </UserInfo>
        <div>{DateService.formatDateAndTime(conclusion.createdAt)}</div>
      </CardHeader>

      <ConclusionDetails>
        <ConclusionTypeContainer>
          <DetailLabel>Tipo de conclusão:</DetailLabel>
          <span>{conclusion.type}</span>
        </ConclusionTypeContainer>

        <ConclusionTypeContainer>
          <DetailLabel>Motivo:</DetailLabel>
          <span>{conclusion.reason}</span>
        </ConclusionTypeContainer>
      </ConclusionDetails>

      <CommentSection>
        <BoldText>Comentário de conclusão</BoldText>
        <LightText>{conclusion.comment}</LightText>
      </CommentSection>
    </ConclusionCard>
  );
};

export default BudgetConclusionCard;
