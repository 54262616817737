import { ICompany } from 'pages/SchedulerList/components/Inspection';
import React from 'react';
import { AuthorityNameEnum } from 'utils/AuthorityEnum';
import {
  CardUserContainer,
  CardUserUsername,
  CardUserAuthority,
  CardUserOperation,
  CardUserOperationsContainer,
  CardUserButtonWrapper,
  NavTooltip,
  HeaderContainer,
} from './CardUser.styles';
import { ICardUser } from './CardUser.types';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';

export const CardUserComponent: React.FC<ICardUser> = ({ accountId, username, authority, operations }) => {
  const renderOperations = (): JSX.Element[] => {
    const shownOperations = operations.slice(0, 2);

    return shownOperations.map((operation) => (
      <NavTooltip key={operation.id} title={operation.name} placement="bottom">
        <CardUserOperation>{operation.name}</CardUserOperation>
      </NavTooltip>
    ));
  };

  const showOperationsNames = (moreOperations: ICompany[]): string => {
    const operationsNames = moreOperations.map((operation) => operation.name);
    return operationsNames.join(', ');
  };

  const renderMoreOperations = (): JSX.Element => {
    const moreOperations = operations.slice(2);
    return (
      <NavTooltip title={showOperationsNames(moreOperations)} placement="bottom">
        <CardUserOperation key={moreOperations[0].id}>+{moreOperations.length}</CardUserOperation>
      </NavTooltip>
    );
  };

  const hasThreeOrMoreOperation = (): boolean => {
    if (operations.length > 2) {
      return true;
    }
    return false;
  };

  const isUsernameBig = (name: string): boolean => {
    return name.length > 31;
  };

  const GetAuthorityName = (auth: keyof typeof AuthorityNameEnum): string => AuthorityNameEnum[auth];

  return (
    <CardUserContainer data-testid="user-card">
      <HeaderContainer>
        {isUsernameBig(username) ? (
          <NavTooltip title={username} placement="top">
            <CardUserUsername>{username}</CardUserUsername>
          </NavTooltip>
        ) : (
          <CardUserUsername>{username}</CardUserUsername>
        )}
        <CardUserButtonWrapper>
          <ActionsMenu accountId={accountId} operations={operations} username={username} dataTestId="actions-menu" />
        </CardUserButtonWrapper>
      </HeaderContainer>
      <CardUserAuthority>{GetAuthorityName(authority as keyof typeof AuthorityNameEnum)}</CardUserAuthority>
      <CardUserOperationsContainer>
        {renderOperations()}
        {hasThreeOrMoreOperation() && renderMoreOperations()}
      </CardUserOperationsContainer>
    </CardUserContainer>
  );
};
