import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px;
    border-radius: 4px;
    max-width: 380px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  height: 24px;
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 16px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 330px;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`;

export const TextField = withTheme(styled(TextFieldComponent)`
  && {
    margin-bottom: ${(props): number => props.theme.spacing(2.5)}px;
    margin-top: ${(props): number => props.theme.spacing(1)}px;
  }
`);

export const ContainerButton = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;
