import React, { useState } from 'react';
import { Button } from 'components/Button';
import { toast } from 'react-toastify';
import { IOptions } from 'components/Select/Select.types';
import AccountService from 'services/ApiService/AccountService';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { AuthorityEnum } from 'utils/AuthorityEnum';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { IResponsibleDialogProps } from './ResponsibleDialog.types';
import * as S from './ResponsibleDialog.styles';

const ResponsibleDialog = ({
  inspectionId,
  companyId,
  isRegulator = true,
  dialogVisibility,
  handleClose,
}: IResponsibleDialogProps) => {
  const [responsibles, setResponsibles] = useState<IOptions[]>([]);
  const [responsibleId, setResponsibleId] = useState<string>('');
  const [loadingResponsibles, setLoadingResponsibles] = useState(false);
  const [loading, setLoading] = useState(false);
  const { invalidateInspectionParallel } = useMutateInspection();

  const isResponsiblesEmpty = !responsibles || responsibles.length === 0;

  const isSelectDisabled = isResponsiblesEmpty || loadingResponsibles || loading;

  const isSubmitButtonDisabled = !responsibleId;

  const getPlaceholderText = () => {
    if (isResponsiblesEmpty) return 'Nenhum responsável encontrado';

    return isRegulator ? 'Selecione o analista' : 'Selecione o perito';
  };

  const onChange = (value: string) => {
    setResponsibleId(value);
  };

  const handleCloseModal = () => {
    if (!loading) {
      handleClose();
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await InspectionService.putResponsible(inspectionId, responsibleId);
      await invalidateInspectionParallel(inspectionId);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      toast.error('Ocorreu um erro.');
    }
  };

  const getResponsibles = async () => {
    if (companyId) {
      setLoadingResponsibles(true);
      const authority = isRegulator ? AuthorityEnum.ANALYST : AuthorityEnum.REGULATOR;

      const response = await AccountService.getAccountsByAuthority(authority, [companyId]);

      const options = response.map(({ id, username }) => ({
        value: id,
        name: username,
      }));

      setResponsibles(options);
      setLoadingResponsibles(false);
    }
  };

  return (
    <S.DialogBase open={dialogVisibility} onClose={handleCloseModal} TransitionProps={{ onEnter: getResponsibles }}>
      <S.Title>{isRegulator ? 'Enviar para análise' : 'Voltar ao perito'}</S.Title>

      <S.Description>
        {isRegulator
          ? 'Selecione o analista que vai receber o orçamento para ser analisado.'
          : 'Deseja voltar o atendimento para o perito?'}
      </S.Description>

      <S.SelectWrapper>
        <S.SelectItem>
          <S.Label htmlFor="responsible-select" testID="responsible-label">
            {isRegulator ? 'Analista' : 'Perito'}
          </S.Label>
          <S.Select
            testID="responsible-select"
            labelId="responsible-select"
            name="responsible-select"
            placeholder={loadingResponsibles ? 'Carregando...' : getPlaceholderText()}
            disabled={isSelectDisabled}
            required={true}
            options={responsibles}
            selectedOptions={responsibleId}
            onChange={({ target: { value } }): void => onChange(value as string)}
          />
        </S.SelectItem>
      </S.SelectWrapper>

      <S.ContainerButton>
        <Button
          testID="cancel-button"
          type="button"
          variant="outlined"
          onClick={handleCloseModal}
          disabled={loading}
          text="CANCELAR"
        />
        <Button
          testID="submit-button"
          type="button"
          text="ENVIAR"
          onClick={handleSubmit}
          disabled={isSubmitButtonDisabled}
          loading={loading}
        />
      </S.ContainerButton>
    </S.DialogBase>
  );
};

export default ResponsibleDialog;
