import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px;
    border-radius: 4px;
    max-width: 420px;
  }
`;

export const DialogTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0;
  color: #000;
  height: 24px;
`;

export const FormWrapper = styled.div`
  padding-top: 15px;
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin: 25px 0px 0px;
`;

export const ButtonWrapper = styled.div`
  height: 45px;
  margin-left: 10px;
`;

export const ConfirmActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ConfirmActionText = styled.div`
  color: #919191;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding-left: 5px;

  :hover {
    cursor: pointer;
  }
`;
