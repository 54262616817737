import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 100px 50px 50px 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Montserrat;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  max-width: 1000px;
`;

export const Profile = styled.div`
  height: 157px;
  width: 120px;
  display: flex;
  flex-direction: column;

  align-items: center;
  cursor: pointer;
  &:hover {
    div {
      transform: scale(1.15);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    span {
      box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.5);
    }
    p {
      transform: translate(0, 10px);
    }
  }
`;

export const ContainerImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  transition: all 0.4s ease-out;
`;

export const ShadowImage = styled.span`
  border-radius: 50%;
  box-shadow: inset 0 0 0 4px (255, 255, 255, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  z-index: 99;
`;

export const ProfileImage = styled.img`
  width: 100%;
  border-radius: 50%;
`;

export const ProfileText = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  color: #000;
  text-align: center;
  transition: all 0.4s ease-out;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 80px;
  color: #000;
  span {
    font-weight: 600;
  }
`;

export const LogOut = styled.button`
  width: 75px;
  height: 45px;
  text-align: center;
  position: fixed;
  right: 30px;
  top: 30px;
  border: 2px solid #000;
  border-radius: 4px;
  background-color: #fff;
  font-family: Montserrat;
  font-weight: 700;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 60px;
  height: 60px;
  position: fixed;
  left: 30px;
  top: 30px;
`;
