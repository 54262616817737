import React from 'react';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import AttendanceData from './AttendanceData/AttendanceData';
import CustomerData from './CustomerData/CustomerData';
import VehicleData from './VehicleData/VehicleData';
import * as S from './InformationCard.styles';

export interface IInformationCardProps {
  inspection: IInspection;
}

const InformationCard = ({ inspection }: IInformationCardProps) => {
  const { product, informations, productType } = inspection;
  const isProductAuto = ProductTypeService.isAuto(productType?.code);

  const hasAttendanceData = !!informations?.length;
  const hasVehicleData = isProductAuto && !!product?.plate;

  const renderNextCard = (element: JSX.Element) => {
    return (
      <>
        <S.Divider />
        {element}
      </>
    );
  };

  return (
    <S.Content>
      <CustomerData inspection={inspection} />
      {hasAttendanceData && renderNextCard(<AttendanceData informations={informations} />)}
      {hasVehicleData && renderNextCard(<VehicleData inspection={inspection} />)}
    </S.Content>
  );
};

export default InformationCard;
