import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 60px;
  background: #f4f4f4;

  @media only screen and (max-width: 599px) {
    padding: 24px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  width: 100%;
  height: 10%;
  border-bottom: solid 1px #e8e8e8;
  background: #ffffff;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.h1`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  color: #000;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 20px 30px;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 150px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
`;

export const SummaryTitle = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #888;
`;

export const SummaryValue = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #000;
`;

export const SummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const SummaryLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #000;
  gap: 5px;
  width: 217px;
  height: 20px;
  cursor: pointer;

  :hover {
    background-color: #f3f3f3;
    border-radius: 5px;
  }

  .MuiSvgIcon-root {
    width: 18px;
  }
`;

export const TableContainer = styled.div`
  background-color: white;
  height: 550px;
  border-radius: 8px;
`;
