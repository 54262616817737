import React from 'react';
import { IDrawerBaseProps } from './DrawerProps.types';
import { HeaderTitle } from './Drawer.styles';

export default function DrawerTitle({ children, testId = 'drawer-title', className }: IDrawerBaseProps) {
  return (
    <HeaderTitle className={className} data-testid={testId}>
      {children}
    </HeaderTitle>
  );
}
