import React, { useCallback, useEffect, useState } from 'react';
import { IOptions } from 'components/Select/Select.types';
import { toast } from 'react-toastify';
import { Button } from 'components';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import * as S from './ConclusionDialog.styles';
import { IConclusionDialogProps } from './ConclusionDialog.types';

const commentProps = {
  'data-testid': 'input-area',
  form: {
    autocomplete: 'off',
  },
};

const ConclusionDialog = ({
  conclusion,
  handleClose,
  showConclusionDialog,
  inspectionId,
  companyConclusionTypes,
  testID,
}: IConclusionDialogProps) => {
  const [reasonsOptions, setReasonsOptions] = useState<IOptions[]>([]);
  const [selectedConclusionTypeId, setSelectedConclusionTypeId] = useState('');
  const [selectedReasonId, setSelectedReasonId] = useState('');
  const [commentInput, setCommentInput] = useState(() => conclusion?.comment || '');
  const [loadingButton, setLoadingButton] = useState(false);
  const disableSelectReason = reasonsOptions.length === 0;

  const verifyInputsChange = () => {
    return (
      conclusion?.conclusionType?.id !== selectedConclusionTypeId ||
      conclusion?.conclusionReason?.id !== selectedReasonId ||
      conclusion?.comment !== commentInput
    );
  };
  const disableSaveButton = (): boolean => {
    return commentInput === '' || selectedConclusionTypeId === '' || selectedReasonId === '' || !verifyInputsChange();
  };

  const conclusionTypesOptions: IOptions[] =
    companyConclusionTypes?.conclusionTypes.map((c) => ({
      value: c.id,
      name: c.description,
    })) || [];

  const handleChangeSelectedConclusionType = (value: string) => {
    if (value) {
      setSelectedConclusionTypeId(value);
      setSelectedReasonId('');
      setReasonsOptions(getReasonsOptionsFromCompanyConclusionType(value));
    } else {
      setSelectedConclusionTypeId('');
      setSelectedReasonId('');
      setReasonsOptions([]);
    }
  };

  const getConclusionTypeById = useCallback(
    (conclusionTypeId: string) => companyConclusionTypes?.conclusionTypes.find((c) => c.id === conclusionTypeId),
    [companyConclusionTypes]
  );

  const getReasonsOptionsFromCompanyConclusionType = useCallback(
    (conclusionTypeId: string): IOptions[] => {
      const conclusionTypeFiltered = getConclusionTypeById(conclusionTypeId);
      if (conclusionTypeFiltered) {
        return conclusionTypeFiltered.conclusionReasons.map((r) => ({
          value: r.id,
          name: r.description,
        }));
      }

      return [];
    },
    [getConclusionTypeById]
  );

  const getConclusionTypeAndReasonById = () => {
    const conclusionTypeFiltered = getConclusionTypeById(selectedConclusionTypeId);
    if (conclusionTypeFiltered) {
      const reasonFiltered = conclusionTypeFiltered.conclusionReasons.find((r) => r.id === selectedReasonId);
      if (reasonFiltered) {
        return {
          conclusionTypeId: conclusionTypeFiltered.id,
          conclusionReasonId: reasonFiltered.id,
          comment: commentInput,
        };
      }
    }

    return null;
  };

  const putConclusion = async () => {
    try {
      const conclusionTypeAndReason = getConclusionTypeAndReasonById();
      setLoadingButton(true);
      if (conclusionTypeAndReason) {
        await InspectionService.putConclusion(inspectionId, conclusionTypeAndReason);
      }
      toast.success('Conclusão salva com sucesso');
      await handleClose(true);
    } catch (error) {
      setLoadingButton(false);
      toast.error('Erro ao salvar conclusão');
    }
  };

  const handleCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    if (conclusion && companyConclusionTypes && conclusion.conclusionType && selectedConclusionTypeId === '') {
      setSelectedConclusionTypeId(conclusion.conclusionType.id);
      setReasonsOptions(getReasonsOptionsFromCompanyConclusionType(conclusion.conclusionType.id));

      if (conclusion.conclusionReason && selectedReasonId === '') {
        setSelectedReasonId(conclusion.conclusionReason.id);
      }
    }
  }, [
    conclusion,
    selectedConclusionTypeId,
    selectedReasonId,
    getReasonsOptionsFromCompanyConclusionType,
    companyConclusionTypes,
  ]);

  return (
    <S.DialogBase open={showConclusionDialog} onClose={() => handleCloseModal()} data-testid={testID}>
      <S.Title>Conclusão</S.Title>

      <S.SelectWrapper>
        <S.SelectItem>
          <S.Label htmlFor="type" testID="label-budget-type">
            Tipo de conclusão
          </S.Label>
          <S.Select
            testID="type-select"
            labelId="type-select"
            name="type-select"
            placeholder="Selecione o tipo de conclusão"
            required={true}
            options={conclusionTypesOptions}
            selectedOptions={selectedConclusionTypeId}
            onChange={({ target: { value } }): void => handleChangeSelectedConclusionType(value as string)}
          />
        </S.SelectItem>
        <S.SelectItem>
          <S.Label htmlFor="reason" testID="label-budget-reason">
            Motivo
          </S.Label>
          <S.Select
            testID="reason-select"
            labelId="reason-select"
            name="reason-select"
            placeholder="Selecione o motivo"
            required={true}
            disabled={disableSelectReason}
            options={reasonsOptions}
            selectedOptions={selectedReasonId}
            onChange={({ target: { value } }): void => setSelectedReasonId(value as string)}
          />
        </S.SelectItem>
      </S.SelectWrapper>

      <S.CommentWrapper>
        <S.Label htmlFor="comment" testID="label-comment-type">
          Comentário
        </S.Label>
        <S.TextField
          testID="comment-input"
          ariaLabelledby="comment-input"
          id="comment-input"
          inputProps={commentProps}
          name="comment"
          type="text"
          placeholder="Descrição da conclusão..."
          variant="outlined"
          value={commentInput}
          multiline
          minRows={10}
          onChange={({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void =>
            setCommentInput(value as string)
          }
        />
      </S.CommentWrapper>
      <S.SaveButtonWrapper>
        <Button
          text="Cancelar"
          testID="cancel-conclusion-button"
          variant="outlined"
          onClick={() => handleCloseModal()}
          disabled={loadingButton}
        />
        <Button
          text="Salvar"
          testID="salve-conclusion-button"
          variant="contained"
          loading={loadingButton}
          onClick={putConclusion}
          disabled={disableSaveButton()}
          loadingSize={25}
          loadingColor="#FFF"
        />
      </S.SaveButtonWrapper>
    </S.DialogBase>
  );
};

export default ConclusionDialog;
