import styled from 'styled-components';

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 10px 0;
`;

export const SkeletonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const SkeletonSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
