import React, { useCallback, useEffect, useRef, useState } from 'react';
import InspectionTableService from 'services/InspectionTableService/InspectionTableService';
import { Button, Table } from 'components';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import { FinishedInspectionEnum } from 'services/ApiService/MetricsService/metricsService.types';
import { toast } from 'react-toastify';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import Loading from 'components/Loading';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateService from 'services/DateService/dateService';
import { CSVLink } from 'react-csv';
import { MetricsTableRow } from '../../TableRow/TableRow';
import { MetricsFilter } from '../../MetricsFilter/MetricsFilter';
import { inspectionStatusFinished } from '../Charts.types';
import { IMetricsTableRow } from '../../TableRow/TableRow.types';
import * as Styled from './InspectionsFinishedDetails.styles';

export const InspectionsFinishedDetails = () => {
  const {
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    startDateSelected,
    productTypesSelected,
    regulatorSelected,
  } = useMetricsFilter();

  useHideZendesk();

  const [page, setPage] = useState<number>(0);
  const [tableData, setTableData] = useState<IMetricsTableRow[]>([]);
  const [dataCSV, setDataCSV] = useState<IMetricsTableRow[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [CSVloading, setCSVLoading] = useState<boolean>(false);
  const [filterMotive, setFilterMotive] = useState<FinishedInspectionEnum>(FinishedInspectionEnum.MORE_THAN_48_HOURS);
  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const dataTableIsEmpty = tableData.length === 0;
  const rowsPerPage = 7;

  const formatCSVFileName = () => {
    const date = DateService.formatDate(new Date().toISOString());
    return `tabela_atendimentos_finalizados_${date}`;
  };

  const getFinishedInspections = useCallback(
    (isDownloadCSV: boolean) => {
      return MetricsService.getFinishedInspectionsChartDetails({
        companyIds: companiesSelected || [],
        createdAtGreaterThan: startDateSelected || '',
        createdAtLessThan: endDateSelected || '',
        inspectionCategoryIds: categoriesSelected || [],
        inspectionProductTypeIds: productTypesSelected || [],
        regulatorIds: [regulatorSelected?.value || ''],
        page: isDownloadCSV ? '' : String(page),
        size: isDownloadCSV ? '' : String(rowsPerPage),
        inspectionFinishedTimes: filterMotive,
      });
    },
    [
      categoriesSelected,
      companiesSelected,
      endDateSelected,
      page,
      productTypesSelected,
      regulatorSelected,
      rowsPerPage,
      startDateSelected,
      filterMotive,
    ]
  );

  const handleFinishedInspections = useCallback(async () => {
    setLoading(true);

    try {
      const isDownloadCSV = false;
      const response = await getFinishedInspections(isDownloadCSV);

      const dataInspectionsFinishedTable = InspectionTableService.getInspectionsChartsTableRows(response.inspections);

      setTotalPages(response.totalPages);
      setTotalCount(response.totalCount);
      setTableData(dataInspectionsFinishedTable);
    } catch {
      setTableData([]);
      toast.error('Falha ao carregar detalhes dos atendimentos');
    }

    setLoading(false);
  }, [getFinishedInspections]);

  const getTableDataCSV = async () => {
    setCSVLoading(true);

    try {
      const isDownloadCSV = true;

      const response = await getFinishedInspections(isDownloadCSV);
      setDataCSV(InspectionTableService.getInspectionsChartsTableRows(response.inspections));
    } catch {
      toast.error('Erro ao realizar o download do CSV');
    }

    setCSVLoading(false);
  };

  const setFilterData = (filterSelected: FinishedInspectionEnum) => {
    setFilterMotive(filterSelected);
    setPage(0);
  };

  useEffect(() => {
    if (companiesSelected?.length > 0) {
      handleFinishedInspections();
    }
  }, [companiesSelected, handleFinishedInspections]);

  useEffect(() => {
    if (dataCSV && csvInstance && csvInstance.current) {
      csvInstance?.current?.link.click();

      setDataCSV([]);
    }
  }, [dataCSV]);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>
          <Styled.Link>Atendimentos Finalizados</Styled.Link>
          <Styled.ArrowForwardIcon />
          Detalhamento
        </Styled.Title>
        <Styled.BackButton to="/metrics">
          <Styled.ArrowBackIcon />
          Voltar
        </Styled.BackButton>
      </Styled.Header>
      <Styled.Content>
        <MetricsFilter style={{ padding: 0 }} />
        <Styled.FilterButtonsWrapper>
          <Styled.ContainerFilterButtons>
            <Styled.FilterButton
              text={inspectionStatusFinished.MORE_THAN_48_HOURS.label}
              variant="outlined"
              textColor={inspectionStatusFinished.MORE_THAN_48_HOURS.color}
              background={inspectionStatusFinished.MORE_THAN_48_HOURS.background}
              onClick={() => setFilterData(FinishedInspectionEnum.MORE_THAN_48_HOURS)}
              isSelected={filterMotive === FinishedInspectionEnum.MORE_THAN_48_HOURS}
            />
            <Styled.FilterButton
              text={inspectionStatusFinished.WITHIN_48_HOURS.label}
              variant="outlined"
              textColor={inspectionStatusFinished.WITHIN_48_HOURS.color}
              background={inspectionStatusFinished.WITHIN_48_HOURS.background}
              onClick={() => setFilterData(FinishedInspectionEnum.WITHIN_48_HOURS)}
              isSelected={filterMotive === FinishedInspectionEnum.WITHIN_48_HOURS}
            />
            <Styled.FilterButton
              text={inspectionStatusFinished.WITHIN_24_HOURS.label}
              variant="outlined"
              textColor={inspectionStatusFinished.WITHIN_24_HOURS.color}
              background={inspectionStatusFinished.WITHIN_24_HOURS.background}
              onClick={() => setFilterData(FinishedInspectionEnum.WITHIN_24_HOURS)}
              isSelected={filterMotive === FinishedInspectionEnum.WITHIN_24_HOURS}
            />
          </Styled.ContainerFilterButtons>

          <Button
            variant="outlined"
            type="button"
            text="BAIXAR CSV"
            disabled={dataTableIsEmpty}
            startIcon={<GetAppIcon />}
            loading={CSVloading}
            loadingColor="black"
            onClick={() => getTableDataCSV()}
          />

          {dataCSV && dataCSV.length > 0 ? (
            <Styled.ButtonCSV
              data={dataCSV}
              filename={formatCSVFileName()}
              headers={InspectionTableService.getInspectionFinishedChartCSVHeaders()}
              target="_blank"
              enclosingCharacter=""
              ref={csvInstance}
            />
          ) : undefined}
        </Styled.FilterButtonsWrapper>

        {loading ? (
          <Styled.LoadingContainer>
            <Loading width="100%" />
          </Styled.LoadingContainer>
        ) : (
          <Table
            headers={InspectionTableService.getInspectionChartTableHeaders(true)}
            page={dataTableIsEmpty ? 0 : page}
            count={dataTableIsEmpty ? 0 : totalCount}
            setPage={setPage}
            rowsPerPage={dataTableIsEmpty ? 0 : rowsPerPage}
            emptyMessage="Nenhum resultado encontrado"
            totalPages={dataTableIsEmpty ? 0 : totalPages}
          >
            {tableData.map((row, index) => (
              <MetricsTableRow key={index} row={row} showTime={true} />
            ))}
          </Table>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
