import styled from 'styled-components';

export const ContainerEmptyConclusion = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    max-width: 296px;
    max-height: 242px;
  }
`;

export const TitleEmptyConclusion = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: #6a758b;
  width: 253px;
  height: 18px;
`;

export const DescriptionEmptyConclusion = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #6a758b;
  width: 220px;
  height: 45px;
`;

export const ArchivedDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #6a758b;
  margin: 0 0 15px 10px;
`;

export const ConclusionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
