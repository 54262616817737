/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

interface IGalleryProps {
  orientation: string;
  positionLoading?: boolean;
  isOrganizingPhotos?: boolean;
  isPhotoSelected?: boolean;
  canSelectSwappPhotos?: boolean;
}

interface IThumbnailProps {
  isOrganizingPhotos?: boolean;
  isPhotoSelected?: boolean;
  canSelectSwappPhotos?: boolean;
}

interface ILoadingProps {
  width: string;
  height: string;
  orientation: string;
}

export const GalleryWrapper = styled.div<IGalleryProps>`
  display: ${({ orientation }): string => (orientation === 'horizontal' ? 'contents' : 'flex')};
  flex-direction: ${({ orientation }): string => (orientation === 'horizontal' ? 'row' : 'column')};
  max-height: 60vh;
  overflow-x: auto;
  padding: 0 5px;
  width: ${({ orientation }): string => (orientation === 'horizontal' ? 'auto' : '40%')};
  ${({ orientation }): string => (orientation === 'vertical' ? 'row-gap: 10px' : '')};

  @media (max-width: 1366px) {
    width: 30%;
  }
`;

export const ImageSkeletonIcon = styled.div<IThumbnailProps>`
  ${({ isPhotoSelected, isOrganizingPhotos }): string =>
    isPhotoSelected && isOrganizingPhotos ? 'display: flex;' : 'display: none;'}
  background: #ffffff;
  border: 2px solid black;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -12px;
  left: -12px;
`;

export const Thumbnail = styled.img<IGalleryProps>`
  ${({ positionLoading }): string => (positionLoading ? 'position: absolute;' : '')}
  cursor: pointer;
  border-radius: 4px;

  ${({ isPhotoSelected }): string => (isPhotoSelected ? 'border: 2px solid black; margin-top: -25px;' : '')}
`;

export const ThumbnailWrapper = styled.div<IThumbnailProps>`
  ${({ isOrganizingPhotos }): string => (isOrganizingPhotos ? 'margin-top: 0px;' : 'margin-top: 0px;')}

  &:hover ${ImageSkeletonIcon} {
    ${({ isOrganizingPhotos, canSelectSwappPhotos, isPhotoSelected }): string =>
      isOrganizingPhotos && canSelectSwappPhotos
        ? 'display: flex;'
        : isPhotoSelected
          ? 'display: flex;'
          : 'display: none;'}
  }

  &:hover ${Thumbnail} {
    ${({ isOrganizingPhotos, canSelectSwappPhotos }): string =>
      isOrganizingPhotos && canSelectSwappPhotos ? 'border: 2px solid black; margin-top: -25px;' : ''}
  }
`;

export const Loading = styled(Skeleton)<ILoadingProps>`
  width: ${({ width }): string => width}px !important;
  height: ${({ orientation, height }): string => (orientation === 'horizontal' ? `${height}px` : '400px')} !important;
  margin: 0 20px 0 0;
  border-radius: 4px;
  padding: ${({ orientation }): string => (orientation === 'horizontal' ? '0 5px' : '5px 0')};
`;

export const LightboxWrapper = styled.div``;

export const ImageCardWrapper = styled.div`
  height: fit-content;
  background: #ffffff;
  border: 1px solid #dbd9d9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0px 15px 10px 15px;
`;

export const ImageIndexBadge = styled.div`
  background: #ffffff;
  border: 1px solid #dbd9d9;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -13px;
  left: -28px;
`;

export const SwapIcon = styled(SwapHorizIcon)`
  width: 20px !important;
`;

export const ImageCardTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
  height: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  margin-top: -15px;
`;
