import React from 'react';
import { Switch } from 'react-router-dom';
import Route from 'routes/CustomRoute';
import Budgets from '../Budgets/Budgets';
import { IInspection } from '../Inspection/Inspection.types';
import InspectionHistory from '../InspectionHistory/InspectionHistory';
import Transmission from '../Transmission/Transmission';
import { TransmissionContainer } from './inspectionDetails.styles';
import CreateBudget from '../CreateBudget/CreateBudget';

interface IInspectionDetailsRouterProps {
  inspection: IInspection;
}

function InspectionDetailsRouter({ inspection }: IInspectionDetailsRouterProps) {
  return (
    <Switch>
      <Route
        exact
        path="/agendamentos/v2/detalhes/:id"
        component={() => <InspectionHistory inspection={inspection} />}
        isPrivate
      />
      <Route
        isPrivate
        path="/agendamentos/v2/detalhes/:id/transmissao"
        component={() => (
          <TransmissionContainer>
            <Transmission />
          </TransmissionContainer>
        )}
      />
      <Route isPrivate path="/agendamentos/v2/detalhes/:id/criar-orcamento" component={() => <CreateBudget />} />
      <Route
        isPrivate
        path="/agendamentos/v2/detalhes/:id/orcamentos"
        component={() => <Budgets inspection={inspection} />}
      />
    </Switch>
  );
}

export default React.memo(
  InspectionDetailsRouter,
  (prevProps: Readonly<IInspectionDetailsRouterProps>, nextProps: Readonly<IInspectionDetailsRouterProps>) =>
    prevProps.inspection === nextProps.inspection
);
