import React from 'react';
import { InspectionDocuments } from 'components/InspectionDocuments/InspectionDocuments';
import { DocumentsWrapper, Title, DocumentsState } from './Documents.styles';
import { IDocumentsProps } from './Documents.types';

export const Documents = ({ documents, testID, company }: IDocumentsProps): JSX.Element => {
  const renderDocumentsReceivedMessage = () => {
    if (documents && documents.length === 0) {
      return (
        <>
          <b>Nenhum</b> documento recebido.
        </>
      );
    }
    if (documents && documents.length === 1) {
      return (
        <>
          Foi recebido <b>1</b> documento.
        </>
      );
    }
    return (
      <>
        Foram recebidos <b>{documents && documents.length}</b> documentos.
      </>
    );
  };

  return (
    <>
      {documents !== undefined && (
        <>
          <DocumentsState>
            <Title>{renderDocumentsReceivedMessage()}</Title>
            {company?.id && !!documents.length}
          </DocumentsState>
          <DocumentsWrapper role="document-wrapper" data-testid={testID}>
            <InspectionDocuments
              documents={documents}
              orientation="horizontal"
              thumbnailHeigth="120"
              thumbnailWidth="160"
            />
          </DocumentsWrapper>
        </>
      )}
    </>
  );
};
