import styled from 'styled-components';

export const DetailsLoadingWrapper = styled.div`
  padding: 40px 30px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: #6a758b;
  margin: 0;
  margin-bottom: 30px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 600;
`;

export const TitleCanceled = styled(Title)`
  color: #c73c3c;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const DetailsHeader = styled.div`
  position: relative;
  background-color: #fff;
  padding: 30px 50px 0 50px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
`;

export const DetailsBody = styled.div`
  padding: 40px 30px;
`;

export const ContainerLinkNotSent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 230px 0 230px;
`;

export const TitleLinkNotSent = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: #6a758b;
`;

export const DescriptionLinkNotSent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #6a758b;
  text-align: center;

  span {
    font-weight: bold;
  }
`;
