import { BudgetPartServiceHoursKeys, IBudgetLaborCostTypeEnum } from 'commom/types/Budget.types';
import IncrementDecrementButton from 'components/IncrementDecrementButton/IncrementDecrementButton';
import InputTime from 'components/InputTime/InputTime';
import { Label } from 'components/Label';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import { selectCost } from 'stores/presentBudget/presentBudget.selector';
import { selectVehiclePaintingType } from 'stores/presentInspection/presentInspection.selector';
import { priceFormatter } from 'utils/formatPrice';
import ManuallyPartPrice from './ManuallyPartPrice';
import {
  ContentWrapper,
  InputWrapper,
  LabelService,
  LaborServicesButtons,
  Separator,
  ServiceWrapper,
} from './PartDrawer.styles';
import PartPrice from './PartPrice';

const Content = () => {
  const {
    isServiceTypeRepair,
    partServiceHours,
    quantityParts,
    setQuantityParts,
    setPartServiceHours,
    partOriginalServiceHours,
    isPartyManuallyInserted,
  } = usePartContext();
  const paintingType = useAppSelector(selectVehiclePaintingType);
  const cost = useAppSelector(selectCost);

  const laborCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.LABOR);
  const repairCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.REPAIR);
  const paintingCost = cost.find((item) => item.type === paintingType?.toString());

  const handleIncrement = (prevValue: number) => {
    return prevValue + 0.5;
  };

  const handleDecrement = (prevValue: number) => {
    const decrementedValue = prevValue - 0.5;
    return decrementedValue > 0 ? decrementedValue : 0;
  };

  const handleDecrementOrIncrementPartServiceHours = (
    key: BudgetPartServiceHoursKeys,
    callback: (prevValue: number) => number
  ) => {
    setPartServiceHours((prev) => ({ ...prev, [key]: callback(prev[key]) }));
  };

  const handleChangePartServiceHours = (key: BudgetPartServiceHoursKeys, valueAsString: string) => {
    const valueReplaced = valueAsString.replace(',', '.');
    const valueFormatted = Number.isNaN(parseFloat(valueReplaced)) ? 0 : parseFloat(valueReplaced);

    setPartServiceHours((prev) => ({ ...prev, [key]: valueFormatted }));
  };

  const getArrowIndicator = (key: BudgetPartServiceHoursKeys) => {
    if (partServiceHours[key] > partOriginalServiceHours[key]) {
      return 'UP';
    }

    if (partServiceHours[key] < partOriginalServiceHours[key]) {
      return 'DOWN';
    }

    return '';
  };

  const getLabelBottom = (key: BudgetPartServiceHoursKeys) => {
    if (key === 'removeAndInstall') {
      return priceFormatter.format(partServiceHours.removeAndInstall * (laborCost?.price || 0));
    }

    if (key === 'repair') {
      return priceFormatter.format(partServiceHours.repair * (repairCost?.price || 0));
    }

    return priceFormatter.format(partServiceHours.painting * (paintingCost?.price || 0));
  };

  return (
    <ContentWrapper>
      <ServiceWrapper>
        <LabelService>Horas</LabelService>
        <LaborServicesButtons>
          <InputTime
            testID="remove-and-install"
            labelTop="Remoção & Instalação"
            value={partServiceHours.removeAndInstall}
            onChangeInput={({ target: { value } }) => handleChangePartServiceHours('removeAndInstall', value)}
            handleDecrement={() => handleDecrementOrIncrementPartServiceHours('removeAndInstall', handleDecrement)}
            handleIncrement={() => handleDecrementOrIncrementPartServiceHours('removeAndInstall', handleIncrement)}
            arrowIndicator={getArrowIndicator('removeAndInstall')}
            labelBottom={getLabelBottom('removeAndInstall')}
          />

          {isServiceTypeRepair && (
            <InputTime
              testID="repair"
              labelTop="Reparo"
              value={partServiceHours.repair}
              onChangeInput={({ target: { value } }) => handleChangePartServiceHours('repair', value)}
              handleDecrement={() => handleDecrementOrIncrementPartServiceHours('repair', handleDecrement)}
              handleIncrement={() => handleDecrementOrIncrementPartServiceHours('repair', handleIncrement)}
              arrowIndicator={getArrowIndicator('repair')}
              labelBottom={getLabelBottom('repair')}
            />
          )}

          <InputTime
            testID="painting"
            labelTop="Pintura"
            value={partServiceHours.painting}
            onChangeInput={({ target: { value } }) => handleChangePartServiceHours('painting', value)}
            handleDecrement={() => handleDecrementOrIncrementPartServiceHours('painting', handleDecrement)}
            handleIncrement={() => handleDecrementOrIncrementPartServiceHours('painting', handleIncrement)}
            arrowIndicator={getArrowIndicator('painting')}
            labelBottom={getLabelBottom('painting')}
          />
        </LaborServicesButtons>

        <InputWrapper>
          <Label htmlFor="input-quantity">Quantidade</Label>
          <IncrementDecrementButton
            counter={quantityParts}
            handleInput={({ target: { value } }) => setQuantityParts(Number(value))}
            handleDecrement={() => setQuantityParts((prev) => prev - 1)}
            handleIncrement={() => setQuantityParts((prev) => prev + 1)}
          />
        </InputWrapper>
      </ServiceWrapper>

      {!isServiceTypeRepair && <Separator />}

      {!isServiceTypeRepair && (
        <ServiceWrapper>
          <LabelService>Valores</LabelService>
          {isPartyManuallyInserted ? <ManuallyPartPrice /> : <PartPrice />}
        </ServiceWrapper>
      )}
    </ContentWrapper>
  );
};

export default Content;
