import React, { useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Button } from 'components/Button';
import UpdateIcon from 'assets/svg/update-icon.svg';
import CloseIcon from 'assets/svg/close-icon.svg';
import { Tooltip } from '@material-ui/core';
import InspectionPhotoService from 'services/ApiService/InspectionPhotoService/InspectionPhotoService';
import { toast } from 'react-toastify';
import { StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { InspectionCategoryEnum } from 'utils/InspectionEnum';
import { IUploadPhotosDialogProps } from './UploadPhotosDialog.types';

import {
  DialogBase,
  Title,
  Description,
  DragAndDropArea,
  ContainerButton,
  PhotoList,
  PhotoItem,
  CloseButton,
  UpdateButton,
  ErrorMessage,
} from './UploadPhotosDialog.styles';

const UploadPhotosDialog = ({
  dialogVisibility,
  inspectionId,
  currentStatus,
  inspectionPhotos,
  handleClose,
  inspectionCategory,
}: IUploadPhotosDialogProps) => {
  const [images, setImages] = useState<ImageListType>([]);
  const [loading, setLoading] = useState(false);
  const { invalidateInspectionParallel } = useMutateInspection();

  const isButtonSubmitDisabled = images.length === 0;
  const currentStatusIsLinkSent = currentStatus?.type === StatusEnum.LINK_SENT;
  const currentStatusIsDefault = currentStatus?.type === StatusEnum.DEFAULT;
  const isLocally =
    inspectionCategory !== undefined &&
    inspectionCategory.code === InspectionCategoryEnum.LOCALLY &&
    currentStatusIsDefault;

  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const handleCloseModal = (event: React.MouseEvent<HTMLElement>, onImageRemoveAll: () => void) => {
    event.stopPropagation();

    if (!loading) {
      onImageRemoveAll();
      handleClose();
    }
  };

  const getTotalPhotosWithOriginUploadKite = (): number => {
    return inspectionPhotos.filter((photo) => photo.origin === 'UPLOAD_KITE').length;
  };

  const getMaxStepFromPhotos = (): number => {
    const maxStep = inspectionPhotos.reduce((acc, photo) => {
      if (photo.step && photo.step > acc) return photo.step;

      return acc;
    }, 0);

    return maxStep;
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>, onImageRemoveAll: () => void) => {
    event.stopPropagation();

    try {
      setLoading(true);
      const totalPhotosWithOriginUploadKite = getTotalPhotosWithOriginUploadKite();
      const maxStepFromPhotos = getMaxStepFromPhotos();

      if (currentStatusIsLinkSent || isLocally) {
        await InspectionStatusService.photosReceived(inspectionId);
      }

      await InspectionPhotoService.uploadPhotos(
        inspectionId,
        images,
        maxStepFromPhotos,
        totalPhotosWithOriginUploadKite
      );
      onImageRemoveAll();
      setLoading(false);
      invalidateInspectionParallel(inspectionId);
      handleClose();
    } catch (error) {
      setLoading(false);
      toast.error('Falha ao enviar fotos.');
    }
  };

  return (
    <ImageUploading multiple value={images} onChange={onChange} acceptType={['jpg', 'png', 'jpeg']}>
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemoveAll,
        onImageRemove,
        isDragging,
        dragProps,
        errors,
      }) => (
        <>
          <DialogBase
            open={dialogVisibility}
            onClose={(e: React.MouseEvent<HTMLElement>) => handleCloseModal(e, onImageRemoveAll)}
            disableEnforceFocus
          >
            <Title>Enviar fotos</Title>

            <Description>Para realizar o atendimento, envie as fotos clicando abaixo</Description>

            <DragAndDropArea
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                onImageUpload();
              }}
              isDragging={isDragging}
              {...dragProps}
            >
              <p>Clique ou arraste a foto aqui</p>
            </DragAndDropArea>

            {errors?.acceptType && <ErrorMessage>* Tipo de arquivo não suportado, use apenas JPG ou PNG</ErrorMessage>}

            <PhotoList>
              {imageList.map(
                (image, index) =>
                  image.dataURL && (
                    <PhotoItem key={`${image.dataURL},${index}`} path={image.dataURL}>
                      <Tooltip title="Substituir foto" placement="top">
                        <UpdateButton
                          type="button"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            onImageUpdate(index);
                          }}
                        >
                          <img src={UpdateIcon} alt="update-icon" /> Substituir
                        </UpdateButton>
                      </Tooltip>
                      <Tooltip title="Remover foto" placement="top">
                        <CloseButton
                          type="button"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            onImageRemove(index);
                          }}
                        >
                          <img src={CloseIcon} alt="close-icon" />
                        </CloseButton>
                      </Tooltip>
                    </PhotoItem>
                  )
              )}
            </PhotoList>

            <ContainerButton>
              <Button
                testID="upload-photos-cancel"
                type="button"
                variant="outlined"
                onClick={(e: React.MouseEvent<HTMLElement>) => handleCloseModal(e, onImageRemoveAll)}
                disabled={loading}
                text="CANCELAR"
              />
              <Button
                testID="upload-photos-submit"
                type="button"
                text="SALVAR"
                onClick={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e, onImageRemoveAll)}
                disabled={isButtonSubmitDisabled}
                loading={loading}
              />
            </ContainerButton>
          </DialogBase>
        </>
      )}
    </ImageUploading>
  );
};

export default UploadPhotosDialog;
