export interface INotification {
  id: string;
  createdAt: string;
  updatedAt: string;
  clientId: string;
  accountId: string;
  title: string;
  message: string;
  status: NotificationStatusEnum;
  type: NotificationTypeEnum;
  action?: INotificationAction;
  metaData?: INotificationMetaData;
}

export enum NotificationStatusEnum {
  UNREAD = 'UNREAD',
  READ = 'READ',
  DELETED = 'DELETED',
}

export enum NotificationTypeEnum {
  MESSAGE = 'MESSAGE',
  ALERT = 'ALERT',
}

export interface INotificationAction {
  link: string;
  label: string;
}

export interface INotificationMetaData {
  inspection: INotificationInspectionData;
}

export interface INotificationInspectionData {
  inspectionId: string;
  code: string;
}
