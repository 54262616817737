import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import { Helmet } from 'react-helmet';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import environment from 'environments/environment';
import ContextProvider from 'contexts';
import { Provider } from 'react-redux';
import { store } from 'stores/store';
import './i18n';
import './firebaseConfig';

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <title>
        {environment.TYPE}
        {environment.PROJECT_NAME}
      </title>
    </Helmet>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
