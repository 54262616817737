import React from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'themes/default';
import { toast } from 'react-toastify';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { IAccountGroups, IClient } from 'services/ApiService/AccountService/types';
import { useAuthState, useAuthDispatch } from 'contexts/auth/useAuth';
import AbleLogo from 'assets/svg/able-logo.svg';
import { useAppDispatch } from 'hooks/hooks';
import { updateActiveProfile } from 'stores/activeProfile.store';
import { useModules } from 'contexts/modules/useModules';
import {
  Container,
  ProfileWrapper,
  Profile,
  ProfileText,
  Title,
  ProfileImage,
  LogOut,
  Logo,
  ShadowImage,
  ContainerImage,
} from './SelectProfile.styles';

export const SelectProfile: React.FC = () => {
  const { account } = useAuthState();
  const { signOut } = useAuthDispatch();
  const { accountGroups, setActiveProfile } = useActiveProfile();
  const { setSelectedClientModules } = useModules();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const selectProfile = async (clientId: string): Promise<void> => {
    await setSelectedClientModules(clientId);
    await setActiveProfile(clientId);
    dispatch(updateActiveProfile(clientId));
    history.push('/');
  };

  const getUserName = (): string => {
    const { username } = account;
    return username !== undefined ? username.split('@')[0] : '';
  };

  const logOut = async (): Promise<void> => {
    try {
      await signOut();

      history.push('/login');
    } catch (error) {
      toast.error('Error ao tentar sair, tente novamente.');
    }
  };

  const fromAccountGroupsToClient = (accGroups: IAccountGroups): IClient[] => {
    return accGroups?.groups?.map((group) => group.client);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container data-testid="login">
        <Title data-testid="title-message">
          Olá, <span>{getUserName()}</span>! Qual ambiente deseja usar?
        </Title>{' '}
        <ProfileWrapper>
          {fromAccountGroupsToClient(accountGroups)?.map((client) => {
            return (
              <Profile
                onClick={async (): Promise<void> => selectProfile(client.id)}
                key={client.id}
                data-testid="select-profile-item"
              >
                <ContainerImage>
                  <ShadowImage />
                  <ProfileImage src={client.logoUrl} alt={client.name} />
                </ContainerImage>
                <ProfileText>{client.name}</ProfileText>
              </Profile>
            );
          })}
        </ProfileWrapper>
        <Logo src={AbleLogo} />
        <LogOut onClick={(): Promise<void> => logOut()} data-testid="button-logout">
          SAIR
        </LogOut>
      </Container>
    </ThemeProvider>
  );
};
