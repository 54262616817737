import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button } from 'components/Button';
import { Label } from 'components/Label';

import { StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';

import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import NotificationService from 'services/ApiService/NotificationService/notificationService';
import { formValidations } from 'utils/form/validations';
import { useQueryClient } from 'react-query';
import { normalizePhone } from '../../../utils/formatPhone';

import { IResendLinkDialogProps, IResendLinkForm } from './ResendLinkDialog.types';
import { DialogBase, Title, Description, TextField, ContainerButton, ContainerForm } from './ResendLinkDialog.styles';

const ResendLinkDialog: React.FC<IResendLinkDialogProps> = ({ dialogVisibility, handleClose, inspection }) => {
  const { register, errors, handleSubmit, control } = useForm({
    mode: 'onBlur',
  });
  const [resendLinkLoading, setResendLinkLoading] = useState(false);
  const queryClient = useQueryClient();

  const onSubmit = async (form: IResendLinkForm): Promise<void> => {
    try {
      setResendLinkLoading(true);

      const inputPhone = normalizePhone(form.phone);

      const inspectionResult = await InspectionService.getInspectionsById(inspection.id);
      const phone = inspectionResult?.inspection?.customer?.phone || '';

      if (checkPhonesIsNotEqual(inputPhone, phone)) {
        await InspectionService.putPhone(inspection.id, inputPhone);
      }

      const { type } = inspection.currentStatus;

      if (type === StatusEnum.DEFAULT) {
        await InspectionStatusService.linkSent(inspection.id);
      } else {
        await NotificationService.sendByInspectionId(inspection.id);
      }
      setResendLinkLoading(false);
      handleClose();
      queryClient.invalidateQueries({
        queryKey: ['get-inspection-by-id', { id: inspection.id }],
      });
      queryClient.invalidateQueries({ queryKey: ['get-inspections'] });
      toast.success('Link enviado!');
    } catch (error) {
      toast.error('Falha! Link não enviado.');
      setResendLinkLoading(false);
      handleClose();
    }
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const checkPhonesIsNotEqual = (inputPhone: string, inspectionPhone: string): boolean => {
    return inputPhone !== inspectionPhone;
  };

  const inputProps = {
    phone: {
      'data-testid': 'input-phone',
    },
  };

  return (
    <DialogBase
      data-testid="resend-link-dialog"
      aria-labelledby="form-dialog-title"
      open={dialogVisibility}
      onClose={handleClose}
    >
      <Title>Reenviar Link</Title>
      <Description>O link será enviado para o número abaixo.</Description>

      <ContainerForm data-testid="resend-link-dialog-form">
        <Label>Número do telefone</Label>
        <Controller
          name="phone-control"
          control={control}
          defaultValue={inspection?.customer?.phone}
          render={({ onChange, value }): JSX.Element => (
            <TextField
              ariaLabelledby="telefone"
              error={hasError('phone')}
              id="phone"
              inputProps={inputProps?.phone}
              inputRef={register(formValidations?.phone)}
              helperText={errors?.phone?.message}
              mask="(00) 0 0000-0000"
              name="phone"
              placeholder="Ex. (99) 9 9999-9999"
              state={handleInputState('phone')}
              type="text"
              variant="outlined"
              onChange={onChange}
              value={value}
            />
          )}
        />

        <ContainerButton>
          <Button testID="resend-link-cancel" type="button" variant="outlined" onClick={handleClose} text="CANCELAR" />
          <Button
            testID="resend-link-submit"
            type="button"
            text="ENVIAR"
            loading={resendLinkLoading}
            loadingSize={25}
            loadingColor="#FFF"
            onClick={handleSubmit(onSubmit)}
          />
        </ContainerButton>
      </ContainerForm>
    </DialogBase>
  );
};

export default ResendLinkDialog;
