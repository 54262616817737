import React from 'react';
import { useAppSelector } from 'hooks/hooks';
import { DataBox } from 'components/DataBox';
import { priceFormatter } from 'utils/formatPrice';
import { Card, CardBody, CardHeader, CardTitle } from '../InitialData.styles';

const Vehicle = () => {
  const vehicle = useAppSelector((state) => state.presentInspection.inspection.product?.vehicle);

  const getFipeValue = () => {
    return vehicle?.fipeInfo?.currentPrice !== undefined ? priceFormatter.format(vehicle?.fipeInfo?.currentPrice) : '-';
  };

  const vehicleInfos = [
    { label: 'Placa', value: vehicle?.plate },
    { label: 'Tipo', value: vehicle?.type },
    { label: 'Marca', value: vehicle?.brand },
    { label: 'Modelo', value: vehicle?.model },
    { label: 'Versão', value: vehicle?.version },
    { label: 'Ano', value: vehicle?.modelYear },
    { label: 'Chassi', value: vehicle?.chassis },
    { label: 'Tipo de pintura', value: vehicle?.paintingType },
    { label: 'Fipe', value: getFipeValue() },
    { label: 'Código Fipe', value: vehicle?.fipeInfo?.fipeCode },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>VEÍCULO</CardTitle>
        </CardHeader>

        <CardBody>
          <DataBox.Root>
            <DataBox.Content>
              {vehicleInfos.map(({ label, value }) => (
                <DataBox.Item key={label}>
                  <DataBox.Label>{label}</DataBox.Label>
                  <DataBox.Value>{value || '-'}</DataBox.Value>
                </DataBox.Item>
              ))}
            </DataBox.Content>
          </DataBox.Root>
        </CardBody>
      </Card>
    </>
  );
};

export default Vehicle;
