import React, { createContext, useCallback } from 'react';

import FeatureTogglesService from 'services/ApiService/FeatureToggles';

import { IFeatureTogglesDispatchContextData } from './types';

export const FeatureTogglesDispatchContext = createContext<IFeatureTogglesDispatchContextData>(
  {} as IFeatureTogglesDispatchContextData
);

export const FeatureTogglesContainer: React.FC = ({ children }) => {
  const getFeatureToggles = useCallback(async (): Promise<void> => {
    try {
      const toggles = await FeatureTogglesService.getToggles();

      FeatureTogglesService.deleteFeatureTogglesInLocalStorage();
      FeatureTogglesService.setFeatureTogglesInLocalStorage(toggles);
    } catch (error) {
      throw new Error();
    }
  }, []);

  const isFeatureToggleActive = useCallback((featureName: string): boolean => {
    const togglesInLocalStorage = FeatureTogglesService.getFeatureTogglesInLocalStorage();

    return !!Object.keys(togglesInLocalStorage).includes(featureName) && togglesInLocalStorage[featureName];
  }, []);

  return (
    <FeatureTogglesDispatchContext.Provider value={{ getFeatureToggles, isFeatureToggleActive }}>
      {children}
    </FeatureTogglesDispatchContext.Provider>
  );
};
