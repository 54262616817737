import React from 'react';
import Step from '@material-ui/core/Step';
import { StepIconProps } from '@material-ui/core';
import InspectionStartedIcon from 'assets/svg/inspection-started.svg';
import InspectionFinishedIcon from 'assets/svg/inspection-finished.svg';
import InspectionCreatedIcon from 'assets/svg/inspection-created.svg';
import InspectioLinkSentIcon from 'assets/svg/link-sent.svg';
import InspectionPhotosReceivedIcon from 'assets/svg/photos-received.svg';
import { StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { IStepperProps } from './Stepper.types';
import {
  StepConnectorWrapper,
  StepperLabelWraper,
  getIconClass,
  StepErrorConnectorWrapper,
  StepperContainer,
  StatusEmpty,
} from './Stepper.styles';

const typeIcon = (type: StatusEnum) => {
  const icons = {
    0: <img src={InspectionCreatedIcon} alt="" />,
    1: <img src={InspectioLinkSentIcon} alt="" />,
    2: <img src={InspectionPhotosReceivedIcon} alt="" />,
    3: <img src={InspectionStartedIcon} alt="" />,
    4: <img src={InspectionFinishedIcon} alt="" />,
    5: <img src="" alt="" />,
    6: <img src="" alt="" />,
  };
  return icons[type];
};

const filterStatus = (type: StatusEnum): boolean =>
  type !== StatusEnum.INSPECTION_EXPIRED && type !== StatusEnum.INSPECTION_CANCELED;

const getStepIcon = (props: StepIconProps, icon: React.ReactNode): JSX.Element => (
  <div className={getIconClass(props)}>{icon}</div>
);

export const StepperComponent: React.FC<IStepperProps> = ({
  testID = 'stepper',
  inspectionStatus,
  inspectionCategory,
  error = false,
}) => {
  const statusFiltered = inspectionStatus.filter(({ type }) => filterStatus(type));
  const inspectionCategoryStatusFiltered = inspectionCategory?.status?.filter((inspectionCategoryStatus) =>
    filterStatus(inspectionCategoryStatus.type)
  );

  return (
    <div data-testid={testID}>
      {inspectionCategoryStatusFiltered && inspectionCategoryStatusFiltered?.length > 0 ? (
        <StepperContainer
          alternativeLabel
          connector={error ? <StepErrorConnectorWrapper /> : <StepConnectorWrapper />}
          activeStep={statusFiltered.length}
        >
          {inspectionCategoryStatusFiltered?.map((inspectionCategoryStatus) => (
            <Step data-testid={`step-${inspectionCategoryStatus.type}`} key={inspectionCategoryStatus.id}>
              <StepperLabelWraper
                data-testid={`step-label-${inspectionCategoryStatus.type}`}
                error={error}
                StepIconComponent={(props): JSX.Element => getStepIcon(props, typeIcon(inspectionCategoryStatus.type))}
              >
                {inspectionCategoryStatus.description}
              </StepperLabelWraper>
            </Step>
          ))}
        </StepperContainer>
      ) : (
        <StatusEmpty>Os status deste atendimento estão indisponíveis no momento</StatusEmpty>
      )}
    </div>
  );
};
