import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const TextField = withTheme(styled(TextFieldComponent)`
  && {
    margin-bottom: ${(props): number => props.theme.spacing(2.5)}px;
    margin-top: ${(props): number => props.theme.spacing(1)}px;
  }
`);
