import { MenuItem } from '@mui/material';
import SendDocumentDialog from 'components/Dialog/SendDocumentDialog/SendDocumentDialog';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import React, { useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import CubeService from 'services/CubeService/cubeService';
import DocumentService from 'services/DocumentService/documentService';

interface IGenerateResaleXMLMenuItemProps {
  inspection: IInspection;
}

const GenerateResaleXMLMenuItem = ({ inspection }: IGenerateResaleXMLMenuItemProps) => {
  const [isResaleDialogOpen, setIsIsResaleDialogOpen] = useState(false);
  const [isResaleDialogLoading, setIsResaleDialogLoading] = useState<boolean>(false);
  const { isConsumerActive } = useConsumer();
  const { invalidateInspectionParallel } = useMutateInspection();

  const showByPermissionAndCurrentStatus =
    isConsumerActive(ConsumersEnum.RESALE_ARVAL, inspection?.companyId || '') &&
    inspection.currentStatus?.type === StatusEnum.INSPECTION_FINISHED;

  const getFileNameWithoutExtension = (fileName: string | undefined) => {
    return fileName?.split('.')[0] || '';
  };

  const onResaleSubmit = async (onImageRemoveAll: () => void, documents: ImageListType) => {
    try {
      setIsResaleDialogLoading(true);
      if (documents[0].dataURL) {
        const base64String = documents[0].dataURL.split(';base64,')[1];
        const buffer = Buffer.from(base64String, 'base64');
        const fileName = getFileNameWithoutExtension(documents[0]?.file?.name);

        const s3Document = await CubeService.uploadXmlDocumentToS3({
          buffer,
          id: inspection.id,
          description: fileName,
          name: fileName,
        });

        if (s3Document.path) {
          const url = await DocumentService.sendResaleDocument(inspection.id, s3Document.path);

          if (url) {
            DocumentService.downloadXmlDocument(url, fileName);
          }
        }
      }
      toast.success('Arquivo baixado com sucesso!');
      onImageRemoveAll();
      setIsResaleDialogLoading(false);
      setIsIsResaleDialogOpen(false);
      invalidateInspectionParallel(inspection.id);
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar o arquivo.');
      setIsResaleDialogLoading(false);
    }
  };

  return (
    <>
      {showByPermissionAndCurrentStatus && (
        <MenuItem data-testid="generate-xml" onClick={() => setIsIsResaleDialogOpen(true)}>
          Gerar XML de revenda
        </MenuItem>
      )}

      <SendDocumentDialog
        title="Enviar arquivo de revenda"
        subtitle="Selecione um arquivo para atualizar"
        textDragAndDrop="Clique ou arraste o arquivo aqui"
        errorMessage="* Tipo de arquivo não suportado, use apenas XML"
        btnSubmitText="ENVIAR"
        dialogVisibility={isResaleDialogOpen}
        extensions={['xml']}
        replaceTooltipIcon="Substituir arquivo"
        removeTooltipIcon="Remover arquivo"
        loadingButton={isResaleDialogLoading}
        handleSubmit={onResaleSubmit}
        handleClose={() => setIsIsResaleDialogOpen(false)}
        hasSelect={false}
      />
    </>
  );
};

export default GenerateResaleXMLMenuItem;
