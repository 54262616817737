import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 25px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #00000099;
`;

export const PartList = styled.div`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
`;

export const PartCard = styled.div`
  padding: 12px;
  width: 300px;
  height: 80px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border: 1px solid #dbd9d9;
  }

  -webkit-box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
`;

export const PartTitle = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  color: #000000;

  text-transform: uppercase;

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const EmptyComtainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
