import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

interface IPhotoItem {
  path: string;
}

interface IDragAndDropArea {
  isDragging: boolean;
}

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px 35px;
    border-radius: 4px;
    width: 650px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  height: 24px;
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 16px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 330px;
`;

export const DragAndDropArea = styled.div<IDragAndDropArea>`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ isDragging }) => (isDragging ? '#099857' : '#dbe1ee')};
  padding: 33px 0;

  &:hover {
    border-color: #099857;
    cursor: pointer;
    transition: 0.8s;

    p {
      color: #099857;
      transition: 0.8s;
    }
  }

  p {
    margin: 0;
    color: ${({ isDragging }) => (isDragging ? '#099857' : '#dbe1ee')};
  }
`;

export const ErrorMessage = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 12px;
  padding-top: 15px;
  color: #dc3d3d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhotoList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 20px;
  max-height: 280px;
  min-height: 160px;
  overflow-y: scroll;
`;

export const PhotoItem = styled.div<IPhotoItem>`
  width: 150px;
  height: 150px;
  background-image: ${({ path }) => `url(${path})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const ButtonBase = styled.button`
  border: none;
  cursor: pointer;
`;

export const CloseButton = styled(ButtonBase)`
  padding: 0;
  background: transparent;
  margin-bottom: 2px;
  margin-right: 5px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const UpdateButton = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 5px;
  margin-left: 5px;
  background: #099857;
  color: #fff;
  font-size: 9px;

  img {
    width: 13px;
    height: 13px;
  }
`;

export const ContainerButton = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;
