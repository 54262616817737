import { useInfiniteQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ErrorNotFound from 'errors/ErrorNotFound';
import { NotificationStatusEnum } from 'commom/types/Notification.types';
import NotificationService from 'services/ApiService/NotificationService/notificationService';
import { AxiosResponse } from 'axios';
import { INotificationsResponse } from 'services/ApiService/NotificationService/notificationService.types';

const ITEMS_PER_PAGE = 10;

export const useMutateNotifications = () => {
  const queryClient = useQueryClient();

  const invalidateNotifications = async () => {
    await queryClient.invalidateQueries({ queryKey: ['get-notifications'] });
  };

  return {
    invalidateNotifications,
  };
};

export const useFilterNotifications = (status: NotificationStatusEnum[]) => {
  const { data, error, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
    ['get-notifications', { status }],
    ({ pageParam = 0 }) => NotificationService.getNotifications(status, pageParam, ITEMS_PER_PAGE),
    {
      getNextPageParam: (lastPage) => {
        const currentPage = parseInt(lastPage.headers['x-current-page'], 10);
        const nextPage = parseInt(lastPage.headers['x-has-next-page'], 10);

        return nextPage ? currentPage + 1 : undefined;
      },
      onError: (err) => {
        if (!(err instanceof ErrorNotFound)) {
          toast.error('Aconteceu algo ao buscar as notificações, tente novamente.');
        }
      },
      refetchInterval: false,
      refetchOnWindowFocus: true,
      enabled: !!status.length,
    }
  );

  return {
    isLoading,
    notificationsData: data,
    notificationsPages: data?.pages,
    hasNotifications: hasNotifications(data?.pages),
    notifications: getNotificationsFromPages(data?.pages),
    unreadNotifications: countUnreadNotifications(data?.pages),
    notificationsError: error,
    notificationsNotFound: error && error instanceof ErrorNotFound,
    hasNextPage: hasNextPage || false,
    isFetchingNextPage,
    fetchNextPage,
  };
};

const getNotificationsFromPages = (pages: AxiosResponse<INotificationsResponse>[] | undefined) => {
  return pages && pages.flatMap(({ data: { notifications } }) => notifications);
};

const hasNotifications = (pages: AxiosResponse<INotificationsResponse>[] | undefined): boolean => {
  if (pages) {
    const notifications = getNotificationsFromPages(pages);

    if (notifications && notifications.length) {
      return true;
    }
  }
  return false;
};

const countUnreadNotifications = (pages: AxiosResponse<INotificationsResponse>[] | undefined): number => {
  if (pages) {
    const notifications = getNotificationsFromPages(pages);

    if (notifications && notifications.length) {
      return notifications.filter((notification) => notification.status === NotificationStatusEnum.UNREAD).length;
    }
  }
  return 0;
};
