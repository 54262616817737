import React, { useState } from 'react';
import { Drawer } from 'components/Drawer';
import NumericFormat from 'react-number-format';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { AnchorTypesEnum } from 'components/Drawer/DrawerProps.types';
import { Button, Label, TextField } from 'components';
import { toast } from 'react-toastify';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import * as S from './CreateOutsourcedServiceDrawer.styles';
import { ICreateOutsourcedServiceDrawerProps } from './CreateOutsourcedServiceDrawer.types';

const CreateOutsourcedServiceDrawer = ({ onClose, open }: ICreateOutsourcedServiceDrawerProps) => {
  const {
    budget: { _id: budgetId },
  } = useAppSelector((state) => state.presentBudget);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [price, setPrice] = useState<number>();
  const [name, setName] = useState<string>('');
  const disableButton = !price || !name || loading;

  const handleCreateExternalService = async () => {
    try {
      setLoading(true);
      const budget = await BudgetService.createExternalService({ budgetId, name, price: price || 0 });
      dispatch(setBudget(budget));
      toast.success('Serviço cadastrado com sucesso!');
    } catch (error) {
      toast.error('Erro ao cadastrar serviço!');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Drawer.Root anchor={AnchorTypesEnum.RIGHT} openDrawer={open} onClose={onClose}>
        <Drawer.Header>
          <Drawer.Title>Adicionar Serviço</Drawer.Title>
        </Drawer.Header>
        <S.DrawerContent>
          <div>
            <Label htmlFor="name" testID="label-name">
              Nome
            </Label>
            <TextField
              ariaLabelledby="name"
              id="name"
              name="name"
              role="name"
              type="text"
              value={name}
              variant="outlined"
              placeholder="Insira o nome do serviço"
              testID="input-name"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => setName(target.value)}
            />
          </div>

          <div>
            <Label htmlFor="cost" testID="label-cost">
              Valor
            </Label>
            <NumericFormat
              customInput={TextField}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              value={price}
              prefix="R$ "
              allowNegative={false}
              allowLeadingZeros={false}
              isNumericString
              placeholder="Insira o valor da serviço"
              onValueChange={({ floatValue }) => setPrice(floatValue)}
              {...{ testID: 'input-price', variant: 'outlined', role: 'price', name: 'price', id: 'price' }}
            />
          </div>
        </S.DrawerContent>
        <S.Divider />
        <S.ActionsContainer>
          <Button
            variant="outlined"
            text="cancelar"
            onClick={onClose}
            testID="button-cancel-register"
            disabled={loading}
          />
          <Button
            variant="contained"
            text="Cadastrar"
            disabled={disableButton}
            onClick={handleCreateExternalService}
            testID="button-create"
            loading={loading}
            loadingSize={10}
          />
        </S.ActionsContainer>
      </Drawer.Root>
    </>
  );
};

export default CreateOutsourcedServiceDrawer;
