import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PersonIcon from '@material-ui/icons/Person';
import { InputAdornment } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Label } from 'components';

import { formValidations } from 'utils/form/validations';
import PasswordRecoveryService from 'services/ApiService/PasswordRecoveryService';
import { PasswordRecoveryApplicationEnum } from 'services/ApiService/PasswordRecoveryService/types';
import {
  Container,
  Illustration,
  Form,
  Title,
  Description,
  LinkNavigation,
  TextField,
  Button,
} from './PasswordRecovery.styles';
import { IPasswordRecoveryForm } from './PasswordRecovery.types';

const InputProps = {
  email: {
    startAdornment: (
      <InputAdornment position="start">
        <PersonIcon />
      </InputAdornment>
    ),
  },
};

export const PasswordRecovery = () => {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({ mode: 'onBlur' });
  const [loading, setLoading] = useState(false);
  const location = useLocation().search;

  const getPasswordRecoveryApplication = (): PasswordRecoveryApplicationEnum => {
    const query = new URLSearchParams(location);
    const passwordRecoveryApp = query.get('passwordRecoveryApplication');
    return passwordRecoveryApp === 'CAP' ? 'CAP' : 'KITE';
  };

  const isCapPasswordRecoveryApplication = (): boolean => {
    return getPasswordRecoveryApplication() === 'CAP';
  };

  const generateSearchParams = (email: string): string => {
    const emailParams = `email=${email}`;
    const passwordRecoveryApplicationParams = `passwordRecoveryApplication=${getPasswordRecoveryApplication()}`;

    return `?${emailParams}&${passwordRecoveryApplicationParams}`;
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const onSubmit = async (formRequest: IPasswordRecoveryForm): Promise<void> => {
    try {
      setLoading(true);
      await PasswordRecoveryService.passwordRecovery(formRequest.email, getPasswordRecoveryApplication());
      setLoading(false);
      toast.success('Email de recuperação enviado com sucesso.');
      history.push({
        pathname: '/check-email',
        search: generateSearchParams(formRequest.email),
      });
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao enviar email para recuperação de senha.');
    }
  };

  return (
    <Container data-testid="password-recovery-form">
      <Illustration />
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <Title>Recuperar senha</Title>

        <Description>Não se preocupe, vamos resolver isso rapidamente!</Description>

        <Label htmlFor="email" testID="email">
          Email
        </Label>
        <TextField
          ariaLabelledby="email"
          error={hasError('email')}
          id="email"
          InputProps={InputProps.email}
          inputRef={register(formValidations.email)}
          helperText={errors?.email?.message}
          name="email"
          placeholder="Insira seu email de login"
          state={handleInputState('email')}
          type="text"
          variant="outlined"
        />

        <Button
          fullWidth={true}
          loading={loading}
          loadingColor="#ffffff"
          loadingSize={26}
          size="large"
          text="RECUPERAR SENHA"
          type="submit"
        />

        {!isCapPasswordRecoveryApplication() && (
          <LinkNavigation data-testid="back-login" to="/login">
            Voltar ao login
          </LinkNavigation>
        )}
      </Form>
    </Container>
  );
};
