import React, { useCallback, useEffect, useState } from 'react';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import { Button } from 'components';
import { IWorkshop } from 'services/ApiService/WorkshopService/WorkshopService.types';
import { ICompanyWorkshopServices } from 'services/ApiService/BudgetService/BudgetService.types';
import { useHistory } from 'react-router-dom';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { toast } from 'react-toastify';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import { useAppSelector } from 'hooks/hooks';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { IBudgetLabor, IBudgetLaborCostTypeEnum } from 'commom/types/Budget.types';
import { Container, Header, Title, Footer, Content } from './CreateBudget.styles';
import Workshop from './components/Workshop/Workshop';
import InsuranceValues from './components/InsuranceValues/InsuranceValues';
import LaborValues from 'components/LaborValues/LaborValues';

const CreateBudget = () => {
  const [selectedWorkshop, setSelectedWorkshop] = useState<IWorkshop | null>(null);
  const [companyWorkshopServices, setCompanyWorkshopServices] = useState<ICompanyWorkshopServices | null>(null);
  const [deductiblePrice, setDeductiblePRice] = useState<number | null>(null);
  const [isLoading, setLoading] = useState(false);
  const inspectionId = useAppSelector((state) => state.presentInspection.inspection.id);
  const companyId = useAppSelector((state) => state.presentInspection.inspection.companyId);
  const { invalidateInspectionSequential } = useMutateInspection();
  const history = useHistory();
  useHideZendesk();

  const isDisabledStartBudget = !selectedWorkshop || !companyWorkshopServices || !deductiblePrice;

  const transformServiceCostsToBudgetLabor = (): IBudgetLabor => {
    const costs =
      companyWorkshopServices?.costs
        .filter((cost) => !!cost.price)
        .map((cost) => ({
          price: cost.price || 0,
          type: cost.code as IBudgetLaborCostTypeEnum,
          description: cost.name,
        })) || [];

    const discount = companyWorkshopServices?.costs.find((cost) => cost.code === 'DISCOUNT')?.percent || 0;
    const increase = companyWorkshopServices?.costs.find((cost) => cost.code === 'MARKUP')?.percent || 0;
    const createdAt = companyWorkshopServices?.createdAt || '';
    const updatedAt = companyWorkshopServices?.updatedAt || '';

    return {
      cost: costs,
      createdAt,
      updatedAt,
      discount,
      increase,
    };
  };

  const getCompanyWorkshopServices = useCallback(async () => {
    try {
      setLoading(true);
      if (companyId) {
        const budgetLaborResponse = await BudgetService.getBudgetLabor(companyId);

        setCompanyWorkshopServices(budgetLaborResponse.companyWorkshopServices);
      }
    } catch {
      toast.error('Erro ao buscar valores de mão de obra');
    } finally {
      setLoading(false);
    }
  }, [companyId, setCompanyWorkshopServices]);

  useEffect(() => {
    getCompanyWorkshopServices();
  }, [getCompanyWorkshopServices]);

  const redirectToBudget = () => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}/orcamentos${window.location.search}`);
  };

  const redirectToInspectionDetails = () => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}/${window.location.search}`);
  };

  const createBudget = async () => {
    try {
      if (!!selectedWorkshop && !!companyWorkshopServices && !!deductiblePrice) {
        await BudgetService.createBudget({
          inspectionId,
          isPrivate: false,
          workshop: selectedWorkshop,
          labor: transformServiceCostsToBudgetLabor(),
          deductible: deductiblePrice,
        });
      }
    } catch {
      throw new Error('Não foi possível iniciar o orçamento, tente novamente.');
    }
  };

  const startInspection = async (): Promise<void> => {
    try {
      await InspectionStatusService.started(inspectionId);
    } catch {
      throw new Error('Aconteceu algo inesperado ao iniciar o atendimento, tente novamente.');
    }
  };

  const handleStartBudget = async () => {
    try {
      setLoading(true);
      await createBudget();
      await startInspection();
      await invalidateInspectionSequential(inspectionId);
      setLoading(false);
      redirectToBudget();
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao iniciar orçamento');
    }
  };

  return (
    <Container>
      <Header>
        <Title>Iniciar orçamento</Title>
      </Header>

      <Content>
        <Workshop selectedWorkshop={selectedWorkshop} setSelectedWorkshop={setSelectedWorkshop} />
        <InsuranceValues deductiblePrice={deductiblePrice} setDeductiblePRice={setDeductiblePRice} />
        <LaborValues labor={transformServiceCostsToBudgetLabor()} />
      </Content>

      <Footer>
        <Button testID="cancel-button" text="Cancelar" variant="outlined" onClick={redirectToInspectionDetails} />
        <Button
          testID="start-budget-button"
          text="Iniciar orçamento"
          variant="contained"
          loading={isLoading}
          disabled={isDisabledStartBudget}
          onClick={handleStartBudget}
        />
      </Footer>
    </Container>
  );
};

export default CreateBudget;
