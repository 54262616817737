/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccount } from 'contexts/auth/types';
import AccountService from 'services/ApiService/AccountService';
import { IAccountGroup } from 'services/ApiService/AccountService/types';
import AuthService from 'services/ApiService/AuthService/authService';

interface IAccountGroupState {
  info: IAccount;
  groups: IAccountGroup[];
}

const ACCOUNT_GROUPS = '@ACCOUNT_GROUPS';
const ACCOUNT_INFO = '@ACCOUNT_INFO';

const initialState: IAccountGroupState = {
  info: AuthService.getAccountInLocalStorage(),
  groups: AccountService.getAccountGroupsInLocalStorage().groups,
};

export const accountGroupSlice = createSlice({
  name: 'accountGroup',
  initialState,
  reducers: {
    updateAccountGroupInfo(state, action: PayloadAction<IAccount>) {
      const { payload } = action;

      state.info = payload;

      AccountService.updateLocalStorage(ACCOUNT_INFO, payload);
    },
    updateAccountGroups(state, action: PayloadAction<IAccountGroup[]>) {
      const { payload } = action;

      state.groups = action.payload;

      AccountService.updateLocalStorage(ACCOUNT_GROUPS, { groups: payload });
    },
  },
});

export const { updateAccountGroupInfo, updateAccountGroups } = accountGroupSlice.actions;
export default accountGroupSlice.reducer;
