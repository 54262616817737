import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Label as LabelComponent } from 'components';
import { Select as SelectComponent } from 'components/Select/Select';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px 35px;
    border-radius: 4px;
    width: 650px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  height: 24px;
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 16px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 330px;
`;

export const ContainerButton = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
`;

export const SelectItem = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
`;

export const Label = styled(LabelComponent)`
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
`;

export const Select = styled(SelectComponent)`
  height: 40px;

  div {
    padding: 10.5px 14px;
  }
`;
