import React, { useCallback, useEffect, useState } from 'react';
import InspectionTableService from 'services/InspectionTableService/InspectionTableService';
import { Table } from 'components';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import { GeneralMetricsDetailsFilterEnum } from 'services/ApiService/MetricsService/metricsService.types';
import { toast } from 'react-toastify';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import Loading from 'components/Loading';
import { useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';
import { MetricsTableRow } from '../../TableRow/TableRow';
import { MetricsFilter } from '../../MetricsFilter/MetricsFilter';
import { IMetricsTableRow } from '../../TableRow/TableRow.types';
import * as Styled from './Details.styles';

interface IUrlParams {
  name: string;
  regulatorId: string;
}

export const GeneralMetricsDetails = () => {
  useHideZendesk();
  const { search } = useLocation();
  const [urlParams, setUrlParams] = useState<IUrlParams>({
    name: '',
    regulatorId: '',
  });
  const [page, setPage] = useState<number>(0);
  const [tableData, setTableData] = useState<IMetricsTableRow[]>([]);
  const [rowsPerPage] = useState<number>(7);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [averageTimeLong] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<GeneralMetricsDetailsFilterEnum>(GeneralMetricsDetailsFilterEnum.RISK_OF_DELAY);

  const dataTableIsEmpty = tableData.length === 0;
  const showCancelledReason = filter === GeneralMetricsDetailsFilterEnum.CANCELLED;
  const setRowsPerPage = dataTableIsEmpty ? 0 : rowsPerPage;
  const setCount = dataTableIsEmpty ? 0 : totalCount;
  const setTotalPagesCount = dataTableIsEmpty ? 0 : totalPages;

  const {
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    startDateSelected,
    productTypesSelected,
    regulatorSelected,
  } = useMetricsFilter();

  const getParamsFromUrl = useCallback((): void => {
    const query = new URLSearchParams(search);
    if (urlParams.name === '' && urlParams.regulatorId === '') {
      setUrlParams({
        name: query.get('name') || '',
        regulatorId: query.get('regulatorId') || '',
      });
    }
  }, [search, urlParams.name, urlParams.regulatorId]);

  const hasRegulator = useCallback(() => {
    return urlParams.name.length > 0 && urlParams.regulatorId.length > 0;
  }, [urlParams.name.length, urlParams.regulatorId.length]);

  const hasFilterData = useCallback(() => {
    return companiesSelected?.length > 0 && startDateSelected !== '' && endDateSelected !== '';
  }, [companiesSelected, startDateSelected, endDateSelected]);

  const getRegulatorId = useCallback(() => {
    return hasRegulator() ? urlParams.regulatorId : regulatorSelected?.value;
  }, [hasRegulator, regulatorSelected, urlParams.regulatorId]);

  const getDetailsTableData = useCallback(async () => {
    try {
      if (hasFilterData()) {
        setLoading(true);

        const filterData = await MetricsService.getGeneralMetricsDetails({
          page,
          size: rowsPerPage,
          filter,
          companyIds: companiesSelected || [],
          createdAtGreaterThan: startDateSelected || '',
          createdAtLessThan: endDateSelected || '',
          inspectionCategoryIds: categoriesSelected || [],
          inspectionProductTypeIds: productTypesSelected || [],
          regulatorIds: [getRegulatorId() || ''],
        });
        const response = InspectionTableService.getGeneralMetricsTableRows(filterData.inspections);
        setTableData(response);
        setTotalPages(filterData.totalPages);
        setTotalCount(filterData.totalCount);
      }
    } catch (error) {
      toast.error('Falha ao carregar os detalhes das métricas gerais');
    } finally {
      setLoading(false);
    }
  }, [
    hasFilterData,
    page,
    rowsPerPage,
    filter,
    companiesSelected,
    startDateSelected,
    endDateSelected,
    categoriesSelected,
    productTypesSelected,
    getRegulatorId,
  ]);

  const setFilterData = (filterSelected: GeneralMetricsDetailsFilterEnum) => {
    setFilter(filterSelected);
    setPage(0);
  };

  useEffect(() => {
    getParamsFromUrl();
  }, [getParamsFromUrl]);

  useEffect(() => {
    getDetailsTableData();
  }, [getDetailsTableData]);

  return (
    <Styled.Container>
      {hasRegulator() ? (
        <Styled.Header>
          <Styled.RegulatorWrapper>
            <Styled.RegulatorName>{urlParams.name}</Styled.RegulatorName>
            {averageTimeLong !== '' && (
              <>
                <Divider orientation="vertical" variant="middle" flexItem />

                <Styled.RegulatorTimeWrapper>
                  <Styled.RegulatorTimeValue>{averageTimeLong}</Styled.RegulatorTimeValue>
                  <Styled.RegulatorTimeLabel>Tempo médio</Styled.RegulatorTimeLabel>
                </Styled.RegulatorTimeWrapper>
              </>
            )}
          </Styled.RegulatorWrapper>
          <Styled.BackButton to="/metrics-regulators-general-details">
            <Styled.ArrowBackIcon />
            Voltar
          </Styled.BackButton>
        </Styled.Header>
      ) : (
        <Styled.Header>
          <Styled.Title>
            <Styled.Title>Métricas Gerais</Styled.Title>
            <Styled.ArrowForwardIcon />
            Detalhamento
          </Styled.Title>
          <Styled.BackButton to="/metrics">
            <Styled.ArrowBackIcon />
            Voltar
          </Styled.BackButton>
        </Styled.Header>
      )}
      <Styled.Content>
        <MetricsFilter hideRegulator={hasRegulator()} style={{ padding: 0 }} />

        <Styled.FilterButtonsWrapper>
          <Styled.FilterButton
            text="Risco de atraso"
            variant="outlined"
            textColor="#F67228"
            background="rgba(246, 114, 40, 0.10)"
            onClick={() => setFilterData(GeneralMetricsDetailsFilterEnum.RISK_OF_DELAY)}
            isSelected={filter === GeneralMetricsDetailsFilterEnum.RISK_OF_DELAY}
          />
          <Styled.FilterButton
            text="Não iniciados"
            variant="outlined"
            textColor="#9F6CD5"
            background="rgba(159, 108, 213, 0.10)"
            onClick={() => setFilterData(GeneralMetricsDetailsFilterEnum.NOT_STARTED)}
            isSelected={filter === GeneralMetricsDetailsFilterEnum.NOT_STARTED}
          />
          <Styled.FilterButton
            text="Em andamento"
            variant="outlined"
            textColor="#ECAF35"
            background="rgba(236, 175, 53, 0.10)"
            onClick={() => setFilterData(GeneralMetricsDetailsFilterEnum.IN_PROGRESS)}
            isSelected={filter === GeneralMetricsDetailsFilterEnum.IN_PROGRESS}
          />
          <Styled.FilterButton
            text="Finalizados"
            variant="outlined"
            textColor="#62B87F"
            background="rgba(98, 184, 127, 0.10)"
            onClick={() => setFilterData(GeneralMetricsDetailsFilterEnum.FINISHED)}
            isSelected={filter === GeneralMetricsDetailsFilterEnum.FINISHED}
          />
          <Styled.FilterButton
            text="Cancelados"
            variant="outlined"
            textColor="#EE5D50"
            background="rgba(238, 93, 80, 0.10)"
            onClick={() => setFilterData(GeneralMetricsDetailsFilterEnum.CANCELLED)}
            isSelected={filter === GeneralMetricsDetailsFilterEnum.CANCELLED}
          />
        </Styled.FilterButtonsWrapper>

        {loading ? (
          <Styled.LoadingContainer>
            <Loading width="100%" />
          </Styled.LoadingContainer>
        ) : (
          <Table
            headers={InspectionTableService.getGeneralMetricsTableHeaders(filter)}
            page={page}
            count={setCount}
            setPage={setPage}
            rowsPerPage={setRowsPerPage}
            emptyMessage="Nenhum resultado encontrado"
            totalPages={setTotalPagesCount}
          >
            {tableData.map((row, index) => (
              <MetricsTableRow key={index} row={row} showTime={true} showCancelledReason={showCancelledReason} />
            ))}
          </Table>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
