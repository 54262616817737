import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 5px;
  height: 35px;
  background-color: #fff;
`;

export const CaptionContainer = styled.div`
  display: flex;
  justify-content: Space-between;
  align-items: center;
  padding: 8px 28px;
`;

export const CaptionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const CaptionSymbol = styled.span`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  color: #000;
`;

export const CaptionInfo = styled.span`
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  color: #000;
`;
