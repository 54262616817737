import React from 'react';
import {
  NoContentListWrapper,
  NoContentListImage,
  NoContentListTitle,
  NoContentListSubTitle,
} from './NoContentList.styles';
import { INoContentList } from './NoContentList.types';

export const NoContentList: React.FC<INoContentList> = ({
  illustration,
  title,
  subTitle,
  testID = 'no-content-list',
}) => {
  return (
    <NoContentListWrapper data-testid={testID}>
      <NoContentListImage data-testid="no-content-list-image" src={illustration} />

      <NoContentListTitle>{title}</NoContentListTitle>

      <NoContentListSubTitle>{subTitle}</NoContentListSubTitle>
    </NoContentListWrapper>
  );
};
