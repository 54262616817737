import ApiService from 'services/ApiService';
import { IClientsResponse, IClientJoinCompanies, IClientService, IModule, IModulesByClientId } from './types';

const MODULES = '@MODULES';

const ClientService: IClientService = {
  getAllClients: async () => {
    const { data } = await ApiService.get<IClientsResponse>(`/client`);

    return data;
  },

  getClientById: async (id: string) => {
    const {
      data: { client },
    } = await ApiService.get<IClientJoinCompanies>(`/client/${id}`);

    return client;
  },

  getModulesByClientId: async (id: string) => {
    const {
      data: { client },
    } = await ApiService.get<IModulesByClientId>(`/client/${id}/modules-and-consumers`);

    return client;
  },

  setModulesInLocalStorage: (modules: IModule[]): void => {
    localStorage.setItem(MODULES, JSON.stringify(modules));
  },

  getModulesInLocalStorage: (): IModule[] => {
    const modules = localStorage.getItem(MODULES);

    if (!modules) return [];

    return JSON.parse(modules);
  },

  deleteModulesFromLocalStorage: (): void => {
    localStorage.removeItem(MODULES);
  },
};

export default ClientService;
