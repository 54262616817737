import styled from 'styled-components';
import { TextField as TextFieldComponent } from 'components';
import { Divider as DividerMui } from '@mui/material';

export const TextField = styled(TextFieldComponent)`
  border: 1px solid black !important;
  border-radius: 4px 0px 0px 4px !important;
  height: 40px !important;

  input {
    font-size: 14px !important;
  }

  .MuiOutlinedInput-input {
    padding-right: 5px !important;
  }

  .MuiFormControl-root {
    border: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ::hover {
    border: none !important;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 0 !important;
  }
`;

export const ContentLabel = styled.div`
  font-size: 12px;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #000;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentArrow = styled.div`
  display: flex;
  flex-direction: column;
  border-color: #000;
  border-style: solid;
  height: 40px;
  border-radius: 0 4px 4px 0;
  border-width: 1px 1px 1px 0px;

  .arrow-up {
    color: #000;
  }

  .arrow-down {
    color: #000;
  }

  .MuiIconButton-root {
    padding: 0 !important;
  }

  .MuiSvgIcon-root {
    width: 20px !important;
    height: 19px !important;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelInput = styled.span`
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  text-align: end;
  margin-right: 1px;
  margin-top: 6px;
`;

export const IconContent = styled.div`
  align-items: center;
  padding: 10px;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const Divider = styled(DividerMui)`
  border-color: #000000 !important;
`;

export const Label = styled.span`
  color: #6a758b;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
`;
