import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import Edit from 'assets/svg/edit.svg';
import EditColored from 'assets/svg/edit-colored.svg';

export const IconButtonCustom = styled(IconButton)`
  padding: 0 !important;

  :hover {
    background-color: transparent !important;
  }
`;

export const EditIcon = styled.div`
  width: 29px;
  height: 28px;
  transition: 0.3s;
  background-image: url(${Edit});

  &:hover {
    background-image: url(${EditColored});
  }
`;
