import { List, ListItem as ListItemStyled } from '@mui/material';
import styled from 'styled-components';

export const TabPhotosContainer = styled(List)`
  display: flex;
  overflow-x: hidden;
  user-select: none;
  -webkit-overflow-scrolling: touch;

  .MuiListItem-root {
    padding: 0 4px;
    width: auto;
  }
`;

export const ListItem = styled(ListItemStyled)``;

export const TouchableImage = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;
