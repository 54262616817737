import React, { useEffect, useRef, useState } from 'react';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Button, Table } from 'components';
import {
  IRegulatorsGeneralDetails,
  IRegulatorsGeneralDetailsCSV,
  IRegulatorsGeneralDetailsMetricsFilter,
} from 'services/ApiService/MetricsService/metricsService.types';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import { toast } from 'react-toastify';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { CSVLink } from 'react-csv';
import DateService from 'services/DateService/dateService';
import Loading from 'components/Loading';
import { MetricsFilter } from '../../MetricsFilter/MetricsFilter';
import * as S from './RegulatorsGeneralDetails.styles';
import { RegulatorsGeneralDetailsTableRow } from './RegulatorsGeneralDetailsTableRow';

const getRegulatorsGeneralDetailsTableHeaders = () => {
  return [
    { key: 0, name: 'Detalhes' },
    { key: 1, name: 'Perito' },
    { key: 2, name: 'Em andamento' },
    { key: 3, name: 'Risco de atraso' },
    { key: 4, name: 'Não iniciados' },
    { key: 5, name: 'Cancelados' },
    { key: 6, name: 'Finalizados' },
    { key: 7, name: 'Finalizados (%)' },
    { key: 8, name: 'Detalhes' },
  ];
};

const getRegulatorsGeneralDetailsCSVHeaders = () => {
  return [
    { key: 'regulatorName', label: 'Perito' },
    { key: 'inProgressInspectionsCount', label: 'Em andamento' },
    { key: 'atRiskOfDelay', label: 'Risco de atraso' },
    { key: 'notStartedInspectionsCount', label: 'Não iniciados' },
    { key: 'cancelledInspectionsCount', label: 'Cancelados' },
    { key: 'finishedInspectionsCount', label: 'Finalizados' },
    { key: 'finishedInspectionPercentage', label: 'Finalizados (%)' },
  ];
};

const formatRegulatorsGeneralDetailsCSVRows = (
  rows: IRegulatorsGeneralDetailsCSV[]
): IRegulatorsGeneralDetailsCSV[] => {
  return rows.map((row) => ({
    ...row,
    finishedInspectionPercentage: Number(row.finishedInspectionPercentage.toFixed(2)),
  }));
};

export function RegulatorsGeneralDetails() {
  useHideZendesk();

  const {
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    startDateSelected,
    productTypesSelected,
    regulatorSelected,
  } = useMetricsFilter();
  const { activeProfile } = useActiveProfile();

  const [dataTable, setDataTable] = useState<IRegulatorsGeneralDetails[]>([]);
  const [dataCSV, setDataCSV] = useState<IRegulatorsGeneralDetailsCSV[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [CSVloading, setCSVLoading] = useState(false);
  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const dataTableIsEmpty = dataTable.length === 0;

  const formatCSVFileName = () => {
    const date = DateService.formatDate(new Date().toISOString());
    return `tabela_peritos_${date}`;
  };

  const handleDownloadRegulatorGeneralDetailsMetricsCSV = async () => {
    try {
      setCSVLoading(true);

      const response = await MetricsService.getRegulatorsGeneralDetailsMetricsCSV({
        clientId: activeProfile.clientId,
        categoriesSelected,
        companiesSelected,
        productTypesSelected,
        regulatorSelected,
        startDateSelected,
        endDateSelected,
      });

      setDataCSV(formatRegulatorsGeneralDetailsCSVRows(response.regulatorsMetrics));
    } catch {
      toast.error('Erro ao realizar o download do CSV');
      setDataCSV([]);
    } finally {
      setCSVLoading(false);
    }
  };

  const getRegulatorsGeneralDetails = async (filter: IRegulatorsGeneralDetailsMetricsFilter) => {
    try {
      setLoading(true);
      const response = await MetricsService.getRegulatorsGeneralDetailsMetrics(filter);

      setDataTable(response.regulatorsMetrics);
      setTotalPages(response.totalPages);
      setTotalCount(response.totalCount);
    } catch (error) {
      toast.error('Erro ao buscar os dados dos peritos');
      setDataTable([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeProfile.clientId && companiesSelected.length > 0) {
      getRegulatorsGeneralDetails({
        clientId: activeProfile.clientId,
        page,
        categoriesSelected,
        companiesSelected,
        productTypesSelected,
        regulatorSelected,
        startDateSelected,
        endDateSelected,
      });
    }
  }, [
    activeProfile.clientId,
    page,
    categoriesSelected,
    companiesSelected,
    productTypesSelected,
    regulatorSelected,
    startDateSelected,
    endDateSelected,
  ]);

  useEffect(() => {
    if (dataCSV && csvInstance && csvInstance.current) {
      csvInstance?.current?.link.click();

      setDataCSV([]);
    }
  }, [dataCSV]);

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <span>Peritos</span>
          <ArrowForwardIosRoundedIcon fontSize="small" />
          <span>Detalhamento</span>
        </S.TitleContainer>
        <S.Link to="/metrics">
          <S.ArrowBackIcon />
          Voltar
        </S.Link>
      </S.Header>
      <S.Content>
        <MetricsFilter />
        <S.ButtonCSVContainer>
          <Button
            variant="outlined"
            type="button"
            text="BAIXAR CSV"
            disabled={dataTableIsEmpty}
            startIcon={<GetAppIcon />}
            onClick={() => handleDownloadRegulatorGeneralDetailsMetricsCSV()}
            loading={CSVloading}
            loadingColor="black"
          />
        </S.ButtonCSVContainer>
        {dataCSV && dataCSV.length > 0 ? (
          <S.ButtonCSV
            data={dataCSV}
            filename={formatCSVFileName()}
            headers={getRegulatorsGeneralDetailsCSVHeaders()}
            target="_blank"
            enclosingCharacter=""
            ref={csvInstance}
          />
        ) : undefined}
        {loading ? (
          <S.LoadingContainer>
            <Loading width="100%" />
          </S.LoadingContainer>
        ) : (
          <Table
            headers={getRegulatorsGeneralDetailsTableHeaders()}
            page={dataTableIsEmpty ? 0 : page}
            count={dataTableIsEmpty ? 0 : totalCount}
            setPage={setPage}
            rowsPerPage={dataTableIsEmpty ? 0 : 7}
            emptyMessage="Nenhum resultado encontrado"
            totalPages={dataTableIsEmpty ? 0 : totalPages}
            marginRight="-115px"
          >
            {dataTable.map((row, index) => (
              <RegulatorsGeneralDetailsTableRow key={index} row={row} />
            ))}
          </Table>
        )}
      </S.Content>
    </S.Container>
  );
}
