import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 60px;
  background: #f4f4f4;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @media only screen and (max-width: 599px) {
    padding: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  width: 100%;
  height: 10%;
  background: #ffffff;
  border-top-left-radius: 20px;
  border-bottom: solid 1px #e8e8e8;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  color: #000;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 20px 30px;
`;
