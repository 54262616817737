import { IStatus, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';

export interface IGetStatus {
  status: IStatus[];
}

export interface IInspectionStatusService {
  getStatus: () => Promise<IStatus[]>;
  linkSent: (inspectionId: string) => Promise<void>;
  started: (inspectionId: string) => Promise<void>;
  finished: (inspectionId: string) => Promise<void>;
  cancel: (inspectionId: string) => Promise<void>;
  photosReceived: (inspectionId: string) => Promise<void>;
  getCurrentStatus: (status: IStatus[]) => IStatus;
  isCanceled: (status: IStatus[]) => boolean;
  isExpired: (status: IStatus[]) => boolean;
  isCreatedOrSent: (currentStatus: IStatus) => boolean;
  getStatusByType: (status: IStatus[], statusType: number) => IStatus;
  isInspectionCreated: (currentStatus: IStatus) => boolean;
  isPhotosReceived: (currentStatus: IStatus) => boolean;
  hasPhotosReceived: (currentStatus: IStatus) => boolean;
  isInspectionStarted: (currentStatus: IStatus) => boolean;
  isInspectionFinished: (currentStatus: IStatus) => boolean;
  isCurrentStatus: (currentStatus: IStatus, statusType: number) => boolean;
  isNotExpiredOrCanceled: (type: StatusEnum) => boolean;
}

export enum StatusDescriptionEnum {
  DEFAULT = 'Atendimento criado',
  LINK_SENT = 'Link enviado',
  PHOTO_RECEIVED = 'Fotos recebidas',
  INSPECTION_STARTED = 'Realizando atendimento',
  INSPECTION_FINISHED = 'Atendimento finalizado',
  INSPECTION_CANCELED = 'Atendimento cancelado',
  INSPECTION_EXPIRED = 'Atendimento expirado',
}
