import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { AssociateUserOperationsDialog } from 'components/Dialog/AssociateUserOperationsDialog/AssociateUserOperationsDialog';
import { CreateAccountDialog } from 'components/Dialog/CreateAccountDialog';
import AccountService from 'services/ApiService/AccountService';
import { Select } from 'components/Select/Select';
import { Button } from 'components/Button';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { IOptions } from 'components/Select/Select.types';
import { ICompany } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { useAccountGroupsByClientId } from 'hooks/AccountGroups/AccountGroupsHooks';
import {
  useUserManagementDashboardDispatch,
  useUserManagementDashboardState,
} from 'contexts/usersManagementDashboard/UserManagementDashboard';
import { useDebounce } from 'hooks/Debounce/DebounceHook';
import {
  ButtonWrapper,
  FormWrapper,
  UsersFilterTitle,
  UsersFilterWrapper,
  UsersSearchContainer,
  UsersSelectFilters,
  TextField,
  FilterButton,
  DialogButtonWrapper,
  DialogButton,
} from './UsersFilter.styles';

const UsersFilter = () => {
  const { getAllCompaniesForActiveProfile, getAllCompaniesIdsForActiveProfile } = useActiveProfile();
  const [companiesList, setCompaniesList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [authorityList, setAuthorityList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [companiesSelected, setCompaniesSelected] = useState<string[]>(getAllCompaniesIdsForActiveProfile());
  const [authoritySelected, setAuthoritySelected] = useState<string[]>(AccountService.getPermissionsValues());
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [createAccountDialogIsOpen, setCreateAccountDialogVisibility] = useState(false);

  const { pagination } = useUserManagementDashboardState();
  const { setUsers, setPagination, setIsLoading } = useUserManagementDashboardDispatch();
  const [companiesId, setCompaniesId] = useState<string[]>(companiesSelected);
  const [authoritiesValue, setAuthoritiesValue] = useState<string[]>(authoritySelected);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const debounceSearch = useDebounce(inputSearch);
  const { accountGroupsData, totalCount, totalPages, isLoadingAccountGroups } = useAccountGroupsByClientId(
    AccountService.getActiveProfileInLocalStorage().clientId,
    pagination.currentPage,
    pagination.itemsPerPage,
    companiesId,
    authoritiesValue,
    debounceSearch
  );

  const InputProps = {
    search: {
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    },
  };

  const inputProps = {
    search: {
      'data-testid': 'user-search',
    },
  };

  const handleDialogClose = (): void => {
    setDialogIsOpen(false);
  };

  const handleDialogOpen = (): void => {
    setDialogIsOpen(true);
  };

  const getAccountGroups = async (): Promise<void> => {
    try {
      setCompaniesId(companiesSelected);
      setAuthoritiesValue(authoritySelected);
      setShowFilters(false);
    } catch (error) {
      toast.error('Aconteceu algo ao pesquisar, tente novamente.');
      setUsers([]);
    }
  };

  const getCompanies = useCallback(async (): Promise<IOptions[]> => {
    try {
      const companies = getAllCompaniesForActiveProfile();

      return companyToOptions(companies);
    } catch (error) {
      toast.error('Problema ao carregar as operações, tente novamente.');

      return [];
    }
  }, [getAllCompaniesForActiveProfile]);

  const companyToOptions = (companies: ICompany[]): IOptions[] => {
    return companies.map(({ id: value, name }) => ({ value, name }));
  };

  const editFilters = (): void => {
    setShowFilters(true);
  };

  const disableFilterButton = (): boolean => {
    return companiesSelected.length === 0 || authoritySelected.length === 0;
  };

  const clearOptionsSelected = (): void => {
    setCompaniesSelected([]);
    setAuthoritySelected([]);
    setShowFilters(true);
  };

  const handleCreateAccountOpen = (): void => {
    setCreateAccountDialogVisibility(true);
  };

  const handleCreateAccountClose = (): void => {
    setCreateAccountDialogVisibility(false);
  };

  useEffect(() => {
    if (accountGroupsData) {
      setUsers(AccountService.getUsersFromAccountGroups(accountGroupsData));
    }
  }, [accountGroupsData, setUsers]);

  useEffect(() => {
    if (totalCount && totalPages) {
      setPagination({ ...pagination, items: totalCount, pages: totalPages });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, totalPages, setPagination]);

  useEffect(() => {
    setCompaniesId(getAllCompaniesIdsForActiveProfile());
    setCompaniesSelected(getAllCompaniesIdsForActiveProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllCompaniesIdsForActiveProfile]);

  useEffect(() => {
    setIsLoading(isLoadingAccountGroups);
  }, [isLoadingAccountGroups, setIsLoading]);

  useEffect(() => {
    (async (): Promise<void> => setCompaniesList(await getCompanies()))();
    (async (): Promise<void> => setAuthorityList(await AccountService.getPermissionsOptions()))();
  }, [getCompanies]);

  return (
    <>
      <UsersSearchContainer>
        <FormWrapper>
          <TextField
            ariaLabelledby="search"
            InputProps={InputProps.search}
            inputProps={inputProps.search}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => setInputSearch(target.value)}
            placeholder="Buscar Usuário"
            type="text"
            value={inputSearch}
            variant="outlined"
          />
        </FormWrapper>

        <DialogButtonWrapper>
          <DialogButton text="ASSOCIAR USUÁRIO" onClick={handleDialogOpen} />
          <DialogButton
            text="CADASTRAR USUÁRIO"
            variant="outlined"
            onClick={handleCreateAccountOpen}
            testID="add-user-button"
          />
          <AssociateUserOperationsDialog dialogVisibility={dialogIsOpen} handleClose={handleDialogClose} />
          <CreateAccountDialog dialogVisibility={createAccountDialogIsOpen} handleClose={handleCreateAccountClose} />
        </DialogButtonWrapper>
      </UsersSearchContainer>

      <UsersFilterWrapper showFilters={showFilters}>
        <UsersFilterTitle>Filtros</UsersFilterTitle>

        <UsersSelectFilters showFilters={showFilters}>
          <span>
            <Select
              className="animate fadeIn"
              testID="company-select"
              options={companiesList}
              selectedOptions={companiesSelected}
              labelId="companies"
              label="Operação"
              placeholder="Selecione uma operação"
              onChange={({ target: { value } }): void => setCompaniesSelected(value as string[])}
              multiple
              buttonShape={!showFilters}
            />
          </span>
          <span>
            <Select
              className="animate fadeIn"
              testID="authority-select"
              options={authorityList}
              selectedOptions={authoritySelected}
              labelId="authorities"
              label="Permissão"
              placeholder="Selecione uma permissão"
              onChange={({ target: { value } }): void => setAuthoritySelected(value as string[])}
              multiple
              buttonShape={!showFilters}
            />
          </span>
          {!showFilters && (
            <FilterButton
              variant="outlined"
              type="button"
              text="Adicionar Filtro"
              onClick={(): void => editFilters()}
              testID="filter-add"
              buttonShape={true}
              size="large"
            />
          )}
        </UsersSelectFilters>
        {showFilters && (
          <ButtonWrapper>
            <Button
              text="FILTRAR"
              type="submit"
              onClick={(): Promise<void> => getAccountGroups()}
              disabled={disableFilterButton()}
              loading={false}
              loadingColor="#fff"
              loadingSize={20}
              size="large"
            />

            <Button
              variant="outlined"
              type="button"
              text="LIMPAR"
              onClick={(): void => clearOptionsSelected()}
              size="large"
            />
          </ButtonWrapper>
        )}
      </UsersFilterWrapper>
    </>
  );
};

export default UsersFilter;
