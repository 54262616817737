import React from 'react';
import EmptyNotificationsSvg from 'assets/svg/empty-notifications.svg';
import * as S from './EmptyNotifications.styles';

interface IEmptyNotificationsProps {
  title: string;
  description: string;
}

export const EmptyNotifications = ({ title, description }: IEmptyNotificationsProps) => {
  return (
    <S.Container>
      <S.BackgroungSvg src={EmptyNotificationsSvg} alt="Não há notificações" data-testid="empty-notifications-svg" />
      <S.Title>{title}</S.Title>
      {description && <S.Description>{description}</S.Description>}
    </S.Container>
  );
};
