import React, { useState, useEffect, useRef, useCallback } from 'react';
import Tab from '@material-ui/core/Tab';
import Edit from '@material-ui/icons/Edit';

import { useFilterInspectionById } from 'hooks/Inspections/InspectionsHooks';
import Loading from 'components/Loading';
import { useAuthDispatch } from 'contexts/auth/useAuth';

import linkNotSentImage from 'assets/svg/link-not-sent.png';

import { EditInspectionDialog } from 'components/Dialog/EditInspectionDialog/EditInspectionDialog';
import { useConsumer } from 'contexts/consumers/useConsumer';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { TabsActions } from '@material-ui/core';
import { useAppSelector } from 'hooks/hooks';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { StepperComponent } from '../../../../components';
import { Button } from '../../../../components/Button';
import {
  Title,
  TitleCanceled,
  DetailsLoadingWrapper,
  DetailsContainer,
  DetailsHeader,
  TabsBase,
  TitleLinkNotSent,
  DescriptionLinkNotSent,
  ContainerLinkNotSent,
  ButtonsWrapper,
  DetailsBody,
} from './Details.styles';

import InspectionStatusService from '../../../../services/ApiService/InspectionStatusService/InspectionStatusService';

import { ACTIVE_TAB_ENUM, ActiveTab, DetailsProps } from './Details.types';
import { IInspection, StatusEnum } from '../Inspection/Inspection.types';
import DetailsRouter from './Details.router';

export const Details = React.memo(({ inspectionId }: DetailsProps) => {
  const { inspectionData, isLoadingInspection } = useFilterInspectionById(inspectionId, 'DETAILS');
  const { isAdmin } = useAuthDispatch();
  const [selectedTabValue, setSelectedTabValue] = useState<ActiveTab>(() => {
    const path = window.location.pathname;

    if (path.includes('transmissao')) return ACTIVE_TAB_ENUM.TAB_TRANSMISSION;

    if (path.includes('orcamentos')) return ACTIVE_TAB_ENUM.TAB_BUDGETS;

    return ACTIVE_TAB_ENUM.TAB_INSPECTION;
  });

  const [showCodeOrPlateDialog, setShowCodeOrPlateDialog] = useState(false);
  const { isConsumerActive } = useConsumer();
  const headerDetailsRef = useRef<HTMLDivElement | null>(null);
  const tabsActionsRef = useRef<TabsActions | null>(null);
  const schedulerListMenuIsOpen = useAppSelector((state) => state.schedulerListMenu.isOpen);
  const history = useHistory();
  const queryClient = useQueryClient();

  const changeTabs = (_: React.SyntheticEvent<Element, Event>, newValue: ActiveTab): void => {
    if (newValue === ACTIVE_TAB_ENUM.TAB_INSPECTION) {
      history.push(`/agendamentos/detalhes/${inspectionId}${window.location.search}`);
    }

    if (newValue === ACTIVE_TAB_ENUM.TAB_TRANSMISSION) {
      history.push(`/agendamentos/detalhes/${inspectionId}/transmissao${window.location.search}`);
    }

    if (newValue === ACTIVE_TAB_ENUM.TAB_BUDGETS) {
      history.push(`/agendamentos/detalhes/${inspectionId}/orcamentos${window.location.search}`);
    }

    setSelectedTabValue(newValue);
  };

  const isInspectionCreatedStatus = ({ currentStatus }: IInspection): boolean => {
    return InspectionStatusService.isInspectionCreated(currentStatus);
  };

  const isInspectionCategoryDigital = (): boolean => {
    return InspectionService.isInspectionCategoryDigital(inspectionData?.inspectionCategory);
  };

  const mutateInspection = async (): Promise<void> => {
    queryClient.invalidateQueries({
      queryKey: ['get-inspection-by-id', { id: inspectionId }],
    });
    queryClient.invalidateQueries({ queryKey: ['get-inspections'] });
  };

  const isAbleToEdit = (inspection: IInspection): boolean => {
    if (isCurrentStatusAbleToEdit(inspection)) return true;
    return false;
  };

  const isCurrentStatusAbleToEdit = (inspection: IInspection): boolean => {
    const { currentStatus } = inspection;
    return currentStatus.type < StatusEnum.INSPECTION_STARTED;
  };

  const handleEditCodeOrPlateOpen = (): void => {
    setShowCodeOrPlateDialog(true);
  };

  const handleEditCodeOrPlateClose = (): void => {
    setShowCodeOrPlateDialog(false);
  };

  const showTransmissionSessionTab = (): boolean => {
    return inspectionData?.transmissionSession !== undefined;
  };

  const showBudgetsTab = (): boolean => {
    return (
      ProductTypeService.isAuto(inspectionData?.productType?.code) &&
      isConsumerActive(ConsumersEnum.BUDGETS_ACCESS, inspectionData?.company?.id || '')
    );
  };

  const hasMultipleActiveTabs = (): boolean => {
    return showTransmissionSessionTab() || showBudgetsTab();
  };

  const updateTabIndicator = useCallback(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (tabsActionsRef.current && headerDetailsRef.current) {
        tabsActionsRef.current.updateIndicator();
      }
    });

    if (headerDetailsRef.current) {
      resizeObserver.observe(headerDetailsRef.current);
    }
  }, []);

  useEffect(() => {
    updateTabIndicator();
  }, [updateTabIndicator, schedulerListMenuIsOpen]);

  const editInspectionButton = (inspection: IInspection): JSX.Element => {
    if (isAdmin()) {
      return (
        <ButtonsWrapper>
          <Button
            type="button"
            testID="edit-inspection"
            text="EDITAR ATENDIMENTO"
            size="large"
            onClick={() => handleEditCodeOrPlateOpen()}
            disabled={!isAbleToEdit(inspection)}
            startIcon={<Edit />}
            variant="outlined"
          />
          <EditInspectionDialog
            dialogVisibility={showCodeOrPlateDialog}
            handleClose={handleEditCodeOrPlateClose}
            inspection={inspection}
            callback={mutateInspection}
          />
        </ButtonsWrapper>
      );
    }
    return <></>;
  };

  if (inspectionData && isInspectionCreatedStatus(inspectionData) && isInspectionCategoryDigital()) {
    return (
      <ContainerLinkNotSent data-testid="link-not-sent">
        <img src={linkNotSentImage} alt="link-not-sent" />

        <TitleLinkNotSent>Link não enviado</TitleLinkNotSent>
        <DescriptionLinkNotSent>
          Houve um problema com o envio do link. Tente enviar novamente clicando no botão
          <span> Reenviar Link.</span>
        </DescriptionLinkNotSent>
      </ContainerLinkNotSent>
    );
  }

  return (
    <>
      {((): JSX.Element => {
        if (!isLoadingInspection && inspectionData) {
          const isInspectionCanceled = InspectionStatusService.isCanceled(inspectionData.status);
          const isInspectionExpired = InspectionStatusService.isExpired(inspectionData.status);

          return (
            <DetailsContainer>
              <DetailsHeader ref={headerDetailsRef}>
                {isInspectionCanceled || isInspectionExpired ? (
                  <TitleCanceled>
                    Atendimento #{inspectionData.code} - {isInspectionCanceled ? 'Cancelado' : 'Expirado'}
                  </TitleCanceled>
                ) : (
                  <Title>Atendimento #{inspectionData.code}</Title>
                )}

                {editInspectionButton(inspectionData)}

                <StepperComponent
                  testID="inspection-status"
                  inspectionStatus={inspectionData.status}
                  inspectionCategory={inspectionData.inspectionCategory}
                  error={isInspectionCanceled || isInspectionExpired}
                />

                {hasMultipleActiveTabs() && (
                  <TabsBase
                    value={selectedTabValue}
                    onChange={changeTabs}
                    action={tabsActionsRef}
                    centered
                    indicatorColor="primary"
                    data-testid="tab-base"
                  >
                    <Tab label="Dados do atendimento" value={ACTIVE_TAB_ENUM.TAB_INSPECTION} />

                    {showTransmissionSessionTab() && (
                      <Tab
                        label="Transmissão Ao Vivo"
                        value={ACTIVE_TAB_ENUM.TAB_TRANSMISSION}
                        data-testid={ACTIVE_TAB_ENUM.TAB_TRANSMISSION}
                      />
                    )}

                    {showBudgetsTab() && (
                      <Tab
                        label="Orçamentos"
                        value={ACTIVE_TAB_ENUM.TAB_BUDGETS}
                        data-testid={ACTIVE_TAB_ENUM.TAB_BUDGETS}
                      />
                    )}
                  </TabsBase>
                )}
              </DetailsHeader>

              <DetailsBody>
                <DetailsRouter inspection={inspectionData} />
              </DetailsBody>
            </DetailsContainer>
          );
        }
        return (
          <DetailsLoadingWrapper>
            <Loading width="20%" />
          </DetailsLoadingWrapper>
        );
      })()}
    </>
  );
});
