import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';

export interface IConclusionStyleProps {
  isFocused?: boolean;
  isBold?: boolean;
}

export const Container = styled.div<IConclusionStyleProps>`
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  border-radius: 10px;
  background-color: #fafbfd;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isFocused }) => (isFocused ? '#000000' : '#C7CBD3')};
  gap: 5px;
  width: 100%;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const CommentContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Comment = styled.div`
  color: #4d4d4d;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  word-break: break-word;
`;

export const InfoTextContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`;

export const InfoText = styled.div<IConclusionStyleProps>`
  color: #282828;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: normal;
  text-align: left;
`;

export const Divider = styled(DividerMui)`
  border-color: #ced6e5 !important;
`;
