import { DisassociateUserDialog } from 'components/Dialog/DisassociateUserDialog/DisassociateUserDialog';
import { EditOperationDialog } from 'components/Dialog/EditOperationDialog/EditOperationDialog';
import React, { useState } from 'react';
import * as Styles from './ActionsMenu.styles';
import { IActionsMenu } from './ActionsMenu.types';

export const ActionsMenu: React.FC<IActionsMenu> = ({ dataTestId, accountId, operations, username }) => {
  const closedMenuItem = null as unknown as Element;
  const [menuItemAnchor, setMenuItemAnchor] = useState<Element>(closedMenuItem);
  const open = Boolean(menuItemAnchor);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [disassociateDialog, setDisassociateDialogIsOpen] = useState(false);

  const openMenuItem = (el: Element): void => {
    setMenuItemAnchor(el);
  };

  const handleCloseMenu = (): void => {
    setMenuItemAnchor(closedMenuItem);
  };

  const handleOpenEditDialog = (): void => {
    setEditDialogIsOpen(true);
  };

  const handleCloseEditDialog = (): void => {
    setEditDialogIsOpen(false);
    handleCloseMenu();
  };

  const handleOpenDisassociateDialog = (): void => {
    setDisassociateDialogIsOpen(true);
  };

  const handleCloseDisassociateDialog = (): void => {
    setDisassociateDialogIsOpen(false);
    handleCloseMenu();
  };

  return (
    <>
      <Styles.ActionsIconWrapper
        data-testid={dataTestId}
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(props): void => openMenuItem(props.currentTarget)}
      >
        <Styles.ActionsMenuIcon />
      </Styles.ActionsIconWrapper>
      <Styles.ActionsMenuWrapper
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={menuItemAnchor}
        open={open}
        onClose={handleCloseMenu}
        disableScrollLock={false}
      >
        <Styles.ActionsMenuItem data-testid="actions-menu-edit" onClick={handleOpenEditDialog}>
          <Styles.ActionsText>EDITAR OPERAÇÕES</Styles.ActionsText>
        </Styles.ActionsMenuItem>

        <Styles.ActionsMenuItem data-testid="actions-menu-disassociate" onClick={handleOpenDisassociateDialog}>
          <Styles.ActionsText>DESASSOCIAR USUÁRIO</Styles.ActionsText>
        </Styles.ActionsMenuItem>
      </Styles.ActionsMenuWrapper>
      <EditOperationDialog
        accountId={accountId}
        dialogVisibility={editDialogIsOpen}
        handleClose={handleCloseEditDialog}
        operations={operations}
      />

      <DisassociateUserDialog
        accountId={accountId}
        username={username}
        dialogVisibility={disassociateDialog}
        handleClose={handleCloseDisassociateDialog}
      />
    </>
  );
};
