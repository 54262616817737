import styled from 'styled-components';

export const NoContentListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
`;

export const NoContentListImage = styled.img`
  -webkit-user-drag: none;
`;

export const NoContentListTitle = styled.h1`
  font-weight: 600;
  font-size: 22px;
  color: #6a758b;
  text-align: center;
  margin: 0 0 10px 0;
`;

export const NoContentListSubTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #6a758b;
  text-align: center;
  margin: auto;
  max-width: 280px;
`;
