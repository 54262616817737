import styled from 'styled-components';

export const SectionContainer = styled.div`
  page-break-inside: avoid;
`;

export const SectionHeader = styled.div`
  display: flex;
  gap: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
  background-color: #1a1a1a;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: white;
`;

export const ServicesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
`;
