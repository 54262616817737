import React, { useState, useEffect, useCallback } from 'react';
import { Button, Label } from 'components';
import { IOptions } from 'components/Select/Select.types';
import { toast } from 'react-toastify';
import { mutateMany } from 'hooks/SWRUtils/SWRUtils';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { ICompany } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import * as Style from './ChangeOperationDialog.styles';
import { IChangeOperationDialogProps } from './ChangeOperationDialog.types';

export const ChangeOperationDialog: React.FC<IChangeOperationDialogProps> = ({
  inspectionId,
  companyId,
  dialogVisibility,
  handleClose,
}) => {
  const [operationsList, setOperationsList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [operationSelected, setOperationSelected] = useState<string>('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { getCompaniesWithoutCAPForActiveProfile } = useActiveProfile();

  const disableSubmitButton = (): boolean => {
    return operationSelected === '';
  };

  const handleSubmit = async (): Promise<void> => {
    setLoadingSubmit(true);
    try {
      await InspectionService.updateOperation(inspectionId, operationSelected);
      updateSWRInspections();
      toast.success('Operação alterada com sucesso');
    } catch (error) {
      toast.error('Falha ao alterar operação');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const filterOperationsToChange = useCallback(
    (operations: IOptions[]): IOptions[] => {
      return operations.filter((operation) => operation.value !== companyId);
    },
    [companyId]
  );

  const getOperations = useCallback(async (): Promise<IOptions[]> => {
    try {
      const operations = getCompaniesWithoutCAPForActiveProfile();
      return filterOperationsToChange(operationsToOptions(operations));
    } catch (error) {
      toast.error('Problema ao carregar as operações, tente novamente.');
      return [];
    }
  }, [getCompaniesWithoutCAPForActiveProfile, filterOperationsToChange]);

  const defaultOperationList = useCallback(async (): Promise<void> => {
    setOperationsList(await getOperations());
  }, [getOperations]);

  const updateSWRInspections = async (): Promise<void> => {
    await mutateMany('^\\/inspections\\/admin\\?companiesId(.*)');
  };

  const operationsToOptions = (operations: ICompany[]): IOptions[] => {
    return operations.map(({ id: value, name }) => ({ value, name }));
  };

  const getDefaultOperationSelected = useCallback((): string => operationsList[0]?.value, [operationsList]);

  useEffect(() => {
    (async (): Promise<void> => defaultOperationList())();
  }, [defaultOperationList]);

  useEffect(() => {
    const defaultOperationSelected = getDefaultOperationSelected();
    setOperationSelected(defaultOperationSelected);
  }, [operationsList, getDefaultOperationSelected]);

  return (
    <Style.DialogBase
      data-testid="change-operation-dialog"
      aria-labelledby="form-dialog-title"
      open={dialogVisibility}
      onClose={handleClose}
    >
      <Style.FormWrapper>
        <Style.DialogTitle>Qual a operação que deseja?</Style.DialogTitle>
        <Label htmlFor="change-operation-company" testID="label-company">
          Operação
        </Label>
        <Style.SelectContainer>
          <Style.SelectWrapper
            testID="change-operation-select"
            options={operationsList}
            selectedOptions={operationSelected}
            labelId="change-operation-select"
            name="operations"
            onChange={({ target: { value } }): void => setOperationSelected(value as string)}
          />
        </Style.SelectContainer>
        <Style.DialogActions>
          <Style.ButtonWrapper>
            <Button
              testID="change-operation-cancel"
              type="button"
              variant="outlined"
              onClick={handleClose}
              text="VOLTAR"
            />
          </Style.ButtonWrapper>
          <Style.ButtonWrapper>
            <Button
              testID="change-operation-submit"
              type="submit"
              text="ALTERAR"
              disabled={disableSubmitButton()}
              loading={loadingSubmit}
              onClick={handleSubmit}
              loadingSize={25}
              loadingColor="#FFF"
            />
          </Style.ButtonWrapper>
        </Style.DialogActions>
      </Style.FormWrapper>
    </Style.DialogBase>
  );
};
