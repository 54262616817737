/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Form } from 'components/Form';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { formValidations } from 'utils/form/validations';
import accountService from 'services/ApiService/AccountService';
import * as Style from './ChangePasswordDialog.styles';
import {
  DialogBase,
  DialogTitle,
  DialogContentText,
  FormWrapper,
  DialogActions,
  ButtonWrapper,
} from './ChangePasswordDialog.styles';
import { IChangePasswordDialogProps, IPasswordChangeForm } from './ChangePasswordDialog.types';

export const ChangePasswordDialog = ({ dialogVisibility, handleClose }: IChangePasswordDialogProps) => {
  const { register, errors, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
  });
  const { dirtyFields, touched } = formState;
  const [invalidPassword, setInvalidPassword] = useState(false);

  const onSubmit = async (formRequest: IPasswordChangeForm): Promise<void> => {
    const validPassword = await confirmPassword(formRequest.password);

    if (validPassword) {
      await changePassword(formRequest.newPassword);
      handleClose();
    } else {
      setInvalidPassword(true);
    }
  };

  const onPasswordChange = (): void => {
    if (dirtyFields.password && touched.password && invalidPassword) {
      setInvalidPassword(false);
    }
  };

  const confirmPassword = async (password: string): Promise<boolean> => {
    const {
      data: { valid },
    } = await accountService.confirmPassword(password);
    return valid;
  };

  const changePassword = async (newPassword: string): Promise<void> => {
    try {
      await accountService.changePassword(newPassword);
      toast.success('Sucesso! Sua senha foi alterada.');
    } catch (error) {
      toast.error('Erro! Não foi possível alterar a senha.');
    }
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const validations = {
    confirmNewPassword: {
      ...formValidations.default,
      validate: (value: string) => value === watch('newPassword') || 'Deve ser igual à nova senha',
    },
  };

  const inputProps = {
    password: {
      'data-testid': 'input-change-password',
    },
    newPassword: {
      'data-testid': 'input-new-password',
    },
    confirmNewPassword: {
      'data-testid': 'input-confirm-password',
    },
  };

  return (
    <DialogBase
      data-testid="change-password-dialog"
      open={dialogVisibility}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Alterar senha</DialogTitle>
      <DialogContentText>
        A sua nova senha deve conter pelo menos 8 caracteres com, ao menos, um número.
      </DialogContentText>
      <FormWrapper>
        <Form testID="change-password-form" onSubmit={handleSubmit(onSubmit)}>
          <Style.CustomErrorContainer>
            <Label htmlFor="password" testID="label-password">
              Senha atual
            </Label>
            <Style.TextField
              ariaLabelledby="password"
              error={hasError('password') || invalidPassword}
              id="password"
              inputProps={inputProps.password}
              inputRef={register(formValidations.default)}
              helperText={errors?.password?.message}
              name="password"
              onChange={onPasswordChange}
              placeholder="Insira a sua senha atual"
              state={handleInputState('password')}
              type="password"
              variant="outlined"
            />
            {invalidPassword && !hasError('password') && (
              <Style.CustomError id="password-helper-text">Senha incorreta</Style.CustomError>
            )}
          </Style.CustomErrorContainer>

          <Label htmlFor="newPassword" testID="label-new-password">
            Nova senha
          </Label>
          <Style.TextField
            ariaLabelledby="newPassword"
            error={hasError('newPassword')}
            id="newPassword"
            inputProps={inputProps.newPassword}
            inputRef={register(formValidations.newPassword)}
            helperText={errors?.newPassword?.message}
            name="newPassword"
            placeholder="Insira a sua nova senha"
            state={handleInputState('newPassword')}
            type="password"
            variant="outlined"
          />

          <Label htmlFor="confirmNewPassword" testID="label-confirm-new-password">
            Repetir nova senha
          </Label>
          <Style.TextField
            ariaLabelledby="confirm password"
            error={hasError('confirmNewPassword')}
            id="confirmNewPassword"
            inputProps={inputProps.confirmNewPassword}
            inputRef={register(validations.confirmNewPassword)}
            helperText={errors?.confirmNewPassword?.message}
            name="confirmNewPassword"
            placeholder="Repita a sua nova senha"
            state={handleInputState('confirmNewpassword')}
            type="password"
            variant="outlined"
          />

          <DialogActions>
            <ButtonWrapper>
              <Button
                testID="change-password-cancel"
                type="button"
                variant="outlined"
                onClick={handleClose}
                text="CANCELAR"
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button testID="change-password-submit" type="submit" text="ALTERAR" />
            </ButtonWrapper>
          </DialogActions>
        </Form>
      </FormWrapper>
    </DialogBase>
  );
};
