import React, { useState, memo, useCallback, useEffect } from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import ResendLinkDialog from 'components/Dialog/ResendLinkDialog/ResendLinkDialog';
import environment from 'environments/environment';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import badgeSms from 'assets/svg/badge-sms.svg';
import badgeWhatsApp from 'assets/svg/badge-whatsapp.svg';
import AvatarIcon from 'components/Avatar/Avatar';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import * as S from './Inspection.styles';

import {
  IInspectionProps,
  IInspectionRow,
  IProduct,
  ISentMessage,
  StatusEnum,
  NotificationSenderEnum,
} from './Inspection.types';
import { CardComponent } from '../../../../components';

import { formatPhone } from '../../../../utils/formatPhone';

import DateService from '../../../../services/DateService/dateService';
import InspectionStatusService from '../../../../services/ApiService/InspectionStatusService/InspectionStatusService';

const Inspection = ({ inspection, type = 'closed' }: IInspectionProps) => {
  const {
    id: inspectionId,
    currentStatus,
    status,
    code,
    inspectionCategory,
    createdAt,
    customer,
    schedule,
    product,
    productType,
    currentResponsible,
    inspectionNotificationSender,
  } = inspection;

  const [resendLinkDialogIsOpen, setResendLinkDialogVisibility] = useState(false);
  const [badgeOrigin, setBadgeOrigin] = useState<NotificationSenderEnum>(NotificationSenderEnum.SMS_TWILIO);
  const [isBadgeVisible, setIsBadgeVisible] = useState(false);
  const { isAdmin } = useAuthDispatch();

  const isProductTypeAuto = ProductTypeService.isAuto(productType?.code);
  const isCreatedOrSent = InspectionStatusService.isCreatedOrSent(currentStatus);
  const isCanceled = InspectionStatusService.isCanceled(status);
  const isExpired = InspectionStatusService.isExpired(status);

  const handleResendLinkOpen = (): void => {
    setResendLinkDialogVisibility(true);
  };

  const handleResendLinkClose = (): void => {
    setResendLinkDialogVisibility(false);
  };

  const handleCopyLink = async () => {
    try {
      toast.dismiss();
      if (!!environment.WEBAPP_URL && !!inspectionId) {
        const webappLink = environment.WEBAPP_URL + inspectionId;
        await navigator.clipboard.writeText(webappLink);
        toast.success('Link copiado para área de transferência.');
      } else {
        toast.error('Ocorreu um erro ao montar o link. Por favor, tente novamente.');
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao copiar o link. Por favor, tente novamente.');
    }
  };

  const InspectionRow = ({ label, value }: IInspectionRow) => (
    <S.Row>
      <S.Label>{label}</S.Label>
      <S.Value>{value}</S.Value>
    </S.Row>
  );

  const formatScheduleDate = (): string => {
    return schedule ? DateService.formatDate(schedule) : '';
  };

  const formatPlate = (productFormat?: IProduct): string => {
    return productFormat?.plate || 'SEM PLACA';
  };

  const showResponsibleUsername = (username: string): string => {
    return username.length <= 12 ? username : `${username.slice(0, 12)}...`;
  };

  const isSentMessageValid = (sentMessage: ISentMessage | undefined): void => {
    !!sentMessage && setBadgeOrigin(sentMessage?.notificationSender?.type);
  };

  const avatarColor = (): string => {
    if (currentResponsible?.avatar?.color) {
      return currentResponsible?.avatar?.color;
    }
    return '';
  };

  const isBadgeOriginWhatsappOrZenvia = (): boolean => {
    return badgeOrigin === NotificationSenderEnum.WHATSAPP || badgeOrigin === NotificationSenderEnum.ZENVIA_WHATSAPP;
  };

  const formatCustomerName = () => {
    return customer?.name || 'Não informado';
  };

  const formatCustomerContactName = () => {
    return customer?.contactName || 'Não informado';
  };

  const formatCustomerPhone = () => {
    return customer?.phone ? formatPhone(customer?.phone) : 'Não informado';
  };

  const formatBrandAndModel = () => {
    const { brand, model } = product?.vehicle || {};

    if (!brand && !model) {
      return '-';
    }

    return [brand, model].filter(Boolean).join(' / ');
  };

  const InspectionBody = () => {
    const BadgeIcon = (): JSX.Element => {
      if (isBadgeOriginWhatsappOrZenvia()) {
        return (
          <S.NavTooltip title="WhatsApp" placement="top">
            <S.Badge src={badgeWhatsApp} data-testid="badge-link-sent" alt="whatsapp" />
          </S.NavTooltip>
        );
      }
      return (
        <S.NavTooltip title="SMS" placement="top">
          <S.Badge src={badgeSms} data-testid="badge-link-sent" alt="sms" />
        </S.NavTooltip>
      );
    };

    const handleBadge = useCallback((): void => {
      const linkSentStatus = status?.find((statusItem) => statusItem.type === StatusEnum.LINK_SENT);
      if (linkSentStatus) {
        const sentMessage = inspectionNotificationSender?.sentMessagesHistory.find(
          (sentMessages) => sentMessages.statusId === linkSentStatus.id
        );
        setIsBadgeVisible(!!sentMessage);
        isSentMessageValid(sentMessage);
      }
    }, []);

    useEffect(() => {
      handleBadge();
    }, [handleBadge]);

    const renderCollapsedBody = () => {
      if (isProductTypeAuto) {
        return (
          <S.Body>
            <S.ColumnLeft>
              <InspectionRow label="Segurado" value={formatCustomerName()} />
            </S.ColumnLeft>
            <S.ColumnRight>
              <InspectionRow label="Placa" value={formatPlate(product)} />
            </S.ColumnRight>
          </S.Body>
        );
      }

      return (
        <S.Body>
          <S.ColumnLeft>
            <InspectionRow label="Cliente" value={formatCustomerName()} />
          </S.ColumnLeft>
          <S.ColumnRight>
            {schedule ? (
              <InspectionRow label="Agendamento" value={formatScheduleDate()} />
            ) : (
              <InspectionRow label="Telefone" value={formatCustomerPhone()} data-testid="contact-phone" />
            )}
          </S.ColumnRight>
        </S.Body>
      );
    };

    return (
      <>
        <S.IconsContainer>
          {isAdmin() && currentResponsible?.username && (
            <S.NavTooltip title={currentResponsible.username} placement="top">
              <S.Responsible onMouseEnter={(): string => showResponsibleUsername(currentResponsible.username)}>
                <AvatarIcon color={avatarColor()}>{currentResponsible.username}</AvatarIcon>
              </S.Responsible>
            </S.NavTooltip>
          )}
          {isBadgeVisible && BadgeIcon()}
        </S.IconsContainer>

        {renderCollapsedBody()}
      </>
    );
  };

  const InspectionActions = () => {
    const isInspectionLocally = InspectionService.isInspectionCategoryLocally(inspectionCategory);
    const showButtons = !isInspectionLocally && isCreatedOrSent && customer?.phone;

    const renderExpandedBody = () => {
      if (isProductTypeAuto) {
        return (
          <S.Body>
            <S.ColumnLeft>
              <InspectionRow label="Telefone" value={formatCustomerPhone()} data-testid="contact-phone" />
              <InspectionRow label="Nome do Contato" value={formatCustomerContactName()} />
            </S.ColumnLeft>
            <S.ColumnRight>
              <InspectionRow label="Marca/Modelo" value={formatBrandAndModel()} />
              <InspectionRow label="Criada em" value={DateService.formatDate(createdAt)} />
            </S.ColumnRight>
          </S.Body>
        );
      }

      return (
        <S.Body>
          <S.ColumnLeft>
            {schedule && <InspectionRow label="Telefone" value={formatCustomerPhone()} data-testid="contact-phone" />}
            <InspectionRow label="Nome do Contato" value={formatCustomerContactName()} />
          </S.ColumnLeft>
          <S.ColumnRight>
            <InspectionRow label="Criado em" value={DateService.formatDate(createdAt)} />
          </S.ColumnRight>
        </S.Body>
      );
    };

    return (
      <S.CollapseWrapper>
        {renderExpandedBody()}

        {showButtons && (
          <S.LinkButtons>
            <S.ButtonLink
              variant="contained"
              type="button"
              fullWidth={false}
              size="small"
              startIcon={<ReplayIcon />}
              testID="open-resend-link-dialog"
              text="REENVIAR LINK"
              onClick={handleResendLinkOpen}
            />
            <S.ButtonLink
              variant="outlined"
              type="button"
              fullWidth={false}
              size="small"
              startIcon={<ContentCopyIcon />}
              testID="copy-link-dialog"
              text="COPIAR LINK"
              onClick={handleCopyLink}
            />
          </S.LinkButtons>
        )}
      </S.CollapseWrapper>
    );
  };

  const renderCardTitle = () => {
    if (code) {
      if (inspectionCategory && inspectionCategory.name) {
        return (
          <S.HeaderTitle>
            {code} - <S.HeaderSubtitle>{inspectionCategory.name}</S.HeaderSubtitle>
          </S.HeaderTitle>
        );
      }

      return <S.HeaderTitle>{code}</S.HeaderTitle>;
    }

    return <S.HeaderTitle>#</S.HeaderTitle>;
  };

  return (
    <>
      <CardComponent
        testID="inspection-card"
        body={<InspectionBody />}
        title={renderCardTitle()}
        subheader={<S.SubHeader alert={isCanceled || isExpired}>{currentStatus.description}</S.SubHeader>}
        type={type}
        actions={<InspectionActions />}
      />
      <ResendLinkDialog
        dialogVisibility={resendLinkDialogIsOpen}
        handleClose={handleResendLinkClose}
        inspection={inspection}
      />
    </>
  );
};

export default memo(Inspection);
