import { IToggleButtonOption } from 'components/ToggleButton';
import React, { useCallback, useMemo } from 'react';
import { Container, NumberContent, Title, ToggleButtonContainer } from './DamageQuestionInput.styles';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { setDamageReportAnswerId } from 'stores/presentBudget/presentBudget.store';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectDamageQuestionIdsUnanswered } from 'stores/presentBudget/presentBudget.selector';
import { answerStatus } from '../../../../RescuedReport/RescuedReportForm/QuestionGroup/QuestionInput/QuestionInput.types';
import { selectDamageReportIsDraft } from 'stores/presentBudget/presentBudget.selector';
import { IDamageQuestionInputProps } from './DamageQuestionInput.types';

const DamageQuestionInput = ({ question, questionGroupId }: IDamageQuestionInputProps) => {
  const questionsWithoutAnswerId = useAppSelector(selectDamageQuestionIdsUnanswered);
  const isDraftDamageReport = useAppSelector(selectDamageReportIsDraft);
  const dispatch = useAppDispatch();

  const isNotAnswered = !!questionsWithoutAnswerId && !!questionsWithoutAnswerId.includes(question._id);

  const toggleButtonOptions: IToggleButtonOption[] = useMemo(
    () =>
      question.options.map((option) => {
        return {
          code: option._id,
          title: option.slug,
        };
      }),
    [question.options]
  );

  const isAnswered = useMemo(() => !!question.answerId, [question]);

  const getAnswerStatus = () => {
    if (isNotAnswered && !isAnswered) {
      return answerStatus.NOT_ANSWERED;
    }

    if (isAnswered) {
      return answerStatus.ANSWERED;
    }

    return answerStatus.INITIAL_STATE;
  };

  const handleToggleButtonOnChange = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
      dispatch(setDamageReportAnswerId({ questionGroupId, questionId: question._id, answerId: value }));
    },
    [dispatch, question._id, questionGroupId]
  );

  return (
    <Container answerStatus={getAnswerStatus()}>
      <Title>{question.text}</Title>
      <NumberContent>{question.weight}</NumberContent>
      <ToggleButtonContainer>
        <ToggleButton
          size="small"
          fontSize="medium"
          options={toggleButtonOptions}
          value={question.answerId}
          onChange={handleToggleButtonOnChange}
          disabled={!isDraftDamageReport}
        />
      </ToggleButtonContainer>
    </Container>
  );
};

export default DamageQuestionInput;
