import React, { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import MuiTableRow from '@mui/material/TableRow';
import DateService from 'services/DateService/dateService';
import { priceFormatter } from 'utils/formatPrice';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import * as S from './InvoicesExpensesTable.styles';
import {
  InvoiceExpensesSorting,
  ITableInvoiceExpensesHeaderCells,
  tableInvoicesExpensesHeader,
} from './InvoicesExpensesTable.type';
import { IInvoiceExpense, IInvoiceWithExpenses } from 'commom/types/Invoices.types';

const MAX_ITEMS_PER_PAGE = 10;
interface IInvoiceExpensesTableProps {
  invoiceWithExpenses?: IInvoiceWithExpenses;
  isLoading?: boolean;
  isFetching?: boolean;
  totalPages?: number;
  totalItems?: number;
  setPage: (page: number) => void;
  testId?: string;
}

export const InvoicesExpensesTable = ({
  invoiceWithExpenses,
  isLoading,
  isFetching,
  totalPages,
  totalItems,
  setPage,
  testId = 'invoices-expenses-table',
}: IInvoiceExpensesTableProps) => {
  useHideZendesk();
  const [tableHeaderCells] = useState<ITableInvoiceExpensesHeaderCells[]>(tableInvoicesExpensesHeader);
  const [pagination, setPagination] = useState<number>(1);
  const [counterTotalPages, setCounterTotalPage] = useState<number>(0);
  const { invoiceExpenses } = invoiceWithExpenses || {};
  const isEmptyInvoicesExpenseData = invoiceExpenses?.length === 0;
  const loading = isLoading || isFetching;

  const handleChange = (__: React.ChangeEvent<unknown>, newPage: number) => {
    setPagination(newPage);
    setPage(newPage - 1);
  };

  const getPageItems = (): number | undefined => {
    if (!invoiceExpenses || invoiceExpenses.length === 0) return 0;

    if (pagination === totalPages || pagination === 1) {
      return pagination === totalPages ? totalItems : MAX_ITEMS_PER_PAGE;
    }

    return MAX_ITEMS_PER_PAGE;
  };

  const formatedDate = (date: string) => {
    return DateService.formatDateAndHourToPTBRWithDot(date);
  };

  const getProductType = (type: string) => {
    return type === 'PHOTOS_RECEIVED' ? 'Vistoria digital' : 'Orçamento';
  };

  const renderPaginationItems = () => {
    if (!loading) {
      if (!isEmptyInvoicesExpenseData) {
        return (
          <S.PaginationDetails data-testid="pagination-expense-invoices-details">
            Mostrando&nbsp;
            <S.Bold>{getPageItems()}</S.Bold>
            &nbsp;de <S.Bold>{totalItems} resultados</S.Bold>
          </S.PaginationDetails>
        );
      } else {
        <S.PaginationDetails data-testid="pagination-expense-invoices-details"></S.PaginationDetails>;
      }
    } else {
      return (
        <S.PaginationDetails data-testid="pagination-expense-invoices-empty-result">
          <S.Bold>Carregando os resultados...</S.Bold>
        </S.PaginationDetails>
      );
    }
  };

  useEffect(() => {
    if (totalPages) {
      setCounterTotalPage(totalPages);
    }
  }, [totalPages]);

  return (
    <S.Container data-testid={testId}>
      <S.Header>
        <span>Serviços cobrados</span>
      </S.Header>
      <S.Content>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <MuiTableRow>
                {tableHeaderCells.map((tableHeaderCell) => (
                  <S.TableHeaderCell
                    align={tableHeaderCell.align}
                    key={tableHeaderCell.sortParam}
                    isIndexTop={tableHeaderCell.sortParam === InvoiceExpensesSorting.PRICE}
                  >
                    {tableHeaderCell.name}
                  </S.TableHeaderCell>
                ))}
              </MuiTableRow>
            </TableHead>
            {!isEmptyInvoicesExpenseData && !loading && (
              <TableBody>
                {invoiceExpenses?.map((invoiceExpense: IInvoiceExpense, index) => (
                  <S.TableBodyRow key={index}>
                    <S.TableBodyCell align="left">{invoiceExpense.companyName}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{invoiceExpense.inspectionCode || '-'}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{getProductType(invoiceExpense.type)}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{invoiceExpense.responsibleName || '-'}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{formatedDate(invoiceExpense.startDate)}</S.TableBodyCell>
                    <S.TableBodyCell align="center">
                      {priceFormatter.format(invoiceExpense?.price || 0)}
                    </S.TableBodyCell>
                  </S.TableBodyRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {loading && <S.TableBodyMessage>Carregando...</S.TableBodyMessage>}
        {isEmptyInvoicesExpenseData && !loading && (
          <S.TableBodyMessage>Nenhum serviço cobrado encontrado</S.TableBodyMessage>
        )}
        <S.PaginationWrapper>
          {renderPaginationItems()}
          <S.PaginationController>
            <Stack spacing={2}>
              <S.PaginationInvoicesTable
                page={pagination}
                onChange={handleChange}
                count={counterTotalPages}
                siblingCount={1}
                boundaryCount={2}
                variant="outlined"
                shape="rounded"
                data-testid="pagination-expense-invoices-table"
              />
            </Stack>
          </S.PaginationController>
        </S.PaginationWrapper>
      </S.Content>
    </S.Container>
  );
};
