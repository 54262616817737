import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const TextField = withTheme(styled(TextFieldComponent)`
  height: 34px !important;

  .MuiInputBase-root {
    height: 34px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1.5px solid #ced6e5;
  }
`);

export const AutocompleteWrapper = styled(Autocomplete)`
  width: 100%;
`;
