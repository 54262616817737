import styled from 'styled-components';
import { List, ListItem as ListItemStyled } from '@mui/material';

export const TabDocumentsContainer = styled(List)`
  display: flex;
  overflow-x: hidden;
  user-select: none;
  -webkit-overflow-scrolling: touch;

  &:hover {
    overflow-x: auto !important;
  }

  .MuiListItem-root {
    padding: 0 4px;
    width: auto;
  }
`;

export const ListItem = styled(ListItemStyled)``;

export const TouchableDocument = styled.button`
  background-color: #f5f5f5;
  border: none;
  padding: 0;
  cursor: pointer;

  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const DeleteIconButton = styled.button`
  cursor: pointer;
  background: transparent;
  position: absolute;
  bottom: 5px;
  right: 10px;
  padding: 0;
  border: none;
  z-index: 1;

  img {
    width: 20px;
    height: 20px;
  }
`;
