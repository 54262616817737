import styled from 'styled-components';
import { answerStatus } from '../../../../RescuedReport/RescuedReportForm/QuestionGroup/QuestionInput/QuestionInput.types';

interface IContainerProps {
  answerStatus: answerStatus;
}

enum AnsweredColor {
  NOT_ANSWERED = '#DC3D3D',
  ANSWERED = 'rgba(9, 152, 87, 1)',
  INITIAL_STATE = '#DADADA',
}
export const Container = styled.div<IContainerProps>`
  padding: 8px 12px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  border-width: 1px;
  border: 1px solid;
  border-color: ${({ answerStatus }) => AnsweredColor[answerStatus]};
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.41px;
  text-align: left;
  display: flex;
  color: #26415e99;
  flex: 2;
  padding: 10px;
`;

export const NumberContent = styled.span`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.41px;
  justify-content: center;
  display: flex;
  color: #26415e99;
  flex: 1;
  padding: 10px;
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  flex: 2.5;
`;
