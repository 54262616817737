import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  display: flex;
`;

export const DashboardContent = styled.div`
  background: black;
  width: 100%;
`;
