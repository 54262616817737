import styled from 'styled-components';

export const CardEmpty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;
`;

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-family: Montserrat;
  letter-spacing: 0em;
  text-align: center;
  color: #6a758b;
`;

export const EmptyTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;

export const EmptyDescription = styled.div`
  width: 205px;

  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;
