import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { pt } from './locales/pt/translations';
import { en } from './locales/en/translations';

export const defaultNS = 'translations';

const resources = { pt, en };

i18next.use(initReactI18next).init({
  fallbackLng: 'pt',
  defaultNS,
  resources,
});

export default i18next;
