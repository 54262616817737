import { ICompanySelectOptions, IOptions } from 'components/Select/Select.types';

export interface IInspectionCategoryService {
  inspectionCategoryIsLocally: (categoriesList: IOptions[], categorySelected: string) => boolean;
  companyInspectionCategoryIsLocally: (companySelected: ICompanySelectOptions) => boolean;
}

export enum ICategoryNameEnum {
  LOCALLY = 'Presencial',
  DIGITAL = 'Digital',
}
