import { IBudgetReportQuestion } from 'commom/types/BudgetReport.types';

export interface IQuestionInputProps {
  questionGroupId: string;
  question: IBudgetReportQuestion;
}

export enum answerStatus {
  NOT_ANSWERED = 'NOT_ANSWERED',
  ANSWERED = 'ANSWERED',
  INITIAL_STATE = 'INITIAL_STATE',
}
