import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 55px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  color: #000;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 35px;
  background-color: #fff;
  border-bottom: 1px solid #c3ccde;
  border-top: 1px solid #c3ccde;
`;

export const SubHeaderTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  color: #000;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
