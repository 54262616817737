import { IBudgetLaborCostTypeEnum } from 'commom/types/Budget.types';
import { Button } from 'components';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import { priceFormatter } from 'utils/formatPrice';
import {
  ButtonContainer,
  Divider,
  FooterWrapper,
  PriceFormatted,
  TotalCostContent,
  TotalCostLabel,
  TotalCostsWrapper,
  TotalPriceFormatted,
} from './PartDrawer.styles';
import { IFooterPartProps } from './PartDrawer.types';

const Footer = ({ handleClose, onSubmit, loadingSubmit, isEditMode = false }: IFooterPartProps) => {
  const { isServiceTypeRepair, partServiceHours, quantityParts, partPrice } = usePartContext();
  const { cost, paintingType } = useAppSelector((state) => {
    return {
      budgetId: state.presentBudget.budget._id,
      cost: state.presentBudget.budget.labor.cost,
      paintingType: state.presentInspection.inspection.product?.vehicle?.paintingType,
    };
  });

  const laborCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.LABOR)?.price || 0;
  const repairCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.REPAIR)?.price || 0;
  const paintingCost = cost.find((item) => item.type === paintingType?.toString())?.price || 0;

  const totalPartPrice = partPrice * quantityParts;

  const totalLaborPrice = () => {
    const removeAndInstall = partServiceHours.removeAndInstall * laborCost;
    const repair = partServiceHours.repair * repairCost;
    const painting = partServiceHours.painting * paintingCost;

    if (isServiceTypeRepair) {
      return (removeAndInstall + repair + painting) * quantityParts;
    }

    return (removeAndInstall + painting) * quantityParts;
  };

  const totalNetPrice = () => {
    if (isServiceTypeRepair) {
      return totalLaborPrice();
    }

    return totalPartPrice + totalLaborPrice();
  };

  return (
    <>
      <Divider />
      <FooterWrapper data-testid="footer-part-labor-cost">
        <TotalCostsWrapper>
          {!isServiceTypeRepair && (
            <>
              <TotalCostContent>
                <TotalCostLabel>Valor líquido da peça</TotalCostLabel>
                <PriceFormatted>{priceFormatter.format(totalPartPrice)}</PriceFormatted>
              </TotalCostContent>

              <TotalCostContent>
                <TotalCostLabel>Valor dos serviços</TotalCostLabel>
                <PriceFormatted>+ {priceFormatter.format(totalLaborPrice())}</PriceFormatted>
              </TotalCostContent>
            </>
          )}

          <TotalCostContent>
            <TotalCostLabel>Valor líquido total</TotalCostLabel>
            <TotalPriceFormatted>{priceFormatter.format(totalNetPrice())}</TotalPriceFormatted>
          </TotalCostContent>
        </TotalCostsWrapper>

        <ButtonContainer>
          <Button variant="outlined" text="CANCELAR" onClick={handleClose} />
          <Button
            testID="submit-part-button"
            variant="contained"
            text={isEditMode ? 'Editar Peça' : 'Adicionar Peça'}
            loading={loadingSubmit}
            onClick={onSubmit}
          />
        </ButtonContainer>
      </FooterWrapper>
    </>
  );
};

export default Footer;
