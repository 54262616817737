import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { IInspection } from 'pages/SchedulerList/components/Inspection';
import InspectionFiles from 'pages/SchedulerList/components/InspectionFiles/InspectionFiles';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { setCurrentStep } from 'stores/presentBudget/presentBudget.store';
import { BudgetsSteps } from 'stores/presentBudget/presentBudget.types';
import InitialData from './InitialData/InitialData';
import PartsAndServices from './PartsAndServices/PartsAndServices';
import Conclusion from './Conclusion/Conclusion';
import Report from './Report/Report';
import { Container, Content } from './StepsControl.styles';
import Header from './Header/Header';
import FooterOverview from '../FooterOverview/FooterOverview';
import { selectCurrentStatus, selectCurrentStep } from 'stores/presentBudget/presentBudget.selector';
import RescuedReport from './RescuedReport/RescuedReport';
import DamageReport from './DamageReport/DamageReport';

interface IStepsControlProps {
  inspection: IInspection;
}

const DEFAULT_STEPS: Record<BudgetsSteps, () => React.JSX.Element | null> = {
  INITIAL_DATA: InitialData,
  PARTS_AND_SERVICES: PartsAndServices,
  CONCLUSION: Conclusion,
  REPORT: Report,
  RESCUED_REPORT: RescuedReport,
  DAMAGE_REPORT: DamageReport,
};

const StepsControl = ({ inspection }: IStepsControlProps) => {
  const { account } = useAuthState();
  const { isAdmin } = useAuthDispatch();
  const currentStep = useAppSelector(selectCurrentStep);
  const currentStatus = useAppSelector(selectCurrentStatus);
  const dispatch = useAppDispatch();

  const currentStatusCode = currentStatus.status.code;
  const isResponsible = inspection.currentResponsible === undefined || inspection.currentResponsible?.id === account.id;

  const setStepInUrl = (step: BudgetsSteps) => {
    const url = new URL(window.location.href);
    url.searchParams.set('currentStep', step);
    window.history.pushState({}, '', url.toString());
  };

  const updateCurrentStep = useCallback(
    (step: BudgetsSteps) => {
      setStepInUrl(step);
      dispatch(setCurrentStep(step));
    },
    [dispatch]
  );

  const UpdateSteps = useCallback(() => {
    if (currentStatusCode === 'FINISHED') {
      updateCurrentStep('REPORT');
    } else if (!(isResponsible || isAdmin())) {
      const isNotResponsibleStep =
        currentStep === 'CONCLUSION' || currentStep === 'REPORT' ? currentStep : 'CONCLUSION';
      updateCurrentStep(isNotResponsibleStep);
    }
  }, [currentStatusCode, currentStep, isAdmin, isResponsible, updateCurrentStep]);

  const CurrentStep = DEFAULT_STEPS[currentStep];

  useEffect(() => {
    UpdateSteps();
  }, [UpdateSteps]);

  return (
    <Container>
      <Header inspection={inspection} />
      <InspectionFiles inspection={inspection} actionsVisible={false} />
      <Content>
        <CurrentStep />
      </Content>
      <FooterOverview />
    </Container>
  );
};

export default StepsControl;
