import {
  BudgetPartServiceHoursKeys,
  IBudgetLaborCostTypeEnum,
  IBudgetPartServiceHours,
} from 'commom/types/Budget.types';
import { Label, TextField } from 'components';
import IncrementDecrementButton from 'components/IncrementDecrementButton/IncrementDecrementButton';
import InputTime from 'components/InputTime/InputTime';
import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import NumericFormat from 'react-number-format';
import { IVehiclePartPrice } from 'services/ApiService/PartService/partsService.types';
import { priceFormatter } from 'utils/formatPrice';
import {
  ContentWrapper,
  GroupInputRow,
  InputWrapper,
  LabelService,
  LaborServicesButtons,
  Separetor,
  ServiceWrapper,
} from '../PartLaborCost.styles';
import { usePartLaborCostContext } from '../PartLaborCostContext';
import { calculateNetPrice } from 'utils/calculateNetPrice';

export interface IContentPartLaborCostProps {
  partPriceSelected: IVehiclePartPrice | null;
  partOriginalServiceHours: IBudgetPartServiceHours;
}

export default function Content({ partPriceSelected, partOriginalServiceHours }: IContentPartLaborCostProps) {
  const {
    isServiceTypeRepair,
    partServiceHours,
    quantityParts,
    partPrice,
    setQuantityParts,
    setPartServiceHours,
    setPartPrice,
  } = usePartLaborCostContext();
  const { cost, paintingType, discount, increase } = useAppSelector((state) => {
    return {
      cost: state.presentBudget.budget.labor.cost,
      paintingType: state.presentInspection.inspection.product?.vehicle?.paintingType,
      discount: state.presentBudget.budget.labor.discount,
      increase: state.presentBudget.budget.labor.increase,
    };
  });

  const laborCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.LABOR);
  const repairCost = cost.find((item) => item.type === IBudgetLaborCostTypeEnum.REPAIR);
  const paintingCost = cost.find((item) => item.type === paintingType?.toString());

  const handleIncrement = (prevValue: number) => {
    return prevValue + 0.5;
  };

  const handleDecrement = (prevValue: number) => {
    const decrementedValue = prevValue - 0.5;
    return decrementedValue > 0 ? decrementedValue : 0;
  };

  const handleDecrementOrIncrementPartServiceHours = (
    key: BudgetPartServiceHoursKeys,
    callback: (prevValue: number) => number
  ) => {
    setPartServiceHours((prev) => ({ ...prev, [key]: callback(prev[key]) }));
  };

  const handleChangePartServiceHours = (key: BudgetPartServiceHoursKeys, valueAsString: string) => {
    const valueReplaced = valueAsString.replace(',', '.');
    const valueFormatted = Number.isNaN(parseFloat(valueReplaced)) ? 0 : parseFloat(valueReplaced);

    setPartServiceHours((prev) => ({ ...prev, [key]: valueFormatted }));
  };

  const getArrowIndicator = (key: BudgetPartServiceHoursKeys) => {
    if (partServiceHours[key] > partOriginalServiceHours[key]) {
      return 'UP';
    }

    if (partServiceHours[key] < partOriginalServiceHours[key]) {
      return 'DOWN';
    }

    return '';
  };

  const getLabelBottom = (key: BudgetPartServiceHoursKeys) => {
    if (key === 'removeAndInstall') {
      return priceFormatter.format(partServiceHours.removeAndInstall * (laborCost?.price || 0));
    }

    if (key === 'repair') {
      return priceFormatter.format(partServiceHours.repair * (repairCost?.price || 0));
    }

    return priceFormatter.format(partServiceHours.painting * (paintingCost?.price || 0));
  };

  return (
    <ContentWrapper>
      <ServiceWrapper>
        <LabelService>Horas</LabelService>
        <LaborServicesButtons>
          <InputTime
            testID="remove-and-install"
            labelTop="Remoção & Instalação"
            value={partServiceHours.removeAndInstall}
            onChangeInput={({ target: { value } }) => handleChangePartServiceHours('removeAndInstall', value)}
            handleDecrement={() => handleDecrementOrIncrementPartServiceHours('removeAndInstall', handleDecrement)}
            handleIncrement={() => handleDecrementOrIncrementPartServiceHours('removeAndInstall', handleIncrement)}
            arrowIndicator={getArrowIndicator('removeAndInstall')}
            labelBottom={getLabelBottom('removeAndInstall')}
          />

          {isServiceTypeRepair && (
            <InputTime
              testID="repair"
              labelTop="Reparo"
              value={partServiceHours.repair}
              onChangeInput={({ target: { value } }) => handleChangePartServiceHours('repair', value)}
              handleDecrement={() => handleDecrementOrIncrementPartServiceHours('repair', handleDecrement)}
              handleIncrement={() => handleDecrementOrIncrementPartServiceHours('repair', handleIncrement)}
              arrowIndicator={getArrowIndicator('repair')}
              labelBottom={getLabelBottom('repair')}
            />
          )}

          <InputTime
            testID="painting"
            labelTop="Pintura"
            value={partServiceHours.painting}
            onChangeInput={({ target: { value } }) => handleChangePartServiceHours('painting', value)}
            handleDecrement={() => handleDecrementOrIncrementPartServiceHours('painting', handleDecrement)}
            handleIncrement={() => handleDecrementOrIncrementPartServiceHours('painting', handleIncrement)}
            arrowIndicator={getArrowIndicator('painting')}
            labelBottom={getLabelBottom('painting')}
          />
        </LaborServicesButtons>

        <InputWrapper>
          <Label htmlFor="input-amount">Quantidade</Label>
          <IncrementDecrementButton
            counter={quantityParts}
            handleInput={({ target: { value } }) => setQuantityParts(Number(value))}
            handleDecrement={() => setQuantityParts((prev) => prev - 1)}
            handleIncrement={() => setQuantityParts((prev) => prev + 1)}
          />
        </InputWrapper>
      </ServiceWrapper>

      {!isServiceTypeRepair && <Separetor />}

      {!isServiceTypeRepair && (
        <ServiceWrapper>
          <LabelService>Valores</LabelService>

          <GroupInputRow>
            <InputWrapper>
              <Label htmlFor="input-original-price-value">Valor original</Label>
              <NumericFormat
                id="input-original-price-value"
                decimalSeparator=","
                prefix="R$ "
                disabled
                customInput={TextField}
                value={partPriceSelected?.price}
                {...{ variant: 'outlined' }}
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="input-price-value">Com desconto e majoração</Label>
              <NumericFormat
                id="input-price-value"
                decimalSeparator=","
                prefix="R$ "
                disabled
                fixedDecimalScale={true}
                decimalScale={2}
                customInput={TextField}
                value={
                  partPriceSelected?.price
                    ? calculateNetPrice(partPriceSelected?.price, discount, increase)
                    : partPriceSelected?.price
                }
                {...{ variant: 'outlined' }}
              />
            </InputWrapper>
          </GroupInputRow>

          <InputWrapper>
            <Label htmlFor="input-part-price-value">Valor líquido da peça</Label>
            <NumericFormat
              id="input-part-price-value"
              placeholder="Insira o valor"
              prefix="R$ "
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={false}
              customInput={TextField}
              value={partPrice}
              onValueChange={({ floatValue }) => setPartPrice(floatValue || 0)}
              {...{ variant: 'outlined' }}
            />
          </InputWrapper>
        </ServiceWrapper>
      )}
    </ContentWrapper>
  );
}
