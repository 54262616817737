import styled from 'styled-components';

export const MonthNavPrev = styled.div`
  color: #6a758b;
  left: 10px;
  position: absolute;
  top: 5px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 3px 0 3px 8px;

  &:hover {
    background-color: #e8e8e8;
  }
`;

export const MonthNavNext = styled.div`
  color: #6a758b;
  right: 22px;
  position: absolute;
  top: 5px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 3px 2px 2px 6px;

  &:hover {
    background-color: #e8e8e8;
  }
`;

export const DateRangeWrapper = styled.div`
  .DateRangePickerInput_clearDates {
    margin: 0;
  }

  .DateRangePickerInput__showClearDates {
    padding-right: 14px;
  }

  tr > td {
    width: 30px !important;
    height: 22px !important;
  }

  .CalendarMonth_table {
    border-collapse: separate;
    border-spacing: 0 4px;
  }

  .CalendarDay {
    font-size: 12px;
    font-weight: 500;

    :focus {
      outline: none;
    }

    &__selected_start,
    &__selected_end {
      background: #000000;
      border-radius: 4px;
    }

    .DateInput_input__focused {
      background: #e8e8e8;
      color: #474f60;
    }
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span,
  .CalendarDay__selected_span {
    background: #e8e8e8;
    color: #474f60;
  }

  .CalendarDay__hovered_span:hover {
  }

  .CalendarDay__selected,
  .CalendarDay__selected_span,
  .CalendarDay__default {
    border: 0px;
  }

  .DayPicker_weekHeader_li::first-letter,
  .CalendarMonth_caption::first-letter {
    text-transform: capitalize;
  }

  .DayPicker > div > div {
    width: 460px !important;
  }

  .DayPicker_weekHeader {
    top: 36px;
  }

  .DayPicker_weekHeader:nth-child(1) {
    left: -12px !important;
  }

  .DayPicker_weekHeader:nth-child(2) {
    left: 214px !important;
  }

  .DayPicker,
  .DayPicker_transitionContainer {
    width: 453px !important;
    height: 240px !important;
  }

  .CalendarMonthGrid_month__horizontal:nth-child(2) .CalendarMonth {
    padding-right: 15px !important;
  }

  .CalendarMonth_caption {
    padding: 8px 0 35px 0;
  }

  .CalendarDay__selected_span {
    color: #474f60;
  }

  .CalendarMonth_caption > strong {
    font-size: 14px;
    font-weight: 500 !important;
    color: #737d92;
  }

  .DateInput_input {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 00.0238em;
    text-align: center;
    font-weight: 500;

    &[value=''] {
      font-weight: 300;
    }
  }

  .DateRangePicker {
    width: 100%;

    &Input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0px 2px;
      border-color: rgba(0, 0, 0, 0.23);
      border-radius: 4px;

      .DateInput {
        &_input {
          border-bottom: none;
        }
      }

      svg {
        top: 45px !important;
      }
    }

    &_picker {
      top: 58px !important;
      z-index: 5;
    }
  }

  .CalendarMonth {
    padding: 0 !important;
  }

  .MuiSvgIcon-root {
    width: 12px;
  }
`;
