export const formatPhone = (phone: string): string => {
  const cleaned = `${phone}`.replace(/\D/g, '');

  const cleanedWithoutZero = removeLeftZeroes(cleaned);

  const match = cleanedWithoutZero.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
  }

  return 'Telefone inválido';
};

export const normalizePhone = (phone: string): string => phone.replace(/[^0-9a-zA-Z]+/g, '');

const removeLeftZeroes = (phone: string): string => {
  return phone.replace(/^0+/, '');
};
