import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import { selectRescuedReportQuestionGroup } from 'stores/presentBudget/presentBudget.selector';
import { Container, QuestionGroupChunkedContainer } from './RescuedReportForm.styles';
import QuestionGroup from './QuestionGroup/QuestionGroup';
import { chunkArray } from 'utils/chunkArray';
import GeneralEvaluation from './GeneralEvaluation/GeneralEvaluation';

const RescuedReportForm = () => {
  const rescuedReportQuestionGroup = useAppSelector(selectRescuedReportQuestionGroup);

  return (
    <Container>
      {chunkArray(rescuedReportQuestionGroup, 2)?.map((questionGroupChunked, index) => {
        return (
          <QuestionGroupChunkedContainer key={index}>
            {index === 0 && <GeneralEvaluation />}
            {questionGroupChunked.map((questionGroup) => (
              <QuestionGroup key={questionGroup._id} questionGroup={questionGroup} />
            ))}
          </QuestionGroupChunkedContainer>
        );
      })}
    </Container>
  );
};

export default RescuedReportForm;
