import { useContext } from 'react';

import { IInvoicesContext } from './Invoices.types';

import { InvoicesContext } from './InvoicesProvider';

export const useInvoicesContext = (): IInvoicesContext => {
  const context = useContext(InvoicesContext);

  if (!context) {
    throw new Error('useInvoices must be used within a InvoicesProvider');
  }

  return context;
};
