import {
  IBudgetReportQuestionTypeEnum,
  IBudgetReportTypeEnum,
  IReportDamageParts,
  IReportRescuedParts,
} from 'commom/types/BudgetReport.types';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';
import { SubtitleColor } from 'components/Dialog/ConfirmDialog/ConfirmDialog.types';
import { useAppDispatch } from 'hooks/hooks';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import BudgetReportService from 'services/ApiService/BudgetReportService/BudgetReportService';
import {
  setDamageQuestionIdsUnanswered,
  setDamageReport,
  setRescuedQuestionIdsUnanswered,
  setRescuedReport,
} from 'stores/presentBudget/presentBudget.store';
import * as S from './FinishReportButton.styles';
import { IFinishReportButtonProps, IUnansweredQuestions } from './FinishReportButton.types';

const FinishReportButton = ({ report }: IFinishReportButtonProps) => {
  const [finishDialogVisibility, setFinishDialogVisibility] = useState(false);
  const [editDialogVisibility, setEditDialogVisibility] = useState(false);
  const dispatch = useAppDispatch();

  const validateVehicleEvaluation = useCallback((report: IReportRescuedParts) => {
    const { withoutEngineAccess, generalEvaluation, engineNumber, justifications } = report.vehicleEvaluation;

    if (generalEvaluation === null || justifications?.trim() === '') {
      return false;
    }

    if (!withoutEngineAccess && (!engineNumber || engineNumber.trim() === '')) {
      return false;
    }

    return true;
  }, []);

  const handleToggleDraftState = useCallback(async () => {
    if (report.isDraft) {
      if (report.type === IBudgetReportTypeEnum.RESCUED) {
        const isValid = validateVehicleEvaluation(report as IReportRescuedParts);

        if (!isValid) {
          toast.error('Preencha todas as informações de avaliação do veículo antes de concluir o laudo');
          return;
        }
      }
    }

    try {
      const data = await BudgetReportService.toggleDraftState(report._id);

      if (report.type === IBudgetReportTypeEnum.RESCUED) {
        dispatch(setRescuedReport(data as IReportRescuedParts));
        dispatch(setRescuedQuestionIdsUnanswered(null));
      } else {
        dispatch(setDamageReport(data as IReportDamageParts));
        dispatch(setDamageQuestionIdsUnanswered(null));
      }

      toast.success('Laudo atualizado com sucesso');
    } catch (error) {
      toast.error('Erro ao atualizar o laudo');
    }
  }, [dispatch, report, validateVehicleEvaluation]);

  const openFinishDialog = () => {
    setFinishDialogVisibility(true);
  };

  const closeFinishDialog = () => {
    setFinishDialogVisibility(false);
  };

  const openEditDialog = () => {
    setEditDialogVisibility(true);
  };

  const closeEditDialog = () => {
    setEditDialogVisibility(false);
  };

  const findUnansweredQuestions = (): IUnansweredQuestions => {
    const unansweredQuestionIds = report.questionsGroups.flatMap((group) =>
      group.questions
        .filter((question) =>
          question.type === IBudgetReportQuestionTypeEnum.SELECT
            ? !question.answerId
            : !question.answerId || !question.textAnswer?.trim()
        )
        .map((question) => question._id)
    );

    return {
      isUnansweredQuestions: unansweredQuestionIds.length > 0,
      questionIdsUnanswered: unansweredQuestionIds,
    };
  };

  const setQuestionIdsUnanswered =
    report.type === IBudgetReportTypeEnum.RESCUED ? setRescuedQuestionIdsUnanswered : setDamageQuestionIdsUnanswered;

  const handleUnansweredQuestions = () => {
    if (report.isDraft) {
      const { isUnansweredQuestions, questionIdsUnanswered } = findUnansweredQuestions();

      if (isUnansweredQuestions) {
        toast.error('Preencha todas as perguntas antes de concluir o laudo');
        dispatch(setQuestionIdsUnanswered(questionIdsUnanswered));
        return false;
      }
    }

    return true;
  };

  const confirmFinishReport = async () => {
    if (!handleUnansweredQuestions()) return;

    await handleToggleDraftState();
    closeFinishDialog();
  };

  const confirmEditReport = async () => {
    if (!handleUnansweredQuestions()) return;

    await handleToggleDraftState();
    closeEditDialog();
  };

  return (
    <>
      {report.isDraft ? (
        <>
          <S.Button
            variant="contained"
            type="button"
            fullWidth={false}
            size="small"
            testID="open-finish-dialog-button"
            text="Concluir"
            onClick={openFinishDialog}
          />
          <ConfirmDialog
            dialogVisibility={finishDialogVisibility}
            handleClose={closeFinishDialog}
            onSubmit={confirmFinishReport}
            title="Concluir laudo"
            subtitle="Deseja concluir o laudo? Após concluir, você poderá imprimi-lo."
            testID="confirm-finish-dialog"
            subtitleColor={SubtitleColor.BLACK}
            confirmText="Concluir"
            cancelText="Cancelar"
          />
        </>
      ) : (
        <>
          <S.Button
            variant="contained"
            type="button"
            fullWidth={false}
            size="small"
            testID="open-edit-dialog-button"
            text="Editar"
            onClick={openEditDialog}
          />
          <ConfirmDialog
            dialogVisibility={editDialogVisibility}
            handleClose={closeEditDialog}
            onSubmit={confirmEditReport}
            title="Editar laudo"
            subtitle="Deseja editar o laudo?"
            testID="confirm-edit-dialog"
            subtitleColor={SubtitleColor.BLACK}
            confirmText="Editar"
            cancelText="Cancelar"
          />
        </>
      )}
    </>
  );
};

export default FinishReportButton;
