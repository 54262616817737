import { Label } from 'components/Label';
import { TextField } from 'components/TextField';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import React, { useEffect } from 'react';
import NumericFormat from 'react-number-format';
import { GroupInputRow, InputWrapper } from './PartDrawer.styles';

const ManuallyPartPrice = () => {
  const { partDiscount, partOriginalPrice, setPartOriginalPrice, setPartDiscount, setPartPrice } = usePartContext();

  const netPrice = partOriginalPrice - (partOriginalPrice * partDiscount) / 100;

  const isPercentageValueAllowed = (floatValue: number | undefined): boolean => {
    return floatValue === undefined ? true : floatValue <= 100;
  };

  useEffect(() => {
    setPartPrice(netPrice);
  }, [netPrice, setPartPrice]);

  return (
    <>
      <GroupInputRow>
        <InputWrapper>
          <Label htmlFor="input-original-price-value">Valor original</Label>
          <NumericFormat
            id="input-original-price-value"
            type="text"
            name="Price"
            placeholder="Digite o valor original da peça"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="R$ "
            customInput={TextField}
            allowNegative={false}
            value={partOriginalPrice}
            onValueChange={({ floatValue }) => setPartOriginalPrice(floatValue || 0)}
            {...{ variant: 'outlined' }}
          />
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="input-price-value">Desconto</Label>
          <NumericFormat
            id="input-price-value"
            placeholder="Insira o valor do desconto"
            prefix="% "
            allowNegative={false}
            customInput={TextField}
            isAllowed={({ floatValue }) => isPercentageValueAllowed(floatValue)}
            value={partDiscount}
            onValueChange={({ floatValue }) => setPartDiscount(floatValue || 0)}
            {...{ variant: 'outlined' }}
          />
        </InputWrapper>
      </GroupInputRow>

      <InputWrapper>
        <Label htmlFor="input-part-price-value">Valor líquido da peça</Label>
        <NumericFormat
          id="input-part-price-value"
          prefix="R$ "
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          disabled
          fixedDecimalScale={true}
          allowNegative={false}
          customInput={TextField}
          value={netPrice}
          {...{ variant: 'outlined' }}
        />
      </InputWrapper>
    </>
  );
};

export default ManuallyPartPrice;
