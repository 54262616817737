import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Label, Button } from 'components';
import { AnchorTypesEnum } from 'components/Drawer';
import CloseIcon from 'assets/svg/close-icon.svg';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import DocumentService from 'services/DocumentService/documentService';
import * as S from './MarkInvoiceAsPaidDrawer.styles';
import InvoicesService from 'services/ApiService/InvoicesService/invoicesService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useMutateInvoices } from 'hooks/Invoices/InvoicesHook';
import { IInvoiceWithExpenses } from 'commom/types/Invoices.types';

interface IMarkInvoiceAsPaidDrawerProps {
  open: boolean;
  onClose: () => void;
  invoice: IInvoiceWithExpenses;
}

const MarkInvoiceAsPaidDrawer = ({ open, onClose, invoice }: IMarkInvoiceAsPaidDrawerProps) => {
  const [dateSelected, setDateSelected] = useState<string>('');
  const [files, setFiles] = useState<ImageListType>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { invalidateInvoicesExpenses } = useMutateInvoices();

  const onFileChange = (files: ImageListType) => {
    setFiles(files);
  };

  const renderFileIcon = (document: string) => {
    const docType = document.split('.')[1];
    const icon = DocumentService.documentIcon(docType);

    return <S.FileIcon data-testid="icon-doc" src={icon} />;
  };

  const getTodayDate = () => {
    const todayDate = new Date();
    return moment(todayDate).format('YYYY-MM-DD');
  };

  const limitDateRange = () => {
    const dateInput = document.getElementById('datepicker');
    dateInput?.setAttribute('max', getTodayDate());
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    try {
      setLoading(true);
      const paymentDate = new Date(dateSelected).toISOString();
      await InvoicesService.sendInvoiceDocumentPaid(invoice, paymentDate, files[0]);
      await invalidateInvoicesExpenses();
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      toast.error('Falha ao enviar comprovante de pagamento');
    }
  };

  const disableSaveButton = !dateSelected || !files.length || loading;

  return (
    <S.Container openDrawer={open} anchor={AnchorTypesEnum.RIGHT} onClose={onClose}>
      <S.Header>
        <S.Title>Marcar como pago</S.Title>
        <S.Description>Preencha os campos abaixo para marcar a cobrança como paga.</S.Description>
      </S.Header>
      <S.Content>
        <>
          <Label htmlFor="datepicker" testID="label-datepicker">
            Data de pagamento
          </Label>
          <S.DatePicker
            type="date"
            id="datepicker"
            name="datepicker"
            role="datepicker"
            variant="outlined"
            ariaLabelledby="datepicker"
            placeholder="Insira a data de pagamento"
            onClick={limitDateRange}
            onKeyDown={(event: KeyboardEvent) => event.preventDefault()}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => {
              setDateSelected(target.value);
            }}
            inputProps={{ 'data-testid': 'date-picker' }}
          />
        </>

        <ImageUploading value={files} onChange={onFileChange} allowNonImageType acceptType={['pdf', 'jpg', 'png']}>
          {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors }) => (
            <>
              <S.DragAndDropArea
                data-testid="drag-drop-area"
                onClick={onImageUpload}
                isDragging={isDragging}
                {...dragProps}
              >
                <p>Clique aqui ou arraste o documento</p>
              </S.DragAndDropArea>

              {errors?.acceptType && (
                <S.ErrorMessage>* Tipo de arquivo não suportado, use apenas PDF, JPG ou PNG</S.ErrorMessage>
              )}

              <S.FileList>
                {imageList.map(
                  (document, index) =>
                    document.dataURL && (
                      <S.FileCard key={`${document.dataURL},${index}`}>
                        <S.FileWrapper>
                          {renderFileIcon(document.file?.name || '')}
                          <Tooltip title={document.file?.name || ''} placement="top">
                            <S.FileName>{document.file?.name}</S.FileName>
                          </Tooltip>
                        </S.FileWrapper>
                        <Tooltip title="Excluir" placement="top">
                          <S.DeleteButton type="button" onClick={() => onImageRemove(index)}>
                            <img src={CloseIcon} alt="Ícone de excluir" />
                          </S.DeleteButton>
                        </Tooltip>
                      </S.FileCard>
                    )
                )}
              </S.FileList>
            </>
          )}
        </ImageUploading>
      </S.Content>
      <S.Footer>
        <Button disabled={loading} onClick={onClose} text="Cancelar" variant="outlined" size="large" />
        <Button
          disabled={disableSaveButton}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e)}
          text="Marcar"
          size="large"
          testID="mark-as-paid-button"
          loading={loading}
          loadingSize={24}
        />
      </S.Footer>
    </S.Container>
  );
};

export default MarkInvoiceAsPaidDrawer;
