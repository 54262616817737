import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  min-height: 60px;

  &:first-child {
    border-radius: 4px;
    background-color: #f5f5f5;
  }
`;

export const ServiceName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-wrap: wrap;
  padding: 0 10px;

  strong {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 12px;
    color: #000;
  }
`;

export const ServiceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  gap: 4px;

  padding: 0px 20px;
`;

export const Title = styled.div`
  color: #6a758b;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 600;
`;

export const Subtitle = styled.div`
  color: #000;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
`;
