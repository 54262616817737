import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React from 'react';
import { setCurrentStep } from 'stores/presentBudget/presentBudget.store';
import { BudgetsSteps } from 'stores/presentBudget/presentBudget.types';

import conclusionIcon from 'assets/svg/budget-steps/conclusion-icon.svg';
import initialDataIcon from 'assets/svg/budget-steps/initial-data-icon.svg';
import partsAndServicesIcon from 'assets/svg/budget-steps/parts-and-services-icon.svg';
import warningIcon from 'assets/svg/budget-steps/warning-icon.svg';
import reportIcon from 'assets/svg/budget-steps/report-icon.svg';
import { useFeatureToggles } from 'contexts/featureToggles/useFeatureToggles';
import { useAuthDispatch, useAuthState } from 'contexts/auth/useAuth';
import { StepButton, StepsContainer } from './Steps.styles';
import {
  selectCurrentStatus,
  selectCurrentStep,
  selectDamageReportIsDraft,
  selectIsTotalLoss,
  selectRescuedReportIsDraft,
} from 'stores/presentBudget/presentBudget.selector';
import { selectCurrentResponsible } from 'stores/presentInspection/presentInspection.selector';
import { BudgetStatusCodeEnum } from 'commom/types/Budget.types';
import { FeatureTogglesEnum } from 'services/ApiService/FeatureToggles/types';

const Steps = () => {
  const currentStep = useAppSelector(selectCurrentStep);
  const currentStatus = useAppSelector(selectCurrentStatus);
  const isTotalLoss = useAppSelector(selectIsTotalLoss);
  const currentResponsible = useAppSelector(selectCurrentResponsible);
  const rescuedReportIsDraft = useAppSelector(selectRescuedReportIsDraft);
  const damageReportIsDraft = useAppSelector(selectDamageReportIsDraft);
  const { account } = useAuthState();
  const { isAdmin } = useAuthDispatch();
  const dispatch = useAppDispatch();
  const { isFeatureToggleActive } = useFeatureToggles();

  const isResponsibleOrAdmin = currentResponsible?.id === account?.id || isAdmin();

  const isbudgetFinished = currentStatus.status.code === BudgetStatusCodeEnum.FINISHED;

  const isActived = (step: BudgetsSteps) => {
    return currentStep === step;
  };

  const setStepInUrl = (step: BudgetsSteps) => {
    const url = new URL(window.location.href);
    url.searchParams.set('currentStep', step);
    window.history.pushState({}, '', url.toString());
  };

  const handleStep = (step: BudgetsSteps) => {
    setStepInUrl(step);
    dispatch(setCurrentStep(step));
  };

  return (
    <StepsContainer>
      {isResponsibleOrAdmin && !isbudgetFinished && (
        <>
          <StepButton type="button" isActive={isActived('INITIAL_DATA')} onClick={() => handleStep('INITIAL_DATA')}>
            <img src={initialDataIcon} alt="Dados iniciais" />
            Dados iniciais
          </StepButton>
          <StepButton
            type="button"
            isActive={isActived('PARTS_AND_SERVICES')}
            onClick={() => handleStep('PARTS_AND_SERVICES')}
          >
            <img src={partsAndServicesIcon} alt="Peças & Serviços" />
            Peças & Serviços
          </StepButton>
        </>
      )}

      {!isbudgetFinished && (
        <StepButton type="button" isActive={isActived('CONCLUSION')} onClick={() => handleStep('CONCLUSION')}>
          <img src={conclusionIcon} alt="Conclusão" />
          Conclusão
        </StepButton>
      )}

      <StepButton type="button" isActive={isActived('REPORT')} onClick={() => handleStep('REPORT')}>
        <img src={reportIcon} alt="Relatório" />
        Relatório
      </StepButton>

      {isFeatureToggleActive(FeatureTogglesEnum.SHOW_BUDGET_FEATURES) && isTotalLoss && (
        <StepButton type="button" isActive={isActived('RESCUED_REPORT')} onClick={() => handleStep('RESCUED_REPORT')}>
          <img src={reportIcon} alt="Laudo de Salvados" />
          Laudo de Salvados
          {rescuedReportIsDraft && <img src={warningIcon} alt="Warning icon" />}
        </StepButton>
      )}

      {isFeatureToggleActive(FeatureTogglesEnum.SHOW_BUDGET_FEATURES) && isTotalLoss && (
        <StepButton type="button" isActive={isActived('DAMAGE_REPORT')} onClick={() => handleStep('DAMAGE_REPORT')}>
          <img src={reportIcon} alt="Laudo de Danos" />
          Laudo de Danos
          {damageReportIsDraft && <img src={warningIcon} alt="Warning icon" />}
        </StepButton>
      )}
    </StepsContainer>
  );
};

export default Steps;
