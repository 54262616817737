import styled from 'styled-components';
import DoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import DoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

export const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  cursor: pointer;

  :hover {
    color: #6a758b;
  }

  :active {
    color: #000;
  }
`;

export const Text = styled.span`
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #e8e8e8;

  &[data-isopen='0'] {
    :hover {
      transform: scale(1.2);
      transition: 0.3s;
    }
  }
`;

export const ArrowBack = styled(DoubleArrowLeft)`
  font-size: 16px !important;
`;

export const ArrowForward = styled(DoubleArrowRight)`
  font-size: 16px !important;
`;
