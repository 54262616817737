import styled from 'styled-components';

interface IPriceHighlighted {
  green?: boolean;
}

interface INetHighlighted {
  gray?: boolean;
  net?: boolean;
}

export const Container = styled.div`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 8px 30px;
  border-top: 1px solid #ced6e5;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HighlightedContainer = styled.div<INetHighlighted>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 5px 10px;
  margin-top: ${({ net }) => (net ? '15px' : '0px')};
  border-radius: 6px;
  background-color: #f1f1f1;
  background-color: ${({ gray }) => (gray ? '#D8D8D8' : '#f1f1f1')};
`;

export const OverviewContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

export const OverviewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: #00000080;
`;

export const Subtitle = styled.span`
  display: flex;
  gap: 5px;

  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: #000000;

  svg {
    cursor: pointer;
    font-size: 14px;
    color: #00000080;
  }
`;

export const Operand = styled.b`
  align-self: center;
`;

export const Price = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #000;
`;

export const PriceHighlighted = styled.span<IPriceHighlighted>`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  padding: 2px 3px;
  border-radius: 4px;
  color: #fff;
  background: ${({ green }) => (green ? '#099857' : '#ef6767')};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 1400px) {
    flex-direction: column-reverse;
  }
`;
