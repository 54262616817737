/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import NumericFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { BackButton, Button, Label, TextField } from 'components';
import { Drawer, AnchorTypesEnum } from 'components/Drawer';
import { formValidations } from 'utils/form/validations';
import { useDebounce } from 'hooks/Debounce/DebounceHook';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import VehicleService from 'services/ApiService/VehicleService/vehicleService';
import {
  IVersion,
  IVehicle,
  IVehicleRequest,
  PaintingTypeEnum,
  IVehicleBaseResponse,
  IPaintingTypeBaseResponse,
} from 'services/ApiService/VehicleService/vehicleService.types';
import { IOptions } from 'components/Select/Select.types';
import { IVehicleDrawer, IVersionOptions, IFormSchemaType } from './VehicleDrawer.types';
import * as S from './VehicleDrawer.styles';

const inputProps = {
  plate: {
    'data-testid': 'input-plate',
  },
  chassis: {
    'data-testid': 'input-chassis',
  },
  fipeCode: {
    'data-testid': 'input-fipe-code',
  },
  fipePrice: {
    'data-testid': 'input-fipe-price',
  },
};

const VehicleDrawer = ({ inspection, isEditMode = false, openDrawer, onCloseDrawer }: IVehicleDrawer) => {
  const { id: inspectionId, product, companyId } = inspection;

  const [brandValue, setBrandValue] = useState('');
  const [modelValue, setModelValue] = useState('');
  const [versionValue, setVersionValue] = useState('');
  const [vehicleTypesLoading, setVehicleTypesLoading] = useState<boolean>(false);
  const [paintingTypesLoading, setPaintingTypesLoading] = useState<boolean>(false);
  const [brandLoading, setBrandLoading] = useState<boolean>(false);
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [versionLoading, setVersionLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [vehicleTypes, setVehicleTypes] = useState<IOptions[]>([]);
  const [paintingTypes, setPaintingTypes] = useState<IOptions[]>([]);
  const [brandOptions, setBrandOptions] = useState<IOptions[]>([]);
  const [modelOptions, setModelOptions] = useState<IOptions[]>([]);
  const [versionOptions, setVersionOptions] = useState<IVersionOptions[]>([]);
  const [modelYearOptions, setModelYearOptions] = useState<IOptions[]>([]);
  const [vehicleTypeSelected, setVehicleTypeSelected] = useState<string>('');
  const [paintingTypeSelected, setPaintingTypeSelected] = useState<string>('');
  const [brandSelected, setBrandSelected] = useState<IOptions | null>(null);
  const [modelSelected, setModelSelected] = useState<IOptions | null>(null);
  const [versionSelected, setVersionSelected] = useState<IVersionOptions | null>(null);
  const [modelYearSelected, setModelYearSelected] = useState<string>('');
  const { invalidateInspectionParallel } = useMutateInspection();

  const { register, errors, handleSubmit, control, getValues } = useForm<IFormSchemaType>({
    mode: 'onChange',
  });

  const noInputChanged = () => {
    if (isEditMode && product?.vehicle) {
      const { vehicle } = product;
      return (
        vehicle.brandId === brandSelected?.value &&
        vehicle.modelId === modelSelected?.value &&
        vehicle.versionId === versionSelected?.value &&
        vehicle.modelYear === modelYearSelected &&
        vehicle.typeId === vehicleTypeSelected &&
        vehicle.paintingType === paintingTypeSelected &&
        vehicle.plate === getValues().plate &&
        vehicle.chassis === getValues().chassis &&
        vehicle.fipeInfo?.fipeCode === getValues().fipeCode &&
        vehicle.fipeInfo?.currentPrice === getValues().fipePrice
      );
    }

    return false;
  };

  const isFormIncomplete = Object.values(getValues()).some((value) => !value);

  const handleDisabled =
    !vehicleTypeSelected ||
    !brandSelected ||
    !modelSelected ||
    !versionSelected ||
    !modelYearSelected ||
    !paintingTypeSelected ||
    isFormIncomplete ||
    noInputChanged();

  const brandDebounceSearch = useDebounce(brandValue);
  const modelDebounceSearch = useDebounce(modelValue);
  const versionDebounceSearch = useDebounce(versionValue);

  const handleInputState = (name: keyof IFormSchemaType): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: keyof IFormSchemaType): boolean => {
    return errors[inputName] !== undefined;
  };

  const convertToOptions = useCallback((items: IVehicleBaseResponse[]) => {
    return items.map(({ id, description }) => ({
      value: id,
      name: description,
    }));
  }, []);

  const paintingTypesToOptions = useCallback((items: IPaintingTypeBaseResponse[]) => {
    return items.map(({ code, name }) => ({
      value: code,
      name,
    }));
  }, []);

  const versionsToOptions = useCallback((items: IVersion[]) => {
    return items.map(({ id, description, startYear, endYear }) => ({
      value: id,
      name: description,
      startyear: startYear,
      endyear: endYear,
    }));
  }, []);

  const dateRangeToOptions = useCallback((startyear: number, endyear: number) => {
    const options = [];
    for (let i = startyear; i <= endyear; i++) {
      options.push({ name: i.toString(), value: i.toString() });
    }
    return options;
  }, []);

  const handleVehicleTypes = async () => {
    try {
      setVehicleTypesLoading(true);
      const { types } = await VehicleService.getVehicleTypes();
      const options = convertToOptions(types);
      setVehicleTypes(options);
      const vehicleType = options.find(({ value }) => value === product?.vehicle?.typeId);
      setVehicleTypeSelected(vehicleType?.value || '');
    } catch (error) {
      toast.error('Erro ao buscar tipos de veículos.');
    } finally {
      setVehicleTypesLoading(false);
    }
  };

  const handlePaintingTypes = async () => {
    try {
      setPaintingTypesLoading(true);
      const response = await VehicleService.getPaintingTypes(companyId || '');
      const options = paintingTypesToOptions(response.paintingTypes);
      setPaintingTypes(options);
      const paintingType = options.find(({ value }) => value === product?.vehicle?.paintingType);
      setPaintingTypeSelected(paintingType?.value || '');
    } catch (error) {
      toast.error('Erro ao buscar tipos de pinturas.');
    } finally {
      setPaintingTypesLoading(false);
    }
  };

  const handleModelYears = ({ startyear, endyear }: IVersionOptions) => {
    const dateRange = dateRangeToOptions(startyear, endyear);
    setModelYearOptions(dateRange);
  };

  const handleBrandSearch = async (search: string) => {
    try {
      setBrandLoading(true);
      const { brands } = await VehicleService.getVehicleBrands(search);
      const options = convertToOptions(brands);
      setBrandOptions(options);
    } catch (error) {
      toast.error('Erro ao buscar marcas de veículos.');
    } finally {
      setBrandLoading(false);
    }
  };

  const handleBrandDebounceSearch = useCallback(() => {
    if (brandDebounceSearch && brandSelected === null) {
      handleBrandSearch(brandDebounceSearch);
    }
  }, [brandDebounceSearch]);

  const handleModelSearch = async (search: string) => {
    try {
      setModelLoading(true);
      const { models } = await VehicleService.getVehicleModels(search, brandSelected?.value || '');
      const options = convertToOptions(models);
      setModelOptions(options);
    } catch (error) {
      toast.error('Erro ao buscar modelos de veículos.');
    } finally {
      setModelLoading(false);
    }
  };

  const handleModelDebounceSearch = useCallback(() => {
    if (modelDebounceSearch && modelSelected === null && brandSelected?.value) {
      handleModelSearch(modelDebounceSearch);
    }
  }, [modelDebounceSearch, brandSelected?.value]);

  const handleVersionSearch = async (search: string) => {
    try {
      setVersionLoading(true);
      const { versions } = await VehicleService.getVehicleVersions(search, modelSelected?.value || '');
      const options = versionsToOptions(versions);
      setVersionOptions(options);
    } catch (error) {
      toast.error('Erro ao buscar versões do veículo.');
    } finally {
      setVersionLoading(false);
    }
  };

  const handleVersionDebounceSearch = useCallback(() => {
    if (versionDebounceSearch && versionSelected === null && modelSelected?.value) {
      handleVersionSearch(versionDebounceSearch);
    }
  }, [versionDebounceSearch, modelSelected?.value]);

  const handleBrandSelected = (__: React.ChangeEvent<{}>, newValue: unknown) => {
    setBrandLoading(false);
    const selectedOpt = newValue as IOptions | undefined;
    setBrandSelected(selectedOpt || null);
    resetInputsExceptBrand();
  };

  const handleModelSelected = (__: React.ChangeEvent<{}>, newValue: unknown) => {
    setModelLoading(false);
    const selectedOpt = newValue as IOptions | undefined;
    setModelSelected(selectedOpt || null);
    resetInputsExceptModel();
  };

  const handleVersionSelected = (__: React.ChangeEvent<{}>, newValue: unknown) => {
    setVersionLoading(false);
    const selectedOpt = newValue as IVersionOptions | undefined;
    setVersionSelected(selectedOpt || null);
    resetInputsExceptVersion();
    if (selectedOpt) {
      handleModelYears(selectedOpt);
    }
  };

  const onVehicleSubmit = async ({ plate, chassis, fipeCode, fipePrice }: IFormSchemaType) => {
    try {
      setSubmitLoading(true);

      const vehicleRequest: IVehicleRequest = {
        inspectionId,
        plate,
        chassis: chassis.toUpperCase(),
        model: modelSelected?.name || '',
        modelId: modelSelected?.value || '',
        brand: brandSelected?.name || '',
        brandId: brandSelected?.value || '',
        version: versionSelected?.name || '',
        versionId: versionSelected?.value || '',
        modelYear: modelYearSelected,
        type: vehicleTypes.find(({ value }) => value === vehicleTypeSelected)?.name || '',
        typeId: vehicleTypeSelected,
        fipeInfo: {
          fipeCode,
          currentPrice: fipePrice,
          origin: 'USER',
        },
        paintingType: paintingTypeSelected as PaintingTypeEnum,
        paintingDescription: paintingTypes.find(({ value }) => value === paintingTypeSelected)?.name || '',
      };

      await VehicleService.saveVehicle(vehicleRequest);
      invalidateInspectionParallel(inspectionId);

      toast.success(`Veículo ${isEditMode ? 'editado' : 'cadastrado'} com sucesso!`);
      setSubmitLoading(false);
      onCloseDrawer();
    } catch (error) {
      setSubmitLoading(false);
      toast.error(`Erro ao ${isEditMode ? 'editar' : 'cadastrar'} veículo.`);
    }
  };

  const resetInputsExceptBrand = () => {
    setModelSelected(null);
    setVersionSelected(null);
    setModelYearSelected('');
    setModelOptions([]);
    setVersionOptions([]);
    setModelYearOptions([]);
  };

  const resetInputsExceptModel = () => {
    setVersionSelected(null);
    setModelYearSelected('');
    setVersionOptions([]);
    setModelYearOptions([]);
  };

  const resetInputsExceptVersion = () => {
    setModelYearSelected('');
    setModelYearOptions([]);
  };

  const initializeModelYear = async (selectedVersion: IVersionOptions) => {
    handleModelYears(selectedVersion);
    const modelYear = selectedVersion.startyear.toString();
    setModelYearSelected(modelYear || '');
  };

  const initializeVersion = (vehicle: IVehicle) => {
    if (vehicle.versionId) {
      const selectedVersion = {
        value: vehicle.versionId,
        name: vehicle.version || '',
        startyear: Number(vehicle.modelYear),
        endyear: Number(vehicle.modelYear),
      };
      setVersionSelected(selectedVersion);
      initializeModelYear(selectedVersion);
    }
  };

  const initializeModel = (vehicle: IVehicle) => {
    if (vehicle.modelId) {
      setModelSelected({ value: vehicle.modelId, name: vehicle.model || '' });
      initializeVersion(vehicle);
    }
  };

  const initializeBrand = (vehicle: IVehicle) => {
    if (vehicle.brandId) {
      setBrandSelected({ value: vehicle.brandId, name: vehicle.brand || '' });
      initializeModel(vehicle);
    }
  };

  useEffect(() => {
    if (product?.vehicle) {
      const { vehicle } = product;
      initializeBrand(vehicle);
    }
  }, [product]);

  useEffect(() => {
    handleVehicleTypes();
    handlePaintingTypes();
  }, []);

  useEffect(() => {
    handleBrandDebounceSearch();
  }, [handleBrandDebounceSearch]);

  useEffect(() => {
    handleModelDebounceSearch();
  }, [handleModelDebounceSearch]);

  useEffect(() => {
    handleVersionDebounceSearch();
  }, [handleVersionDebounceSearch]);

  return (
    <Drawer.Root testId="vehicle-drawer" anchor={AnchorTypesEnum.RIGHT} openDrawer={openDrawer} onClose={onCloseDrawer}>
      <S.Header>
        <BackButton onClick={onCloseDrawer} />
        <Drawer.Title>{`${isEditMode ? 'Editar' : 'Cadastrar'} veículo`}</Drawer.Title>
      </S.Header>

      <S.FormContent id="form-create-vehicle" onSubmit={handleSubmit(onVehicleSubmit)}>
        <div>
          <Label htmlFor="plate" testID="label-plate">
            Placa
          </Label>
          <Controller
            name="plate"
            control={control}
            defaultValue={product?.plate || ''}
            rules={formValidations.plate}
            render={({ onChange, value }) => (
              <TextField
                ariaLabelledby="plate"
                id="plate"
                type="text"
                name="plate"
                role="plate"
                variant="outlined"
                placeholder="Digite a placa do veículo"
                mask="aaa0*00"
                maxLength="7"
                minLength="0"
                value={value}
                inputProps={inputProps?.plate}
                helperText={errors?.plate?.message}
                onChange={onChange}
                error={hasError('plate')}
                state={handleInputState('plate')}
                inputRef={register(formValidations?.plate)}
                prepare={(char: string): string => char.toUpperCase()}
              />
            )}
          />
        </div>

        <div>
          <Label htmlFor="chassis" testID="label-chassis">
            Chassi
          </Label>
          <Controller
            name="chassis"
            control={control}
            rules={formValidations.default}
            defaultValue={product?.vehicle?.chassis?.toUpperCase() || ''}
            render={({ onChange, value }) => (
              <TextField
                ariaLabelledby="chassis"
                id="chassis"
                type="text"
                name="chassis"
                variant="outlined"
                placeholder="Digite o chassi do veículo"
                value={value.toUpperCase()}
                inputProps={inputProps?.chassis}
                helperText={errors?.chassis?.message}
                onChange={onChange}
                error={hasError('chassis')}
                state={handleInputState('chassis')}
              />
            )}
          />
        </div>

        <S.Select
          testID="select-vehicle-type"
          placeholder={vehicleTypesLoading ? 'Carregando...' : 'Selecione o tipo do veículo'}
          disabled={vehicleTypesLoading}
          options={vehicleTypes}
          selectedOptions={vehicleTypeSelected}
          required
          label="Tipo"
          labelId="type"
          onChange={({ target: { value } }): void => setVehicleTypeSelected(value as string)}
        />

        <S.Autocomplete
          label="Marca"
          placeholder="Digite a marca do veículo"
          loading={brandLoading}
          loadingText="Buscando..."
          options={brandOptions}
          value={brandSelected?.name || ''}
          onChange={handleBrandSelected}
          onInputChange={(__, newValue) => setBrandValue(newValue)}
        />

        <S.Autocomplete
          label="Modelo"
          placeholder="Digite o modelo do veículo"
          disabled={!brandSelected}
          loading={modelLoading}
          loadingText="Buscando..."
          options={modelOptions}
          value={modelSelected?.name || ''}
          onChange={handleModelSelected}
          onInputChange={(__, newValue) => setModelValue(newValue)}
        />

        <S.Autocomplete
          label="Versão"
          placeholder="Digite a versão do veículo"
          disabled={!modelSelected}
          loading={versionLoading}
          loadingText="Buscando..."
          options={versionOptions}
          value={versionSelected?.name || ''}
          onChange={handleVersionSelected}
          onReset={() => setVersionSelected(null)}
          clearText="Limpar seleção"
          onInputChange={(__, newValue) => setVersionValue(newValue)}
        />

        <S.Select
          testID="select-model-year"
          placeholder="Selecione o ano"
          disabled={!versionSelected}
          options={modelYearOptions}
          selectedOptions={modelYearSelected}
          label="Ano"
          labelId="modelYear"
          onChange={({ target: { value } }): void => setModelYearSelected(value as string)}
        />

        <S.Select
          testID="select-painting-type"
          placeholder={paintingTypesLoading ? 'Carregando...' : 'Selecione o tipo de pintura'}
          disabled={paintingTypesLoading}
          options={paintingTypes}
          selectedOptions={paintingTypeSelected}
          required
          label="Pintura"
          labelId="paintingType"
          onChange={({ target: { value } }): void => setPaintingTypeSelected(value as PaintingTypeEnum)}
        />

        <div>
          <Label htmlFor="fipeCode" testID="label-fipeCode">
            Código FIPE
          </Label>
          <Controller
            name="fipeCode"
            control={control}
            rules={formValidations.fipeCode}
            defaultValue={product?.vehicle?.fipeInfo?.fipeCode || ''}
            render={({ onChange, value }) => (
              <TextField
                ariaLabelledby="fipeCode"
                id="fipeCode"
                type="text"
                name="fipeCode"
                variant="outlined"
                placeholder="Digite o código da FIPE"
                mask="0000000"
                value={value}
                inputProps={inputProps?.fipeCode}
                helperText={errors?.fipeCode?.message}
                onChange={onChange}
                error={hasError('fipeCode')}
                state={handleInputState('fipeCode')}
              />
            )}
          />
        </div>

        <div>
          <Label htmlFor="fipePrice" testID="label-fipePrice">
            Valor FIPE
          </Label>
          <Controller
            name="fipePrice"
            control={control}
            rules={formValidations.default}
            defaultValue={product?.vehicle?.fipeInfo?.currentPrice || ''}
            render={({ onChange, value }) => (
              <NumericFormat
                value={value}
                id="fipePrice"
                type="text"
                name="fipePrice"
                placeholder="Digite o valor da FIPE"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={TextField}
                allowNegative={false}
                onValueChange={({ floatValue }) => onChange(floatValue)}
                {...{
                  variant: 'outlined',
                  error: hasError('fipePrice'),
                  inputProps: inputProps?.fipePrice,
                  helperText: errors?.fipePrice?.message,
                  state: handleInputState('fipePrice'),
                }}
              />
            )}
          />
        </div>
      </S.FormContent>

      <S.Separator />
      <S.ActionsContainer>
        <Button variant="outlined" text="cancelar" onClick={onCloseDrawer} />
        <Button
          variant="contained"
          text="salvar"
          form="form-create-vehicle"
          type="submit"
          loading={submitLoading}
          disabled={handleDisabled}
        />
      </S.ActionsContainer>
    </Drawer.Root>
  );
};

export default VehicleDrawer;
