import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { Button as ButtonComponent } from 'components';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { ISubHeader } from './Inspection.types';

export const InspectionLink = styled.div`
  margin-bottom: 15px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const SubHeader = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: ${({ alert }: ISubHeader): string => (alert ? '#aa2b2b' : '#099857')};
  display: block;
`;

export const HeaderTitle = styled.div`
  width: 200px !important;
`;

export const HeaderSubtitle = styled.span`
  font-size: 14px;
`;

export const LinkButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ButtonLink = withTheme(styled(ButtonComponent)`
  && {
    width: 150px;
    height: 40px;
    font-size: 13px;
  }
`);

export const Body = styled.div`
  display: flex;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
`;

export const Label = styled.div`
  font-size: 12px;
  color: #6a758b;
  font-weight: 700;
  text-align: left;
  margin-bottom: 6px;
`;

export const Value = styled.div`
  font-size: 16px;
  color: #6a758b;
  font-weight: 500;
  text-align: left;
  word-wrap: break-word;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Row = styled.div`
  margin-bottom: 10px;
`;

export const ColumnLeft = styled.div`
  flex: 50%;
  margin-right: 15px;
`;

export const ColumnRight = styled.div`
  flex: 50%;
  margin-left: 5px;
`;

export const CollapseWrapper = styled.div`
  width: 100%;
`;

export const Responsible = styled.div`
  font-size: 14px;
  color: #6a758b;
  font-weight: 500;
  text-align: left;
  margin-top: 18px;
`;

export const IconsContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
  display: flex;
  gap: 8px;
`;

export const Badge = styled.img`
  width: 44px;
  margin-top: -7px;
  height: 65px;
  z-index: 99;
`;

export const NavTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
  popper: {
    top: '10px !important',
  },
}))(Tooltip);
