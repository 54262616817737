import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import MetricsService from 'services/ApiService/MetricsService/metricsService';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { GeneralMetricsCard } from './Card';
import { GeneralMetricsSkeletonLoading } from './GeneralMetricsSkeletonLoading';
import * as S from './GeneralMetrics.styles';

export const GeneralMetrics = () => {
  const [riskOfDelay, setRiskOfDelay] = useState<number>(0);
  const [inProgress, setInProgress] = useState<number>(0);
  const [cancelled, setCancelled] = useState<number>(0);
  const [notStarted, setNotStarted] = useState<number>(0);
  const [finished, setFinished] = useState<number>(0);
  const [averageTime, setAverageTime] = useState<string>('00:00');
  const [totalInspections, setTotalInspections] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const {
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    startDateSelected,
    productTypesSelected,
    regulatorSelected,
  } = useMetricsFilter();

  const getGeneralMetrics = useCallback(async () => {
    try {
      if (hasFilterData()) {
        setLoading(true);

        const responseGeneralMetrics = await MetricsService.getGeneralMetrics({
          companyIds: companiesSelected || [],
          createdAtGreaterThan: startDateSelected || '',
          createdAtLessThan: endDateSelected || '',
          inspectionCategoryIds: categoriesSelected || [],
          inspectionProductTypeIds: productTypesSelected || [],
          regulatorIds: [regulatorSelected?.value || ''],
        });
        setRiskOfDelay(responseGeneralMetrics.riskOfDelayCount);
        setInProgress(responseGeneralMetrics.inProgressCount);
        setCancelled(responseGeneralMetrics.cancelledCount);
        setNotStarted(responseGeneralMetrics.notStartedCount);
        setFinished(responseGeneralMetrics.finishedCount);
        setAverageTime(responseGeneralMetrics.averageTime);
        setTotalInspections(responseGeneralMetrics.totalCount);
      }
    } catch (error) {
      toast.error('Falha ao carregar as métricas gerais');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoriesSelected,
    companiesSelected,
    endDateSelected,
    productTypesSelected,
    regulatorSelected,
    startDateSelected,
  ]);

  const hasFilterData = () => {
    return companiesSelected?.length > 0 && startDateSelected !== '' && endDateSelected !== '';
  };

  useEffect(() => {
    getGeneralMetrics();
  }, [getGeneralMetrics]);

  return (
    <>
      {!loading ? (
        <S.Container>
          <S.Header>
            <S.TitleWrapper>
              <S.Title>Métricas Gerais</S.Title>
              <S.Subtitle>Atendimentos atualizados automaticamente</S.Subtitle>
            </S.TitleWrapper>
            <S.ActionsWrapper>
              <S.ShowDetails to="/metrics-details">
                <RemoveRedEyeIcon sx={{ width: '14px' }} />
                Ver detalhes
              </S.ShowDetails>
            </S.ActionsWrapper>
          </S.Header>
          <S.Content>
            <GeneralMetricsCard.Root backgroundColor="#fff7f8" borderColor="#f67228">
              <GeneralMetricsCard.Icon
                tooltipText="Atendimentos criados há mais de 24h e não finalizados"
                color="#f67228"
              >
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Risco de atraso</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#f67228">{riskOfDelay}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>

            <GeneralMetricsCard.Root backgroundColor="#F3E8FF" borderColor="#BF83FF">
              <GeneralMetricsCard.Icon tooltipText="Atendimentos ainda não iniciados por um perito" color="#9F6CD5">
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Não iniciados</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#9F6CD5">{notStarted}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>

            <GeneralMetricsCard.Root backgroundColor="#FFFBF4" borderColor="#ECAF35">
              <GeneralMetricsCard.Icon tooltipText="Atendimentos já iniciados por um perito" color="#ECAF35">
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Em andamento</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#ECAF35">{inProgress}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>

            <GeneralMetricsCard.Root backgroundColor="#DCFCE7" borderColor="#099857">
              <GeneralMetricsCard.Icon tooltipText="Atendimentos com processos finalizados" color="#62B87F">
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Finalizados</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#62B87F">{finished}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>

            <GeneralMetricsCard.Root backgroundColor="#FFF0EC" borderColor="#EE5D50">
              <GeneralMetricsCard.Icon
                tooltipText="Atendimentos expirados ou cancelados por algum motivo"
                color="#EE5D50"
              >
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Cancelados</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#EE5D50">{cancelled}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>

            <GeneralMetricsCard.Root backgroundColor="#E1FFFF" borderColor="#00C6C6">
              <GeneralMetricsCard.Icon
                tooltipText="Somatório dos atendimentos não iniciados, em andamento, finalizados e cancelados"
                color="#239C9C"
              >
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Total de atendimentos</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#239C9C">{totalInspections}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>

            <GeneralMetricsCard.Root backgroundColor="#feffe1" borderColor="#c6b900">
              <GeneralMetricsCard.Icon
                tooltipText="Tempo médio que um atendimento demora para ser finalizado"
                color="#9c7c23"
                placement="top-end"
              >
                <InfoIcon fontSize="small" sx={{ width: '16px' }} />
              </GeneralMetricsCard.Icon>
              <GeneralMetricsCard.Title>Tempo médio (horas)</GeneralMetricsCard.Title>
              <GeneralMetricsCard.Data color="#9c7c23">{averageTime}</GeneralMetricsCard.Data>
            </GeneralMetricsCard.Root>
          </S.Content>
        </S.Container>
      ) : (
        <S.Container>
          <GeneralMetricsSkeletonLoading />
        </S.Container>
      )}
    </>
  );
};
