import React, { useCallback, useEffect } from 'react';
import { useMetricsFilter } from 'contexts/metricsFilter/useMetricsFilter';
import AsyncAutocomplete from 'components/AsyncAutocomplete/AsyncAutocomplete';
import { IOptions } from 'components/Select/Select.types';
import AccountService from 'services/ApiService/AccountService';
import { useDebounce } from 'hooks/Debounce/DebounceHook';
import { DateRangeStateType, DateRangeType } from 'components/DateRange/DateRange.types';
import DateService from 'services/DateService/dateService';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import moment from 'moment';
import { FilterContainer, FilterButton, StyedButton, SelectStyled, DateRangeStyled } from './MetricsFilter.styles';
import { IMetricsFilterProps } from './MetricsFilter.types';

const MAX_ALLOWED_DAYS = 365;

export const MetricsFilter = ({ style, hideRegulator = false }: IMetricsFilterProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [companiesSelectedFilter, setCompaniesSelectedFilter] = React.useState<string[]>([]);
  const [categoriesSelectedFilter, setCategoriesSelectedFilter] = React.useState<string[]>([]);
  const [productTypesSelectedFilter, setProductTypesSelectedFilter] = React.useState<string[]>([]);
  const [regulatorSelectedFilter, setRegulatorSelectedFilter] = React.useState<IOptions | null>(null);
  const [startDateSelectedFilter, setStartDateSelectedFilter] = React.useState<string>(DateService.getDateFromToday());
  const [endDateSelectedFilter, setEndDateSelectedFilter] = React.useState<string>(DateService.getDateFromTomorrow());
  const [momentStartDateSelectedFilter, setMomentStartDateSelectedFilter] = React.useState<DateRangeStateType>(
    DateService.getDateFromTodayMoment()
  );
  const [momentEndDateSelectedFilter, setMomentEndDateSelectedFilter] = React.useState<DateRangeStateType>(
    DateService.getDateFromTomorrowMoment()
  );
  const { activeProfile } = useActiveProfile();
  const {
    companyOptions,
    categoryOptions,
    productTypeOptions,
    regulatorOptions,
    companiesSelected,
    categoriesSelected,
    productTypesSelected,
    regulatorSelected,
    startDateSelected,
    endDateSelected,
    formatAccountGroupsToRegulatorOptions,
    setCompaniesSelected,
    updateFilters,
  } = useMetricsFilter();

  const debounceSearch = useDebounce(inputValue);

  const onDatesChange = (range: { startDate: DateRangeType; endDate: DateRangeType }): void => {
    const { startDate, endDate } = range;

    setStartDateFilters(startDate);
    setEndDateFilters(limitEndDate(startDate, endDate));
  };

  const limitEndDate = (startDate: DateRangeType, endDate: DateRangeType | null): DateRangeType | null => {
    if (!endDate) return null;

    const maxEndDate = moment(startDate).add(MAX_ALLOWED_DAYS, 'days');
    return endDate.isAfter(maxEndDate) ? maxEndDate : endDate;
  };

  const setEndDateFilters = (endDate: DateRangeType | null): void => {
    setMomentEndDateSelectedFilter(endDate);
    setEndDateSelectedFilter(endDate ? DateService.fromMomentToIsoLessThan(endDate) : '');
  };

  const setStartDateFilters = (startDate: DateRangeType | null): void => {
    setMomentStartDateSelectedFilter(startDate);
    setStartDateSelectedFilter(startDate ? DateService.fromMomentToIsoGreaterThan(startDate) : '');
  };

  const handleUpdateFilters = () => {
    updateFilters(
      companiesSelectedFilter,
      categoriesSelectedFilter,
      productTypesSelectedFilter,
      regulatorSelectedFilter,
      startDateSelectedFilter,
      endDateSelectedFilter
    );
  };

  const isFilterDisabled = () => {
    return companiesSelectedFilter.length === 0 || startDateSelectedFilter === '' || endDateSelectedFilter === '';
  };

  /* eslint-disable @typescript-eslint/ban-types */
  const handleRegulatorSelected = (__: React.ChangeEvent<{}>, newValue: unknown) => {
    setLoading(false);
    const selectedOpt = newValue as IOptions | undefined;
    setRegulatorSelectedFilter(selectedOpt || null);
  };

  const updateSelectedFilterFromContext = useCallback(() => {
    if (companiesSelectedFilter.join() !== companiesSelected.join()) {
      setCompaniesSelectedFilter(companiesSelected);
    }
    if (categoriesSelectedFilter.join() !== categoriesSelected.join()) {
      setCategoriesSelectedFilter(categoriesSelected);
    }

    if (productTypesSelectedFilter.join() !== productTypesSelected.join()) {
      setProductTypesSelectedFilter(productTypesSelected);
    }

    if (regulatorSelectedFilter?.name !== regulatorSelected?.name) {
      setRegulatorSelectedFilter(regulatorSelected);
    }

    if (startDateSelectedFilter !== startDateSelected) {
      setMomentStartDateSelectedFilter(moment(startDateSelected).add(1, 'days'));
    }

    if (endDateSelectedFilter !== endDateSelected) {
      setMomentEndDateSelectedFilter(moment(endDateSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilters = () => {
    setCompaniesSelectedFilter([]);
    setCategoriesSelectedFilter([]);
    setProductTypesSelectedFilter([]);
    setRegulatorSelectedFilter(null);
    setStartDateSelectedFilter('');
    setEndDateSelectedFilter('');
  };

  const handleDebounceRegulatorSearch = useCallback(() => {
    if (debounceSearch && companiesSelectedFilter.length > 0 && regulatorSelectedFilter === null) {
      setLoading(true);
      AccountService.getAccountGroupsByFilters(
        activeProfile.clientId,
        0,
        10,
        companiesSelectedFilter,
        ['REGULATOR'],
        debounceSearch
      ).then((users) => {
        formatAccountGroupsToRegulatorOptions(users);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  const handleSelectAllComapnies = useCallback(() => {
    if (companyOptions.length > 0) {
      const options = companyOptions.map((company) => company.value);
      setCompaniesSelectedFilter(options);
      setCompaniesSelected(options);
    }
  }, [companyOptions, setCompaniesSelected]);

  useEffect(() => {
    handleSelectAllComapnies();
  }, [handleSelectAllComapnies]);

  useEffect(() => {
    handleDebounceRegulatorSearch();
  }, [handleDebounceRegulatorSearch]);

  useEffect(() => {
    updateSelectedFilterFromContext();

    return () => {
      resetFilters();
    };
  }, [updateSelectedFilterFromContext]);

  return (
    <FilterContainer style={style} data-testid="metrics-filter">
      <DateRangeStyled
        className="animate fadeIn"
        testID="date-range-select"
        startDate={momentStartDateSelectedFilter}
        endDate={momentEndDateSelectedFilter}
        label="Período"
        onDatesChange={onDatesChange}
      />

      <SelectStyled
        label="Operação"
        labelId="companies"
        options={companyOptions}
        selectedOptions={companiesSelectedFilter}
        onChange={({ target: { value } }): void => setCompaniesSelectedFilter(value as string[])}
        multiple
        placeholder="Selecione as empresas"
      />
      <SelectStyled
        label="Categoria"
        labelId="categories"
        placeholder="Selecione as categorias"
        options={categoryOptions}
        selectedOptions={categoriesSelectedFilter}
        onChange={({ target: { value } }): void => setCategoriesSelectedFilter(value as string[])}
        multiple
        disabled={companiesSelectedFilter.length === 0}
      />
      <SelectStyled
        label="Tipos do produto"
        labelId="productTypes"
        placeholder="Selecione os tipos do produto"
        options={productTypeOptions}
        selectedOptions={productTypesSelectedFilter}
        onChange={({ target: { value } }): void => setProductTypesSelectedFilter(value as string[])}
        multiple
        disabled={companiesSelectedFilter.length === 0}
      />
      {!hideRegulator && (
        <AsyncAutocomplete
          label="Peritos"
          placeholder="Busque o perito"
          loading={loading}
          loadingText="Buscando..."
          options={regulatorOptions}
          value={regulatorSelectedFilter?.name || ''}
          onChange={handleRegulatorSelected}
          onInputChange={(__, newInputValue) => setInputValue(newInputValue)}
        />
      )}
      <FilterButton>
        <StyedButton text="Filtrar" onClick={handleUpdateFilters} disabled={isFilterDisabled()} capitalize />
      </FilterButton>
    </FilterContainer>
  );
};
