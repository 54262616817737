import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { TableRow as MuiTableRow, TableCell } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { InvoiceStatusEnum } from 'commom/types/Invoices.types';
import { Tooltip as MuiTooltip, withStyles } from '@material-ui/core';

interface IStyledStatusCell {
  type: string;
}

interface IIconShowDetailsProps {
  isActive?: boolean;
}

export const Container = styled.div`
  background: #fff;
  width: 100%;
  padding: 20px 0 0px 0px;
  border-radius: 8px;
  box-shadow: 0px 2px 15px 0px rgba(238, 238, 238, 0.5);
`;

export const Header = styled.div`
  padding: 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 15px 0px;
  padding: 0px 15px;
`;

export const TableHeaderCell = MuiStyled(TableCell)(() => ({
  padding: '6px 16px',
  color: '#6a758b',
}));

export const TableBodyCell = MuiStyled(TableCell)(() => ({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  padding: '14px 16px',
  border: 0,
}));

export const TableBodyRow = MuiStyled(MuiTableRow)(() => ({
  backgroundColor: '#fff',
  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FBFD',
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TableBodyMessage = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationInvoicesTable = styled(Pagination)`
  .MuiButtonBase-root {
    color: black;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: white;
    cursor: pointer;
    border: none;
    &.Mui-selected {
      background: #000000 !important;
      color: white !important;
      border-radius: 5px;
    }
  }
`;

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 24px 10px;
`;

export const PaginationController = styled.div`
  margin-left: auto;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const PaginationDetails = styled.div``;

const statusColor = (type: string) => {
  const colors: { [key: string]: string } = {
    [InvoiceStatusEnum.PAID]: '#08844B',
    [InvoiceStatusEnum.NOT_PAID]: '#E71C40',
  };

  return colors[type] || '#8F8F8F';
};

const statusBackground = (type: string) => {
  const colors: { [key: string]: string } = {
    [InvoiceStatusEnum.PAID]: '#08844b0d',
    [InvoiceStatusEnum.NOT_PAID]: '#e71c400d',
  };

  return colors[type] || '#8f8f8f0d';
};

export const StatusCell = styled.span<IStyledStatusCell>`
  color: ${({ type }) => statusColor(type)};
  border: 1px solid ${({ type }) => statusColor(type)};
  border-radius: 50px;
  background-color: ${({ type }) => statusBackground(type)};
  padding: 4px 8px;
  min-width: 50px;
  text-align: center;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.06px;
`;

export const IconShowActions = MuiStyled(RemoveRedEyeIcon, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<IIconShowDetailsProps>`
  cursor: ${(props): string => (props.isActive ? 'pointer' : 'default')};
  color: ${(props): string => (props.isActive ? '#000' : '#6a758b')};
`;

export const Tooltip = withStyles((theme) => ({
  popper: {
    left: '10px !important',
  },
  tooltip: {
    boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    color: '#ffffff',
    borderRadius: '4px',
    width: 180,
    fontSize: theme.typography.pxToRem(10),
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
  },
}))(MuiTooltip);
