import React from 'react';
import { useAppSelector } from 'hooks/hooks';
import { DataBox } from 'components/DataBox';
import { priceFormatter } from 'utils/formatPrice';
import { Card, CardBody, CardHeader, CardTitle } from './InsuranceDataAndVehicle.styles';

const InsuranceDataAndVehicle = () => {
  const deductiblePrice = useAppSelector((state) => state.presentBudget.budget.deductible);

  return (
    <Card>
      <CardHeader>
        <CardTitle>DADOS DO SEGURO</CardTitle>
      </CardHeader>
      <CardBody>
        <DataBox.Root>
          <DataBox.Content>
            <DataBox.Item>
              <DataBox.Label>Franquia</DataBox.Label>
              <DataBox.Value>{priceFormatter.format(deductiblePrice) || '-'}</DataBox.Value>
            </DataBox.Item>
          </DataBox.Content>
        </DataBox.Root>
      </CardBody>
    </Card>
  );
};

export default InsuranceDataAndVehicle;
