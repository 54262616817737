import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import accountReducer from './accoutGroup.store';
import activeProfileReducer from './activeProfile.store';
import presentBudgetReducer from './presentBudget/presentBudget.store';
import presentInspectionReducer from './presentInspection/presentInspection.store';
import schedulerListMenuReducer from './schedulerListMenu.store';
import initBudgetListenerMiddleware from './presentBudget/middlewares/initBudget';
import updateReportsListenerMiddleware from './presentBudget/middlewares/updateReports';

export const store = configureStore({
  reducer: {
    accountGroup: accountReducer,
    activeProfile: activeProfileReducer,
    presentBudget: presentBudgetReducer,
    presentInspection: presentInspectionReducer,
    schedulerListMenu: schedulerListMenuReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(initBudgetListenerMiddleware.middleware)
      .prepend(updateReportsListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
