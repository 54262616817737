import styled from 'styled-components';
import { Label as LabelComponent } from 'components';
import { Select as SelectComponent } from 'components/Select/Select';
import Dialog from '@material-ui/core/Dialog';

interface IDragAndDropArea {
  isDragging: boolean;
}

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px 35px;
    border-radius: 4px;
    width: 650px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  height: 24px;
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 16px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
`;

export const DragAndDropArea = styled.div<IDragAndDropArea>`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ isDragging }) => (isDragging ? '#099857' : '#dbe1ee')};
  padding: 33px 0;

  &:hover {
    border-color: #099857;
    cursor: pointer;
    transition: 0.8s;

    p {
      color: #099857;
      transition: 0.8s;
    }
  }

  p {
    margin: 0;
    color: ${({ isDragging }) => (isDragging ? '#099857' : '#dbe1ee')};
  }
`;

export const ErrorMessage = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 12px;
  padding-top: 15px;
  color: #dc3d3d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DocumentList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 20px;
  max-height: 280px;
  min-height: 160px;
  overflow-y: scroll;
`;

export const DocumentItem = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 2px gray;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DocumentTitle = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 5px;
  font-size: 14px;
  font-weight: 500;
  max-width: 120px;
`;

const ButtonBase = styled.button`
  border: none;
  cursor: pointer;
`;

export const CloseButton = styled(ButtonBase)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0;
  background: transparent;
  margin-bottom: 2px;
  margin-right: 5px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const UpdateButton = styled(ButtonBase)`
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 5px;
  margin-left: 5px;
  background: #099857;
  color: #fff;
  font-size: 9px;

  img {
    width: 13px;
    height: 13px;
  }
`;

export const Label = styled(LabelComponent)``;

export const ContainerButton = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const Select = styled(SelectComponent)`
  div {
    font-weight: bold;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const DocumentIconStyle = styled.img`
  width: 50px !important;
`;
