import { IVehiclePartPrice } from 'services/ApiService/PartService/partsService.types';

export enum PartType {
  GENUINE = 'GENUINE',
  ORIGINAL = 'ORIGINAL',
  GREEN = 'GREEN',
  OTHERS = 'OTHERS',
}

export enum PartTypeDescription {
  GENUINE = 'Genuíno',
  ORIGINAL = 'Original',
  GREEN = 'Verde',
  OTHERS = 'Outros',
}

export interface IPartTypeOption {
  value: PartType;
  name: string;
}

export interface IFooterPartProps {
  handleClose: () => void;
  onSubmit: () => Promise<void> | void;
  loadingSubmit: boolean;
  isEditMode?: boolean;
}

export interface IPartHeaderProps {
  partPriceSelected?: IVehiclePartPrice | null;
  handleBackButton: () => void;
  isEditMode?: boolean;
}
