import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 6px 0px;
  width: 100%;
  box-sizing: border-box;
  gap: 6px;

  #info-wrapper {
    font-size: 10px;
  }

  border-bottom: 1px solid #00000017;
`;

export const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  font-size: 12px;
  row-gap: 15px;
  padding: 6px 17px;
  width: 100%;
  gap: 6px;

  border-bottom: 1px solid #00000017;
`;

export const InsuranceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: 15px;
  padding: 6px 17px;
  gap: 6px;

  box-sizing: border-box;
`;

export const HeaderSection = styled.div`
  display: flex;
  gap: 10px;

  h1 {
    font-size: 14px;
  }
`;

export const InformationContainer = styled.div`
  font-size: 10px;
  display: flex;
  width: 100%;
  gap: 20px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Label = styled.div`
  font-weight: 700;
  line-height: 7px;
  letter-spacing: 0em;
`;

export const Value = styled.div`
  font-weight: 500;
  letter-spacing: 0em;
`;
