import { IBudget } from 'commom/types/Budget.types';
import ApiService from 'services/ApiService';
import { PaintingTypeEnum } from '../VehicleService/vehicleService.types';
import {
  IBudgetResponse,
  IBudgetService,
  ICompanyConclusionTypes,
  ICreateBudgetLaborResponse,
  IPartTempValues,
  TypesListEnum,
  IInspectionBudgetVersions,
  ICreateExternalServiceRequest,
  IUpdateExternalServiceRequest,
  IGetWorkshopServicesResponse,
  IServiceCost,
  IAddConclusion,
  ICalculateBudgetPartNetPriceResponse,
} from './BudgetService.types';

const BudgetService: IBudgetService = {
  getCompanyConclusionTypes: async (companyId: string) => {
    const { data } = await ApiService.get<ICompanyConclusionTypes>(
      `/budget-company-conclusion-types?companyId=${companyId}`
    );
    return data;
  },

  getBudgetsByInspectionId: async (inspectionId: string) => {
    const { data } = await ApiService.get<IBudget[]>(`/budgets/find-by-inspection/${inspectionId}`);

    return data;
  },

  addPart: async (part, budgetId) => {
    const { data } = await ApiService.post<IBudgetResponse>(`/budgets/part?budgetId=${budgetId}`, {
      part,
    });

    return data.budget;
  },

  editPart: async (part, budgetId) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/budgets/part?budgetId=${budgetId}`, {
      part,
    });

    return data.budget;
  },

  removePartBudget: async (budgetId: string, sku: string) => {
    const { data } = await ApiService.delete<IBudgetResponse>(`/budgets/part?budgetId=${budgetId}&sku=${sku}`);

    return data.budget;
  },

  createBudget: async ({ inspectionId, isPrivate, workshop, labor, deductible }) => {
    await ApiService.post(`/budgets`, {
      inspectionId,
      isPrivate,
      workshop,
      labor,
      deductible,
    });
  },

  addBudgetConclusion: async ({ reason, type, budgetId, comment, userId }: IAddConclusion) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/budgets/${budgetId}/add-conclusion`, {
      type: type,
      reason: reason,
      comment,
      user: userId,
    });

    return data.budget;
  },
  archiveBudgetConclusion: async (budgetId: string, conclusionId: string) => {
    const { data } = await ApiService.put<IBudgetResponse>(
      `/budgets/${budgetId}/archive-conclusion?conclusionId=${conclusionId}`
    );

    return data.budget;
  },

  finishBudget: async (budgetId: string) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/inspection-budgets/${budgetId}/finish`);

    return data.budget;
  },

  formattedLaborCostsToPartTempValues: (laborCosts, paintingCode: PaintingTypeEnum) => {
    const partTempValues: IPartTempValues = {
      repair: 0,
      painting: 0,
      removeAndInstall: 0,
    };

    laborCosts.forEach((laborCost) => {
      if (laborCost?.price) {
        if (laborCost.code === 'REPAIR') {
          partTempValues.repair = laborCost.price;
        }

        if (laborCost.code === paintingCode) {
          partTempValues.painting = laborCost.price;
        }

        if (laborCost.code === 'LABOR') {
          partTempValues.removeAndInstall = laborCost.price;
        }
      }
    });

    return partTempValues;
  },

  setWorkshop: async (budgetId: string, workshopId: string) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/inspection-budgets/${budgetId}/workshop`, {
      workshopId,
    });

    return data.budget;
  },

  createBudgetLabor: async (companyId: string, serviceCosts: IServiceCost[]) => {
    const { data } = await ApiService.post<ICreateBudgetLaborResponse>('/company-workshop-services', {
      companyId,
      serviceCosts,
    });

    return data;
  },

  getBudgetLabor: async (companyId: string) => {
    const { data } = await ApiService.get<ICreateBudgetLaborResponse>('/company-workshop-services/find-by-company-id', {
      params: {
        companyId,
      },
    });

    return data;
  },

  updateBudgetLabor: async (budgetLaborId: string, serviceCosts: IServiceCost[]) => {
    const { data } = await ApiService.put<ICreateBudgetLaborResponse>(
      `company-workshop-services/${budgetLaborId}/service-costs`,
      {
        serviceCosts,
      }
    );

    return data;
  },

  getWorkshopServices: async () => {
    const { data } = await ApiService.get<IGetWorkshopServicesResponse>(`/workshop-services`);

    return data;
  },

  getTypesPartsList: () => {
    return [
      {
        name: 'Genuína',
        value: TypesListEnum.GENUINE,
      },
      {
        name: 'Original',
        value: TypesListEnum.ORIGINAL,
      },
      {
        name: 'Peças Paralelas',
        value: TypesListEnum.OTHER_SOURCES,
      },
      {
        name: 'Verde',
        value: TypesListEnum.USED,
      },
    ];
  },

  createExternalService: async ({ budgetId, name, price }: ICreateExternalServiceRequest) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/budgets/${budgetId}/service`, {
      service: { name, price, discount: 0 },
    });

    return data.budget;
  },

  updateExternalService: async ({ budgetId, price, serviceId }: IUpdateExternalServiceRequest) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/budgets/${budgetId}/edit-service`, {
      price,
      serviceId,
    });
    return data.budget;
  },

  deleteExternalService: async (budgetId: string, serviceId: string) => {
    const { data } = await ApiService.delete<IBudgetResponse>(`/budgets/service?id=${budgetId}&serviceId=${serviceId}`);

    return data.budget;
  },

  calculateDamagePercentage: (fullPrice: number, fipeValue: number) => {
    return ((fullPrice / fipeValue) * 100).toFixed(2).replace(',', '.');
  },

  calculateBudgetPartNetPrice: async (originalPrice, discount, increase) => {
    const { data } = await ApiService.get<ICalculateBudgetPartNetPriceResponse>('/budgets/calculate-net-price', {
      params: {
        originalPrice,
        discount,
        increase,
      },
    });

    return data.individualNetPrice;
  },

  getbudgetLatestVersions: async (budgetId: string) => {
    const {
      data: { inspectionBudgetVersionsOutput },
    } = await ApiService.get<IInspectionBudgetVersions>(
      `/inspection-budgets/find-latests-version-by-previous-budget-id/${budgetId}`
    );

    return inspectionBudgetVersionsOutput;
  },
  createBudgetDeal: async (budgetId, cost, discount, increase) => {
    const { data } = await ApiService.put<IBudgetResponse>(`/budgets/${budgetId}/labor`, {
      cost,
      discount,
      increase,
    });

    return data.budget;
  },
};

export default BudgetService;
