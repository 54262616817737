export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export function getFirtAndLastName(fullName: string | undefined) {
  if (!fullName) {
    return '';
  }

  const words = fullName.split(' ');

  if (words.length === 1) {
    return fullName;
  }

  const firstName = words[0];
  const lastName = words[words.length - 1];

  return `${firstName} ${lastName}`;
}
