import { useContext } from 'react';

import { IModulesContext } from './types';

import { ModulesContext } from './ModulesProvider';

export const useModules = (): IModulesContext => {
  const context = useContext(ModulesContext);

  if (!context) {
    throw new Error('useActiveProfileDispatch must be used within a AuthProvider');
  }

  return context;
};
