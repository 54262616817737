import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Label, Button } from 'components';
import { toast } from 'react-toastify';
import { IOptions } from 'components/Select/Select.types';
import { formValidations } from 'utils/form/validations';
import { IAssociateUserOperations } from 'services/ApiService/AccountService/types';
import AccountService from 'services/ApiService/AccountService';
import ClientService from 'services/ApiService/ClientService';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import { CompanyLabel } from 'components/CompanyLabel/CompanyLabel';
import { mutateMany } from 'hooks/SWRUtils/SWRUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  IAssociateUserOperationsDialogProps,
  IAssociateUserOperationsForm,
} from './AssociateUserOperationsDialog.types';
import {
  DialogBase,
  DialogTitle,
  DialogContentText,
  FormWrapper,
  SelectContainer,
  SelectWrapper,
  TextField,
  DialogActions,
  ButtonWrapper,
  LoadingSelectContainer,
} from './AssociateUserOperationsDialog.styles';
import { isAxiosError } from 'utils/isAxiosError';

export const AssociateUserOperationsDialog = ({
  dialogVisibility,
  handleClose,
}: IAssociateUserOperationsDialogProps) => {
  const [operationsList, setOperationsList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [operationsSelected, setOperationsSelected] = useState<string[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const { clientId } = AccountService.getActiveProfileInLocalStorage();
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
  });

  const getOperationId = useCallback((operations: ICompany[]): string[] => {
    return operations.length === 1 ? operations.map((op) => op.id) : [];
  }, []);

  const getCompaniesByClientId = useCallback(async (): Promise<ICompany[]> => {
    const { companies } = await ClientService.getClientById(clientId);

    return companies;
  }, [clientId]);

  const getOperations = useCallback(async (): Promise<IOptions[]> => {
    try {
      setLoadingSelect(true);
      const operationsResponse = await getCompaniesByClientId();
      setOperationsSelected(getOperationId(operationsResponse));
      return operationToOptions(operationsResponse);
    } catch (error) {
      toast.error('Problema ao carregar as operações, tente novamente.');
      return [];
    } finally {
      setLoadingSelect(false);
    }
  }, [getOperationId, getCompaniesByClientId]);

  const operationToOptions = (operations: ICompany[]): IOptions[] => {
    return operations.map(({ id: value, name }) => ({ value, name }));
  };

  const onSubmit = async (formRequest: IAssociateUserOperationsForm): Promise<void> => {
    const formData: IAssociateUserOperations = {
      username: formRequest.email,
      operations: operationsSelected,
      clientId,
    };

    try {
      if (operationsSelected.length > 0) {
        setLoadingSubmit(true);
        await associateUserOperations(formData);
        setLoadingSubmit(false);
      } else {
        throw new Error('Error');
      }
    } catch (error) {
      setLoadingSubmit(false);
      toast.error('Por favor, selecione uma operação.');
    }
  };

  const showToastUserAssociationErrorMessage = (status: number | undefined) => {
    if (status === 400) {
      toast.error('Usuário já associado, verifique a tabela.');
    } else if (status === 404) {
      toast.error('Usuário inexistente, digite um e-mail ou login válido.');
    } else {
      toast.error('Erro ao associar usuário, tente novamente.');
    }
  };

  const associateUserOperations = async (formData: IAssociateUserOperations): Promise<void> => {
    try {
      await AccountService.associateUserOperations(formData);
      toast.success('Usuário associado com sucesso!');
      updateSWRAccountGroups();
      handleClose();
      resetInputs();
    } catch (error) {
      if (isAxiosError(error)) {
        showToastUserAssociationErrorMessage(error.response?.status);
      } else {
        toast.error('Ocorreu um erro interno.');
      }
    }
  };

  const updateSWRAccountGroups = async (): Promise<void> => {
    await mutateMany(`^/account-groups/${clientId}(.*)`);
  };

  const resetInputs = (): void => {
    setValue('email', '');
    setOperationsSelected([]);
  };

  const hasOneOperation = (): boolean => {
    return operationsList.length === 1;
  };

  const getOperationName = (): string => {
    return operationsList[0]?.name;
  };

  const disableSubmitButton = (): boolean => {
    return (
      operationsSelected.length === 0 ||
      operationsSelected === null ||
      operationsSelected === undefined ||
      loadingSelect
    );
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const onEnter = async (): Promise<void> => setOperationsList(await getOperations());

  const inputProps = {
    email: {
      'data-testid': 'input-email',
      form: {
        autocomplete: 'off',
      },
    },
  };

  return (
    <DialogBase open={dialogVisibility} onClose={handleClose} TransitionProps={{ onEnter }}>
      <DialogTitle>Associar usuário</DialogTitle>
      {hasOneOperation() ? (
        <DialogContentText>Associe o usuário à operação selecionada.</DialogContentText>
      ) : (
        <DialogContentText>Associe o usuário a uma ou mais operações.</DialogContentText>
      )}
      <FormWrapper>
        <Form testID="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="select-operations" testID="label-operation">
            Operação
          </Label>

          {((): JSX.Element => {
            if (loadingSelect) {
              return (
                <LoadingSelectContainer>
                  <CircularProgress size={25} style={{ color: '#000' }} data-testid="loading-select" />
                </LoadingSelectContainer>
              );
            }

            if (hasOneOperation()) {
              return <CompanyLabel testID="operation">{getOperationName()}</CompanyLabel>;
            }
            return (
              <SelectContainer>
                <SelectWrapper
                  testID="select-operations"
                  labelId="label-operation"
                  placeholder="Selecione uma ou mais operações"
                  options={operationsList}
                  multiple
                  selectedOptions={operationsSelected}
                  onChange={({ target: { value } }): void => setOperationsSelected(value as string[])}
                />
              </SelectContainer>
            );
          })()}

          <Label htmlFor="input-username" testID="label-username">
            Usuário
          </Label>
          <TextField
            testID="input-username"
            error={hasError('email')}
            inputProps={inputProps.email}
            inputRef={register(formValidations.default)}
            helperText={errors?.email?.message}
            state={handleInputState('email')}
            name="email"
            type="email"
            placeholder="Insira o e-mail ou login do usuário"
            variant="outlined"
          />

          <DialogActions>
            <ButtonWrapper>
              <Button
                testID="dialog-button-cancel"
                type="button"
                variant="outlined"
                onClick={handleClose}
                text="CANCELAR"
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                testID="dialog-button-submit"
                type="submit"
                text="ASSOCIAR"
                disabled={disableSubmitButton()}
                loading={loadingSubmit}
                loadingSize={25}
                loadingColor="#FFF"
              />
            </ButtonWrapper>
          </DialogActions>
        </Form>
      </FormWrapper>
    </DialogBase>
  );
};
