import styled from 'styled-components';

export const Container = styled.div`
  page-break-inside: avoid;
`;

export const ConclusionSection = styled.div`
  padding-bottom: 20px;
  font-size: 14px;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #00000017;
  border-top: 1px solid #00000017;
  padding: 10px 20px;
  border-radius: 4px;
  background: #000;
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
`;

export const ConclusionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 0;
`;
