import React from 'react';
import { Avatar } from './Avatar.styles';
import { IAvatar } from './Avatar.types';

const AvatarIcon = ({ children, testId, image, color = '', className }: IAvatar) => {
  const formatName = (): string | undefined => {
    return children?.slice(0, 1).toUpperCase();
  };

  return (
    <Avatar data-testid={testId} src={image} color={color} className={className}>
      {formatName()}
    </Avatar>
  );
};

export default AvatarIcon;
