import styled from 'styled-components';

export const CompanyLabel = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #000000;
  margin: 8px 0px 20px 0px;
`;
