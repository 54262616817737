import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const TextField = withTheme(styled(TextFieldComponent)`
  && {
    margin-bottom: ${(props): number => props.theme.spacing(2.5)}px;
    margin-top: ${(props): number => props.theme.spacing(1)}px;
  }
`);

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px;
    border-radius: 4px;
    max-width: 380px;
  }
`;

export const DialogTitle = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0;
  color: #000;
  width: 142px;
  height: 24px;
`;

export const DialogContentText = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 16px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 330px;
`;

export const FormWrapper = styled.div`
  padding-top: 15px;
`;

export const InputWrapper = styled.div`
  position: relative;
  padding: 5px;
`;

export const LabelWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 8px;
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const ButtonWrapper = styled.div`
  height: 45px;
  margin-left: 10px;
`;

export const CustomError = styled.div`
  bottom: 0;
  color: #f44336;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  left: 14px;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  position: absolute;
`;

export const CustomErrorContainer = styled.span`
  position: relative;

  .MuiFormControl-root {
    width: 100%;
  }
`;
