export enum AuthorityEnum {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  REGULATOR = 'REGULATOR',
  USER = 'USER',
  ANALYST = 'ANALYST',
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
}

export enum AuthorityNameEnum {
  ADMIN = 'Administrador',
  REGULATOR = 'Perito',
  USER = 'Operador',
  ANALYST = 'Analista',
  INSURANCE_COMPANY = 'Seguradora',
}
