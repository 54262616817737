import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IDocument } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';
import DocumentService from 'services/DocumentService/documentService';
import DocXlsxIcon from 'assets/svg/icon-xlsx.svg';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import DeleteIcon from 'assets/svg/close-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import { getErrorMessage, hasException } from 'utils/exceptions/formatDeleteFileException';
import TabEmpty from '../TabEmpty/TabEmpty';
import { ITabDocumentsProps } from './TabDocuments.types';
import * as S from './TabDocuments.styles';

const TabDocuments = ({ inspection }: ITabDocumentsProps) => {
  const { id: inspectionId, files } = inspection;
  const [selectedDocument, setSelectedDocument] = useState<IDocument>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const listRef = useRef<HTMLUListElement>(null);

  const { invalidateInspectionParallel } = useMutateInspection();

  const documentsLength = files?.length || 0;

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLUListElement>) => {
    if (!listRef.current) return;
    setIsMouseDown(true);
    setStartX(event.clientX - listRef.current?.offsetLeft);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLUListElement>) => {
    if (!isMouseDown) return;
    if (!listRef.current) return;
    const x = event.clientX - listRef.current?.offsetLeft;
    const scrollLeft = listRef.current?.scrollLeft;

    listRef.current.scrollLeft = scrollLeft - (x - startX);
    setStartX(x);
  };

  const handleDocumentDeletion = (event: React.MouseEvent<HTMLElement>, document: IDocument) => {
    event.stopPropagation();
    setSelectedDocument(document);
    setShowConfirmDialog(true);
  };

  const deleteDocument = async () => {
    if (!selectedDocument?.id) return;
    DocumentService.deleteDocuments([selectedDocument.id], inspectionId)
      .then(() => {
        invalidateInspectionParallel(inspectionId);
        toast.success('Documento excluído com sucesso!');
      })
      .catch((error) => {
        const exception = error.response?.data?.exception;
        if (exception && hasException(exception)) {
          toast.warning(getErrorMessage(exception));
        } else {
          toast.error('Erro ao excluir documento!');
        }
      });
  };

  const downloadDocument = (file: IDocument) => {
    DocumentService.downloadDocument(file);
  };

  if (documentsLength === 0) {
    return <TabEmpty title="Não há documentos enviados" />;
  }

  return (
    <>
      <S.TabDocumentsContainer
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={listRef}
      >
        {files?.map((file) => (
          <S.ListItem key={file.id} data-testid="doc-item">
            <Tooltip title={file.description} placement="top">
              <S.TouchableDocument type="button" aria-label="Baixar documento" onClick={() => downloadDocument(file)}>
                <img src={DocXlsxIcon} alt="Ilustração de um documento XLSX" />
              </S.TouchableDocument>
            </Tooltip>

            <Tooltip title="Excluir documento" placement="top">
              <S.DeleteIconButton
                data-testid="delete-icon-btn"
                type="button"
                onClick={(e) => handleDocumentDeletion(e, file)}
              >
                <img src={DeleteIcon} alt="Botão de excluir documento" />
              </S.DeleteIconButton>
            </Tooltip>
          </S.ListItem>
        ))}
      </S.TabDocumentsContainer>

      {showConfirmDialog && (
        <ConfirmDialog
          dialogVisibility={showConfirmDialog}
          handleClose={() => setShowConfirmDialog(false)}
          testID="confirm-dialog"
          onSubmit={deleteDocument}
          title="Deseja realmente excluir o documento?"
          subtitle="Ao confirmar, essa ação não poderá ser desfeita."
        />
      )}
    </>
  );
};

export default TabDocuments;
