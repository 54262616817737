import React from 'react';
import { IReportDamagePartsSeverityEnum } from 'commom/types/BudgetReport.types';
import { StrongInfo, SummaryInfo } from '../DamageSummary.styles';
import { useAppSelector } from 'hooks/hooks';
import { selectDamageSeverity } from 'stores/presentBudget/presentBudget.selector';

const DamageClassification = () => {
  const severity = useAppSelector(selectDamageSeverity);

  const LightDamageDescription = () => (
    <>
      <StrongInfo>{'Dano de Pequena Monta: '}</StrongInfo>
      <SummaryInfo>{'até 1 ponto, somando os pontos de todos os itens assinalados nas colunas'}</SummaryInfo>
    </>
  );

  const MediumDamageDescription = () => (
    <>
      <StrongInfo>{'Dano de Média Monta: '}</StrongInfo>
      <SummaryInfo>{'de 2 a 6 pontos, somando os pontos de todos os itens assinalados nas colunas'}</SummaryInfo>
    </>
  );

  const HeavyDamageDescription = () => (
    <>
      <StrongInfo>{'Dano de Grande Monta: '}</StrongInfo>
      <SummaryInfo>{'acima de 6 pontos, somando os pontos de todos os itens assinalados nas colunas'}</SummaryInfo>
    </>
  );

  const DamageSeverity = {
    [IReportDamagePartsSeverityEnum.LIGHT_DAMAGE]: LightDamageDescription,
    [IReportDamagePartsSeverityEnum.MEDIUM_DAMAGE]: MediumDamageDescription,
    [IReportDamagePartsSeverityEnum.HEAVY_DAMAGE]: HeavyDamageDescription,
  };

  if (severity) {
    const Description = DamageSeverity[severity];
    return (
      <SummaryInfo>
        <Description />
        <StrongInfo>{' Sim'}</StrongInfo>
        <SummaryInfo>{' e '}</SummaryInfo>
        <StrongInfo>{'NA.'}</StrongInfo>
      </SummaryInfo>
    );
  } else {
    return <SummaryInfo>Após preencher o laudo, uma classificação será exibida.</SummaryInfo>;
  }
};

export default DamageClassification;
