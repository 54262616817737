import { ICompanySelectOptions, IOptions } from 'components/Select/Select.types';
import { InspectionTypeCodeEnum } from 'services/InspectionTypeService/inspectionTypeService.types';
import { IProductType } from 'services/ProductTypeService/productTypeService.types';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import { ICompanySelectService } from './CompanySelectService.types';

const CompanySelectService: ICompanySelectService = {
  getFirstCompany: (companies: IOptions[]) => {
    return companies[0];
  },

  getDefaultCompany: (): ICompanySelectOptions => {
    return { value: '', name: '', inspectionPlatforms: [], productTypeCode: '', productTypeId: '' };
  },

  getFirstCompanyProductTypeCode: (productTypes: IProductType[]) => {
    return productTypes && productTypes[0].code;
  },

  getFirstCompanyProductTypeId: (productTypes: IProductType[]) => {
    return productTypes && productTypes[0].id;
  },

  transformCompanyToOption: (company: ICompany): ICompanySelectOptions => {
    const { id: value, name, inspectionPlatforms, inspectionTypes, productTypes } = company;
    return {
      value,
      name,
      inspectionPlatforms,
      inspectionTypes,
      productTypeCode: CompanySelectService.getFirstCompanyProductTypeCode(productTypes),
      productTypeId: CompanySelectService.getFirstCompanyProductTypeId(productTypes),
    };
  },
  isOptionPreviousInspection: (option: ICompanySelectOptions): boolean => {
    return !!option.inspectionTypes?.some((type) => type.code === InspectionTypeCodeEnum.PREVIOUS);
  },

  isOptionNotPreviousInspection: (option: ICompanySelectOptions): boolean => {
    return !option.inspectionTypes?.some((type) => type.code === InspectionTypeCodeEnum.PREVIOUS);
  },
};

export default CompanySelectService;
