import React from 'react';
import UserIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { DataBox } from 'components/DataBox';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { formatPhone } from 'utils/formatPhone';

interface ICustomerDataProps {
  inspection: IInspection;
}

const CustomerData = ({ inspection: { code, customer, productType } }: ICustomerDataProps) => {
  const isProductAuto = ProductTypeService.isAuto(productType?.code);

  const customerLabel = isProductAuto ? 'Segurado' : 'Cliente';

  const customerInfos = [
    { label: customerLabel, value: customer?.name },
    { label: 'Nome do contato', value: customer?.contactName },
    { label: 'Telefone', value: formatPhone(customer?.phone || '') },
    { label: `${isProductAuto ? 'Sinistro' : 'Identificador'}`, value: code },
  ];

  return (
    <DataBox.Root>
      <DataBox.Header>
        <DataBox.Title>
          <UserIcon />
          Dados do {customerLabel.toLowerCase()}
        </DataBox.Title>
      </DataBox.Header>
      <DataBox.Content>
        {customerInfos.map(({ label, value }) => (
          <DataBox.Item key={label}>
            <DataBox.Label>{label}</DataBox.Label>
            <DataBox.Value>{value || '-'}</DataBox.Value>
          </DataBox.Item>
        ))}
      </DataBox.Content>
    </DataBox.Root>
  );
};

export default CustomerData;
