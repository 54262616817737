import React, { createContext, useMemo, useState } from 'react';

import {
  IPagination,
  IUsers,
  IUsersManagementDashboardDispatchContextData,
  IUsersManagementDashboardStateContextData,
} from './types';

export const UsersManagementDashboardDispatchContext = createContext<IUsersManagementDashboardDispatchContextData>(
  {} as IUsersManagementDashboardDispatchContextData
);

export const UsersManagementDashboardStateContext = createContext<IUsersManagementDashboardStateContextData>(
  {} as IUsersManagementDashboardStateContextData
);

export const UsersManagementDashboardContainer: React.FC = ({ children }) => {
  const [users, setUsers] = useState<IUsers[]>([]);
  const [pagination, setPagination] = useState<IPagination>({ pages: 0, items: 0, currentPage: 0, itemsPerPage: 12 });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const managementDashboardState = useMemo(
    () => ({
      users,
      pagination,
      isLoading,
    }),
    [users, isLoading, pagination]
  );

  return (
    <UsersManagementDashboardStateContext.Provider value={managementDashboardState}>
      <UsersManagementDashboardDispatchContext.Provider value={{ setUsers, setIsLoading, setPagination }}>
        <>{children}</>
      </UsersManagementDashboardDispatchContext.Provider>
    </UsersManagementDashboardStateContext.Provider>
  );
};
