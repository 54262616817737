import styled from 'styled-components';
import { Stepper as MuiStepper, Step as MuiStep, StepLabel, StepContent } from '@mui/material';
import { IStyledContentProps, IStyledIconProps } from './InspectionSteps.types';

export const Header = styled(StepLabel)`
  padding: 0px !important;

  .MuiStepLabel-label {
    color: #a6adb6 !important;
    font-family: 'Montserrat' !important;
    font-size: 16px !important;
    font-weight: 600 !important;

    &.Mui-active {
      color: #a6adb6 !important;
    }

    &.Mui-completed {
      color: #099857 !important;
    }

    &.Mui-error {
      color: #c73c3c !important;
    }
  }
`;

export const Semibold = styled.span`
  font-weight: 600;
`;

export const Content = styled(StepContent)<IStyledContentProps>`
  display: flex;
  flex-direction: column;
  margin-left: 14px !important;
  padding-bottom: 20px;

  font-family: Montserrat;
  font-size: 14px;
  text-align: left;

  border-left: ${(props) => getContentBorderLine(props)} !important;
`;

const getContentBorderLine = ({ last, completed, error }: IStyledContentProps) => {
  if (last === 1) return 'none';

  if (error === 1) return '3px solid #C73C3C';

  if (completed === 1) return '3px solid #099857';

  return '3px solid #A6ADB6';
};

export const StatusMessage = styled.div`
  font-weight: 500;
  color: #6a758b;

  margin-bottom: 8px;
`;

export const StatusDate = styled.div`
  font-weight: 400;
  color: #bbc2ce;
`;

export const Step = styled(MuiStep)``;

export const Stepper = styled(MuiStepper)`
  background-color: #fff;
  width: 100%;
`;

export const Icon = styled.div<IStyledIconProps>`
  padding: 10px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;

  background-color: ${(props) => getIconColor(props)};
`;

const getIconColor = ({ error, completed }: IStyledIconProps) => {
  if (error) return '#C73C3C';

  if (completed) return '#099857';

  return '#A6ADB6';
};

export const StatusEmpty = styled.div`
  justify-content: center;
  display: flex;
  padding-bottom: 30px;
  font-weight: 500;
  color: #6a758b;
  font-size: 22px;
`;

export const Title = styled.h1`
  color: #6a758b;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 20px;
  border-radius: 4px;
  padding: 20px 30px;
  background: #ffffff;
`;
