import styled from 'styled-components';
import { IImageComponentProps, IImageContainerProps } from './Image.types';

export const ImageContainer = styled.div<IImageContainerProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow: hidden;
  border-radius: 4px;
`;

export const ImageComponent = styled.img<IImageComponentProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  -webkit-user-drag: none;
`;
