import React from 'react';
import DateService from 'services/DateService/dateService';
import { IBudgetLabor, IBudgetLaborCostTypeEnum } from 'commom/types/Budget.types';
import { priceFormatter } from 'utils/formatPrice';
import {
  CardContainer,
  CardHeader,
  DealDate,
  DealName,
  InformationsContainer,
  InformationsContainerBody,
  InformationsContainerFooter,
  InformationsItem,
  InformationTitle,
  InformationValue,
} from './DealCard.styles';

export interface IDealCardProps {
  deal: IBudgetLabor;
}

const DealCard = ({ deal }: IDealCardProps) => {
  const CostFields = (type: IBudgetLaborCostTypeEnum) => {
    const cost = deal?.cost.find((cost) => cost.type === type);
    return cost ? (
      <InformationsItem key={cost.type}>
        <InformationTitle>{cost.description}</InformationTitle>
        <InformationValue>{priceFormatter.format(cost.price)}</InformationValue>
      </InformationsItem>
    ) : null;
  };

  return (
    <CardContainer>
      <CardHeader>
        <InformationsItem>
          <DealName>{deal?.user?.name ?? deal?.user?.username}</DealName>
        </InformationsItem>
        <InformationsItem>
          <DealDate>{DateService.formatDate(deal?.createdAt)}</DealDate>
        </InformationsItem>
      </CardHeader>
      <InformationsContainer>
        <InformationsContainerBody>
          {CostFields(IBudgetLaborCostTypeEnum.PAINTING)}
          {CostFields(IBudgetLaborCostTypeEnum.METALLIC_PAINTING)}
        </InformationsContainerBody>
        <InformationsContainerBody>
          {CostFields(IBudgetLaborCostTypeEnum.TRICOAT_PAINTING)}
          {CostFields(IBudgetLaborCostTypeEnum.REPAIR)}
        </InformationsContainerBody>
        <InformationsContainerBody>
          {CostFields(IBudgetLaborCostTypeEnum.PEARLIZED_PAINTING)}
          {CostFields(IBudgetLaborCostTypeEnum.LABOR)}
        </InformationsContainerBody>
      </InformationsContainer>
      <InformationsContainerFooter>
        <InformationsItem>
          <InformationTitle>Desconto</InformationTitle>
          <InformationValue>{deal?.discount}%</InformationValue>
        </InformationsItem>
        <InformationsItem>
          <InformationTitle>Majoração</InformationTitle>
          <InformationValue>{deal?.increase}%</InformationValue>
        </InformationsItem>
      </InformationsContainerFooter>
    </CardContainer>
  );
};

export default DealCard;
