import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';
import Edit from 'assets/svg/edit.svg';
import EditColored from 'assets/svg/edit-colored.svg';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px 30px;
  gap: 20px;
  border-radius: 5px;
`;

export const Divider = styled(DividerMui)`
  border-color: #f4f4f4 !important;
`;

export const EditIconButton = styled.div`
  cursor: pointer;
  width: 29px;
  height: 28px;
  transition: 0.3s;
  background-image: url(${Edit});

  &:hover {
    background-image: url(${EditColored});
  }
`;
