import styled from 'styled-components';
import { ButtonGroup as ButtonGroupBase } from '@mui/material';
import { Button as ButtonBase } from 'components/Button';
import { TextField as TextFieldComponent } from 'components';

export const ButtonGroup = styled(ButtonGroupBase)`
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const Button = styled(ButtonBase)`
  height: 40px !important;
  padding: 0 !important;
  min-width: 35px !important;
  border: 1px solid #ced6e5 !important;

  .MuiSvgIcon-root {
    font-size: 20px !important;
  }

  &.MuiButton-root.btn-left {
    border-radius: 4px 0px 0px 4px !important;
  }

  &.MuiButton-root.btn-right {
    border-radius: 0px 4px 4px 0px !important;
  }
`;

export const TextField = styled(TextFieldComponent)`
  .MuiInputBase-input {
    text-align: center !important;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px !important;
  }
`;
