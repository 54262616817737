import ApiService from 'services/ApiService';
import ErrorNotFound from 'errors/ErrorNotFound';
import { IAccount } from 'contexts/auth/types';
import { IAuthService } from './authService.types';
import { Buffer } from 'buffer';

const ACCOUNT_INFO = '@ACCOUNT_INFO';
const TOKEN = '@TOKEN';

const AuthService: IAuthService = {
  login: async (username: string, password: string) => {
    const params = new URLSearchParams();

    params.append('username', username);
    params.append('password', password);

    await ApiService.post('/login', params);
  },

  logout: async () => {
    await ApiService.post('/logout');
  },

  getAccountByUsername: async (username: string) => {
    const { data: account } = await ApiService.get<IAccount>(`/accounts/${username}`);

    if (!account) throw new ErrorNotFound();

    return account;
  },

  getAccountByBase64: (token: string) => {
    const decodeToken = Buffer.from(token, 'base64').toString('utf-8');

    const [username, password] = decodeToken.split(':');

    return {
      username,
      password,
    };
  },

  setAccountInLocalStorage: (account: IAccount) => {
    localStorage.setItem(ACCOUNT_INFO, JSON.stringify(account));
  },

  getAccountInLocalStorage: () => {
    const account = localStorage.getItem(ACCOUNT_INFO);

    if (!account) return {} as IAccount;

    return JSON.parse(account);
  },

  deleteAccountFromLocalStorage: () => {
    localStorage.removeItem(ACCOUNT_INFO);
  },

  setTokenInLocalStorage: (token: string) => {
    localStorage.setItem(TOKEN, token);
  },

  getTokenInLocalStorage: () => {
    const token = localStorage.getItem(TOKEN);
    if (!token) return '';
    return token;
  },
};

export default AuthService;
