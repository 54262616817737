import ApiService from 'services/ApiService';
import { IFeatureTogglesService, IFeatureToggles } from './types';

const TOGGLES = '@TOGGLES';

const FeatureTogglesService: IFeatureTogglesService = {
  getToggles: async () => {
    const { data } = await ApiService.get<IFeatureToggles>(`/toggles`);

    return data;
  },

  setFeatureTogglesInLocalStorage: (toggles: IFeatureToggles) => {
    localStorage.setItem(TOGGLES, JSON.stringify(toggles));
  },

  getFeatureTogglesInLocalStorage: () => {
    const toggles = localStorage.getItem(TOGGLES);

    if (!toggles) return {} as IFeatureToggles;

    return JSON.parse(toggles);
  },

  deleteFeatureTogglesInLocalStorage: () => {
    localStorage.removeItem(TOGGLES);
  },
};
export default FeatureTogglesService;
