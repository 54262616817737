import styled from 'styled-components';

export const SectionVersion = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: #000;
  padding: 10px 25px;
`;

export const TemplateContainer = styled.div`
  background-color: #fff;
  min-width: 793px;
  margin: auto;
  padding: 0 20px 20px 20px;
  font-family: 'Montserrat', sans-serif;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RowWithLogo = styled.div`
  display: flex;
`;

export const LogoContainer = styled.div`
  border-right: 1px solid #00000017;
  display: flex;
  width: 250px;

  img {
    width: 50px;
    border-radius: 50%;
    margin: auto;
  }
`;

export const SectionTitle = styled.div`
  color: #fff;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
`;

export const SectionDate = styled.div`
  color: #fff;
  text-align: right;
  font-family: 'Roboto';
  font-size: 12px;
  margin: auto 0;
  font-weight: 500;
`;

export const SectionItem = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;
