import { RootState } from 'stores/store';

export const selectInspection = (state: RootState) => {
  return state.presentInspection.inspection;
};

export const selectFipValue = (state: RootState) => {
  return state.presentInspection.inspection.product?.vehicle?.fipeInfo?.currentPrice;
};

export const selectVehicle = (state: RootState) => {
  return state.presentInspection.inspection.product?.vehicle;
};

export const selectVehiclePaintingType = (state: RootState) => {
  return state.presentInspection.inspection.product?.vehicle?.paintingType;
};

export const selectCompanyId = (state: RootState) => {
  return state.presentInspection.inspection.companyId;
};

export const selectCurrentResponsible = (state: RootState) => {
  return state.presentInspection.inspection?.currentResponsible;
};
