import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Label } from 'components';
import AutocompleteField from 'components/AutocompleteField/AutocompleteField';
import { IOptions } from 'components/Select/Select.types';
import RegulatorService from 'services/ApiService/RegulatorService/regulatorService';
import { toast } from 'react-toastify';
import { IRegulator } from 'pages/SchedulerList/components/Inspection';
import { ISelectRegulatorProps } from './SeletectRegulator.types';

export const SelectRegulator = ({ regulatorSelected, setRegulatorSelected, companies }: ISelectRegulatorProps) => {
  const [regulatorsList, setRegulatorsList] = useState<IOptions[]>([]);
  const companiesRef = useRef(['']);

  const handleChangeRegulator = (value: IOptions) => {
    return value ? setRegulatorSelected(value) : setRegulatorSelected({ name: '', value: '' });
  };

  const regulatorsToOptions = (regulators: IRegulator[]): IOptions[] => {
    return regulators.map(({ id: value, username: name }) => ({ value, name }));
  };

  const getRegulators = useCallback(async (companiesIds: string[]): Promise<IOptions[]> => {
    try {
      const regulators = await RegulatorService.getRegulators(companiesIds);
      return regulatorsToOptions(regulators);
    } catch (error) {
      toast.error('Problema ao carregar os peritos, tente novamente.');

      return [];
    }
  }, []);

  const handleRegulators = useCallback(async (): Promise<void> => {
    const regulators = await getRegulators(companies);
    setRegulatorsList(regulators);
  }, [companies, getRegulators]);

  const companiesChanged = useCallback(() => {
    return companiesRef.current[0] !== companies[0] && companies.length > 0;
  }, [companies]);

  useEffect(() => {
    if (companiesChanged()) {
      companiesRef.current = companies;
      setRegulatorSelected({ name: '', value: '' });
      handleRegulators();
    }
  }, [handleRegulators, companies, companiesChanged, setRegulatorSelected]);

  return (
    <>
      {regulatorsList.length > 0 && (
        <>
          <Label htmlFor="regulator" testID="label-regulator">
            Perito - Opcional
          </Label>

          <AutocompleteField
            required={false}
            testID="select-regulator"
            id="regulator"
            options={regulatorsList}
            selected={regulatorSelected.name}
            placeholder="Insira o perito"
            onChange={(e: React.SyntheticEvent<Element, Event>, value: IOptions) => handleChangeRegulator(value)}
          />
        </>
      )}
    </>
  );
};
