import DocIconSvg from 'assets/svg/icon-doc.svg';
import PdfIconSvg from 'assets/svg/icon-pdf.svg';
import CsvIconSvg from 'assets/svg/icon-csv.svg';
import XlsxIconSvg from 'assets/svg/icon-xlsx.svg';
import JpgIconSvg from 'assets/svg/icon-jpg.svg';
import PngIconSvg from 'assets/svg/icon-png.svg';
import {
  DocumentTypeEnum,
  IDocument,
  DocumentCategoryEnum,
} from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { ImageListType } from 'react-images-uploading';
import { IFilterInspectionsByActiveConsumerCompanyOutput } from 'services/ApiService/CompanyService/companyService.types';

export interface IDocumentService {
  fetchDocuments: (document: IDocumentFileBase) => Promise<IFetchDocument>;
  downloadDocuments: (documents: IDocumentFileBase[]) => Promise<IFetchDocument[]>;
  downloadFileZip: (documents: IDocument[], fileZipName: string) => Promise<void>;
  exportFileZip: (fetchDocuments: IFetchDocument[], fileZipName: string) => void;
  openPdfinNewTab: (documentData: IDocument) => Promise<void>;
  downloadDocument: (doc: IDocument) => void;
  downloadXmlDocument: (url: string, filename: string) => void;
  transformImageListTypeInInspectionDocument(
    document: ImageListType,
    totalDocumentWithOriginUploadKite: number,
    documentCategory: DocumentCategoryEnum
  ): IUploadDocument[];
  convertToBlob: (documentUri: string | undefined) => Promise<Blob>;
  handleBase64: (document: string) => string;
  transformDocuments: (documents: ImageListType) => IUploadDocument[];
  sendDocuments: (id: string, documents: ImageListType) => Promise<IUploadDocument[]>;
  sendResaleDocument: (inspectionId: string, baseXmlPath: string) => Promise<string>;
  uploadDocumentsToAwsS3(id: string, documents: IUploadDocument[]): Promise<IUploadDocument[]>;
  filterAllDocumentsUploadedToAwsS3(documents: IUploadDocument[]): IUploadDocument[];
  saveDocuments: (inspectionId: string, inspectionDocuments: IUploadDocument[]) => Promise<IDocument[]>;
  uploadDocuments(
    inspectionId: string,
    documents: ImageListType,
    totalDocumentsWithOriginUploadKite: number,
    documentCategory: DocumentCategoryEnum
  ): Promise<IDocument[]>;
  convertDocumentToCSV: (id: string | undefined) => Promise<void>;
  generateReportCSV: (
    generateReport: IFilterInspectionsByActiveConsumerCompanyOutput
  ) => Promise<IDownloadDocumentResponse>;
  downloadReportCsvZip: (reportCsv: IFilterInspectionsByActiveConsumerCompanyOutput) => Promise<void>;
  getReportObjectFromUrl: (urls: string[]) => IDocumentFileBase[];
  getFilenameFromUrl: (url: string) => string;
  removeFileExtension: (filename: string) => string;
  documentIcon: (typeDocument: string) => string;
  deleteDocuments: (filesIds: string[], inspectionId: string) => Promise<void>;
}

export interface IFetchDocument {
  blob: Promise<Blob>;
  fileName: string;
  fileType: string;
}

export interface IUploadDocument {
  name: string;
  description: string;
  path?: string;
  base64?: string;
  file?: File;
  step: number;
  origin: string;
  type?: DocumentTypeEnum;
  category?: DocumentCategoryEnum;
}

export interface IResultUpdateContract {
  success: number;
  failure: number;
}

export interface IDownloadDocumentResponse {
  path: string[];
}

export interface IDocumentFileBase {
  url: string;
  name: string;
  path: string;
}

export enum FileExtensionEnum {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  TXT = 'txt',
  XLSX = 'xlsx',
  CSV = 'csv',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export const IconType = [
  {
    type: FileExtensionEnum.PDF,
    icon: PdfIconSvg,
  },
  {
    type: FileExtensionEnum.DOC,
    icon: DocIconSvg,
  },
  {
    type: FileExtensionEnum.DOCX,
    icon: DocIconSvg,
  },
  {
    type: FileExtensionEnum.XLSX,
    icon: XlsxIconSvg,
  },
  {
    type: FileExtensionEnum.CSV,
    icon: CsvIconSvg,
  },
  {
    type: FileExtensionEnum.JPG,
    icon: JpgIconSvg,
  },
  {
    type: FileExtensionEnum.JPEG,
    icon: JpgIconSvg,
  },
  {
    type: FileExtensionEnum.PNG,
    icon: PngIconSvg,
  },
];
