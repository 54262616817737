import styled from 'styled-components';

export const PageBreakInside = styled.div`
  @media print {
    page-break-inside: avoid;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ServiceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  border-top: 1px solid #00000017;
  border-bottom: 1px solid #00000017;

  padding: 10px 0px;
`;

export const ServiceBadge = styled.div`
  padding: 2px 7px;
  border-radius: 12px;
  font-size: 11px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  &.bg-orange {
    background: #f67228;
  }

  &.bg-purple {
    background: #3821ae;
  }

  &.bg-red {
    background: #b60000;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #6a758b;
`;

export const ValueBadge = styled.div`
  padding: 2px 7px;
  border-radius: 12px;
  font-size: 11px;
  border: 1px solid #434343;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`;

export const PartType = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 10px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: auto;
`;

export const LabelText = styled.div`
  padding: 0.5px 2px;
  margin-right: auto;
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 600;

  &.label-origin {
    border: 0.6px solid #000000;
    color: #000;
  }

  &.label-green {
    border: 0.6px solid #099857;
    color: #099857;
  }

  &.label-genuine {
    border: 0.6px solid #0e86ca;
    color: #0e86ca;
  }

  &.label-other-sources {
    border: 0.6px solid #6a758b;
    color: #6a758b;
  }
`;

export const LabelDescription = styled.div`
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  color: #282828;
`;

export const IconImage = styled.img`
  width: 10px;
`;
