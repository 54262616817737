import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IButtonProps } from './Button.types';
import { theme } from './Button.styles';
import * as Styles from './Button.styles';

export const Button = ({
  onClick,
  text,
  testID = 'button',
  disabled = false,
  loading = false,
  loadingSize = 20,
  loadingColor = '#FFF',
  className,
  color = 'primary',
  disableElevation = true,
  variant = 'contained',
  size = 'medium',
  endIcon = null,
  startIcon = null,
  type = 'button',
  fullWidth = false,
  ariaLabel,
  buttonShape,
  onlyIcon = false,
  form,
  capitalize = false,
}: IButtonProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Styles.Button
        className={`${className}`}
        data-testid={testID}
        onClick={onClick}
        disabled={disabled || loading}
        color={color}
        disableElevation={disableElevation}
        variant={variant}
        size={size}
        endIcon={loading ? null : endIcon}
        startIcon={loading ? null : startIcon}
        type={type}
        fullWidth={fullWidth}
        aria-label={ariaLabel}
        button-shape={`${buttonShape}`}
        form={form}
        capitalize={`${capitalize}`}
      >
        {loading ? (
          <CircularProgress size={loadingSize} style={{ color: loadingColor }} data-testid="loading-button" />
        ) : (
          <Styles.ButtonText onlyIcon={onlyIcon} startIcon={!!startIcon} endIcon={!!endIcon}>
            {text}
          </Styles.ButtonText>
        )}
      </Styles.Button>
    </ThemeProvider>
  );
};
