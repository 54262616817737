import React, { useState } from 'react';
import { Button } from 'components';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import Edit from '@material-ui/icons/Edit';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import EditInspectionDrawer from 'components/Drawer/EditInspectionDrawer/EditInspectionDrawer';

interface IEditInspectionActionProps {
  inspection: IInspection;
}

const EditInspectionAction = ({ inspection }: IEditInspectionActionProps) => {
  const { currentStatus } = inspection;
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const { isAdmin } = useAuthDispatch();

  const isCurrentStatusAbleToEdit = currentStatus.type < StatusEnum.INSPECTION_STARTED;

  const handleCloseEditDrawer = () => {
    setShowEditDrawer(false);
  };

  return isAdmin() && isCurrentStatusAbleToEdit ? (
    <>
      <Button
        type="button"
        testID="edit-inspection"
        text="EDITAR"
        size="small"
        onClick={() => setShowEditDrawer(true)}
        startIcon={<Edit />}
        variant="outlined"
      />
      <EditInspectionDrawer
        inspection={inspection}
        openDrawer={showEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        testID="edit-inspection-drawer"
      />
    </>
  ) : null;
};

export default EditInspectionAction;
