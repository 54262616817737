import { Label } from 'components/Label';
import { GroupInputRow, InputWrapper } from './PartDrawer.styles';
import NumericFormat from 'react-number-format';
import React from 'react';
import { TextField } from 'components/TextField';
import { usePartContext } from 'contexts/budgetPart/BudgetPartContext';
import { calculateNetPrice } from 'utils/calculateNetPrice';
import { useAppSelector } from 'hooks/hooks';
import { selectLaborDiscount, selectLaborIncrease } from 'stores/presentBudget/presentBudget.selector';

const PartPrice = () => {
  const discount = useAppSelector(selectLaborDiscount);
  const increase = useAppSelector(selectLaborIncrease);
  const { partPrice, partOriginalPrice, setPartPrice } = usePartContext();

  return (
    <>
      <GroupInputRow>
        <InputWrapper>
          <Label htmlFor="input-original-price-value">Valor original</Label>
          <NumericFormat
            id="input-original-price-value"
            decimalSeparator=","
            prefix="R$ "
            disabled
            customInput={TextField}
            value={partOriginalPrice}
            {...{ variant: 'outlined' }}
          />
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="input-price-value">Com desconto e majoração</Label>
          <NumericFormat
            id="input-price-value"
            decimalSeparator=","
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale={true}
            disabled
            customInput={TextField}
            value={partOriginalPrice ? calculateNetPrice(partOriginalPrice, discount, increase) : partOriginalPrice}
            {...{ variant: 'outlined' }}
          />
        </InputWrapper>
      </GroupInputRow>

      <InputWrapper>
        <Label htmlFor="input-part-price-value">Valor líquido da peça</Label>
        <NumericFormat
          id="input-part-price-value"
          placeholder="Insira o valor"
          prefix="R$ "
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          customInput={TextField}
          value={partPrice}
          onValueChange={({ floatValue }) => setPartPrice(floatValue || 0)}
          {...{ variant: 'outlined' }}
        />
      </InputWrapper>
    </>
  );
};

export default PartPrice;
