import { useContext } from 'react';

import { INotificationsContext } from './Notifications.types';

import { NotificationsContext } from './NotificationsProvider';

export const useNotificationsContext = (): INotificationsContext => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('useInvoices must be used within a InvoicesProvider');
  }

  return context;
};
