import React from 'react';
import { Button } from 'components/Button/Button';
import { IConfirmDialogProps, SubtitleColor } from './ConfirmDialog.types';
import * as Style from './ConfirmDialog.styles';

export const ConfirmDialog = ({
  dialogVisibility,
  handleClose,
  testID,
  onSubmit,
  title,
  subtitle,
  subtitleColor = SubtitleColor.DARK_RED,
  confirmText = 'Sim',
  cancelText = 'Não',
}: IConfirmDialogProps) => {
  const handleSubmit = (): void => {
    onSubmit();
    handleClose();
  };
  return (
    <Style.DialogBase data-testid={testID} open={dialogVisibility} onClose={handleClose}>
      <Style.TitleWrapper>
        <Style.Title>{title}</Style.Title>
        <Style.Subtitle style={{ color: subtitleColor }}>{subtitle}</Style.Subtitle>
      </Style.TitleWrapper>
      <Style.ButtonWrapper>
        <Button testID="no-button" onClick={handleClose} variant="outlined" text={cancelText} />
        <Button testID="yes-button" type="button" size="large" text={confirmText} onClick={handleSubmit} />
      </Style.ButtonWrapper>
    </Style.DialogBase>
  );
};
