import React from 'react';
import { useHistory } from 'react-router-dom';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { Button } from 'components';

interface IOpenTransmissionActionProps {
  inspection: IInspection;
}

const OpenTransmissionAction = ({ inspection }: IOpenTransmissionActionProps) => {
  const { id: inspectionId, transmissionSession, currentStatus } = inspection;
  const isCreatedOrSent = InspectionStatusService.isCreatedOrSent(currentStatus);
  const history = useHistory();

  const showButton = (): boolean => {
    return transmissionSession !== undefined;
  };

  const redirect = () => {
    history.push(`/agendamentos/v2/detalhes/${inspectionId}/transmissao${window.location.search}`);
  };

  return (
    <>
      {showButton() && (
        <Button
          type="button"
          testID="trasmission-admin-button"
          text={isCreatedOrSent ? 'ABRIR TRANSMISSÃO' : 'VER TRANSMISSÃO'}
          variant={isCreatedOrSent ? 'contained' : 'outlined'}
          size="small"
          onClick={redirect}
        />
      )}
    </>
  );
};

export default OpenTransmissionAction;
