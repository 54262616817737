import React, { useState } from 'react';
import {
  faChartPie,
  faUsers,
  faFileCirclePlus,
  faListAlt,
  faClipboardList,
  faBell,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { useAuthState, useAuthDispatch } from 'contexts/auth/useAuth';
import ableLogo from 'assets/svg/able-logo-dark.svg';
import { useModules } from 'contexts/modules/useModules';
import { ModuleEnum } from 'utils/ModulesEnum';
import NotificationsDrawer from 'components/Drawer/NotificationsDrawer/NotificationsDrawer';
import { useNotificationsContext } from 'contexts/notifications/useNotificationsContext';
import { ISidenavProps } from './Sidenav.types';
import * as S from './Sidenav.styles';

export const Sidenav = ({ testID = 'sidenav', toggleButton }: ISidenavProps) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const { account } = useAuthState();
  const { isAdmin, isOwner, isInsuranceCompany } = useAuthDispatch();
  const { isModuleActive } = useModules();

  const isAdminOrInsuranceCompany = isAdmin() || isInsuranceCompany();

  const { hasNewNotifications } = useNotificationsContext();

  const showMetricsScreen = isModuleActive(ModuleEnum.INSPECTION_MANAGEMENT) && isAdminOrInsuranceCompany;

  const renderNotifications = () => {
    return (
      <>
        <S.NavTooltip title="Notificações" placement="top">
          <S.NavItem onClick={() => setShowNotifications(true)}>
            {hasNewNotifications && <S.NotificationBadge data-testid="notification-badge" />}
            <S.ListIcon
              $isActive={showNotifications || hasNewNotifications}
              icon={faBell}
              data-testid="notification-icon"
            />
          </S.NavItem>
        </S.NavTooltip>

        {showNotifications && (
          <NotificationsDrawer openDrawer={showNotifications} onCloseDrawer={() => setShowNotifications(false)} />
        )}
      </>
    );
  };

  return (
    <S.SidenavWrapper data-testid={testID}>
      <S.TopContent>
        <S.SidenavLogoWrapper>
          <S.SidenavLogo src={ableLogo} data-testid="logo" />
        </S.SidenavLogoWrapper>
        <S.NavTooltip title="Gerar Atendimento" placement="bottom">
          <S.IconLink exact activeClassName="active" to="/">
            <S.ListIcon icon={faFileCirclePlus} data-testid="form-icon" />
          </S.IconLink>
        </S.NavTooltip>
        <S.NavTooltip title="Lista de Atendimentos" placement="bottom">
          <S.IconLink to="/agendamentos">
            <S.ListIcon icon={faClipboardList} data-testid="list-icon" />
          </S.IconLink>
        </S.NavTooltip>

        {isAdminOrInsuranceCompany && (
          <S.NavTooltip title="Gestão de Atendimentos" placement="bottom">
            <S.IconLink to="/management-dashboard">
              <S.ListIcon icon={faListAlt} data-testid="management-dashboard-icon" />
            </S.IconLink>
          </S.NavTooltip>
        )}

        {isAdmin() && (
          <S.NavTooltip title="Gestão de Usuários" placement="bottom">
            <S.IconLink to="/users-dashboard">
              <S.ListIcon icon={faUsers} data-testid="users-management-icon" />
            </S.IconLink>
          </S.NavTooltip>
        )}

        {showMetricsScreen && (
          <S.NavTooltip title="Métricas" placement="bottom">
            <S.IconLink to="/metrics">
              <S.ListIcon icon={faChartPie} data-testid="metrics-dashboard-icon" />
            </S.IconLink>
          </S.NavTooltip>
        )}

        {isOwner() && (
          <S.NavTooltip title="Cobranças" placement="bottom">
            <S.IconLink to="/invoices">
              <S.ListIcon icon={faFileInvoiceDollar} data-testid="invoice-icon" />
            </S.IconLink>
          </S.NavTooltip>
        )}
      </S.TopContent>
      <S.BottomContent>
        {renderNotifications()}

        {!account.linkAuthenticated && <>{toggleButton}</>}
      </S.BottomContent>
    </S.SidenavWrapper>
  );
};
