import styled from 'styled-components';

export const VehicleSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;
  padding: 6px 17px;
  gap: 6px;
  row-gap: 15px;

  border-bottom: 1px solid #00000017;
  border-right: 1px solid #00000017;
`;

export const HeaderSection = styled.div`
  display: flex;
  gap: 10px;

  h1 {
    font-size: 14px;
  }
`;

export const BodySection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  font-size: 10px;
  gap: 10px;
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Label = styled.div`
  font-weight: 700;
  line-height: 7px;
  letter-spacing: 0em;
`;

export const Value = styled.div`
  font-weight: 500;
  letter-spacing: 0em;
`;

export const VersionValue = styled(Value)`
  text-wrap: wrap;
  width: 120px;
`;
