import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  height: auto;
`;

export const Header = styled.div`
  padding: 12px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #ececec;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  color: #000000;
`;

export const QuestionList = styled.div`
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
