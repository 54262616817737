import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  p {
    width: 180px;
    font-family: Montserrat;
    font-size: 16px;
    text-align: center;
    color: #bcc4d2;
  }
`;
