import { ReactNode } from 'react';
import { IInspection } from '../Inspection/Inspection.types';

export interface IInspectionFilesProps {
  inspection: IInspection;
  actionsVisible?: boolean;
}

export interface IAccordion {
  title: string;
  children: ReactNode;
  testID?: string;
  defaultExpanded?: boolean;
}

export enum ACTIVE_TAB_ENUM {
  TAB_PHOTO = 'tab-photos',
  TAB_DOCS = 'tab-documents',
}
