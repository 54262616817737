import React, { useState } from 'react';
import { Drawer } from 'components/Drawer';
import { BackButton, Button, Label, TextField } from 'components';
import { useForm } from 'react-hook-form';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import { formValidations } from 'utils/form/validations';
import WorkshopService from 'services/ApiService/WorkshopService/WorkshopService';
import { IWorkshop, IWorkshopForm } from 'services/ApiService/WorkshopService/WorkshopService.types';
import { ActionsContainer, FormContent, Header, Separator } from './WorkshopDrawer.styles';

interface ICreateWorkshopDrawerProps {
  backToSearch: () => void;
  onCloseDrawer: () => void;
  setSelectedWorkshop: React.Dispatch<React.SetStateAction<IWorkshop | null>>;
}

interface IFormSchemaType {
  cnpj: string;
  name: string;
  phone: string;
  email: string;
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
}

const formSchemaValidations = {
  phone: {
    required: {
      value: false,
      message: 'Digite o número do telefone',
    },
    pattern: { ...formValidations.phone.pattern },
  },
  cep: {
    required: {
      value: false,
      message: 'Digite o CEP da oficina',
    },
    pattern: { ...formValidations.cep.pattern },
  },
};

const inputProps = {
  cnpj: {
    'data-testid': 'input-cnpj',
  },
  name: {
    'data-testid': 'input-name',
  },
  phone: {
    'data-testid': 'input-phone',
  },
  email: {
    'data-testid': 'input-email',
  },
  cep: {
    'data-testid': 'input-cep',
  },
  state: {
    'data-testid': 'input-state',
  },
  city: {
    'data-testid': 'input-city',
  },
  neighborhood: {
    'data-testid': 'input-neighborhood',
  },
  street: {
    'data-testid': 'input-street',
  },
};

const CreateWorkshopDrawer = ({ backToSearch, onCloseDrawer, setSelectedWorkshop }: ICreateWorkshopDrawerProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { register, errors, handleSubmit } = useForm<IFormSchemaType>({
    mode: 'onBlur',
    defaultValues: {
      cnpj: '',
      name: '',
      phone: '',
      email: '',
      cep: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
    },
  });

  const handleInputState = (inputName: keyof IFormSchemaType): string => {
    return errors[inputName] ? 'invalid' : 'default';
  };

  const hasError = (inputName: keyof IFormSchemaType): boolean => {
    return errors[inputName] !== undefined;
  };

  const createWorkshop = async (formData: IFormSchemaType): Promise<IWorkshop | null> => {
    try {
      setSubmitLoading(true);
      const requestBody: IWorkshopForm = {
        cnpj: formData.cnpj,
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        address: {
          cep: formData.cep,
          uf: formData.state,
          city: formData.city,
          neighborhood: formData.neighborhood,
          fullAddress: formData.street,
        },
      };
      const workshopResponse = await WorkshopService.create(requestBody);

      setSubmitLoading(false);

      return workshopResponse;
    } catch (error) {
      toast.error('Erro ao cadastrar oficina.');
      setSubmitLoading(false);
      return null;
    }
  };

  const onSubmit = async (formData: IFormSchemaType) => {
    const createdWorkshop = await createWorkshop(formData);

    if (createdWorkshop) {
      setSelectedWorkshop(createdWorkshop);
      onCloseDrawer();
    }
  };

  return (
    <>
      <Header>
        <BackButton onClick={backToSearch} />
        <Drawer.Title>Cadastrar oficina</Drawer.Title>
      </Header>

      <FormContent id="form-create-workshop" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Label htmlFor="cnpj" testID="label-cnpj">
            CNPJ
          </Label>
          <TextField
            ariaLabelledby="cnpj"
            id="cnpj"
            name="cnpj"
            role="cnpj"
            type="text"
            variant="outlined"
            placeholder="Digite o CNPJ da oficina"
            mask="00.000.000/0000-00"
            error={hasError('cnpj')}
            state={handleInputState('cnpj')}
            inputProps={inputProps.cnpj}
            inputRef={register(formValidations.cnpj)}
            helperText={errors?.cnpj?.message}
          />
        </div>

        <div>
          <Label htmlFor="name" testID="label-name">
            Oficina
          </Label>
          <TextField
            ariaLabelledby="name"
            id="name"
            name="name"
            role="name"
            type="text"
            variant="outlined"
            placeholder="Digite o nome da oficina"
            error={hasError('name')}
            state={handleInputState('name')}
            inputProps={inputProps.name}
            helperText={errors?.name?.message}
          />
        </div>

        <Divider />

        <div>
          <Label htmlFor="phone" testID="label-phone">
            Telefone
          </Label>
          <TextField
            ariaLabelledby="phone"
            id="phone"
            name="phone"
            role="phone"
            type="phone"
            variant="outlined"
            placeholder="Digite o telefone da oficina"
            mask="(00) 0 0000-0000"
            error={hasError('phone')}
            state={handleInputState('phone')}
            inputProps={inputProps.phone}
            inputRef={register(formSchemaValidations.phone)}
            helperText={errors?.phone?.message}
          />
        </div>

        <div>
          <Label htmlFor="email" testID="label-email">
            E-mail
          </Label>
          <TextField
            ariaLabelledby="email"
            id="email"
            name="email"
            role="email"
            type="email"
            variant="outlined"
            placeholder="Digite o e-mail da oficina"
            error={hasError('email')}
            state={handleInputState('email')}
            inputProps={inputProps.email}
            helperText={errors?.email?.message}
          />
        </div>

        <Divider />

        <div>
          <Label htmlFor="cep" testID="label-cep">
            CEP
          </Label>
          <TextField
            ariaLabelledby="cep"
            id="cep"
            name="cep"
            role="cep"
            type="cep"
            variant="outlined"
            placeholder="Digite o cep da oficina"
            mask="00000-000"
            error={hasError('cep')}
            state={handleInputState('cep')}
            inputProps={inputProps.cep}
            inputRef={register(formSchemaValidations.cep)}
            helperText={errors?.cep?.message}
          />
        </div>

        <div>
          <Label htmlFor="state" testID="label-state">
            Estado
          </Label>
          <TextField
            ariaLabelledby="state"
            id="state"
            name="state"
            role="state"
            type="state"
            variant="outlined"
            placeholder="Digite o estado da oficina"
            error={hasError('state')}
            state={handleInputState('state')}
            inputProps={inputProps.state}
            helperText={errors?.state?.message}
          />
        </div>

        <div>
          <Label htmlFor="city" testID="label-city">
            Cidade
          </Label>
          <TextField
            ariaLabelledby="city"
            id="city"
            name="city"
            role="city"
            type="city"
            variant="outlined"
            placeholder="Digite a cidade da oficina"
            error={hasError('city')}
            state={handleInputState('city')}
            inputProps={inputProps.city}
            helperText={errors?.city?.message}
          />
        </div>

        <div>
          <Label htmlFor="neighborhood" testID="label-neighborhood">
            Bairro
          </Label>
          <TextField
            ariaLabelledby="neighborhood"
            id="neighborhood"
            name="neighborhood"
            role="neighborhood"
            type="neighborhood"
            variant="outlined"
            placeholder="Digite a bairro da oficina"
            error={hasError('neighborhood')}
            state={handleInputState('neighborhood')}
            inputProps={inputProps.neighborhood}
            helperText={errors?.neighborhood?.message}
          />
        </div>

        <div>
          <Label htmlFor="street" testID="label-street">
            Rua
          </Label>
          <TextField
            ariaLabelledby="street"
            id="street"
            name="street"
            role="street"
            type="street"
            variant="outlined"
            placeholder="Digite a rua da oficina"
            error={hasError('street')}
            state={handleInputState('street')}
            inputProps={inputProps.street}
            helperText={errors?.street?.message}
          />
        </div>
      </FormContent>

      <Separator />
      <ActionsContainer>
        <Button variant="outlined" text="cancelar" onClick={() => onCloseDrawer()} />
        <Button
          variant="contained"
          text="cadastrar"
          form="form-create-workshop"
          type="submit"
          loading={submitLoading}
        />
      </ActionsContainer>
    </>
  );
};

export default CreateWorkshopDrawer;
