/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import { Icon, withStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { IUsersIcon } from './Sidenav.types';

interface IListIconStyleProps {
  $isActive?: boolean;
}

export const SidenavWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex: 0 0 60px;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  width: 60px;
  height: 100vh;
  position: fixed;
  z-index: 30;
  background: black;
`;

export const SidenavLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SidenavLogo = styled.img`
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 30px;
  margin-bottom: 20px;
  width: 35px;
`;

export const IconLink = styled(NavLink)`
  border-radius: 10px;
  margin-bottom: 30px;
  text-decoration: none;

  &::after {
    background-color: black;
    border-radius: 50px;
    content: '';
    display: block;
    height: 3px;
  }

  &.active {
    &::after {
      background-color: white;
    }

    .svg-inline--fa {
      color: white;
    }
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ListIcon = styled(FontAwesomeIcon)<IListIconStyleProps>`
  align-items: center;
  border-radius: 10px;
  color: ${({ $isActive }) => ($isActive ? 'white' : 'rgba(255, 255, 255, 0.4)')};
  display: flex;
  height: 30px !important;
  justify-content: center;
  width: 30px !important;
  padding: 6px;

  :hover {
    color: white;
    cursor: pointer;
  }
`;

export const BottomContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const UsersIcon = styled(Icon)<IUsersIcon>`
  align-items: center;
  border-radius: 10px;
  color: ${(props): string => (props.opened === 'true' ? '#000000' : '#939fb6')};
  display: block;
  font-size: 2.5em !important;
  height: 40px !important;
  justify-content: center;
  overflow: visible !important;
  width: 40px !important;

  :hover {
    color: #000000;
    cursor: pointer;
  }

  &::after {
    background-color: ${(props) => (props.opened ? '#000000' : '#ffffff')};
    border-radius: 50px;
    content: '';
    display: block;
    height: 3px;
  }
`;

export const NavTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);

export const NavItem = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const NotificationBadge = styled.div`
  position: absolute;
  top: 8px;
  right: 7px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: red;
  cursor: pointer;
`;
