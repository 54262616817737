import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #6a758b;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #6a758b;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 20px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.label`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  color: #6a758b;
`;

export const Value = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #6a758b;
`;
