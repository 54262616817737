import React, { useState } from 'react';
import { ChangeOperationDialog } from 'pages/ManagementDashboard/components/ChangeOperationDialog/ChangeOperationDialog';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import { CancellationMotiveDialog } from '../../../CancellationMotiveDialog/CancellationMotiveDialog';
import * as Styles from './ActionsMenu.styles';
import { IActionsMenu } from './ActionsMenu.types';

export const ActionsMenu: React.FC<IActionsMenu> = ({
  dataTestId,
  inspectionId,
  companyId,
  isCancelable,
  hasManyOperations,
}) => {
  const closedMenuItem = null as unknown as Element;
  const [menuItemAnchor, setMenuItemAnchor] = useState<Element>(closedMenuItem);
  const [cancellationMotiveDialogIsOpen, setCancellationMotiveDialogVisibility] = useState(false);
  const [changeOperationDialogIsOpen, setChangeOperationDialogVisibility] = useState(false);
  const open = Boolean(menuItemAnchor);
  const { isInsuranceCompany } = useAuthDispatch();

  const openMenuItem = (el: Element): void => {
    setMenuItemAnchor(el);
  };

  const handleClose = (): void => {
    setMenuItemAnchor(closedMenuItem);
  };

  const showCancelAction = isCancelable && !isInsuranceCompany();

  const showChangeOperationAction = hasManyOperations && !isInsuranceCompany();

  return (
    <>
      <Styles.ActionsIconWrapper
        data-testid={dataTestId}
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(props): void => openMenuItem(props.currentTarget)}
      >
        <Styles.ActionsMenuIcon />
      </Styles.ActionsIconWrapper>
      <Styles.ActionsMenuWrapper
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={menuItemAnchor}
        open={open}
        onClose={handleClose}
        disableScrollLock={false}
      >
        {(showCancelAction && (
          <Styles.CancelWrapper>
            <Styles.ActionsMenuItem
              data-testid="actions-menu-cancel"
              onClick={(): void => setCancellationMotiveDialogVisibility(true)}
            >
              <Styles.ActionsText>CANCELAR</Styles.ActionsText>
            </Styles.ActionsMenuItem>
            <CancellationMotiveDialog
              dialogVisibility={cancellationMotiveDialogIsOpen}
              handleClose={(): void => {
                setCancellationMotiveDialogVisibility(false);
                setMenuItemAnchor(closedMenuItem);
              }}
              inspectionId={inspectionId}
            />
          </Styles.CancelWrapper>
        )) || (
          <Styles.CancelWrapper>
            <Styles.ActionsMenuItemDisabled data-testid="actions-menu-cancel-disabled">
              CANCELAR
            </Styles.ActionsMenuItemDisabled>
          </Styles.CancelWrapper>
        )}
        {(showChangeOperationAction && (
          <Styles.ChangeOperationWrapper>
            <Styles.ActionsMenuItem
              data-testid="actions-menu-change-operation"
              onClick={(): void => setChangeOperationDialogVisibility(true)}
            >
              <Styles.ActionsText>TROCAR OPERAÇÃO</Styles.ActionsText>
            </Styles.ActionsMenuItem>
            <ChangeOperationDialog
              dialogVisibility={changeOperationDialogIsOpen}
              handleClose={(): void => {
                setChangeOperationDialogVisibility(false);
                setMenuItemAnchor(closedMenuItem);
              }}
              inspectionId={inspectionId}
              companyId={companyId}
            />
          </Styles.ChangeOperationWrapper>
        )) || (
          <Styles.ChangeOperationWrapper>
            <Styles.ActionsMenuItemDisabled data-testid="actions-menu-change-operation-disabled">
              TROCAR OPERAÇÃO
            </Styles.ActionsMenuItemDisabled>
          </Styles.ChangeOperationWrapper>
        )}
      </Styles.ActionsMenuWrapper>
    </>
  );
};
