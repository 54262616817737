import { useAppSelector } from 'hooks/hooks';
import React from 'react';
import { DataBox } from 'components/DataBox';
import { Card, CardBody, CardHeader, CardTitle } from '../InitialData.styles';

const Workshop = () => {
  const workshop = useAppSelector((state) => state.presentBudget.budget.workshop);

  const workshopAddress = workshop?.address
    ? `${workshop?.address?.fullAddress}, ${workshop?.address?.neighborhood}, ${workshop?.address?.city} - ${workshop?.address?.uf} - ${workshop?.address?.cep}`
    : '-';

  const workshopInfos = [
    { label: 'Nome', value: workshop?.name || '-' },
    { label: 'CNPJ', value: workshop?.cnpj || '-' },
    { label: 'Telefone', value: workshop?.phone || '-' },
    { label: 'Email', value: workshop?.email || '-' },
    {
      label: 'Endereço',
      value: workshopAddress,
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>OFICINA</CardTitle>
        </CardHeader>

        <CardBody>
          <DataBox.Root>
            <DataBox.Content>
              {workshopInfos.map(({ label, value }) => (
                <DataBox.Item key={label}>
                  <DataBox.Label>{label}</DataBox.Label>
                  <DataBox.Value>{value}</DataBox.Value>
                </DataBox.Item>
              ))}
            </DataBox.Content>
          </DataBox.Root>
        </CardBody>
      </Card>
    </>
  );
};

export default Workshop;
