import React from 'react';
import * as S from './Metrics.styles';
import { GeneralMetrics } from './components/GeneralMetrics/GeneralMetrics';
import { Charts } from './components/Charts/Charts';
import { MetricsFilter } from './components/MetricsFilter/MetricsFilter';
import { TopTenRegulators } from './components/Regulators/TopTenRegulators';

export const Metrics = () => {
  return (
    <>
      <S.Container data-testid="metrics">
        <S.Content>
          <MetricsFilter />
          <GeneralMetrics />
          <Charts />
          <TopTenRegulators />
        </S.Content>
      </S.Container>
    </>
  );
};
