import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    max-width: 160px;
    max-height: 122px;
  }
`;

export const Description = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #6a758b;
  width: 291px;
`;

export const SubDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #6a758b;
  width: 291px;
  margin-bottom: 10px !important;
  word-wrap: break-word;
`;
