import React from 'react';
import EmptyConclusionSvg from 'assets/svg/conclusion-not-found.svg';
import { Button } from 'components';
import { useAuthDispatch } from 'contexts/auth/useAuth';
import * as S from './EmptyConclusion.styles';
import { IEmptyConclusionProps } from './EmptyConclusion.types';

const EmptyConclusion = ({ testID = 'empty-conclusion-inspection', onOpenConclusionDialog }: IEmptyConclusionProps) => {
  const { isInsuranceCompany } = useAuthDispatch();

  return (
    <S.Container data-testid={testID}>
      <img src={EmptyConclusionSvg} alt="Não há conclusão" data-testid="empty-conclusion-svg" />
      <S.Description>Nenhuma conclusão adicionada.</S.Description>

      <S.SubDescription>Para finalizar um atendimento, é necessário adicionar uma conclusão.</S.SubDescription>
      {!isInsuranceCompany() && (
        <Button
          testID="button-insert-conclusion"
          variant="outlined"
          text="Inserir Conclusão"
          onClick={onOpenConclusionDialog}
        />
      )}
    </S.Container>
  );
};

export default EmptyConclusion;
