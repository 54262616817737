import styled from 'styled-components';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export const HistoryWrapper = styled.div`
  div:last-of-type {
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  display: flex;
  font-size: 22px;
  color: #6a758b;
  font-weight: 600;
  margin: 0 0 20px 0;
`;

export const CardWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #dbd9d9;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 20px 40px;
`;

export const GeolocationCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const TitleCard = styled.span`
  color: #6a758b;
  font-size: 18px;
  font-weight: 700;
`;

export const DateCard = styled.span`
  margin: 0 0 0 auto;
  font-size: 14px;
  color: #6a758b;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.span`
  color: #6a758b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const InformartionSubtitle = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #6a758b;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const InformartionContentWrapper = styled.div`
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div:not(:last-child) {
    border-bottom: 1px solid #0000000d;
    padding-bottom: 16px;
  }
`;

export const InformationDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const AttendanceDataContent = styled.div`
  display: flex;
  gap: 20px;
`;

export const Column = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InformationDetail = styled.span`
  color: #6a758b;
  font-size: 14px;
  font-weight: 500;
  display: block;
`;

export const AutoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 20px;
`;

export const AutoDataContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AutoDataTitle = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  color: #6a758b;
`;

export const AutoDataSubtitle = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #6a758b;
`;

export const TabsBase = styled(Tabs)`
  background: #fcfcfc;
  border-color: #dbd9d9;
  color: #6a758b;
  font-family: 'Montserrat', sans-serif !important;
  border-radius: 6px;
  margin-bottom: 15px;
`;

export const TabBase = styled(Tab).attrs({
  selectedClassName: 'selected',
})`
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 14px 38px;
  cursor: pointer;
  color: #b2bac9;
  font-size: 18px;
  font-weight: 400 !important;

  &.selected {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #dbd9d9;
    background: #fff;
    color: #6a758b;
    font-weight: 700 !important;
    border-radius: 6px 6px 0 0;
    outline: none;
  }

  :focus-visible {
    outline: none !important;
  }
`;

export const TabsListBase = styled(TabList)`
  border-bottom: 1px solid #dbd9d9;
  margin: 0;
  padding: 0;
`;

export const CardTabWrapper = styled.div`
  background-color: #ffffff;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 6px 6px;
  border-style: solid;
  border-color: #dbd9d9;
  padding: 20px 40px;
`;

export const TitleWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
