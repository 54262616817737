import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Select } from 'components/Select/Select';
import { Label as LabelComponent } from 'components';
import { Form as FormComponent } from 'components/Form';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    display: grid;
    grid-template-rows: 75px 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'head' 'body';
    width: 43%;
    min-width: 620px;
    max-width: 620px;
    padding: 25px;
    gap: 20px;
    background: #ffffff;
    border-radius: 4px;
  }
`;

export const TitleWrapper = styled.div`
  grid-area: head;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;

  font-family: 'Montserrat';
  font-style: normal;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;

export const Subtitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: rgba(0, 0, 0, 0.8);
`;

export const Form = styled(FormComponent)`
  grid-area: body;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SelectContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
`;

export const SelectWrapper = styled(Select)`
  div {
    font-weight: bold;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled(LabelComponent)`
  margin-bottom: 10px;
`;

export const TextField = styled(TextFieldComponent)`
  margin-bottom: 15px !important;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 15px;
`;
