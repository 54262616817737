import ApiService from 'services/ApiService';
import { ICancellation, ICancellationMotiveService, IGetMotives } from './CancellationMotiveService.types';

const CancellationMotiveService: ICancellationMotiveService = {
  getMotives: async () => {
    const {
      data: { cancellationMotives },
    } = await ApiService.get<IGetMotives>('/cancellation/motive');

    return cancellationMotives;
  },
  saveCancellation: async (cancellation: ICancellation) => {
    return ApiService.post('/cancellation', { ...cancellation });
  },
};

export default CancellationMotiveService;
