import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PasswordRecoveryApplicationEnum } from 'services/ApiService/PasswordRecoveryService/types';

import { Container, Illustration, Information, Title, Description, Button } from '../../PasswordRecovery.styles';

import { LinkToOpenCap, LinkContainer } from './PasswordRecovered.styles';

export const PasswordRecovered: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const getPasswordRecoveryApplication = (): PasswordRecoveryApplicationEnum => {
    const query = new URLSearchParams(search);
    const passwordRecoveryApp = query.get('passwordRecoveryApplication');
    return passwordRecoveryApp === 'CAP' ? 'CAP' : 'KITE';
  };

  const isCapPasswordRecoveryApplication = (): boolean => {
    return getPasswordRecoveryApplication() === 'CAP';
  };

  return (
    <Container data-testid="password-recovery-done">
      <Illustration />
      <Information>
        <Title>Senha recuperada</Title>
        <Description>Sua nova senha está pronta, agora é só aproveitar!</Description>

        {isCapPasswordRecoveryApplication() ? (
          <LinkContainer>
            <LinkToOpenCap data-testid="back-cap" href="cap://login">
              Voltar para o CAP
            </LinkToOpenCap>
          </LinkContainer>
        ) : (
          <Button
            data-testid="back-login"
            fullWidth={true}
            size="large"
            text="FAZER LOGIN"
            type="button"
            onClick={(): void => history.push('/login')}
          />
        )}
      </Information>
    </Container>
  );
};
