class ErrorNotFound extends Error {
  constructor() {
    super('Not found');

    Object.setPrototypeOf(this, ErrorNotFound.prototype);
  }

  static messageNotFound(): string {
    return 'Not found';
  }
}

export default ErrorNotFound;
