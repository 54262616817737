import React, { useCallback, useEffect } from 'react';

import { Dashboard } from 'components/Dashboard';

import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import { toast } from 'react-toastify';
import sortSound from 'assets/sounds/sort.wav';
import notificationSound from 'assets/sounds/notification.mp3';
import { NotificationListener } from 'components/NotificationListener/NotificationListener';
import { useNotificationsContext } from 'contexts/notifications/useNotificationsContext';
import { NotificationTypeEnum } from 'commom/types/Notification.types';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import FirebaseService from 'services/FirebaseService/firebaseService';

const App = () => {
  const { updateHasNewNotifications } = useNotificationsContext();
  let audio = new Audio();

  const isNotAlertType = (type: string | undefined) => {
    return type !== NotificationTypeEnum.ALERT;
  };

  const playDefaultNotificationSound = () => {
    audio = new Audio(notificationSound);
    audio.play();
  };

  const playSortNotificationSound = () => {
    audio = new Audio(sortSound);
    audio.play();
    audio.loop = true;
  };

  const handleNotificationActions = (payload: MessagePayload) => {
    if (isNotAlertType(payload?.data?.type)) {
      updateHasNewNotifications(true);
      playDefaultNotificationSound();
    } else {
      playSortNotificationSound();
    }
  };

  onMessage(messaging, (payload) => {
    toast.info(<NotificationListener payload={payload} />, {
      autoClose: false,
      closeOnClick: false,
      onClose: () => {
        audio.pause();
      },
    });

    handleNotificationActions(payload);
  });

  const inicializeFirebaseToken = useCallback(async (): Promise<void> => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await FirebaseService.getTokenInFirebase();
      }
    } catch (error) {
      console.error('Error getting FCM token', error);
    }
  }, []);

  useEffect(() => {
    inicializeFirebaseToken();
  }, [inicializeFirebaseToken]);

  return <Dashboard />;
};

export default App;
