import React from 'react';
import { IDrawerBaseProps } from './DrawerProps.types';
import { HeaderSubtitle } from './Drawer.styles';

export default function DrawerSubtitle({ children, testId = 'drawer-subtitle', className }: IDrawerBaseProps) {
  return (
    <HeaderSubtitle data-testid={testId} className={className}>
      {children}
    </HeaderSubtitle>
  );
}
