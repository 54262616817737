/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import AccountService from 'services/ApiService/AccountService';
import { toast } from 'react-toastify';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Label } from 'components/Label';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import { IOptions } from 'components/Select/Select.types';
import { formValidations } from 'utils/form/validations';
import { ICreateAccount, ICreateAccountMultipleCompany } from 'services/ApiService/AccountService/types';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { AuthorityEnum, AuthorityNameEnum } from 'utils/AuthorityEnum';
import { CompanyLabel } from 'components/CompanyLabel/CompanyLabel';
import { ICreateAccountDialogProps, ICreateAccountForm } from './CreateAccountDialog.types';
import * as Style from './CreateAccountDialog.styles';

export const CreateAccountDialog: React.FC<ICreateAccountDialogProps> = ({ dialogVisibility, handleClose }) => {
  const { register, errors, handleSubmit, watch, setValue } = useForm({
    mode: 'onBlur',
  });
  const [companySelected, setCompanySelected] = useState<string>('');
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
  const [companiesList, setCompaniesList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { getCompaniesWithoutCAPForActiveProfile } = useActiveProfile();
  const [authoritySelected, setAuthoritySelected] = useState<string>(`${AuthorityEnum.USER}`);
  const authorityList = [
    { name: AuthorityNameEnum.USER, value: `${AuthorityEnum.USER}` },
    { name: AuthorityNameEnum.REGULATOR, value: `${AuthorityEnum.REGULATOR}` },
    { name: AuthorityNameEnum.ADMIN, value: `${AuthorityEnum.ADMIN}` },
    { name: AuthorityNameEnum.ANALYST, value: `${AuthorityEnum.ANALYST}` },
    { name: AuthorityNameEnum.INSURANCE_COMPANY, value: `${AuthorityEnum.INSURANCE_COMPANY}` },
  ];

  const onSubmit = async (formRequest: ICreateAccountForm): Promise<void> => {
    const account: ICreateAccountMultipleCompany = {
      name: formRequest.name,
      username: formRequest.email,
      password: formRequest.password,
      companiesIds: companiesSelected,
      authority: authoritySelected,
    };

    setLoadingSubmit(true);

    await createAccount(account);

    setLoadingSubmit(false);
  };

  const createAccount = async (account: ICreateAccount | ICreateAccountMultipleCompany): Promise<void> => {
    try {
      await AccountService.postAccount(account as ICreateAccountMultipleCompany);

      toast.success('Usuário adicionado com sucesso');
      handleClose();
      resetInputs();
    } catch (error) {
      toast.error('Erro! Não foi possível adicionar o usuário');
    }
  };

  const getCompanies = useCallback((): IOptions[] => {
    try {
      const companies = getCompaniesWithoutCAPForActiveProfile();
      setCompanySelected(companies.length > 0 ? companies[0].id : '');
      setCompaniesSelected(companies.length > 0 ? companies.map((comp) => comp.id) : []);
      return companyToOptions(companies);
    } catch (error) {
      toast.error('Problema ao carregar as empresas, tente novamente.');

      return [];
    }
  }, [getCompaniesWithoutCAPForActiveProfile]);

  const disableSubmitButton = (): boolean => {
    return companySelected === '' || companySelected === null || companySelected === undefined;
  };

  const companyToOptions = (companies: ICompany[]): IOptions[] => {
    return companies.map(({ id: value, name }) => ({ value, name }));
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const resetInputs = (): void => {
    setValue('name', '');
    setValue('email', '');
    setValue('password', '');
    setValue('confirmPassword', '');
    setCompanySelected('');
    setAuthoritySelected('');
  };

  useEffect(() => {
    setCompaniesList(getCompanies());
  }, [getCompanies]);

  const validations = {
    confirmPassword: {
      ...formValidations.default,
      validate: (value: string) => value === watch('password') || 'Deve ser igual à senha',
    },
  };

  const inputProps = {
    name: {
      'data-testid': 'input-name',
      form: {
        autocomplete: 'off',
      },
    },
    email: {
      'data-testid': 'input-email',
      form: {
        autocomplete: 'off',
      },
    },
    password: {
      'data-testid': 'input-password',
      form: {
        autocomplete: 'off',
      },
    },
    confirmPassword: {
      'data-testid': 'input-confirm-password',
      form: {
        autocomplete: 'off',
      },
    },
  };

  const hasOneCompany = (): boolean => {
    return companiesList.length === 1;
  };

  const getCompanyName = (): string => {
    return companiesList[0]?.name;
  };

  return (
    <Style.DialogBase
      data-testid="create-account-dialog"
      aria-labelledby="form-dialog-title"
      open={dialogVisibility}
      onClose={handleClose}
    >
      <Style.DialogTitle>Adicionar usuário</Style.DialogTitle>
      <Style.DialogContentText>
        A senha deve conter pelo menos 8 caracteres com, ao menos, um número.
      </Style.DialogContentText>
      <Style.FormWrapper>
        <Form testID="create-account-form" onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="create-account-company" testID="label-company">
            Operação
          </Label>
          {hasOneCompany() ? (
            <CompanyLabel testID="company">{getCompanyName()}</CompanyLabel>
          ) : (
            <Style.SelectContainer>
              <Style.SelectWrapper
                testID="companies-multiple-input"
                options={companiesList}
                selectedOptions={companiesSelected}
                labelId="create-account-company"
                name="company"
                multiple
                onChange={({ target: { value } }): void => setCompaniesSelected(value as string[])}
              />
            </Style.SelectContainer>
          )}

          <Label htmlFor="create-account-company" testID="label-company">
            Perfil
          </Label>
          <Style.SelectContainer>
            <Style.SelectWrapper
              testID="authority"
              options={authorityList}
              selectedOptions={authoritySelected}
              labelId="create-account-auth"
              name="authority"
              onChange={({ target: { value } }): void => setAuthoritySelected(value as string)}
            />
          </Style.SelectContainer>

          <Label htmlFor="create-account-name" testID="label-name">
            Nome
          </Label>
          <Style.TextField
            ariaLabelledby="create-account-name"
            error={hasError('name')}
            id="create-account-name"
            inputProps={inputProps.name}
            inputRef={register(formValidations.default)}
            helperText={errors?.name?.message}
            name="name"
            placeholder="Insira o nome do usuário"
            state={handleInputState('name')}
            type="name"
            variant="outlined"
          />

          <Label htmlFor="create-account-email" testID="label-email">
            E-mail
          </Label>
          <Style.TextField
            ariaLabelledby="create-account-email"
            error={hasError('email')}
            id="create-account-email"
            inputProps={inputProps.email}
            inputRef={register(formValidations.email)}
            helperText={errors?.email?.message}
            name="email"
            placeholder="Insira o e-mail de login do usuário"
            state={handleInputState('email')}
            type="email"
            variant="outlined"
          />

          <Label htmlFor="create-account-password" testID="label-password">
            Senha
          </Label>
          <Style.TextField
            ariaLabelledby="create-account-email"
            error={hasError('password')}
            id="create-account-password"
            inputProps={inputProps.password}
            inputRef={register(formValidations.newPassword)}
            helperText={errors?.password?.message}
            name="password"
            placeholder="Crie uma senha para este usuário"
            state={handleInputState('password')}
            type="password"
            variant="outlined"
          />

          <Label htmlFor="confirmPassword" testID="label-confirm-password">
            Repetir senha
          </Label>
          <Style.TextField
            ariaLabelledby="confirm password"
            error={hasError('confirmPassword')}
            id="create-account-confirm-password"
            inputProps={inputProps.confirmPassword}
            inputRef={register(validations.confirmPassword)}
            helperText={errors?.confirmPassword?.message}
            name="confirmPassword"
            placeholder="Repita a senha inserida acima"
            state={handleInputState('confirmNewpassword')}
            type="password"
            variant="outlined"
          />

          <Style.DialogActions>
            <Style.ButtonWrapper>
              <Button
                testID="create-account-cancel"
                type="button"
                variant="outlined"
                onClick={handleClose}
                text="CANCELAR"
              />
            </Style.ButtonWrapper>
            <Style.ButtonWrapper>
              <Button
                testID="create-account-submit"
                type="submit"
                text="ADICIONAR"
                disabled={disableSubmitButton()}
                loading={loadingSubmit}
                loadingSize={25}
                loadingColor="#FFF"
              />
            </Style.ButtonWrapper>
          </Style.DialogActions>
        </Form>
      </Style.FormWrapper>
    </Style.DialogBase>
  );
};
