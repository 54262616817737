import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';

interface IPageButton {
  selected: boolean;
}

export const UsersTableContainer = styled.div`
  padding: 0px;
  overflow: scroll;
`;

export const UserTableContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 15.5px;
  grid-row-gap: 15px;
  justify-items: center;

  @media screen and (max-width: 1650px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  @media screen and (max-width: 1270px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
`;

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 24px 50px;
`;

export const PaginationDetails = styled.div``;

export const PaginationController = styled.div`
  margin-left: auto;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const PaginationButton = styled.button<IPageButton>`
  width: 37px;
  height: 36.1px;
  outline: none;
  border: none;
  border-radius: ${({ selected }): string => (selected ? '5px;' : '0px')};
  background: ${({ selected }): string => (selected ? 'black;' : 'white')};
  color: ${({ selected }): string => (selected ? 'white;' : 'black')};
  ${({ selected }) => !selected && 'border: 1px solid black;'}
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  :hover {
    filter: ${({ selected }) => !selected && 'brightness(90%)'};
    ${({ selected }) => selected && 'background: #000000e6;'}
  }

  :active {
    filter: ${({ selected }) => !selected && 'brightness(80%)'};
    ${({ selected }) => selected && 'background: #000000cc;'}
  }
`;

export const TableLoadingWrapper = styled.div`
  height: 30vh;
  display: flex;
  align-items: center;
`;

export const NoResultFoundWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  text-align: center;
`;

export const NotResultTitle = styled.h2`
  color: #6a758b;
  font-size: 22px;
  margin-bottom: 15px;
`;

export const NotResultText = styled.p`
  margin-top: 0;
  color: #6a758b;
`;

export const PaginationUserTable = styled(Pagination)`
  .MuiButtonBase-root {
    color: black;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid black;
    background: white;
    cursor: pointer;

    &.Mui-selected {
      background: #000000 !important;
      color: white !important;
    }
  }
`;
