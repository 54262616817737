import styled from 'styled-components';
import { IShowFilters } from 'pages/ManagementDashboard/components/InspectionsFilter/InspectionFilter.types';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';
import { Button as ButtonComponent } from 'components/Button/Button';

export const UsersFilterWrapper = styled.div<IShowFilters>`
  display: flex;
  flex-direction: ${(props): string => (props.showFilters ? 'column' : 'row')};
  align-items: ${(props): string => (props.showFilters ? 'inherit' : 'center')};
  gap: ${(props): string | number => (props.showFilters ? 0 : '20px')};
  row-gap: 20px;
  width: auto;
  padding: 20px 35px;
  background-color: #fff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const UsersFilterTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #6a758b;
`;

export const UsersSelectFilters = styled.div<IShowFilters>`
  display: ${(props): string => (props.showFilters ? 'grid' : 'flex')};
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  z-index: 1;

  @media only screen and (max-width: 599px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 600px) and (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(6, 1fr);

  @media only screen and (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 600px) and (max-width: 1279px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const UsersSearchContainer = styled.div`
  background-color: #ffffff;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  flex: 1;
`;

export const TextField = styled(TextFieldComponent)`
  width: 411px;
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`;
export const SubmitInput = styled.input`
  display: none;
`;

export const FilterButton = styled(ButtonComponent)`
  font-family: Montserrat !important;
  font-weight: bold !important;
`;

export const DialogButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 10px;
`;

export const DialogButton = styled(ButtonComponent)`
  height: 40px;
`;
