import styled from 'styled-components';
import { SchedulerListWrapper } from 'pages/SchedulerList/SchedulerList.styles';
import { IListWrapper } from './List.types';

export const ListWrapper = styled.div<IListWrapper>`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: ${(props) =>
    props?.hasManyCompanies && props?.isTransmissionInspection ? 'calc(100vh - 350px)' : 'calc(100vh - 280px)'};
  padding: 20px 20px 0 20px;
  border-top: solid 1px #e8e8e8;

  ::-webkit-scrollbar-thumb {
    background: white;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const FormWrapper = styled.div`
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;

  ${SchedulerListWrapper}:not([data-isopen='true']) & {
    display: none;
  }
`;

export const LoadingWrapper = styled.div`
  height: 80px;
  text-align: center;
`;

export const LoadingSVG = styled.img`
  height: 80px;
`;

export const ListBottomWrapper = styled.div`
  width: 100%;
  align-items: center;
`;
