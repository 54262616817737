import styled from 'styled-components';
import { TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const TableCellWrapper = withStyles({
  root: {
    borderWidth: 1,
    borderColor: 'rgba(237, 237, 237, .9)',
    borderStyle: 'solid',
  },
  body: {
    color: '#6a758b',
    fontFamily: 'Montserrat, sans-serif',
  },
})(TableCell);

export const TableRowWrapper = withStyles({
  selected: {
    backgroundColor: '#ced6e5 !important',
    textShadow: '1px 0px 0px #6a758b',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f0f3fa !important',
    },
  },
})(TableRow);

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
`;

export const ButtonCSVLink = styled(CSVLink)`
  text-decoration: none;
  display: flex;
`;

export const ButtonCSVLinkMenu = styled(CSVLink)`
  text-decoration: none;
  color: #6a758b !important;
  display: flex;

  &:hover {
    color: #000000 !important;
  }
`;

export const TableActionsWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  border-top: 1px solid #dfe0eb;
  border-left: 1px solid #dfe0eb;
  border-right: 1px solid #dfe0eb;
  border-radius: 8px 8px 0px 0px;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  flex: 1;
`;

export const TextField = styled(TextFieldComponent)`
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`;
