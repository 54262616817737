import styled from 'styled-components';

export const ConclusionCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1.5px solid #e8ebf1;
  border-radius: 8px;
  font-family: 'Roboto';
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #e8ebf1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const ConclusionDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px;
  border-top: 1px solid #e8ebf1;
  border-bottom: 1px solid #e8ebf1;
`;

export const ConclusionTypeContainer = styled.div`
  display: flex;
`;

export const DetailLabel = styled.div`
  font-weight: 700;
  margin-right: 10px;
`;

export const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 20px;
`;

export const LightText = styled.div`
  font-weight: 300;
`;
