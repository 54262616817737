import { Avatar as AvatarComponent } from '@material-ui/core';
import styled from 'styled-components';

export const Avatar = styled(AvatarComponent)`
  color: white !important;
  font-weight: 700;
  font-family: 'Montserrat' !important;
  font-size: 22px !important;
  background-color: ${(props): string | undefined => (props.color !== '' ? props.color : '#008080')} !important;
`;
