import styled from 'styled-components';

interface IContainerProps {
  width?: string;
  height?: string;
}

export const Container = styled.div<IContainerProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '300px'};
  border-radius: 6px;
`;
