import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

interface IGalleryProps {
  orientation: string;
  positionLoading?: boolean;
}

interface ILoadingProps {
  width: string;
  height: string;
  orientation: string;
}

export const GalleryWrapper = styled.div<IGalleryProps>`
  display: ${({ orientation }): string => (orientation === 'horizontal' ? 'contents' : 'flex')};
  flex-direction: ${({ orientation }): string => (orientation === 'horizontal' ? 'row' : 'column')};
  max-height: 60vh;
  overflow-x: auto;
  padding: 0 5px;
  width: ${({ orientation }): string => (orientation === 'horizontal' ? 'auto' : '40%')};
  ${({ orientation }): string => (orientation === 'vertical' ? 'row-gap: 10px' : '')};

  @media (max-width: 1366px) {
    width: 30%;
  }
`;

export const Thumbnail = styled.img<IGalleryProps>`
  ${({ positionLoading }): string => (positionLoading ? 'position: absolute;' : '')}
  cursor: pointer;
  border-radius: 4px;
`;

export const Loading = styled(Skeleton)<ILoadingProps>`
  width: ${({ width }): string => width}px !important;
  height: ${({ orientation, height }): string => (orientation === 'horizontal' ? `${height}px` : '400px')} !important;
  border-radius: 4px;
  padding: ${({ orientation }): string => (orientation === 'horizontal' ? '0 5px' : '5px 0')};
`;

export const LightboxWrapper = styled.div``;
