export interface ICaptureTypeService {
  getCaptureTypeByCompanyId: (companyId: string) => Promise<ICaptureTypeResponse>;
  setCaptureTypeInspectionIsLocally: (captureType: ICaptureType[]) => string | undefined;
}

export interface ICaptureType {
  id: string;
  createdAt: string;
  updatedAt: string;
  order?: number;
  name: string;
  description?: string;
  companyId: string;
  accountId?: string;
}

export interface ICaptureTypeResponse {
  captureTypes: ICaptureType[];
}

export enum CaptureTypeEnum {
  AUTO = 'AUTO',
  COMPLEMENTARY = 'COMPLEMENTARY',
  HEAVY = 'HEAVY',
  FREE = 'FREE',
  MOTOCYCLE = 'MOTOCYCLE',
  PICKUP = 'PICKUP',
}
