import React, { createContext, useCallback, useEffect, useState } from 'react';
import { IModule } from 'services/ApiService/ClientService/types';
import ClientService from 'services/ApiService/ClientService';
import { IModulesContext } from './types';

export const ModulesContext = createContext<IModulesContext>({} as IModulesContext);

export const ModulesContainer: React.FC = ({ children }) => {
  const [modules, setModulesInContext] = useState<IModule[]>([]);

  const setModules = useCallback(async (modulesToSave: IModule[]): Promise<void> => {
    ClientService.setModulesInLocalStorage(modulesToSave);
    setModulesInContext(modulesToSave);
  }, []);

  const isModuleActive = useCallback((moduleCode: string): boolean => {
    const modulesFromLocalStorage = ClientService.getModulesInLocalStorage();
    return modulesFromLocalStorage.some((module) => module.code === moduleCode);
  }, []);

  const setSelectedClientModules = async (clientId: string): Promise<void> => {
    const client = await ClientService.getModulesByClientId(clientId);
    setModules(client.modules);
  };

  useEffect(() => {
    const modulesLocalStorage = ClientService.getModulesInLocalStorage();

    if (modulesLocalStorage) setModulesInContext(modulesLocalStorage);
  }, []);

  return (
    <ModulesContext.Provider
      value={{
        modules,
        setModules,
        isModuleActive,
        setSelectedClientModules,
      }}
    >
      {children}
    </ModulesContext.Provider>
  );
};
