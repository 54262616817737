import React from 'react';
import BudgetServicesPrice from './BudgetServicesPrice/BudgetServicesPrice';
import BudgetSummaryCosts from './BudgetSummaryCosts/BudgetSummaryCosts';
import budgetValuesWhiteIcon from 'assets/svg/budget-template/icon-budget-value-white.svg';
import { SectionContainer, SectionHeader, SectionTitle, ServicesContainer } from './BudgetValuesSection.styles';

const BudgetValuesSection = () => {
  return (
    <SectionContainer>
      <SectionHeader>
        <img src={budgetValuesWhiteIcon} alt="clock" width="18" />
        <SectionTitle>Valores do Orçamento</SectionTitle>
      </SectionHeader>

      <ServicesContainer>
        <BudgetServicesPrice />
        <BudgetSummaryCosts />
      </ServicesContainer>
    </SectionContainer>
  );
};

export default BudgetValuesSection;
