import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CardContent, CardActions } from '@material-ui/core';
import { CardType, ICardBase, ICardHeaderBase, ICardStyle } from './Card.types';

export const getCardStyle = (type: CardType): ICardStyle => {
  const styles = {
    open: {
      border: '2px solid #000000',
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.25) !important',
      boxShadowHover: '0 2px 10px 0 rgba(0, 0, 0, 0.25)',
    },
    closed: {
      border: '2px solid #DBD9D9',
      boxShadow: 'none !important',
      boxShadowHover: '0 2px 10px 0 rgba(0, 0, 0, 0.25) !important',
    },
  };

  return styles[type];
};

export const CardBase = styled(Card)<ICardBase>`
  width: 360px;
  padding: 20px 20px 20px 20px !important;
  border-radius: 10px !important;
  position: relative;
  overflow: visible !important;
  border: ${({ type }): string => getCardStyle(type).border};
  box-shadow: ${({ type }): string => getCardStyle(type).boxShadow};
  &:hover {
    box-shadow: ${({ type }): string => getCardStyle(type).boxShadowHover};
  }
`;

export const CardHeaderBase = styled(CardHeader)<ICardHeaderBase>`
  font-family: 'Montserrat', sans-serif;
  padding: 0 0 15px 0 !important;
  color: ${(props): string => (props.alert === 'true' ? '#C73C3C' : '#6a758b')};
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  word-wrap: break-word;

  width: 250px;

  .MuiCardHeader-action {
    margin: auto !important;
    display: flex;
  }
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardBody = styled(CardContent)`
  padding: 0px !important;
`;

export const CardActionsBase = styled(CardActions)`
  padding: 0px !important;
`;
