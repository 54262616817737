import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0;

  height: 30px;
  border-radius: 4px;
  background: transparent;
  border: none;
  cursor: pointer;

  color: #000;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  :hover {
    color: #737791;
    img {
      filter: invert(0.5);
    }
  }
`;
