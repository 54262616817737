import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useHistory } from 'react-router-dom';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import { initBudget, resetPresentBudgetState } from 'stores/presentBudget/presentBudget.store';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { toast } from 'react-toastify';
import { BudgetsSteps } from 'stores/presentBudget/presentBudget.types';
import Loading from 'components/Loading';

import { IBudget } from 'commom/types/Budget.types';
import { IBudgetsProps } from './Budgets.types';
import StepsControl from './components/BudgetSteps/StepsControl';
import { LoadingContainer } from './Budgets.styles';

const Budgets = ({ inspection }: IBudgetsProps) => {
  const [loadingBudgets, setLoadingBudgets] = useState(false);
  const presentBudgetId = useAppSelector((state) => state.presentBudget.budget._id);
  const dispatch = useAppDispatch();
  const history = useHistory();
  useHideZendesk();

  const isBudgetNotExist = !presentBudgetId;

  const removeUrlParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('currentStep');
    url.searchParams.delete('budgetId');
    window.history.pushState({}, '', url.toString());
  };

  const backInspectionDetailsPage = useCallback(() => {
    toast.error('Este atendimento não tem orçamentos.');
    removeUrlParams();
    history.push(`/agendamentos/v2/detalhes/${inspection.id}`);
  }, [history, inspection.id]);

  const updateUrlWithBudgetData = useCallback((budget: IBudget, currentStep: BudgetsSteps) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    searchParams.set('budgetId', budget._id);
    searchParams.set('currentStep', currentStep);

    url.search = searchParams.toString();

    window.history.pushState({}, '', url.toString());
  }, []);

  const dispatchInitBudget = useCallback(
    (budget: IBudget, currentStep: BudgetsSteps) => {
      dispatch(
        initBudget({
          budget,
          currentStep,
        })
      );
    },
    [dispatch]
  );

  const getBudgetFromURL = useCallback((budgetIdFromURL: string | null, budgetsResponse: IBudget[]) => {
    return budgetsResponse.find((budgetItem) => budgetItem._id === budgetIdFromURL && budgetItem.isCurrentVersion);
  }, []);

  const getBudgetCurrentVersion = useCallback((budgetsResponse: IBudget[]) => {
    return budgetsResponse.find((budgetItem) => budgetItem.isCurrentVersion);
  }, []);

  const getBudgets = useCallback(async () => {
    try {
      setLoadingBudgets(true);

      const budgetsResponse = await BudgetService.getBudgetsByInspectionId(inspection.id);

      if (!budgetsResponse.length) {
        backInspectionDetailsPage();
        return;
      }

      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const budgetId = searchParams.get('budgetId');
      let currentStep = searchParams.get('currentStep') as BudgetsSteps;

      let budgetToContinue = getBudgetFromURL(budgetId, budgetsResponse);

      if (!budgetToContinue) {
        budgetToContinue = getBudgetCurrentVersion(budgetsResponse);
        currentStep = 'INITIAL_DATA';
      }

      if (budgetToContinue) {
        updateUrlWithBudgetData(budgetToContinue, currentStep);
        dispatchInitBudget(budgetToContinue, currentStep);
      }
    } catch (error) {
      toast.error('Não foi possível carregar o orçamento.');
    } finally {
      setLoadingBudgets(false);
    }
  }, [
    inspection,
    backInspectionDetailsPage,
    getBudgetFromURL,
    getBudgetCurrentVersion,
    updateUrlWithBudgetData,
    dispatchInitBudget,
  ]);

  useEffect(() => {
    dispatch(resetPresentBudgetState());
  }, [dispatch]);

  useEffect(() => {
    getBudgets();
  }, [getBudgets]);

  if (loadingBudgets || isBudgetNotExist) {
    return (
      <LoadingContainer>
        <Loading width="10%" />
      </LoadingContainer>
    );
  }

  return <StepsControl inspection={inspection} />;
};

export default Budgets;
