import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import NumericFormat from 'react-number-format';
import { Button } from 'components/Button/Button';
import { ICompanySelectOptions, IOptions } from 'components/Select/Select.types';
import { toast } from 'react-toastify';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { ICompany } from 'pages/SchedulerList/components/Inspection';
import CompanySelectService from 'services/CompanySelectService/CompanySelectService';
import { removerPercentFormatter, removerPriceFormatter } from 'utils/formatPrice';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { ICost, IServiceCost, IWorkshopService } from 'services/ApiService/BudgetService/BudgetService.types';
import {
  IBudgetSettingsDialogProps,
  costMapping,
  IFormState,
  formValueInitialState,
} from './BudgetSettingsDialog.types';
import * as Style from './BudgetSettingsDialog.styles';
import { isAxiosError } from 'utils/isAxiosError';

export const BudgetSettingsDialog = ({
  dialogVisibility,
  handleClose,
  testID = 'budget-dialog',
}: IBudgetSettingsDialogProps) => {
  const [companiesList, setCompaniesList] = useState<IOptions[]>([{ name: '', value: '' }]);
  const [companySelected, setCompanySelected] = useState('');
  const [workshopServices, setWorkshopServices] = useState<IWorkshopService[]>([]);
  const [companyWorkshopServicesId, setCompanyWorkshopServicesId] = useState<string>('');
  const [formValues, setFormValues] = useState(formValueInitialState);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const { getAllCompaniesForActiveProfile } = useActiveProfile();

  const showToastConfig = (error: number | undefined) => {
    if (error === 400) {
      toast.error('Não foi possível salvar a configuração do orçamento, tente novamente.');
    } else {
      toast.error('Ocorreu um erro ao salvar a configuração do orçamento.');
    }
  };

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      setLoadingButton(true);
      const isUpdate = isUpdateBudgetLabor();
      const formValuesCosts = insertIdInFormValues();
      const servicesCost = createServiceCostPayload(formValuesCosts);

      if (isUpdate) {
        await BudgetService.updateBudgetLabor(companyWorkshopServicesId, servicesCost);
        toast.success('Configurações salvas com sucesso.');
      } else {
        await BudgetService.createBudgetLabor(companySelected, servicesCost);
        toast.success('Configurações salvas com sucesso.');
      }
      handleClose();
    } catch (error) {
      if (isAxiosError(error)) {
        showToastConfig(error.response?.status);
      } else {
        toast.error('Ocorreu um erro interno.');
      }
    } finally {
      setLoadingButton(false);
    }
  };

  const createServiceCostPayload = (formValuesCosts: IFormState): IServiceCost[] => {
    return Object.keys(formValuesCosts).map((key: string) => {
      const workshopServicesId = formValuesCosts[key as keyof typeof formValuesCosts].id;

      if (key === costMapping.DISCOUNT || key === costMapping.MARKUP) {
        const percent = removerPercentFormatter(formValuesCosts[key as keyof typeof formValuesCosts].value);
        return {
          workshopServicesId,
          percent: percent || 0,
        };
      }

      const price = removerPriceFormatter(formValuesCosts[key as keyof typeof formValuesCosts].value);

      return {
        workshopServicesId,
        price: parseFloat(price),
      };
    });
  };

  const insertIdInFormValues = () => {
    const formValuesWithId = JSON.parse(JSON.stringify(formValues));

    workshopServices.forEach((workshopService) => {
      const propertyName = costMapping[workshopService.code as keyof typeof costMapping];
      if (propertyName) {
        formValuesWithId[propertyName as keyof typeof formValuesWithId].id = workshopService.id;
      }
    });

    return formValuesWithId;
  };

  const isUpdateBudgetLabor = () =>
    Object.keys(formValues).some((key: string) => formValues[key as keyof typeof formValues].id !== '');

  const companiesToOption = useCallback((companies: ICompany[]): ICompanySelectOptions[] => {
    return companies
      .map(CompanySelectService.transformCompanyToOption)
      .filter(CompanySelectService.isOptionNotPreviousInspection);
  }, []);

  const getCompanies = useCallback(async (): Promise<IOptions[]> => {
    try {
      const companies = getAllCompaniesForActiveProfile();
      return companiesToOption(companies);
    } catch (error) {
      toast.error('Problema ao carregar as empresas, tente novamente.');

      return [];
    }
  }, [getAllCompaniesForActiveProfile, companiesToOption]);

  const setUpCompanies = useCallback(async (): Promise<void> => {
    const companies = await getCompanies();
    setCompaniesList(companies.length > 0 ? companies : []);
    setCompanySelected(
      companies.length > 0
        ? CompanySelectService.getFirstCompany(companies).value
        : CompanySelectService.getDefaultCompany().value
    );
  }, [getCompanies]);

  const hasError = (input: string): boolean => {
    return input.length > 0 && !/\d/.test(input);
  };

  const formHasError = () => {
    let isError = false;
    Object.keys(formValues).forEach((key: string) => {
      if (hasError(formValues[key as keyof typeof formValues].value)) {
        isError = true;
      }
    });

    return isError;
  };

  const handleFormValues = useCallback((costs: ICost[]) => {
    const formattedValues = JSON.parse(JSON.stringify(formValueInitialState));

    costs.forEach((cost) => {
      const propertyName = costMapping[cost.code as keyof typeof costMapping];
      let value = '0';

      if (cost.price) value = cost.price.toString().replace('.', ',');
      else if (cost.percent) value = cost.percent.toString();

      if (propertyName) {
        formattedValues[propertyName as keyof typeof formattedValues].value = value;
        formattedValues[propertyName as keyof typeof formattedValues].id = cost.id;
        formattedValues[propertyName as keyof typeof formattedValues].code = cost.code;
      }
    });

    return formattedValues;
  }, []);

  const getBudgetLabor = async (companySelectedId: string) => {
    try {
      setLoadingCompanies(true);
      const {
        companyWorkshopServices: { costs, id },
      } = await BudgetService.getBudgetLabor(companySelectedId);

      setFormValues(handleFormValues(costs));
      setCompanyWorkshopServicesId(id);
    } catch {
      setFormValues(formValueInitialState);
    } finally {
      setLoadingCompanies(false);
    }
  };

  const getWorkshopServices = useCallback(async () => {
    try {
      const { workshopServices: workshops } = await BudgetService.getWorkshopServices();

      setWorkshopServices(workshops);
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar os dados de configuração do orçamento');
    }
  }, []);

  const validateInputPriceValue = (): boolean => {
    const repair = parseFloat(removerPriceFormatter(formValues.repair.value));
    const painting = parseFloat(removerPriceFormatter(formValues.painting.value));
    const tricoatPainting = parseFloat(removerPriceFormatter(formValues.tricoatPainting.value));
    const metallicPainting = parseFloat(removerPriceFormatter(formValues.metallicPainting.value));
    const pearlizedPainting = parseFloat(removerPriceFormatter(formValues.pearlizedPainting.value));
    const labor = parseFloat(removerPriceFormatter(formValues.labor.value));

    return !repair || !painting || !tricoatPainting || !metallicPainting || !pearlizedPainting || !labor;
  };

  const disableSaveButton = (): boolean =>
    validateInputPriceValue() || formHasError() || loadingButton || loadingCompanies;

  const isPercentageValueAllowed = (floatValue: number | undefined): boolean => {
    return floatValue === undefined ? true : floatValue <= 100;
  };

  useEffect(() => {
    setUpCompanies();
  }, [setUpCompanies]);

  const instantiateDataInModal = async () => {
    if (workshopServices.length === 0) await getWorkshopServices();
    await getBudgetLabor(companySelected);
  };

  const handleCompanySelected = (companyId: string) => {
    setCompanySelected(companyId);
    getBudgetLabor(companyId);
  };

  return (
    <Style.DialogBase
      data-testid={testID}
      open={dialogVisibility}
      onClose={handleClose}
      TransitionProps={{ onEnter: instantiateDataInModal }}
    >
      <Style.TitleWrapper>
        <Style.Title>Configuração de Orçamento</Style.Title>
        <Style.Subtitle>
          Configure os valores que irão ser utilizados para a orçamentação de cada operação.
        </Style.Subtitle>
      </Style.TitleWrapper>
      <Style.Form testID="budget-settings-form" onSubmit={onSubmit}>
        <Style.SelectContainer>
          <Style.Label htmlFor="company" testID="label-company">
            Operação
          </Style.Label>
          <Style.SelectWrapper
            testID="companies-select"
            options={companiesList}
            selectedOptions={companySelected}
            error={!companySelected}
            required={true}
            labelId="companies"
            name="companies"
            disabled={loadingCompanies}
            onChange={({ target: { value } }): void => handleCompanySelected(value as string)}
          />
        </Style.SelectContainer>

        <Style.ContentWrapper>
          <Style.InputRow>
            <Style.InputContainer>
              <Style.Label>Reparação</Style.Label>
              <NumericFormat
                value={formValues.repair.value}
                placeholder="Insira o valor da peça"
                disabled={loadingCompanies}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-repair' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    repair: { ...formValues.repair, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>

            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-labor">
                Mão de Obra
              </Style.Label>
              <NumericFormat
                value={formValues.labor.value}
                placeholder="Insira o valor da mão de obra"
                disabled={loadingCompanies}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-labor' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    labor: { ...formValues.labor, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>
          </Style.InputRow>

          <Style.InputRow>
            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-tricoat-painting">
                Pintura Tricoat
              </Style.Label>
              <NumericFormat
                value={formValues.tricoatPainting.value}
                placeholder="Insira o valor da pintura tricoat"
                disabled={loadingCompanies}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-tricoat-painting' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    tricoatPainting: { ...formValues.tricoatPainting, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>

            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-common-painting">
                Pintura Comum
              </Style.Label>
              <NumericFormat
                value={formValues.painting.value}
                placeholder="Insira o valor da pintura comum"
                disabled={loadingCompanies}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-painting' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    painting: { ...formValues.painting, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>
          </Style.InputRow>

          <Style.InputRow>
            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-metallic-painting">
                Pintura Metálica
              </Style.Label>
              <NumericFormat
                value={formValues.metallicPainting.value}
                placeholder="Insira o valor da pintura metálica"
                disabled={loadingCompanies}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-metallic-painting' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    metallicPainting: { ...formValues.metallicPainting, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>

            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-pearlescent-painting">
                Pintura Perolizada
              </Style.Label>
              <NumericFormat
                value={formValues.pearlizedPainting.value}
                placeholder="Insira o valor da pintura perolizada"
                disabled={loadingCompanies}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="R$ "
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-pearlescent-painting' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    pearlizedPainting: { ...formValues.pearlizedPainting, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>
          </Style.InputRow>

          <Style.InputRow>
            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-discount">
                Desconto
              </Style.Label>
              <NumericFormat
                value={formValues.discount.value}
                placeholder="Insira o valor do desconto"
                disabled={loadingCompanies}
                prefix="% "
                isAllowed={({ floatValue }) => isPercentageValueAllowed(floatValue)}
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-discount' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    discount: { ...formValues.discount, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>

            <Style.InputContainer>
              <Style.Label htmlFor="login" testID="label-markup">
                Majoração
              </Style.Label>
              <NumericFormat
                value={formValues.markup.value}
                placeholder="Insira o valor da majoração"
                disabled={loadingCompanies}
                prefix="% "
                isAllowed={({ floatValue }) => isPercentageValueAllowed(floatValue)}
                customInput={Style.TextField}
                allowNegative={false}
                inputProps={{ 'data-testid': 'budget-settings-discount' }}
                onValueChange={({ formattedValue }): void =>
                  setFormValues({
                    ...formValues,
                    markup: { ...formValues.markup, value: formattedValue },
                  })
                }
                {...{ variant: 'outlined' }}
              />
            </Style.InputContainer>
          </Style.InputRow>
        </Style.ContentWrapper>

        <Style.ButtonWrapper>
          <Button
            testID="cancel-button"
            onClick={handleClose}
            variant="outlined"
            text="Cancelar"
            disabled={loadingButton}
          />
          <Button
            testID="save-button"
            type="submit"
            size="large"
            text="Salvar"
            loading={loadingButton}
            disabled={disableSaveButton()}
          />
        </Style.ButtonWrapper>
      </Style.Form>
    </Style.DialogBase>
  );
};
