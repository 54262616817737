import React from 'react';
import { Container, Header, Title } from './DamageReport.styles';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import {
  selectDamageReport,
  selectDamageReportPartSeverity,
  selectReports,
} from 'stores/presentBudget/presentBudget.selector';
import { IBudgetReportTypeEnum } from 'commom/types/BudgetReport.types';
import { useQuery } from 'react-query';
import BudgetReportService from 'services/ApiService/BudgetReportService/BudgetReportService';
import { toast } from 'react-toastify';
import DamageSummary from './DamageSummary/DamageSummary';
import DamageCaption from './DamageCaption/DamageCaption';
import { setDamageReport } from 'stores/presentBudget/presentBudget.store';
import DamageReportForm from './DamageReportForm/DamageReportForm';
import DamageReportActions from '../../RescuedDamageReportActions/RescuedDamageReportActions';
import DamageReportFormSkeleton from './DamageSkeleton/DamageReportFormSkeleton/DamageReportFormSkeleton';
import DamageSummarySkeleton from './DamageSkeleton/DamageSummarySkeleton/DamageSummarySkeleton';

const DamageReport = () => {
  const budgetReports = useAppSelector(selectReports);
  const damagePartSeverity = useAppSelector(selectDamageReportPartSeverity);
  const report = useAppSelector(selectDamageReport);

  const dispatch = useAppDispatch();
  const reportId = budgetReports?.find(({ reportType }) => reportType === IBudgetReportTypeEnum.DAMAGE)?.reportId;

  const { isLoading } = useQuery(
    ['get-damage-report-parts', { reportId }],
    () => BudgetReportService.getBudgetReportDamageParts(reportId as string),
    {
      onSuccess: (data) => {
        dispatch(setDamageReport(data));
      },
      onError: () => {
        toast.error('Erro ao buscar dados do laudo de danos');
      },
      enabled: !!reportId,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || !report) {
    return (
      <Container>
        <Header>
          <Title>Laudo de Danos</Title>
        </Header>
        <DamageSummarySkeleton />
        <DamageCaption />
        <DamageReportFormSkeleton />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Laudo de Danos</Title>
        <DamageReportActions report={report} />
      </Header>
      {damagePartSeverity && <DamageSummary damagePartSeverity={damagePartSeverity} />}
      <DamageCaption />
      <DamageReportForm />
    </Container>
  );
};

export default DamageReport;
