import React, { Ref } from 'react';
import { OTStreams, OTSubscriber, OTSubscriberRef } from 'opentok-react';
import { Error } from 'opentok-react/types/opentok';
import { SubscriberContainer } from '../Transmission.styles';

interface ISubscriberProps {
  onSessionError: (e: Error) => void;
  subscriberRef: Ref<OTSubscriberRef> | undefined;
}

export const Subscriber = ({ onSessionError, subscriberRef }: ISubscriberProps) => {
  return (
    <OTStreams>
      <SubscriberContainer>
        <OTSubscriber
          properties={{
            width: '100%',
            height: '420px',
            preferredFrameRate: 30,
            preferredResolution: { width: 1920, height: 1080 },
            audioVolume: 100,
            insertDefaultUI: true,
            showControls: true,
            fitMode: 'contain',
          }}
          ref={subscriberRef}
          onError={onSessionError}
        />
      </SubscriberContainer>
    </OTStreams>
  );
};
