import styled from 'styled-components';
import { TextField } from 'components/TextField/TextField';
import { withTheme } from '@material-ui/core/styles';
import { Drawer } from '..';

export const Container = styled(Drawer.Root)`
  .MuiPaper-root {
    padding: 0px;
  }
`;

export const Header = styled(Drawer.Header)`
  display: flex;
  justify-content: start;
  padding: 20px;
`;

export const Title = styled(Drawer.Title)`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 15px;
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #000;
`;

export const Content = styled(Drawer.Content)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
`;

export const Footer = styled(Drawer.Footer)`
  border-top: 1px solid #ced6e5;
  padding: 20px;
`;

interface IDragAndDropArea {
  isDragging: boolean;
}

export const DragAndDropArea = styled.div<IDragAndDropArea>`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ isDragging }) => (isDragging ? '#099857' : '#dbe1ee')};
  padding: 33px 0;

  &:hover {
    border-color: #099857;
    cursor: pointer;
    transition: 0.8s;

    p {
      color: #099857;
      transition: 0.8s;
    }
  }

  p {
    margin: 0;
    color: ${({ isDragging }) => (isDragging ? '#099857' : '#6A758B')};
  }
`;

export const ErrorMessage = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: medium;
  font-size: 12px;
  padding-top: 15px;
  color: #dc3d3d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 20px;
  max-height: 280px;
  min-height: 160px;
  overflow-y: scroll;
`;

export const FileCard = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #dbe1ee;
`;

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const FileName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  max-width: 200px;
`;

const ButtonBase = styled.button`
  border: none;
  cursor: pointer;
`;

export const FileIcon = styled.img`
  width: 25px !important;
`;

export const DeleteButton = styled(ButtonBase)`
  padding: 0;
  background: transparent;
  margin-right: 10px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const DatePicker = withTheme(styled(TextField)`
  && {
    margin-top: ${(props): number => props.theme.spacing(1)}px;
    margin-bottom: ${(props): number => (props.helperText ? props.theme.spacing(5) : props.theme.spacing(2.5))}px;
  }
  [class*='MuiFormHelperText-root'] {
    color: red;
  }
`);
