import React from 'react';
import {
  PartsSection,
  HeaderContainer,
  HeaderItem,
  ServiceInfo,
  ServiceQuantity,
  Title,
  Icon,
  Divider,
} from './PartsAndServicesSection.styles';
import LabelsGuide from '../LabelsGuide/LabelsGuide';
import BudgetValuesSection from '../BudgetValuesSection/BudgetValuesSection';
import servicesHeaderIcon from 'assets/svg/budget-template/icon-services.svg';
import BudgetPartsSection from '../BudgetPartsSection/BudgetPartsSection';
import BudgetOutsourcedServices from '../BudgetServicesSection/BudgetServicesSection';
import { useAppSelector } from 'hooks/hooks';
import { selectBudget } from 'stores/presentBudget/presentBudget.selector';

const PartsAndServicesSection = () => {
  const budget = useAppSelector(selectBudget);
  const formatPartsQuantity = (quantity: number): string => quantity.toString().padStart(2, '0');

  return (
    <PartsSection>
      <BudgetPartsSection parts={budget.items.parts} />
      <Divider>
        <HeaderContainer>
          <HeaderItem>
            <Icon src={servicesHeaderIcon} alt="clock" width="18" />
            <Title>Serviços</Title>
          </HeaderItem>
          <ServiceInfo>
            <Title normal>Qtd. Total de serviços</Title>
            <ServiceQuantity>{formatPartsQuantity(budget.items.outsourcedServices.length)}</ServiceQuantity>
          </ServiceInfo>
        </HeaderContainer>
      </Divider>
      <BudgetOutsourcedServices outsourcedServices={budget.items.outsourcedServices} />
      <LabelsGuide />
      <BudgetValuesSection />
    </PartsSection>
  );
};

export default PartsAndServicesSection;
