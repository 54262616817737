import React, { ReactNode } from 'react';
import * as Styled from './styles';

interface IGeneralMetricsCardTitle {
  children: ReactNode;
}

export const GeneralMetricsCardTitle = ({ children }: IGeneralMetricsCardTitle) => {
  return <Styled.Title>{children}</Styled.Title>;
};
