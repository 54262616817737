import styled from 'styled-components';

export const Container = styled.div`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 24px 32px;
  margin-left: 60px;
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1153px;
  margin: 0 auto;
  gap: 10px;
`;
