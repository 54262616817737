import React from 'react';
import { ActiveProfileContainer } from './activeProfile/ActiveProfileProvider';
import { AuthContainer } from './auth/AuthProvider';
import { FeatureTogglesContainer } from './featureToggles/FeatureTogglesProvider';
import { ConsumerContainer } from './consumers/ConsumerProvider';
import { RegulatorDetailsContainer } from './regulatorDetails/RegulatorDetailsProvider';
import { ModulesContainer } from './modules/ModulesProvider';
import { MetricsFilterContainer } from './metricsFilter/MetricsFilterProvider';
import { NotificationsProvider } from './notifications/NotificationsProvider';

const ContextProvider: React.FC = ({ children }) => {
  return (
    <FeatureTogglesContainer>
      <AuthContainer>
        <ActiveProfileContainer>
          <ModulesContainer>
            <ConsumerContainer>
              <NotificationsProvider>
                <RegulatorDetailsContainer>
                  <MetricsFilterContainer>{children}</MetricsFilterContainer>
                </RegulatorDetailsContainer>
              </NotificationsProvider>
            </ConsumerContainer>
          </ModulesContainer>
        </ActiveProfileContainer>
      </AuthContainer>
    </FeatureTogglesContainer>
  );
};

export default ContextProvider;
