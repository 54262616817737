import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'components';
import { useInvoicesContext } from 'contexts/invoices/useInvoicesContext';
import ClientService from 'services/ApiService/ClientService';
import InvoicesService from 'services/ApiService/InvoicesService/invoicesService';
import { DateRangeStateType, DateRangeType } from 'components/DateRange/DateRange.types';
import { IClient } from 'services/ApiService/AccountService/types';
import { IOptions } from 'components/Select/Select.types';
import * as S from './Filter.styles';

export const Filter = () => {
  const [clients, setClients] = useState<IOptions[]>([]);
  const [statusList, setStatusList] = useState<IOptions[]>([]);
  const [clientsSelected, setClientsSelected] = useState<string[]>([]);
  const [statusSelected, setStatusSelected] = useState<string[]>([]);
  const [loadingClients, setLoadingClients] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [momentStartDateSelectedFilter, setMomentStartDateSelectedFilter] = useState<DateRangeStateType>(null);
  const [momentEndDateSelectedFilter, setMomentEndDateSelectedFilter] = useState<DateRangeStateType>(null);

  const { setFilter, setLoading } = useInvoicesContext();

  const disableFilterButton = clientsSelected.length === 0;

  const disableClearFiltersButton =
    clientsSelected.length === 0 &&
    statusSelected.length === 0 &&
    !momentStartDateSelectedFilter &&
    !momentEndDateSelectedFilter;

  const getInvoices = () => {
    try {
      setLoading(true);

      setFilter({
        clientIds: clientsSelected,
        finalRangeCreatedAt: momentEndDateSelectedFilter?.toISOString(),
        initialRangeCreatedAt: momentStartDateSelectedFilter?.toISOString(),
        statusIds: statusSelected,
      });
    } catch (error) {
      toast.error('Ocorreu um problema ao filtrar, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setClientsSelected([]);
    setStatusSelected([]);
    setMomentStartDateSelectedFilter(null);
    setMomentEndDateSelectedFilter(null);

    setFilter({
      clientIds: undefined,
      finalRangeCreatedAt: undefined,
      initialRangeCreatedAt: undefined,
      statusIds: undefined,
    });
  };

  const onDatesChange = (range: { startDate: DateRangeType; endDate: DateRangeType }): void => {
    const { startDate, endDate } = range;

    setMomentStartDateSelectedFilter(startDate);
    setMomentEndDateSelectedFilter(endDate);
  };

  const clientToOptions = (clients: IClient[]): IOptions[] => {
    return clients.map(({ id: value, name }) => ({ value, name }));
  };

  const initializeInvoiceClients = useCallback(async () => {
    try {
      setLoadingClients(true);
      const { clients } = await ClientService.getAllClients();
      const options = clientToOptions(clients);
      setClients(options);
    } catch (error) {
      toast.error('Ocorreu um problema ao buscar os clientes, tente novamente.');
    } finally {
      setLoadingClients(false);
    }
  }, []);

  const initializeInvoiceStatus = useCallback(async () => {
    try {
      setLoadingStatus(true);
      const status = await InvoicesService.getInvoiceStatus();
      const options = status?.map(({ id, description }) => ({ value: id, name: description }));
      setStatusList(options);
    } catch (error) {
      toast.error('Ocorreu um problema ao buscar os status, tente novamente.');
    } finally {
      setLoadingStatus(false);
    }
  }, []);

  useEffect(() => {
    initializeInvoiceClients();
  }, [initializeInvoiceClients]);

  useEffect(() => {
    initializeInvoiceStatus();
  }, [initializeInvoiceStatus]);

  return (
    <S.Container>
      <S.FilterWrapper>
        <S.Select
          className="animate fadeIn"
          testID="client-selected"
          options={clients}
          selectedOptions={clientsSelected}
          labelId="client"
          label="Clientes"
          disabled={loadingClients}
          placeholder={loadingClients ? 'Carregando...' : 'Selecione os clientes'}
          onChange={({ target: { value } }): void => setClientsSelected(value as string[])}
          multiple
        />

        <S.DateRange
          className="animate fadeIn"
          testID="date-range-select"
          startDate={momentStartDateSelectedFilter}
          endDate={momentEndDateSelectedFilter}
          label="Data de geração"
          onDatesChange={onDatesChange}
        />

        <S.Select
          className="animate fadeIn"
          testID="status"
          options={statusList}
          selectedOptions={statusSelected}
          labelId="status"
          label="Status"
          disabled={loadingStatus}
          placeholder={loadingStatus ? 'Carregando...' : 'Selecione os status'}
          onChange={({ target: { value } }): void => setStatusSelected(value as string[])}
          multiple
        />
      </S.FilterWrapper>

      <S.ActionsWrapper>
        <Button text="FILTRAR" type="submit" onClick={getInvoices} disabled={disableFilterButton} />

        <Button
          text="LIMPAR"
          type="submit"
          onClick={clearFilters}
          disabled={disableClearFiltersButton}
          variant="outlined"
        />
      </S.ActionsWrapper>
    </S.Container>
  );
};
