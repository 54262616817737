import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';
import { Input as InputBase } from '@material-ui/core';

export const ListPartsContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  gap: 16px;
`;

export const PartCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #ced6e5;
  border-radius: 6px;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 6px;

  :hover {
    box-shadow: 4px 5px 8px -5px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  :active {
    background: #f9f9f9;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Divider = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;

  services-labor-cost {
    margin: 0 !important;
  }
`;

export const SubLabel = styled.label`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  text-align: right;

  margin-top: 8px;
`;

export const Input = styled(InputBase)`
  border: 1px solid black;
  border-radius: 0px 4px 4px 0px !important;
`;

export const TitlePart = styled.span`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  line-height: normal;

  title-labor-cost {
    font-size: 16px;
  }
`;

export const SubtitlePart = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  line-height: normal;
  margin-top: 2px;
`;

export const PartCostContainerEmpty = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  img {
    max-width: 358.09px;
    max-height: 252.78px;
  }
`;

export const PartCostTitle = styled.div`
  color: #6a758b;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;

  font-size: 20px;
  font-weight: 700;
`;

export const PartCostDescription = styled.div`
  color: #6a758b;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  add-part {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 20px;
`;

export const UnavaliablePartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const UnavaliablePartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  max-width: 232px;

  text-align: center;
  color: #6a758b;
`;

export const RegisterDrawerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 1px;
  overflow-y: auto;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
`;
