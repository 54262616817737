/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import JsZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { IPhoto } from 'pages/SchedulerList/components/Inspection';
import ApiService from 'services/ApiService';
import { createCanvas, loadImage } from 'canvas';
import { IFetchPhoto, IPhotoService, ISwapPhoto } from './photoService.types';

const PhotoService: IPhotoService = {
  fetchPhotos: async (photo: IPhoto) => {
    const response = await axios.get(`${photo.url}?x-request=xhr`, {
      responseType: 'blob',
    });
    return {
      blob: response.data,
      fileName: photo.name,
    };
  },

  downloadPhotos: (photos: IPhoto[]) => {
    return Promise.all(photos.map((photo) => PhotoService.fetchPhotos(photo)));
  },

  exportFileZip: (fetchPhotos: IFetchPhoto[], fileZipName: string) => {
    const zip = JsZip();

    fetchPhotos.forEach((fetchPhoto) => {
      zip.file(`${fetchPhoto.fileName}.jpeg`, fetchPhoto.blob);
    });

    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      const fileName = `sinistro-${fileZipName}.zip`;
      return saveAs(zipFile, fileName);
    });
  },

  downloadFileZip: async (photos: IPhoto[], fileZipName: string) => {
    const responseFetchPhotos = await PhotoService.downloadPhotos(photos);
    return PhotoService.exportFileZip(responseFetchPhotos, fileZipName);
  },

  swapPhotos: async (photos: ISwapPhoto[]) => {
    await ApiService.post('/photos/swap', {
      photos,
    });
  },

  handleBase64: (image) => {
    const array = image.split(',');

    if (array.length > 1) {
      return image;
    }

    return `data:image/jpeg;base64,${array[0]}`;
  },

  convertToBlob: async (imageUri: string | undefined) => {
    if (!imageUri) {
      throw new Error('Image undefined');
    }

    const base64 = PhotoService.handleBase64(imageUri);

    const response = await fetch(base64);

    return response.blob();
  },

  watermark: async (path, texts) => {
    const base64 = PhotoService.handleBase64(path);

    try {
      const { width, height } = await PhotoService.getImageDimensions(base64);

      const canvas = createCanvas(width, height);
      const ctx = canvas.getContext('2d');
      const image = await loadImage(base64);

      ctx.drawImage(image, 0, 0);

      texts.forEach((text) => {
        PhotoService.writeText(ctx, text.text, text.align, width, height - text.height);
      });

      return canvas.toDataURL('image/jpeg', 0.8);
    } catch (error) {
      throw new Error('Invalid base64');
    }
  },

  getImageDimensions: (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () =>
        resolve({
          width: img.width,
          height: img.height,
        });
      img.onerror = (error) => reject(error);
      img.src = url;
    });
  },

  getFont: (width, fontSize, fontBase) => {
    const ratio = fontSize / fontBase;
    const size = width * ratio;

    return `${size}px verdana`;
  },

  writeText: (ctx, text, align, width, height) => {
    const canvasWidth = align === 'start' ? 0 : width;
    const widthSafeArea = align === 'start' ? 10 : -10;
    const widthHeightArea = -10;
    const textShadow = 1;

    ctx.font = `bold ${PhotoService.getFont(width, 22, 1000)}`;
    ctx.globalAlpha = 0.8;

    ctx.fillStyle = 'white';
    ctx.textAlign = align;
    ctx.fillText(text, canvasWidth + widthSafeArea, height + widthHeightArea);
    ctx.fillStyle = 'black';
    ctx.fillText(text, canvasWidth + textShadow + widthSafeArea, height + textShadow + widthHeightArea);
  },
};

export default PhotoService;
