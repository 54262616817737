import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { IBudgetItemOutsourcedService } from 'commom/types/Budget.types';
import EditOutsourcedServiceDrawer from 'components/Drawer/EditOutsourcedServiceDrawer/EditOutsourcedServiceDrawer';
import { EditIcon, IconButtonCustom } from './EditOutsourcedServiceAction.styles';

interface IEditOutsourcedServiceActionProps {
  outsourcedService: IBudgetItemOutsourcedService;
}

const EditOutsourcedServiceAction = ({ outsourcedService }: IEditOutsourcedServiceActionProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <IconButtonCustom color="inherit" className="edit-icon" title="editButton" onClick={() => setOpenDrawer(true)}>
        <Tooltip title="edit" placement="top" arrow>
          <EditIcon />
        </Tooltip>
      </IconButtonCustom>

      {openDrawer && (
        <EditOutsourcedServiceDrawer
          outsourcedService={outsourcedService}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
        />
      )}
    </>
  );
};

export default EditOutsourcedServiceAction;
