export enum InspectionCategoryEnum {
  DIGITAL = 'DIGITAL',
  LOCALLY = 'LOCALLY',
}

export enum InspectionPlatformEnum {
  CAP = 'CAP',
  SORT = 'SORT',
  WEBAPP = 'WEBAPP',
}

export enum inspectionTypeEnum {
  INITIAL = 'INITIAL',
  COMPLEMENTARY = 'COMPLEMENTARY',
  PREVIOUS = 'PREVIOUS',
}
