import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import {
  IInspectionFilterWithStatusIds,
  IInspectionFilterWithStatusType,
} from 'services/InspectionFilterService/inspectionFilterService.types';
import useSWR from 'swr';

export const useInspectionByAdminFilter = (inspectionAdminParams: IInspectionFilterWithStatusIds) => {
  const endpoint = inspectionAdminParams.isInspectionReport ? 'arval-report' : 'admin';

  const { data, error, isValidating } = useSWR(
    buildInspectionUrlParamsIfCompanyIdAndStatusIdsExist(inspectionAdminParams, endpoint),
    async (url) => {
      const inspectionsResponse = await InspectionService.search(url);
      return inspectionsResponse;
    },
    { revalidateOnFocus: false }
  );

  if (!inspectionAdminParams.companiesId[0]) {
    return {
      inspectionsData: data,
      inspectionsError: error,
      isValidating: true,
    };
  }

  return {
    inspectionsData: data,
    inspectionsError: error,
    isValidating,
  };
};

export const useInspectionByAdminFilterOld = (inspectionAdminParams: IInspectionFilterWithStatusType) => {
  const endpoint = inspectionAdminParams.isInspectionReport ? 'arval-report' : 'admin';

  const { data, error, isValidating } = useSWR(
    buildInspectionUrlParamsIfCompanyIdAndStatusTypeExist(inspectionAdminParams, endpoint),
    async (url) => {
      const inspectionsResponse = await InspectionService.search(url);
      return inspectionsResponse;
    },
    { revalidateOnFocus: false }
  );

  if (!inspectionAdminParams.companiesId[0]) {
    return {
      inspectionsData: data,
      inspectionsError: error,
      isValidating: true,
    };
  }

  return {
    inspectionsData: data,
    inspectionsError: error,
    isValidating,
  };
};

const buildInspectionUrlParams = (
  {
    companiesId,
    statusIds,
    createdAtGreaterThan,
    createdAtLessThan,
    inspectionCategoriesId,
  }: IInspectionFilterWithStatusIds,
  endpoint: string
): string => {
  const queryParams = new URLSearchParams({
    companiesId: companiesId.join(','),
    statusIds: statusIds ? statusIds.join(',') : '',
    createdAtGreaterThan,
    createdAtLessThan,
    inspectionCategoriesId: inspectionCategoriesId.join(','),
  });

  return `inspections/${endpoint}?${queryParams.toString()}`;
};

const buildInspectionUrlParamsOld = (
  {
    companiesId,
    statusType,
    createdAtGreaterThan,
    createdAtLessThan,
    inspectionCategoriesId,
  }: IInspectionFilterWithStatusType,
  endpoint: string
): string => {
  const queryParams = new URLSearchParams({
    companiesId: companiesId.join(','),
    statusType,
    createdAtGreaterThan,
    createdAtLessThan,
    inspectionCategoriesId: inspectionCategoriesId.join(','),
  });

  return `inspections/${endpoint}?${queryParams.toString()}`;
};

const buildInspectionUrlParamsIfCompanyIdAndStatusIdsExist = (
  inspectionAdminParams: IInspectionFilterWithStatusIds,
  endpoint: string
) => {
  const { statusIds, companiesId } = inspectionAdminParams;
  const statusNotEmpty = statusIds.length > 0;
  const companiesNotEmpty = companiesId.length > 0;
  const statusAndCompaniesNotEmpty = statusNotEmpty && companiesNotEmpty;

  return statusAndCompaniesNotEmpty ? buildInspectionUrlParams(inspectionAdminParams, endpoint) : null;
};

const buildInspectionUrlParamsIfCompanyIdAndStatusTypeExist = (
  inspectionAdminParams: IInspectionFilterWithStatusType,
  endpoint: string
) => {
  const { statusType, companiesId } = inspectionAdminParams;
  const statusNotEmpty = statusType !== '' && statusType !== null && statusType !== undefined;
  const companiesNotEmpty = companiesId.length > 0;
  const statusAndCompaniesNotEmpty = statusNotEmpty && companiesNotEmpty;

  return statusAndCompaniesNotEmpty ? buildInspectionUrlParamsOld(inspectionAdminParams, endpoint) : null;
};
