import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-width: 418px;
  width: 100%;
  max-width: 1153px;
  min-height: 240px;
  border: 1px solid #f8f9fa;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 3px 14px 0px rgba(238, 238, 238, 0.5);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Montserrat';
  color: #000;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat';
  color: #737791;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const ShowDetails = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  font-family: 'Montserrat';
  color: #000000;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #737791;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: lightgreen;
  width: 170px;
  height: 130px;
  border: 1px solid gray;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
`;
