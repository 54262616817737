import styled from 'styled-components';

export const BudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #00000017;
  border-radius: 4px;

  width: 49%;
  height: 345px;
`;

export const ServicePricesHeader = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
`;

export const RowServiceHeader = styled.div`
  padding: 6px 4px;
`;

export const RowServiceBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 4px;

  &:nth-child(even) {
    background: #ebebeb;
    border-radius: 4px;
  }
`;

export const Label = styled.div`
  color: #656565;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  white-space: nowrap;
`;

export const ValueServiceHeader = styled.div`
  font-weight: 500;
  color: #000000;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
`;

export const ValueServiceBody = styled.div`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 18px;
`;

export const ServicePricesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-top: 1.5px solid #e8ebf1;
`;

export const TotalPrices = styled.div`
  display: flex;
  gap: 10px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ValueBlue = styled(ValueServiceBody)`
  color: #0e86ca;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }: { gap?: string }) => gap || '0'};
  font-size: 12px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const PercentageText = styled.div`
  font-size: 12px;
`;

export const SemiBold = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #c0c0c0;
`;

export const LabelPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BreakSpaces = styled(Label)`
  white-space: break-spaces;
`;

export const OrangeDot = styled(Dot)`
  background-color: #f67228;
`;

export const RedDot = styled(Dot)`
  background-color: #c60c00;
`;
