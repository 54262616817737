import React from 'react';
import { Drawer } from './Drawer.styles';
import { IDrawerProps } from './DrawerProps.types';

export default function DrawerRoot({
  children,
  onClose,
  openDrawer,
  anchor,
  testId = 'drawer',
  className,
}: IDrawerProps) {
  return (
    <Drawer anchor={anchor} open={openDrawer} onClose={onClose} data-testid={testId} className={className}>
      {children}
    </Drawer>
  );
}
