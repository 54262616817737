import SendDocumentDialog from 'components/Dialog/SendDocumentDialog/SendDocumentDialog';
import React, { useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import AccountService from 'services/ApiService/AccountService';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import DocumentService from 'services/DocumentService/documentService';
import { IResultUpdateContract, IUploadDocument } from 'services/DocumentService/documentService.types';
import { IBulkUpdateDialogProps } from './BulkUpdateDialog.types';
import { isAxiosError } from 'utils/isAxiosError';

const BulkUpdateDialog = ({ bulkUpdateDialogIsOpen, setBulkUpdateDialogIsOpen }: IBulkUpdateDialogProps) => {
  const [loadingButton, setLoadingButton] = useState(false);

  const dialogBulkClose = (): void => {
    setBulkUpdateDialogIsOpen(false);
  };

  const onSubmitDocument = async (onImageRemoveAll: () => void, documents: ImageListType) => {
    setLoadingButton(true);
    try {
      const activeProfile = AccountService.getActiveProfileInLocalStorage();
      const s3Documents = await DocumentService.sendDocuments(activeProfile.clientId, documents);

      await bulkUpdateInspections(s3Documents);
      onImageRemoveAll();
      setBulkUpdateDialogIsOpen(false);
    } catch (error) {
      toast.error('Erro ao enviar a planilha de acordos.');
    } finally {
      setLoadingButton(false);
    }
  };

  const getSuccessMessage = (success: number) => {
    return success > 1 ? `${success} acordos foram atualizados` : `${success} acordo foi atualizado`;
  };

  const getFailureMessage = (failure: number) => {
    return failure > 1 ? ` e ${failure} falharam.` : ` e ${failure} falhou.`;
  };

  const getToastSuccessOrWarningMessage = (data: IResultUpdateContract) => {
    const { success, failure } = data;
    const messageSuccess = getSuccessMessage(success);
    const messageFailure = getFailureMessage(failure);

    switch (true) {
      case failure > 0 && success > 0:
        toast.warning(`${messageSuccess} ${messageFailure}`);
        break;
      case failure > 0 && success === 0:
        toast.warning('Nenhum acordo foi atualizado.');
        break;
      default:
        toast.success('Todos os acordos foram atualizados.');
    }
  };

  const getToastErrorMessage = (error: number | undefined) => {
    if (error === 415) {
      toast.error('Ocorreu um erro, a planilha está fora dos padrões esperados.');
    } else if (error === 402) {
      toast.error('Ocorreu um erro ao processar a planilha.');
    } else {
      toast.error('Ocorreu um erro inesperado.');
    }
  };

  const getPathDocument = (document: IUploadDocument[]) => {
    try {
      return document[0].path as string;
    } catch (error) {
      toast.error('Ocorreu um erro ao recuperar a planilha');
      throw new Error();
    }
  };

  const bulkUpdateInspections = async (document: IUploadDocument[]) => {
    const path = getPathDocument(document);

    try {
      const data = await InspectionService.bulkUpdateInspections(path);
      getToastSuccessOrWarningMessage(data);
    } catch (error) {
      if (isAxiosError(error)) {
        getToastErrorMessage(error?.response?.status);
      } else {
        toast.error('Ocorreu um erro inesperado em receber os dados.');
      }
    }
  };

  return (
    <SendDocumentDialog
      title="Enviar planilha de acordos"
      subtitle="Selecione uma planilha para atualizar os dados dos atendimentos"
      textDragAndDrop="Clique ou arraste o arquivo de planilha aqui"
      errorMessage="* Tipo de arquivo não suportado, use apenas XLSX"
      btnSubmitText="ENVIAR"
      dialogVisibility={bulkUpdateDialogIsOpen}
      extensions={['xlsx']}
      replaceTooltipIcon="Substituir planilha"
      removeTooltipIcon="Remover planilha"
      loadingButton={loadingButton}
      handleSubmit={onSubmitDocument}
      handleClose={dialogBulkClose}
      hasSelect={false}
    />
  );
};

export default BulkUpdateDialog;
