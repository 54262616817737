import React, { useState } from 'react';
import CheckIconConclusion from 'assets/svg/check-icon-conclusion.svg';
import { DataBox } from 'components/DataBox';
import { StatusEnum } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { ConsumersEnum } from 'services/ApiService/ConsumerService/types';
import { useConsumer } from 'contexts/consumers/useConsumer';
import EmptyConclusion from './components/EmptyConclusion/EmptyConclusion';
import ConclusionDialog from '../../../../../../components/Dialog/ConclusionDialog/ConclusionDialog';
import ConclusionCard from './components/ConclusionCard/ConclusionCard';
import { IIInspectionConclusionProps } from './InspectionConclusion.types';
import { Container, Content } from './InspectionConclusion.styles';

const InspectionConclusion = ({ inspection }: IIInspectionConclusionProps) => {
  const { status, companyId, conclusion, currentStatus } = inspection;
  const [showConclusionDialog, setShowConclusionDialog] = useState<boolean>(false);
  const { isConsumerActive } = useConsumer();

  const hasInspectionStartedStatus = status.some(({ type }) => type === StatusEnum.INSPECTION_STARTED);

  const hasConclusionConsumer = isConsumerActive(ConsumersEnum.INSPECTION_CONCLUSION, companyId || '');

  const handleConclusionDialogOpen = () => {
    setShowConclusionDialog(true);
  };

  const handleConclusionDialogClose = () => {
    setShowConclusionDialog(false);
  };

  return hasConclusionConsumer && hasInspectionStartedStatus ? (
    <Container>
      <DataBox.Root>
        <DataBox.Header>
          <DataBox.Title>
            <img src={CheckIconConclusion} alt="icon-check" />
            Conclusão do atendimento
          </DataBox.Title>
        </DataBox.Header>

        <Content>
          {conclusion ? (
            <ConclusionCard
              conclusion={conclusion}
              inspectionStatus={currentStatus}
              onOpenConclusionDialog={handleConclusionDialogOpen}
            />
          ) : (
            <EmptyConclusion onOpenConclusionDialog={handleConclusionDialogOpen} />
          )}
        </Content>
      </DataBox.Root>
      <ConclusionDialog
        inspection={inspection}
        showConclusionDialog={showConclusionDialog}
        handleClose={handleConclusionDialogClose}
      />
    </Container>
  ) : null;
};

export default InspectionConclusion;
