import styled from 'styled-components';

export const DetailsBody = styled.div`
  flex: 1;
  margin-bottom: 50px;
`;

export const DetailsCards = styled.div`
  display: flex;
  gap: 15px;
  padding: 15px;
`;

export const InformationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;
