import { IBudgetWorkshop, IBudgetWorkshopAddress } from 'commom/types/Budget.types';
import React from 'react';
import workshopIcon from 'assets/svg/budget-template/icon-workshop.svg';
import {
  Container,
  HeaderSection,
  Information,
  InformationContainer,
  InformationWrapper,
  Label,
  Value,
} from './WorkshopSection.styles';

interface IWorkshopSectionProps {
  workshop: IBudgetWorkshop;
}

const WorkshopSection = ({ workshop }: IWorkshopSectionProps) => {
  const formatAddress = ({ cep, city, fullAddress, neighborhood, uf }: IBudgetWorkshopAddress) => {
    return `${fullAddress}, ${neighborhood} ${city ? `- ${city}` : ''} ${uf ? `- ${uf}` : ''} ${cep ? `- ${cep}` : ''}`;
  };

  return (
    <Container>
      <InformationContainer>
        <InformationWrapper>
          <HeaderSection>
            <img src={workshopIcon} alt="clock" width="18" />
            <h1>Dados da oficina</h1>
          </HeaderSection>

          <Information>
            <Label>Nome</Label>
            <Value>{workshop.name || '-'}</Value>
          </Information>

          <Information>
            <Label>CNPJ</Label>
            <Value>{workshop.cnpj || '-'}</Value>
          </Information>

          <Information>
            <Label>Telefone</Label>
            <Value>{workshop.phone || '-'}</Value>
          </Information>

          <Information>
            <Label>Email</Label>
            <Value>{workshop.email || '-'}</Value>
          </Information>
        </InformationWrapper>
      </InformationContainer>

      <InformationContainer>
        <InformationWrapper>
          <Information>
            <Label>Endereço</Label>
            <Value>{workshop.address ? formatAddress(workshop.address) : '-'}</Value>
          </Information>
        </InformationWrapper>
      </InformationContainer>
    </Container>
  );
};

export default WorkshopSection;
