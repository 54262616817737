/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import DocumentService from 'services/DocumentService/documentService';
import Lightbox from 'react-image-lightbox';
import { FileExtensionEnum, IconType } from 'services/DocumentService/documentService.types';
import { Tooltip } from '@mui/material';
import { DocumentTypeEnum, IDocument } from 'pages/SchedulerList/components/Inspection';
import {
  DocumentWrapper,
  LightboxWrapper,
  Loading,
  Thumbnail,
  DocumentCardWrapper,
  DocumentIndexBadge,
  DocumentCard,
  DocumentIconStyle,
  DocumentCardTitle,
} from './InspectionDocuments.style';
import {
  IInspectionDocumentsProps,
  IInspectionDocumentsWithProps,
  IDocumentFileProps,
  IDocSkeleton,
} from './InspectionDocuments.types';

const hiddenDocumentStyle = {
  width: 0,
  height: 0,
};

const GalleryWithDocuments = ({
  documents,
  orientation,
  thumbnailHeigth,
  thumbnailWidth,
  documentIndex,
  setDocIndex,
  isDocumentsOpen,
  setIsDocumentsOpen,
}: IInspectionDocumentsWithProps): JSX.Element => {
  const renderDocumentIndex = (index: number) => (index < 10 ? `0${index}` : index);

  const verifyUrl = (url: string | undefined) => {
    return url !== null && url !== undefined && url !== '' ? url : 'Error';
  };

  const handleDocUrl = () => {
    return verifyUrl(documents[documentIndex]?.url);
  };

  const getNextDocImage = (startIndex: number, index: boolean) => {
    const remainingDocuments = documents.slice(startIndex + 1);
    return index
      ? remainingDocuments.findIndex((doc) => doc.type === DocumentTypeEnum.IMAGE)
      : remainingDocuments.find((doc) => doc.type === DocumentTypeEnum.IMAGE);
  };

  const getPreviousDocImage = (index: boolean) => {
    const remainingDocuments = documents.slice(0, documentIndex).reverse();
    return index
      ? remainingDocuments.findIndex((doc) => doc.type === DocumentTypeEnum.IMAGE)
      : remainingDocuments.find((doc) => doc.type === DocumentTypeEnum.IMAGE);
  };

  const getNextImageURL = (): string => {
    const nextImage = getNextDocImage(documentIndex, false);

    if (typeof nextImage !== 'number' && nextImage !== undefined) {
      return nextImage.url;
    }

    const firstImage = documents.find((doc) => doc.type === DocumentTypeEnum.IMAGE);
    return verifyUrl(firstImage?.url);
  };

  const getPreviousImageURL = (): string => {
    const prevImage = getPreviousDocImage(false);

    if (typeof prevImage !== 'number' && prevImage !== undefined) {
      return prevImage.url;
    }

    const lastImage = documents
      .slice()
      .reverse()
      .find((doc) => doc.type === DocumentTypeEnum.IMAGE);
    return verifyUrl(lastImage?.url);
  };

  const getNextImageIndex = (startIndex: number): number => {
    const nextImageIndex = getNextDocImage(documentIndex, true);

    if (typeof nextImageIndex === 'number' && nextImageIndex !== undefined && nextImageIndex !== -1) {
      return nextImageIndex + startIndex + 1;
    }

    const firstImageIndex = documents.findIndex((doc) => doc.type === DocumentTypeEnum.IMAGE);
    return firstImageIndex !== -1 ? firstImageIndex : startIndex;
  };

  const getPreviousImageIndex = (startIndex: number): number => {
    const prevImageIndex = getPreviousDocImage(true);

    if (typeof prevImageIndex === 'number' && prevImageIndex !== undefined && prevImageIndex !== -1) {
      return startIndex - prevImageIndex - 1;
    }

    const lastImageIndex = documents
      .slice()
      .reverse()
      .findIndex((doc) => doc.type === DocumentTypeEnum.IMAGE);
    return lastImageIndex !== -1 ? documents.length - lastImageIndex - 1 : startIndex;
  };

  const setTitle = (document: IDocument) => {
    return document.description || document.name;
  };

  return (
    <>
      {documents.map((document, index) => (
        <DocumentCardWrapper key={index} role="document-card">
          <DocumentIndexBadge data-testid="index-badge">{renderDocumentIndex(index + 1)}</DocumentIndexBadge>
          <Tooltip title={setTitle(document)} placement="top">
            <DocumentCardTitle data-testid="document-description">{setTitle(document)}</DocumentCardTitle>
          </Tooltip>
          <DocumentFile
            document={document}
            setDocIndex={setDocIndex}
            index={index}
            orientation={orientation}
            thumbnailWidth={thumbnailWidth}
            thumbnailHeigth={thumbnailHeigth}
            setIsDocumentsOpen={setIsDocumentsOpen}
          />
        </DocumentCardWrapper>
      ))}

      {isDocumentsOpen && (
        <LightboxWrapper data-testid="gallery">
          <Lightbox
            mainSrc={handleDocUrl()}
            nextSrc={getNextImageURL()}
            prevSrc={getPreviousImageURL()}
            onCloseRequest={(): void => setIsDocumentsOpen(false)}
            onMovePrevRequest={(): void => setDocIndex(getPreviousImageIndex(documentIndex))}
            onMoveNextRequest={(): void => setDocIndex(getNextImageIndex(documentIndex))}
            imageTitle={documents[documentIndex].name}
          />
        </LightboxWrapper>
      )}
    </>
  );
};

export const InspectionDocuments = ({
  documents,
  orientation,
  thumbnailHeigth,
  thumbnailWidth,
  testId,
}: IInspectionDocumentsProps): JSX.Element => {
  const [documentIndex, setDocIndex] = useState<number>(0);
  const [isDocumentsOpen, setIsDocumentsOpen] = useState<boolean>(false);
  return (
    <DocumentWrapper data-testid={testId} orientation={orientation}>
      <GalleryWithDocuments
        documents={documents}
        orientation={orientation}
        thumbnailHeigth={thumbnailHeigth}
        thumbnailWidth={thumbnailWidth}
        documentIndex={documentIndex}
        setDocIndex={setDocIndex}
        isDocumentsOpen={isDocumentsOpen}
        setIsDocumentsOpen={setIsDocumentsOpen}
      />
    </DocumentWrapper>
  );
};

export const DocumentFile = ({
  document,
  setIsDocumentsOpen,
  setDocIndex,
  index,
  orientation,
  thumbnailWidth,
  thumbnailHeigth,
}: IDocumentFileProps): JSX.Element => {
  const type = document.path.split('.')[1];

  const downloadDocument = () => {
    DocumentService.downloadDocument(document);
  };

  const openPdf = async () => {
    await DocumentService.openPdfinNewTab(document);
  };

  const documentIcon = (typeDocument: string) => {
    let iconSrc = DocumentService.documentIcon(typeDocument);
    const clickHandler = typeDocument === FileExtensionEnum.PDF ? openPdf : downloadDocument;

    IconType.forEach((icon) => {
      if (icon.type === typeDocument) {
        iconSrc = icon.icon;
      }
    });

    return <DocumentIconStyle data-testid="icon-doc" src={iconSrc} onClick={clickHandler} />;
  };

  return (
    <DocumentCard data-testid="document-card">
      {document.type === DocumentTypeEnum.FILE ? (
        documentIcon(type)
      ) : (
        <DocImageWithSkeleton
          document={document}
          orientation={orientation}
          thumbnailHeigth={thumbnailHeigth}
          thumbnailWidth={thumbnailWidth}
          setIsDocumentsOpen={setIsDocumentsOpen}
          setDocIndex={setDocIndex}
          index={index}
        />
      )}
    </DocumentCard>
  );
};

const DocImageWithSkeleton = ({
  document,
  orientation,
  thumbnailWidth,
  thumbnailHeigth,
  setIsDocumentsOpen,
  setDocIndex,
  index,
}: IDocSkeleton): JSX.Element => {
  const [loaded, setLoaded] = useState(false);

  const OpenDocumentGallery = (): void => {
    setIsDocumentsOpen(true);
    setDocIndex(index);
  };

  const RenderImageDoc: React.FC = () => {
    return (
      <Thumbnail
        data-testid="thumbDocumentDoc"
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        src={document.url}
        onClick={OpenDocumentGallery}
      />
    );
  };

  const RenderSkeletonLoading: React.FC = () => (
    <>
      <Loading
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        variant="rect"
        animation="wave"
      />

      <Thumbnail
        data-testid="thumbDocumentDoc"
        orientation={orientation}
        width={thumbnailWidth}
        height={thumbnailHeigth}
        onLoad={(): void => setLoaded(true)}
        style={hiddenDocumentStyle}
        positionLoading={true}
        src={document.url}
        onClick={OpenDocumentGallery}
      />
    </>
  );

  return <>{loaded ? <RenderImageDoc /> : <RenderSkeletonLoading />}</>;
};
