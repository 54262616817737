import { RootState } from 'stores/store';

export const selectBudget = (state: RootState) => {
  return state.presentBudget.budget;
};

export const selectDeductible = (state: RootState) => {
  return state.presentBudget.budget.deductible;
};

export const selectSummaryCost = (state: RootState) => {
  return state.presentBudget.budget.summaryCost;
};

export const selectBudgetId = (state: RootState) => {
  return state.presentBudget.budget._id;
};

export const selectCost = (state: RootState) => {
  return state.presentBudget.budget.labor.cost;
};

export const selectLaborDiscount = (state: RootState) => {
  return state.presentBudget.budget.labor.discount;
};

export const selectLaborIncrease = (state: RootState) => {
  return state.presentBudget.budget.labor.increase;
};

export const selectNegotiationHistory = (state: RootState) => {
  return state.presentBudget.budget.laborNegotiationHistory;
};

export const selectBudgetClientName = (state: RootState) => {
  return state.presentBudget.budget.clientInfo.name;
};

export const selectCurrentStep = (state: RootState) => {
  return state.presentBudget.currentStep;
};

export const selectConclusions = (state: RootState) => {
  return state.presentBudget.budget.conclusions;
};

export const selectCurrentStatus = (state: RootState) => {
  return state.presentBudget.budget.currentStatus;
};

export const selectIsTotalLoss = (state: RootState) => {
  return state.presentBudget.budget?.isTotalLoss;
};

export const selectReports = (state: RootState) => {
  return state.presentBudget.budget?.reports;
};

export const selectRescuedReportQuestionGroup = (state: RootState) => {
  return state.presentBudget.rescuedReport?.questionsGroups;
};

export const selectDamageReportQuestionGroup = (state: RootState) => {
  return state.presentBudget.damageReport?.questionsGroups;
};

export const selectDamageReportPartSeverity = (state: RootState) => {
  return state.presentBudget.damageReport?.damagePartSeverity;
};

export const selectDamageSeverity = (state: RootState) => {
  return state.presentBudget.damageReport?.damagePartSeverity.severity;
};

export const selectVehicleEvaluationWithoutEngineAccess = (state: RootState) => {
  return state.presentBudget.rescuedReport?.vehicleEvaluation.withoutEngineAccess;
};

export const selectVehicleEvaluationGeneralEvaluation = (state: RootState) => {
  return state.presentBudget.rescuedReport?.vehicleEvaluation.generalEvaluation;
};

export const selectVehicleEvaluationEngineNumber = (state: RootState) => {
  return state.presentBudget.rescuedReport?.vehicleEvaluation.engineNumber;
};

export const selectVehicleEvaluationJustifications = (state: RootState) => {
  return state.presentBudget.rescuedReport?.vehicleEvaluation.justifications;
};

export const selectRescuedReport = (state: RootState) => {
  return state.presentBudget.rescuedReport;
};

export const selectDamageReport = (state: RootState) => {
  return state.presentBudget.damageReport;
};

export const selectRescuedQuestionIdsUnanswered = (state: RootState) => {
  return state.presentBudget.rescuedQuestionIdsUnanswered;
};

export const selectDamageQuestionIdsUnanswered = (state: RootState) => {
  return state.presentBudget.damageQuestionsIdsUnanswered;
};

export const selectRescuedReportIsDraft = (state: RootState) => {
  return state.presentBudget.rescuedReport?.isDraft;
};

export const selectDamageReportIsDraft = (state: RootState) => {
  return state.presentBudget.damageReport?.isDraft;
};
