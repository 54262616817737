import React from 'react';
import EditInspectionAction from './components/EditInspectionAction/EditInspectionAction';
import FinishInspectionAction from './components/FinishInspectionAction/FinishInspectionAction';
import HeaderTitle from './components/HeaderTitle/HeaderTitle';
import ViewBudgetsAction from './components/ViewBudgetsAction/ViewBudgetsAction';
import OpenTransmissionAction from './components/OpenTransmissionAction/OpenTransmissionAction';
import StartInpectionAction from './components/StartInpectionAction/StartInpectionAction';
import { IHeaderProps } from './Header.types';
import * as S from './Header.styles';

const Header = ({ inspection }: IHeaderProps) => {
  return (
    <S.Header>
      <HeaderTitle inspection={inspection} />
      <S.Actions>
        <FinishInspectionAction inspection={inspection} />

        <StartInpectionAction inspection={inspection} />

        <OpenTransmissionAction inspection={inspection} />

        <ViewBudgetsAction inspection={inspection} />

        <EditInspectionAction inspection={inspection} />
      </S.Actions>
    </S.Header>
  );
};

export default Header;
