import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import AccountService from 'services/ApiService/AccountService';
import AuthService from 'services/ApiService/AuthService/authService';
import { AuthorityEnum } from 'utils/AuthorityEnum';

import { ISignInCredentials, IAuthDispatchContextData, IAccount, IAuthStateContextData } from './types';

export const AuthDispatchContext = createContext<IAuthDispatchContextData>({} as IAuthDispatchContextData);

export const AuthStateContext = createContext<IAuthStateContextData>({} as IAuthStateContextData);

export const AuthContainer: React.FC = ({ children }) => {
  const [account, setAccount] = useState<IAccount>({} as IAccount);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const accountLocalStorage = AuthService.getAccountInLocalStorage();
      if (accountLocalStorage) setAccount(accountLocalStorage);
    } catch (arror) {
      toast.error('Ocorreu um erro interno ao tentar validar os dados do usuário.');

      AuthService.deleteAccountFromLocalStorage();
    }
  }, []);

  const signIn = useCallback(
    async ({ username, password }: ISignInCredentials): Promise<void> => {
      try {
        setLoading(true);

        await AuthService.login(username, password);

        const accountResponse = await AuthService.getAccountByUsername(username);

        AuthService.deleteAccountFromLocalStorage();

        AuthService.setAccountInLocalStorage(accountResponse);

        setAccount(accountResponse);
      } catch (error) {
        setLoading(false);
        throw new Error();
      }
    },

    [setAccount, setLoading]
  );

  const signOut = useCallback(async (): Promise<void> => {
    await AuthService.logout();

    AccountService.deleteAccountGroupsFromLocalStorage();
    AccountService.deleteActiveProfileFromLocalStorage();
    AuthService.deleteAccountFromLocalStorage();

    setLoading(false);

    setAccount({} as IAccount);
  }, [setAccount]);

  const isAuthenticated = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    const accountGroups = AccountService.getAccountGroupsInLocalStorage();
    const activeProfile = AccountService.getActiveProfileInLocalStorage();

    return (
      !!Object.keys(accountLocalStorage).length &&
      !!Object.keys(accountGroups).length &&
      !!Object.keys(activeProfile).length
    );
  }, []);

  const isEmailConfirmed = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    return accountLocalStorage.hasValidatedUsername;
  }, []);

  const isRegulator = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    return isAuthenticated() && accountLocalStorage.authority === AuthorityEnum.REGULATOR;
  }, [isAuthenticated]);

  const isAdmin = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    return isAuthenticated() && accountLocalStorage.authority === AuthorityEnum.ADMIN;
  }, [isAuthenticated]);

  const isOwner = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    return isAuthenticated() && accountLocalStorage.authority === AuthorityEnum.OWNER;
  }, [isAuthenticated]);

  const isAnalyst = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    return isAuthenticated() && accountLocalStorage.authority === AuthorityEnum.ANALYST;
  }, [isAuthenticated]);

  const isInsuranceCompany = useCallback((): boolean => {
    const accountLocalStorage = AuthService.getAccountInLocalStorage();
    return isAuthenticated() && accountLocalStorage.authority === AuthorityEnum.INSURANCE_COMPANY;
  }, [isAuthenticated]);

  const authState = useMemo(
    () => ({
      account,
      loading,
    }),
    [account, loading]
  );

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider
        value={{
          signIn,
          signOut,
          isAuthenticated,
          isEmailConfirmed,
          isRegulator,
          isAdmin,
          isOwner,
          isAnalyst,
          isInsuranceCompany,
        }}
      >
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
