import React from 'react';
import { IMaskMixin } from 'react-imask';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'themes/default';
import { ITextField } from './TextField.types';
import * as Style from './TextField.styles';

export const TextField = IMaskMixin(
  ({
    ariaLabel,
    ariaLabelledby,
    autoComplete,
    autoFocus,
    className,
    classes,
    color,
    defaultValue,
    disabled,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    margin,
    multiline,
    name,
    onChange,
    onClick,
    onKeyUp,
    onKeyDown,
    placeholder,
    required,
    minRows,
    maxRows,
    select,
    SelectProps,
    size = 'small',
    testID,
    type,
    value,
    variant,
    min,
    max,
    mask,
    blocks,
    pipe,
    ...restProps
  }: ITextField) => (
    <ThemeProvider theme={theme}>
      <Style.TextField
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        classes={classes}
        color={color}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        FormHelperTextProps={FormHelperTextProps}
        fullWidth={fullWidth}
        helperText={helperText}
        id={id}
        InputLabelProps={InputLabelProps}
        inputProps={inputProps}
        InputProps={InputProps}
        inputRef={inputRef}
        label={label}
        margin={margin}
        multiline={multiline}
        name={name}
        onChange={onChange}
        onClick={onClick}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        required={required}
        minRows={minRows}
        maxRows={maxRows}
        select={select}
        SelectProps={SelectProps}
        size={size}
        data-testid={testID}
        type={type}
        value={value}
        variant={variant}
        className={className}
        min={min}
        max={max}
        mask={mask}
        blocks={blocks}
        pipe={pipe}
        {...restProps}
      />
    </ThemeProvider>
  )
);
