import React, { useState } from 'react';
import { Button } from 'components';
import EmptyWorkshop from 'assets/svg/empty-workshop.svg';
import { DataBox } from 'components/DataBox';
import { IWorkshop } from 'services/ApiService/WorkshopService/WorkshopService.types';
import { CardEmpty, EmptyDescription, EmptyMessage, EmptyTitle } from './Workshop.styles';
import { Card, CardBody, CardHeader, CardTitle } from '../../CreateBudget.styles';
import SearchWorkshopDrawer from './WorkshopDrawer/SearchWorkshopDrawer';

interface IWorkshopProps {
  selectedWorkshop: IWorkshop | null;
  setSelectedWorkshop: React.Dispatch<React.SetStateAction<IWorkshop | null>>;
}

const Workshop = ({ selectedWorkshop, setSelectedWorkshop }: IWorkshopProps) => {
  const [openSearchWorkshopDrawer, setOpenSearchWorkshopDrawer] = useState(false);

  const handleOpenSearchWorkshopDrawer = () => {
    setOpenSearchWorkshopDrawer(true);
  };

  const handleCloseSearchWorkshopDrawer = () => {
    setOpenSearchWorkshopDrawer(false);
  };

  const workshopInfos = [
    { label: 'Nome', value: selectedWorkshop?.name || '-' },
    { label: 'CNPJ', value: selectedWorkshop?.cnpj || '-' },
    { label: 'Telefone', value: selectedWorkshop?.phone || '-' },
    { label: 'Email', value: selectedWorkshop?.email || '-' },
    {
      label: 'Endereço',
      value:
        `${selectedWorkshop?.address?.fullAddress}, ${selectedWorkshop?.address?.neighborhood}, ${selectedWorkshop?.address?.city} - ${selectedWorkshop?.address?.uf} - ${selectedWorkshop?.address?.cep}` ||
        '-',
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>OFICINA</CardTitle>
          <Button
            testID="search-workshop"
            text="pesquisar oficina"
            variant="outlined"
            onClick={handleOpenSearchWorkshopDrawer}
          />
        </CardHeader>

        {!selectedWorkshop ? (
          <CardEmpty>
            <img alt="Oficina Vazia" src={EmptyWorkshop} />
            <EmptyMessage>
              <EmptyTitle>Adição de oficina</EmptyTitle>
              <EmptyDescription>
                Pesquise ou cadastre uma oficina, e preencha os valores de franquia e segurado.
              </EmptyDescription>
            </EmptyMessage>
          </CardEmpty>
        ) : (
          <CardBody>
            <DataBox.Root>
              <DataBox.Content>
                {workshopInfos.map(({ label, value }) => (
                  <DataBox.Item key={label}>
                    <DataBox.Label>{label}</DataBox.Label>
                    <DataBox.Value>{value}</DataBox.Value>
                  </DataBox.Item>
                ))}
              </DataBox.Content>
            </DataBox.Root>
          </CardBody>
        )}
      </Card>

      {openSearchWorkshopDrawer && (
        <SearchWorkshopDrawer
          openDrawer={openSearchWorkshopDrawer}
          onCloseDrawer={handleCloseSearchWorkshopDrawer}
          setSelectedWorkshop={setSelectedWorkshop}
        />
      )}
    </>
  );
};

export default Workshop;
