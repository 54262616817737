import { InspectionGallery } from 'components/InspectionGallery/InspectionGallery';
import React from 'react';
import { ImagesWrapper, Title, PhotosState } from './Photos.styles';
import { IPhotosProps } from './Photos.types';

export const Photos = ({
  photos,
  testID,
  isOrganizingPhotos,
  company,
  selectSwappPhoto,
  canSelectSwappPhotos,
  isPhotoSelected,
}: IPhotosProps): JSX.Element => {
  const renderPhotosReceivedMessage = () => {
    if (photos.length === 0) {
      return (
        <>
          <b>Nenhuma</b> foto recebida.
        </>
      );
    }
    if (photos.length === 1) {
      return (
        <>
          Foi recebida <b>1</b> foto.
        </>
      );
    }
    return (
      <>
        Foram recebidas <b>{photos.length}</b> fotos.
      </>
    );
  };

  return (
    <>
      <PhotosState>
        <Title>{renderPhotosReceivedMessage()}</Title>
      </PhotosState>
      <ImagesWrapper role="photo-wrapper" data-testid={testID}>
        <InspectionGallery
          images={photos}
          isOrganizingPhotos={isOrganizingPhotos}
          selectSwappPhoto={selectSwappPhoto}
          canSelectSwappPhotos={canSelectSwappPhotos}
          isPhotoSelected={isPhotoSelected}
          orientation="horizontal"
          thumbnailHeigth="120"
          thumbnailWidth="160"
          companyId={company?.id}
        />
      </ImagesWrapper>
    </>
  );
};
