import React from 'react';
import NoteIcon from '@mui/icons-material/StickyNote2Outlined';
import { IInformation } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import { DataBox } from 'components/DataBox';

interface IAttendanceDataProps {
  informations: IInformation[];
}

const AttendanceData = ({ informations }: IAttendanceDataProps) => {
  return (
    <>
      <DataBox.Root>
        <DataBox.Header>
          <DataBox.Title>
            <NoteIcon /> Dados do atendimento
          </DataBox.Title>
        </DataBox.Header>
        <DataBox.Content>
          {informations?.map(({ key, value }) => (
            <DataBox.Item key={key}>
              <DataBox.Value>{key}</DataBox.Value>
              <DataBox.Label>{value || '-'}</DataBox.Label>
            </DataBox.Item>
          ))}
        </DataBox.Content>
      </DataBox.Root>
    </>
  );
};

export default AttendanceData;
