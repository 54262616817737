import { AnyAction, createListenerMiddleware, ListenerEffectAPI, ThunkDispatch } from '@reduxjs/toolkit';
import { IReportRescuedParts, IReportDamageParts } from 'commom/types/BudgetReport.types';
import queryClient from 'lib/reactQuery';
import { debounce } from 'lodash';
import BudgetReportService from 'services/ApiService/BudgetReportService/BudgetReportService';
import { RootState } from 'stores/store';
import {
  setRescuedReportAllAnswers,
  setRescuedReportAnswerId,
  setRescuedReportAnswerText,
  setVehicleEvaluationEngineNumber,
  setVehicleEvaluationGeneralEvaluation,
  setVehicleEvaluationJustifications,
  setVehicleEvaluationWithoutEngineAccess,
  setDamageReportAllAnswers,
  setDamageReportAnswerId,
} from '../presentBudget.store';

const updateReportsListenerMiddleware = createListenerMiddleware();

const debounceMutationTime = 1500;

const createDebouncedMutation = <T>(mutationFn: (variables: T) => Promise<void>, errorMessage: string) => {
  return debounce(async (variables: T) => {
    try {
      await queryClient.executeMutation({
        mutationFn,
        variables,
      });
    } catch (error) {
      console.error(errorMessage, error);
    }
  }, debounceMutationTime);
};

const debouncedRescuedMutation = createDebouncedMutation<IReportRescuedParts>(
  BudgetReportService.updateBudgetReportRescuedParts,
  'Erro ao atualizar dados do laudo de salvados:'
);

const debouncedDamageMutation = createDebouncedMutation<IReportDamageParts>(
  BudgetReportService.updateBudgetReportDamageParts,
  'Erro ao atualizar dados do laudo de danos'
);

const handleReportUpdate = <T>(getReport: (state: RootState) => T | null, debouncedMutation: (report: T) => void) => {
  return async (listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>) => {
    const state = listenerApi.getState() as RootState;
    const report = getReport(state);

    if (report) {
      debouncedMutation(report);
    }
  };
};

const handleRescuedReportUpdate = handleReportUpdate(
  (state: RootState) => state.presentBudget.rescuedReport,
  debouncedRescuedMutation
);

const handleDamageReportUpdate = handleReportUpdate(
  (state: RootState) => state.presentBudget.damageReport,
  debouncedDamageMutation
);

const addListeners = (
  actions: AnyAction[],
  handler: (
    listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
  ) => Promise<void>
) => {
  actions.forEach((actionCreator) => {
    updateReportsListenerMiddleware.startListening({
      type: actionCreator.type,
      effect: async (_, listenerApi) => {
        await handler(listenerApi);
      },
    });
  });
};

addListeners(
  [
    setRescuedReportAnswerId,
    setRescuedReportAllAnswers,
    setRescuedReportAnswerText,
    setVehicleEvaluationJustifications,
    setVehicleEvaluationWithoutEngineAccess,
    setVehicleEvaluationGeneralEvaluation,
    setVehicleEvaluationEngineNumber,
  ],
  handleRescuedReportUpdate
);

addListeners([setDamageReportAnswerId, setDamageReportAllAnswers], handleDamageReportUpdate);

export default updateReportsListenerMiddleware;
