import {
  IBudgetPartServiceHours,
  IBudgetPartServiceType,
  IBudgetPartServiceTypeEnum,
  IBudgetPart,
} from 'commom/types/Budget.types';
import { PartType } from 'components/Drawer/PartDrawer/PartDrawer.types';
import React, { createContext, useContext, useState } from 'react';

export type PartContextValue = {
  isServiceTypeRepair: boolean;
  isPartyManuallyInserted: boolean;
  serviceType: IBudgetPartServiceType;
  partOriginalServiceHours: IBudgetPartServiceHours;
  partServiceHours: IBudgetPartServiceHours;
  quantityParts: number;
  partDiscount: number;
  partPrice: number;
  partOriginalPrice: number;
  partName: string;
  partSku: string;
  partType: PartType;
  partSupplier: { code: string; description: string };
  partSourceType: { code: string; description: string };
  setPartSupplier: React.Dispatch<React.SetStateAction<{ code: string; description: string }>>;
  setPartSourceType: React.Dispatch<React.SetStateAction<{ code: string; description: string }>>;
  setIsPartyManuallyInserted: React.Dispatch<React.SetStateAction<boolean>>;
  setPartName: React.Dispatch<React.SetStateAction<string>>;
  setPartSku: React.Dispatch<React.SetStateAction<string>>;
  setPartType: React.Dispatch<React.SetStateAction<PartType>>;
  setServiceType: React.Dispatch<React.SetStateAction<IBudgetPartServiceType>>;
  setPartServiceHours: React.Dispatch<React.SetStateAction<IBudgetPartServiceHours>>;
  setQuantityParts: React.Dispatch<React.SetStateAction<number>>;
  setPartDiscount: React.Dispatch<React.SetStateAction<number>>;
  setPartPrice: React.Dispatch<React.SetStateAction<number>>;
  setPartOriginalPrice: React.Dispatch<React.SetStateAction<number>>;
};

const PartContext = createContext({} as PartContextValue);

export const usePartContext = () => useContext(PartContext);

interface IPartContextProviderProps {
  children: React.ReactNode;
  part?: Partial<IBudgetPart>;
}

export function PartContextProvider({ children, part }: IPartContextProviderProps) {
  const defaultPart: IBudgetPart = {
    _id: '',
    createdAt: '',
    updatedAt: '',
    sku: '',
    name: '',
    user: '',
    partSupplier: { code: '', description: '' },
    sourceType: { code: '', description: '' },
    quantity: 1,
    originalPrice: 0,
    totalGrossPrice: 0,
    discount: 0,
    totalNetPrice: 0,
    individualNetPrice: 0,
    totalDiscount: 0,
    servicePrice: 0,
    inputNetPrice: 0,
    individualServicePrice: 0,
    finalDiscount: 0,
    finalIncrease: 0,
    totalFinalAmount: 0,
    isManualEntry: true,
    isNetPriceModified: false,
    serviceType: { code: IBudgetPartServiceTypeEnum.REPLACEMENT, description: 'Troca' },
    originalServiceHours: { removeAndInstall: 0, repair: 0, painting: 0 },
    serviceHours: { removeAndInstall: 0, repair: 0, painting: 0 },
  };

  const mergedPart = { ...defaultPart, ...part };

  const [serviceType, setServiceType] = useState<IBudgetPartServiceType>(mergedPart.serviceType);
  const [partServiceHours, setPartServiceHours] = useState<IBudgetPartServiceHours>(mergedPart.serviceHours);
  const [quantityParts, setQuantityParts] = useState(mergedPart.quantity);
  const [partDiscount, setPartDiscount] = useState(mergedPart.discount);
  const [partPrice, setPartPrice] = useState(
    mergedPart.isNetPriceModified ? mergedPart.inputNetPrice : mergedPart.individualNetPrice
  );
  const [partName, setPartName] = useState(mergedPart.name);
  const [partSku, setPartSku] = useState(mergedPart.sku);
  const [partType, setPartType] = useState<PartType>(PartType.GENUINE);
  const [isPartyManuallyInserted, setIsPartyManuallyInserted] = useState(mergedPart.isManualEntry);
  const [partSupplier, setPartSupplier] = useState(mergedPart.partSupplier);
  const [partSourceType, setPartSourceType] = useState(mergedPart.sourceType);
  const [partOriginalPrice, setPartOriginalPrice] = useState(mergedPart.originalPrice);

  const isServiceTypeRepair = serviceType.code === IBudgetPartServiceTypeEnum.REPAIR;

  return (
    <PartContext.Provider
      value={{
        isServiceTypeRepair,
        isPartyManuallyInserted,
        serviceType,
        partServiceHours,
        partOriginalServiceHours: mergedPart.originalServiceHours,
        quantityParts,
        partDiscount,
        partPrice,
        partOriginalPrice,
        partName,
        partSku,
        partType,
        partSupplier,
        partSourceType,
        setPartSupplier,
        setPartSourceType,
        setIsPartyManuallyInserted,
        setPartName,
        setPartSku,
        setPartType,
        setServiceType,
        setPartServiceHours,
        setQuantityParts,
        setPartDiscount,
        setPartPrice,
        setPartOriginalPrice,
      }}
    >
      {children}
    </PartContext.Provider>
  );
}
