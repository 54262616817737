import React from 'react';
import { Filter } from '../Filter';
import { InvoicesTable } from '../InvoiceTable/InvoicesTable';
import * as S from './Dashboard.styles';

export const InvoicesDashboard = () => {
  return (
    <S.Container>
      <S.Header>
        <S.Title>Cobranças</S.Title>
      </S.Header>
      <S.Body>
        <Filter />
        <InvoicesTable />
      </S.Body>
    </S.Container>
  );
};
