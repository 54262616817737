import styled from 'styled-components';
import { Icon as IconBase } from '@mui/material';
import { Tooltip as MuiTooltip, withStyles } from '@material-ui/core';

interface IStyledContainer {
  background?: string;
  borderColor?: string;
}

interface IStyledIcon {
  width?: string;
  iconcolor?: string;
}

export const Container = styled.div<IStyledContainer>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${({ background }) => background || '#fff'};
  width: 145px;
  height: 105px;
  border: 1px solid ${({ borderColor }) => borderColor || '#d3d3d3'};
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  :hover {
    box-shadow: 0px 0px 14px 0px rgba(201, 201, 201, 0.5);
  }
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #425166;
`;

export const Data = styled.div`
  font-size: 40px;
  font-weight: 600;
  font-family: 'Montserrat';
  color: ${({ color }) => color || '#d3d3d3'};
`;

export const Tooltip = withStyles((theme) => ({
  popper: {
    top: '12px !important',
  },
  tooltip: {
    backgroundColor: '#eee',
    boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'black',
    width: 180,
    borderRadius: '4px',
    fontSize: theme.typography.pxToRem(10),
    textAlign: 'center',
    letterSpacing: '1px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    border: '1px solid #dadde9',
    padding: '5px',
  },
}))(MuiTooltip);

export const Icon = styled(IconBase)<IStyledIcon>`
  color: ${({ iconcolor }) => iconcolor || '#d3d3d3'};
  margin-left: 116px;
  margin-top: -15px;
  margin-bottom: -5px;
`;
