import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Select } from 'components/Select/Select';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px;
    border-radius: 4px;
    min-width: 380px;
  }
`;

export const DialogTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0;
  color: #000;
  height: 24px;
`;

export const DialogContentText = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 330px;
`;

export const FormWrapper = styled.div`
  padding-top: 15px;
`;

export const SelectContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 4px;
`;

export const SelectWrapper = styled(Select)`
  min-height: 0px;
  margin-bottom: 15px;
`;

export const TextField = styled(TextFieldComponent)`
  height: 40px;
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin: 25px 0px 0px;
`;

export const ButtonWrapper = styled.div`
  height: 45px;
  margin-left: 10px;
`;

export const LoadingSelectContainer = styled.div`
  display: flex;
  justify-content: center;
`;
