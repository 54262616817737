import React, { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { useHistory } from 'react-router-dom';
import MuiTableRow from '@mui/material/TableRow';
import { useInvoicesContext } from 'contexts/invoices/useInvoicesContext';
import DateService from 'services/DateService/dateService';
import { priceFormatter } from 'utils/formatPrice';
import { useHideZendesk } from 'hooks/Zendesk/ZendeskHook';
import { useFilterInvoices } from 'hooks/Invoices/InvoicesHook';
import InvoicesService from 'services/ApiService/InvoicesService/invoicesService';
import { tableInvoiceHeader } from './InvoicesTable.types';
import * as S from './InvoicesTable.styles';
import { IInvoice, IInvoiceStatus, InvoiceStatusEnum } from 'commom/types/Invoices.types';
import AccountService from 'services/ApiService/AccountService';

const MAX_ITEMS_PER_PAGE = 10;

export const InvoicesTable = () => {
  useHideZendesk();

  const [pagination, setPagination] = useState<number>(1);
  const [counterTotalPages, setCounterTotalPage] = useState<number>(0);

  const { filter, setInvoice, setCompaniesIds } = useInvoicesContext();
  const history = useHistory();

  const { invoices, isLoading, totalPages, totalItems, isFetching, setPage } = useFilterInvoices(filter);

  const isEmptyInvoiceData = invoices?.length === 0;
  const loading = isFetching || isLoading;

  const handleChange = (__: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
    setPagination(newPage);
  };

  const getPageItems = (): number | undefined => {
    if (!invoices || invoices.length === 0) return 0;

    if (pagination === totalPages || pagination === 1) {
      return pagination === totalPages ? totalItems : MAX_ITEMS_PER_PAGE;
    }

    return MAX_ITEMS_PER_PAGE;
  };

  const formatedDate = (date: string) => {
    return DateService.formatDateAndHourToPTBRWithDot(date);
  };

  const formatedPeriod = (endDate: string) => {
    return `${DateService.getMonthNameAcronym(endDate)}/${DateService.getYear(endDate)}`;
  };

  const getCompaniesIdsByClientId = (clientId: string | undefined) => {
    return clientId
      ? AccountService.getClientInAccountGroupInLocalStorageByClientId(clientId)?.companiesIds
      : undefined;
  };

  const openInvoiceDetails = (invoice: IInvoice) => {
    setInvoice(invoice);
    setCompaniesIds(getCompaniesIdsByClientId(invoice.clientId));
    history.push(`/invoices/${invoice.id}`);
  };

  const renderStatusCell = (invoiceStatus: IInvoiceStatus[]) => {
    const { status, description } = InvoicesService.getCurrentStatus(invoiceStatus);

    return <S.StatusCell type={status}>{description}</S.StatusCell>;
  };

  const isCurrentStatusUnpaid = (status: IInvoiceStatus[]) => {
    return InvoicesService.getCurrentStatus(status).status === InvoiceStatusEnum.UNPAID;
  };

  const renderPaginationItems = () => {
    if (!loading) {
      if (!isEmptyInvoiceData) {
        return (
          <S.PaginationDetails data-testid="pagination-invoices-details">
            Mostrando&nbsp;
            <S.Bold>{getPageItems()}</S.Bold>
            &nbsp;de <S.Bold>{totalItems} resultados</S.Bold>
          </S.PaginationDetails>
        );
      } else {
        <S.PaginationDetails data-testid="pagination-invoices-details"></S.PaginationDetails>;
      }
    } else {
      return (
        <S.PaginationDetails data-testid="pagination-invoices-empty-result">
          <S.Bold>Carregando os resultados...</S.Bold>
        </S.PaginationDetails>
      );
    }
  };

  const renderShowDetailsAction = (invoice: IInvoice) => {
    return isCurrentStatusUnpaid(invoice.status) ? (
      <S.Tooltip title="Não há detalhes, pois não há cobranças registradas" placement="left" arrow>
        <S.IconShowActions data-testid="show-details-icon" isActive={false} />
      </S.Tooltip>
    ) : (
      <S.IconShowActions data-testid="show-details-icon" onClick={() => openInvoiceDetails(invoice)} isActive={true} />
    );
  };

  useEffect(() => {
    if (totalPages) {
      setCounterTotalPage(totalPages);
    }
  }, [totalPages]);

  return (
    <S.Container data-testid="invoices-table">
      <S.Header>
        <span>Cobranças</span>
      </S.Header>
      <S.Content>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <MuiTableRow>
                {tableInvoiceHeader.map((tableHeaderCell) => (
                  <S.TableHeaderCell align={tableHeaderCell.align} key={tableHeaderCell.name}>
                    {tableHeaderCell.name}
                  </S.TableHeaderCell>
                ))}
              </MuiTableRow>
            </TableHead>
            {!isEmptyInvoiceData && !loading && (
              <TableBody>
                {invoices?.map((invoice: IInvoice) => (
                  <S.TableBodyRow key={invoice.id}>
                    <S.TableBodyCell align="left">{invoice.client.name}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{formatedDate(invoice.createdAt)}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{formatedPeriod(invoice.endDate)}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{invoice.expensesCount}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{priceFormatter.format(invoice.price)}</S.TableBodyCell>
                    <S.TableBodyCell align="left">{renderStatusCell(invoice.status)}</S.TableBodyCell>
                    <S.TableBodyCell align="center">{renderShowDetailsAction(invoice)}</S.TableBodyCell>
                  </S.TableBodyRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {loading && <S.TableBodyMessage>Carregando...</S.TableBodyMessage>}
        {isEmptyInvoiceData && !loading && <S.TableBodyMessage>Nenhuma cobrança encontrada</S.TableBodyMessage>}
        <S.PaginationWrapper>
          {renderPaginationItems()}
          <S.PaginationController>
            <Stack spacing={2}>
              <S.PaginationInvoicesTable
                page={pagination}
                onChange={handleChange}
                count={counterTotalPages}
                siblingCount={1}
                boundaryCount={2}
                variant="outlined"
                shape="rounded"
                data-testid="pagination-invoices-table"
              />
            </Stack>
          </S.PaginationController>
        </S.PaginationWrapper>
      </S.Content>
    </S.Container>
  );
};
