import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { PartsService } from 'services/ApiService/PartService/partsService';
import { IPartGroup, IVehicleArea, IVehiclePartType } from 'services/ApiService/PartService/partsService.types';
import { useAppSelector } from 'hooks/hooks';
import {
  Container,
  Menu,
  MenuButton,
  MenuItem,
  SelectionParts,
  SelectionPartsBody,
  SelectionPartsHeader,
  Title,
} from './PartsAndServices.styles';
import AddPartsDrawer from './AddPartsDrawer/AddPartsDrawer';

import SelectionCarAreaAndPartGroup from './SelectionCarAreaAndPartGroup/SelectionCarAreaAndPartGroup';
import SelectionCarPartTypes from './SelectionCarPartTypes/SelectionCarPartTypes';
import ListPartsAndOutsourcedServices from './ListPartsAndOutsourcedServices/ListPartsAndOutsourcedServices';
import CreateOutsourcedServiceDrawer from './CreateOutsourcedServiceDrawer/CreateOutsourcedServiceDrawer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import AddManuallyPartDrawer from 'components/Drawer/AddManuallyPartDrawer/AddManuallyPartDrawer';
import { PartContextProvider } from 'contexts/budgetPart/BudgetPartContext';

const PartsAndServices = () => {
  const [vehicleAreaSelected, setVehicleAreaSelected] = useState<IVehicleArea | null>(null);
  const [partGroupSelected, setPartGroupSelected] = useState<IPartGroup | null>(null);
  const [partTypeSelected, setPartTypeSelected] = useState<IVehiclePartType | null>(null);
  const [isOpenAddPartsDrawer, setIsOpenAddPartsDrawer] = useState(false);
  const [isOpenExternalServicesDrawer, setIsOpenExternalServicesDrawer] = useState(false);
  const [showAddManuallyPartDrawer, setShowAddManuallyPartDrawer] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const { identifier, typeId } = useAppSelector((state) => {
    return {
      identifier: state.presentInspection.inspection.product?.vehicle?.identifier,
      typeId: state.presentInspection.inspection.product?.vehicle?.typeId,
    };
  });

  const vehicleType = useQuery(
    ['get-vehicle-type-by-id', { vehicleId: typeId }],
    () => PartsService.getVehicleTypeById(typeId as string),
    {
      onError: () => {
        toast.error('Erro ao buscar dados do veículo');
      },
      enabled: !!typeId,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const vehicleArea = useQuery(
    ['get-vehicle-area-by-id', { vehicleId: vehicleAreaSelected }],
    () => PartsService.getVehicleAreaById(vehicleAreaSelected?.id as string),
    {
      onError: () => {
        toast.error('Erro ao buscar grupo de peças');
      },
      enabled: vehicleAreaSelected !== null,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const vehiclePartTypes = useQuery(
    ['get-vehicle-part-types', { groupPartId: partGroupSelected?.id, vehicleIdentifier: identifier }],
    () =>
      PartsService.getVehiclePartTypesByGroupPartIdAndInspectionId(
        partGroupSelected?.id as string,
        identifier as string
      ),
    {
      onError: () => {
        toast.error('Erro ao buscar peças do veículo');
      },
      enabled: vehicleAreaSelected !== null && partGroupSelected !== null && !!identifier,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeVehicleArea = (area: IVehicleArea | null) => {
    setVehicleAreaSelected(area);
  };

  const handleChangePartGroup = (partGroup: IPartGroup | null) => {
    setPartGroupSelected(partGroup);
  };

  const handleChangePartType = (partType: IVehiclePartType | null) => {
    setPartTypeSelected(partType);
  };

  const handleChangeIsOpenAddPartsDrawer = (visible: boolean) => {
    setIsOpenAddPartsDrawer(visible);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleCloseAddManuallyPartDrawer = () => {
    setShowAddManuallyPartDrawer(false);
    handleCloseMenu();
  };

  const handleCloseExternalServicesDrawer = () => {
    setIsOpenExternalServicesDrawer(false);
    handleCloseMenu();
  };

  const MenuActions = () => {
    return (
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        open={isMenuOpen}
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => setShowAddManuallyPartDrawer(true)}>
          <PriceChangeOutlinedIcon />
          <span>Peça</span>
        </MenuItem>
        <MenuItem onClick={() => setIsOpenExternalServicesDrawer(true)}>
          <PriceChangeOutlinedIcon />
          <span>Serviço</span>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <>
      <Container>
        <SelectionParts>
          <SelectionPartsHeader>
            <Title>Seleção de Peças</Title>

            <MenuButton
              id="actions-button"
              testID="actions-button"
              variant="outlined"
              text="INCLUSÃO MANUAL"
              aria-controls={isMenuOpen ? 'actions-menu' : undefined}
              aria-expanded={isMenuOpen ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpenMenu}
              loadingColor="#fff"
              endIcon={isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            />

            <MenuActions />
          </SelectionPartsHeader>

          <SelectionPartsBody>
            {vehicleAreaSelected !== null && partGroupSelected !== null ? (
              <SelectionCarPartTypes
                vehicleAreaSelected={vehicleAreaSelected}
                partGroupSelected={partGroupSelected}
                vehiclePartTypes={vehiclePartTypes.data}
                isVehiclePartTypeLoading={vehiclePartTypes.isLoading}
                handleChangePartGroup={handleChangePartGroup}
                handleChangePartType={handleChangePartType}
                handleChangeIsOpenAddPartsDrawer={handleChangeIsOpenAddPartsDrawer}
              />
            ) : (
              <SelectionCarAreaAndPartGroup
                vehicleType={vehicleType.data}
                vehicleArea={vehicleArea.data}
                hasVehicleAreaSelected={vehicleAreaSelected !== null}
                isVehicleTypeLoading={vehicleType.isLoading}
                isVehicleAreaLoading={vehicleArea.isLoading}
                handleChangeVehicleArea={handleChangeVehicleArea}
                handleChangePartGroup={handleChangePartGroup}
              />
            )}
          </SelectionPartsBody>
        </SelectionParts>

        <ListPartsAndOutsourcedServices />
      </Container>

      {isOpenAddPartsDrawer && !!partTypeSelected && (
        <AddPartsDrawer
          isOpenAddPartsDrawer={isOpenAddPartsDrawer}
          partTypeSelected={partTypeSelected}
          handleChangeIsOpenAddPartsDrawer={handleChangeIsOpenAddPartsDrawer}
          handleChangePartType={handleChangePartType}
        />
      )}

      {isOpenExternalServicesDrawer && (
        <CreateOutsourcedServiceDrawer
          onClose={handleCloseExternalServicesDrawer}
          open={isOpenExternalServicesDrawer}
        />
      )}

      {showAddManuallyPartDrawer && (
        <PartContextProvider>
          <AddManuallyPartDrawer openDrawer={showAddManuallyPartDrawer} onClose={handleCloseAddManuallyPartDrawer} />
        </PartContextProvider>
      )}
    </>
  );
};

export default PartsAndServices;
