import React from 'react';
import DateService from 'services/DateService/dateService';
import { CheckIcon, CloseIcon, Description, IndicatorContent } from './StatusIndicator.styles';

interface IStatusIndicatorProps {
  isDraft: boolean;
  updatedAt: string;
}

export const StatusIndicator = ({ isDraft, updatedAt }: IStatusIndicatorProps) => {
  return (
    <IndicatorContent isDraft={isDraft}>
      {isDraft ? (
        <>
          <CloseIcon />
          <Description>Laudo não concluído</Description>
        </>
      ) : (
        <>
          <CheckIcon />
          <Description>Concluído em {DateService.formatDateToPTBR(updatedAt)}</Description>
        </>
      )}
    </IndicatorContent>
  );
};

export default StatusIndicator;
