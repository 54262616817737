import { useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import ErrorNotFound from 'errors/ErrorNotFound';
import InvoicesService from 'services/ApiService/InvoicesService/invoicesService';
import {
  IInvoiceExpenseFilter,
  IInvoiceFilter,
  IInvoicesResponse,
} from 'services/ApiService/InvoicesService/invoicesService.types';
import { useState } from 'react';
import { IInvoiceWithExpenses } from 'commom/types/Invoices.types';

const ITEMS_PER_PAGE = 10;

export const useMutateInvoices = () => {
  const queryClient = useQueryClient();

  const invalidateInvoices = async () => {
    await queryClient.invalidateQueries({ queryKey: ['get-invoices'] });
  };

  const invalidateInvoicesExpenses = async () => {
    await queryClient.invalidateQueries({ queryKey: ['get-invoices-expenses'] });
  };

  return {
    invalidateInvoices,
    invalidateInvoicesExpenses,
  };
};

export const useFilterInvoices = (filter: IInvoiceFilter) => {
  const [page, setPage] = useState<number>(0);

  const { data, error, isLoading, isFetching } = useQuery(
    ['get-invoices', { filter, page }],
    () => InvoicesService.getInvoices({ filter, page, size: ITEMS_PER_PAGE }),
    {
      onError: (err) => {
        if (!(err instanceof ErrorNotFound)) {
          toast.error('Ocorreu um erro ao carregar as cobranças.');
        }
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    isFetching,
    setPage,
    hasMorePages: hasMorePages(data),
    invoices: data?.data.invoices,
    currentPage: getCurrentPageInvoices(data),
    totalPages: getTotalPagesInvoices(data),
    totalItems: getTotalCountInvoices(data),
    error: error,
    notFound: error && error instanceof ErrorNotFound,
  };
};

export const useFilterInvoicesExpenses = (filter: IInvoiceExpenseFilter) => {
  const [page, setPage] = useState<number>(0);
  const { data, error, isLoading, isFetching } = useQuery(
    ['get-invoices-expenses', { filter, page }],
    () => InvoicesService.getInvoicesExpenses({ filter, page, size: ITEMS_PER_PAGE }),
    {
      onError: (err) => {
        if (!(err instanceof ErrorNotFound)) {
          toast.error('Ocorreu um erro ao carregar os serviços de cobranças.');
        }
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    isLoading,
    invoicesWithExpenses: data?.data,
    invoicesExpenses: data?.data.invoiceExpenses,
    hasMorePages: hasMorePagesInvoicesExpenses(data),
    currentPage: getCurrentPageInvoicesExpenses(data),
    totalPages: getTotalPagesInvoicesExpenses(data),
    totalItems: getTotalCountInvoicesExpenses(data),
    error: error,
    notFound: error && error instanceof ErrorNotFound,
    setPage,
    isFetching,
  };
};

const getTotalPagesInvoices = (pages: AxiosResponse<IInvoicesResponse> | undefined): number | undefined => {
  return pages && Number(pages.headers['x-total-pages']);
};

const getTotalCountInvoices = (pages: AxiosResponse<IInvoicesResponse> | undefined): number | undefined => {
  return pages && Number(pages.headers['x-total-count']);
};

const getCurrentPageInvoices = (pages: AxiosResponse<IInvoicesResponse> | undefined): number | undefined => {
  return pages && Number(pages.headers['x-current-page']);
};

const getTotalPagesInvoicesExpenses = (pages: AxiosResponse<IInvoiceWithExpenses> | undefined): number | undefined => {
  return pages && Number(pages.headers['x-total-pages']);
};

const getTotalCountInvoicesExpenses = (pages: AxiosResponse<IInvoiceWithExpenses> | undefined) => {
  return pages && Number(pages.headers['x-total-count']);
};

const getCurrentPageInvoicesExpenses = (pages: AxiosResponse<IInvoiceWithExpenses> | undefined) => {
  return pages && Number(pages.headers['x-current-page']);
};

const hasMorePagesInvoicesExpenses = (pages: AxiosResponse<IInvoiceWithExpenses> | undefined): boolean => {
  return (pages && Boolean(pages.headers['x-has-next-page'])) || false;
};

const hasMorePages = (pages: AxiosResponse<IInvoicesResponse> | undefined): boolean => {
  return (pages && Boolean(pages.headers['x-has-next-page'])) || false;
};
