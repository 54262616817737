import {
  IBudget,
  IBudgetLabor,
  IBudgetLaborCost,
  IBudgetPartServiceHours,
  IBudgetPartServiceType,
} from 'commom/types/Budget.types';
import { IOptions } from 'components/Select/Select.types';
import { IConclusionReason, IConclusionType } from 'pages/SchedulerList/components/Inspection';
import { PaintingTypeEnum } from '../VehicleService/vehicleService.types';
import { IWorkshop } from '../WorkshopService/WorkshopService.types';

export interface IBudgetService {
  createBudget: (createBudgetRequest: ICreateBudgetRequest) => Promise<void>;
  getBudgetsByInspectionId: (inspectionId: string) => Promise<IBudget[]>;
  addPart: (part: IAddPartRequest, budgetId: string) => Promise<IBudget>;
  editPart: (part: IEditPartRequest, budgetId: string) => Promise<IBudget>;
  getCompanyConclusionTypes: (companyId: string) => Promise<ICompanyConclusionTypes>;
  removePartBudget: (budgetId: string, sku: string) => Promise<IBudget>;
  addBudgetConclusion: (addConclusionRequest: IAddConclusion) => Promise<IBudget>;
  archiveBudgetConclusion: (budgetId: string, conclusionId: string) => Promise<IBudget>;
  finishBudget: (budgetId: string) => Promise<IBudget>;
  formattedLaborCostsToPartTempValues: (laborCosts: ICost[], paintingCode: PaintingTypeEnum) => IPartTempValues;
  createBudgetLabor: (companyId: string, services: IServiceCost[]) => Promise<ICreateBudgetLaborResponse>;
  getBudgetLabor: (companyId: string) => Promise<ICreateBudgetLaborResponse>;
  updateBudgetLabor: (budgetLaborId: string, services: IServiceCost[]) => Promise<ICreateBudgetLaborResponse>;
  setWorkshop: (budgetId: string, workshopId: string) => Promise<IBudget>;
  getWorkshopServices: () => Promise<IGetWorkshopServicesResponse>;
  getTypesPartsList: () => IOptions[];
  createExternalService: (externalServiceRequest: ICreateExternalServiceRequest) => Promise<IBudget>;
  updateExternalService: (externalServiceRequest: IUpdateExternalServiceRequest) => Promise<IBudget>;
  deleteExternalService: (budgetId: string, serviceId: string) => Promise<IBudget>;
  calculateDamagePercentage: (fullPrice: number, fipeValue: number) => string;
  calculateBudgetPartNetPrice: (originalPrice: number, discount?: number, increase?: number) => Promise<number>;
  getbudgetLatestVersions: (budgetId: string) => Promise<IInspectionBudgetVersionsOutput>;
  createBudgetDeal: (
    budgetId: string,
    cost: IBudgetLaborCost[],
    discount: number,
    increase: number
  ) => Promise<IBudget>;
}

export interface IInspectionBudgetVersions {
  inspectionBudgetVersionsOutput: IInspectionBudgetVersionsOutput;
}

export interface IInspectionBudgetVersionsOutput {
  latestVersion: IBudget;
  previousVersion: IBudget;
}

export interface IGroupPart {
  groupPartId: string;
  groupPartDescription: string;
}

export interface IPartPrice {
  prices: IPrice[];
  partTemp: IPartTemp;
}

export interface IPrice {
  id: string;
  description: string;
  price: number;
  originalPrice: number;
  labor: string;
  amount: string;
  partNumber: string;
  availableServies: {
    hasUsed: boolean;
    hasOriginal: boolean;
  };
  budgetPartType: TypesListEnum;
  partTemp: IPartTemp;
}

export interface IPartTemp {
  serviceType?: PartTempServiceTypeEnum;
  partTempOriginal: {
    removeAndInstall: number;
    repair: number;
    painting: number;
  };
  partTempUser: {
    removeAndInstall: number;
    repair: number;
    painting: number;
  };
  sourceType: string;
}

export interface IPartTempValues {
  removeAndInstall: number;
  repair: number;
  painting: number;
  [key: string]: number;
}

export enum PartTempServiceTypeEnum {
  REPLACEMENT = 'REPLACEMENT',
  REPAIR = 'REPAIR',
  PAINTING = 'PAINTING',
}

export enum PartTempSourceEnum {
  USER = 'USER',
  SUIV = 'SUIV',
}

export enum PartChangesTypeEnum {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export type StatusBudgetCode = 'CREATED' | 'STARTED' | 'FINISHED';

export interface IStatusBudget {
  statusDate: string;
  budgetStatus: IBudgetStatus;
}

export interface IGroupPartBudget {
  originIdentifier: string;
  description: string;
}

export interface IPartBudget {
  partGroup: IGroupPartBudget;
  unitPrice: number;
  originalPrice?: number;
  name: string;
  quantity: number;
  nicknameId: string;
  supplier?: SuppliersEnum;
  partTemp: IPartTemp;
  partId: string;
  partNumber?: string;
  partBudgetOrigin: IPartBudgetOrigin;
  budgetPartType: IBudgetPartType;
  discountPercent?: number;
  discountAmount?: number;
  fullPrice?: number;
  netPrice?: number;
  changesType?: PartChangesTypeEnum;
  [key: string]: unknown;
}

export interface IClientInfo {
  name: string;
  phone: string;
}

export interface IInputCost {
  laborCost: ILaborCost[];
  partsDiscount: number;
  servicesDiscount: number;
  deductible: number;
}

export interface IItems {
  parts: IPart[];
  outsourcedServices: IOutsourcedService[];
}

export interface ILaborCost {
  price: number;
  type: LaborCostTypeEnum;
}

export interface ISummaryCost {
  partsExpenseRecord: IPartsExpenseRecord;
  servicesExpenseRecord: IServicesExpenseRecord;
  fullPrice: number;
  netPrice: number;
  damagePercentage: number;
}

export interface IPartsExpenseRecord {
  fullPrice: number;
  discountAmount: number;
  netPrice: number;
}

export interface IServicesExpenseRecord {
  servicesExpenses: IServiceExpense[];
  totalHours: number;
  laborPrice: number;
  outsourcedServicesPrice: number;
  netPrice: number;
}

export interface IStatusItem {
  createdAt: string;
  status: IStatus;
}

export interface IStatus {
  id: string;
  createdAt: string;
  updatedAt: string;
  code: StatusBudgetCode;
  description: string;
}

export interface IConclusion {
  createdAt: string;
  updatedAt: string;
  type: string;
  reason: string;
  comment: string;
  user: IUser;
  visibility: VisibilityEnum;
}

export interface IUser {
  id: string;
  name: string;
}

export interface IOutsourcedService {
  name: string;
  price: number;
}

export interface IPart {
  createdAt: string;
  updatedAt: string;
  partNumber: string;
  name: string;
  quantity: number;
  originalPrice: number;
  discountPercent: number;
  discountAmount: number;
  user: string;
  partDiscount: number;
  partBudgetOrigin: PartTempSourceEnum;
  partType: TypesListEnum;
  partTemp: {
    serviceType: PartTempServiceTypeEnum;
    partTempOriginal: {
      removeAndInstall: number;
      repair: number;
      painting: number;
    };
    partTempUser: {
      removeAndInstall: number;
      repair: number;
      painting: number;
    };
    sourceType: string;
  };
  changesType?: PartChangesTypeEnum;
  [key: string]: unknown;
}

export enum VisibilityEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum LaborCostTypeEnum {
  PAINTING = 'PAINTING',
  TRICOAT_PAINTING = 'TRICOAT_PAINTING',
  LABOR = 'LABOR',
  METALLIC_PAINTING = 'METALLIC_PAINTING',
  PEARLIZED_PAINTING = 'PEARLIZED_PAINTING',
  REPAIR = 'REPAIR',
}

export interface IBudgetDetails {
  id: string;
  createdAt: string;
  updatedAt: string;
  vehicleToken: string;
  paintingType?: PaintingTypeEnum;
}

export interface ICompanyConclusionTypes {
  id: string;
  conclusionTypes: Array<IConclusionType & { conclusionReasons: IConclusionReason[] }>;
}

export interface IBudgetResponse {
  budget: IBudget;
}

export interface ICreateBudgetServiceCost {
  workshopServicesId: string;
  price?: number;
  percent?: number;
}

export interface ICreateBudgetRequest {
  inspectionId: string;
  isPrivate: boolean;
  workshop: IWorkshop;
  labor: IBudgetLabor;
  deductible: number;
}

export interface IListBudget {
  inspectionBudgets: IBudget[];
}

export interface IBudgetStatus {
  id: string;
  code: StatusBudgetCode;
  description: string;
}

export interface IAddConclusion {
  budgetId: string;
  comment: string;
  type: string;
  reason: string;
  userId: string;
}

export interface IServiceCost {
  workshopServicesId: string;
  price?: number;
  percentage?: number;
}

export interface IWorkshopService {
  id: string;
  code: string;
  name: string;
}

export interface ICost {
  id: string;
  code: string;
  name: string;
  price: number | null;
  percent: number | null;
}

export interface ICompanyWorkshopServices {
  id: string;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  costs: ICost[];
}

export interface ICreateBudgetLaborResponse {
  companyWorkshopServices: ICompanyWorkshopServices;
}

export interface IGetWorkshopServicesResponse {
  workshopServices: IWorkshopService[];
}

export enum BudgetLaborServicesEnum {
  REPLACEMENT = 'Troca',
  REPAIR = 'Reparo',
}

export enum OverlapLaborEnum {
  REMOVAL_AND_INSTALLATION = 'Remoção & Instalação',
  PAINTING = 'Pintura',
  REPAIR = 'Reparo',
}

export interface IBuildFormatedPart {
  partsGroupSelected: IOptions;
  unitPrice: string;
  quantity: number;
  partNumber: string;
  serviceType?: PartTempServiceTypeEnum;
  partTemp: {
    partTempOriginal: IPartTempValues;
    partTempUpdated: IPartTempValues;
  };
  nicknameId: string;
  supplier?: SuppliersEnum;
  partId?: string;
  name: string;
  partBudgetOrigin: PartTempSourceEnum;
  budgetPartType: TypesListEnum;
  originalPrice?: number;
  discountPercent: number;
}

export interface IFormatedPart {
  partFormatted: {
    id: string;
    title: string;
    subtitle: string;
    secondarySubtitle: string;
  };
  quantity?: number;
  partTemp: IPartTemp;
  partTempValues: IPartTempValues;
}

export interface ILaborService {
  laborService: OverlapLaborEnum;
  editPartLabor: boolean;
  partPrice: IFormatedPart;
}

export enum TypesListEnum {
  GENUINE = 'GENUINE',
  ORIGINAL = 'ORIGINAL',
  OTHER_SOURCES = 'OTHER_SOURCES',
  USED = 'USED',
}

export enum SuppliersEnum {
  WORKSHOP = 'WORKSHOP',
  OTHERS = 'OTHERS',
}

export interface IPartBudgetOrigin {
  id: string;
  origin: PartTempSourceEnum;
}

export interface IBudgetPartType {
  code: TypesListEnum;
  description: string;
  id: string;
}

export interface IAddPartRequest {
  user: string;
  name: string;
  sku: string;
  quantity: number;
  originalPrice: number;
  discount: number;
  inputNetPrice?: number;
  isManualEntry: boolean;
  partSupplier: {
    code: string;
    description: string;
  };
  sourceType: {
    code: string;
    description: string;
  };
  serviceType: IBudgetPartServiceType;
  originalServiceHours: IBudgetPartServiceHours;
  serviceHours: IBudgetPartServiceHours;
}

export interface IEditPartRequest {
  _id: string;
  user: string;
  name: string;
  sku: string;
  quantity: number;
  originalPrice: number;
  discount: number;
  inputNetPrice?: number;
  isManualEntry: boolean;
  partSupplier: {
    code: string;
    description: string;
  };
  sourceType: {
    code: string;
    description: string;
  };
  serviceType: IBudgetPartServiceType;
  originalServiceHours: IBudgetPartServiceHours;
  serviceHours: IBudgetPartServiceHours;
}

export interface IPartIdentifier {
  partId: string;
  partBudgetOrigin: PartTempSourceEnum;
}

export interface IServicesOutsource {
  name: string;
  price: number;
}

export interface IBudgetPartsExpenseRecord {
  fullPrice: number;
  discountAmount: number;
  netPrice: number;
}

export interface IBudgetServicesExpenseRecord {
  servicesExpenses: IServiceExpense[];
  totalHours: number;
  netPrice: number;
  outsourcedServicesPrice: number;
  laborPrice: number;
}

export interface IServiceExpense {
  type: BudgetServiceTypeEnum;
  price: number;
  temp: number;
  percent: number;
}

export enum BudgetServiceTypeEnum {
  LABOR = 'LABOR',
  REPAIR = 'REPAIR',
  PAINTING = 'PAINTING',
  DISCOUNT = 'DISCOUNT',
  MARKUP = 'MARKUP',
  OUTSOURCED_SERVICES = 'OUTSOURCED_SERVICES',
}

export interface ICreateExternalServiceRequest {
  budgetId: string;
  name: string;
  price: number;
}
export interface IUpdateExternalServiceRequest {
  budgetId: string;
  serviceId: string;
  price: number;
}

export interface ICalculateBudgetPartNetPriceResponse {
  individualNetPrice: number;
}
