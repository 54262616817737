import React, { createContext, useCallback } from 'react';
import ConsumerService from 'services/ApiService/ConsumerService';

import { IConsumer, IConsumerContext } from './types';

export const ConsumerContext = createContext<IConsumerContext>({} as IConsumerContext);

export const ConsumerContainer: React.FC = ({ children }) => {
  const initiateCompanyConsumers = useCallback(async (): Promise<void> => {
    try {
      const companyConsumerResponse = await ConsumerService.getCompanyConsumersByAccount();
      ConsumerService.deleteCompanyConsumersInLocalStorage();
      ConsumerService.setCompanyConsumersInLocalStorage(companyConsumerResponse);
    } catch (err) {
      throw new Error('Could not initiate company consumers');
    }
  }, []);

  const getConsumersForCompany = (companyId: string): IConsumer[] => {
    const companyConsumersList = ConsumerService.getCompanyConsumersInLocalStorage();
    const consumersFiltered = companyConsumersList.companyConsumers
      ?.filter((companyConsumer) => companyConsumer.companyId === companyId)
      .map((companyConsumer) => companyConsumer.consumer);

    return consumersFiltered;
  };

  const isConsumerActive = (featureName: string, companyId: string): boolean => {
    const consumers = getConsumersForCompany(companyId);
    const feature = consumers?.find((consumer) => consumer.value === featureName);
    return !!feature;
  };

  return (
    <ConsumerContext.Provider value={{ initiateCompanyConsumers, isConsumerActive, getConsumersForCompany }}>
      {children}
    </ConsumerContext.Provider>
  );
};
