import styled from 'styled-components';
import { Button as ButtonComponent } from 'components';

interface IContainer {
  permissionDenied: boolean;
}

export const Container = styled.div<IContainer>`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: ${(props) => (props.permissionDenied ? `533px 1fr` : `500px 1fr`)};
  gap: 15px;
  grid-auto-flow: row;
  grid-template-areas:
    'live photos'
    'comments photos';

  > div {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #dbd9d9;
    border-radius: 6px;
    padding: 10px 15px;
  }
`;

export const VideoCard = styled.div`
  grid-area: live;
  justify-content: flex-start;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.span`
  margin-bottom: 10px;
  color: #6a758b;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const ButtonText = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;

  height: 40px;
  border-radius: 4px;
  background: white;
  border: none;
  cursor: pointer;

  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Description = styled.span`
  color: #6a758b;
  font-size: 16px;
  font-weight: 500;
`;

export const Video = styled.video`
  width: 100%;
  height: 400px;

  @media (max-height: 960px) {
    height: 360px;
  }
`;

export const VideoSubtitle = styled.span`
  color: #6a758b;
  font-size: 16px;
  font-weight: 500;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5px;
`;

export const TransmissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SubscriberContainer = styled.div`
  .OTSubscriberContainer {
    border-radius: 10px;
  }
`;

export const PublisherContainer = styled.div`
  display: none;
`;

export const Photo = styled.img`
  height: 120px;
  width: 180px;
  border-radius: 4px;
`;

export const Illustration = styled.img`
  height: 240px;
  width: 360px;
  border-radius: 4px;

  @media (max-width: 1600px) {
    height: 120px;
    width: 180px;
  }
`;

export const Button = styled(ButtonComponent)`
  width: fit-content;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;
