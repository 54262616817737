import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PersonIcon from '@material-ui/icons/Person';
import { InputAdornment } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Label } from 'components';
import { useHistory } from 'react-router-dom';
import { formValidations } from 'utils/form/validations';
import EmailConfirmationService from 'services/ApiService/EmailConfirmationService/emailConfirmationService';
import {
  Container,
  Illustration,
  Form,
  Title,
  Description,
  LinkNavigation,
  TextField,
  Button,
} from '../PasswordRecovery/PasswordRecovery.styles';
import { IEmailConfirmationForm } from './EmailConfirmation.types';

const InputProps = {
  email: {
    startAdornment: (
      <InputAdornment position="start">
        <PersonIcon />
      </InputAdornment>
    ),
  },
};

export const EmailConfirmation = () => {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({ mode: 'onBlur' });
  const [loading, setLoading] = useState(false);

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const onSubmit = async ({ email }: IEmailConfirmationForm): Promise<void> => {
    try {
      setLoading(true);
      await EmailConfirmationService.emailConfirmation(email);
      setLoading(false);
      toast.success('Email enviado com sucesso.');
      history.push({
        pathname: '/check-confirmation-email',
        search: `?email=${email}`,
      });
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao enviar email.');
    }
  };

  return (
    <Container data-testid="email-confirmation-container">
      <Illustration />
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <Title>Confirmação de email</Title>

        <Description>
          Se seu usuário cadastrado for um e-mail válido, insira no campo abaixo para continuarmos o processo.
        </Description>
        <Description>Caso contrário, insira um e-mail que será o seu novo usuário após a confirmação.</Description>

        <Label htmlFor="email" testID="email">
          Email
        </Label>
        <TextField
          ariaLabelledby="email"
          error={hasError('email')}
          id="email"
          InputProps={InputProps.email}
          inputRef={register(formValidations.email)}
          helperText={errors?.email?.message}
          name="email"
          placeholder="Insira seu email de login"
          state={handleInputState('email')}
          type="text"
          variant="outlined"
        />

        <Button
          fullWidth={true}
          loading={loading}
          loadingColor="#ffffff"
          loadingSize={26}
          size="large"
          text="CONFIRMAR EMAIL"
          type="submit"
        />

        <LinkNavigation data-testid="back-login" to="/login">
          Voltar ao login
        </LinkNavigation>
      </Form>
    </Container>
  );
};
