export const hasException = (exception: string) => {
  return exception.includes('AccountNotResponsibleException') || exception.includes('FailedToDeleteFileException');
};

export const getErrorMessage = (exception: string) => {
  if (exception.includes('AccountNotResponsibleException')) {
    return 'Apenas o responsável pelo atendimento pode deletar o arquivo!';
  }
  if (exception.includes('FailedToDeleteFileException')) {
    return 'Não é possível deletar o arquivo de um atendimento já finalizado.';
  }
  return 'Erro ao excluir documento!';
};
