export interface IBudgetSettingsDialogProps {
  handleClose: () => void;
  dialogVisibility: boolean;
  testID?: string;
}

export interface IBudgetSettingsDialogForm {
  platformId: string;
  login: string;
  password: string;
}

export enum CostsEnum {
  PAINTING = 'PAINTING',
  REPAIR = 'REPAIR',
  TRICOAT_PAINTING = 'TRICOAT_PAINTING',
  METALLIC_PAINTING = 'METALLIC_PAINTING',
  PEARLIZED_PAINTING = 'PEARLIZED_PAINTING',
  LABOR = 'LABOR',
  DISCOUNT = 'DISCOUNT',
  MARKUP = 'MARKUP',
}

export const costMapping = {
  [CostsEnum.REPAIR]: 'repair',
  [CostsEnum.PAINTING]: 'painting',
  [CostsEnum.TRICOAT_PAINTING]: 'tricoatPainting',
  [CostsEnum.METALLIC_PAINTING]: 'metallicPainting',
  [CostsEnum.PEARLIZED_PAINTING]: 'pearlizedPainting',
  [CostsEnum.LABOR]: 'labor',
  [CostsEnum.DISCOUNT]: 'discount',
  [CostsEnum.MARKUP]: 'markup',
};

export const formValueInitialState: IFormState = {
  repair: { id: '', value: '', code: CostsEnum.REPAIR },
  painting: { id: '', value: '', code: CostsEnum.PAINTING },
  tricoatPainting: { id: '', value: '', code: CostsEnum.TRICOAT_PAINTING },
  metallicPainting: { id: '', value: '', code: CostsEnum.METALLIC_PAINTING },
  pearlizedPainting: { id: '', value: '', code: CostsEnum.PEARLIZED_PAINTING },
  labor: { id: '', value: '', code: CostsEnum.LABOR },
  discount: { id: '', value: '', code: CostsEnum.DISCOUNT },
  markup: { id: '', value: '', code: CostsEnum.MARKUP },
};

export interface IFormState {
  repair: ICostState;
  painting: ICostState;
  tricoatPainting: ICostState;
  metallicPainting: ICostState;
  pearlizedPainting: ICostState;
  labor: ICostState;
  discount: ICostState;
  markup: ICostState;
}

export interface ICostState {
  id: string;
  value: string;
  code: CostsEnum;
}
