import { AnyAction, createListenerMiddleware, ListenerEffectAPI, ThunkDispatch } from '@reduxjs/toolkit';
import { initBudget, setDamageReport, setRescuedReport } from '../presentBudget.store';
import { RootState } from 'stores/store';
import queryClient from 'lib/reactQuery';
import BudgetReportService from 'services/ApiService/BudgetReportService/BudgetReportService';
import { IReportItem } from 'commom/types/Budget.types';
import { IBudgetReportTypeEnum } from 'commom/types/BudgetReport.types';

const initBudgetListenerMiddleware = createListenerMiddleware();

const fetchRescuedReport = async (
  report: IReportItem,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
) => {
  const rescuedReport = await queryClient.fetchQuery({
    queryFn: () => BudgetReportService.getBudgetReportRescuedParts(report.reportId),
    queryKey: ['get-rescued-report-parts', { reportId: report.reportId }],
  });
  listenerApi.dispatch(setRescuedReport(rescuedReport));
};

const fetchDamageReport = async (
  report: IReportItem,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
) => {
  const damageReport = await queryClient.fetchQuery({
    queryFn: () => BudgetReportService.getBudgetReportDamageParts(report.reportId),
    queryKey: ['get-damage-report-parts', { reportId: report.reportId }],
  });
  listenerApi.dispatch(setDamageReport(damageReport));
};

const handleReport = (
  report: IReportItem,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
) => {
  if (report.reportType === IBudgetReportTypeEnum.RESCUED) {
    return fetchRescuedReport(report, listenerApi);
  } else if (report.reportType === IBudgetReportTypeEnum.DAMAGE) {
    return fetchDamageReport(report, listenerApi);
  }
  return Promise.resolve();
};

initBudgetListenerMiddleware.startListening({
  type: initBudget.type,
  effect: async (_, listenerApi) => {
    try {
      const { presentBudget } = listenerApi.getState() as RootState;
      const isTotalLoss = presentBudget.budget.isTotalLoss;
      const reports = presentBudget.budget.reports;

      if (isTotalLoss) {
        const promises = reports.map((report) => handleReport(report, listenerApi));
        await Promise.all(promises);
      }
    } catch (error) {
      console.error('Erro ao atualizar dados do laudo de salvados:', error);
    }
  },
});

export default initBudgetListenerMiddleware;
