import React from 'react';
import { ActionsContainer } from './RescuedDamageReportActions.styles';
import StatusIndicator from './StatusIndicator/StatusIndicator';
import { IBudgetReport } from 'commom/types/BudgetReport.types';
import FinishReportButton from './FinishReportButton/FinishReportButton';

interface IRescuedDamageReportActionsProps {
  report: IBudgetReport;
}

const RescuedDamageReportActions = ({ report }: IRescuedDamageReportActionsProps) => {
  return (
    <ActionsContainer>
      <StatusIndicator isDraft={report.isDraft} updatedAt={report.updatedAt} />
      <FinishReportButton report={report} />
    </ActionsContainer>
  );
};

export default RescuedDamageReportActions;
