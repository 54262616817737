import { useState, useEffect } from 'react';
import './useFade.css';

interface IUseFade {
  isVisible: boolean;
  setFadeShow: React.Dispatch<React.SetStateAction<boolean>>;
  fromProps: {
    style: { animation: string };
    onAnimationEnd: () => void;
  };
  toProps: {
    style: { animation: string };
    onAnimationEnd: () => void;
  };
}

const useFade = (initial: boolean): IUseFade => {
  const [show, setFadeShow] = useState<boolean>(initial);
  const [isVisible, setVisible] = useState<boolean>(show);

  useEffect(() => {
    if (show) setVisible(true);
  }, [show]);

  const fromProps = {
    style: { animation: `${show ? 'fadeIn' : 'fadeOut'} 0.3s` },
    onAnimationEnd: (): void => {
      !show && setVisible(false);
    },
  };

  const toProps = {
    style: { animation: `${show ? 'fadeOut' : 'fadeIn'} 0.3s` },
    onAnimationEnd: (): void => {
      show && setVisible(true);
    },
  };

  return { isVisible, setFadeShow, fromProps, toProps };
};

export default useFade;
