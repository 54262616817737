import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  padding: 20px;
`;

export const BackgroungSvg = styled.img`
  max-width: 296px;
  max-height: 242px;
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  color: #6a758b;
`;

export const Description = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: center;
  color: #64748b;
`;
