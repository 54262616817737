import React from 'react';
import GeneralEvaluationSkeleton from '../GeneralEvaluationSkeleton/GeneralEvaluationSkeleton';
import QuestionGroupSkeleton from '../QuestionGroupSkeleton/QuestionGroupSkeleton';
import { Container, QuestionGroupChunkedContainer } from './RescuedReportFormSkeleton.styles';

const RescuedReportFormSkeleton = () => {
  return (
    <Container data-testid="rescued-report-form-skeleton">
      <QuestionGroupChunkedContainer>
        <GeneralEvaluationSkeleton />
        <QuestionGroupSkeleton />
      </QuestionGroupChunkedContainer>
      <QuestionGroupChunkedContainer>
        <QuestionGroupSkeleton />
      </QuestionGroupChunkedContainer>
      <QuestionGroupChunkedContainer>
        <QuestionGroupSkeleton />
      </QuestionGroupChunkedContainer>
    </Container>
  );
};

export default RescuedReportFormSkeleton;
