import styled from 'styled-components';
import { Button as ButtonComponent } from 'components';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';
import { withTheme } from '@material-ui/core/styles';
import { Select } from 'components/Select/Select';
import { IImageWrapper } from 'pages/SchedulerForm';

export const Button = withTheme(styled(ButtonComponent)`
  && {
    margin-top: ${(props): number => props.theme.spacing(2)}px;
  }
`);

export const ImageWrapper = styled.div<IImageWrapper>`
  background-image: url(${(props): string => props.image});
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  height: 100vh;
  position: fixed;
  right: 0;
  width: 90%;
  z-index: -1;
  position: absolute;

  @media screen and (min-width: 951px) {
    display: flex !important;
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const TextField = withTheme(styled(TextFieldComponent)`
  && {
    margin-bottom: ${(props): number => props.theme.spacing(2.5)}px;
    margin-top: ${(props): number => props.theme.spacing(1)}px;
  }
`);

export const InputErrorText = styled.div`
  bottom: -16px;
  color: #b60000;
  font-size: 11px;
  padding-top: 4px;
  position: absolute;
  user-select: none;
`;

export const LeftContent = styled.div`
  border-right: solid 1px #e8e8e8;
  display: flex;
  align-items: flex-start;
  padding: 30px;
  background: white;
  overflow: scroll;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100vh;
  z-index: 0;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 950px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PreviousInspectionForm = styled.div`
  display: flex;
  height: 100vh;
  margin-left: 60px;

  position: relative;
  z-index: 0;
`;

export const Title = styled.h1`
  color: #6a758b;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0;
  margin-bottom: 40px;
`;

export const SelectContainer = styled.div`
  padding-bottom: 20px;
  padding-top: 8px;
`;

export const SelectWrapper = styled(Select)``;
