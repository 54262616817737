import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  min-width: 793px;
`;

export const PrintStyles = createGlobalStyle`
  @media print {
    body {
      visibility: hidden;
    }

    #printable{
      display: flex;
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;

      #template {
        padding: 0px;
        box-shadow: none;
      }
    }
  }

  #printable {
    display: flex;
  }

  body {
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  box-sizing: unset;
}
`;

export const ReportContainer = styled.div`
  max-width: 840px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 36px;

  background: white;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #00000017;
  padding: 25px 30px;
`;

export const BudgetInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const BudgetActions = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

export const Title = styled.div`
  color: #020202;
  font-family: 'Montserrat';

  font-size: 14px;
  font-weight: 700;
  line-height: 19px;

  letter-spacing: 0em;
  text-align: left;
`;

export const SubTitle = styled.div`
  color: #020202;
  font-family: 'Montserrat';

  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  letter-spacing: 0em;
  text-align: left;
`;

export const LoadingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  min-width: 793px;
`;
