import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import ArrowUpwardRounded from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRounded from '@mui/icons-material/ArrowDownwardRounded';
import Delete from 'assets/svg/delete.svg';
import DeleteColored from 'assets/svg/delete-colored.svg';
import DeleteDisabled from 'assets/svg/delete-disabled.svg';
import Edit from 'assets/svg/edit.svg';
import EditColored from 'assets/svg/edit-colored.svg';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 55px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  color: #000;
`;

export const SubHeaderTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  color: #000;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

export const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  min-height: 69px;
  padding: 5px 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
`;

export const ItemSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 35%;

  @media (max-width: 1715px) {
    width: 35%;
  }

  @media (max-width: 1310px) {
    width: 25%;
  }
`;

export const ItemLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #000000;
  }

  span {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;
    color: #00000080;
  }

  @media (max-width: 1440px) {
    strong {
      font-size: 12px;
    }
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 65%;
  justify-content: end;

  @media (max-width: 1715px) {
    gap: 10px;
    width: 65%;
  }

  @media (max-width: 1310px) {
    gap: 10px;
    width: 75%;
  }
`;

export const InformationServiceContainer = styled.div`
  display: flex;
  gap: 30px;
  width: 65%;
  justify-content: end;
  align-items: end;

  @media (max-width: 1715px) {
    gap: 30px;
    width: 65%;
  }

  @media (max-width: 1310px) {
    gap: 10px;
    width: 70%;
  }
`;

export const InformationItem = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  min-width: 80px;
  max-width: 100px;

  &.widthSmaller {
    min-width: 55px;
    max-width: 70px;
  }

  &.widthQuantity {
    min-width: 25px;
    max-width: 45px;
  }

  &.widthService {
    min-width: 65px;
    max-width: 80px;
  }

  @media (max-width: 1366px) {
    min-width: 70px;

    &.widthSmaller {
      min-width: 40px;
    }

    &.widthService {
      min-width: 50px;
    }
  }

  @media (min-width: 1750px) {
    :first-child {
      min-width: 145px;
    }
  }
`;

export const InformationServiceItem = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  min-width: 80px;
  max-width: 80px;

  @media (min-width: 1920px) {
    min-width: 80px;
    max-width: 90px;
  }

  @media (min-width: 1850px) {
    min-width: 80px;
    max-width: 100px;
  }
`;

export const InformationTitle = styled.span`
  color: #6a758b;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

export const InformationSubtitle = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: #000;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;

  @media (max-width: 1366px) {
    font-size: 11px;
  }
`;

export const ChangeInfoWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

export const OldInformation = styled.span`
  color: #000;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: line-through;

  @media (max-width: 1366px) {
    font-size: 8px;
  }
`;

export const NewInformation = styled.span`
  color: #0e86ca;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

export const ItemActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;

export const IconButtonCustom = styled(IconButton)`
  padding: 0 !important;

  :hover {
    background-color: transparent !important;
  }

  &.Mui-disabled.delete-icon span div {
    background-image: url(${DeleteDisabled});
  }

  &.Mui-disabled.edit-icon span div {
    background-image: url(${Edit});
  }
`;

export const ArrowUp = styled(ArrowUpwardRounded)`
  font-size: 16px !important;
  margin-left: -16px;
  color: #b60000;
`;

export const ArrowDown = styled(ArrowDownwardRounded)`
  font-size: 16px !important;
  margin-left: -16px;
  color: #099857;
`;

export const EditIcon = styled.div`
  width: 29px;
  height: 28px;
  transition: 0.3s;
  background-image: url(${Edit});

  &:hover {
    background-image: url(${EditColored});
  }
`;

export const DeleteIcon = styled.div`
  width: 29px;
  height: 28px;
  transition: 0.3s;
  background-image: url(${Delete});

  &:hover {
    background-image: url(${DeleteColored});
  }
`;

export const LoadingContainer = styled.div`
  margin-top: 50px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 55px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
`;
