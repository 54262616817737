import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';

export const DrawerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 1px;
  overflow-y: auto;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const Divider = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
`;
