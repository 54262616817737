import styled from 'styled-components';
import AsyncAutocomplete from 'components/AsyncAutocomplete/AsyncAutocomplete';
import { Select as SelectComponent } from 'components/Select/Select';
import { Divider as DividerMui } from '@mui/material';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
`;

export const Separator = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;
`;

export const FormContent = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 5px;
  overflow-y: auto;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const Autocomplete = styled(AsyncAutocomplete)`
  .MuiInputBase-root {
    height: 40px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
`;

export const Select = styled(SelectComponent)``;
