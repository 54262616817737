import { AxiosResponse } from 'axios';

import ErrorNotFound from 'errors/ErrorNotFound';
import { createInspectionPayload, createPreviousInspectionPayload } from 'utils/inspectionHandler';

import ApiService from 'services/ApiService';

import { PaginationStateType } from 'pages/SchedulerList/SchedulerList.types';
import { ISchedulerForm } from 'pages/SchedulerForm/SchedulerForm.types';
import { IPreviousInspectionForm } from 'pages/PreviousInspection/PreviousInspection.types';
import { InspectionCategoryEnum } from 'utils/InspectionEnum';
import { IInspectionCategory } from 'pages/SchedulerList/components/Inspection';
import { IVehicleResponse } from 'services/ApiService/VehicleService/vehicleService.types';
import {
  IResponseInspections,
  IResponseInspection,
  IInspectionService,
  IPutCodeOrPlate,
  IPutConclusion,
} from './inspectionService.types';

const InspectionService: IInspectionService = {
  getInspectionsByFilter: async (url: string) => {
    const response = await ApiService.get<IResponseInspections>(url);

    if (response.data.inspections.length === 0) {
      throw new ErrorNotFound();
    }

    return response;
  },

  getInspectionsById: async (id) => {
    const { data } = await ApiService.get<IResponseInspection>(`/inspections/${id}`);

    return data;
  },

  getInspectionByToken: async (token: string) => {
    const { data } = await ApiService.get<IResponseInspection>(`/inspections/inquiry/client?token=${token}`);

    return data;
  },

  postInspection: async (form: ISchedulerForm) => {
    const { data } = await ApiService.post<IResponseInspection>(`/inspections/create`, createInspectionPayload(form));

    return data;
  },

  postPreviousInspection: async (form: IPreviousInspectionForm) => {
    const { data } = await ApiService.post<IResponseInspection>(
      `/inspections/create`,
      createPreviousInspectionPayload(form)
    );

    return data;
  },

  updatePaginationState: (oldPaginationState: PaginationStateType, response: AxiosResponse<IResponseInspections>) => {
    const totalPage: number | null = parseInt(response.headers['x-total-pages'], 10);
    const nextPage = oldPaginationState.nextPage + 1;
    const hasMorePage = !totalPage || nextPage < totalPage;

    return {
      totalPage,
      nextPage,
      hasMorePage,
    };
  },

  search: async (url: string) => {
    const {
      data: { inspections },
    } = await ApiService.get<IResponseInspections>(url);

    return inspections;
  },

  putPhone: async (id: string, phone: string) => {
    const { data } = await ApiService.put<IResponseInspection>(`/inspections/${id}/phone`, { phone });

    return data;
  },

  putCodeOrPlate: async (id: string, form: IPutCodeOrPlate) => {
    const { data } = await ApiService.put<IResponseInspection>(`/inspections/${id}/code-or-plate`, form);

    return data;
  },

  putResponsible: async (id: string, responsibleId: string) => {
    const { data } = await ApiService.put<IResponseInspection>(`/inspections/responsible/${id}`, {
      responsibleId,
    });

    return data;
  },

  checkIfExistInspection: async (companyId: string, code: string, phone: string, plate: string) => {
    const {
      data: { inspections },
    } = await ApiService.get<IResponseInspections>(
      `/inspections/already-created?companyId=${companyId}&code=${code}&phone=${phone}&plate=${plate}`
    );

    return inspections;
  },

  putProduct: async (id: string, brand: string, model: string, year: string) => {
    const { data } = await ApiService.put<IResponseInspection>(`/inspections/${id}/product`, { brand, model, year });

    return data;
  },

  updateVehicle: async (id: string, plate: string) => {
    await ApiService.put<IVehicleResponse>(`/inspections/vehicle/${id}`, {
      plate,
    });
  },

  updateOperation: async (id: string, companyId: string) => {
    const { data } = await ApiService.put<IResponseInspection>(`/inspections/${id}/change-operation`, { companyId });

    return data;
  },

  bulkUpdateInspections: async (path) => {
    const { data } = await ApiService.post(`/inspections/bulk-update`, {
      path,
    });
    return data;
  },

  saveDeductiblePrice: async (inspectionId: string, deductible: number | undefined) => {
    const { data } = await ApiService.put(`/inspections/deductible/${inspectionId}`, {
      deductible: deductible || 0,
    });

    return data;
  },

  putConclusion: async (inspectionId: string, conclusion: IPutConclusion) => {
    const { data } = await ApiService.put(`/inspections/conclusion/${inspectionId}`, conclusion);

    return data;
  },

  isInspectionCategoryLocally: (inspectionCategory: IInspectionCategory | undefined) => {
    return inspectionCategory?.code === InspectionCategoryEnum.LOCALLY;
  },

  isInspectionCategoryDigital: (inspectionCategory: IInspectionCategory | undefined) => {
    return inspectionCategory?.code === InspectionCategoryEnum.DIGITAL;
  },
};

export default InspectionService;
