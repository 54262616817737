import React from 'react';
import { ManagementDashboardContainer } from 'contexts/managementDashboard/ManagementDashboardProvider';
import { Title, Container } from './ManagementDashboard.styles';
import InspectionsFilter from './components/InspectionsFilter/InspectionsFilter';
import { InspectionsTable } from './components/InspectionsTable/InspectionsTable';

export const ManagementDashboard = () => {
  return (
    <ManagementDashboardContainer>
      <Container data-testid="management-dashboard">
        <Title>Gestão de Atendimentos</Title>
        <InspectionsFilter />
        <InspectionsTable />
      </Container>
    </ManagementDashboardContainer>
  );
};
