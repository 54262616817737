import React from 'react';
import EmptyConclusionSvg from 'assets/svg/conclusion-not-found.svg';
import EmptyArchivedConclusionSvg from 'assets/svg/archived-conclusion-not-found.svg';
import { IBudgetConclusion } from 'commom/types/Budget.types';
import {
  ArchivedDescription,
  ConclusionsContainer,
  ContainerEmptyConclusion,
  DescriptionEmptyConclusion,
  TitleEmptyConclusion,
} from './ConclusionTab.styles';
import ConclusionCard from '../ConclusionCard/ConclusionCard';

interface IConclusionTabProps {
  conclusions: IBudgetConclusion[];
  isArchivedConclusionTab?: boolean;
}

const ConclusionTab = ({ conclusions, isArchivedConclusionTab }: IConclusionTabProps) => {
  const isEmptyConclusions = conclusions.length === 0;

  if (isEmptyConclusions && !isArchivedConclusionTab) {
    return (
      <ContainerEmptyConclusion>
        <img src={EmptyConclusionSvg} alt="Não há conclusão" data-testid="empty-conclusion-svg" />
        <TitleEmptyConclusion>Nenhuma conclusão adicionada</TitleEmptyConclusion>
        <DescriptionEmptyConclusion>
          Ainda não foi adicionada uma conclusão a este relatório. Por favor, adicione-a ao lado.
        </DescriptionEmptyConclusion>
      </ContainerEmptyConclusion>
    );
  }

  if (isEmptyConclusions && isArchivedConclusionTab) {
    return (
      <ContainerEmptyConclusion>
        <img
          src={EmptyArchivedConclusionSvg}
          alt="Não há conclusão arquivada"
          data-testid="empty-archived-conclusion-svg"
        />
        <TitleEmptyConclusion>Sem conclusões arquivadas</TitleEmptyConclusion>
        <DescriptionEmptyConclusion>
          Você ainda não arquivou nenhuma conclusão. As conclusões arquivadas não aparecem no relatório.
        </DescriptionEmptyConclusion>
      </ContainerEmptyConclusion>
    );
  }

  return (
    <>
      {isArchivedConclusionTab && (
        <ArchivedDescription>As conclusões arquivadas não aparecem no relatório.</ArchivedDescription>
      )}
      <ConclusionsContainer>
        {conclusions.map((conclusion) => (
          <ConclusionCard key={conclusion.id} conclusion={conclusion} />
        ))}
      </ConclusionsContainer>
    </>
  );
};

export default ConclusionTab;
