import React, { useState } from 'react';
import CarIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import { DataBox } from 'components/DataBox';
import VehicleDrawer from 'components/Drawer/VehicleDrawer/VehicleDrawer';
import Tooltip from '@mui/material/Tooltip';
import { priceFormatter } from 'utils/formatPrice';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import * as S from '../InformationCard.styles';

interface IVehicleDataProps {
  inspection: IInspection;
}

const VehicleData = ({ inspection }: IVehicleDataProps) => {
  const { product } = inspection;

  const [showVehicleDrawer, setShowVehicleDrawer] = useState(false);

  const handleFipePrice = (price: number | undefined) => {
    if (!price) return '-';
    return priceFormatter.format(price);
  };

  const vehicleInfos = [
    { label: 'Placa', value: product?.plate },
    { label: 'Chassi', value: product?.vehicle?.chassis },
    { label: 'Tipo', value: product?.vehicle?.type },
    { label: 'Marca', value: product?.vehicle?.brand },
    { label: 'Modelo', value: product?.vehicle?.model },
    { label: 'Versão', value: product?.vehicle?.version },
    { label: 'Ano', value: product?.vehicle?.modelYear },
    { label: 'Pintura', value: product?.vehicle?.paintingDescription },
    { label: 'Código FIPE', value: product?.vehicle?.fipeInfo?.fipeCode },
    { label: 'Valor FIPE', value: handleFipePrice(product?.vehicle?.fipeInfo?.currentPrice) },
  ];

  return (
    <>
      <DataBox.Root>
        <DataBox.Header>
          <DataBox.Title>
            <CarIcon />
            Dados do veículo
          </DataBox.Title>
          <DataBox.Actions>
            <Tooltip title="Editar veículo" placement="top" arrow>
              <S.EditIconButton data-testid="edit-vehicle-button" onClick={() => setShowVehicleDrawer(true)} />
            </Tooltip>
          </DataBox.Actions>
        </DataBox.Header>
        <DataBox.Content>
          {vehicleInfos.map(({ label, value }) => (
            <DataBox.Item key={label}>
              <DataBox.Label>{label}</DataBox.Label>
              <DataBox.Value>{value || '-'}</DataBox.Value>
            </DataBox.Item>
          ))}
        </DataBox.Content>
      </DataBox.Root>

      {showVehicleDrawer && (
        <VehicleDrawer
          inspection={inspection}
          isEditMode={true}
          openDrawer={showVehicleDrawer}
          onCloseDrawer={() => setShowVehicleDrawer(false)}
        />
      )}
    </>
  );
};

export default VehicleData;
