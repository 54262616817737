import ApiService from 'services/ApiService';
import { IEmailConfirmationService, IResponseNewEmail } from './emailConfirmation.types';

const EmailConfirmationService: IEmailConfirmationService = {
  emailConfirmation: async (newUsername: string) => {
    await ApiService.post(`/accounts/validate-username/create-token`, { newUsername });
  },
  newEmail: async (token: string | null) => {
    const { data } = await ApiService.post<IResponseNewEmail>(`/accounts/validate-username`, { token });

    return data;
  },
};

export default EmailConfirmationService;
