// create Skeleton Loadin component
import React from 'react';
import { Skeleton } from '@mui/material';
import { SkeletonContainer, SkeletonRow } from './GeneralMetricsSkeletonLoading.styles';

export const GeneralMetricsSkeletonLoading = () => {
  return (
    <SkeletonContainer>
      <SkeletonRow>
        <div>
          <Skeleton variant="rounded" width={200} height={20} />
          <Skeleton variant="text" width={230} sx={{ fontSize: '1rem' }} />
        </div>
        <Skeleton variant="rounded" width={110} height={18} />
      </SkeletonRow>
      <SkeletonRow>
        <Skeleton variant="rounded" width={165} height={125} />
        <Skeleton variant="rounded" width={165} height={125} />
        <Skeleton variant="rounded" width={165} height={125} />
        <Skeleton variant="rounded" width={165} height={125} />
        <Skeleton variant="rounded" width={165} height={125} />
        <Skeleton variant="rounded" width={165} height={125} />
      </SkeletonRow>
    </SkeletonContainer>
  );
};
