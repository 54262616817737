import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Label as LabelComponent } from 'components';
import { Select as SelectComponent } from 'components/Select/Select';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    padding: 25px 35px;
    border-radius: 4px;
    width: 760px;
    max-width: 760px;
  }
`;

export const Title = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #6a758b;
`;

export const Label = styled(LabelComponent)`
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
`;

export const Select = styled(SelectComponent)`
  height: 40px;

  div {
    padding: 10.5px 14px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
`;

export const SelectItem = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 100%;
`;

export const TextField = styled(TextFieldComponent)`
  width: 100%;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
`;
