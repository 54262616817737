import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import { useTranslate } from 'hooks/Translate/TranslateHook';
import { useFilterNotifications, useMutateNotifications } from 'hooks/Notifications/NotificationsHooks';
import NotificationService from 'services/ApiService/NotificationService/notificationService';
import { useNotificationsContext } from 'contexts/notifications/useNotificationsContext';
import { NotificationStatusEnum } from 'commom/types/Notification.types';
import { NotificationsList } from './NotificationsList/NotificationsList';
import { AnchorTypesEnum } from '../DrawerProps.types';
import { INotificationsDrawerProps, NotificationsTabsEnum } from './NotificationsDrawer.types';
import * as S from './NotificationsDrawer.styles';

const NotificationsDrawer = ({ openDrawer, onCloseDrawer }: INotificationsDrawerProps) => {
  const { translate } = useTranslate();
  const { invalidateNotifications } = useMutateNotifications();
  const { updateHasNewNotifications } = useNotificationsContext();

  const [activeTab, setActiveTab] = useState<NotificationsTabsEnum>(NotificationsTabsEnum.ALL);
  const [loadingNotificationAsRead, setLoadingNotificationAsRead] = useState<boolean>(false);

  const getFilterStatus = () => {
    return activeTab === NotificationsTabsEnum.ALL
      ? [NotificationStatusEnum.READ, NotificationStatusEnum.UNREAD]
      : [NotificationStatusEnum.UNREAD];
  };

  const {
    isLoading,
    notifications,
    hasNotifications,
    unreadNotifications,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFilterNotifications(getFilterStatus());

  const showLoading = isLoading || loadingNotificationAsRead;

  const handleTabChange = (_: React.SyntheticEvent<Element, Event>, newValue: NotificationsTabsEnum) => {
    setActiveTab(newValue);
  };

  const markAllAsRead = async () => {
    try {
      if (hasNotifications && unreadNotifications > 0) {
        setLoadingNotificationAsRead(true);
        await NotificationService.readAllNotifications();
        invalidateNotifications();
        setLoadingNotificationAsRead(false);
      } else {
        toast.info('Não há notificações para marcar como lidas.');
      }
    } catch (error) {
      setLoadingNotificationAsRead(false);
      toast.error('Ocorreu um problema ao tentar marcar todas notificações como lidas.');
    }
  };

  const badgeCounter = hasNotifications && unreadNotifications > 0 && (
    <S.BadgeBase data-testid="notifications-badge" badgeContent={unreadNotifications} />
  );

  const renderUnreadLabel = () => (
    <S.UnreadLabelContainer>
      Não lidas
      {badgeCounter}
    </S.UnreadLabelContainer>
  );

  useEffect(() => {
    updateHasNewNotifications(false);
  }, [updateHasNewNotifications]);

  const renderTabsPanel = () => {
    return (
      <>
        <S.Panel value={activeTab} tabName={NotificationsTabsEnum.ALL}>
          <NotificationsList
            notifications={notifications}
            hasNotifications={hasNotifications}
            loading={isFetchingNextPage}
            hasNextPage={hasNextPage}
            listType={NotificationsTabsEnum.ALL}
            fetchNextPage={fetchNextPage}
          />
        </S.Panel>
        <S.Panel value={activeTab} tabName={NotificationsTabsEnum.UNREAD}>
          <NotificationsList
            notifications={notifications}
            hasNotifications={hasNotifications}
            hasNextPage={hasNextPage}
            listType={NotificationsTabsEnum.UNREAD}
            loading={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
          />
        </S.Panel>
      </>
    );
  };

  return (
    <S.Container
      testId="notifications-drawer"
      anchor={AnchorTypesEnum.LEFT}
      openDrawer={openDrawer}
      onClose={onCloseDrawer}
    >
      <S.Header>
        <S.Title>{translate('NOTIFICATIONS.TITLE')}</S.Title>
        <S.HeaderActions>
          <S.ReadAllButton onClick={markAllAsRead} data-testid="read-all-btn">
            Marcar todas como lidas
          </S.ReadAllButton>
          <S.CloseButton data-testid="close-btn" onClick={onCloseDrawer} />
        </S.HeaderActions>
      </S.Header>

      <S.Content>
        <S.Tabs value={activeTab} onChange={handleTabChange}>
          <S.Tab disableRipple label="Todas" value={NotificationsTabsEnum.ALL} data-testid="tab-all" />
          <S.Tab
            disableRipple
            label={renderUnreadLabel()}
            value={NotificationsTabsEnum.UNREAD}
            data-testid="tab-unread"
          />
        </S.Tabs>

        <S.Panels>
          {showLoading ? (
            <S.LoadingContainer>
              <Loading width="40%" />
            </S.LoadingContainer>
          ) : (
            renderTabsPanel()
          )}
        </S.Panels>
      </S.Content>
    </S.Container>
  );
};

export default NotificationsDrawer;
