import React, { useState } from 'react';
import { MoreVert } from '@material-ui/icons';
import { Menu } from '@mui/material';
import * as Styles from './DocumentActions.styles';
import { IPhotoActionsProps } from './DocumentActions.types';
import DownloadDocumentsMenuItem from './components/DownloadDocumentsMenuItem/DownloadDocumentsMenuItem';
import UploadDocumentsMenuItem from './components/UploadDocumentsMenuItem/UploadDocumentsMenuItem';
import GenerateResaleXMLMenuItem from './components/GenerateResaleXMLMenuItem/GenerateResaleXMLMenuItem';

const DocumentActions = ({ inspection }: IPhotoActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Styles.IconButton data-testid="show-more-button" onClick={handleClick} disableRipple>
        <MoreVert />
      </Styles.IconButton>
      <Menu data-testid="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <UploadDocumentsMenuItem inspection={inspection} />
        <DownloadDocumentsMenuItem inspection={inspection} onCloseMenu={handleClose} />
        <GenerateResaleXMLMenuItem inspection={inspection} />
      </Menu>
    </>
  );
};

export default DocumentActions;
