import React from 'react';
import { OTPublisher } from 'opentok-react';

import { Error } from 'opentok-react/types/opentok';
import { PublisherContainer } from '../Transmission.styles';

interface IPublisherProps {
  onSessionError: (e: Error) => void;
}

export const Publisher = ({ onSessionError }: IPublisherProps) => {
  return (
    <PublisherContainer>
      <OTPublisher
        properties={{
          publishAudio: true,
          publishVideo: false,
          videoSource: null,
          width: '0',
          height: '0',
          style: {
            archiveStatusDisplayMode: 'off',
            videoDisabledDisplayMode: 'off',
          },
        }}
        onError={onSessionError}
      />
    </PublisherContainer>
  );
};
