import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IInspection, StatusEnum } from 'pages/SchedulerList/components/Inspection';
import { IVehicle } from 'services/ApiService/VehicleService/vehicleService.types';
import { IPresentInspection } from './presentInspection.types';

export const LOCAL_STORAGE_KEY = '@PRESENT_INSPECTION';

const initialState: IPresentInspection = {
  inspection: {
    id: '',
    code: '',
    createdAt: '',
    updatedAt: '',
    customer: {
      name: '',
      phone: '',
      contactName: '',
    },
    currentStatus: {
      id: '',
      type: StatusEnum.DEFAULT,
      description: '',
      date: '',
    },
    status: [],
    photos: [],
  },
};

export const presentInspectionSlice = createSlice({
  name: 'presentInspection',
  initialState,
  reducers: {
    resetPresentInspectionState: () => initialState,
    setPresentInspection: (state, action: PayloadAction<IInspection>) => {
      const { payload } = action;
      state.inspection = payload;
    },
    setVehicle: (state, action: PayloadAction<IVehicle>) => {
      const { payload } = action;
      const { product } = state.inspection;
      if (product?.vehicle) {
        product.vehicle = payload;
      }
    },
  },
});

export const { resetPresentInspectionState, setPresentInspection, setVehicle } = presentInspectionSlice.actions;
export default presentInspectionSlice.reducer;
