import React from 'react';
import NumberFormat from 'react-number-format';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from '@material-ui/core';
import {
  ArrowUp,
  ArrowDown,
} from 'pages/SchedulerList/components/Budgets/components/BudgetSteps/PartsAndServices/ListPartsAndExternalServices/ListPartsAndExternalServices.styles';
import { IInputTime } from './InputTime.types';
import clockIcon from '../../assets/svg/clock.svg';
import {
  TextField,
  Content,
  ContentArrow,
  IconContent,
  Divider,
  ContentInput,
  LabelInput,
  Label,
  Container,
} from './InputTime.styles';

export default function InputTime({
  value,
  labelTop,
  labelBottom,
  onChangeInput,
  onBlurInput,
  handleDecrement,
  handleIncrement,
  testID = 'input-time-money',
  loading = false,
  disabled = false,
  arrowIndicator,
}: IInputTime) {
  const disableDownButton = value <= 0.01 || loading || disabled;

  const showArrowIndicator = () => {
    switch (arrowIndicator) {
      case 'UP':
        return <ArrowUp />;
      case 'DOWN':
        return <ArrowDown />;
      default:
        return <></>;
    }
  };

  return (
    <Container>
      <Label data-testid="input-time-label">{labelTop}</Label>
      <Content>
        <ContentInput>
          <NumberFormat
            disabled={disabled}
            customInput={TextField}
            value={value}
            allowEmptyFormatting
            allowLeadingZeros={false}
            thousandSeparator={false}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            decimalSeparator=","
            isNumericString
            onChange={onChangeInput}
            onBlur={onBlurInput}
            inputProps={{ 'data-testid': testID }}
            InputProps={{
              startAdornment: (
                <IconContent>
                  <img src={clockIcon} alt="Icone relógio" />
                </IconContent>
              ),
            }}
            {...{ variant: 'outlined' }}
          />
          {labelBottom && (
            <LabelInput data-testid="label-price">
              {arrowIndicator && showArrowIndicator()}
              {labelBottom}
            </LabelInput>
          )}
        </ContentInput>
        <ContentArrow>
          <IconButton
            data-testid="button-arrow-up"
            className="arrow-up"
            onClick={handleIncrement}
            disabled={loading || disabled}
          >
            <ArrowDropUpIcon data-testid="arrow-up" />
          </IconButton>
          <Divider />
          <IconButton
            data-testid="button-arrow-down"
            className="arrow-down"
            onClick={handleDecrement}
            disabled={disableDownButton}
          >
            <ArrowDropDownIcon data-testid="arrow-down" />
          </IconButton>
        </ContentArrow>
      </Content>
    </Container>
  );
}
