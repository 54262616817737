import styled from 'styled-components';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Link as LinkRouterDom } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { TableCell as MuiTableCell, TableRow as MuiTableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 24px 32px;
  margin-left: 60px;
  justify-content: start;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 5px;
  width: 100%;
  max-width: 1156px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
`;

export const Link = styled(LinkRouterDom)`
  display: flex;
  gap: 5px;
  align-items: center;

  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #000;
  text-decoration: none;

  :hover {
    cursor: pointer;
    color: #737791;
  }
`;

export const ArrowBackIcon = styled(ArrowBackIosRoundedIcon)`
  font-size: 16px !important;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-width: 940px;
  max-width: 1156px;
  height: 90%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 15px;
  border: 1px solid #f8f9fa;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
`;

export const ContainerCSV = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const ButtonCSV = styled(CSVLink)`
  text-decoration: none;
  display: flex;
`;

export const ButtonCSVContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
`;

export const TableCellSticky = withStyles({
  root: {
    borderWidth: 1,
    borderColor: 'rgba(237, 237, 237, .9)',
    borderStyle: 'solid',
    position: 'sticky',
    background: 'white',
    '@media (min-width:800px)': { left: '88%' },
    '@media (min-width:900px)': { left: '89%' },
    '@media (min-width:1000px)': { left: '90%' },
    '@media (min-width:1100px)': { left: '91.5%' },
    '@media (min-width:1200px)': { left: '92%' },
    '@media (min-width:1300px)': { left: '92.5%' },
    '@media (min-width:1400px)': { left: '93%' },
    '@media (min-width:1500px)': { left: '94%' },
    '@media (min-width:1600px)': { left: '94.5%' },
    '@media (min-width:1700px)': { left: '95%' },
  },
  body: {
    color: '#000000',
    fontFamily: 'Montserrat, sans-serif',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const TableCell = withStyles({
  root: {
    borderWidth: 1,
    borderColor: 'rgba(237, 237, 237, .9)',
    borderStyle: 'solid',
  },
  body: {
    color: '#000000',
    fontFamily: 'Montserrat, sans-serif',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const TableRow = withStyles({
  selected: {
    backgroundColor: '#ced6e5 !important',
    textShadow: '1px 0px 0px #000000',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f0f3fa !important',
    },
  },
})(MuiTableRow);

export const ActionLink = styled(LinkRouterDom)`
  display: flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;

  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #000;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    font-size: 14px !important;
  }
`;
