import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { IInspection } from 'pages/SchedulerList/components/Inspection/Inspection.types';
import PhotoService from 'services/PhotoService/photoService';
import { toast } from 'react-toastify';

interface IDownloadPhotosMenuItemProps {
  inspection: IInspection;
  onCloseMenu: () => void;
}

const DownloadPhotosMenuItem = ({ inspection, onCloseMenu }: IDownloadPhotosMenuItemProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadPhotos = async (): Promise<void> => {
    try {
      setIsLoading(true);
      if (!inspection) return;

      await PhotoService.downloadFileZip(inspection.photos, inspection.code);
    } catch (error) {
      toast.error('Aconteceu algo inesperado ao baixar as fotos, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPhotos = () => {
    downloadPhotos();
    onCloseMenu();
  };

  const showDownloadPhotosButton = inspection.photos.length > 0;

  const disabled = isLoading || !showDownloadPhotosButton;

  return (
    <MenuItem data-testid="download-photos" onClick={handleDownloadPhotos} disabled={disabled}>
      Baixar Fotos
    </MenuItem>
  );
};

export default DownloadPhotosMenuItem;
