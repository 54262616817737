import styled from 'styled-components';
import { FormControl as FormRadioButton } from '@mui/material';

interface IFormControlProps {
  disabled?: boolean;
}

export const FormControl = styled(FormRadioButton)<IFormControlProps>`
  .MuiFormControlLabel-root {
    margin-left: 0;

    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
  }

  .MuiButtonBase-root {
    padding: 3px 0px;
  }

  .MuiButtonBase-root.Mui-checked {
    color: #000;
  }
`;

export const LabelRadioForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

export const TitleRadioForm = styled.span`
  color: #6a758b;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  line-height: 13px;
  margin-left: 5px;
`;

export const SubtitleRadioForm = styled.span`
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: normal;
  margin-top: 2px;
`;
