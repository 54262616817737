import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import TabPanel from 'components/TabPanel/TabPanel';
import DrawerRoot from '../DrawerRoot';

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
`;

export const Container = styled(DrawerRoot)`
  .MuiPaper-root {
    margin-left: 60px;
    border-radius: 0px 10px 10px 0px;
    width: 450px;
    padding: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1e293b;
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  color: #64748b;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Tabs = styled(MuiTabs)`
  padding: 0px 20px;

  .MuiTab-root {
    min-width: 0;
    padding: 10px;
    text-transform: none;
    font-family: 'Montserrat' !important;
  }

  .MuiTabs-flexContainer {
    gap: 20px;
  }

  .MuiTabs-indicator {
    color: #1ca6af !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px 20px 0 0;
  }

  .MuiTab-wrapper {
    font-size: 16px;
    text-align: left;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #1e293b;
    font-weight: 600;
  }

  .MuiTab-textColorPrimary {
    color: #64748b;
    font-weight: 500;
  }
`;

export const Tab = styled(MuiTab)``;

export const Panels = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  overflow: scroll;
  padding-right: 4px;
`;

export const Panel = styled(TabPanel)``;

export const UnreadLabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 35px;
`;

export const BadgeBase = styled(Badge)<BadgeProps>`
  .MuiBadge-badge {
    background-color: #f2f4f7;
    color: #334155;
    font-size: 14px;
    margin-right: 10px;
  }
`;

export const ReadAllButton = styled.a`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #1ca6af;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const MarkAllAsReadButton = styled.a`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #1ca6af;

  &:hover {
    color: #106369;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const EmptyNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  padding: 20px;
`;

export const EmptyNotificationSvg = styled.img`
  max-width: 296px;
  max-height: 242px;
`;

export const Description = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: center;
  color: #64748b;
`;

export const TitleEmptyNotification = styled.span`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  text-align: center;
  color: #6a758b;
`;
