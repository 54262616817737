import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { TableRow as MuiTableRow, TableCell } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { InvoiceStatusEnum } from 'commom/types/Invoices.types';

interface IStyledStatusCell {
  type: string;
}

export const Container = styled.div`
  background: #fff;
  width: 100%;
  padding: 20px 0 0px 0px;
`;

export const Header = styled.div`
  padding: 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 15px 0px;
  padding: 0px 15px;
`;

interface ITableHeaderCell {
  isIndexTop: boolean;
}

export const TableHeaderCell = MuiStyled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isIndexTop',
})<ITableHeaderCell>(({ isIndexTop }) => ({
  padding: '6px 16px',
  color: '#6a758b',
  zIndex: isIndexTop ? 1 : 0,
  fontWeight: 400,
}));

export const TableBodyCell = MuiStyled(TableCell)(() => ({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  padding: '14px 16px',
  border: 0,
}));

export const TableBodyRow = MuiStyled(MuiTableRow)(() => ({
  backgroundColor: '#fff',
  '&:nth-of-type(odd)': {
    backgroundColor: '#F9FBFD',
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TableBodyMessage = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationInvoicesTable = styled(Pagination)`
  .MuiButtonBase-root {
    color: black;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: white;
    cursor: pointer;
    border: none;
    &.Mui-selected {
      background: #000000 !important;
      color: white !important;
      border-radius: 5px;
    }
  }
`;

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 24px 10px;
`;

export const PaginationController = styled.div`
  margin-left: auto;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const PaginationDetails = styled.div``;

const statusColor = (type: string) => {
  const colors: { [key: string]: string } = {
    [InvoiceStatusEnum.PAID]: '#08844B',
    [InvoiceStatusEnum.CREATED]: '#0E86CA',
    [InvoiceStatusEnum.CANCELED]: '#E71C40',
  };

  return colors[type] || '#8F8F8F';
};

const statusBackground = (type: string) => {
  const colors: { [key: string]: string } = {
    [InvoiceStatusEnum.PAID]: '#08844b0d',
    [InvoiceStatusEnum.CREATED]: '#0e86ca0d',
    [InvoiceStatusEnum.CANCELED]: '#e71c400d',
  };

  return colors[type] || '#8f8f8f0d';
};

export const StatusCell = styled.span<IStyledStatusCell>`
  color: ${({ type }) => statusColor(type)};
  border: 1px solid ${({ type }) => statusColor(type)};
  border-radius: 50px;
  background-color: ${({ type }) => statusBackground(type)};
  padding: 5px;
`;

export const IconShowActions = styled(RemoveRedEyeIcon)`
  cursor: pointer;
`;

export const SortingButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: #fff;
  font-size: 14px;
  font-style: normal;
  color: #6a758b;
  font-weight: 500;
  text-align: left;

  &[data-active='true'] {
    color: #000;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
