import styled from 'styled-components';

export const PhotosCard = styled.div`
  grid-area: photos;
  justify-content: flex-start;
  gap: 10px;
`;

export const Title = styled.span`
  margin-bottom: 10px;
  color: #6a758b;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  overflow: auto;
  max-height: 69vh;

  @media (max-height: 900px) {
    justify-content: center;
    max-height: 94vh;
  }
`;

export const NoPhoto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  text-align: center;

  height: 100%;
  width: 100%;
`;

export const Illustration = styled.img`
  height: 240px;
  width: 360px;
  border-radius: 4px;

  @media (max-width: 1600px) {
    height: 120px;
    width: 180px;
  }
`;

export const Description = styled.span`
  color: #6a758b;
  font-size: 16px;
  font-weight: 500;
`;
