import React from 'react';
import EmptyConclusionSvg from 'assets/svg/conclusion-not-found.svg';
import { Button } from 'components';
import * as S from './EmptyConclusion.styles';
import { IEmptyConclusionProps } from './EmptyConclusion.types';

const EmptyConclusion = ({
  onClick,
  testID = 'empty-conclusion-inspection',
  inspectionAuthenticated = false,
  haveCompanyConclusionTypes = false,
  isLoadingData = false,
}: IEmptyConclusionProps) => {
  return (
    <S.Container data-testid={testID}>
      <img src={EmptyConclusionSvg} alt="Não há conclusão" data-testid="empty-conclusion-svg" />
      <S.Description>Nenhuma conclusão adicionada.</S.Description>

      {!isLoadingData ? (
        <>
          {!inspectionAuthenticated && haveCompanyConclusionTypes && (
            <>
              <S.SubDescription>Para finalizar um atendimento, é necessário adicionar uma conclusão.</S.SubDescription>
              <Button testID="button-insert-conclusion" variant="outlined" text="Inserir Conclusão" onClick={onClick} />
            </>
          )}

          {!inspectionAuthenticated && !haveCompanyConclusionTypes && (
            <S.SubDescription>
              Não é possível adicionar conclusões nesta operação, pois não há tipos de conclusão cadastradas. Entre em
              contato com a Able Tech para solicitar o cadastro das conclusões.
            </S.SubDescription>
          )}
        </>
      ) : (
        <S.SubDescription>Aguarde enquanto carregamos as informações...</S.SubDescription>
      )}
    </S.Container>
  );
};

export default EmptyConclusion;
