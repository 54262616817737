import React from 'react';
import linkNotSentImage from 'assets/svg/link-not-sent.png';
import Loading from 'components/Loading';
import { StepperComponent } from 'components';
import { IInspection } from 'pages/SchedulerList/components/Inspection';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { InspectionCategoryEnum } from 'utils/InspectionEnum';
import { History } from '../History';
import { DetailsProps } from './Details.types';
import {
  Title,
  TitleCanceled,
  DetailsLoadingWrapper,
  DetailsContainer,
  DetailsHeader,
  DetailsBody,
  TitleLinkNotSent,
  DescriptionLinkNotSent,
  ContainerLinkNotSent,
} from './Details.styles';

export const Details = ({ inspectionData }: DetailsProps) => {
  const isInspectionCreatedStatus = ({ currentStatus }: IInspection): boolean => {
    return InspectionStatusService.isInspectionCreated(currentStatus);
  };

  const isInspectionCategoryDigital = (): boolean => {
    return inspectionData?.inspectionCategory?.code === InspectionCategoryEnum.DIGITAL;
  };

  if (inspectionData && isInspectionCreatedStatus(inspectionData) && isInspectionCategoryDigital()) {
    return (
      <ContainerLinkNotSent data-testid="link-not-sent">
        <img src={linkNotSentImage} alt="link-not-sent" />

        <TitleLinkNotSent>Link não enviado</TitleLinkNotSent>
        <DescriptionLinkNotSent>
          Houve um problema com o envio do link. Tente enviar novamente clicando no botão
          <span> Reenviar Link.</span>
        </DescriptionLinkNotSent>
      </ContainerLinkNotSent>
    );
  }

  return (
    <>
      {((): JSX.Element => {
        if (inspectionData) {
          const isInspectionCanceled = InspectionStatusService.isCanceled(inspectionData.status);
          const isInspectionExpired = InspectionStatusService.isExpired(inspectionData.status);

          return (
            <DetailsContainer>
              <DetailsHeader>
                {isInspectionCanceled || isInspectionExpired ? (
                  <TitleCanceled>
                    Atendimento #{inspectionData.code} - {isInspectionCanceled ? 'Cancelado' : 'Expirado'}
                  </TitleCanceled>
                ) : (
                  <Title>Atendimento #{inspectionData.code}</Title>
                )}

                <StepperComponent
                  testID="inspection-status"
                  inspectionStatus={inspectionData.status}
                  inspectionCategory={inspectionData.inspectionCategory}
                  error={isInspectionCanceled || isInspectionExpired}
                />
              </DetailsHeader>
              <DetailsBody>
                <History inspection={inspectionData} />
              </DetailsBody>
            </DetailsContainer>
          );
        }
        return (
          <DetailsLoadingWrapper>
            <Loading width="20%" />
          </DetailsLoadingWrapper>
        );
      })()}
    </>
  );
};
