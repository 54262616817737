import { useContext } from 'react';

import { IConsumerContext } from './types';

import { ConsumerContext } from './ConsumerProvider';

export const useConsumer = (): IConsumerContext => {
  const context = useContext(ConsumerContext);
  if (!context) {
    throw new Error('useConsumerDispatch must be used within a AuthProvider');
  }

  return context;
};
