import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';

interface IDamageReport {
  isTitle?: boolean;
  bold?: boolean;
  topCardInfo?: boolean;
}

export const Card = styled.div`
  border-radius: 5px;
  background-color: #fff;
  gap: 10px;
`;

export const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 101px;
`;

export const FirstLineCaption = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 30px;
  margin: 10px 24px 10px 24px;
`;

export const SecondLineCaption = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  border-radius: 6px;
  justify-content: space-between;
  padding: 10px 24px 10px 24px;
`;

export const CaptionInfo = styled.span<IDamageReport>`
  font-family: 'Montserrat';
  color: '#000';
  width: 33%;
  font-size: ${({ isTitle }) => (isTitle ? '15px' : '12px')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  flex: ${({ topCardInfo }) => (topCardInfo ? '1' : '')};
`;

export const Separator = styled(DividerMui)`
  border: 1px solid #f2f2f2;
  width: 100%;
`;
