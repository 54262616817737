import { Label } from 'components';
import styled from 'styled-components';
import { Divider as DividerMui } from '@mui/material';
import { TextField as TextFieldComponent } from 'components/TextField/TextField';

export const LabelNameEdit = styled(Label)`
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #000 !important;
  margin-top: 10px !important;
`;

export const DrawerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 1px;
  overflow-y: auto;

  .MuiTextField-root {
    width: 100%;
  }
`;

export const Divider = styled(DividerMui)`
  margin: 0 -20px !important;
  border-color: #ced6e5 !important;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
`;

export const TextField = styled(TextFieldComponent)`
  margin-bottom: 15px !important;
`;
