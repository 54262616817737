import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const DialogBase = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    display: grid;
    grid-template-rows: 75px 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'head' 'body';
    min-width: 30%;
    padding: 25px;
    gap: 20px;
    background: #ffffff;
    border-radius: 4px;
  }
`;

export const TitleWrapper = styled.div`
  grid-area: head;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;
  font-family: 'Montserrat';
  font-style: normal;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const Subtitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 15px;
`;
