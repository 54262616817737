import React, { useState } from 'react';
import DateService from 'services/DateService/dateService';
import { getFirtAndLastName } from 'utils/formatString';
import { IBudgetConclusion } from 'commom/types/Budget.types';
import {
  ButtonArchive,
  CardBody,
  CardContainer,
  CardHeader,
  CommentContent,
  DataAndActionContent,
  DataContainer,
  ReasonAndTypeContent,
  ReasonAndTypeTitle,
} from './ConclusionCard.styles';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { useAppSelector } from 'hooks/hooks';
import { selectBudgetId } from 'stores/presentBudget/presentBudget.selector';
import { ConfirmDialog } from 'components/Dialog/ConfirmDialog/ConfirmDialog';

export interface IConclusionCardProps {
  conclusion: IBudgetConclusion;
}

const ConclusionCard = ({
  conclusion: { user, createdAt, comment, reason, type, isArchived, id: conclusionId },
}: IConclusionCardProps) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const budgetId = useAppSelector(selectBudgetId);
  const dispatch = useDispatch();

  const archiveConclusion = async () => {
    try {
      setLoading(true);
      const budgetResponse = await BudgetService.archiveBudgetConclusion(budgetId, conclusionId);
      dispatch(setBudget(budgetResponse));
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao arquivar a conclusão');
      setLoading(false);
    }
  };

  return (
    <>
      <CardContainer isArchived={isArchived}>
        <CardHeader>
          <span>
            <ReasonAndTypeTitle>Perito: </ReasonAndTypeTitle>
            {getFirtAndLastName(user.name)}
          </span>
          <DataAndActionContent>
            <DataContainer>
              <CalendarMonthRoundedIcon />
              {DateService.formatDateAndTime(createdAt || '')}
            </DataContainer>
            {!isArchived && (
              <ButtonArchive
                data-testid="archive-button"
                type="button"
                onClick={() => setShowConfirmDialog(true)}
                disabled={loading}
              >
                <ArchiveOutlinedIcon />
              </ButtonArchive>
            )}
          </DataAndActionContent>
        </CardHeader>
        <CardBody>
          <ReasonAndTypeContent>
            <span>
              <ReasonAndTypeTitle>Motivo: </ReasonAndTypeTitle>
              {reason}
            </span>
            <span>
              <ReasonAndTypeTitle>Tipo de conclusão: </ReasonAndTypeTitle>
              {type}
            </span>
          </ReasonAndTypeContent>
          <CommentContent>{comment}</CommentContent>
        </CardBody>
      </CardContainer>
      {showConfirmDialog && (
        <ConfirmDialog
          dialogVisibility={showConfirmDialog}
          handleClose={() => setShowConfirmDialog(false)}
          testID="confirm-archive-dialog"
          onSubmit={archiveConclusion}
          title="Deseja realmente arquivar a conclusão?"
          subtitle="Ao confirmar, essa ação não poderá ser desfeita."
        />
      )}
    </>
  );
};

export default ConclusionCard;
