import styled from 'styled-components';

type StateTitleType = 'error' | 'finished';

interface IStateTitleProps {
  type: StateTitleType;
}

export const Title = styled.span`
  color: #6a758b;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
`;

export const StateTitle = styled.div<IStateTitleProps>`
  ${({ type }) => (type === 'error' ? 'color: #dc3d3d;' : 'color: #0070ae;')}

  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
`;

export const DocumentsWrapper = styled.div`
  padding: 15px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const DocumentsState = styled.div`
  display: flex;
  justify-content: space-between;
`;
