import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Select } from 'components/Select/Select';

export const SchedulerListWrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  height: 100vh;
  overflow: hidden;
  margin-left: 60px;
  transition: all 0.3s ease-in-out;

  &[data-isopen='false'] {
    grid-template-columns: 70px 1fr;
    transition: all 0.2s ease-in-out;
  }
`;

export const SchedulerListLeft = styled.div`
  width: 100%;
  padding: 29px 0px 0px 0px;
  border-right: solid 1px #e8e8e8;

  background: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

export const SchedulerListLeftContent = styled.div`
  transition: all 0.1s ease-in-out;

  ${SchedulerListWrapper}:not([data-isopen='true']) & {
    transition: all 0.2s ease-in-out;
    display: none;
  }
`;

export const SchedulerListRight = styled.div`
  overflow-y: auto;
  width: 100%;
  background-color: #f4f4f4;
`;

export const ButtonWrapper = styled.div`
  padding: 0 20px;
`;

export const SchedulersListWrapper = styled.div`
  height: calc(100vh - 200px);
  margin-bottom: 30px;
  border-bottom: solid 1px #e8e8e8;
`;

export const LinkList = styled(Link)`
  text-decoration: none;
  color: #0e7bc4;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 27px;
  text-transform: uppercase;
`;

export const LinkListIcon = styled(LinkList)`
  align-items: center;
  display: flex;

  svg {
    margin-right: 7px;
  }
`;

export const SchedulerListTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 30px;

  ${SchedulerListWrapper}:not([data-isopen='true']) & {
    justify-content: center;
  }
`;

export const SchedulerListTitle = styled.h1`
  color: #6a758b;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;

  ${SchedulerListWrapper}:not([data-isopen='true']) & {
    display: none;
  }
`;

export const SelectContainer = styled.div`
  padding-top: 10px;
`;

export const SelectWrapper = styled(Select)``;

export const SelectDate = styled.div`
  margin-top: 10px;
  display: grid;
`;
