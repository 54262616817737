import React, { useState } from 'react';
import { Form } from 'components/Form';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { useForm } from 'react-hook-form';
import AutocompleteField from 'components/AutocompleteField/AutocompleteField';
import FederalUnitService from 'services/ApiService/FederalUnitService';
import { IFederalUnit, ICity } from 'services/ApiService/FederalUnitService/types';
import AccountService from 'services/ApiService/AccountService';
import { formValidations } from 'utils/form/validations';
import { toast } from 'react-toastify';
import { IOptionSorted } from 'components/AutocompleteField';
import * as Style from './EditProfileDialog.styles';
import { IEditProfileDialogProps, IEditProfileForm, IFormRequest } from './EditProfileDialog.types';

export const EditProfileDialog = ({ dialogVisibility, handleClose }: IEditProfileDialogProps) => {
  const [ufList, setUfList] = useState<IFederalUnit[]>([]);
  const [citiesList, setCitiesList] = useState<ICity[]>([]);
  const [ufSelected, setUfSelected] = useState<string>('');
  const [citySelected, setCitySelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const getFederalUnits = async () => {
    try {
      const { federalUnits } = await FederalUnitService.getAll();
      setUfList(federalUnits);
    } catch (error) {
      toast.error('Erro ao buscar estados.');
    }
  };

  const onSubmit = async ({ name, cpf, phone }: IFormRequest): Promise<void> => {
    try {
      setLoading(true);
      const form: IEditProfileForm = {
        name,
        cpf,
        phone,
        uf: ufSelected,
        city: citySelected,
      };

      await AccountService.updateAccount(form);

      toast.success('Perfil atualizado com sucesso!');

      handleClose();
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao atualizar perfil.');
    } finally {
      setLoading(false);
    }
  };

  const transformFederalUnitToOptions = () => {
    return ufList.map((federalUnit) => ({ name: federalUnit.name }));
  };

  const transformCitiesToOptions = () => {
    return citiesList.map((item) => ({ name: item.name }));
  };

  const getCitiesFromFederalUnit = (federalUnitName: string) => {
    const uf = ufList.find((item) => item.name === federalUnitName);

    return uf?.cities || [];
  };

  const handleChangeUf = (value: IOptionSorted) => {
    if (value) {
      setUfSelected(value.name);
      setCitySelected('');
      setCitiesList(getCitiesFromFederalUnit(value.name));
    } else {
      setUfSelected('');
      setCitySelected('');
      setCitiesList([]);
    }
  };

  const handleChangeCity = (value: IOptionSorted) => {
    return value ? setCitySelected(value.name) : setCitySelected('');
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const inputProps = {
    name: {
      'data-testid': 'input-name',
    },
    cpf: {
      'data-testid': 'input-cpf',
    },
    phone: {
      'data-testid': 'input-phone',
    },
  };

  return (
    <Style.DialogBase
      data-testid="edit-profile-dialog"
      open={dialogVisibility}
      TransitionProps={{ onEnter: getFederalUnits }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <Style.DialogTitle>Editar perfil</Style.DialogTitle>
      <Style.DialogContentText>Altere apenas os dados que desejar do seu perfil.</Style.DialogContentText>
      <Style.FormWrapper>
        <Form testID="edit-profile-form" onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="name" testID="label-name">
            Nome
          </Label>
          <Style.TextField
            ariaLabelledby="name"
            error={hasError('name')}
            id="name"
            inputProps={inputProps.name}
            inputRef={register(formValidations.default)}
            helperText={errors?.name?.message}
            name="name"
            placeholder="Insira seu nome"
            state={handleInputState('name')}
            type="text"
            variant="outlined"
          />

          <Label htmlFor="cpf" testID="label-cpf">
            CPF
          </Label>
          <Style.TextField
            ariaLabelledby="cpf"
            error={hasError('cpf')}
            id="cpf"
            inputProps={inputProps.cpf}
            inputRef={register(formValidations.cpf)}
            helperText={errors?.cpf?.message}
            name="cpf"
            mask="000.000.000-00"
            placeholder="Insira seu CPF"
            state={handleInputState('cpf')}
            type="text"
            variant="outlined"
          />

          <Label htmlFor="phone" testID="label-phone">
            Telefone
          </Label>
          <Style.TextField
            ariaLabelledby="phone"
            error={hasError('phone')}
            id="phone"
            inputProps={inputProps.phone}
            inputRef={register(formValidations.phone)}
            helperText={errors?.phone?.message}
            mask="(00) 0 0000-0000"
            name="phone"
            placeholder="Insira seu telefone"
            state={handleInputState('phone')}
            type="text"
            variant="outlined"
          />

          <Label htmlFor="ufs" testID="label-ufs">
            UF
          </Label>
          <AutocompleteField
            required={false}
            testID="select-uf"
            id="ufs"
            options={transformFederalUnitToOptions()}
            selected={ufSelected}
            placeholder="Insira o estado - UF"
            onChange={(e: React.SyntheticEvent<Element, Event>, value: IOptionSorted) => handleChangeUf(value)}
          />

          <Label htmlFor="cities" testID="label-cities">
            Cidade
          </Label>
          <AutocompleteField
            required={false}
            testID="select-city"
            id="cities"
            options={transformCitiesToOptions()}
            selected={citySelected}
            placeholder="Insira a cidade"
            disabled={ufSelected.length === 0}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: IOptionSorted) => handleChangeCity(value)}
          />

          <Style.DialogActions>
            <Style.ButtonWrapper>
              <Button
                testID="edit-profile-cancel"
                type="button"
                variant="outlined"
                onClick={handleClose}
                text="CANCELAR"
              />
            </Style.ButtonWrapper>
            <Style.ButtonWrapper>
              <Button testID="edit-profile-submit" type="submit" text="SALVAR" loading={loading} loadingSize={26} />
            </Style.ButtonWrapper>
          </Style.DialogActions>
        </Form>
      </Style.FormWrapper>
    </Style.DialogBase>
  );
};
