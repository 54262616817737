import { IBudgetPart } from 'commom/types/Budget.types';
import React from 'react';
import carManualPartsIcon from 'assets/svg/budget-template/icon-car-manual-parts.svg';
import carPartsIcon from 'assets/svg/budget-template/icon-car-parts.svg';
import BudgetPart from './BudgetPart/BudgetPart';
import {
  IconTitleContainer,
  NormalText,
  QuantityContainer,
  SemiBoldText,
  Title,
  SectionContainer,
  PartsTable,
  EmptyPartsMessage,
} from './BudgetPartsSection.styles';

interface IBudgetPartsSectionProps {
  parts: IBudgetPart[];
}

const BudgetPartsSection = ({ parts }: IBudgetPartsSectionProps) => {
  const manualParts = parts.filter((part) => part.isManualEntry);
  const nonManualParts = parts.filter((part) => !part.isManualEntry);

  const formatPartsQuantity = (quantity: number): string => quantity.toString().padStart(2, '0');

  const getAllPartsQuantity = (parts: IBudgetPart[]) => {
    return parts.reduce((total, part) => total + part.quantity, 0);
  };

  const showParts = (parts: IBudgetPart[], title: string, icon: string) => {
    return (
      <>
        <SectionContainer>
          <IconTitleContainer>
            <img src={icon} alt="icon" width="18" />
            <Title>{title}</Title>
          </IconTitleContainer>

          <QuantityContainer>
            <NormalText>Qtd. Total de Peças</NormalText>
            <SemiBoldText>{formatPartsQuantity(getAllPartsQuantity(parts))}</SemiBoldText>
          </QuantityContainer>
        </SectionContainer>
        {parts?.length > 0 ? (
          <PartsTable>
            {parts.map((part) => (
              <BudgetPart part={part} key={part._id} />
            ))}
          </PartsTable>
        ) : (
          <EmptyPartsMessage>{`Sem ${title.toLocaleLowerCase()} adicionadas`}</EmptyPartsMessage>
        )}
      </>
    );
  };

  return parts?.length > 0 ? (
    <>
      {showParts(nonManualParts, 'Peças', carPartsIcon)}
      {showParts(manualParts, 'Peças incluídas manualmente', carManualPartsIcon)}
    </>
  ) : (
    <>
      <SectionContainer className="empty">
        <IconTitleContainer>
          <img src={carPartsIcon} alt="icon" width="18" />
          <Title>Peças</Title>
        </IconTitleContainer>

        <QuantityContainer>
          <NormalText>Qtd. Total de Peças</NormalText>
          <SemiBoldText>00</SemiBoldText>
        </QuantityContainer>
      </SectionContainer>
      <EmptyPartsMessage>Sem peças adicionadas</EmptyPartsMessage>
    </>
  );
};

export default BudgetPartsSection;
