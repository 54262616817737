import React from 'react';
import Loading from 'components/Loading';
import { Button } from 'components/Button/Button';
import { INotification } from 'commom/types/Notification.types';
import { NotificationCard } from 'components/Card/NotificationCard/NotificationCard';
import { useTranslate } from 'hooks/Translate/TranslateHook';
import { NotificationsTabsEnum } from '../NotificationsDrawer.types';
import { EmptyNotifications } from '../EmptyNotifications/EmptyNotifications';
import * as S from './NotificationsList.styles';

interface INotificationsListProps {
  notifications: INotification[] | undefined;
  listType: NotificationsTabsEnum;
  hasNotifications: boolean;
  hasNextPage: boolean;
  loading: boolean;
  fetchNextPage: () => void;
}

export const NotificationsList = ({
  notifications,
  hasNotifications,
  listType,
  loading,
  hasNextPage,
  fetchNextPage,
}: INotificationsListProps) => {
  const { translate } = useTranslate();

  function getEmptyMessages() {
    const unreadMessage = {
      title: translate('NOTIFICATIONS.EMPTY.UNREAD.TITLE'),
      description: translate('NOTIFICATIONS.EMPTY.UNREAD.DESCRIPTION'),
    };

    const defaultMessage = {
      title: translate('NOTIFICATIONS.EMPTY.DEFAULT.TITLE'),
      description: translate('NOTIFICATIONS.EMPTY.DEFAULT.DESCRIPTION'),
    };

    const messagesMap = {
      [NotificationsTabsEnum.UNREAD]: unreadMessage,
      [NotificationsTabsEnum.ALL]: defaultMessage,
    };

    return messagesMap[listType] || defaultMessage;
  }

  return (
    <S.Container data-testid="notifications-list">
      {hasNotifications ? (
        <>
          {notifications &&
            notifications.map((notification) => <NotificationCard key={notification.id} notification={notification} />)}

          {loading ? (
            <S.Wrapper>
              <Loading width="40%" />
            </S.Wrapper>
          ) : (
            hasNextPage && (
              <S.Wrapper>
                <Button
                  variant="outlined"
                  type="button"
                  fullWidth={true}
                  size="large"
                  testID="load-more-button"
                  text="Carregar mais"
                  onClick={fetchNextPage}
                />
              </S.Wrapper>
            )
          )}
        </>
      ) : (
        <EmptyNotifications title={getEmptyMessages().title} description={getEmptyMessages().description} />
      )}
    </S.Container>
  );
};
