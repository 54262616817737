import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { theme } from './Label.styles';
import { ILabelProps } from './Label.types';

export const Label: React.FC<ILabelProps> = ({
  className,
  children,
  testID = 'label',
  htmlFor,
  color,
  disableAnimation,
  disabled,
  error,
  focused,
  margin,
  required,
  shrink,
  variant,
  id,
}) => (
  <ThemeProvider theme={theme}>
    <InputLabel
      className={className}
      htmlFor={htmlFor}
      data-testid={testID}
      color={color}
      disableAnimation={disableAnimation}
      disabled={disabled}
      error={error}
      focused={focused}
      margin={margin}
      required={required}
      shrink={shrink}
      variant={variant}
      id={id}
    >
      {children}
    </InputLabel>
  </ThemeProvider>
);
