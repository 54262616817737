import {
  IInspectionTableRow,
  IReportInspectionTableRow,
} from 'pages/ManagementDashboard/components/InspectionsTable/InspectionsTable.types';
import { StatusEnum } from 'pages/SchedulerList/components/Inspection';
import DateService from 'services/DateService/dateService';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { GeneralMetricsDetailsFilterEnum } from 'services/ApiService/MetricsService/metricsService.types';
import { IMetricsTableRow } from 'pages/Metrics/components/TableRow/TableRow.types';
import { IInspectionTableService } from './InspectionTableService.types';

const InspectionTableService: IInspectionTableService = {
  getFromInspection: (inspections, isInspectionReport) => {
    return isInspectionReport
      ? InspectionTableService.getInspectionReportTable(inspections)
      : InspectionTableService.getInspectionTable(inspections);
  },

  getInspectionTable: (inspections) => {
    return inspections.map((inspection) => {
      const { code, company, product, id, testID, companyId, regulator, conclusion } = inspection;

      const statusLinkSent = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.LINK_SENT);
      const statusPhotoReceived = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.PHOTO_RECEIVED);
      const statusFinished = InspectionStatusService.getStatusByType(inspection.status, StatusEnum.INSPECTION_FINISHED);

      return {
        testID,
        id,
        companyName: company?.name,
        code,
        plate: product?.plate,
        regulator: regulator?.username,
        dateLinkSent: statusLinkSent.date ? DateService.formatDate(statusLinkSent.date) : '',
        hourLinkSent: statusLinkSent.date ? DateService.formatTime(statusLinkSent.date) : '',
        datePhotoReceived: statusPhotoReceived.date ? DateService.formatDate(statusPhotoReceived.date) : '',
        hourPhotoReceived: statusPhotoReceived.date ? DateService.formatTime(statusPhotoReceived.date) : '',
        dateFinished: statusFinished.date ? DateService.formatDate(statusFinished.date) : '',
        hourFinished: statusFinished.date ? DateService.formatTime(statusFinished.date) : '',
        companyId,
        conclusionType: conclusion?.conclusionType.description,
        conclusionReason: conclusion?.conclusionReason.description,
      };
    }) as IInspectionTableRow[];
  },

  getInspectionReportTable: (inspections) => {
    return inspections.map((inspection) => {
      const {
        id,
        company,
        product,
        testID,
        companyId,
        regulator,
        createdAt,
        schedule,
        inspectionDetails,
        customer,
        shortTokenUrl,
        currentStatus,
        conclusion,
      } = inspection;

      const statusInspectionFinished = InspectionStatusService.getStatusByType(
        inspection.status,
        StatusEnum.INSPECTION_FINISHED
      );

      return {
        id,
        testID,
        companyName: company?.name,
        regulator: regulator?.username,
        plate: product?.plate,
        vehicle: product?.vehicle?.brand,
        model: product?.vehicle?.model,
        km: String(product?.vehicle?.kilometers || ''),
        register: DateService.formatDate(createdAt) || '',
        requested: schedule ? DateService.formatDate(schedule) : '',
        accomplished: statusInspectionFinished ? DateService.formatDate(statusInspectionFinished.date) : '',
        damageExpertAmountGross: inspectionDetails?.damageExpertAmountGross,
        damageExpertAmountNet: inspectionDetails?.damageExpertAmountNet,
        address: product?.vehicle?.address?.fullAddress?.replace(/,/g, ' '),
        city: product?.vehicle?.address?.city?.replace(/,/g, ' '),
        neighborhood: product?.vehicle?.address?.neighborhood?.replace(/,/g, ' '),
        cep: product?.vehicle?.address?.cep,
        agreement: inspectionDetails?.agreement,
        contract: inspectionDetails?.contract,
        status: currentStatus.description,
        renter: customer?.name,
        inspectionType: company?.name,
        user: customer?.contactName,
        email: customer?.name,
        phone: customer?.phone,
        shortTokenUrl,
        companyId,
        conclusionType: conclusion?.conclusionType.description,
        conclusionReason: conclusion?.conclusionReason.description,
      };
    }) as IReportInspectionTableRow[];
  },

  getCSVHeader: (isInspectionReport, isAuto = true) => {
    return isInspectionReport
      ? InspectionTableService.getCSVReportHeader()
      : InspectionTableService.getCSVDefaultHeader(isAuto);
  },

  getCSVDefaultHeader: (isAuto = true) => {
    let headers = [
      { label: isAuto ? 'CIA' : 'OPERAÇÃO', key: 'companyName' },
      { label: isAuto ? 'SINISTRO' : 'IDENTIFICADOR', key: 'code' },
      { label: 'PLACA', key: 'plate' },
      { label: 'PERITO', key: 'regulator' },
      { label: 'DATA - ENVIO LINK', key: 'dateLinkSent' },
      { label: 'HORA - ENVIO DO LINK', key: 'hourLinkSent' },
      { label: 'DATA - RETORNO IMAGENS', key: 'datePhotoReceived' },
      { label: 'HORA - RETORNO DAS IMAGENS', key: 'hourPhotoReceived' },
      { label: 'DATA - DEVOLUÇÃO LOTE', key: 'dateFinished' },
      { label: 'HORA - DEVOLUÇÃO DO LOTE', key: 'hourFinished' },
      { label: 'TIPO DA CONCLUSÃO', key: 'conclusionType' },
      { label: 'MOTIVO', key: 'conclusionReason' },
    ];

    if (!isAuto) {
      headers = headers.filter((header) => header.label !== 'PLACA');
    }

    return headers;
  },

  getCSVReportHeader: () => {
    return [
      { label: 'EMPRESA', key: 'companyName' },
      { label: 'PERITO', key: 'regulator' },
      { label: 'PLACA', key: 'plate' },
      { label: 'VEÍCULO', key: 'vehicle' },
      { label: 'MODELO', key: 'model' },
      { label: 'KM', key: 'km' },
      { label: 'CADASTRO', key: 'register' },
      { label: 'SOLICITADO', key: 'requested' },
      { label: 'REALIZADO', key: 'accomplished' },
      { label: 'AVARIAS', key: 'damageExpertAmountGross' },
      { label: 'AVARIAS REVISADAS', key: 'damageExpertAmountNet' },
      { label: 'ENDEREÇO', key: 'address' },
      { label: 'CIDADE', key: 'city' },
      { label: 'BAIRRO', key: 'neighborhood' },
      { label: 'CEP', key: 'zipCode' },
      { label: 'ACORDO', key: 'agreement' },
      { label: 'CONTRACT', key: 'contract' },
      { label: 'STATUS', key: 'status' },
      { label: 'LOCATÁRIO', key: 'renter' },
      { label: 'TIPO DE VISTORIA', key: 'inspectionType' },
      { label: 'USUÁRIO', key: 'user' },
      { label: 'EMAIL', key: 'email' },
      { label: 'TELEFONE', key: 'phone' },
      { label: 'URL DA CONSULTA', key: 'shortTokenUrl' },
      { label: 'TIPO DA CONCLUSÃO', key: 'conclusionType' },
      { label: 'MOTIVO', key: 'conclusionReason' },
    ];
  },

  getHeader: () => {
    return [
      { key: 1, name: 'CIA' },
      { key: 2, name: 'SINISTRO' },
      { key: 3, name: 'PLACA' },
      { key: 4, name: 'PERITO' },
      { key: 5, name: 'DATA - ENVIO LINK' },
      { key: 6, name: 'HORA - ENVIO DO LINK' },
      { key: 7, name: 'DATA - RETORNO IMAGENS' },
      { key: 8, name: 'HORA - RETORNO DAS IMAGENS' },
      { key: 9, name: 'DATA - DEVOLUÇÃO LOTE' },
      { key: 10, name: 'HORA - DEVOLUÇÃO DO LOTE' },
      { key: 11, name: 'TIPO DA CONCLUSÃO' },
      { key: 12, name: 'MOTIVO' },
    ];
  },

  getHeaderActionsTable: (isAuto = true) => {
    let headers = [
      { key: 0, name: 'AÇÕES' },
      { key: 1, name: '' },
      { key: 2, name: isAuto ? 'CIA' : 'OPERAÇÃO' },
      { key: 3, name: isAuto ? 'SINISTRO' : 'IDENTIFICADOR' },
      { key: 4, name: 'PLACA' },
      { key: 5, name: 'PERITO' },
      { key: 6, name: 'DATA - ENVIO LINK' },
      { key: 7, name: 'HORA - ENVIO DO LINK' },
      { key: 8, name: 'DATA - RETORNO IMAGENS' },
      { key: 9, name: 'HORA - RETORNO DAS IMAGENS' },
      { key: 10, name: 'DATA - DEVOLUÇÃO LOTE' },
      { key: 11, name: 'HORA - DEVOLUÇÃO DO LOTE' },
      { key: 12, name: 'TIPO DA CONCLUSÃO' },
      { key: 13, name: 'MOTIVO' },
      { key: 14, name: 'AÇÕES' },
    ];

    if (!isAuto) {
      headers = headers.filter((header) => header.name !== 'PLACA');
    }

    return headers;
  },

  getHeaderEmptyActionsTable: (isAuto = true) => {
    let headers = [
      { key: 0, name: 'AÇÕES' },
      { key: 2, name: isAuto ? 'CIA' : 'OPERAÇÃO' },
      { key: 3, name: isAuto ? 'SINISTRO' : 'IDENTIFICADOR' },
      { key: 4, name: 'PLACA' },
      { key: 5, name: 'PERITO' },
      { key: 6, name: 'DATA - ENVIO LINK' },
      { key: 7, name: 'HORA - ENVIO DO LINK' },
      { key: 8, name: 'DATA - RETORNO IMAGENS' },
      { key: 9, name: 'HORA - RETORNO DAS IMAGENS' },
      { key: 10, name: 'DATA - DEVOLUÇÃO LOTE' },
      { key: 11, name: 'HORA - DEVOLUÇÃO DO LOTE' },
      { key: 12, name: 'TIPO DA CONCLUSÃO' },
      { key: 13, name: 'MOTIVO' },
      { key: 14, name: 'AÇÕES' },
    ];

    if (!isAuto) {
      headers = headers.filter((header) => header.name !== 'PLACA');
    }

    return headers;
  },

  getReportHeaderActionsTable: () => {
    return [
      { key: 0, name: 'AÇÕES' },
      { key: 1, name: '' },
      { key: 2, name: 'EMPRESA' },
      { key: 3, name: 'PERITO' },
      { key: 4, name: 'PLACA' },
      { key: 5, name: 'VEÍCULO' },
      { key: 6, name: 'MODELO' },
      { key: 7, name: 'KM' },
      { key: 8, name: 'CADASTRO' },
      { key: 9, name: 'SOLICITADO' },
      { key: 10, name: 'REALIZADO' },
      { key: 11, name: 'AVARIAS' },
      { key: 12, name: 'AVARIAS REVISADAS' },
      { key: 13, name: 'ENDEREÇO' },
      { key: 14, name: 'CIDADE' },
      { key: 15, name: 'BAIRRO' },
      { key: 16, name: 'CEP' },
      { key: 17, name: 'ACORDO' },
      { key: 18, name: 'CONTRACT' },
      { key: 19, name: 'STATUS' },
      { key: 20, name: 'LOCATÁRIO' },
      { key: 21, name: 'TIPO DE VISTORIA' },
      { key: 22, name: 'USUÁRIO' },
      { key: 23, name: 'EMAIL' },
      { key: 24, name: 'TELEFONE' },
      { key: 25, name: 'URL DA CONSULTA' },
      { key: 26, name: 'TIPO DA CONCLUSÃO' },
      { key: 27, name: 'MOTIVO' },
      { key: 28, name: 'AÇÕES' },
    ];
  },

  getReportHeaderEmptyActionsTable: () => {
    return [
      { key: 0, name: 'AÇÕES' },
      { key: 2, name: 'EMPRESA' },
      { key: 3, name: 'PERITO' },
      { key: 4, name: 'PLACA' },
      { key: 5, name: 'VEÍCULO' },
      { key: 6, name: 'MODELO' },
      { key: 7, name: 'KM' },
      { key: 8, name: 'CADASTRO' },
      { key: 9, name: 'SOLICITADO' },
      { key: 10, name: 'REALIZADO' },
      { key: 11, name: 'AVARIAS' },
      { key: 12, name: 'AVARIAS REVISADAS' },
      { key: 13, name: 'ENDEREÇO' },
      { key: 14, name: 'ESTADO' },
      { key: 15, name: 'CIDADE' },
      { key: 16, name: 'BAIRRO' },
      { key: 17, name: 'CEP' },
      { key: 18, name: 'ACORDO' },
      { key: 19, name: 'CONTRACT' },
      { key: 20, name: 'STATUS' },
      { key: 21, name: 'LOCATÁRIO' },
      { key: 22, name: 'TIPO DE VISTORIA' },
      { key: 23, name: 'USUÁRIO' },
      { key: 24, name: 'EMAIL' },
      { key: 25, name: 'TELEFONE' },
      { key: 26, name: 'URL DA CONSULTA' },
      { key: 27, name: 'TIPO DA CONCLUSÃO' },
      { key: 28, name: 'MOTIVO' },
      { key: 29, name: 'AÇÕES' },
    ];
  },

  getGeneralMetricsTableHeaders: (filter) => {
    const headers = [
      { key: 0, name: 'Tempo' },
      { key: 1, name: 'Sinistro' },
      { key: 2, name: 'Perito' },
      { key: 3, name: 'Tipo de vistoria' },
      { key: 4, name: 'Operação' },
      { key: 5, name: 'Tipo do produto' },
      { key: 6, name: 'Data de criação' },
      { key: 7, name: 'Data de início' },
      { key: 8, name: 'Data de Conclusão' },
      { key: 9, name: 'Tempo' },
    ];

    const headersWithCancelledReason = [
      { key: 0, name: 'Tempo' },
      { key: 1, name: 'Sinistro' },
      { key: 2, name: 'Perito' },
      { key: 3, name: 'Tipo de vistoria' },
      { key: 4, name: 'Operação' },
      { key: 5, name: 'Tipo do produto' },
      { key: 6, name: 'Motivo Cancelamento' },
      { key: 7, name: 'Data de criação' },
      { key: 8, name: 'Data de início' },
      { key: 9, name: 'Data de Conclusão' },
      { key: 10, name: 'Tempo' },
    ];

    return filter === GeneralMetricsDetailsFilterEnum.CANCELLED ? headersWithCancelledReason : headers;
  },

  getInspectionChartTableHeaders: (showTime) => {
    const headersFinishInspection = [
      { key: 0, name: 'Tempo' },
      { key: 1, name: 'Sinistro' },
      { key: 2, name: 'Perito' },
      { key: 3, name: 'Tipo de vistoria' },
      { key: 4, name: 'Operação' },
      { key: 5, name: 'Tipo do produto' },
      { key: 6, name: 'Data de criação' },
      { key: 7, name: 'Data de início' },
      { key: 8, name: 'Data de Conclusão' },
      { key: 9, name: 'Tempo' },
    ];

    const headersWithCancelledInspection = [
      { key: 1, name: 'Sinistro' },
      { key: 2, name: 'Perito' },
      { key: 3, name: 'Tipo de vistoria' },
      { key: 4, name: 'Operação' },
      { key: 5, name: 'Tipo do produto' },
      { key: 6, name: 'Data de criação' },
      { key: 7, name: 'Data de início' },
      { key: 8, name: 'Data do cancelamento' },
    ];
    return showTime ? headersFinishInspection : headersWithCancelledInspection;
  },

  getInspectionCancelledChartCSVHeaders: () => {
    return [
      { key: 'code', label: 'Sinistro' },
      { key: 'regulator', label: 'Perito' },
      { key: 'inspectionTypeName', label: 'Tipo de vistoria' },
      { key: 'company', label: 'Operação' },
      { key: 'productTypeName', label: 'Tipo do produto' },
      { key: 'createdDate', label: 'Data de criação' },
      { key: 'startedDate', label: 'Data de início' },
      { key: 'finishedDate', label: 'Data do cancelamento' },
    ];
  },

  getInspectionFinishedChartCSVHeaders: () => {
    return [
      { key: 'code', label: 'Sinistro' },
      { key: 'regulator', label: 'Perito' },
      { key: 'inspectionTypeName', label: 'Tipo de vistoria' },
      { key: 'company', label: 'Operação' },
      { key: 'productTypeName', label: 'Tipo do produto' },
      { key: 'createdDate', label: 'Data de criação' },
      { key: 'startedDate', label: 'Data de início' },
      { key: 'finishedDate', label: 'Data do cancelamento' },
      { key: 'averageTime', label: 'Tempo' },
    ];
  },

  getGeneralMetricsTableRows: (dataTable) => {
    return dataTable.map((data) => {
      const { createdDate, finishedDate, startedDate, averageTime } = data;
      return {
        ...data,
        averageTime: averageTime ? `${averageTime} h` : '',
        createdDate: createdDate ? DateService.formatDateToPTBR(createdDate) : '',
        startedDate: startedDate ? DateService.formatDateToPTBR(startedDate) : '',
        finishedDate: finishedDate ? DateService.formatDateToPTBR(finishedDate) : '',
      };
    }) as IMetricsTableRow[];
  },

  getInspectionsChartsTableRows: (dataTable): IMetricsTableRow[] => {
    return dataTable.map((data) => {
      return {
        testID: data?.id,
        code: data?.code,
        regulator: data?.regulator?.username,
        inspectionTypeName: data?.inspectionType?.description,
        company: data?.company?.name,
        productTypeName: data?.productType?.description,
        createdDate: data?.createdAt ? DateService.formatDateToPTBR(data?.createdAt) : '',
        startedDate: data?.schedule
          ? DateService.formatDateToPTBR(data?.schedule)
          : DateService.formatDateToPTBR(data?.createdAt),
        finishedDate: data?.currentStatus?.date ? DateService.formatDateToPTBR(data.currentStatus.date) : '',
        averageTime: data?.conclusionTime
          ? `${DateService.getHourAndMinuteByMilliseconds(data?.conclusionTime)} h`
          : '',
      };
    }) as IMetricsTableRow[];
  },
};

export default InspectionTableService;
