import React from 'react';
import Conclusion from 'pages/SchedulerList/components/Conclusion/Conclusion';
import { Button } from 'components';
import InspectionStatusService from 'services/ApiService/InspectionStatusService/InspectionStatusService';
import { IConclusionCardProps } from './ConclusionCard.types';
import * as S from './ConclusionCard.styles';

const ConclusionCard = ({
  testId = 'conclusion-card-component',
  conclusion,
  inspectionStatus,
  onOpenConclusionDialog,
}: IConclusionCardProps) => {
  const { comment, conclusionReason, conclusionType, createdBy, updatedAt } = conclusion;

  const isInspectionFinished = InspectionStatusService.isInspectionFinished(inspectionStatus);

  return (
    <S.Container data-testid={testId}>
      <Conclusion
        comment={comment}
        conclusionReason={conclusionReason.description}
        conclusionType={conclusionType.description}
        createdByUser={createdBy || 'Usuário não encontrado'}
        dateCreated={updatedAt || 'Data não encontrada'}
        testId="conclusion-component"
      />
      {!isInspectionFinished && (
        <Button
          onClick={onOpenConclusionDialog}
          variant="outlined"
          text="Editar Conclusão"
          testID="button-edit-conclusion"
          startIcon={<S.EditIcon />}
        />
      )}
    </S.Container>
  );
};

export default ConclusionCard;
