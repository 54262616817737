import ApiService from 'services/ApiService';
import { CaptureTypeEnum, ICaptureType, ICaptureTypeResponse, ICaptureTypeService } from './captureTypeService.types';

const CaptureTypeService: ICaptureTypeService = {
  getCaptureTypeByCompanyId: async (companyId: string) => {
    const { data } = await ApiService.get<ICaptureTypeResponse>(`/capture-types/by-company/${companyId}`);

    return data;
  },

  setCaptureTypeInspectionIsLocally: (captureType: ICaptureType[]) => {
    if (captureType.length > 1) {
      return captureType.find((item) => item.name === CaptureTypeEnum.AUTO)?.id;
    }
    return undefined;
  },
};

export default CaptureTypeService;
