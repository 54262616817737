import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  height: auto;
  padding: 12px 20px;
  gap: 18px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Subtitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.5px;
  color: #6a758b;
`;

export const Description = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.5px;
  color: #6a758b;
`;

export const RecoverableVehicleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InformationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CheckBoxContainer = styled.div`
  margin-left: -10px;
  display: flex;
  align-items: center;
`;
