import React, { useState } from 'react';
import { AnchorTypesEnum, Drawer } from 'components/Drawer';
import { IDealDrawerProps } from './DealDrawer.types';
import CreateDeal from './CreateDeal/CreateDeal';
import ListDeal from './ListDeal/ListDeal';

const DealDrawer = ({ onClose, open }: IDealDrawerProps) => {
  const [createDeal, setCreateDeal] = useState(false);

  return (
    <Drawer.Root anchor={AnchorTypesEnum.RIGHT} onClose={onClose} openDrawer={open}>
      {createDeal ? (
        <CreateDeal setCreateDeal={setCreateDeal} onCloseDrawer={onClose} />
      ) : (
        <ListDeal onCloseDrawer={onClose} setCreateDeal={setCreateDeal} />
      )}
    </Drawer.Root>
  );
};

export default DealDrawer;
