import React from 'react';
import loadingIllustration from 'assets/svg/loading-dots.svg';

import { Container, Illustration } from './styles';
import { ILoadingDotsPros } from './types';

const Loading = ({ width = '50%' }: ILoadingDotsPros) => {
  return (
    <Container data-testid="loading">
      <Illustration src={loadingIllustration} width={width} />
    </Container>
  );
};

export default Loading;
