import React from 'react';
import { ITabPanel } from './TabPanel.types';
import { Container } from './TabPanel.styles';

const TabPanel: React.FC<ITabPanel> = ({ children, value, tabName }) => {
  const isActiveTab = value === tabName;

  return (
    <Container
      role="tabpanel"
      hidden={value !== tabName}
      id={`simple-tabpanel-${tabName}`}
      data-testid={`simple-tabpanel-${tabName}`}
      aria-labelledby={`simple-tab-${tabName}`}
      data-active={isActiveTab ? 'true' : 'false'}
    >
      {isActiveTab && <>{children}</>}
    </Container>
  );
};

export default TabPanel;
