import React from 'react';

import notFoundIllustration from 'assets/svg/page-not-found.svg';
import { NotFoundWrapper, Illustration, Description } from './NotFound.styles';

export const NotFound: React.FC = () => {
  return (
    <NotFoundWrapper data-testid="page-not-found">
      <Illustration src={notFoundIllustration} />

      <Description>Página não Encontrada</Description>
    </NotFoundWrapper>
  );
};
