import styled from 'styled-components';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const ActionsIconWrapper = styled(IconButton)``;

export const ActionsMenuIcon = styled(MoreVertIcon)`
  transform: rotate(90deg);
  color: black;
`;

export const ActionsMenuWrapper = styled(Menu)`
  .MuiPaper-root {
    left: calc(100% - 254px) !important;
    height: 107px;
    width: 200px !important;
    border-radius: 0px;
    padding: 15px 10px 15px 10px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
  }

  .MuiButtonBase-root:hover {
    background-color: #fff !important;
  }

  .MuiTouchRipple-root {
    opacity: 0;
  }

  .MuiMenu-list {
    position: relative;
  }
`;

export const CancelWrapper = styled.div`
  position: absolute;
`;

export const ChangeOperationWrapper = styled.div`
  position: absolute;
  top: 42px;
`;

export const ActionsMenuItem = styled(MenuItem)`
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #000000 !important;
  padding-top: 5px !important;

  &:hover span {
    border-bottom: 2px solid #000000;
  }
`;

export const ActionsMenuItemDisabled = styled(MenuItem)`
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #919191 !important;
  padding-top: 5px !important;
  pointer-events: none;
`;

export const ActionsText = styled.span`
  &:hover {
    border-bottom: 2px solid #000000;
  }
`;
