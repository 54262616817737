import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel/TabPanel';
import { ACTIVE_TAB_ENUM, IInspectionFilesProps } from './InspectionFiles.types';
import * as Styles from './InspectionFiles.styles';
import { IDocument, IPhoto } from '../Inspection/Inspection.types';
import PhotoActions from './components/PhotoActions/PhotoActions';
import TabPhotos from './components/TabPhotos/TabPhotos';
import DocumentActions from './components/DocumentActions/DocumentActions';
import TabDocuments from './components/TabDocuments/TabDocuments';

const InspectionFiles = ({ inspection, actionsVisible = true }: IInspectionFilesProps) => {
  const [activeTab, setActiveTab] = useState<ACTIVE_TAB_ENUM>(ACTIVE_TAB_ENUM.TAB_PHOTO);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const toggleAccordionExpanded = () => setIsAccordionExpanded((previous) => !previous);

  const handleTabsChange = (_: React.SyntheticEvent<Element, Event>, newValue: ACTIVE_TAB_ENUM) => {
    setActiveTab(newValue);
  };

  const getFilesCount = (photoOrDocument?: IPhoto[] | IDocument[]) => photoOrDocument?.length || 0;

  const renderActionsMenu = () => {
    const actionsMenu = {
      [ACTIVE_TAB_ENUM.TAB_PHOTO]: <PhotoActions inspection={inspection} />,
      [ACTIVE_TAB_ENUM.TAB_DOCS]: <DocumentActions inspection={inspection} />,
    };

    return actionsMenu[activeTab];
  };

  return (
    <>
      <Styles.Accordion data-testid="inspection-files-accordion" expanded={isAccordionExpanded}>
        <Styles.Summary
          data-testid="inspection-files-accordion-summary"
          expandIcon={<Styles.ExpandIcon />}
          IconButtonProps={{ onClick: toggleAccordionExpanded, id: 'expand-button' }}
        >
          <Styles.TabsBase value={activeTab} onChange={handleTabsChange}>
            <Tab
              disableRipple
              label={`Fotos (${getFilesCount(inspection.photos)})`}
              value={ACTIVE_TAB_ENUM.TAB_PHOTO}
            />
            <Tab
              disableRipple
              label={`Documentos (${getFilesCount(inspection.files)})`}
              value={ACTIVE_TAB_ENUM.TAB_DOCS}
            />
          </Styles.TabsBase>
          {actionsVisible && renderActionsMenu()}
        </Styles.Summary>
        <Styles.Details>
          <TabPanel value={activeTab} tabName={ACTIVE_TAB_ENUM.TAB_PHOTO}>
            <TabPhotos inspection={inspection} />
          </TabPanel>
          <TabPanel value={activeTab} tabName={ACTIVE_TAB_ENUM.TAB_DOCS}>
            <TabDocuments inspection={inspection} />
          </TabPanel>
        </Styles.Details>
      </Styles.Accordion>
    </>
  );
};

export default InspectionFiles;
