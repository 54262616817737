import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setBudget } from 'stores/presentBudget/presentBudget.store';
import { IOptions } from 'components/Select/Select.types';
import { ICompanyConclusionTypes } from 'services/ApiService/BudgetService/BudgetService.types';
import { toast } from 'react-toastify';
import BudgetService from 'services/ApiService/BudgetService/BudgetService';
import { useAuthState } from 'contexts/auth/useAuth';
import { Button } from 'components';
import { Select } from 'components/Select/Select';
import * as S from './ConclusionForm.styles';
import { selectBudgetId } from 'stores/presentBudget/presentBudget.selector';
import { selectCompanyId } from 'stores/presentInspection/presentInspection.selector';

const ConclusionForm = () => {
  const budgetId = useAppSelector(selectBudgetId);
  const companyId = useAppSelector(selectCompanyId);
  const [companyConclusionTypes, setCompanyConclusionTypes] = useState<ICompanyConclusionTypes>();
  const [reasonsOptions, setReasonsOptions] = useState<IOptions[]>([]);
  const [selectedConclusionTypeId, setSelectedConclusionTypeId] = useState('');
  const [selectedReasonId, setSelectedReasonId] = useState('');
  const [commentInput, setCommentInput] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const dispatch = useAppDispatch();
  const { account } = useAuthState();

  const conclusionTypesOptions: IOptions[] =
    companyConclusionTypes?.conclusionTypes.map((conclusionType) => ({
      value: conclusionType.id,
      name: conclusionType.description,
    })) || [];

  const getConclusionTypeById = useCallback(
    (conclusionTypeId: string) =>
      companyConclusionTypes?.conclusionTypes.find((conclusionType) => conclusionType.id === conclusionTypeId),
    [companyConclusionTypes]
  );

  const getReasonsOptionsFromCompanyConclusionType = useCallback(
    (conclusionTypeId: string): IOptions[] => {
      const conclusionTypeFiltered = getConclusionTypeById(conclusionTypeId);
      if (conclusionTypeFiltered) {
        return conclusionTypeFiltered.conclusionReasons.map((reason) => ({
          value: reason.id,
          name: reason.description,
        }));
      }

      return [];
    },
    [getConclusionTypeById]
  );

  const handleChangeSelectedConclusionType = (value: string) => {
    if (value) {
      setSelectedConclusionTypeId(value);
      setSelectedReasonId('');
      setReasonsOptions(getReasonsOptionsFromCompanyConclusionType(value));
    } else {
      setSelectedConclusionTypeId('');
      setSelectedReasonId('');
      setReasonsOptions([]);
    }
  };

  const disableSelectReason = reasonsOptions.length === 0;

  const getConclusionTypeAndReasonById = () => {
    const conclusionTypeFiltered = getConclusionTypeById(selectedConclusionTypeId);

    if (conclusionTypeFiltered) {
      const reasonFiltered = conclusionTypeFiltered.conclusionReasons.find((reason) => reason.id === selectedReasonId);

      if (reasonFiltered) {
        return {
          conclusionType: conclusionTypeFiltered.description,
          conclusionReason: reasonFiltered.description,
          comment: commentInput,
        };
      }
    }

    return null;
  };

  const clearFormInputs = () => {
    setSelectedConclusionTypeId('');
    setSelectedReasonId('');
    setCommentInput('');
  };

  const createBudgetConclusion = async () => {
    try {
      const conclusionTypeAndReason = getConclusionTypeAndReasonById();
      setLoadingButton(true);
      if (conclusionTypeAndReason) {
        const budgetReponse = await BudgetService.addBudgetConclusion({
          budgetId,
          comment: conclusionTypeAndReason.comment,
          reason: conclusionTypeAndReason.conclusionReason,
          type: conclusionTypeAndReason.conclusionType,
          userId: account.id,
        });
        dispatch(setBudget(budgetReponse));
      }
    } catch (error) {
      toast.error('Erro ao salvar conclusão');
    } finally {
      clearFormInputs();
      setLoadingButton(false);
    }
  };

  const getCompanyConclusionTypes = useCallback(async () => {
    try {
      if (companyId) {
        const response = await BudgetService.getCompanyConclusionTypes(companyId);
        setCompanyConclusionTypes(response);
      }
    } catch (error) {
      toast.error('Erro ao buscar tipos de conclusão');
    }
  }, [companyId]);

  const disableSaveButton = commentInput === '' || selectedConclusionTypeId === '' || selectedReasonId === '';

  useEffect(() => {
    getCompanyConclusionTypes();
  }, [getCompanyConclusionTypes]);

  return (
    <>
      <S.CardContainer>
        <S.Title>Nova conclusão</S.Title>
        <S.CardBody>
          <S.SelectWrapper>
            <S.SelectItem>
              <S.Label htmlFor="type" testID="label-budget-type">
                Tipo de conclusão
              </S.Label>
              <Select
                testID="type-select"
                labelId="selecttype"
                name="selecttype"
                placeholder="Selecione o tipo de conclusão"
                required={true}
                options={conclusionTypesOptions}
                selectedOptions={selectedConclusionTypeId}
                onChange={({ target: { value } }): void => handleChangeSelectedConclusionType(value as string)}
              />
            </S.SelectItem>
            <S.SelectItem>
              <S.Label htmlFor="reason" testID="label-budget-reason">
                Motivo
              </S.Label>
              <S.Select
                testID="reason-select"
                labelId="reason-select"
                name="reason-select"
                placeholder="Selecione o motivo"
                required={true}
                disabled={disableSelectReason}
                options={reasonsOptions}
                selectedOptions={selectedReasonId}
                onChange={({ target: { value } }): void => setSelectedReasonId(value as string)}
              />
            </S.SelectItem>
          </S.SelectWrapper>
          <S.CommentWrapper>
            <S.Label htmlFor="comment" testID="label-comment-type">
              Comentário
            </S.Label>
            <S.TextField
              ariaLabelledby="comment-input"
              id="comment"
              inputProps={{
                'data-testid': 'commentTextArea',
                form: {
                  autocomplete: 'off',
                },
              }}
              name="comment"
              type="text"
              placeholder="Descrição da conclusão..."
              variant="outlined"
              value={commentInput}
              multiline
              minRows={17}
              maxRows={17}
              onChange={({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void =>
                setCommentInput(value as string)
              }
            />
          </S.CommentWrapper>
          <S.CardFooter>
            <Button
              text="Salvar conclusão"
              testID="save-conclusion-button"
              variant="contained"
              loading={loadingButton}
              onClick={createBudgetConclusion}
              disabled={!!disableSaveButton}
              loadingSize={25}
              loadingColor="#FFF"
              size="large"
            />
          </S.CardFooter>
        </S.CardBody>
      </S.CardContainer>
    </>
  );
};

export default ConclusionForm;
