import React from 'react';
import customerIcon from 'assets/svg/budget-template/icon-customer.svg';
import {
  CodeContainer,
  Container,
  HeaderSection,
  Information,
  InformationContainer,
  InsuranceDetailsWrapper,
  Label,
  Value,
} from './InsuranceDetailsSection.styles';
import { useAppSelector } from 'hooks/hooks';
import { selectInspection } from 'stores/presentInspection/presentInspection.selector';
import { selectBudget } from 'stores/presentBudget/presentBudget.selector';
import { priceFormatter } from 'utils/formatPrice';

const InsuranceDetailsSection = () => {
  const inspection = useAppSelector(selectInspection);
  const budget = useAppSelector(selectBudget);

  return (
    <Container>
      <CodeContainer>
        <Label>Sinistro:</Label>
        <Value>{inspection.code || '-'}</Value>
      </CodeContainer>

      <InsuranceDetailsWrapper>
        <HeaderSection>
          <img src={customerIcon} alt="clock" width="18" />
          <h1>Dados do segurado</h1>
        </HeaderSection>
        <InformationContainer>
          <Information>
            <Label>Nome do Segurado</Label>
            <Value>{inspection.customer?.name || '-'}</Value>
          </Information>

          <Information>
            <Label>Telefone</Label>
            <Value>{inspection.customer?.phone || '-'}</Value>
          </Information>

          <Information>
            <Label>Franquia</Label>
            <Value>{priceFormatter.format(budget.deductible)}</Value>
          </Information>
        </InformationContainer>
      </InsuranceDetailsWrapper>
    </Container>
  );
};

export default InsuranceDetailsSection;
