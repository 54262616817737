import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 5px;
  background-color: #fff;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #ececec;
`;

const Title = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  color: #6a758b;
`;

export const Description = styled(Title)`
  width: 100%;
  flex: 2.3;
`;

export const Value = styled(Title)`
  flex: 1.2;
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  gap: 10px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  gap: 8px;
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  padding-right: 12px;
  flex: 2.5;
`;
