import { createTheme } from '@material-ui/core/styles';

export const ableTheme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#000000',
    },
  },
});
