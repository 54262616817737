import styled from 'styled-components';
import Edit from '@material-ui/icons/Edit';

export const Card = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
`;

export const CardHeader = styled.div`
  background: #fff;
  height: 60px;
  padding: 0 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ced6e5;
`;

export const CardTitle = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
`;

export const CardBody = styled.div`
  padding: 30px;
`;

export const EditIcon = styled(Edit)`
  font-size: 16px !important;
`;
