import ApiService from 'services/ApiService';
import {
  IInvoicesService,
  IInvoicesResponse,
  IInvoiceResponse,
  IUploadInvoice,
  ITransformUploadInvoice,
} from './invoicesService.types';
import CubeService from 'services/CubeService/cubeService';
import { convertToBlob } from 'utils/formatDocument';
import { ImageType } from 'react-images-uploading';
import {
  IInvoiceStatus,
  IInvoicePaid,
  IInvoiceWithExpenses,
  InvoiceExpenseTypesEnum,
} from 'commom/types/Invoices.types';

const InvoicesService: IInvoicesService = {
  getInvoices: async ({ filter, page, size }) => {
    const { clientIds, finalRangeCreatedAt, initialRangeCreatedAt, statusIds } = filter;

    const queryParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    clientIds && queryParams.append('clientIds', clientIds.join(','));

    initialRangeCreatedAt && queryParams.append('initialRangeCreatedAt', initialRangeCreatedAt);

    finalRangeCreatedAt && queryParams.append('finalRangeCreatedAt', finalRangeCreatedAt);

    statusIds && queryParams.append('statusIds', statusIds.join(','));

    return await ApiService.get<IInvoicesResponse>(`/invoices?${queryParams.toString()}`);
  },

  getInvoiceStatus: async () => {
    const { data } = await ApiService.get<IInvoiceStatus[]>('/invoices/status');

    return data;
  },

  getInvoicesExpenses: async ({ filter, page, size }) => {
    const { invoiceId, companyIds } = filter;

    const queryParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
    });

    companyIds && queryParams.append('companyIds', companyIds.join(','));

    return await ApiService.get<IInvoiceWithExpenses>(`/invoices/expenses/${invoiceId}?${queryParams.toString()}`);
  },

  getCurrentStatus: (status: IInvoiceStatus[]) => {
    return status[status.length - 1];
  },

  getInvoiceExpenseType: (type: InvoiceExpenseTypesEnum) => {
    const expenseTypes = {
      PHOTOS_RECEIVED: 'Vistoria digital',
      BUDGET: 'Orçamento',
    };

    return expenseTypes[type];
  },

  setInvoicePaid: async (invoiceId: string, invoicePaidRequest: IInvoicePaid) => {
    const { data } = await ApiService.put<IInvoiceResponse>(`/invoices/${invoiceId}`, { ...invoicePaidRequest });
    return data;
  },

  uploadInvoiceToAwsS3: async (id, invoice) => {
    try {
      if (invoice.base64 && invoice.file) {
        const invoiceBlob = await convertToBlob(invoice.base64, invoice.file.type);
        const invoiceToUpload = {
          id,
          name: invoice.name,
          description: invoice.description,
          document: invoiceBlob,
          documentType: invoice.file.type,
          extension: `${invoice.file.name.split('.')[1]}`,
        };

        const { path } = await CubeService.uploadInvoiceToS3(invoiceToUpload);
        return { ...invoice, path };
      }
      return { ...invoice };
    } catch {
      return { ...invoice };
    }
  },

  transformInvoices: (invoiceFormat: ITransformUploadInvoice): IInvoicePaid => {
    const { invoiceId, name, path, paymentDate } = invoiceFormat;
    return {
      paymentDate: paymentDate,
      receipt: {
        invoiceId,
        name: name,
        path: path || '',
        paymentDate: paymentDate,
      },
    };
  },

  transformInvoiceToUploadToInvoice: (invoice: IInvoiceWithExpenses, image: ImageType): IUploadInvoice => {
    return {
      name: `comprovante_de_${invoice.client.name}_${new Date().toISOString()}`,
      description: `comprovante de ${invoice.client.name} referente ao pagamento da fatura de ${invoice.startDate} a ${invoice.endDate}`,
      base64: image.dataURL,
      file: image.file,
    };
  },

  sendInvoiceDocumentPaid: async (invoice: IInvoiceWithExpenses, paymentDate: string, image: ImageType) => {
    const invoiceUnformated = InvoicesService.transformInvoiceToUploadToInvoice(invoice, image);
    const uploadInvoice = await InvoicesService.uploadInvoiceToAwsS3(invoice.id, invoiceUnformated);
    const invoicePaidRequest = InvoicesService.transformInvoices({
      ...uploadInvoice,
      invoiceId: invoice.id,
      paymentDate,
    });
    return InvoicesService.setInvoicePaid(invoice.id, invoicePaidRequest);
  },
};

export default InvoicesService;
