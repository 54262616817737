import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ReactDOMServer from 'react-dom/server';
import { useHistory, useParams } from 'react-router-dom';
import { BackButton, Button } from 'components';
import { priceFormatter } from 'utils/formatPrice';
import { Skeleton } from '@mui/material';
import InvoiceReport from './InvoiceReport/InvoiceReport';
import PrintIcon from '@mui/icons-material/LocalPrintshopRounded';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import ReceiptIcon from 'assets/svg/invoices-icons/receipt.svg';
import PriceIcon from 'assets/svg/invoices-icons/price.svg';
import ListIcon from 'assets/svg/invoices-icons/product-list.svg';
import DateService from 'services/DateService/dateService';
import InvoicesService from 'services/ApiService/InvoicesService/invoicesService';
import { InvoicesExpensesTable } from './InvoiceExpensesTable/InvoicesExpensesTable';
import MarkInvoiceAsPaidDrawer from 'components/Drawer/MarkInvoiceAsPaidDrawer/MarkInvoiceAsPaidDrawer';
import { IInvoiceStatus, InvoiceStatusEnum, IReceiptInvoice } from 'commom/types/Invoices.types';
import { useFilterInvoicesExpenses } from 'hooks/Invoices/InvoicesHook';
import { useInvoicesContext } from 'contexts/invoices/useInvoicesContext';
import { StatusCell } from '../InvoiceTable/InvoicesTable.styles';
import * as S from './Details.styles';

export const InvoiceDetails = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { companiesIds } = useInvoicesContext();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [isMarkAsPaidDrawerOpen, setIsMarkAsPaidDrawerOpen] = useState(false);

  const { invoicesWithExpenses, isLoading, isFetching, totalPages, totalItems, setPage } = useFilterInvoicesExpenses({
    invoiceId: id,
    companyIds: companiesIds,
  });

  const { client, price, expensesCount, endDate, status, receipt } = invoicesWithExpenses || {};

  const openMarkAsPaid = () => {
    setIsMarkAsPaidDrawerOpen(true);
  };

  const printFrame = (printContents: string) => {
    try {
      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = 'none';

      const doc = iframe?.contentWindow?.document;
      doc?.open();
      doc?.write(`
          <html>
            <head>
              <title>Relatório de cobrança</title>
              <link rel="preconnect" href="https://fonts.googleapis.com" media="all">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin media="all">
              <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap" rel="stylesheet" media="all">
            </head>
            <body>
              ${printContents}
            </body>
          </html>
        `);
      doc?.close();

      iframe.onload = () => {
        iframe?.contentWindow?.focus();
        iframe?.contentWindow?.print();
        document.body.removeChild(iframe);
      };
    } catch (error) {
      toast.error('Erro no carregamento da impressão da cobrança.');
    }
  };

  const handlePrint = async () => {
    try {
      setLoadingPrint(true);

      const { data } = await InvoicesService.getInvoicesExpenses({
        filter: {
          invoiceId: id,
          companyIds: companiesIds,
        },
        page: 0,
        size: totalItems || 10,
      });

      const printContents = ReactDOMServer.renderToString(<InvoiceReport invoice={data} />);

      printFrame(printContents);
    } catch (error) {
      toast.error('Ocorreu um erro ao imprimir a cobrança.');
    } finally {
      setLoadingPrint(false);
    }
  };

  const formatedPeriod = (endDate: string | undefined) => {
    if (!endDate) return '-';
    return DateService.getMonthAndYearAcronym(endDate);
  };

  const renderStatusCell = (invoiceStatus: IInvoiceStatus[] | undefined) => {
    if (invoiceStatus && invoiceStatus.length > 0) {
      const { status, description } = InvoicesService.getCurrentStatus(invoiceStatus);

      return <StatusCell type={status}>{description}</StatusCell>;
    }

    return <StatusCell type="">-</StatusCell>;
  };

  const showPrice = (price: number | undefined) => {
    return priceFormatter.format(price || 0);
  };

  const showPaymentDate = (receipt: IReceiptInvoice | undefined) => {
    return receipt ? DateService.formatDate(receipt.paymentDate) : '-';
  };

  const handleReceiptLink = () => {
    if (receipt?.url) {
      window.open(receipt?.url, '_blank', 'noopener,noreferrer');
    }
  };

  const isCurrentStatusIsPaid = () => {
    return status && InvoicesService.getCurrentStatus(status).status === InvoiceStatusEnum.PAID;
  };

  const showMarkIsPaidButton = () => {
    return invoicesWithExpenses && !isCurrentStatusIsPaid();
  };

  const showPaymentCard = () => {
    return isCurrentStatusIsPaid() && receipt;
  };

  return (
    <S.Container>
      <S.Header>
        <BackButton onClick={() => history.push('/invoices')} />
        <S.TitleWrapper>
          {isLoading ? (
            <>
              <Skeleton variant="text" width={200} sx={{ fontSize: '1.7rem' }} />
              <Skeleton variant="rounded" width={50} />
            </>
          ) : (
            <>
              <S.Title>
                {client?.name} - {formatedPeriod(endDate || '')}
              </S.Title>
              {renderStatusCell(status)}
            </>
          )}
        </S.TitleWrapper>
        <S.Actions>
          {showMarkIsPaidButton() && <Button text="MARCAR COMO PAGO" onClick={openMarkAsPaid} variant="outlined" />}
          <Button text="IMPRIMIR" onClick={handlePrint} startIcon={<PrintIcon />} loading={loadingPrint} />
        </S.Actions>
      </S.Header>
      <S.Body>
        <S.SummaryContainer>
          <S.SummaryItem>
            <S.SummaryInfo>
              <S.SummaryTitle>Serviços cobrados</S.SummaryTitle>
              <S.SummaryValue>{expensesCount || 0}</S.SummaryValue>
            </S.SummaryInfo>
            <img src={ListIcon} alt="Serviços" />
          </S.SummaryItem>
          <S.SummaryItem>
            <S.SummaryInfo>
              <S.SummaryTitle>Preço</S.SummaryTitle>
              <S.SummaryValue>{showPrice(price)}</S.SummaryValue>
            </S.SummaryInfo>
            <img src={PriceIcon} alt="Preço" />
          </S.SummaryItem>
          {showPaymentCard() && (
            <S.SummaryItem data-testid="payment-card">
              <S.SummaryInfo>
                <S.SummaryTitle>Data do Pagamento</S.SummaryTitle>
                <S.SummaryValue>{showPaymentDate(receipt)}</S.SummaryValue>
                {receipt?.url && (
                  <S.SummaryLink data-testid="receipt-link" onClick={handleReceiptLink}>
                    <EyeIcon /> Visualizar comprovante
                  </S.SummaryLink>
                )}
              </S.SummaryInfo>
              <img src={ReceiptIcon} alt="Comprovante" />
            </S.SummaryItem>
          )}
        </S.SummaryContainer>
        <S.TableContainer>
          <InvoicesExpensesTable
            setPage={setPage}
            isFetching={isFetching}
            isLoading={isLoading}
            totalItems={totalItems}
            invoiceWithExpenses={invoicesWithExpenses}
            totalPages={totalPages}
          />
        </S.TableContainer>
      </S.Body>

      {isMarkAsPaidDrawerOpen && invoicesWithExpenses && (
        <MarkInvoiceAsPaidDrawer
          open={isMarkAsPaidDrawerOpen}
          invoice={invoicesWithExpenses}
          onClose={() => setIsMarkAsPaidDrawerOpen(false)}
        />
      )}
    </S.Container>
  );
};
