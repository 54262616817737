import React, { useCallback, useEffect, useState } from 'react';

import { IInspection, IRegulator } from 'pages/SchedulerList/components/Inspection';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InspectionService from 'services/ApiService/InspectionService/inspectionService';
import { isAxiosError } from 'utils/isAxiosError';
import * as S from './EditInspectionDrawer.styles';
import { AnchorTypesEnum } from 'components/Drawer';
import { Button } from 'components/Button';
import ProductTypeService from 'services/ProductTypeService/productTypeService';
import { Label } from 'components/Label';
import { formValidations } from 'utils/form/validations';
import { useMutateInspection } from 'hooks/Inspections/InspectionsHooks';
import RegulatorService from 'services/ApiService/RegulatorService/regulatorService';
import { IOptions } from 'components/Select/Select.types';
import { Select } from 'components/Select/Select';
import { TextField } from 'components/TextField';
import { IPutCodeOrPlate } from 'services/ApiService/InspectionService/inspectionService.types';

interface IEditInspectionDrawer {
  openDrawer: boolean;
  onCloseDrawer(): void;
  inspection: IInspection;
  testID?: string;
}

const inputProps = {
  code: {
    'data-testid': 'input-code',
  },
};

const EditInspectionDrawer = ({ openDrawer, inspection, onCloseDrawer, testID }: IEditInspectionDrawer) => {
  const [code, setCode] = useState<string>('');
  const [regulatorsList, setRegulatorsList] = useState<IOptions[]>([]);
  const [regulatorSelected, setRegulatorSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const isRegulatorsAvailable = (): boolean => regulatorsList.length > 0;
  const isRegulatorNotSelected = (): boolean => regulatorSelected === '';

  const isAbleToChangeRegulator = (): boolean =>
    regulatorSelected !== inspection?.regulatorId && !isRegulatorNotSelected();

  const isProductAuto = ProductTypeService.isAuto(inspection.productType?.code);

  const isAbleToChangeCode = (): boolean => code !== inspection.code;

  const { invalidateInspectionParallel } = useMutateInspection();

  const showErrorToast = (error: number | undefined) => {
    if (error === 409) {
      toast.warning('Já existe um atendimento com esses dados!');
    } else {
      toast.error('Erro ao atualizar informações!');
    }
  };

  const getRegulators = useCallback(async (companyId: string): Promise<IOptions[]> => {
    try {
      const regulators = await RegulatorService.getRegulators([companyId]);

      return regulatorsToOptions(regulators);
    } catch (error) {
      toast.error('Problema ao carregar os peritos, tente novamente.');

      return [];
    }
  }, []);

  const regulatorsToOptions = (regulators: IRegulator[]): IOptions[] => {
    return regulators.map(({ id: value, username: name }) => ({ value, name }));
  };

  const editInspection = async (inspectionId: string, form: IPutCodeOrPlate, regulatorId: string) => {
    if (isAbleToChangeCode()) {
      await InspectionService.putCodeOrPlate(inspectionId, form);
    }

    if (isAbleToChangeRegulator()) {
      await RegulatorService.putRegulatorInInspections(regulatorId, [inspectionId]);
    }
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      await editInspection(inspection.id, { code }, regulatorSelected);

      await invalidateInspectionParallel(inspection.id);
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      if (isAxiosError(error)) {
        showErrorToast(error.response?.status);
      } else {
        toast.error('Erro ao atualizar informações!');
      }
    } finally {
      setLoading(false);
      onCloseDrawer();
    }
  };

  const handleInputState = (name: string): string => {
    return errors[name] ? 'invalid' : 'default';
  };

  const hasError = (inputName: string): boolean => {
    return errors[inputName] !== undefined;
  };

  const isDisabled = () => {
    return (code === inspection?.code && regulatorSelected === inspection?.regulatorId) || loading;
  };

  const handleRegulator = useCallback(
    (regulators: IOptions[]): void => {
      const haveRegulator = regulators.find((regulator) => regulator.value === inspection?.regulatorId);
      if (haveRegulator && inspection?.regulatorId) {
        setRegulatorSelected(inspection?.regulatorId);
      } else {
        setRegulatorSelected(regulators[0]?.value);
      }
    },
    [inspection]
  );

  const instantiateRegulators = useCallback(
    async (companyId: string): Promise<void> => {
      const regulators = await getRegulators(companyId);
      setRegulatorsList(regulators);
      handleRegulator(regulators);
    },
    [getRegulators, handleRegulator]
  );

  useEffect(() => {
    setCode(inspection.code);
  }, [inspection]);

  useEffect(() => {
    if (inspection?.companyId) {
      instantiateRegulators(inspection.companyId);
    }
  }, [inspection, instantiateRegulators]);

  return (
    <S.Container testId={testID} anchor={AnchorTypesEnum.RIGHT} openDrawer={openDrawer} onClose={onCloseDrawer}>
      <S.Header>
        <S.Title>Editar Atendimento</S.Title>
        <S.CloseButton data-testid="close-btn" onClick={onCloseDrawer} />
      </S.Header>

      <S.Content>
        <S.FormContent
          id="edit-inspection-drawer-form"
          data-testid="edit-inspection-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <S.InputContainer>
            <Label htmlFor="code" testID="label-code">
              {isProductAuto ? 'Sinistro' : 'Identificador'}
            </Label>

            <TextField
              ariaLabelledby="code"
              error={hasError('code')}
              id="code"
              inputProps={inputProps.code}
              inputRef={register(formValidations.default)}
              helperText={errors?.code?.message}
              name="code"
              state={handleInputState('code')}
              type="text"
              variant="outlined"
              value={code}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => setCode(target.value)}
              testID="input-code"
            />
          </S.InputContainer>

          {isRegulatorsAvailable() ? (
            <Select
              testID="regulator-select"
              options={regulatorsList}
              selectedOptions={regulatorSelected}
              label="Perito"
              labelId="regulator"
              onChange={({ target: { value } }): void => setRegulatorSelected(value as string)}
            />
          ) : (
            <S.MessageError>Nenhum perito encontrado</S.MessageError>
          )}
        </S.FormContent>
      </S.Content>
      <S.Footer>
        <S.Separator />
        <S.ActionsContainer>
          <Button variant="outlined" text="cancelar" onClick={onCloseDrawer} />
          <Button
            variant="contained"
            text="salvar"
            form="edit-inspection-drawer-form"
            type="submit"
            loading={loading}
            disabled={isDisabled()}
            testID="button-submit-drawer"
          />
        </S.ActionsContainer>
      </S.Footer>
    </S.Container>
  );
};

export default EditInspectionDrawer;
